import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './project.initialState';
import { Project } from 'models';

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    getProjects: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    getProjectsSuccess: (state, { payload }: PayloadAction<Project[]>) => {
      state.projects = payload;
      state.isFetching = false;
      state.error = null;
    },
    getProjectsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getProjectsNoUpdateFetching: (state) => {
      state.error = null;
    },
    getProjectsNoUpdateFetchingSuccess: (state, { payload }: PayloadAction<Project[]>) => {
      state.projects = payload;
      state.error = null;
    },
    getProjectsNoUpdateFetchingFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    getProject: (state, { payload }: PayloadAction<string>) => {
      // state.isFetching = true;
      state.error = null;
    },
    getProjectSuccess: (state, { payload }: PayloadAction<Project>) => {
      state.project = payload;
      // state.isFetching = false;
      state.error = null;
    },
    getProjectFailed: (state, { payload }: PayloadAction<any>) => {
      // state.isFetching = false;
      state.error = payload;
    },
    createProject: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = true;
      state.error = null;
    },
    createProjectSuccess: (state, { payload }: PayloadAction<Project>) => {
      // state.project = payload;
      state.isCreating = false;
      state.error = null;
    },
    createProjectFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = false;
      state.error = payload;
    },
    updateProject: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = true;
      state.error = null;
    },
    updateProjectSuccess: (state, { payload }: PayloadAction<Project>) => {
      // state.project = payload;
      state.isCreating = false;
      state.error = null;
    },
    updateProjectFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdatingProjectUsers = false;
      state.error = payload;
    },
    updateProjectUsers: (state, { payload }: PayloadAction<any>) => {
      state.isUpdatingProjectUsers = true;
      state.error = null;
    },
    updateProjectUsersSuccess: (state, { payload }: PayloadAction<Project>) => {
      // state.project = payload;
      state.isUpdatingProjectUsers = false;
      state.error = null;
    },
    updateProjectUsersFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdatingProjectUsers = false;
      state.error = payload;
    },
    setIsModalUpdateProjectVisible: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsModalUpdateProjectVisibleSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalUpdateProjectVisible = payload;
    },
    setSelectedProject: (state, { payload }: PayloadAction<Project | null>) => undefined,
    setSelectedProjectSuccess: (state, { payload }: PayloadAction<Project | null>) => {
      state.selectedProject = payload;
    },
    deleteProject: (state, { payload }: PayloadAction<string>) => {
      state.isDeleting = true;
      state.error = null;
    },
    deleteProjectSuccess: (state, { payload }: PayloadAction<Project>) => {
      state.isDeleting = false;
      state.error = null;
    },
    deleteProjectFailed: (state, { payload }: PayloadAction<any>) => {
      state.isDeleting = false;
      state.error = payload;
    }
  }
});

export const projectActions = projectSlice.actions;

export * as projectSelector from './project.selector';

export default projectSlice.reducer;
