import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './auth.initialState';
import { Login, TwoFactor, LoginStep, LoginResponse } from 'models';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    postLogin: (state, { payload }: PayloadAction<Login>) => {
      state.isLoggedIn = false;
      state.isFetching = true;
      state.error = null;
    },

    postLoginSuccess: (state, { payload }: PayloadAction<LoginResponse>) => {
      state.isLoggedIn = true;
      state.accessToken = payload.accessToken;
      state.twoFactorEnabled = payload.twoFAEnabled;
      state.twoFactorCode = payload.dataURL;
      state.twoFactorSecretKey = payload.secret;
      state.isFetching = false;
      state.step = LoginStep.VERIFY_CODE;
      state.error = null;
    },
    postLoginFailed: (state, { payload }) => {
      state.isFetching = false;
      state.isLoggedIn = false;
      state.error = payload;
    },
    postVerifyTwoFactor: (state, { payload }: PayloadAction<TwoFactor>) => {
      state.isFetching = true;
      state.error = null;
    },
    postVerifyTwoFactorSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = false;
      state.twoFactorAccessToken = payload;
      state.error = null;
    },
    postVerifyTwoFactorFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    logout: (state) => {
      state.isFetching = true;
    },
    logoutSuccess: (state) => {
      state.isLoggedIn = false;
      state.isFetching = false;
      state.accessToken = '';
      state.step = LoginStep.LOGIN;
      // state.error = null;
    },
    getCurrentUser: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = true;
      state.error = null;
    },
    getCurrentUserSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = null;
      state.currentUser = payload;
    },
    getCurrentUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
      state.currentUser = null;
    }
  }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;

export * as authSelector from './auth.selector';

export * from './auth.selector';
