import {
  AlphabetFilter,
  Alphabet,
  AlphabetWrapper,
  AlphabetFilterLabel
} from './UserSortByAlphabet.styled';
import { Badge, Button } from 'react-bootstrap';

interface Props {
  selectedAlphabet: string[];
  onSelectAlphabet: (value: string[]) => void;
}

const UserSortByAlphabet = (props: Props) => {
  const alphabet = new Array(26).fill(1).map((_, i) => String.fromCharCode(65 + i));

  const onClick = (char: string) => {
    const _selectedAlphabet = [...props.selectedAlphabet];
    const index = _selectedAlphabet.findIndex((c) => c === char);
    if (index > -1) {
      _selectedAlphabet.splice(index, 1);
    } else {
      _selectedAlphabet.push(char);
    }
    props.onSelectAlphabet(_selectedAlphabet);
  };

  const getClass = (char: string) => {
    return props.selectedAlphabet.includes(char) ? 'text-underline text-bold' : '';
  };

  return (
    <AlphabetFilter>
      <AlphabetFilterLabel>Username starts with</AlphabetFilterLabel>
      <AlphabetWrapper>
        {alphabet.map((char) => (
          <Alphabet className={getClass(char)} key={char} onClick={() => onClick(char)}>
            {char}
          </Alphabet>
        ))}
        <Badge variant="primary" pill onClick={() => props.onSelectAlphabet([])}>
          <strong>Clear</strong>
        </Badge>
      </AlphabetWrapper>
    </AlphabetFilter>
  );
};
export default UserSortByAlphabet;
