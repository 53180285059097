import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import classNames from './FcMentionInput.module.css';

interface Props {
  value: string;
  onChange: (value: string) => void;
  onMention: (value: string[]) => void;
  data: any;
  selectedValue: string[];
  children?: React.ReactElement;
  margin?: boolean;
  bordered?: boolean;
}

export const FcMentionInput = (props: Props) => {
  const { value, onChange, onMention, data, selectedValue, children, margin, bordered } = {
    ...props
  };

  const onAdd = (id: string) => {
    const newValue: string[] = [...selectedValue];
    selectedValue.push(id);
    onMention(selectedValue);
  };

  return (
    <>
      <MentionsInput
        className="mentions"
        classNames={classNames}
        value={value}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          onChange(newValue);
        }}
        style={{
          margin: margin ? '0.3rem 0' : '0',
          '&multiLine': {
            input: {
              border: bordered || bordered === undefined ? '1px solid #dee2e6' : '0',
              borderRadius: bordered ? '0.375rem' : '0'
            }
          }
        }}
      >
        <Mention
          trigger="@"
          data={data}
          className={classNames.mentions__mention}
          appendSpaceOnAdd={true}
          onAdd={(id: string | number, display: string) => {
            onAdd(id as string);
          }}
        />
      </MentionsInput>
      {children}
    </>
  );
};
