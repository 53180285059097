import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const CommentWrapper = styled.div`
  padding: 0.425rem 0.725rem 0.3rem 0.725rem;
  background: #fff;
  box-shadow: yellowgreen;
  border-radius: 0.375rem;
  box-shadow: 2px 2px 6px 0px rgba(136, 152, 170, 0.53);
  border: 1px solid rgba(136, 152, 170, 0.15);
  z-index: 1;
`;

export const CommentInput = styled(Form.Control)`
  resize: none;
`;

export const CommentLabel = styled.div`
  font-weight: 600;
`;

export const CommentAction = styled.span`
  font-weight: 600;
  font-size: 0.725rem;
  &:hover {
    cursor: pointer;
  }
`;

export const CommentInputWrapper = styled.div`
  width: 180px;
`;
