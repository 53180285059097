import { Notification } from 'models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notificationActions } from 'store/notifications/notification.slice';
import { NotificationItemWrapper } from './NotificationListItem.styled';
import { Col } from 'react-bootstrap';
import { formatTo_HHMM, getReadableDurationString } from 'utils/formatters/datetime.formatter';
import debounce from 'lodash.debounce';
import { getShiftChangeById } from 'store/shiftChanges/shiftChange.slice';
import { selectShiftChanges } from 'store/shiftChanges/shiftChange.selector';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ShiftChangeDetail from 'components/ShiftChange/Actions/NewShiftChangeForm/ShiftChangeDetail';
import { getOffVacationById, offVacationSelector } from 'store/offVacations/offVacation.slice';
import OffVacationRequestDetail from 'components/ShiftChange/MainActions/OffVacationRequestDetail/OffVacationRequestDetail';

interface Props {
  data: Notification;
  type: string;
}

const shiftChangeRequestTypes = [
  { key: 'SWAP', value: 'Swap' },
  { key: 'COVER', value: 'Cover' },
  { key: 'REPLACE', value: 'Replace' },
  { key: 'MOVE', value: 'Move' }
];
const OffVacationRequestTypes = [
  { key: 'ONEDAY', value: 'ONEDAY' },
  { key: 'SOMEDAY', value: 'SOMEDAY' },
  { key: 'CONSECUTIVEDAY', value: 'CONSECUTIVEDAY' },
  { key: 'SOMEHOURS', value: 'SOMEHOURS' }
];

const NotificationListItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const { data, type } = { ...props };
  const shiftChanges = useAppSelector(selectShiftChanges);
  const offVacations = useAppSelector(offVacationSelector.selectOffVacation);
  const [isVisible, setIsVisible] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [notificationType, setNotificationType] = useState('');

  const onMark = (id: string) => {
    dispatch(notificationActions.markAsRead({ id: id, type: type }));
  };

  const onUnreadMark = (id: string) => {
    dispatch(notificationActions.markAsUnread({ id: id, type: type }));
  };

  const showDetal = (externalData: string) => {
    const eDataNotification = externalData.split('|');
    if (eDataNotification[0] && eDataNotification[1]) {
      if (shiftChangeRequestTypes.some((item) => item.key === eDataNotification[0])) {
        setShowDetail(false);
        getShiftChangeData(eDataNotification[1]);
        setNotificationType(eDataNotification[0]);
        setIsVisible(true);
      } else if (OffVacationRequestTypes.some((item) => item.key === eDataNotification[0])) {
        setShowDetail(false);
        getOffVacationData(eDataNotification[1]);
        setNotificationType(eDataNotification[0]);
        setIsVisible(true);
      }
    }
  };

  const getShiftChangeData = debounce((externalData: string) => {
    const payload = getPayload(externalData);
    dispatch(getShiftChangeById(payload));
  }, 200);

  const getOffVacationData = debounce((externalData: string) => {
    const payload = getPayload(externalData);
    dispatch(getOffVacationById(payload));
  }, 200);

  const getPayload = (externalData: string) => {
    return {
      id: externalData
    };
  };

  useEffect(() => {
    setShowDetail(true);
  }, [shiftChanges, offVacations]);

  return (
    <NotificationItemWrapper className={!data.isRead ? 'highlighted p-3' : 'p-3'}>
      <Col xs={8}>
        <span
          className={!data.isRead ? 'font-weight-bold' : ''}
          dangerouslySetInnerHTML={{ __html: data.message }}
          onClick={() => {
            showDetal(data.externalData);
          }}
        ></span>
        <Modal
          className="modal-confirm"
          show={isVisible}
          onHide={() => {
            setIsVisible(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-0">
            {showDetail &&
              shiftChangeRequestTypes.some((item) => item.key === notificationType) && (
                <ShiftChangeDetail shiftChange={shiftChanges[0]} />
              )}
            {showDetail &&
              OffVacationRequestTypes.some((item) => item.key === notificationType) && (
                <OffVacationRequestDetail offVacations={offVacations[0]} />
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={() => setIsVisible(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
      <Col xs={2} className="text-right">
        <span className={!data.isRead ? 'font-weight-normal' : ''}>
          {formatTo_HHMM(new Date(data.createdAt))}{' '}
          {getReadableDurationString(new Date(data.createdAt))}
        </span>
      </Col>
      <Col xs={1} className="text-right">
        <strong
          onClick={() => {
            onMark(data._id);
          }}
          className={data.isRead ? 'text-light disable-cursor' : 'clickable'}
        >
          Mark as read
        </strong>
      </Col>
      <Col xs={1} className="text-right">
        <strong
          onClick={() => {
            onUnreadMark(data._id);
          }}
          className={!data.isRead ? 'text-light disable-cursor' : 'clickable'}
        >
          Mark as unread
        </strong>
      </Col>
    </NotificationItemWrapper>
  );
};

export default NotificationListItem;
