import { Shift, User } from '../../models';
import { Nullable } from '../../utils/common/types';

export type FxiedScheduledPreviewIntialState = {
  error: Nullable<string>;
  isFetching: boolean;
  projectId: Nullable<string>;
  previewData: FixedSchedulePreviewItem[];
};

export type FixedSchedulePreviewItem = {
  shift: Shift;
  weekday: number;
  users: User[];
};

export type FixedScheduledPreviewSlot = {
  shiftId: string;
  weekday: number;
  users: string[];
};

export const initialState: FxiedScheduledPreviewIntialState = {
  error: null,
  isFetching: false,
  projectId: null,
  previewData: []
};
