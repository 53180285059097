import { FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';
import { resetPasswordSchema } from 'utils/validators';

interface FormProps {
  password: string;
  passwordConfirmation: string;
}

export const useUserResetPassword = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(userSelector.selectIsModalResetPasswordVisible);
  const user = useAppSelector(userSelector.selectSelectedUser);
  const isUpdating = useAppSelector(userSelector.selectIsUpdatingPassword);
  const error = useAppSelector(userSelector.selectError);

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState<boolean>(false);

  const initialValues: FormProps = {
    password: '',
    passwordConfirmation: ''
  };

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values: FormProps, actions) => {
      handleSubmit(values, actions);
    }
  });

  const handleSubmit = (values: FormProps, actions: any) => {
    dispatch(userActions.resetPasswordUser({ ...values, id: user._id }));
  };

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (error === null && !isUpdating && formik.isSubmitting) {
      toast.success('Successfully Updated Password.');
      formik.setSubmitting(false);
      onHide();
      formik.resetForm({ values: initialValues });
    }
  }, [isUpdating]);

  const onHide = () => {
    dispatch(userActions.setIsModalResetPasswordVisible(false));
  };

  const ButtonShowPassword = () => {
    if (!isShowPassword) {
      return <i className="fas fa-eye text-dark" onClick={() => setIsShowPassword(true)} />;
    } else {
      return <i className="fas fa-eye-slash text-dark" onClick={() => setIsShowPassword(false)} />;
    }
  };

  const ButtonShowPasswordConfirm = () => {
    if (!isShowPasswordConfirm) {
      return <i className="fas fa-eye text-dark" onClick={() => setIsShowPasswordConfirm(true)} />;
    } else {
      return (
        <i className="fas fa-eye-slash text-dark" onClick={() => setIsShowPasswordConfirm(false)} />
      );
    }
  };

  return {
    formik,
    isShowPassword,
    setIsShowPassword,
    isShowPasswordConfirm,
    user,
    isVisible,
    setIsShowPasswordConfirm,
    ButtonShowPassword,
    ButtonShowPasswordConfirm,
    onHide
  };
};
