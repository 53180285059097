import UserExportCsv from 'components/User/Actions/UserExportCsv/UserExportCsv';
import AddUser from '../AddUser/AddUser';

const UserTopActions = () => {
  return (
    <div className="d-flex justify-content-between">
      <AddUser></AddUser>
      <UserExportCsv></UserExportCsv>
    </div>
  );
};
export default UserTopActions;
