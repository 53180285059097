import avatar from 'assets/images/team-3.jpg';
import { User } from 'models';
import { Dropdown, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { authActions } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/hooks';
import './Header.scss';
import { Cookies } from 'react-cookie';
import { FcAvatar } from 'components/Shared';

const Header = () => {
  const cookies = new Cookies();
  const currentUser: User = cookies.get('currentUser');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogOut = () => {
    dispatch(authActions.logout());
  };

  return (
    <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <form
            className="navbar-search navbar-search-light form-inline mr-sm-3"
            id="navbar-search-main"
          >
            <div className="form-group mb-0">
              <div className="input-group input-group-alternative input-group-merge">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </span>
                </div>
                <input className="form-control" placeholder="Search" type="text" />
              </div>
            </div>
            <button
              type="button"
              className="close"
              data-action="search-close"
              data-target="#navbar-search-main"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </form>
          <ul className="navbar-nav align-items-center  ml-md-auto">
            <li className="nav-item d-sm-none">
              <a
                className="nav-link"
                href="#"
                data-action="search-show"
                data-target="#navbar-search-main"
              >
                <i className="ni ni-zoom-split-in"></i>
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ni ni-bell-55"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-xl  dropdown-menu-right  py-0 overflow-hidden">
                {/* List group */}
                <div className="list-group list-group-flush">
                  <a href="#!" className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* Avatar */}
                        <img
                          alt="Image placeholder"
                          src="assets/img/theme/team-1.jpg"
                          className="avatar rounded-circle"
                        />
                      </div>
                      <div className="col ml--2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0 text-sm">John Snow</h4>
                          </div>
                          <div className="text-right text-muted">
                            <small>2 hrs ago</small>
                          </div>
                        </div>
                        <p className="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                      </div>
                    </div>
                  </a>
                  <a href="#!" className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* Avatar */}
                        <img
                          alt="Image placeholder"
                          src="assets/img/theme/team-2.jpg"
                          className="avatar rounded-circle"
                        />
                      </div>
                      <div className="col ml--2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0 text-sm">John Snow</h4>
                          </div>
                          <div className="text-right text-muted">
                            <small>3 hrs ago</small>
                          </div>
                        </div>
                        <p className="text-sm mb-0">A new issue has been reported for Argon.</p>
                      </div>
                    </div>
                  </a>
                  <a href="#!" className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* Avatar */}
                        <img
                          alt="Image placeholder"
                          src="assets/img/theme/team-3.jpg"
                          className="avatar rounded-circle"
                        />
                      </div>
                      <div className="col ml--2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0 text-sm">John Snow</h4>
                          </div>
                          <div className="text-right text-muted">
                            <small>5 hrs ago</small>
                          </div>
                        </div>
                        <p className="text-sm mb-0">Your posts have been liked a lot.</p>
                      </div>
                    </div>
                  </a>
                  <a href="#!" className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* Avatar */}
                        <img
                          alt="Image placeholder"
                          src="assets/img/theme/team-4.jpg"
                          className="avatar rounded-circle"
                        />
                      </div>
                      <div className="col ml--2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0 text-sm">John Snow</h4>
                          </div>
                          <div className="text-right text-muted">
                            <small>2 hrs ago</small>
                          </div>
                        </div>
                        <p className="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                      </div>
                    </div>
                  </a>
                  <a href="#!" className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        {/* Avatar */}
                        <FcAvatar
                          size="28"
                          textSizeRatio={2}
                          name={currentUser?.displayName || currentUser?.username}
                        />
                        {/* <img
                          alt="Image placeholder"
                          src="assets/img/theme/team-5.jpg"
                          className="avatar rounded-circle"
                        /> */}
                      </div>
                      <div className="col ml--2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="mb-0 text-sm">John Snow</h4>
                          </div>
                          <div className="text-right text-muted">
                            <small>3 hrs ago</small>
                          </div>
                        </div>
                        <p className="text-sm mb-0">A new issue has been reported for Argon.</p>
                      </div>
                    </div>
                  </a>
                </div>
                {/* View all */}
                <a
                  href="#!"
                  className="dropdown-item text-center text-primary font-weight-bold py-3"
                >
                  View all
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ni ni-ungroup"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-default  dropdown-menu-right ">
                <div className="row shortcuts px-4">
                  <a href="#!" className="col-4 shortcut-item">
                    <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                      <i className="ni ni-calendar-grid-58"></i>
                    </span>
                    <small>Calendar</small>
                  </a>
                  <a href="#!" className="col-4 shortcut-item">
                    <span className="shortcut-media avatar rounded-circle bg-gradient-orange">
                      <i className="ni ni-email-83"></i>
                    </span>
                    <small>Email</small>
                  </a>
                  <a href="#!" className="col-4 shortcut-item">
                    <span className="shortcut-media avatar rounded-circle bg-gradient-info">
                      <i className="ni ni-credit-card"></i>
                    </span>
                    <small>Payments</small>
                  </a>
                  <a href="#!" className="col-4 shortcut-item">
                    <span className="shortcut-media avatar rounded-circle bg-gradient-green">
                      <i className="ni ni-books"></i>
                    </span>
                    <small>Reports</small>
                  </a>
                  <a href="#!" className="col-4 shortcut-item">
                    <span className="shortcut-media avatar rounded-circle bg-gradient-purple">
                      <i className="ni ni-pin-3"></i>
                    </span>
                    <small>Maps</small>
                  </a>
                  <a href="#!" className="col-4 shortcut-item">
                    <span className="shortcut-media avatar rounded-circle bg-gradient-yellow">
                      <i className="ni ni-basket"></i>
                    </span>
                    <small>Shop</small>
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <Nav className="navbar-nav align-items-center ml-auto ml-md-0" as="ul">
            <Dropdown navbar>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                className="circle-user nav-link p-0"
                as="a"
              >
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <FcAvatar
                      size="36"
                      textSizeRatio={2}
                      color="#11cdef"
                      name={currentUser?.displayName || currentUser?.username}
                    />
                  </span>
                  <div className="media-body  ml-2  d-none d-lg-block">
                    <span className="mb-0 text-sm  font-weight-bold">{currentUser?.username}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-header-dropdown">
                <Dropdown.Item href="#/action-1">My account</Dropdown.Item>
                <Dropdown.Item href="/managers/about_me">About Me</Dropdown.Item>
                <Dropdown.Item href="#/action-2" onClick={handleLogOut}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </div>
    </nav>
  );
};

export default Header;
