import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
export const UserBoardWrapper = styled.div`
  padding: 0.5rem 1rem;
  height: 20vh;
  max-height: 200px;
  border-radius: 0.25rem;
  overflow-y: auto;
  border: 1px solid #dee2e6;
`;

export const ButtonRemove = styled(FontAwesomeIcon)`
  margin-left: 0.25rem;
  &:hover {
    cursor: pointer;
  }
`;

export const LoadingIcon = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
`;
