export const managerSidebarItems = [
  {
    name: 'Notifications/Inbox',
    href: '/managers/notifications',
    icon: 'fa-regular fa-envelope',
    colorClass: 'text-primary'
  },
  {
    name: 'Projects',
    href: '/managers/projects',
    icon: 'fa-solid fa-gears',
    colorClass: 'text-blue'
  },
  {
    name: 'Users',
    href: '/managers/users',
    icon: 'fa-solid fa-users',
    colorClass: 'text-orange'
  },
  {
    name: 'Schedule - Actual',
    href: '/managers/schedule_actual',
    icon: 'fa-regular fa-calendar-days',
    colorClass: 'text-info'
  },
  {
    name: 'Schedule - Fix',
    href: '/managers/schedule_fix',
    icon: 'fa-regular fa-calendar-plus',
    colorClass: 'text-red'
  },
  {
    name: 'Schedule - Availability',
    href: '/managers/schedule_availability',
    icon: 'fa-regular fa-calendar-check',
    colorClass: 'text-yellow'
  },
  {
    name: 'Moderation Tool',
    href: '/managers/moderation_tool',
    icon: 'fa-solid fa-wrench',
    colorClass: 'text-pink'
  },
  {
    name: 'Attendance',
    href: '/managers/attendance',
    icon: 'fa-regular fa-circle-check',
    colorClass: 'text-success'
  },
  {
    name: 'Report',
    href: '/managers/report',
    icon: 'fa-solid fa-chart-pie',
    colorClass: 'text-info'
  },
  {
    name: 'Shift Change Request',
    href: '/managers/shift_change',
    icon: 'fa-solid fa-user',
    colorClass: 'text-primary'
  }
];
