import styled from 'styled-components';

export const SchedulerWrapper = styled.div``;

export const SchedulerTableWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  margin-bottom: 1.25rem;
`;
export const SchedulerTable = styled.table`
  width: 100%;
  min-width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  color: #717783;
  text-align: center;
  & th,
  & td {
    border-bottom: 0.2px solid #f1f1f1;
    border-left: 0.2px solid #f1f1f1;
    background: #fff;
    vertical-align: top;
    width: 170px;
  }
  & th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    font-size: 0.85rem;
    color: #1b409d;
    font-weight: 600;
    width: 130px;
    box-shadow: 1px 2px 1px 0px #e7ecf6;
  }
  & td {
    padding: 4px 4px 8px 4px;
    &:last-child {
      border-right: none;
    }
  }
  & th {
    padding: 8px;
    background: #ecf1f9;
  }
`;

export const SchedulerHead = styled.thead`
  th {
    text-align: left;
    font-size: 0.85rem;
    color: #1b409d;
    font-weight: 600;
  }
`;

export const SchedulerBody = styled.tbody`
  & th {
    height: 120px;
    background: #fff;
    padding: 4px 4px 8px 1.5rem;
    
    & p {
      color: #717783;
      text-align: left;
      font-weight: 600;
      font-size: 0.85rem;
      letter-spacing: 0.2px;
      white-space: break-spaces;
      word-break: break-word;
      margin-bottom: 0;
    }

    & i {
      color: #717783;
    }

  & tr td:last-child {
    padding-right: 1.25rem !important;
  }
`;
