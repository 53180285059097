import { SpinnerButton } from 'components/Shared/';
import { AssignSlotValidatorHelper } from 'helpers';
import { ButtonTypes, Shift } from 'models';
import { Button } from 'react-bootstrap';
interface Props {
  formik: any;
  shifts: Shift[];
  shiftId: string;
  date: string;
  submittingType: string;
  setSubmittingType: (value: string) => void;
  setIsShowConfirmModal: (value: boolean) => void;
  setModalConfirmEmptyVisible: (value: boolean) => void;
  onHide: () => void;
}
const FormButtons = (props: Props) => {
  const {
    formik,
    shifts,
    shiftId,
    date,
    submittingType,
    setSubmittingType,
    setIsShowConfirmModal,
    setModalConfirmEmptyVisible,
    onHide
  } = { ...props };

  const validateForm = () => {
    const hasNullField = formik.values.employees.flat(1).filter((slot: any, agentIndex: number) => {
      return slot.userId.length === 0 || slot.duration.length === 0;
    });
    if (hasNullField.length > 0) {
      return [];
    }
    const res = formik.values.employees.filter((slot: any, agentIndex: number) => {
      return (
        AssignSlotValidatorHelper.isTotalHoursInvalid(agentIndex, shifts, shiftId, formik.values) ||
        AssignSlotValidatorHelper.hasInvalidAgent(agentIndex, shifts, date, formik.values)
      );
    });
    return res;
  };

  const onClick = (type: string) => {
    if (isExistingUnassignedSlot()) {
      setModalConfirmEmptyVisible(true);
      return;
    }
    setSubmittingType(type);
    validateForm().length === 0 ? formik.submitForm() : setIsShowConfirmModal(true);
  };

  const isExistingUnassignedSlot = () => {
    return formik.values.employees.some((slot: any) =>
      slot.some(
        (userSlot: any, index: number) =>
          !userSlot.userId || (userSlot.userId === 'multiple' && slot.length === 1)
      )
    );
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        onClick={onHide}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
      >
        Cancel
      </Button>
      <SpinnerButton
        btnType={ButtonTypes.Submit}
        colorType="success"
        disabled={formik.isSubmitting && submittingType === 'apply'}
        onClick={() => {
          onClick('apply');
        }}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
      >
        Apply
      </SpinnerButton>
      <SpinnerButton
        btnType={ButtonTypes.Submit}
        disabled={formik.isSubmitting && submittingType === 'submit'}
        onClick={() => {
          onClick('submit');
        }}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
      >
        Save
      </SpinnerButton>
    </>
  );
};

export default FormButtons;
