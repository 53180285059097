import apiService from '../utils/network';
import ApiService from '../utils/network';
import { API_URL } from './url';

export const projectApi = {
  getProjects() {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.PROJECT}`);
  },
  getProject(payload: any) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.payload}`);
  },
  createProject(payload: any) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.PROJECT}`, payload);
  },
  updateProject(payload: any) {
    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}`,
      payload.payload
    );
  },
  deleteProject(payload: string) {
    return ApiService.delete(`${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload}`);
  },
  getListOfAvailability(projectId: string) {
    return apiService.get(`/projects/${projectId}/availabilities`);
  },
  getShuffledAvailability(projectId: string) {
    return apiService.get(`/projects/${projectId}/shuffled-availabilities`);
  },
  getAvailabilityBreakdown(projectId: string) {
    return apiService.get(`/projects/${projectId}/availability-breakdown`);
  },
  agentClearAvailability(projectId: string) {
    return apiService.delete(`/projects/${projectId}/clear-availability`);
  }
};
