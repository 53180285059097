import { Nullable } from '../../utils/common/types';
import { GetShiftsPayload, Shift } from 'models';

export type ShiftInitialState = {
  shifts: Shift[];
  error: Nullable<string>;
  getShiftsPayload: Nullable<GetShiftsPayload>;
  isFetching: boolean;
  selectedShift: Shift | null;
  offVacationSlotShifts: Shift[];
};

export const initialState: ShiftInitialState = {
  shifts: [],
  error: null,
  getShiftsPayload: null,
  isFetching: false,
  selectedShift: null,
  offVacationSlotShifts: []
};
