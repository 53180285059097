import DateRangeCalendar from 'components/Report/hooks/DateRangeCalendar';
import PersonalFilter from 'components/Report/hooks/PersonalFilter';
import ProjectFilter from 'components/Report/hooks/ProjectFitler';
import UserFilter from 'components/Report/hooks/UserFilter';
import ButtonApply from 'components/Report/hooks/ButtonApply';
import ReportSorter from 'components/Report/hooks/ReportSorter';
import ReportTimesheetExport from 'components/Report/hooks/ReportTimesheetExport';
import { Button } from 'react-bootstrap';
import ReportTimesheetSaveFilter from 'components/Report/hooks/ReportTimesheetSaveFilter';
import UserTypeFilter from 'components/Report/hooks/UserTypeFilter';

const ReportTimesheetFilter = () => {
  return (
    <div className="px-4 py-3 d-flex justify-content-between">
      <div>
        <PersonalFilter />
        <ProjectFilter />
        <UserFilter />
        <UserTypeFilter />
        <ReportSorter />
        <DateRangeCalendar />
        <ButtonApply />
        <ReportTimesheetSaveFilter />
      </div>
      <div>
        <ReportTimesheetExport />
      </div>
    </div>
  );
};

export default ReportTimesheetFilter;
