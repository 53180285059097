import { AssignSlotValidatorHelper } from 'helpers/index';
import { useUserFilter } from 'hooks/scheduler/useUserFilter';
import { Shift, User } from 'models';
import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
interface Props {
  show: boolean;
  shifts: Shift[];
  shiftId: string;

  date: string;
  values: any;
  onHide: () => void;
  onSubmit: () => void;
}

enum ErrorTypes {
  EXCESS_HOURS = 'excess_hours' as any,
  NOT_ENOUGH_HOURS = 'not_enough_hours' as any,
  INVALID_AGENT = 'invalid_agent' as any
}

const ErrorMessages = {
  [ErrorTypes.EXCESS_HOURS]: 'Excess hours added:',
  [ErrorTypes.NOT_ENOUGH_HOURS]: 'Not enough hours added:',
  [ErrorTypes.INVALID_AGENT]: 'Agent with excess hours detected'
};

const ModalConfirm = (props: Props) => {
  const { users } = useUserFilter();
  const hasInvalidHours = (errorType: string) => {
    return errorType !== 'invalid_agent' && Object.values(errors[errorType as any]).length > 0;
  };

  const hasInvalidAgent = (errorType: string) => {
    return errorType === 'invalid_agent' && Object.values(errors[errorType as any]).length > 0;
  };

  const errors = useMemo(
    () => AssignSlotValidatorHelper.getErrors(props.shifts, { ...props, users: users }),
    [props.values]
  );
  return (
    <Modal
      className="modal-confirm"
      style={{
        zIndex: 100000
      }}
      show={props.show}
      onHide={props.onHide}
      size="sm"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-0">
        <>
          {Object.keys(errors).map((errorType: any, index: number) => (
            <React.Fragment key={index}>
              {hasInvalidHours(errorType) && (
                <div className="ml-2">
                  <span>
                    <strong data-testid={`${errorType}`}>{`- ${ErrorMessages[errorType]}`}:</strong>
                  </span>
                  <ul>
                    {Object.values(errors[errorType]).map((error: any, errIndex) => (
                      <li key={errIndex} data-testid={`${errorType}_${error}`}>
                        {error.join(', ')}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {hasInvalidAgent(errorType) && (
                <div className="ml-2">
                  <span>
                    <strong>{`- ${ErrorMessages[errorType]}`}:</strong>
                  </span>
                  <ul>
                    {Object.values(errors[errorType]).map((error: any, errIndex) => (
                      <React.Fragment key={errIndex}>
                        <strong data-testid={`invalid_agent_${Object.keys(error)}`}>
                          {Object.keys(error)}:
                        </strong>
                        <ul key={errIndex}>
                          {Object.values(error).map((err: any, errIndex: number) =>
                            err.map((e: any, eIndex: number) => (
                              <li
                                key={eIndex}
                                data-testid={`invalid_agent_${Object.keys(error)}_error`}
                              >
                                {e}
                              </li>
                            ))
                          )}
                        </ul>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              )}
            </React.Fragment>
          ))}
        </>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onHide} variant="secondary">
          No
        </Button>
        <Button onClick={props.onSubmit}>Yes, keep going</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirm;
