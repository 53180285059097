import { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { selectPending } from 'store/shiftChanges/shiftChange.selector';
import { BadgeCount } from './RequestTypeTab.styled';

const tabs = [
  {
    key: 'SWAP',
    value: 'Swap'
  },
  {
    key: 'COVER',
    value: 'Cover'
  },
  {
    key: 'REPLACE',
    value: 'Replace'
  },
  {
    key: 'MOVE',
    value: 'Move'
  }
];

export const RequestTypeTab = () => {
  const [selectedTab, setSelectedTab] = useState<string>('SWAP');

  const pending = useAppSelector(selectPending);

  const getPendingCount = (tabKey: string) => {
    if (pending === null) {
      return undefined;
    }
    switch (tabKey) {
      case 'SWAP':
        return pending.swap;
      case 'COVER':
        return pending.cover;
      case 'REPLACE':
        return pending.replace;
      case 'MOVE':
        return pending.move;
      default:
        return 0;
    }
  };

  const Tab = () => {
    return (
      <div className="d-flex justify-content-between">
        <ButtonGroup toggle className="mr-2">
          {tabs.map((tab) => (
            <ToggleButton
              key={tab.key}
              type="radio"
              variant="outline-primary"
              size="sm"
              name="radio"
              value={tab.key}
              checked={selectedTab === tab.key}
              onChange={(e) => setSelectedTab(tab.key)}
              style={{ lineHeight: 2 }}
              className="position-relative"
            >
              {getPendingCount(tab.key) !== undefined && (
                <BadgeCount>{getPendingCount(tab.key)}</BadgeCount>
              )}
              {tab.value}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    );
  };

  return { selectedTab, Tab };
};
