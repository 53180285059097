import { TimePicker } from 'components/Shared';
import { FormikProps } from 'formik';
import { FixedShift, ShiftChangeFormProps, User } from 'models';
import { Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Calendar } from 'react-date-range';

interface Props {
  formik: FormikProps<ShiftChangeFormProps>;
  projectUsers: User[];
  shifts: FixedShift[];
  shiftStartTime: string | undefined;
  shiftEndTime: string | undefined;
  onSelectAgent: (index: number, e: any) => void;
  onDateChange: (field: string, item: any) => void;
}

const ShiftCoverBodyForm = (props: Props) => {
  const {
    formik,
    projectUsers,
    shifts,
    shiftStartTime,
    shiftEndTime,
    onSelectAgent,
    onDateChange
  } = { ...props };
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`owner`}>
          <Form.Label className="required">Shift Owner</Form.Label>
          <Form.Control
            as="select"
            placeholder="Select Owner"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            name="owner"
            value={formik.values.owner}
            isInvalid={formik.touched.owner && !!formik.errors.owner}
          >
            <option value="">Select Owner</option>
            {projectUsers.map((user) => (
              <option key={user._id} value={user._id}>
                {user.username}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{formik.errors.owner}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId={`agent[0]`}>
          <Form.Label className="required">Corresponding Agent</Form.Label>
          <Form.Control
            as="select"
            placeholder="Select Agent"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              onSelectAgent(0, e);
            }}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            name="agent[0]"
            value={formik.values.agent[0]}
            isInvalid={
              (formik.touched.agent as any)?.[0] &&
              (formik.errors.agent?.[0] ? formik.errors.agent[0] : undefined) !== undefined
            }
          >
            <option value="">Select Agent</option>
            {projectUsers.map((user) => (
              <option key={user._id} value={user._id}>
                {user.username}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {formik.errors.agent?.[0] ? formik.errors.agent[0] : undefined}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`ownerShift`}>
          <Form.Label className="required">Shift</Form.Label>
          <Form.Control
            as="select"
            placeholder="Select Shift"
            onBlur={formik.handleBlur}
            // onChange={(e) => onChangeShift(e.target.value)}
            onChange={formik.handleChange}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            name="ownerShift"
            value={formik.values.ownerShift}
            isInvalid={formik.touched.ownerShift && !!formik.errors.ownerShift}
          >
            <option value="">Select Shift</option>
            {shifts.map((shift) => (
              <option key={shift._id} value={shift._id}>
                {shift.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{formik.errors.ownerShift}</Form.Control.Feedback>
        </Form.Group>
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="top-end"
          overlay={
            <Popover
              id="popover-basic"
              style={{ maxWidth: '600px' }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Popover.Content style={{ padding: 0 }}>
                <Calendar
                  onChange={(item) => onDateChange('ownerDate', item)}
                  // date={parse(date, 'yyyy-MM-dd', new Date())}
                />
              </Popover.Content>
            </Popover>
          }
        >
          <Form.Group as={Col} className="mb-3" controlId="ownerDate">
            <Form.Label className="text-start required">Date</Form.Label>
            <Form.Control
              type="text"
              placeholder="Date"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ownerDate}
              isInvalid={formik.touched.ownerDate && !!formik.errors.ownerDate}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.ownerDate}</Form.Control.Feedback>
          </Form.Group>
        </OverlayTrigger>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="ownerTime">
          <TimePicker
            formik={formik}
            fieldName={'ownerTime'}
            startTime={shiftStartTime}
            endTime={shiftEndTime}
          >
            <div
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Form.Label className="required">From Time</Form.Label>
              <Form.Control
                type="text"
                placeholder="From Time"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                onKeyDown={(e: any) => e.preventDefault()}
                name="ownerTime"
                value={props.formik.values.ownerTime}
                isInvalid={formik.touched.ownerTime && !!formik.errors.ownerTime}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ownerTime}
              </Form.Control.Feedback>
            </div>
          </TimePicker>
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="agentTime">
          <TimePicker
            formik={formik}
            fieldName={'agentTime'}
            startTime={shiftStartTime}
            endTime={shiftEndTime}
          >
            <div
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Form.Label className="required">To Time</Form.Label>
              <Form.Control
                type="text"
                placeholder="To Time"
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                onKeyDown={(e: any) => e.preventDefault()}
                name="agentTime"
                value={props.formik.values.agentTime}
                isInvalid={formik.touched.agentTime && !!formik.errors.agentTime}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.agentTime}
              </Form.Control.Feedback>
            </div>
          </TimePicker>
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default ShiftCoverBodyForm;
