import { Col, Row } from 'react-bootstrap';
import NewShiftChangeForm from '../../Actions/NewShiftChangeForm/NewShiftChangeForm';
import { useShiftChangeAgentView } from './useShiftChangeAgentView';

const ShiftChangeAgentView = () => {
  const {
    formik,
    projects,
    onChangeProject,
    projectUsers,
    onSelectAgent,
    shifts,
    onSelectAgentNewShift,
    onSelectAgentDate,
    onSelectAgentShift,
    onSelectAgentNewDate,
    onDateChange,
    scheduleGlancesOwner,
    scheduleGlancesAgent,
    glanceDateArr,
    shiftStartTime,
    shiftEndTime,
    selectedType,
    setSelectedType
  } = useShiftChangeAgentView();

  return (
    <Row>
      <Col xs={12} lg={8} xl={6}>
        <div className="justify-content-between">
          <NewShiftChangeForm
            formik={formik}
            projects={projects}
            projectUsers={projectUsers}
            shifts={shifts}
            scheduleGlancesOwner={scheduleGlancesOwner}
            scheduleGlancesAgent={scheduleGlancesAgent}
            glanceDateArr={glanceDateArr}
            onSelectAgent={onSelectAgent}
            onSelectAgentDate={onSelectAgentDate}
            onSelectAgentNewDate={onSelectAgentNewDate}
            onSelectAgentShift={onSelectAgentShift}
            onSelectAgentNewShift={onSelectAgentNewShift}
            onDateChange={onDateChange}
            onChangeProject={onChangeProject}
            shiftStartTime={shiftStartTime}
            shiftEndTime={shiftEndTime}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ShiftChangeAgentView;
