import { UserSameProject } from 'models';
import { Card, Table, Badge, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { userActions, userSelector } from 'store/users/user.slice';
import { useAuth } from 'hooks';
import { ButtonAction } from 'components/User/Actions/UserListItem/UserListItem.styled';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface Props {
  data: UserSameProject;
}

const UserListByAgent = (props: Props) => {
  const { data } = { ...props };
  const dispatch = useAppDispatch();
  const currentUser = useAuth();

  const actions = () => {
    return [
      {
        action: 'Reset Password',
        variant: 'primary',
        onClick: (e: any) => {
          dispatch(userActions.setSelectedUser(currentUser));
          dispatch(userActions.setIsModalResetPasswordVisible(true));
          e.stopPropagation();
        }
      }
    ];
  };
  return (
    <Card className="shadow">
      <Card.Header className="card-header border-0">
        <h3>{data.name}</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Table responsive style={{ tableLayout: 'fixed' }}>
          <thead className="thead-light">
            <tr>
              <th style={{ width: '100px' }}>E.Code</th>
              <th style={{ width: '110px' }}>Username</th>
              <th style={{ width: '100px' }}>D.Name</th>
              <th style={{ width: '160px' }}>Full Name</th>
              <th style={{ width: '120px' }}>Phone</th>
              <th style={{ width: '180px' }}>Work Email</th>
              <th style={{ width: '180px' }}>Personal Email</th>
              <th style={{ width: '150px' }}>Division</th>
              <th style={{ width: '150px' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.users.map((user) => (
              <tr key={user._id}>
                <td>{user.employeeCode}</td>
                <td>{user.username}</td>
                <td>{user.displayName}</td>
                <td>{user.fullName}</td>
                <td>{user.phone}</td>
                <td style={{ whiteSpace: 'break-spaces' }}>{user.email}</td>
                <td style={{ whiteSpace: 'break-spaces' }}>{user.personalEmail}</td>
                <td>
                  {data.divisions.map((division) => (
                    <Badge pill variant="primary" key={division._id}>
                      {division.name}
                    </Badge>
                  ))}
                </td>
                <td>
                  {user._id == currentUser._id && (
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Content className="p-0">
                            {actions().map((action) => (
                              <Dropdown.Item key={action.action} onClick={(e) => action.onClick(e)}>
                                <ButtonAction
                                  className={`text-${action.variant}`}
                                  onClick={(e) => action.onClick(e)}
                                >
                                  {action.action}
                                </ButtonAction>
                              </Dropdown.Item>
                            ))}
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Dropdown.Toggle
                        className="btn-icon-only text-light btn btn- btn-sm bg-transparent border-0 mr-0"
                        style={{ boxShadow: 'none' }}
                        bsPrefix="test"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </Dropdown.Toggle>
                    </OverlayTrigger>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default UserListByAgent;
