import { FcMentionInput } from 'components/Shared';
import { useAuth } from 'hooks';
import { Note, NoteStatus, User, UserRole } from 'models';
import { useEffect, useState } from 'react';
import { SuggestionDataItem } from 'react-mentions';
import { userApi } from 'services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectError,
  selectIsUpdating,
  selectNotes,
  selectUpdatingNoteId
} from 'store/notes/note.selector';
import { noteActions } from 'store/notes/note.slice';
import {
  ScheduleAvailabilityAction,
  ScheduleAvailabilityLink,
  ScheduleAvailabilitySendNote,
  ScheduleAvailabilityStar
} from './ScheduleAvailability.styled';
import { isNotEmittedStatement } from 'typescript';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  projectId: string | undefined;
  user: User;
}

const ScheduleAvailabilityNote = (props: Props) => {
  const { projectId, user } = { ...props };

  const dispatch = useAppDispatch();
  const currentUser = useAuth();
  const notes = useAppSelector(selectNotes);
  const updatingNoteId = useAppSelector(selectUpdatingNoteId);
  const error = useAppSelector(selectError);
  const isUpdating = useAppSelector(selectIsUpdating);

  const [comment, setComment] = useState('');
  const [userTags, setUserTags] = useState<string[]>([]);
  const [note, setNote] = useState<Note | undefined>(undefined);
  const [showDetail, setShowDetail] = useState(false);

  useDidMountEffect(() => {
    if (!isUpdating && !error && updatingNoteId === note?._id) {
      toast.success('Successfully Updated Note.');
    }
  }, [isUpdating]);

  useDidMountEffect(() => {
    if (!isUpdating && error && updatingNoteId === note?._id) {
      toast.error(error?.message || 'Something went wrong.');
    }
  }, [isUpdating]);

  useEffect(() => {
    const note = notes.find((note) => note.userNote[0]._id === user._id);
    if (note) {
      setComment(note.content);
      setNote(note);
    }
  }, []);

  const getUsers = async (query: string, callback: any) => {
    await userApi
      .getUsers({
        page: 1,
        perPage: 10,
        search: query
      })
      .then((res: any) => {
        return res.data
          .map((user: User) => user)
          .filter((user: User) => !userTags.includes(user._id));
      })
      // Transform the users to what react-mentions expects
      .then((res: User[]) => {
        return res.map(
          (user: User) =>
            ({ display: user?.displayName || user?.username, id: user._id } as SuggestionDataItem)
        );
      })
      .then(callback);
  };

  const sendNote = () => {
    if (!projectId) return;

    if (!note) {
      const payload = {
        projectId: projectId,
        content: comment,
        status: NoteStatus.NONE,
        userTags: userTags,
        userNote: currentUser._id
      };
      dispatch(noteActions.createNote(payload));
    } else {
      const payload = {
        noteId: note._id,
        status: note.status,
        content: comment
      };
      dispatch(noteActions.updateNote(payload));
    }
  };

  return (
    <>
      {currentUser._id === user._id && (
        <FcMentionInput
          value={comment}
          onChange={(newValue: string) => {
            setComment(newValue);
          }}
          selectedValue={userTags}
          onMention={(ids: string[]) => {
            setUserTags(ids);
          }}
          data={getUsers}
          margin={false}
          bordered={false}
        >
          {isUpdating ? (
            <Spinner animation="border" variant="primary" className="text-center" />
          ) : (
            <ScheduleAvailabilitySendNote className="fas fa-arrow-right" onClick={sendNote} />
          )}
        </FcMentionInput>
      )}
      {currentUser.role === UserRole.MANAGER && note && (
        <div className="p-1">
          <span>
            {showDetail && note.content.length > 60 ? note.content : note.content.slice(0, 60)}
          </span>
          {note.content.length > 60 && (
            <span
              className="text-primary font-weight-bold clickable"
              onClick={() => {
                setShowDetail(!showDetail);
              }}
            >
              {showDetail ? ' ...Show less' : ' ...Show more'}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default ScheduleAvailabilityNote;
