import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { filterActions, filterSelector } from 'store/filters/filter.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { filterSchema } from 'utils/validators';
import { useProjectFilter } from './useProjectFilter';
import { useUserFilter } from './useUserFilter';

interface FormProps {
  name: string;
}
export const useButtonSaveFilter = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useProjectFilter();
  const { selectedUsers } = useUserFilter();
  const isLoading = useAppSelector(filterSelector.selectIsLoadingFilters);
  const error = useAppSelector(filterSelector.selectError);
  const [isVisible, setIsVisible] = useState(false);

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: {
      name: ''
    },
    validationSchema: filterSchema,
    onSubmit: (values, actions) => {
      handleSubmit(values, actions);
    }
  });

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
      formik.setSubmitting(false);
    }
  }, [error]);
  useEffect(() => {
    if (error === null && !isLoading && formik.isSubmitting) {
      toast.success('Successfully Created.');
      formik.setSubmitting(false);
      formik.resetForm();
      setIsVisible(false);
    }
  }, [isLoading]);

  const handleSubmit = (values: FormProps, actions: FormikHelpers<FormProps>) => {
    dispatch(
      filterActions.createPersonalFilter({
        ...values,
        ...{
          project: selectedProject,
          employees: selectedUsers,
          divisions: []
        }
      })
    );
  };
  return { formik, isVisible, setIsVisible };
};
