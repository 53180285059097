import { Comment } from 'models';
import { Nullable } from '../../utils/common/types';
export type CommentInitialState = {
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: any;
};

export const initialState: CommentInitialState = {
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null
};
