import { DropdownAutoCloseOutside } from 'components/Shared';
import { Button, Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect, useState } from 'react';
import { filterSelector, filterActions } from 'store/filters/filter.slice';
import { FilterItem, FilterItemLabel } from 'hooks/scheduler/FilterDropdown.styled';
import { projectSelector } from 'store/projects/project.slice';
import { BtnRemovePersonalFilter } from 'components/Shared/ButtonRemove/BtnRemovePersonalFilter.styled';
import { toast } from 'react-toastify';

interface Props {
  [key: string]: unknown;
  setSelectedProjects: (projectIds: string[]) => void;
}

const ShiftChangePersonalFilter = (props: Props) => {
  const { setSelectedProjects } = { ...props };
  const dispatch = useAppDispatch();
  const [label, setLabel] = useState('Personal Filter');
  const [selectedFilter, setSelectedFilter] = useState('');
  const filters = useAppSelector(filterSelector.selectPersonalFilters);
  const projects = useAppSelector(projectSelector.selectProjects);
  const [activeFilter, setActiveFilter] = useState(filters);

  useEffect(() => {
    const filterId = localStorage.getItem('filterId');
    if (filterId) {
      onSelectFilter(filterId);
    }
  }, []);

  const onSelectFilter = (filterId: string) => {
    const filter = filters.find((f) => f._id === filterId);
    if (filter) {
      setSelectedFilter(filterId);
      setLabel(filter.name);
      setSelectedProjects(filter?.projects?.map((project) => project._id) || []);

      // const project = projects.find((p) => p._id === filter.project._id);
      // setSelectedFilter(filterId);
      // localStorage.setItem('filterId', filterId);
      // setLabel(filter.name);
      // if (project) {
      //   onSelectProject(null, project._id);
      //   const users: User[] = project.users || [];
      //   dispatch(userActions.setUsers(users));
      //   setSelectedUsers(filter.employees.map((user) => user._id));
      //   if (project.users.length === filter.employees.length) {
      //     setSelectedAll(true);
      //   } else {
      //     setSelectedAll(false);
      //   }
      // }
    }
  };

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(filters);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(filters);
    } else {
      const filtersList = filters.filter((f) =>
        f.name.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  const isLoading = useAppSelector(filterSelector.selectIsLoadingFilters);
  const error = useAppSelector(filterSelector.selectError);
  const [deletedFilter, setDeletedFilter] = useState<string[]>([]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
    }
  }, [error]);

  useEffect(() => {
    if (error === null && !isLoading && deletedFilter.length > 0) {
      toast.success('Successfully Deleted.');

      // Reset filter list from BE
      dispatch(filterActions.getPersonalFilters({ kind: 'SHIFTCHANGE' }));

      // Remove the deleted filter in FE for temporarily display
      const filtersList = filters.filter(function (filter, index) {
        return filter._id != deletedFilter[index];
      });
      setActiveFilter(filtersList);
    }
  }, [isLoading]);

  const onDeleteFilter = (event: any, filterId: string) => {
    dispatch(filterActions.deleteFilter({ filterId: filterId }));
    deletedFilter.push(filterId);
    setDeletedFilter(deletedFilter);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <DropdownAutoCloseOutside className="mr-2" {...props}>
      <Dropdown.Toggle size="sm" variant="outline-primary h-100" onMouseEnter={onSetFilter}>
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        <Dropdown.Item as="li">
          <FilterItem>
            <input
              type="text"
              onChange={(e) => onChangeFilter(e.target.value)}
              placeholder="Search Item"
            />
          </FilterItem>
        </Dropdown.Item>
        {activeFilter.length > 0 &&
          activeFilter.map((filter) => (
            <Dropdown.Item key={filter._id} as="li" onClick={(e) => onSelectFilter(filter._id)}>
              <FilterItem style={{ display: 'list-item' }}>
                <input
                  type="radio"
                  value={filter._id}
                  checked={selectedFilter === filter._id}
                  onChange={(e) => onSelectFilter(filter._id)}
                />
                <FilterItemLabel>{filter.name}</FilterItemLabel>
                <BtnRemovePersonalFilter
                  className="fa fa-trash"
                  onClick={(e) => onDeleteFilter(e, filter._id)}
                />
              </FilterItem>
            </Dropdown.Item>
          ))}
        {activeFilter.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};
export default ShiftChangePersonalFilter;
