import * as Yup from 'yup';

export const fixedSlotSchema = Yup.object().shape({
  weekday: Yup.number().required(),
  employees: Yup.array().of(
    Yup.array().of(
      Yup.object().shape({
        duration: Yup.string().when('userId', {
          is: (value: any) => value !== 'multiple',
          then: Yup.string()
            .required('Required')
            .test('min', 'Must larger than 1', (val) => !!(val && parseFloat(val) >= 1))
        })
      })
    )
  )
});
