import { Table } from 'react-bootstrap';
import NotificationList from './NotificationList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notificationActions, notificationSelector } from 'store/notifications/notification.slice';
import { useEffect } from 'react';
import { GetNotificationsPayload } from 'models';

const SystemNotification = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(notificationSelector.selectIsFetchingSystemNoti);
  const data = useAppSelector(notificationSelector.selectSystemNotifications);
  const paginationInfo = useAppSelector(notificationSelector.selectSystemNotisPaginationInfo);

  useEffect(() => {
    dispatch(
      notificationActions.getSystemNotifications({
        page: 1,
        perPage: 10,
        type: 'SYSTEM',
        orderBy: 'createdAt',
        order: 'desc'
      } as GetNotificationsPayload)
    );
  }, []);

  return (
    <>
      <NotificationList
        data={data}
        isLoading={isLoading}
        type="SYSTEM"
        paginationInfo={paginationInfo}
      />
    </>
  );
};

export default SystemNotification;
