import { Form, Col } from 'react-bootstrap';
import { Project, UserSlotFormProps } from 'models';
import { useUserFilter } from 'hooks/scheduler/useUserFilter';
import { useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';

interface Props {
  formik: any;
  agentIndex: number;
  userIndex: number;
  agentCount: any;
  project: Project | null;
}
const InputUserId = (props: Props) => {
  const { users } = useUserFilter();
  const { formik, agentIndex, userIndex, agentCount, project } = { ...props };
  const selectedProject = useAppSelector(projectSelector.selectSelectedProject);

  const getUserSlot = () => {
    const userId = formik.values.employees[agentIndex][userIndex];
    return formik.values.employees[agentIndex][userIndex];
  };

  const hasDivision = () => {
    return project && project.divisions.length > 0;
  };

  const getData = () => {
    try {
      if (
        !selectedProject?.divisions ||
        (selectedProject.divisions && !selectedProject?.divisions.length) ||
        formik.values.employees[agentIndex][0].divisionId === ''
      ) {
        return users;
      }

      const divisionId = formik.values.employees[agentIndex][0].divisionId;
      return users.filter((user) => {
        const project = user.projects.find(
          (userProject) => userProject.project._id === selectedProject._id
        );
        if (!project) return false;
        return project.divisions.some((division) => divisionId === division._id);
      });
    } catch {
      return users;
    }
  };

  const onChange = (e: any) => {
    if (userIndex > 0 || (userIndex === 0 && e.target.value === 'multiple')) {
      const fieldName = `employees[${agentIndex}][${userIndex}].userId`;
      const fieldValue = formik.values.employees[agentIndex][userIndex];
      const newValue = {
        ...fieldValue,
        userId: e.target.value
      };
      formik.setFieldValue(fieldName, newValue.userId);
    } else {
      formik.setFieldValue(`employees[${agentIndex}]`, [
        {
          ...formik.values.employees[agentIndex][0],
          userId: e.target.value
        }
      ]);
    }
  };

  return (
    <Form.Group
      as={Col}
      xs={hasDivision() ? 3 : 4}
      className={`mb-3 ${agentCount.length > 1 && userIndex > 0 ? 'pl-4' : ''}`}
      controlId={`agent-count-${userIndex}-user-Id`}
    >
      <Form.Control
        as="select"
        placeholder="Select Employeee"
        onBlur={formik.handleBlur}
        onChange={(event) => {
          onChange(event);
        }}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
        name={`employees[${agentIndex}][${userIndex}].userId`}
        value={getUserSlot().userId}
        isInvalid={
          formik.touched.employees?.[agentIndex]?.[userIndex]?.userId &&
          (formik.errors.employees?.[agentIndex]?.[userIndex]
            ? (formik.errors.employees[agentIndex][userIndex] as UserSlotFormProps)?.userId
            : undefined) !== undefined
        }
        custom
      >
        <option value="">Select Employee</option>
        {userIndex === 0 && <option value="multiple">Multiple Employees</option>}

        {getData().map((user: any) => (
          <option key={user._id} value={user._id}>
            {user.username}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {formik.errors.employees?.[agentIndex]?.[userIndex]
          ? (formik.errors.employees[agentIndex][userIndex] as UserSlotFormProps)?.userId
          : undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputUserId;
