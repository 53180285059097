import { Nullable } from '../../utils/common/types';
import { LoginStep, User } from 'models';

export type AuthInitialState = {
  isLoggedIn: boolean;
  currentUser: User | null;
  error: Nullable<string>;
  isFetching: boolean;
  isLoggingOut: boolean;
  accessToken: string;
  twoFactorEnabled: boolean;
  twoFactorCode: string;
  twoFactorSecretKey: string;
  twoFactorAccessToken: string;
  step: LoginStep;
};

export const initialState: AuthInitialState = {
  isLoggedIn: false,
  currentUser: null,
  error: null,
  isFetching: false,
  accessToken: '',
  twoFactorEnabled: false,
  twoFactorCode: '',
  twoFactorSecretKey: '',
  twoFactorAccessToken: '',
  step: LoginStep.LOGIN,
  isLoggingOut: false
};
