import { FcMentionInput } from 'components/Shared';
import { useAuth } from 'hooks';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { CommentStatus, CreateCommentPayload, User } from 'models';
import { useState } from 'react';
import { SuggestionDataItem } from 'react-mentions';
import { toast } from 'react-toastify';
import { userApi } from 'services';
import { commentActions, commentSelector } from 'store/comments/comment.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userSelector } from 'store/users/user.slice';
import { CommentAction, CommentInputWrapper, CommentLabel } from './Comment.styled';
interface Props {
  userId: string;
  shiftId: string;
  slotId: string;
  setVisible: (value: boolean) => void;
}

const Comment = (props: Props) => {
  const { userId, shiftId, slotId, setVisible } = { ...props };
  const dispatch = useAppDispatch();
  const currentUser = useAuth();
  const users = useAppSelector(userSelector.selectUsersModalAssign);
  const isCreating = useAppSelector(commentSelector.selectIsCreating);
  const error = useAppSelector(commentSelector.selectError);
  const [commentString, setCommentString] = useState('');
  const [userTags, setUserTags] = useState<string[]>([]);

  useDidMountEffect(() => {
    if (!isCreating && !error) {
      toast.success('Successfully Created Comment.');
      setCommentString('');
      setUserTags([]);
    }
  }, [isCreating]);

  useDidMountEffect(() => {
    if (!isCreating && error) {
      toast.error('Create Comment Failed.');
    }
  }, [error]);

  const onClear = () => {
    setCommentString('');
    setUserTags([]);
  };

  const onSendComment = () => {
    const payload: CreateCommentPayload = {
      shiftId: shiftId,
      slotId: slotId,
      content: commentString,
      status: CommentStatus.NONE,
      userComment: currentUser._id,
      userTags: userTags,
      userSlot: currentUser._id
    };
    dispatch(commentActions.createComment(payload));
  };

  const getUsers = async (query: string, callback: any) => {
    await userApi
      .getUsers({
        page: 1,
        perPage: 10,
        search: query
      })
      .then((res: any) => {
        return res.data
          .map((user: User) => user)
          .filter((user: User) => !userTags.includes(user._id));
      })
      // Transform the users to what react-mentions expects
      .then((res: User[]) => {
        return res.map(
          (user: User) =>
            ({ display: user?.displayName || user?.username, id: user._id } as SuggestionDataItem)
        );
      })
      .then(callback);
  };

  return (
    <>
      <CommentLabel>Comment</CommentLabel>
      <CommentInputWrapper>
        <FcMentionInput
          value={commentString}
          onChange={(newValue: string) => {
            setCommentString(newValue);
          }}
          selectedValue={userTags}
          onMention={(ids: string[]) => {
            setUserTags(ids);
          }}
          data={getUsers}
        />
      </CommentInputWrapper>
      <div className="text-right">
        <CommentAction className="mr-2" onClick={onClear}>
          <i className="fas fa-eraser" />
        </CommentAction>
        <CommentAction className="text-primary" onClick={onSendComment}>
          <i className="fas fa-paper-plane" />
        </CommentAction>
      </div>
    </>
  );
};

export default Comment;
