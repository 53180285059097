import ApiService from '../utils/network';
import { Login, TwoFactor } from '../models';
import { API_URL } from './url';

export const authApi = {
  login(login: Login) {
    return ApiService.post(`${API_URL.BASE_URL}/auth/login`, {
      username: login.username,
      password: login.password
    });
  },
  verifyTwoFactor(twoFactor: TwoFactor) {
    return ApiService.post(`${API_URL.BASE_URL}/auth/2fa/verify`, {
      verifyCode: twoFactor.verificationCode
    });
  },
  getCurrentUser() {
    return ApiService.get(`${API_URL.BASE_URL}/users/profile`);
  }
};
