import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './division.initialState';
import {
  CreateDivisionPayload,
  DeleteDivisionPayload,
  Division,
  GetDivisionsPayload,
  SetDivisionsModalAddUserPayload,
  UpdateDivisionPayload
} from 'models';

const divisionSlice = createSlice({
  name: 'divisions',
  initialState,
  reducers: {
    getDivisions: (state, { payload }: PayloadAction<GetDivisionsPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getDivisionsSuccess: (state, { payload }: PayloadAction<Division[]>) => {
      state.divisions = payload;
      state.isFetching = false;
      state.error = null;
    },
    getDivisionsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    setDivisionsModalAddUser: (
      state,
      { payload }: PayloadAction<SetDivisionsModalAddUserPayload>
    ) => {
      state.error = null;
    },
    setDivisionsModalAddUserSuccess: (state, { payload }: PayloadAction<any[]>) => {
      state.divisionsModalAddUser = payload;
      state.error = null;
    },
    setDivisionsModalAddUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    getMultiProjectDivisions: (state, { payload }: PayloadAction<string[]>) => {
      state.isFetchingMultiProjectDivisions = true;
      state.error = null;
    },
    getMultiProjectDivisionsSuccess: (state) => {
      state.isFetchingMultiProjectDivisions = false;
      state.error = null;
    },
    getMultiProjectDivisionsFailed: (state, { payload }: PayloadAction<unknown>) => {
      state.isFetchingMultiProjectDivisions = false;
      state.error = payload;
    },
    createDivision: (state, { payload }: PayloadAction<CreateDivisionPayload>) => {
      state.isCreating = true;
      state.error = null;
    },
    createDivisionSuccess: (state, { payload }: PayloadAction<Division>) => {
      state.isCreating = false;
      state.error = null;
    },
    createDivisionFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = false;
      state.error = payload;
    },
    updateDivision: (state, { payload }: PayloadAction<UpdateDivisionPayload>) => {
      state.isUpdating = true;
      state.error = null;
    },
    updateDivisionSuccess: (state) => {
      state.isUpdating = false;
      state.error = null;
    },
    updateDivisionFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    deleteDivision: (state, { payload }: PayloadAction<DeleteDivisionPayload>) => {
      state.isDeleting = true;
      state.error = null;
    },
    deleteDivisionSuccess: (state) => {
      state.isDeleting = false;
      state.error = null;
    },
    deleteDivisionFailed: (state, { payload }: PayloadAction<any>) => {
      state.isDeleting = false;
      state.error = payload;
    },
    setSelectedDivisions: (state, { payload }: PayloadAction<string[]>) => undefined,
    setSelectedDivisionsSuccess: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedDivisions = payload;
    },
    setSelectedAllDivisions: (state, { payload }: PayloadAction<boolean>) => undefined,
    setSelectedAllDivisionsSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.selectedAllDivisions = payload;
    }
  }
});

export const divisionActions = divisionSlice.actions;

export * as divisionSelector from './division.selector';

export default divisionSlice.reducer;
