import { DropdownAutoCloseOutside } from 'components/Shared';
import { FilterItem, FilterItemLabel } from 'hooks/scheduler/FilterDropdown.styled';
import { Project } from 'models';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { removeSimpleEl } from 'utils/formatters/array';

interface Props {
  selectedProjects: string[];
  setSelectedProjects: (projectIds: string[]) => void;
}
const ShiftChangeProjectFilter = (props: Props) => {
  const { selectedProjects, setSelectedProjects } = { ...props };
  const projects = useAppSelector(projectSelector.selectProjects);
  const [activeFilter, setActiveFilter] = useState(projects);

  const onSelectProject = (e: any, projectId: string) => {
    if (e.target.checked) {
      const projectIds = [...selectedProjects];
      projectIds.push(projectId);
      setSelectedProjects(projectIds);
    } else {
      setSelectedProjects(removeSimpleEl(selectedProjects, projectId));
    }
  };

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(projects);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(projects);
    } else {
      const filtersList = projects.filter((f) =>
        f.name.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  return (
    <>
      <DropdownAutoCloseOutside className="mr-2">
        <Dropdown.Toggle size="sm" variant="outline-primary h-100" onMouseEnter={onSetFilter}>
          <span> {selectedProjects.length > 0 ? selectedProjects.length : ''} Projects</span>
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul">
          <Dropdown.Item as="li">
            <FilterItem>
              <input
                type="text"
                onChange={(e) => onChangeFilter(e.target.value)}
                placeholder="Search Item"
              />
            </FilterItem>
          </Dropdown.Item>
          {activeFilter.length > 0 &&
            activeFilter.map((prj) => (
              <Dropdown.Item key={prj._id} as="li" onClick={(e) => onSelectProject(e, prj._id)}>
                <FilterItem>
                  <input
                    type="checkbox"
                    value={prj._id}
                    checked={selectedProjects.includes(prj._id)}
                    onChange={(e) => onSelectProject(e, prj._id)}
                  />
                  <FilterItemLabel>{prj.name}</FilterItemLabel>
                </FilterItem>
              </Dropdown.Item>
            ))}
          {activeFilter.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
        </Dropdown.Menu>
      </DropdownAutoCloseOutside>
    </>
  );
};

export default ShiftChangeProjectFilter;
