import { Comment, Filter, SalaryPeriod } from 'models';
import { Nullable } from '../../utils/common/types';
import { Report, ReportDashboardData } from 'models/report.model';

export type FilterInitialState = {
  isFetching: boolean;
  personalFilters: Filter[];
  isLoadingFilters: boolean;
  error: any;
};

export const initialState: FilterInitialState = {
  isFetching: false,
  personalFilters: [],
  isLoadingFilters: false,
  error: null
};
