import { Division, Project, UserSlotFormProps } from 'models';
import { Col, Form } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';

interface Props {
  formik: any;
  agentIndex: number;
  userIndex: number;
  agentCount: any;
  project: Project;
}

const InputDivision = (props: Props) => {
  const { formik, agentIndex, userIndex, agentCount, project } = { ...props };

  const getValue = () => {
    return formik.values.employees[agentIndex][0].divisionId;
  };

  const onChangeDivision = (e: any) => {
    const fieldName = `employees[${agentIndex}][${userIndex}].divisionId`;
    const fieldValue = formik.values.employees[agentIndex][userIndex];
    const newValue = {
      ...fieldValue,
      divisionId: e.target.value
    };
    formik.setFieldValue(fieldName, newValue.divisionId);
    formik.values.employees[agentIndex].forEach((userSlot: any, index: number) => {
      formik.setFieldValue(`employees[${agentIndex}][${index}].divisionId`, newValue.divisionId);
      handleUserId(index, e.target.value);
    });
  };

  const handleUserId = (index: number, divisionId: string) => {
    const userId = formik.values.employees[agentIndex][index].userId;

    if (!divisionId || userId === 'multiple') return;

    const user = project.users.find((user) => user._id === userId);

    if (!user) resetUserId(index);
    const isAssignedToCurrentDivision = user?.projects.some((project) =>
      project.divisions.some((division) => division._id === divisionId)
    );

    if (!isAssignedToCurrentDivision) resetUserId(index);
  };

  const resetUserId = (index: number) => {
    formik.setFieldValue(`employees[${agentIndex}][${index}].userId`, '');
  };

  const getData = () => {
    const userId = formik.values.employees[agentIndex][userIndex].userId;
    const user = project.users.find((user) => user._id === userId);

    if (!userId || userId === 'multiple') return project.divisions;

    if (!user) return [];

    const resArr = project.divisions.filter((d) => {
      const res = user.projects.some((project) =>
        project.divisions.some((division) => division._id === d._id)
      );
      return res;
    });

    return resArr;
  };

  return (
    <Form.Group as={Col} xs={2} className="mb-3" controlId={`agent-count-${userIndex}-division-Id`}>
      <Form.Control
        as="select"
        placeholder="Select Division"
        onBlur={formik.handleBlur}
        onChange={(event) => {
          onChangeDivision(event);
        }}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
        disabled={userIndex === 0 ? false : true}
        name={`employees[${agentIndex}][${userIndex}].divisionId`}
        value={getValue()}
        isInvalid={
          formik.touched.employees?.[agentIndex]?.[userIndex]?.divisionId &&
          (formik.errors.employees?.[agentIndex]?.[userIndex]
            ? (formik.errors.employees[agentIndex][userIndex] as UserSlotFormProps)?.divisionId
            : undefined) !== undefined
        }
        custom
      >
        <option value="">Select Division</option>
        {getData().map((division: Division) => (
          <option key={division._id} value={division._id}>
            {division.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {formik.errors.employees?.[agentIndex]?.[userIndex]
          ? (formik.errors.employees[agentIndex][userIndex] as UserSlotFormProps)?.divisionId
          : undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default InputDivision;
