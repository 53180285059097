import { DropdownAutoCloseOutside } from 'components/Shared';
import { User } from 'models';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { userActions } from 'store/users/user.slice';
import {
  HighlighterPickerIcon,
  HighlighterPickerInput,
  HighlighterPickerItem,
  HighlighterSelectInput
} from './HighlighterPicker.styled';
import { useUserFilter } from 'hooks/scheduler/useUserFilter';
import { useHighlighter } from './useHighlighter';

const HighlighterPicker = () => {
  const { users, selectedUsers } = useUserFilter();
  const {
    allColor,
    selectedUsersColor,
    pickedUsers,
    DEFAULT_COLOR,
    onReset,
    onSelectColor,
    onSelectColorAll,
    onClick,
    onSelectUser,
    onChangeSelectedUsersColor
  } = useHighlighter();

  return (
    <DropdownAutoCloseOutside className="mr-2">
      <Dropdown.Toggle size="sm" variant="outline-primary">
        Highlighter
      </Dropdown.Toggle>

      <Dropdown.Menu as="ul">
        <Dropdown.Item
          as="li"
          style={{ paddingLeft: '2rem', cursor: 'pointer' }}
          onClick={() => onReset()}
        >
          <HighlighterPickerItem>
            <HighlighterPickerIcon className="fa-solid fa-arrows-rotate" />
            <div>
              <strong className="text-primary">Reset</strong>
            </div>
          </HighlighterPickerItem>
        </Dropdown.Item>
        <Dropdown.Item as="li" style={{ paddingLeft: '2rem', cursor: 'pointer' }}>
          <HighlighterPickerItem>
            <HighlighterPickerIcon className="fa-solid fa-arrow-right-long" />
            All Employee
            <HighlighterPickerInput
              type="color"
              value={allColor}
              onChange={(e: any) => {
                onSelectColorAll(e);
              }}
            />
          </HighlighterPickerItem>
        </Dropdown.Item>
        <Dropdown.Item as="li" style={{ paddingLeft: '2rem', cursor: 'pointer' }}>
          <HighlighterPickerItem>
            <HighlighterPickerIcon className="fa-solid fa-arrow-right-long" />
            Selected Employee
            <HighlighterPickerInput
              type="color"
              value={selectedUsersColor}
              onChange={(e: any) => {
                onChangeSelectedUsersColor(e);
              }}
            />
          </HighlighterPickerItem>
        </Dropdown.Item>
        {users.map(
          (user: any, index: any) =>
            selectedUsers.includes(user._id) && (
              <Dropdown.Item
                as="li"
                key={index}
                onClick={(e) => onClick(e, user)}
                style={{ paddingLeft: '2rem', cursor: 'pointer' }}
              >
                <HighlighterPickerItem>
                  <HighlighterSelectInput
                    className="form-control"
                    value={user._id}
                    checked={pickedUsers.includes(user._id)}
                    onChange={(e: any) => {
                      onSelectUser(e, user);
                    }}
                  />
                  {user.username}
                  <div className="">
                    <HighlighterPickerInput
                      type="color"
                      value={user.color ? user.color : DEFAULT_COLOR}
                      onChange={(e: any) => {
                        onSelectColor(e, user);
                      }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </HighlighterPickerItem>
              </Dropdown.Item>
            )
        )}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};

export default HighlighterPicker;
