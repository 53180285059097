import { useAuth } from 'hooks';
import { Card } from 'react-bootstrap';
import { ShiftChangeTab } from './Actions/ShiftChangeTab/ShiftChangeTab';
import ShiftChangeAgentView from './MainActions/ShiftChangeAgentView/ShiftChangeAgentView';
import ShiftChangeManagerView from './MainActions/ShiftChangeManagerView/ShiftChangeManagerView';
import OffVacationRequest from './MainActions/OffVacationRequest/OffVacationRequest';
import OffVacationRequestManagerView from './MainActions/OffVacationRequestManagerView/OffVacationRequestManagerView';
import RequestLog from './MainActions/RequestLog/RequestLog';

const ShiftChange = () => {
  const { selectedTab, Tab } = ShiftChangeTab();
  const currentUser = useAuth();
  return (
    <Card className="shadow">
      <Card.Header className="border-0">
        <Tab />
      </Card.Header>

      <Card.Body>
        {selectedTab === 'ShiftChangeRequest' && (
          <>
            {currentUser.role === 'AGENT' ? <ShiftChangeAgentView /> : <ShiftChangeManagerView />}
          </>
        )}
        {selectedTab === 'OffVacationRequest' && (
          <>
            {currentUser.role === 'AGENT' ? (
              <OffVacationRequest />
            ) : (
              <OffVacationRequestManagerView />
            )}
          </>
        )}
        {selectedTab === 'RequestLogs' && currentUser.role === 'MANAGER' && <RequestLog />}
      </Card.Body>
    </Card>
  );
};

export default ShiftChange;
