import { DropdownAutoCloseOutside } from 'components/Shared';
import { Project } from 'models';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from './FilterDropdown.styled';
import { useProjectFilter } from './useProjectFilter';
import { useState } from 'react';

const ProjectFilter = () => {
  const { projects, selectedProject, projectLabel, onSelectProject } = useProjectFilter();
  const [activeFilter, setActiveFilter] = useState(projects);

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(projects);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(projects);
    } else {
      const filtersList = projects.filter((f) =>
        f.name.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  return (
    <>
      <DropdownAutoCloseOutside className="mr-2">
        <Dropdown.Toggle size="sm" variant="outline-primary" onMouseEnter={onSetFilter}>
          {projectLabel}
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul">
          <Dropdown.Item as="li">
            <FilterItem>
              <input
                type="text"
                onChange={(e) => onChangeFilter(e.target.value)}
                placeholder="Search Item"
              />
            </FilterItem>
          </Dropdown.Item>
          {activeFilter.length > 0 &&
            activeFilter.map((prj) => (
              <Dropdown.Item key={prj._id} as="li" onClick={(e) => onSelectProject(e, prj._id)}>
                <FilterItem>
                  <input
                    type="radio"
                    value={prj._id}
                    checked={selectedProject === prj._id}
                    onChange={(e) => onSelectProject(e, prj._id)}
                  />
                  <FilterItemLabel>{prj.name}</FilterItemLabel>
                </FilterItem>
              </Dropdown.Item>
            ))}
          {activeFilter.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
        </Dropdown.Menu>
      </DropdownAutoCloseOutside>
    </>
  );
};
export default ProjectFilter;
