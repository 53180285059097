import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './report.initialState';
import { GetReportsPayload, ReportDashboardData } from 'models/report.model';

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setSelectedDateRange: (state, { payload }: PayloadAction<Date[]>) => {
      state.error = null;
    },
    setSelectedDateRangeSuccess: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedDateRange = payload;
      state.error = null;
    },
    setSelectedDateRangeFailed: (state, { payload }: PayloadAction<any>) => {
      state.selectedDateRange = [];
      state.error = payload;
    },
    getReports: (state, { payload }: PayloadAction<GetReportsPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getReportsSuccess: (state, { payload }: PayloadAction<any>) => {
      state.reports = payload;
      state.error = null;
    },
    getReportsFailed: (state, { payload }: PayloadAction<any>) => {
      state.reports = [];
      state.error = payload;
    },
    getDashboardData: (state, { payload }: PayloadAction<GetReportsPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getDashboardDataSuccess: (state, { payload }: PayloadAction<ReportDashboardData>) => {
      state.dashboardData = payload;
      state.error = null;
    },
    getDashboardDataFailed: (state, { payload }: PayloadAction<any>) => {
      state.dashboardData = undefined;
      state.error = payload;
    },
    setSortString: (state, { payload }: PayloadAction<string>) => undefined,
    setSortStringSuccess: (state, { payload }: PayloadAction<string>) => {
      {
        state.sortString = payload;
      }
    },
    setUserType: (state, { payload }: PayloadAction<string>) => undefined,
    setUserTypeSuccess: (state, { payload }: PayloadAction<string>) => {
      {
        state.userType = payload;
      }
    },
    exportTimesheet: (state, { payload }: PayloadAction<GetReportsPayload>) => {
      state.isExporting = true;
      state.error = null;
    },
    exportTimesheetSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isExporting = false;
      state.error = null;
    },
    exportTimesheetFailed: (state, { payload }: PayloadAction<any>) => {
      state.isExporting = false;
      state.error = payload;
    },
    getPersonalFilters: (state, { payload }: PayloadAction<{ kind: string }>) => undefined,
    getPersonalFiltersSuccess: (state, { payload }: PayloadAction<any>) => {
      state.personalFilters = payload;
      state.error = null;
    },
    getPersonalFiltersFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    createPersonalFilter: (state, { payload }: PayloadAction<any>) => {
      state.isCreatingFilter = true;
      state.error = null;
    },
    createPersonalFilterSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isCreatingFilter = false;
      state.personalFilters = payload;
      state.error = null;
    },
    createPersonalFilterFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCreatingFilter = false;
      state.error = payload;
    }
  }
});

export const reportActions = reportSlice.actions;

export * as reportSelector from './report.selector';

export default reportSlice.reducer;
