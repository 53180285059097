import { SpinnerButton } from 'components/Shared';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';
import { ButtonTypes, User } from 'models';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';
import { useDeleteUser } from './useDeleteUser';
interface Props {
  user: User;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
}
const DeleteUser = () => {
  const { isDeleting, isVisible, user, onHide, onDelete } = useDeleteUser();

  return (
    <>
      <Modal
        className="modal-confirm handle"
        show={isVisible}
        onHide={onHide}
        size="lg"
        centered
        scrollable={true}
        dialogAs={DraggableModalDialog}
        backdrop={'static'}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Delete User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete{' '}
            <span>
              <strong>{user.username}</strong>
            </span>
            ?
          </p>
          <span></span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onHide}>
            Cancel
          </Button>
          <SpinnerButton btnType={ButtonTypes.Submit} disabled={isDeleting} onClick={onDelete}>
            Yes
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteUser;
