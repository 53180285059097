import { PayloadAction } from '@reduxjs/toolkit';
import { GetReportsPayload, Report } from 'models/report.model';
import { call, put, takeLatest } from 'redux-saga/effects';
import { salaryPeriodApi } from 'services/salaryPeriod.api';
import { salaryPeriodActions } from './salaryPeriod.slice';
import { SalaryPeriod } from 'models';

export function* getCurrentPeriodWorker() {
  try {
    const response: SalaryPeriod = yield call(salaryPeriodApi.getCurrentPeriod);
    yield put(salaryPeriodActions.getCurrentPeriodSuccess(response));
  } catch (e) {
    console.log('getReportsWorker Error: ', e);
    yield put(salaryPeriodActions.getCurrentPeriodFailed(e));
  }
}

export default function* salaryPeriodSaga() {
  yield takeLatest(salaryPeriodActions.getCurrentPeriod, getCurrentPeriodWorker);
}
