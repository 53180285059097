import {
  CreateDivisionPayload,
  DeleteDivisionPayload,
  GetDivisionsPayload,
  UpdateDivisionPayload
} from 'models';
import ApiService from '../utils/network';
import { API_URL } from './url';

export const divisionApi = {
  getDivisions(payload: GetDivisionsPayload) {
    return ApiService.get(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/${API_URL.DIVISION}`
    );
  },
  createDivision(payload: CreateDivisionPayload) {
    return ApiService.post(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/${API_URL.DIVISION}`,
      {
        name: payload.name
      }
    );
  },
  updateDivision({ name, color, projectId, divisionId }: UpdateDivisionPayload) {
    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${projectId}/${API_URL.DIVISION}/${divisionId}`,
      {
        name: name,
        color: color
      }
    );
  },
  deleteDivision(payload: DeleteDivisionPayload) {
    return ApiService.delete(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/${API_URL.DIVISION}/${payload.divisionId}`
    );
  }
};
