import { PayloadAction } from '@reduxjs/toolkit';
import {
  OffVacation,
  OffVacationRequestFormProps,
  OffVacationPayload,
  OffVacationResponse,
  OffVacationIdPayload,
  GetOffVacationRequestLogResponse
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getOffVacation,
  getOffVacationSuccess,
  getOffVacationFailed,
  // getOnHoldData,
  // getOnHoldDataSuccess,
  // getOnHoldDataFailed,
  createOffVacationSuccess,
  createOffVacationFailed,
  createOffVacation,
  updateOffVacation,
  updateOffVacationSuccess,
  updateOffVacationFailed,
  getOffVacationById,
  getOffVacationByIdSuccess,
  getOffVacationByIdFailed,
  getOffVacationApprovedRequestLog,
  getOffVacationRejectedRequestLog,
  getOffVacationApprovedRequestLogSuccess,
  getOffVacationApprovedRequestLogFailed,
  getOffVacationRejectedRequestLogSuccess,
  getOffVacationRejectedRequestLogFailed,
  setOffVacationApprovedRequestLogPaginationInfoSuccess,
  setOffVacationRejectedRequestLogPaginationInfoSuccess
} from './offVacation.slice';
import { offVacationApi } from 'services';

export function* getOffVacationWorker({ payload }: PayloadAction<OffVacationPayload>) {
  try {
    const response: OffVacationResponse = yield call(offVacationApi.getOffVacation, payload);
    yield put(getOffVacationSuccess(response));
  } catch (e) {
    console.log('Get OffVacation err: ', e);
    yield put(getOffVacationFailed(e));
  }
}

export function* createOffVacationWorker({ payload }: PayloadAction<OffVacationRequestFormProps>) {
  try {
    const response: OffVacation = yield call(offVacationApi.createOffVacation, payload);
    yield put(createOffVacationSuccess(response));
  } catch (e) {
    console.log('ShiftChangeFailed err: ', e);
    yield put(createOffVacationFailed(e));
  }
}

export function* updateOffVacationWorker({ payload }: PayloadAction<OffVacation>) {
  try {
    const response: OffVacation = yield call(offVacationApi.updateOffVacation, payload);
    yield put(updateOffVacationSuccess(response));
  } catch (e) {
    console.log('updateOffVacation err: ', e);
    yield put(updateOffVacationFailed(e));
  }
}

export function* getOffVacationByIdWorker({ payload }: PayloadAction<OffVacationIdPayload>) {
  try {
    const response: OffVacationResponse = yield call(offVacationApi.getOffVacationById, payload);
    yield put(getOffVacationByIdSuccess(response));
  } catch (e) {
    console.log('Get OffVacation err: ', e);
    yield put(getOffVacationByIdFailed(e));
  }
}

export function* getOffVacationApprovedRequestLogWorker({
  payload
}: PayloadAction<OffVacationPayload>) {
  try {
    const response: GetOffVacationRequestLogResponse = yield call(
      offVacationApi.getOffVacation,
      payload
    );
    yield put(getOffVacationApprovedRequestLogSuccess(response));
    yield put(setOffVacationApprovedRequestLogPaginationInfoSuccess(response.pagination));
  } catch (e) {
    console.log('OffVacationApprovedRequestLogFailed err: ', e);
    yield put(getOffVacationApprovedRequestLogFailed(e));
  }
}

export function* getOffVacationRejectedRequestLogWorker({
  payload
}: PayloadAction<OffVacationPayload>) {
  try {
    const response: GetOffVacationRequestLogResponse = yield call(
      offVacationApi.getOffVacation,
      payload
    );
    yield put(getOffVacationRejectedRequestLogSuccess(response));
    yield put(setOffVacationRejectedRequestLogPaginationInfoSuccess(response.pagination));
  } catch (e) {
    console.log('OffVacationRejectedRequestLogFailed err: ', e);
    yield put(getOffVacationRejectedRequestLogFailed(e));
  }
}

export default function* shiftSaga() {
  yield takeLatest(getOffVacation, getOffVacationWorker);
  yield takeLatest(createOffVacation, createOffVacationWorker);
  yield takeLatest(updateOffVacation, updateOffVacationWorker);
  yield takeLatest(getOffVacationById, getOffVacationByIdWorker);
  yield takeLatest(getOffVacationApprovedRequestLog, getOffVacationApprovedRequestLogWorker);
  yield takeLatest(getOffVacationRejectedRequestLog, getOffVacationRejectedRequestLogWorker);
}
