/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
export const API_URL = {
  BASE_URL: process.env.REACT_APP_API_URL,

  // Namespaces
  PROJECT: 'projects',
  SHIFT: 'shifts',
  FILTER: 'filters',
  SLOTS: 'slots',
  FIXED_SLOTS: 'fixed-slots',
  USER: 'users',
  DIVISION: 'divisions',
  ATTENDANCE: 'attendances',
  COMMENT: 'comments',
  NOTIFICATION: 'notifications',
  NOTE: 'notes',
  SHIFTCHANGE: 'shift-change-request',
  SCHEDULEGLANCE: 'schedule-glance',
  OFF_VACATION: 'vacation-request',
  OFF_VACATION2: 'off-vacation'
};
