import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './slot.initialState';
import { CreateSlotPayload } from 'models';

const slotSlice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    createSlot: (state, { payload }: PayloadAction<CreateSlotPayload>) => {
      state.isLoading = true;
      state.error = null;
    },
    createSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
    },
    createSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    },
    updateSlot: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = true;
      state.error = null;
    },
    updateSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = null;
    },
    updateSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    deleteSlot: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    },
    deleteUserSlot: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteUserSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteUserSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export const slotActions = slotSlice.actions;

export * as slotSelector from './slot.selector';

export default slotSlice.reducer;
