import { RequestLog } from 'models';
import { Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';

interface Props {
  data: RequestLog;
}

const ShiftChangeRequestLogMoveDetail = (props: Props) => {
  const { data } = {
    ...props
  };
  const getDate = (value: string) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`owner`}>
          <Form.Label className="required">Shift Owner</Form.Label>
          <Form.Control type="text" value={data.owner[0].displayName} disabled />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`ownerShift`}>
          <Form.Label className="required">Current Shift</Form.Label>
          <Form.Control type="text" value={data.ownerShift[0].name} disabled />
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="ownerDate">
          <Form.Label className="text-start required">Date effect</Form.Label>
          <Form.Control type="text" value={getDate(data.ownerDate)} disabled />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`agentShift[0]`}>
          <Form.Label className="required">New Shift</Form.Label>
          <Form.Control type="text" value={data.agentShift[0].name} disabled />
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="agentDate[0]">
          <Form.Label className="text-start required">Date effect</Form.Label>
          <Form.Control type="text" value={getDate(data.agentDate[0])} disabled />
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default ShiftChangeRequestLogMoveDetail;
