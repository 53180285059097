import { PayloadAction } from '@reduxjs/toolkit';
import { getDivisionId } from 'helpers/division.helper';
import { SlotHelper, findUserSlotsOf, getNewShifts } from 'helpers/slot.helper';
import cloneDeep from 'lodash.clonedeep';
import { CreateFixedSlotPayload, Division, DragAndDropParams, FixedShift, UserSlot } from 'models';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fixedShiftApi, fixedSlotApi } from 'services';
import { fixedShiftActions, fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';
import { fixedSlotActions } from './fixedSlot.slice';

interface HandleUpdatePayload {
  droppedItem: DragAndDropParams;
  draggingItem: DragAndDropParams;
  droppedUserSlots: UserSlot[];
  draggingUserSlots: UserSlot[];
  index: number;
}

const updateDragAndDrop = (payload: HandleUpdatePayload) => {
  const { droppedItem, draggingItem, droppedUserSlots, draggingUserSlots } = { ...payload };
  const { slotId: dragSlotId, shiftId: dragShiftId } = { ...draggingItem };
  const { slotId: dropSlotId, shiftId: dropShiftId } = { ...droppedItem };
  return [
    call(fixedSlotApi.bulkUpdate, {
      slotIds: [dragSlotId, dropSlotId],
      shiftIds: [dragShiftId, dropShiftId],
      userSlots: [draggingUserSlots, droppedUserSlots],
      division: [getDivisionId(draggingItem.division), getDivisionId(droppedItem.division)]
    })
  ];
};

const updateSlotFunc = (
  shiftId: string,
  slotId: string,
  userSlots: UserSlot[],
  division: Division | string | undefined | Division[]
) => {
  const payload = {
    shiftId,
    slotId,
    userSlots,
    division: getDivisionId(division)
  };
  return call(fixedSlotApi.updateSlot, payload);
};

function* handleReplaceOrMerge(payload: HandleUpdatePayload) {
  const { droppedItem, draggingItem, droppedUserSlots, draggingUserSlots, index } = { ...payload };

  if (draggingUserSlots?.length === 0) {
    const { shiftId, slotId, division } = { ...droppedItem };
    yield all([updateSlotFunc(shiftId, slotId, droppedUserSlots, division)]);
    yield call(fixedSlotApi.deleteSlot, {
      shiftId: draggingItem.shiftId,
      slotId: draggingItem.slotId
    });
  } else {
    yield all(updateDragAndDrop(payload));
  }
}

function* handlePaste(payload: HandleUpdatePayload) {
  const { droppedItem, droppedUserSlots } = { ...payload };
  const { shiftId, slotId }: any = { ...droppedItem };
  const dropDivision = droppedItem?.division?._id || droppedItem?.division || undefined;
  yield all([updateSlotFunc(shiftId, slotId, droppedUserSlots, dropDivision)]);
}

function* handleCreate(payload: HandleUpdatePayload) {
  const { droppedItem, draggingItem, droppedUserSlots, draggingUserSlots, index } = { ...payload };
  const { shiftId: dragShiftId, slotId: dragSlotId }: DragAndDropParams = { ...draggingItem };
  const dragDivision = getDivisionId(draggingItem?.division);

  if (draggingUserSlots.length === 0) {
    const payload = { shiftId: dragShiftId, slotId: dragSlotId };
    yield call(fixedSlotApi.deleteSlot, payload);
  } else {
    yield all([updateSlotFunc(dragShiftId, dragSlotId, draggingUserSlots, dragDivision)]);
  }

  const { shiftId, weekday }: any = { ...droppedItem };
  const newPayload = {
    shiftId: shiftId,
    payload: {
      division: [dragDivision],
      weekday: weekday,
      employees: [droppedUserSlots],
      index: index
    }
  };
  const response: { projectId: string; shiftId: string } = yield call(
    fixedSlotApi.createSlot,
    newPayload
  );
  return response;
}

function* handleDuplicate(params: HandleUpdatePayload) {
  const { droppedItem, draggingItem, droppedUserSlots, index } = { ...params };
  const payload: any = {
    shiftId: droppedItem.shiftId,
    payload: {
      weekday: (droppedItem as any)?.weekday,
      division: [getDivisionId(draggingItem?.division)],
      employees: [droppedUserSlots],
      index: index
    }
  };
  const response: unknown = yield call(fixedSlotApi.createSlot, payload);
  return response;
}

// dirty code (DRY), need to be refactored by Linh Mai (email: linh.mhl9@gmail.com, sdt: 0898233146)
export function* updateSlotWorker({ payload }: PayloadAction<any>) {
  const { droppedItem, draggingItem, type, index } = payload;
  const shifts: FixedShift[] = yield select(fixedShiftSelector.selectShifts);
  const helper = new SlotHelper(droppedItem, draggingItem, shifts);

  try {
    const { draggingUserSlots, droppedUserSlots }: any = helper.handleUpdateSlot(type);
    const handleUpdatePayload = {
      droppedItem,
      droppedUserSlots,
      draggingItem,
      draggingUserSlots,
      index
    };
    let newShifts = [];

    switch (type) {
      case 'replace':
      case 'merge':
        yield handleReplaceOrMerge(handleUpdatePayload);
        yield put(fixedShiftActions.setShifts(helper.handleUpdateShifts() as FixedShift[]));
        break;
      case 'swap': {
        yield all(updateDragAndDrop(handleUpdatePayload));
        yield put(fixedShiftActions.setShifts(helper.handleUpdateShifts() as FixedShift[]));
        break;
      }
      case 'paste': {
        yield handlePaste(handleUpdatePayload);
        yield put(fixedShiftActions.setShifts(helper.handleUpdateShifts() as FixedShift[]));
        break;
      }
      case 'create': {
        const response: unknown = yield handleCreate(handleUpdatePayload);
        const payload = {
          projectId: (response as any).projectId,
          fixedSlotsIndex: index
        };
        newShifts = yield call(fixedShiftApi.getShiftsSlotIndex, payload);
        yield put(fixedShiftActions.setShifts(sortShiftsByName(newShifts) as FixedShift[]));
        break;
      }
      case 'duplicate': {
        const response: unknown = yield handleDuplicate(handleUpdatePayload);
        const payload = {
          projectId: (response as any).projectId,
          fixedSlotsIndex: index
        };
        newShifts = yield call(fixedShiftApi.getShiftsSlotIndex, payload);
        yield put(fixedShiftActions.setShifts(sortShiftsByName(newShifts) as FixedShift[]));
        break;
      }
      default: {
        break;
      }
    }
    toast.success('Successfully Updated');
  } catch (e) {
    console.log('updateSlotFailed error: ', e);
    yield put(fixedSlotActions.updateSlotFailed(e));
  }
}

export function* deleteSlotWorker({ payload }: PayloadAction<any>) {
  try {
    const response: FixedShift = yield call(fixedSlotApi.deleteSlot, payload);
    const shifts: FixedShift[] = yield select(fixedShiftSelector.selectShifts);
    const _shifts = shifts.map((s) => {
      if (s._id === payload.shiftId) {
        const _slots = [...s.slots];
        _slots.splice(payload.index, 1);
        return { ...s, slots: _slots };
      }
      return s;
    });
    yield put(fixedShiftActions.getShiftsSuccess(_shifts));
    yield put(fixedSlotActions.deleteSlotSuccess(response));
    toast.success('Successfully Deleted');
  } catch (e) {
    console.log('deleteSlotWorker Error: ', e);
    yield put(fixedSlotActions.deleteSlotFailed(e));
  }
}

export function* deleteUserSlotWorker({ payload }: PayloadAction<any>) {
  const shifts: FixedShift[] = yield select(fixedShiftSelector.selectShifts);
  const userSlots = findUserSlotsOf(payload, shifts) || [];
  userSlots?.splice(payload.index, 1);
  try {
    if (userSlots.length === 0) {
      yield call(fixedSlotApi.deleteSlot, {
        shiftId: payload.shiftId,
        slotId: payload.slotId
      });
    } else {
      yield call(fixedSlotApi.updateSlot, { ...payload, userSlots: userSlots });
    }
    const _shifts = getNewShifts(payload, cloneDeep(shifts), userSlots) as FixedShift[];
    yield put(fixedShiftActions.setShifts(_shifts));
    toast.success('Successfully Deleted');
  } catch (e) {
    console.log('deleteSlotWorker Error: ', e);
    yield put(fixedSlotActions.deleteSlotFailed(e));
  }
}

export function* createSlotWorker({ payload }: PayloadAction<CreateFixedSlotPayload>) {
  try {
    // Do not delete existing slot when on new schedule fix tab.
    if (!payload.isAddingMoreScheduleFixedIndex) {
      yield all(
        (payload.createdSlotIds as string[]).map((r, index) =>
          put(
            fixedSlotActions.deleteSlot({
              shiftId: payload.shiftId,
              slotId: r
            })
          )
        )
      );
    }
    const response: { projectId: string; shiftId: string } = yield call(fixedSlotApi.createSlot, {
      ...payload
    });
    const payloadFixedShift = {
      projectId: response.projectId,
      fixedSlotsIndex: payload.payload.index
    };

    const shifts: FixedShift[] = yield call(fixedShiftApi.getShiftsSlotIndex, payloadFixedShift);
    // const shifts: FixedShift[] = yield call(fixedShiftApi.getShifts, response.projectId);
    yield put(fixedShiftActions.getShiftsSuccess(sortShiftsByName(shifts) as FixedShift[]));
    yield put(fixedSlotActions.createSlotSuccess(response));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(fixedSlotActions.createSlotFailed(e));
  }
}

export function* exportFixedSlotsWorker({
  payload
}: PayloadAction<{
  dateStart: string;
  dateEnd: string;
  projectId: string;
  fixedSlotsIndex: number;
}>) {
  try {
    const response: string = yield call(fixedSlotApi.exportSlots, payload);
    yield put(fixedSlotActions.exportFixedSlotsSuccess(payload));
    toast.success('Successfully Exported');
  } catch (e) {
    console.log('Export fixed slot Error: ', e);
    yield put(fixedSlotActions.exportFixedSlotsFailed(e));
  }
}

export default function* shiftSaga() {
  yield takeLatest(fixedSlotActions.updateSlot, updateSlotWorker);
  yield takeLatest(fixedSlotActions.deleteSlot, deleteSlotWorker);
  yield takeLatest(fixedSlotActions.createSlot, createSlotWorker);
  yield takeLatest(fixedSlotActions.exportFixedSlots, exportFixedSlotsWorker);
  yield takeLatest(fixedSlotActions.deleteUserSlot, deleteUserSlotWorker);
}
