import { ReactNode, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

interface Props {
  children?: ReactNode;
  className?: string;
}

export const DropdownAutoCloseOutside = (props: Props) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <Dropdown
      className={props.className}
      show={isShown}
      onToggle={(isOpen, event, metadata) => {
        if (metadata.source !== 'select') {
          setIsShown(isOpen);
        }
      }}
    >
      {props.children !== null && props.children}
    </Dropdown>
  );
};
