import { AppState } from '../index.store';

export const selectIsCheckingIn = (state: AppState) => state.attendances.isCheckingIn;

export const selectIsCheckingInSuccess = (state: AppState) => state.attendances.isCheckingInSuccess;

export const selectIsCheckingInEarly = (state: AppState) => state.attendances.isCheckingInEarly;

export const selectIsCheckingInLate = (state: AppState) => state.attendances.isCheckingInLate;

export const selectIsCheckingOutSuccess = (state: AppState) =>
  state.attendances.isCheckingOutSuccess;

export const selectIsCheckingOutEarly = (state: AppState) => state.attendances.isCheckingOutEarly;

export const selectIsCheckingOutLate = (state: AppState) => state.attendances.isCheckingOutLate;

export const selectIsCovering = (state: AppState) => state.attendances.isCovering;

export const selectIsCoveringSuccess = (state: AppState) => state.attendances.isCoveringSuccess;

export const selectIsFetching = (state: AppState) => state.attendances.isFetching;

export const selectAttendances = (state: AppState) => state.attendances.attendances;

export const selectError = (state: AppState) => state.attendances.error;
