import { PayloadAction } from '@reduxjs/toolkit';
import { fixedScheduledPreviewActions } from './fixedScheduledPreview.slice';
import { ErrorResponse } from '../../utils/network';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fixedScheduledPreviewApi } from '../../services/fixedScheduledPreview.api';
import {
  FixedSchedulePreviewItem,
  FixedScheduledPreviewSlot
} from './fixedScheduledPreview.initialState';

function* getFixedScheduledPreviewSaga({
  payload
}: PayloadAction<{
  projectId: string;
}>) {
  try {
    const { projectId } = payload;
    const result: FixedSchedulePreviewItem[] = yield call(
      fixedScheduledPreviewApi.getFixedScheduledPreview,
      projectId
    );

    yield put(
      fixedScheduledPreviewActions.getFixedScheduledPreviewSuccess({ previewData: result })
    );
  } catch (e) {
    yield put(
      fixedScheduledPreviewActions.getFixedScheduledPreviewFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* savePreviewDataSaga({
  payload
}: PayloadAction<{
  projectId: string;
  previewData: FixedSchedulePreviewItem[];
}>) {
  try {
    const { projectId, previewData } = payload;
    const slots: FixedScheduledPreviewSlot[] = previewData.map((previewItem) => {
      return {
        shiftId: previewItem.shift._id,
        weekday: previewItem.weekday,
        users: previewItem.users.map((user) => user._id)
      };
    });
    yield call(fixedScheduledPreviewApi.savePreviewData, projectId, slots);
    yield put(fixedScheduledPreviewActions.requestSavePreviewDataSuccess());
  } catch (e) {
    yield put(
      fixedScheduledPreviewActions.requestSavePreviewDataFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* getShuffledPreviewDataSaga({
  payload
}: PayloadAction<{
  projectId: string;
}>) {
  try {
    const { projectId } = payload;
    const result: FixedSchedulePreviewItem[] = yield call(
      fixedScheduledPreviewApi.getShuffledPreviewData,
      projectId
    );
    yield put(
      fixedScheduledPreviewActions.getFixedScheduledPreviewSuccess({ previewData: result })
    );
  } catch (e) {
    yield put(
      fixedScheduledPreviewActions.requestShuffledPreviewDataFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

export default function* fixedScheduledPreviewSaga() {
  yield takeLatest(
    fixedScheduledPreviewActions.getFixedScheduledPreview,
    getFixedScheduledPreviewSaga
  );
  yield takeLatest(fixedScheduledPreviewActions.requestSavePreviewData, savePreviewDataSaga);
  yield takeLatest(
    fixedScheduledPreviewActions.requestShuffledPreviewData,
    getShuffledPreviewDataSaga
  );
}
