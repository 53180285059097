import { Form, Col } from 'react-bootstrap';
import { UserSlotFormProps } from 'models';

interface Props {
  formik: any;
  agentIndex: number;
  userIndex: number;
  // onChangeNote: (agentIndex: number, userIndex: number, e: any) => void;
}

const InputNote = (props: Props) => {
  const { formik, agentIndex, userIndex } = { ...props };

  const getUserSlot = () => {
    return formik.values.employees[agentIndex][userIndex];
  };

  const onChangeNote = (e: any) => {
    const fieldName = `employees[${agentIndex}][${userIndex}].note`;
    const fieldValue = formik.values.employees[agentIndex][userIndex];
    const newValue = {
      ...fieldValue,
      note: e.target.value
    };
    formik.setFieldValue(fieldName, newValue.note);
    if (userIndex === 0) {
      formik.values.employees[agentIndex].forEach((slot: any, index: number) => {
        formik.setFieldValue(`employees[${agentIndex}][${index}].note`, newValue.note);
      });
    }
  };

  return (
    <Form.Group
      as={Col}
      xs={userIndex === 0 ? 4 : 3}
      className="mb-3"
      controlId={`user-[${userIndex}].note`}
    >
      {/* <Button variant="success">
            Add Note
          </Button> */}
      <Form.Control
        type="text"
        placeholder="Note goes here"
        onBlur={formik.handleBlur}
        onChange={(event) => {
          onChangeNote(event);
        }}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
        name={`employees[${agentIndex}][${userIndex}].note`}
        value={getUserSlot().note}
      />
    </Form.Group>
  );
};

export default InputNote;
