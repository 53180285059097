import { Division } from 'models';
import { useEffect, useState } from 'react';
import { divisionActions, divisionSelector } from 'store/divisions/division.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';

export const useDivisionFilterV2 = () => {
  const dispatch = useAppDispatch();
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [divisionLabel, setDivisionLabel] = useState('All Division');
  // const projects = useAppSelector(projectSelector.selectProjects);
  const project = useAppSelector(projectSelector.selectSelectedProject);
  // const project = projects.find((prj) => prj?._id === selectedProject);
  // const divisions = project?.divisions || [];
  const selectedDivisions = useAppSelector(divisionSelector.selectSelectedDivisions);
  const selectedAllDivisions = useAppSelector(divisionSelector.selectSelectedAllDivisions);

  const setSelectedDivisions = (divisionIds: string[]) => {
    dispatch(dispatch(divisionActions.setSelectedDivisions(divisionIds)));
  };

  const setSelectedAllDivisions = (value: boolean) => {
    dispatch(divisionActions.setSelectedAllDivisions(value));
  };

  useEffect(() => {
    if (project && project.divisions) {
      const { divisions } = { ...project };
      setDivisions(divisions);
      setSelectedAllDivisions(true);
      setSelectedDivisions(divisions.map((d) => d._id));
    }
  }, [project]);

  useEffect(() => {
    if (selectedAllDivisions) {
      setDivisionLabel('All Divisions');
    } else {
      if (selectedDivisions.length === 0) {
        setDivisionLabel('Division');
      } else {
        setDivisionLabel(`${selectedDivisions.length} Divisions`);
      }
    }
  }, [selectedDivisions]);

  const onSelectDivision = (e: any, divisionId: string) => {
    // e.stopPropagation();
    // const checked = e.target.checked;
    let _selectedDivisions: string[] = [];
    if (divisionId === 'all') {
      const _isSelectedAll = !selectedAllDivisions;
      setSelectedAllDivisions(_isSelectedAll);
      _selectedDivisions =
        _isSelectedAll === true ? [...divisions.map((division) => division._id)] : [];
    } else {
      const index = selectedDivisions?.indexOf(divisionId);
      _selectedDivisions = [...(selectedDivisions || [])];
      index > -1 ? _selectedDivisions.splice(index, 1) : _selectedDivisions.push(divisionId);
      if (_selectedDivisions.length === divisions.length) {
        setSelectedAllDivisions(true);
      } else {
        setSelectedAllDivisions(false);
      }
    }
    setSelectedDivisions(_selectedDivisions);
  };

  return {
    divisions,
    selectedDivisions,
    setSelectedDivisions,
    divisionLabel,
    onSelectDivision,
    selectedAllDivisions,
    setSelectedAllDivisions,
    setDivisionLabel
  };
};
