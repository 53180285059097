import { Table } from 'react-bootstrap';
import ShiftChangeRequestLogList from './ShiftChangeRequestLogList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getShiftChanges,
  shiftSelector,
  getApprovedRequestLog
} from 'store/shiftChanges/shiftChange.slice';
import { useEffect } from 'react';
import { GetShiftChangePayload } from 'models';

interface Props {
  selectedTab: string;
  projectId: string;
}

const ShiftChangeApproved = (props: Props) => {
  const { selectedTab, projectId } = { ...props };

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(shiftSelector.selectIsFetching);
  const data = useAppSelector(shiftSelector.selectApprovedRequestLog);
  const paginationInfo = useAppSelector(shiftSelector.approvedShiftChangePaginationInfo);

  useEffect(() => {
    // setTimeout(() => {
    dispatch(
      getApprovedRequestLog({
        page: 1,
        perPage: 10,
        type: props.selectedTab,
        projectId: [props.projectId],
        status: 'APPROVED',
        orderBy: 'createdAt',
        order: 'desc',
        displayProject: props.projectId
      } as GetShiftChangePayload)
    );
    // }, 500);
  }, [selectedTab, projectId]);

  return (
    <>
      <ShiftChangeRequestLogList
        data={data}
        isLoading={isLoading}
        type={selectedTab}
        status="APPROVED"
        paginationInfo={paginationInfo}
        projectId={[projectId]}
        displayProject={projectId}
      />
    </>
  );
};

export default ShiftChangeApproved;
