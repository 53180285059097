import React, { Fragment } from 'react';
import { SchedulerHead } from '../SchedulerFix/Scheduler.styled';
import {
  ScheduleAvailabilityBody,
  ScheduleAvailabilityCell,
  ScheduleAvailabilityHeader,
  ScheduleAvailabilityRow,
  ScheduleAvailabilityTable,
  ScheduleAvailabilityTableWrapper,
  TableLabel
} from './ScheduleAvailability.styled';
import { SchedulerHeaderWrapper } from '../Scheduler/Actions/SchedulerHeader/SchedulerHeader.styled';
import { Shift, getWeekdayNumber, getWeekdays } from '../../models';
import { useAppSelector } from '../../store/hooks';
import { selectSelectedProject } from '../../store/meta/meta.selector';
import { selectAvailabilityBreakdown } from '../../store/scheduleAvailability/scheduledAvailability.selector';
import { parseShiftTime } from '../../utils/formatters/datetime.formatter';
import { selectShifts } from '../../store/shifts/shift.selector';
import { ScheduleAvailabilityBreakdownItem } from '../../store/scheduleAvailability/scheduleAvailability.initialState';

const ScheduleAvailabilityBreakdown = (): React.ReactElement => {
  const project = useAppSelector(selectSelectedProject);
  const availabilityBreakdown = useAppSelector(selectAvailabilityBreakdown);
  const shifts: Shift[] = useAppSelector(selectShifts);

  return (
    <Fragment>
      <TableLabel align="center" className="mt-3">
        Availability Break-down
      </TableLabel>
      <ScheduleAvailabilityTableWrapper>
        <ScheduleAvailabilityTable bordered size="sm" responsive="sm">
          <SchedulerHead>
            <SchedulerHeaderWrapper>
              <ScheduleAvailabilityHeader style={{ width: '10%' }}>
                {project?.name}
              </ScheduleAvailabilityHeader>
              {getWeekdays().map((weekday, index) => {
                return (
                  <ScheduleAvailabilityHeader key={index}>{weekday}</ScheduleAvailabilityHeader>
                );
              })}
            </SchedulerHeaderWrapper>
          </SchedulerHead>
          <ScheduleAvailabilityBody>
            {shifts.map((shift) => {
              const breakdownItem = availabilityBreakdown.find(
                (breakdownItem) => breakdownItem.shiftId === shift._id
              );

              return (
                <ScheduleAvailabilityRow key={`breakdown-${shift._id}-row`}>
                  <ScheduleAvailabilityCell
                    key={`breakdown-${shift._id}-cell`}
                    title={`${parseShiftTime(shift.startTime)} - ${parseShiftTime(shift.endTime)}`}
                  >
                    {shift.name}
                  </ScheduleAvailabilityCell>
                  {getWeekdays().map((weekday) => {
                    let breakdown: ScheduleAvailabilityBreakdownItem | undefined;

                    if (breakdownItem) {
                      breakdown = breakdownItem.breakdown.find(
                        (item) => item.weekday === getWeekdayNumber(weekday)
                      );
                    }

                    return (
                      <ScheduleAvailabilityCell key={`breakdown-${shift._id}-weekday-${weekday}`}>
                        {breakdown && (
                          <>
                            {breakdown.noOfHours} hrs <br /> {breakdown.noOfAgents} agents
                          </>
                        )}
                      </ScheduleAvailabilityCell>
                    );
                  })}
                </ScheduleAvailabilityRow>
              );
            })}
          </ScheduleAvailabilityBody>
        </ScheduleAvailabilityTable>
      </ScheduleAvailabilityTableWrapper>
    </Fragment>
  );
};

export default ScheduleAvailabilityBreakdown;
