import { Project } from '../../models';
import { Nullable } from '../../utils/common/types';

export interface MetaInitialState {
  selectedProject: Nullable<Project>;
}

export const initialState: MetaInitialState = {
  selectedProject: null
};
