import { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { BadgeCount } from './OffRequestTypeTab.styled';
import { selectPending } from 'store/offVacations/offVacation.selector';

const tabs = [
  {
    key: 'ONEDAY',
    value: 'ONEDAY'
  },
  {
    key: 'SOMEDAY',
    value: 'SOMEDAY'
  },
  {
    key: 'CONSECUTIVEDAY',
    value: 'CONSECUTIVEDAY'
  },
  {
    key: 'SOMEHOURS',
    value: 'SOMEHOURS'
  }
];

export const OffRequestTypeTab = () => {
  const [selectedTab, setSelectedTab] = useState<string>('ONEDAY');

  const pending = useAppSelector(selectPending);

  const getPendingCount = (tabKey: string) => {
    if (pending === null) {
      return undefined;
    }
    switch (tabKey) {
      case 'ONEDAY':
        return pending.ONEDAY;
      case 'SOMEDAY':
        return pending.SOMEDAY;
      case 'CONSECUTIVEDAY':
        return pending.CONSECUTIVEDAY;
      case 'SOMEHOURS':
        return pending.SOMEHOURS;
      default:
        return 0;
    }
  };

  const OffVacataionTypeTab = () => {
    return (
      <div className="d-flex justify-content-between">
        <ButtonGroup toggle className="mr-2">
          {tabs.map((tab) => (
            <ToggleButton
              key={tab.key}
              type="radio"
              variant="outline-primary"
              size="sm"
              name="radio"
              value={tab.key}
              checked={selectedTab === tab.key}
              onChange={(e) => setSelectedTab(tab.key)}
              style={{ lineHeight: 2 }}
              className="position-relative"
            >
              {getPendingCount(tab.key) !== undefined && (
                <BadgeCount>{getPendingCount(tab.key)}</BadgeCount>
              )}
              {tab.value}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    );
  };

  return { selectedTab, OffVacataionTypeTab };
};
