import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
export const HighlighterPickerInput = styled.input.attrs({
  type: 'color'
})`
  margin-left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const HighlighterSelectInput = styled.input.attrs({
  type: 'checkbox'
})`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -1.3rem;
  width: 1rem;
  height: 1rem;
`;

export const HighlighterPickerItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const HighlighterPickerIcon = styled.i`
  position: absolute;
  left: -1.3rem;
`;
