import { useAuth } from 'hooks/useAuth';
import { Division, DragAndDropParams, FixedShift, FixedSlot, Shift, Slot } from 'models';
import { useState } from 'react';
import { contextMenu, useContextMenu } from 'react-contexify';
import { ShiftAgentContainer, ShiftAgentContent } from './useShiftAgentContainer.styled';
import { useProjectFilter } from './useProjectFilter';

export const useShiftAgentContainer = (
  index: number,
  slotData: Slot | FixedSlot,
  shiftData: Shift | FixedShift,
  onSetDraggingItem: (object: DragAndDropParams) => void,
  onSetDroppedItem: (object: DragAndDropParams) => void,
  children: JSX.Element
) => {
  const currentUser = useAuth();
  const { show } = useContextMenu();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const { projects, selectedProject } = useProjectFilter();

  const displayMenu = (e: any, id: string) => {
    show({
      event: e,
      id: id
    });
  };

  const getClass = () => {
    return isDraggingOver ? 'drag-entered' : '';
  };

  const getBorderColor = () => {
    const project = projects.find((project) => project._id === selectedProject);
    const divisionId = slotData?.division?.[0]?._id || (slotData?.division as any)?._id;
    return project && divisionId
      ? project.divisions.find((division: Division) => division._id === divisionId)?.color
      : '#32539c';
  };

  const onDrop = (e: any) => {
    const division =
      typeof slotData.division === 'string'
        ? slotData?.division
        : slotData?.division?.[0] || (slotData?.division as any)?._id;
    e.stopPropagation();
    setIsDraggingOver(false);
    contextMenu.show({
      id: 'DRAG_AND_DROP',
      event: e
    });
    onSetDroppedItem({
      type: 'shift',
      slotId: slotData._id,
      shiftId: shiftData._id,
      division:
        typeof slotData.division === 'string'
          ? slotData?.division
          : slotData?.division?.[0] || (slotData?.division as any)?._id
    });
  };

  const onDragStart = (e: any) => {
    const division =
      typeof slotData.division === 'string'
        ? slotData?.division
        : slotData?.division?.[0] || (slotData?.division as any)?._id;
    e.stopPropagation();
    onSetDraggingItem({
      type: 'shift',
      slotId: slotData._id,
      shiftId: shiftData._id,
      division:
        typeof slotData.division === 'string'
          ? slotData?.division
          : slotData?.division?.[0] || (slotData?.division as any)?._id
    });
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDragEnter = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const onDragLeave = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const Container = () => {
    if (currentUser?.role === 'MANAGER') {
      return (
        <ShiftAgentContainer
          onContextMenu={(e: any) => {
            e.stopPropagation();
            displayMenu(e, `${index + slotData._id}`);
          }}
          className={getClass()}
          draggable
          onDrop={onDrop}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        >
          <ShiftAgentContent style={{ borderColor: getBorderColor() || '#32539c' }}>
            {children}
          </ShiftAgentContent>
        </ShiftAgentContainer>
      );
    } else {
      return (
        <ShiftAgentContainer
          className={getClass()}
          style={{
            cursor: slotData.userSlots.length > 1 ? 'pointer' : 'normal'
          }}
        >
          <ShiftAgentContent style={{ borderColor: getBorderColor() || '#32539c' }}>
            {children}
          </ShiftAgentContent>
        </ShiftAgentContainer>
      );
    }
  };

  return { setIsDraggingOver, Container };
};
