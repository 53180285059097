import { useAppSelector } from '../../../store/hooks';
import { DropdownAutoCloseOutside } from 'components/Shared/index';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from './ScheduleFixIndex.styled';
import { fixedShiftSelector, fixedShiftActions } from 'store/fixedShifts/fixedShift.slice';

interface Props {
  fixTab: number;
  onChangeValue: (e: any, index: number) => void;
}
const ScheduleFixIndex = (props: Props) => {
  const highestIndexFixedSlot: number[] = useAppSelector(
    fixedShiftSelector.selectHighestIndexFixedSlot
  );
  // const [fixTab, setFixTab] = useState(1);
  // const dispatch = useAppDispatch();

  // const onSelectFixTab = (e: any, index: number) => {
  //   e.stopPropagation();
  //   setFixTab(index);
  // };

  return (
    <DropdownAutoCloseOutside className="mr-2">
      <Dropdown.Toggle size="sm" variant="outline-primary">
        Fix tab ({props.fixTab})
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        {highestIndexFixedSlot.map((e, index) => (
          <Dropdown.Item key={index + 1} as="li" onClick={(e) => props.onChangeValue(e, index + 1)}>
            <FilterItem>
              <input
                type="radio"
                value={e}
                checked={e === props.fixTab}
                onChange={(event) => props.onChangeValue(e, index + 1)}
              />
              <FilterItemLabel>{e}</FilterItemLabel>
            </FilterItem>
          </Dropdown.Item>
        ))}
        {highestIndexFixedSlot.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};

export default ScheduleFixIndex;
