import { addDays, startOfDay } from 'date-fns';
import { FixedShift, FixedSlot, Shift, Slot, User, UserSlot } from 'models';
import { removeSimpleDuplicatedEl } from 'utils/formatters/array';
import { ShiftHelper } from './shift.helper';
import { UserHelper } from './user.helper';

export enum ErrorTypes {
  EXCESS_HOURS = 'excess_hours' as any,
  NOT_ENOUGH_HOURS = 'not_enough_hours' as any,
  INVALID_AGENT = 'invalid_agent' as any
}

export const ErrorMessages = {
  [ErrorTypes.EXCESS_HOURS]: 'Excess hours added',
  [ErrorTypes.NOT_ENOUGH_HOURS]: 'Not enough hours added',
  [ErrorTypes.INVALID_AGENT]: 'Agent with excess hours detected'
};

interface Props {
  users: User[];
  shiftId: string;
  date: Date | number;
  slot: Slot | FixedSlot;
  shifts: (Shift | FixedShift)[];
}

export class SlotValidatorHelper {
  users: User[];
  shiftId: string;
  shift: Shift | FixedShift | undefined;
  date: Date | number; // Date === Schedule Actual, number === Schedule Fix
  slot: Slot | FixedSlot;
  shifts: (Shift | FixedShift)[];
  duration: number;
  totalHours: number;
  isActualSchedule: boolean;
  startTime = 0;

  constructor(props: Props) {
    this.users = props.users;
    this.shiftId = props.shiftId;
    this.shift = props.shifts.find((shift) => shift._id === this.shiftId);
    this.date = props.date;
    this.slot = props.slot;
    this.shifts = props.shifts;
    this.duration = ShiftHelper.getShiftDuration(this.shifts, this.shiftId);
    this.totalHours = this.getTotalHours();
    this.isActualSchedule = typeof this.date !== 'number';
    if (this.shift) {
      this.startTime = ShiftHelper.parseTime(this.shift.startTime);
    }
  }

  public getErrors = () => {
    const errors = {
      [ErrorTypes.EXCESS_HOURS]: [] as any,
      [ErrorTypes.NOT_ENOUGH_HOURS]: [] as any,
      [ErrorTypes.INVALID_AGENT]: [] as any
    };
    this.checkExcessOrNotEnoughHours(errors);
    this.checkInvalidAgents(errors);

    return errors;
  };

  private checkExcessOrNotEnoughHours = (errors: any) => {
    if (this.totalHours > this.duration) {
      errors[ErrorTypes.EXCESS_HOURS].push(this.getUsernames());
    } else if (this.totalHours < this.duration) {
      errors[ErrorTypes.NOT_ENOUGH_HOURS].push(this.getUsernames());
    }
  };

  private checkInvalidAgents = (errors: any) => {
    const _shifts = this.findShiftsNotStartBeforeSixHours();
    this.isActualSchedule
      ? this.checkActualScheduleInvalidAgents(_shifts as Shift[], errors)
      : this.checkFixedScheduleInvalidAgents(_shifts as FixedShift[], errors);
  };

  private findShiftsNotStartBeforeSixHours = () => {
    if (!this.shift) {
      return;
    }

    return this.shifts.filter((shift: Shift | FixedShift) => {
      const _shiftEndTime = ShiftHelper.getEndTimeInt(shift);

      if (this.startTime === 0) {
        const interval = 24 - _shiftEndTime;
        return interval >= 0 && interval <= 7;
      }

      if (this.startTime - 6 >= 0 && this.startTime - 6 <= 1) {
        const interval = this.startTime - _shiftEndTime;
        return _shiftEndTime === 24 || (interval >= 0 && interval <= 7);
      }

      const interval = this.startTime - _shiftEndTime;
      return interval >= 0 && interval <= 7;
    });
  };

  private checkActualScheduleInvalidAgents = (_shifts: Shift[], errors: any) => {
    const slots = this.findActualScheduleSlots(_shifts);
    const comparingUserSlots = this.slot.userSlots;

    const duplicatedAgents = slots.flatMap((slot) =>
      slot.userSlots.filter((slotUser) =>
        comparingUserSlots.some((u) => u.user._id === slotUser.user._id)
      )
    );
    const invalidAgents = removeSimpleDuplicatedEl(duplicatedAgents.map((r) => r.user.username));

    if (invalidAgents.length) {
      errors[ErrorTypes.INVALID_AGENT].push(invalidAgents);
    }
  };

  private checkFixedScheduleInvalidAgents = (_shifts: FixedShift[], errors: any) => {
    const slots = this.findFixedScheduleSlots(_shifts);

    const comparingUserSlots = this.slot.userSlots;

    const duplicatedAgents = slots.flatMap((slot) =>
      slot.userSlots.filter((slotUser) =>
        comparingUserSlots.some((u) => u.user._id === slotUser.user._id)
      )
    );

    const invalidAgents = removeSimpleDuplicatedEl(duplicatedAgents.map((r) => r.user.username));

    if (invalidAgents.length) {
      errors[ErrorTypes.INVALID_AGENT].push(invalidAgents);
    }
  };

  private findActualScheduleSlots = (_shifts: Shift[]) => {
    let slots: Slot[] = [];
    _shifts.forEach((shift) => {
      let shiftSlots: Slot[] = [];
      if (this.startTime === 0) {
        shiftSlots = shift.slots.filter(
          (slot) =>
            startOfDay(new Date(slot.date)).getTime() === addDays(this.date as Date, -1).getTime()
        );
      } else if (this.startTime - 6 >= 0 && this.startTime - 6 <= 1) {
        const _shiftEndTime = ShiftHelper.getEndTimeInt(shift);
        const interval = 24 - _shiftEndTime;
        if (_shiftEndTime === 24) {
          shiftSlots = shift.slots.filter(
            (slot) =>
              startOfDay(new Date(slot.date)).getTime() === addDays(this.date as Date, -1).getTime()
          );
        } else {
          shiftSlots = shift.slots.filter(
            (slot) => startOfDay(new Date(slot.date)).getTime() === (this.date as Date).getTime()
          );
        }
      } else {
        shiftSlots = shift.slots.filter(
          (slot) => startOfDay(new Date(slot.date)).getTime() === (this.date as Date).getTime()
        );
      }
      slots = [...slots, ...shiftSlots];
    });
    return slots;
  };

  private findFixedScheduleSlots = (_shifts: FixedShift[]) => {
    let slots: FixedSlot[] = [];
    _shifts.forEach((shift) => {
      let shiftSlots: FixedSlot[] = [];
      if (this.startTime === 0) {
        shiftSlots = shift.slots.filter((slot) => slot.weekday === (this.date as number) - 1);
      } else if (this.startTime - 6 >= 0 && this.startTime - 6 <= 1) {
        const _shiftEndTime = ShiftHelper.getEndTimeInt(shift);
        const interval = 24 - _shiftEndTime;
        if (_shiftEndTime === 24) {
          shiftSlots = shift.slots.filter((slot) => slot.weekday === (this.date as number) - 1);
        } else {
          shiftSlots = shift.slots.filter((slot) => slot.weekday === (this.date as number));
        }
      } else {
        shiftSlots = shift.slots.filter((slot) => slot.weekday === (this.date as number));
      }
      slots = [...slots, ...shiftSlots];
    });
    return slots;
  };

  public static getHoursAgentAssigned = (slots: (Slot | FixedSlot)[], userId: string): number => {
    let hours = 0;
    slots.map((slot) => {
      slot.userSlots.map((userSlot: UserSlot) => {
        if (userSlot.user._id === userId) {
          hours += userSlot.duration || 0;
        }
      });
    });
    return hours;
  };

  public getSlotsOfGivenDate(shift: Shift | FixedShift): (Slot | FixedSlot)[] {
    if (!this.isActualSchedule) {
      return this.getFixedSlotsForDate(shift as FixedShift);
    } else {
      return this.getActualSlotsForDate(shift as Shift);
    }
  }

  private getFixedSlotsForDate(shift: FixedShift): FixedSlot[] {
    return shift?.slots.filter((slot: FixedSlot) => slot.weekday === this.date) || [];
  }

  private getActualSlotsForDate(shift: Shift): Slot[] {
    const selectedDate = this.date as Date;
    const startOfDaySelectedDate = startOfDay(selectedDate).getTime();

    return (
      shift?.slots.filter((slot: Slot) => {
        return startOfDay(new Date(slot.date)).getTime() === startOfDaySelectedDate;
      }) || []
    );
  }

  public getUsernames = () => {
    return this.slot.userSlots.map(({ user, duration }) => {
      const username = UserHelper.findUsername(user._id, this.users);
      return `${username} (${duration})`;
    });
  };

  public getTotalHours = () => {
    return this.slot.userSlots.reduce((a: any, b: any) => {
      return a + parseFloat(b.duration) || 0;
    }, 0);
  };
}
