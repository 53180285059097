import ApiService from '../utils/network';
import { API_URL } from './url';
export const fixedShiftApi = {
  getShifts(projectId: string) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.PROJECT}/${projectId}/${API_URL.SHIFT}`, {
      params: { getFixedSlot: true }
    });
  },
  getShiftsSlotIndex(payload: any) {
    return ApiService.get(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/${API_URL.SHIFT}`,
      {
        params: { getFixedSlot: true, fixedSlotsIndex: payload.fixedSlotsIndex }
      }
    );
  },
  getHighestIndexFixedSlot(payload: any) {
    return ApiService.get(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/getHighestIndexFixedSlot`
      // {
      //   params: { getFixedSlot: true, fixedSlotsIndex: payload.fixedSlotsIndex }
      // }
    );
  },
  updateShift(payload: any) {
    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/${API_URL.SHIFT}/${payload.shiftId}`,
      {
        ...payload,
        type: payload.type.toUpperCase(),
        endTime: payload.endTime === '24:00' ? '23:59' : payload.endTime
      }
    );
  },
  createShifts(payload: any) {
    return ApiService.post(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/${API_URL.SHIFT}`,
      payload.payload
    );
  },
  removeShift(payload: any) {
    return ApiService.delete(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/${API_URL.SHIFT}/${payload.shiftId}`
    );
  },
  deleteFixedSlotsByIndex(payload: any) {
    return ApiService.delete(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${payload.projectId}/deleteFixedSlotsByIndex/${payload.index}`,
      {
        params: { index: payload.index }
      }
    );
  }
};
