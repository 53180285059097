import ApiService from '../utils/network';
import { API_URL } from './url';
import {
  GetShiftChangePayload,
  ShiftChange,
  ShiftChangeFormProps,
  GetShiftChangeIdPayload
} from 'models';
export const shiftChangeApi = {
  getShiftChanges(payload: GetShiftChangePayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.SHIFTCHANGE}`, {
      params: payload
    });
  },
  createShiftChange(payload: ShiftChangeFormProps) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.SHIFTCHANGE}`, payload);
  },
  updateShiftChange(payload: ShiftChange) {
    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.SHIFTCHANGE}/${payload._id}/manager`,
      payload
    );
  },
  getShiftChangeById(payload: GetShiftChangeIdPayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.SHIFTCHANGE}/getById`, {
      params: payload
    });
  }
};
