import { PayloadAction } from '@reduxjs/toolkit';
import { Shift, ShiftChange } from 'models';
import { call, put, takeLatest, select, SelectEffect } from 'redux-saga/effects';
import { shiftApi } from 'services';
import { shiftActions } from './shift.slice';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';
import { schedulerActions, schedulerSelector } from 'store/schedulers/scheduler.slice';

export function* getShiftsWorker({ payload }: PayloadAction<string>) {
  try {
    const dateRange: Date[] = yield select(schedulerSelector.selectSelectedDateRange);
    const response: Shift[] = yield call(
      shiftApi.getShifts,
      payload,
      ((dateRange as any)?.[0] as string) || null,
      ((dateRange as any)?.[dateRange.length - 1] as string) || null
    );
    yield put(shiftActions.getShiftsSuccess(sortShiftsByName(response) as Shift[]));
    yield put(
      shiftActions.setGetShiftsPayload({
        projectId: payload,
        dateStart: ((dateRange as any)?.[0] as string) || undefined,
        dateEnd: ((dateRange as any)?.[dateRange.length - 1] as string) || undefined
      })
    );
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(shiftActions.getShiftsFailed(e));
  }
}

export function* setShiftsWorker({ payload }: PayloadAction<Shift[]>) {
  try {
    yield put(shiftActions.setShiftsSuccess(sortShiftsByName(payload) as Shift[]));
  } catch (e) {
    console.log('Setting Data Error: ', e);
  }
}

export function* shiftChangeWorker({ payload }: PayloadAction<ShiftChange>) {
  try {
    // const response: ShiftChange = yield call(shiftApi.shiftChange, payload);
    // yield put(shiftActions.shiftChangeSuccess(response));
  } catch (e) {
    console.log('ShiftChangeFailed err: ', e);
    yield put(shiftActions.shiftChangeFailed(e));
  }
}

export function* setSelectedShiftWorker({ payload }: PayloadAction<Shift | null>) {
  yield put(shiftActions.setSelectedShiftSuccess(payload));
}

export function* getOffVacationSlotShiftsWorker({ payload }: PayloadAction<string>) {
  try {
    const dateRange: Date[] = yield select(schedulerSelector.selectSelectedDateRange);
    const response: Shift[] = yield call(
      shiftApi.getOffVacationSlotShifts,
      payload,
      ((dateRange as any)?.[0] as string) || null,
      ((dateRange as any)?.[dateRange.length - 1] as string) || null
    );
    yield put(shiftActions.getOffVacationSlotShiftsSuccess(sortShiftsByName(response) as Shift[]));
    yield put(
      shiftActions.setGetShiftsPayload({
        projectId: payload,
        dateStart: ((dateRange as any)?.[0] as string) || undefined,
        dateEnd: ((dateRange as any)?.[dateRange.length - 1] as string) || undefined
      })
    );
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(shiftActions.getOffVacationSlotShiftsFailed(e));
  }
}

export default function* shiftSaga() {
  yield takeLatest(shiftActions.getShifts, getShiftsWorker);
  yield takeLatest(shiftActions.setShifts, setShiftsWorker);
  yield takeLatest(shiftActions.shiftChange, shiftChangeWorker);
  yield takeLatest(shiftActions.setSelectedShift, setSelectedShiftWorker);
  yield takeLatest(shiftActions.getOffVacationSlotShifts, getOffVacationSlotShiftsWorker);
}
