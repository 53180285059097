import { DropdownAutoCloseOutside } from 'components/Shared';
import { Project } from 'models';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from 'hooks/scheduler/FilterDropdown.styled';
// import { FilterItem, FilterItemLabel } from '../../FilterDropdown.styled';
import React, { useEffect, useState } from 'react';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useSearchParams } from 'react-router-dom';
import { shiftActions } from 'store/shifts/shift.slice';

const AttendanceProjectFilter = () => {
  const dispatch = useAppDispatch();
  const [selectedProject, setSelectedProject] = useState('');
  const [projectLabel, setProjectLabel] = useState('Project');
  const [searchParams, setSearchParams] = useSearchParams();
  const projects = useAppSelector(projectSelector.selectProjects);
  const projectId = localStorage.getItem('projectId');
  const filterId = localStorage.getItem('filterId');
  const [activeFilter, setActiveFilter] = useState(projects);

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(projects);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(projects);
    } else {
      const filtersList = projects.filter((f) =>
        f.name.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  useEffect(() => {
    if (projectId && !filterId) {
      const project = projects.find((prj: Project) => prj?._id === projectId);
      if (project) {
        searchParams.set('project', project.name);
        searchParams.set('projectId', project._id);
        setSearchParams(searchParams);
      }
    }
  }, []);

  useEffect(() => {
    const project = projects.find((prj) => prj?.name === searchParams.get('project'));
    if (project) {
      setProjectLabel(project.name);
      setSelectedProject(project._id);
      dispatch(projectActions.setSelectedProject(project));
    }
  }, [searchParams]);

  const onSelectProject = (e: any, projectId: string) => {
    // dispatch(schedulerActions.setIsApplyingFilter(true));
    // dispatch(schedulerFixActions.setIsApplyingFilter(true));
    if (e !== null) {
      e.stopPropagation();
      localStorage.removeItem('filterId');
    }
    // dispatch(schedulerFixActions.setIsApplyingFilter(true));
    const project = projects.find((prj: Project) => prj?._id === projectId);
    if (project) {
      searchParams.set('project', project.name);
      searchParams.set('projectId', project._id);
      setSearchParams(searchParams);
      localStorage.setItem('projectId', project._id);
      dispatch(shiftActions.getShifts(project._id));
    }
  };

  return (
    <>
      <DropdownAutoCloseOutside className="mr-2">
        <Dropdown.Toggle size="sm" variant="outline-primary h-100" onMouseEnter={onSetFilter}>
          {projectLabel}
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul">
          <Dropdown.Item as="li">
            <FilterItem>
              <input
                type="text"
                onChange={(e) => onChangeFilter(e.target.value)}
                placeholder="Search Item"
              />
            </FilterItem>
          </Dropdown.Item>

          {activeFilter.length > 0 &&
            activeFilter.map((prj) => (
              <Dropdown.Item key={prj._id} as="li" onClick={(e) => onSelectProject(e, prj._id)}>
                <FilterItem>
                  <input
                    type="radio"
                    value={prj._id}
                    checked={selectedProject === prj._id}
                    onChange={(e) => onSelectProject(e, prj._id)}
                  />
                  <FilterItemLabel>{prj.name}</FilterItemLabel>
                </FilterItem>
              </Dropdown.Item>
            ))}
          {projects.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
        </Dropdown.Menu>
      </DropdownAutoCloseOutside>
    </>
  );
};
export default React.memo(AttendanceProjectFilter);
