import { useSpinner } from 'hooks/useSpinner';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { reportActions } from 'store/reports/report.slice';
import ReportDashboardFilter from './MainActions/ReportDashboardFilter';
import ReportDashboardChart from './MainActions/ReportDashboardChart';

const ReportDashboard = () => {
  const isFetchingProjects: boolean = useAppSelector(projectSelector.selectIsFetching);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(projectActions.getProjects());
    dispatch(reportActions.getPersonalFilters({ kind: 'REPORT' }));
  }, []);

  return !isFetchingProjects ? (
    <>
      <ReportDashboardFilter />
      <ReportDashboardChart />
      {/* <ReportTimesheetList /> */}
    </>
  ) : (
    useSpinner()
  );
};
export default ReportDashboard;
