import { AppState } from '../index.store';

export const selectEnableEditing = (state: AppState) => state.scheduleAvailability.enableEditing;
export const selectShowAllNote = (state: AppState) => state.scheduleAvailability.showAllNote;
export const selectProjectId = (state: AppState) => state.scheduleAvailability.projectId;
export const selectAvailabilities = (state: AppState) =>
  state.scheduleAvailability.availabilityList;

export const selectCreatedAvailability = (state: AppState) =>
  state.scheduleAvailability.createdAvailability;

export const selectDeletedAvailability = (state: AppState) =>
  state.scheduleAvailability.deletedAvailability;

export const selectPreview = (state: AppState) => state.fixedScheduledPreview.previewData;

export const selectApplyChangesMessage = (state: AppState) =>
  state.scheduleAvailability.applyChanges.message;

export const selectAvailabilityBreakdown = (state: AppState) =>
  state.scheduleAvailability.breakdown.data;

export const selectRecentClear = (state: AppState) => state.scheduleAvailability.recentClear;
