import { CreateCommentPayload, UpdateCommentPayload, Comment } from 'models';
import ApiService from '../utils/network';
import { API_URL } from './url';

export const commentApi = {
  createComment(payload: CreateCommentPayload) {
    return ApiService.post(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/${API_URL.SLOTS}/${payload.slotId}/${API_URL.COMMENT}`,
      payload
    );
  },
  updateComment(payload: UpdateCommentPayload) {
    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/${API_URL.SLOTS}/${payload.slotId}/${API_URL.COMMENT}/${payload.commentId}`,
      { status: payload.status, content: payload.content }
    );
  },
  deleteComment(payload: Comment) {
    return ApiService.delete(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shift}/${API_URL.SLOTS}/${payload.slot}/${API_URL.COMMENT}/${payload._id}`
    );
  }
};
