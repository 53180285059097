import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetReportsPayload } from 'models/report.model';
import { initialState } from './filter.initialState';
import { SalaryPeriod } from 'models';

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    getPersonalFilters: (state, { payload }: PayloadAction<any | undefined>) => undefined,
    getPersonalFiltersSuccess: (state, { payload }: PayloadAction<any>) => {
      state.personalFilters = payload;
      state.error = null;
    },
    getPersonalFiltersFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    createPersonalFilter: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = true;
      state.error = null;
    },
    createPersonalFilterSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = false;
      state.personalFilters = payload;
      console.log(state.personalFilters);
      state.error = null;
    },
    createPersonalFilterFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = false;
      state.error = payload;
    },
    deleteFilter: (state, { payload }: PayloadAction<{ filterId: string }>) => {
      // state.deletingFilter = payload.filterId;
      state.isLoadingFilters = true;
      state.error = null;
      // state.deletedFilter = null;
    },
    deleteFilterSuccess: (state, { payload }: PayloadAction<{ filterId: string }>) => {
      // state.deletingFilter = null;
      state.error = null;
      state.isLoadingFilters = false;
      // state.deletingFilter = payload.filterId;
    },
    deleteFilterFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.error = payload.error;
      state.isLoadingFilters = false;
      // state.deletingFilter = null;
      // state.deletingFilter = null;
    }
  }
});

export const filterActions = filterSlice.actions;

export * as filterSelector from './filter.selector';

export default filterSlice.reducer;
