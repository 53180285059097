import ModalConfirm from 'components/Scheduler/Actions/ModalConfirm/ModalConfirm';
import ModalConfirmChange from 'components/Scheduler/Actions/ModalConfirmChange/ModalConfirmChange';
import ShiftInformation from 'components/Scheduler/FormActions/ShiftInformation/ShiftInformation';
import { parse } from 'date-fns';
import { FieldArray, FormikProvider } from 'formik';
import ButtonAddEmployee from 'hooks/scheduler/FormActions/ButtonAddEmployee';

import { Shift } from 'models';
import React from 'react';
import { Col, Form, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import InputEmployeeRow from '../../FormActions/InputEmployeeRow/InputEmployeeRow';

import FormButtons from 'components/Scheduler/FormActions/FormButtons/FormButtons';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';
import ModalConfirmEmptySlot from 'hooks/scheduler/ModalConfirmEmptySlot';
import { Calendar } from 'react-date-range';
import { useModalCreateSlot } from './useModalCreateSlot';
interface Props {
  show: boolean;
  shifts: Shift[];
  date: string;
  onHide: () => void;
}

const ModalCreateSlot = (props: Props) => {
  const { show, shifts, date, onHide } = { ...props };
  const {
    modalWarningVisible,
    setModalWarningVisible,
    modalConfirmEmptyVisible,
    setModalConfirmEmptyVisible,
    isShowConfirmChangeModal,
    setIsShowConfirmChangeModal,
    formik,
    onDateChange,
    onChangeShift,
    shiftDuration,
    shiftId,
    initialValues,
    changingValue,
    onProceed,
    onKeepGoing,
    submittingType,
    setSubmittingType,
    project
  } = useModalCreateSlot(props);

  const hasDivision = () => {
    return project && project.divisions.length > 0;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        centered
        className="handle"
        scrollable
        dialogAs={DraggableModalDialog}
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title
            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
            }}
          >
            Assign Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <FormikProvider value={formik}>
            <Form>
              <Form.Row>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="bottom-end"
                  overlay={
                    <Popover
                      id="popover-basic"
                      style={{ maxWidth: '600px' }}
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <Popover.Content style={{ padding: 0 }}>
                        <Calendar
                          onChange={(item) => onDateChange(item)}
                          date={parse(date, 'yyyy-MM-dd', new Date())}
                        />
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Form.Group as={Col} xs={6} lg={3} controlId="date">
                    <Form.Label className="text-start">Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.date}
                      isInvalid={formik.touched.date && !!formik.errors.date}
                      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation();
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </OverlayTrigger>
                <Form.Group as={Col} xs={6} lg={{ span: 2, offset: 1 }} controlId="shift">
                  <Form.Label className="text-start">Shift</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Employeee"
                    onBlur={formik.handleBlur}
                    onChange={(e) => onChangeShift(e)}
                    name={`shiftId`}
                    value={formik.values.shiftId}
                    custom
                    onMouseDown={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    {props.shifts.map((shift) => (
                      <option key={shift._id} value={shift._id}>
                        {shift.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <ShiftInformation shifts={shifts} shiftDuration={shiftDuration} formik={formik} />
              </Form.Row>
              <Row>
                <Col
                  xs={hasDivision() ? 3 : 4}
                  onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                  }}
                >
                  Employee Name
                </Col>
                {project && project.divisions.length > 0 && (
                  <Col
                    xs={2}
                    style={{ paddingLeft: '8px' }}
                    onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                    }}
                  >
                    Division
                  </Col>
                )}
                <Col
                  xs={hasDivision() ? 2 : 3}
                  style={{ paddingLeft: '8px' }}
                  onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                  }}
                >
                  Duration
                </Col>
              </Row>
              <FieldArray
                name="employees"
                render={(arrayHelpers) => (
                  <>
                    {formik.values.employees.map((agentCount: any, agentIndex: number) => (
                      <React.Fragment key={agentIndex}>
                        <FieldArray
                          name={`employees[${agentIndex}]`}
                          render={(agentCountHelpers) => (
                            <>
                              {agentCount.map((user: any, userIndex: number) => (
                                <InputEmployeeRow
                                  key={`${agentIndex + userIndex}`}
                                  agentCount={agentCount}
                                  agentIndex={agentIndex}
                                  userIndex={userIndex}
                                  formik={formik}
                                  shifts={shifts}
                                  date={date}
                                  shiftId={shiftId}
                                  arrayHelpers={arrayHelpers}
                                  project={project}
                                  agentCountHelpers={agentCountHelpers}
                                />
                              ))}
                              {agentCount[0].userId === 'multiple' && (
                                <ButtonAddEmployee
                                  isAddingSubEmpl={true}
                                  agentCountHelpers={agentCountHelpers}
                                  shiftDuration={shiftDuration}
                                />
                              )}
                            </>
                          )}
                        />
                      </React.Fragment>
                    ))}
                    <ButtonAddEmployee
                      isAddingSubEmpl={false}
                      arrayHelpers={arrayHelpers}
                      shiftDuration={shiftDuration}
                    />
                  </>
                )}
              />
            </Form>
          </FormikProvider>
          <ModalConfirm
            show={modalWarningVisible}
            shifts={shifts}
            shiftId={shiftId}
            date={date}
            values={formik.values}
            onHide={() => setModalWarningVisible(false)}
            onSubmit={() => formik.submitForm()}
          />
          <ModalConfirmChange
            show={isShowConfirmChangeModal}
            formik={formik}
            initialValues={initialValues}
            changingValue={changingValue}
            onProceed={onProceed}
            onKeepGoing={onKeepGoing}
            onHide={() => setIsShowConfirmChangeModal(false)}
          />
          <ModalConfirmEmptySlot
            formik={formik}
            show={modalConfirmEmptyVisible}
            setShow={(show) => setModalConfirmEmptyVisible(show)}
          />
        </Modal.Body>
        <Modal.Footer>
          <FormButtons
            formik={formik}
            shifts={shifts}
            shiftId={shiftId}
            date={date}
            submittingType={submittingType}
            setSubmittingType={(value: string) => setSubmittingType(value)}
            setIsShowConfirmModal={(value: boolean) => setModalWarningVisible(value)}
            setModalConfirmEmptyVisible={(value: boolean) => setModalConfirmEmptyVisible(value)}
            onHide={onHide}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCreateSlot;
