import { Shift } from 'models';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { schedulerActions, schedulerSelector } from 'store/schedulers/scheduler.slice';
import MenuCreateSlot from '../../MainActions/MenuCreateSlot/MenuCreateSlot';
import MenuUpdateSlot from '../../MainActions/MenuUpdateSlot/MenuUpdateSlot';
import ModalCreateSlot from '../../MainActions/ModalCreateSlot/ModalCreateSlot';
import SchedulerHeader from '../SchedulerHeader/SchedulerHeader';
import SchedulerRow from '../SchedulerRow/SchedulerRow';
import {
  SchedulerBody,
  SchedulerHead,
  SchedulerTableEl,
  SchedulerTableWrapper
} from './SchedulerTable.styled';
import { slotActions, slotSelector } from 'store/slots/slot.slice';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { toast } from 'react-toastify';

interface Props {
  dates: Date[];
  datesMonth: Date[];
  shifts: Shift[];
}
const SchedulerTable = (props: Props) => {
  const dispatch = useAppDispatch();
  const isModalCreateSlotVisible = useAppSelector(schedulerSelector.selectIsModalCreateSlotVisible);
  const isUpdating = useAppSelector(slotSelector.selectIsUpdating);
  const error = useAppSelector(slotSelector.selectError);

  const date = useAppSelector(schedulerSelector.selectSelectedDate);
  const [draggingItem, setDraggingItem] = useState({});
  const [droppedItem, setDroppedItem] = useState({});
  const shifts: Shift[] = [...props.shifts];
  const datesMonthGroupBy7: any = [];

  const getDates = () => {
    const _dates = [...props.datesMonth];
    while (_dates.length > 0) {
      datesMonthGroupBy7.push(_dates.splice(0, 7));
    }
    return datesMonthGroupBy7;
  };

  useDidMountEffect(() => {
    if (!isUpdating && !error) {
      toast.success('Successfully Updated.');
    }
  }, [isUpdating]);
  useDidMountEffect(() => {
    if (!isUpdating && error) {
      toast.error((error as any)?.message || 'Something went wrong');
    }
  }, [error]);

  return (
    <>
      {
        <SchedulerTableWrapper>
          <SchedulerTableEl>
            {getDates().map((week: any, index: number) => (
              <React.Fragment key={index}>
                <SchedulerHead>
                  <SchedulerHeader dates={week} shifts={shifts} />
                </SchedulerHead>
                <SchedulerBody>
                  {shifts.map((s) => (
                    <SchedulerRow
                      key={s._id}
                      shift={s}
                      dates={week}
                      selectedDates={props.dates}
                      setDraggingItem={setDraggingItem}
                      setDroppedItem={setDroppedItem}
                    />
                  ))}
                </SchedulerBody>
              </React.Fragment>
            ))}
          </SchedulerTableEl>
        </SchedulerTableWrapper>
      }
      {isModalCreateSlotVisible && (
        <ModalCreateSlot
          show={isModalCreateSlotVisible}
          shifts={props.shifts}
          date={date}
          onHide={() => dispatch(schedulerActions.setIsModalCreateSlotVisible(false))}
        />
      )}
      <MenuUpdateSlot draggingItem={draggingItem} droppedItem={droppedItem} shifts={props.shifts} />
      <MenuCreateSlot draggingItem={draggingItem} droppedItem={droppedItem} />
    </>
  );
};

export default SchedulerTable;
