import { FcMultiselect } from 'components/Shared';
import { FieldArray, FormikProvider } from 'formik';
import { Division, GetDivisionsPayload, Project, UserProject } from 'models';
import { useRef, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { divisionActions, divisionSelector } from 'store/divisions/division.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface Props {
  formik: any;
  projects: Project[];
  isUpdating: boolean;
  // project?: Project;
}

const ProjectForm = (props: Props) => {
  const { formik, isUpdating } = { ...props };
  const dispatch = useAppDispatch();
  const divisionsRef = useRef();

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState<boolean>(false);
  const divisionsModalAddUser = useAppSelector(divisionSelector.selectDivisionsModalAddUser);

  const setDivisions = (val: Division[], index: number) => {
    formik.setFieldValue(`projects[${index}].divisions`, val);
  };

  const btnShowPassword = () => {
    if (!isShowPassword) {
      return <i className="fas fa-eye text-dark" onClick={() => setIsShowPassword(true)} />;
    } else {
      return <i className="fas fa-eye-slash text-dark" onClick={() => setIsShowPassword(false)} />;
    }
  };

  const btnShowPasswordConfirm = () => {
    if (!isShowPasswordConfirm) {
      return <i className="fas fa-eye text-dark" onClick={() => setIsShowPasswordConfirm(true)} />;
    } else {
      return (
        <i className="fas fa-eye-slash text-dark" onClick={() => setIsShowPasswordConfirm(false)} />
      );
    }
  };

  const onSelectProject = (project: UserProject, index: number, e: any) => {
    // Dispatch an action to get divisions for the selected project
    dispatch(divisionActions.getDivisions({ projectId: e.target.value } as GetDivisionsPayload));

    // Update the formik values for the selected project
    const fieldPath = `projects[${index}]`;
    formik.setFieldValue(`${fieldPath}.project._id`, e.target.value);
    formik.setFieldValue(`${fieldPath}.divisions`, [{ _id: '', name: '' }]);

    // Check if divisionsRef exists and is not null before calling its method
    if ((divisionsRef?.current as any)?.onReset) {
      (divisionsRef as any).current.onReset();
    }
  };

  const getDivisionsOfProject = (projectId: string) => {
    const res = divisionsModalAddUser.find((item) => item.projectId === projectId);
    return res ? res.divisions : [];
  };

  const getSelectedDivisions = (index: number) => {
    const divisions = formik.values.projects[index]?.divisions || [];
    const filteredDivisions = divisions.filter((division: Division) => division._id.length !== 0);
    if (filteredDivisions.length !== 0) {
      return filteredDivisions;
    }
    return undefined;
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <h6 className="heading-small text-muted mb-2">Account Information</h6>
        <div className="pl-lg-4">
          <Form.Row>
            <Form.Group as={Col} className="mb-3" controlId="username">
              <Form.Label className="required">Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                name="username"
                autoComplete="nope"
                isInvalid={formik.touched.username && !!formik.errors.username}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="employeeCode">
              <Form.Label className="required">Employee code</Form.Label>
              <Form.Control
                type="number"
                placeholder="Employee code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.employeeCode}
                name="employeeCode"
                isInvalid={formik.touched.employeeCode && !!formik.errors.employeeCode}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.employeeCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="email">
              <Form.Label className="required">Work Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="displayName">
              <Form.Label className="required">Display name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Display name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.displayName}
                name="displayName"
                isInvalid={formik.touched.displayName && !!formik.errors.displayName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.displayName}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="mb-3" controlId="role">
              <Form.Label className="required">Role</Form.Label>
              <Form.Control
                as="select"
                placeholder="Enable"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
                value={formik.values.role}
                name="role"
                custom
              >
                <option value="MANAGER">Manager</option>
                <option value="AGENT">Agent</option>
              </Form.Control>
            </Form.Group>
            {formik.values.role === 'AGENT' && (
              <Form.Group as={Col} className="mb-3" controlId="userType">
                <Form.Label className="required">User Type</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Enable"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  value={formik.values.userType}
                  name="userType"
                  custom
                >
                  <option value="FULLTIME">Full Time</option>
                  <option value="PARTIME">Part Time</option>
                </Form.Control>
              </Form.Group>
            )}
            {!isUpdating && (
              <Form.Group as={Col} className="mb-3" controlId="twoFA">
                <Form.Label className="required">2FA</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Enable"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  value={formik.values.twoFA}
                  name="twoFA"
                  custom
                >
                  <option value="ENABLE">Enable</option>
                  <option value="DISABLE">Disabled</option>
                  <option value="RESET">Reset 2FA</option>
                </Form.Control>
              </Form.Group>
            )}
          </Form.Row>
          {!isUpdating && (
            <Form.Row>
              <Col sm="12" md="6">
                <Form.Label className="required">Password</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    name="password"
                    autoComplete="nope"
                    isInvalid={formik.touched.password && !!formik.errors.password}
                  />
                  <InputGroup.Append className="clickable">
                    <InputGroup.Text>{btnShowPassword()}</InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Col sm="12" md="6">
                <Form.Label className="required">Password (again)</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type={isShowPasswordConfirm ? 'text' : 'password'}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.passwordConfirmation}
                    name="passwordConfirmation"
                    autoComplete="nope"
                    isInvalid={
                      formik.touched.passwordConfirmation && !!formik.errors.passwordConfirmation
                    }
                  />
                  <InputGroup.Append className="clickable">
                    <InputGroup.Text>{btnShowPasswordConfirm()}</InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.passwordConfirmation}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Row>
          )}
        </div>

        <hr className="my-2" />

        <h6 className="heading-small text-muted mb-2">Personal Information</h6>
        <div className="pl-lg-4">
          <Form.Row>
            <Form.Group as={Col} className="mb-3" controlId="fullName">
              <Form.Label className="required">Full name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Full name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullName}
                name="fullName"
                isInvalid={formik.touched.fullName && !!formik.errors.fullName}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.fullName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="personalEmail">
              <Form.Label className="required">Personal Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Personal Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.personalEmail}
                name="personalEmail"
                isInvalid={formik.touched.personalEmail && !!formik.errors.personalEmail}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.personalEmail}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="phone">
              <Form.Label className="required">Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                name="phone"
                isInvalid={formik.touched.phone && !!formik.errors.phone}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </div>

        <hr className="my-2" />
        <h6 className="heading-small text-muted mb-2">Assignment Information</h6>
        <div className="pl-lg-4">
          <FieldArray
            name="projects"
            render={(arrayHelpers) => (
              <>
                {formik.values.projects.map((project: UserProject, index: number) => (
                  <Form.Row key={index}>
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId={`projects[${index}].project._id`}
                    >
                      <Form.Label>Project</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Select Project"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          onSelectProject(project, index, e);
                        }}
                        onMouseDown={(e: any) => {
                          e.stopPropagation();
                        }}
                        disabled={isUpdating}
                        name={`projects[${index}].project._id`}
                        value={formik.values.projects[index].project._id}
                        custom
                      >
                        <option value="">Select Project</option>
                        {props.projects.map((project: Project) => (
                          <option key={project._id} value={project._id}>
                            {project.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId={`projects-[${index}].divisions`}
                    >
                      <Form.Label>Divisions</Form.Label>
                      <FcMultiselect
                        data={getDivisionsOfProject(project.project._id)}
                        displayValue={'name'}
                        selectedValues={getSelectedDivisions(index)}
                        onSelect={(val: Project[]) => {
                          setDivisions(val, index);
                        }}
                        onRemove={(val: Project[]) => {
                          setDivisions(val, index);
                        }}
                        ref={divisionsRef}
                      />
                    </Form.Group>
                  </Form.Row>
                ))}
                {!isUpdating && (
                  <span
                    className="text-primary font-weight-bold clickable"
                    onClick={() => {
                      arrayHelpers.push({
                        project: {
                          _id: '',
                          name: ''
                        },
                        divisions: [{ _id: '', name: '' }]
                      });
                    }}
                    onMouseDown={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    + Add Project
                  </span>
                )}
              </>
            )}
          />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default ProjectForm;
