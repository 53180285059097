import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Availability,
  ScheduleAvailabilityBreakdownShift,
  ScheduleAvailabilityPreview,
  initialState
} from './scheduleAvailability.initialState';
import { ScheduleAvailabilityModel } from '../../models/scheduleAvailability.model';

const scheduleAvailabilitySlice = createSlice({
  name: 'scheduleAvailability',
  initialState,
  reducers: {
    getListOfAvailability: (
      state,
      {
        payload
      }: PayloadAction<{
        projectId: string;
      }>
    ) => {
      const { projectId } = payload;
      state.isFetching = true;
      state.error = null;
      state.projectId = projectId;
    },
    getListOfAvailabilitySuccess: (state, { payload }: PayloadAction<Availability[]>) => {
      state.isFetching = false;
      state.error = null;
      state.availabilityList = payload;
    },
    getListOfAvailabilityFailed: (
      state,
      {
        payload
      }: PayloadAction<{
        error: string;
      }>
    ) => {
      const { error } = payload;
      state.isFetching = false;
      state.error = error;
    },
    setEnableEditing: (
      state,
      {
        payload
      }: PayloadAction<{
        enabled: boolean;
      }>
    ) => {
      const { enabled } = payload;
      state.enableEditing = enabled;
    },
    createAvailability: (
      state,
      { payload }: PayloadAction<{ userId: string; weekday: number; shiftId: string }>
    ) => {
      state.creatingAvailability = {
        ...payload
      };

      state.createdAvailability = null;
      state.error = null;
    },
    createAvailabilitySuccess: (
      state,
      {
        payload
      }: PayloadAction<{
        availability: ScheduleAvailabilityModel;
      }>
    ) => {
      const { availability } = payload;
      state.creatingAvailability = null;
      state.createdAvailability = availability;
    },
    createAvailabilityFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.error = payload.error;
      state.createdAvailability = null;
    },
    deleteAvailability: (state, { payload }: PayloadAction<{ availabilityId: string }>) => {
      state.deletingAvailability = payload.availabilityId;
      state.error = null;
      state.deletedAvailability = null;
    },
    deleteAvailabilitySuccess: (state, { payload }: PayloadAction<{ availabilityId: string }>) => {
      state.deletingAvailability = null;
      state.error = null;
      state.deletedAvailability = payload.availabilityId;
    },
    deleteAvailabilityFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.error = payload.error;
      state.deletedAvailability = null;
      state.deletingAvailability = null;
    },
    requestApplyChanges: (state, { payload }: PayloadAction<ScheduleAvailabilityPreview[]>) => {
      state.applyChanges.sending = true;
      state.applyChanges.error = null;
      state.applyChanges.payload = payload;
      state.applyChanges.message = null;
    },
    requestApplyChangesSuccess: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.applyChanges.sending = false;
      state.applyChanges.error = null;
      state.applyChanges.message = payload.message;
    },
    requestApplyChangesFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.applyChanges.sending = false;
      state.applyChanges.error = payload.error;
      state.applyChanges.message = null;
    },
    requestAvailabilityBreakdown: (state, { payload }: PayloadAction<{ projectId: string }>) => {
      state.breakdown.fetching = true;
      state.breakdown.error = null;
      state.breakdown.projectId = payload.projectId;
    },
    requestAvailabilityBreakdownSuccess: (
      state,
      {
        payload
      }: PayloadAction<{
        data: ScheduleAvailabilityBreakdownShift[];
      }>
    ) => {
      state.breakdown.fetching = false;
      state.breakdown.error = null;
      state.breakdown.data = payload.data;
    },
    requestAvailabilityBreakdownFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.breakdown.fetching = false;
      state.breakdown.error = payload.error;
    },
    agentClearAvaiability: (state, { payload }: PayloadAction<{ projectId: string }>) => {
      state.projectId = payload.projectId;
      state.isFetching = true;
      state.error = null;
      state.recentClear = false;
    },
    agentClearAvailabilitySuccess: (state) => {
      state.isFetching = false;
      state.error = null;
      state.recentClear = true;
    },
    agentClearAvailabilityFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.isFetching = false;
      state.error = payload.error;
      state.recentClear = false;
    },
    triggerNoti: (state, { payload }: PayloadAction<string>) => undefined,
    triggerNotiSuccess: (state) => undefined,
    triggerNotiFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.error = payload.error;
    },
    setShowAllNote: (
      state,
      {
        payload
      }: PayloadAction<{
        showAllNote: boolean;
      }>
    ) => undefined,
    setShowAllNoteSuccess: (
      state,
      {
        payload
      }: PayloadAction<{
        showAllNote: boolean;
      }>
    ) => {
      const { showAllNote } = payload;
      state.showAllNote = showAllNote;
    }
  }
});

export const { actions: scheduleAvailabilityActions, reducer: scheduleAvailabilityReducer } =
  scheduleAvailabilitySlice;
