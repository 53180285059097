import cloneDeep from 'lodash.clonedeep';
import { FixedShift, Shift, Slot, FixedSlot, UserSlot, Division } from 'models';
import { getDivision, getDivisionId } from './division.helper';

export class SlotHelper {
  droppedItem: any;
  draggingItem: any;
  shifts: Shift[] | FixedShift[] = [];
  droppedUserSlots: UserSlot[] = [];
  draggingUserSlots: UserSlot[] = [];

  constructor(droppedItem: any, draggingItem: any, shifts: Shift[] | FixedShift[]) {
    this.droppedItem = droppedItem;
    this.draggingItem = draggingItem;

    this.shifts = cloneDeep(shifts);
    this.droppedUserSlots = findUserSlotsOf(this.droppedItem, this.shifts);
    this.draggingUserSlots = findUserSlotsOf(this.draggingItem, this.shifts);
  }

  handleUpdateSlot(type: string) {
    switch (type) {
      case 'replace':
        return this.replace();
      case 'swap':
        return this.swap();
      case 'merge':
        return this.merge();
      case 'paste':
        return this.paste();
      case 'create':
        return this.create();
      case 'duplicate':
        return this.duplicate();
      default:
        console.log('handleUpdateSlot error');
        throw new Error('Something went wrong.');
    }
  }

  handleUpdateShifts(): (Shift | FixedShift)[] {
    this.shifts = this.getNewShifts('drag');
    this.shifts = this.getNewShifts('drop');
    return this.shifts;
  }

  getNewSlot(type: string) {
    let item: any;
    let userSlots: UserSlot[];
    let division: Division;

    if (type === 'drag') {
      item = this.draggingItem;
      userSlots = this.draggingUserSlots;
      division = getDivision(this.draggingItem?.division);
    } else {
      item = this.droppedItem;
      userSlots = this.droppedUserSlots;
      division = getDivision(this.droppedItem?.division);
    }
    return { item, userSlots, division };
  }
  getNewShifts(type: string): Shift[] | FixedShift[] {
    const { item, userSlots, division } = this.getNewSlot(type);
    console.log(division);
    return this.shifts.map((s) => {
      if (s._id === item.shiftId) {
        const slots: Slot[] | FixedSlot[] = cloneDeep(s.slots);
        const index = slots.findIndex((slot) => slot._id === item.slotId);
        slots[index].userSlots = userSlots;
        slots[index].division = [division];
        return {
          ...s,
          slots: getUpdatedSlots(item, userSlots, slots)
        };
      }
      return s;
    });
  }

  replace() {
    let resDroppedUserSlots: any = this.droppedUserSlots;
    let resDraggingUserSlots: any = [];
    //======update dropped item=======
    if (this.draggingItem.type === 'event') {
      if (this.droppedItem.type === 'event') {
        resDroppedUserSlots[this.droppedItem.index] = {
          userId: this.draggingItem.userId,
          duraqtion: this.draggingItem.duration,
          note: this.draggingItem.note,
          user: this.draggingItem.user
        };
      } else if (this.droppedItem.type === 'shift') {
        resDroppedUserSlots = [
          {
            userId: this.draggingItem.userId,
            duration: this.draggingItem.duration,
            note: this.draggingItem.note,
            user: this.draggingItem.user
          }
        ];
      }
    } else if (this.draggingItem.type === 'shift') {
      if (this.droppedItem.type === 'shift') {
        resDroppedUserSlots = this.draggingUserSlots;
      } else if (this.droppedItem.type === 'event') {
        const userSlots = cloneDeep([...this.droppedUserSlots, ...this.draggingUserSlots]);
        userSlots.splice(this.droppedItem.index, 1);
        resDroppedUserSlots = userSlots;
      }
    }
    // =======END_UPDATE_DROPPED======

    // ======update dragging item =======
    if (this.draggingItem.type === 'event') {
      resDraggingUserSlots = cloneDeep(this.draggingUserSlots);
      resDraggingUserSlots.length > 1
        ? resDraggingUserSlots.splice(this.draggingItem.index, 1)
        : (resDraggingUserSlots = []);
    } else if (this.draggingItem.type === 'shift') {
      resDraggingUserSlots = [];
    }
    this.draggingUserSlots = resDraggingUserSlots;
    this.droppedUserSlots = resDroppedUserSlots;
    // =======END_UPDATE_DRAGGING======
    return {
      draggingUserSlots: resDraggingUserSlots,
      droppedUserSlots: resDroppedUserSlots
    };
  }

  paste() {
    if (this.droppedItem.type === 'event') {
      this.droppedUserSlots.splice(this.droppedItem.index, 1);
    } else {
      this.droppedUserSlots = [];
    }
    if (this.draggingItem.type === 'event') {
      this.droppedUserSlots.push({
        userId: this.draggingItem.userId,
        duration: this.draggingItem.duration,
        note: this.draggingItem.note,
        user: this.draggingItem.user
      } as UserSlot);
    } else {
      this.draggingUserSlots.forEach((usrSlot) => {
        this.droppedUserSlots.push(usrSlot);
      });
    }

    return { draggingUserSlots: this.draggingUserSlots, droppedUserSlots: this.droppedUserSlots };
  }

  swap() {
    if (this.draggingItem.type === 'event') {
      if (this.droppedItem.type === 'event') {
        const tempUserSlot = this.draggingUserSlots[this.draggingItem.index];
        this.draggingUserSlots[this.draggingItem.index] = {
          ...this.droppedUserSlots[this.droppedItem.index]
        };
        this.droppedUserSlots[this.droppedItem.index] = { ...tempUserSlot };
      } else if (this.droppedItem.type === 'shift') {
        this.droppedUserSlots = [
          {
            userId: this.draggingItem.userId,
            duration: this.draggingItem.duration,
            note: this.draggingItem.note,
            user: this.draggingItem.user
          }
        ];
        const droppedUserSlotsBeforeUpdating = findUserSlotsOf(this.droppedItem, this.shifts);
        const _draggingUserSlots = findUserSlotsOf(this.draggingItem, this.shifts);
        _draggingUserSlots.splice(this.draggingItem.index, 1);
        _draggingUserSlots?.forEach((usrSlot: UserSlot) =>
          droppedUserSlotsBeforeUpdating?.push(usrSlot)
        );

        this.draggingUserSlots = droppedUserSlotsBeforeUpdating;
      }
    } else if (this.draggingItem.type === 'shift') {
      if (this.droppedItem.type === 'event') {
        this.draggingUserSlots = [
          {
            userId: this.droppedItem.userId,
            duration: this.droppedItem.duration,
            note: this.droppedItem.note,
            user: this.droppedItem.user
          }
        ];

        const draggingUserSlotsBeforeUpdating = findUserSlotsOf(this.draggingItem, this.shifts);
        const _droppedUserSlots = findUserSlotsOf(this.droppedItem, this.shifts);
        _droppedUserSlots.splice(this.droppedItem.index, 1);
        _droppedUserSlots?.forEach((usrSlot: UserSlot) =>
          draggingUserSlotsBeforeUpdating?.push(usrSlot)
        );

        this.droppedUserSlots = draggingUserSlotsBeforeUpdating;
      } else if (this.droppedItem.type === 'shift') {
        this.droppedUserSlots = findUserSlotsOf(this.draggingItem, this.shifts);
        this.draggingUserSlots = findUserSlotsOf(this.droppedItem, this.shifts);
      }
    }
    return { draggingUserSlots: this.draggingUserSlots, droppedUserSlots: this.droppedUserSlots };
  }

  create() {
    this.droppedUserSlots = [];
    if (this.draggingItem.type === 'event') {
      this.droppedUserSlots.push({ ...this.draggingUserSlots[this.draggingItem.index] });
      this.draggingUserSlots.splice(this.draggingItem.index, 1);
    } else if (this.draggingItem.type === 'shift') {
      this.droppedUserSlots = [...this.draggingUserSlots];
      this.draggingUserSlots = [];
    }
    return { draggingUserSlots: this.draggingUserSlots, droppedUserSlots: this.droppedUserSlots };
  }

  duplicate() {
    if (this.draggingItem.type === 'event') {
      this.droppedUserSlots.push({ ...this.draggingUserSlots[this.draggingItem.index] });
    } else if (this.draggingItem.type === 'shift') {
      this.droppedUserSlots = [...this.draggingUserSlots];
    }
    return { draggingUserSlots: this.draggingUserSlots, droppedUserSlots: this.droppedUserSlots };
  }

  merge() {
    if (this.draggingItem.type === 'event') {
      this.droppedUserSlots.push({
        userId: this.draggingItem.userId,
        duration: this.draggingItem.duration,
        note: this.draggingItem.note,
        user: this.draggingItem.user
      } as UserSlot);
      this.draggingUserSlots = getDraggingNewUserSlots(
        this.droppedItem,
        this.draggingItem,
        this.shifts
      );
      return { draggingUserSlots: this.draggingUserSlots, droppedUserSlots: this.droppedUserSlots };
    } else if (this.draggingItem.type === 'shift') {
      this.draggingUserSlots.forEach((usrSlot: UserSlot) => {
        this.droppedUserSlots?.push(usrSlot);
      });
      this.draggingUserSlots = [];
      return { draggingUserSlots: this.draggingUserSlots, droppedUserSlots: this.droppedUserSlots };
    }
  }

  updateShifts() {
    this.shifts = getNewShifts(
      this.droppedItem,
      this.shifts,
      this.droppedUserSlots
    ) as FixedShift[];
    this.shifts = getNewShifts(
      this.draggingItem,
      this.shifts,
      this.draggingUserSlots
    ) as FixedShift[];
    return this.shifts;
  }
}

export const findUserSlotsOf = (item: any, shifts: Shift[] | FixedShift[]): UserSlot[] => {
  const shift = cloneDeep((shifts as any).find((s: Shift | FixedShift) => s._id === item.shiftId));
  const slot = shift.slots.find((slot: Slot | FixedSlot) => slot._id === item.slotId);
  if (slot !== undefined) {
    return slot.userSlots.map((userSlot: UserSlot) => {
      return {
        userId: userSlot.user._id,
        duration: userSlot.duration,
        note: userSlot.note,
        user: userSlot.user
      } as UserSlot;
    });
  }
  return [];
};

export const getDraggingNewUserSlots = (
  droppedItem: any,
  draggingItem: any,
  shifts: Shift[] | FixedShift[]
) => {
  let draggingUserSlots: UserSlot[] = findUserSlotsOf(draggingItem, shifts);
  if (draggingItem.type === 'event') {
    if (draggingUserSlots.length > 1) {
      draggingUserSlots.splice(draggingItem.index, 1);
    } else {
      draggingUserSlots = [];
    }
    return draggingUserSlots;
  }
  return [];
};

export const getNewShifts = (
  item: any,
  shifts: Shift[] | FixedShift[],
  userSlots: UserSlot[]
): Shift[] | FixedShift[] => {
  return shifts.map((s) => {
    if (s._id === item.shiftId) {
      const slots: Slot[] | FixedSlot[] = cloneDeep(s.slots);
      const index = slots.findIndex((slot) => slot._id === item.slotId);
      slots[index].userSlots = userSlots;
      slots[index].division = getDivision(item?.division);
      return {
        ...s,
        slots: getUpdatedSlots(item, userSlots, slots)
      };
    }
    return s;
  });
};

const getUpdatedSlots = (item: any, userSlots: UserSlot[], slots: Slot[] | FixedSlot[]) => {
  if (userSlots.length === 0) {
    return (slots as any).filter((slot: Slot | FixedSlot) => slot._id !== item.slotId);
  } else {
    return slots;
  }
};
