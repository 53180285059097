import styled from 'styled-components';

export const TypeSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
`;

export const TypeSelectButton = styled.div`
  border: 1px solid var(--light);
  padding: 1rem;
  width: 150px;
  height: 100%;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.375rem;
  &:hover {
    cursor: pointer;
    border-color: var(--primary);
    color: var(--primary);
  }
`;
