import { Pagination } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

interface Props {
  onChangePage: (page: number) => void;
  totalItems: number;
  perPage: number;
  currentPage: number;
  // setCurrentPage: (page: number) => void;
}
export const FcPagination = (props: Props) => {
  const [totalPage, setTotalPage] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + props.perPage;
    let _totalPage = Math.ceil(props.totalItems / props.perPage);
    if (_totalPage === 0) {
      _totalPage = 1;
    }
    setTotalPage(_totalPage);
  }, [itemOffset, props.perPage, props.totalItems]);

  const handlePageClick = (event: any) => {
    const nextPage = event.selected + 1;
    // props.setCurrentPage(nextPage);
    const newOffset = (nextPage * props.perPage) % props.totalItems;
    props.onChangePage(nextPage);
    setItemOffset(newOffset);
  };

  return (
    <ReactPaginate
      nextLabel=">"
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      pageCount={totalPage}
      previousLabel="<"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
      forcePage={props.currentPage - 1}
    />
  );
};
