import { Card } from 'react-bootstrap';
import { RequestLogTab } from '../RequestLogTab/RequestLogTab';
import RequestLogShiftChangeSummary from './RequestLogShiftChangeSummary';
import RequestLogOffVacationSummary from './RequestLogOffVacationSummary';

const RequestLog = () => {
  const { selectedTab, Tab } = RequestLogTab();
  return (
    <Card className="shadow">
      <Card.Header className="border-0">
        <Tab />
      </Card.Header>
      <Card.Body>
        {selectedTab === 'SHIFTCHANGE' && <RequestLogShiftChangeSummary />}
        {selectedTab === 'OFFVACATION' && <RequestLogOffVacationSummary />}
      </Card.Body>
    </Card>
  );
};

export default RequestLog;
