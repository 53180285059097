import { ShiftChangeFormProps, OffVacationRequestLog } from 'models';
import { useAppDispatch } from 'store/hooks';
// import { notificationActions } from 'store/notifications/notification.slice';
import { RequestLogListItemWrapper } from './OffVacationRequestLogListItem.styled';
import { Col } from 'react-bootstrap';
import {
  formatTo_HHMM,
  getReadableDurationString,
  formatTo_yyyyMMdd
} from 'utils/formatters/datetime.formatter';
import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { shiftChangeSchema } from 'utils/validators';
import OffVacationRequestLogDetail from './OffVacationRequestLogDetail';

interface Props {
  data: OffVacationRequestLog;
  type: string;
}
const OffVacationRequestLogListItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const { data, type } = { ...props };
  const [isVisible, setIsVisible] = useState(false);

  return (
    <RequestLogListItemWrapper className={'p-3'}>
      <Col xs={8}>
        <span>{data.requester?.displayName}</span>
      </Col>
      <Col xs={2} className="text-right">
        <span>
          {formatTo_HHMM(new Date(data.createdAt))}{' '}
          {getReadableDurationString(new Date(data.createdAt))}
        </span>
      </Col>
      <Col xs={2} className="text-right">
        <Button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setIsVisible(true);
          }}
        >
          Review
        </Button>
        <Modal
          className="modal-confirm"
          show={isVisible}
          onHide={() => {
            setIsVisible(false);
          }}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Request Log Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-0">
            <OffVacationRequestLogDetail data={data} selectedType={type} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={() => setIsVisible(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </RequestLogListItemWrapper>
  );
};

export default OffVacationRequestLogListItem;
