import { useEffect, useState, useRef } from 'react';
import { Col, Card } from 'react-bootstrap';
import { ScheduleGlanceItemWrapper } from '../ScheduleGlanceList/ScheduleGlanceItem.styled';
import { ScheduleGlance } from 'models/scheduleGlance.model';
import {
  formatTo_yyyyMMdd,
  getReadableDurationString,
  toDateFromString
} from 'utils/formatters/datetime.formatter';

interface Props {
  scheduleGlancesOwner: ScheduleGlance[];
  scheduleGlancesAgent: ScheduleGlance[];
  glanceDateArr: string[];
  showHeader: boolean;
}

const ScheduleGlanceList = (props: Props) => {
  const { scheduleGlancesOwner, scheduleGlancesAgent, glanceDateArr, showHeader } = { ...props };

  const scheduleGlanceList = (dateStr: string, list: ScheduleGlance[]) => {
    let count = 0;
    return list.map((sg, index) => {
      if (dateStr === getReadableDurationString(new Date(sg.date))) {
        count++;
        if (count == 1) {
          return (
            <span className={'font-weight-bold'} key={index}>
              {sg.shift.name}
            </span>
          );
        } else {
          return (
            <span className={'font-weight-bold'} key={index}>
              {', ' + sg.shift.name}
            </span>
          );
        }
      }
    });
  };

  return (
    <Card className="shadow py-2">
      {showHeader && (
        <Card.Header className="border-0 p-0 ">
          <div className="d-flex p-3">
            <Col xs={12}>
              <h5 className="h4 mb-0 text-center">Note: Your Schedule At A Glance</h5>
            </Col>
          </div>
        </Card.Header>
      )}
      <Card.Body className="card-body p-0">
        <div>
          <ScheduleGlanceItemWrapper key="-1" className={' p-3'}>
            <Col xs={4}>
              {scheduleGlancesOwner && scheduleGlancesOwner.length > 0 && (
                <span className={'font-weight-bold'}>{scheduleGlancesOwner[0].user.username}</span>
              )}
            </Col>
            <Col xs={4} className="text-center">
              <span></span>
            </Col>

            <Col xs={4} className="text-right">
              {scheduleGlancesAgent && scheduleGlancesAgent.length > 0 && (
                <span className={'font-weight-bold'}>{scheduleGlancesAgent[0].user.username}</span>
              )}
            </Col>
          </ScheduleGlanceItemWrapper>

          {glanceDateArr &&
            glanceDateArr.length > 0 &&
            glanceDateArr.map((dateStr, index) => (
              <>
                <ScheduleGlanceItemWrapper key={index} className={' p-3'}>
                  <Col xs={4}>
                    {scheduleGlancesOwner &&
                      scheduleGlancesOwner.length > 0 &&
                      scheduleGlanceList(dateStr, scheduleGlancesOwner)}
                  </Col>
                  <Col xs={4} className="text-center">
                    <span className={'font-weight-bold'}>{dateStr}</span>
                  </Col>
                  <Col xs={4} className="text-right">
                    {scheduleGlancesAgent &&
                      scheduleGlancesAgent.length > 0 &&
                      scheduleGlanceList(dateStr, scheduleGlancesAgent)}
                  </Col>
                </ScheduleGlanceItemWrapper>
              </>
            ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ScheduleGlanceList;
