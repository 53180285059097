import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './comment.initialState';
import { CreateCommentPayload, Comment, UpdateCommentPayload } from 'models';

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    createComment: (state, { payload }: PayloadAction<CreateCommentPayload>) => {
      state.isCreating = true;
      state.error = null;
    },
    createCommentSuccess: (state, { payload }: PayloadAction<Comment>) => {
      state.isCreating = false;
      state.error = null;
    },
    createCommentFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = false;
      state.error = payload;
    },
    updateComment: (state, { payload }: PayloadAction<UpdateCommentPayload>) => {
      state.isUpdating = true;
      state.error = null;
    },
    updateCommentSuccess: (state, { payload }: PayloadAction<Comment>) => {
      state.isUpdating = false;
      state.error = null;
    },
    updateCommentFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    deleteComment: (state, { payload }: PayloadAction<Comment>) => {
      state.isDeleting = true;
      state.error = null;
    },
    deleteCommentSuccess: (state, { payload }: PayloadAction<Comment>) => {
      state.isDeleting = false;
      state.error = null;
    },
    deleteCommentFailed: (state, { payload }: PayloadAction<any>) => {
      state.isDeleting = false;
      state.error = payload;
    }
  }
});

export const commentActions = commentSlice.actions;

export * as commentSelector from './comment.selector';

export default commentSlice.reducer;
