import { SpinnerButton } from 'components/Shared';
import { FormikProps, useFormik } from 'formik';
import { ButtonTypes, CreateUserPayload, UserProject } from 'models';
import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { userActions, userSelector } from 'store/users/user.slice';
import { userSchema } from 'utils/validators/user.validator';
import UserForm from '../../Actions/UserForm/UserForm';

interface FormProps {
  fullName: string;
  employeeCode?: number;
  username: string;
  email: string;
  displayName: string;
  password: string;
  passwordConfirmation: string;
  twoFA: string;
  role: string;
  userType: string;
  phone: string;
  projects: UserProject[];
  personalEmail: string;
}

const initialValues: FormProps = {
  fullName: '',
  employeeCode: undefined,
  username: '',
  email: '',
  personalEmail: '',
  displayName: '',
  password: '',
  passwordConfirmation: '',
  twoFA: 'ENABLE',
  role: 'AGENT',
  userType: 'FULLTIME',
  phone: '',
  projects: [
    {
      project: {
        _id: '',
        name: ''
      },
      divisions: [{ _id: '', name: '' }]
    }
  ]
};
const AddProject = () => {
  const dispatch = useAppDispatch();
  const [submittingType, setSubmittingType] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const isCreating = useAppSelector(userSelector.selectIsCreating);
  const error = useAppSelector(userSelector.selectError);
  const [isVisible, setIsVisible] = useState(false);
  const projects = useAppSelector(projectSelector.selectProjects);
  const isFetchingProjects = useAppSelector(projectSelector.selectIsFetching);

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: initialValues,
    validationSchema: userSchema,
    onSubmit: (values: FormProps, actions) => {
      handleSubmit(values, actions);
    }
  });

  useEffect(() => {
    if (formik.isSubmitting && !isCreating && error === null) {
      formik.setSubmitting(false);
      toast.success('User Created Successfully.');
      if (submittingType === 'submit') {
        setIsVisible(false);
        // dispatch(projectActions.setSelectedProject(null));
      } else {
        formik.resetForm();
      }
    }
  }, [isCreating]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.message || 'Something went wrong.');
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
    } else {
      dispatch(projectActions.getProjects());
    }
  }, [isVisible]);

  const handleSubmit = (values: FormProps, actions: any) => {
    const payload = {
      ...values,
      projects: values.projects
        .map((project) => {
          if (project.project._id === '') {
            return undefined;
          }
          return {
            project: project.project._id,
            divisions: project.divisions
              .map((division) => division._id)
              .filter((division) => division.length > 0)
          };
        })
        .filter((project) => project !== undefined)
    };
    dispatch(userActions.createUser(payload as any as CreateUserPayload));
  };

  return (
    <>
      <Button
        variant="primary"
        size="sm"
        onClick={() => {
          setIsVisible(true);
          // dispatch(projectActions.setSelectedProject(null));
        }}
      >
        New User
      </Button>

      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
          // dispatch(projectActions.setSelectedProject(null));
        }}
        size="lg"
        centered
        // scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>New User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {isFetchingProjects && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {!isFetchingProjects && (
            <UserForm
              formik={formik}
              projects={projects}
              isUpdating={false}
              // project={project === null ? undefined : project}
            ></UserForm>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setIsVisible(false)}>
            Cancel
          </Button>
          {!submitted && (
            <>
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                colorType="success"
                disabled={formik.isSubmitting && submittingType === 'apply'}
                onClick={() => {
                  setSubmittingType('apply');
                  // setSubmitted(true);
                  formik.submitForm();
                }}
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
              >
                Apply
              </SpinnerButton>
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                disabled={formik.isSubmitting || submitted}
                onClick={() => {
                  setSubmittingType('submit');
                  // setSubmitted(true);
                  formik.submitForm();
                }}
              >
                Save
              </SpinnerButton>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProject;
