import { Card } from 'react-bootstrap';
import { useReportTab } from './hooks/useReportTab';
import ReportTimesheet from './Timesheet/ReportTimesheet';
import ReportDashboard from './Dashboard/ReportDashboard';

const Report = () => {
  const { selectedTab, Tab } = useReportTab();
  return (
    <Card className="shadow">
      <Card.Header className="border-0">
        <Tab />
      </Card.Header>
      {selectedTab === 'COPY_TIMESHEET' && <ReportTimesheet />}
      {selectedTab === 'DASHBOARD' && <ReportDashboard />}
    </Card>
  );
};
export default Report;
