import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { noteActions } from 'store/notes/note.slice';
import { useAuth } from '../../hooks';
import { Shift, User, UserRole, getWeekdayNumber, getWeekdays } from '../../models';
import { ScheduleAvailabilityModel } from '../../models/scheduleAvailability.model';
import { fixedScheduledPreviewActions } from '../../store/fixedSchedulePreview/fixedScheduledPreview.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Availability } from '../../store/scheduleAvailability/scheduleAvailability.initialState';
import { scheduleAvailabilityActions } from '../../store/scheduleAvailability/scheduleAvailability.slice';
import {
  selectAvailabilities,
  selectCreatedAvailability,
  selectDeletedAvailability,
  selectEnableEditing,
  selectShowAllNote
} from '../../store/scheduleAvailability/scheduledAvailability.selector';
import { selectShifts } from '../../store/shifts/shift.selector';
import { selectUsers } from '../../store/users/user.selector';
import { Nullable } from '../../utils/common/types';
import { parseShiftTime } from '../../utils/formatters/datetime.formatter';
import { SchedulerHeaderWrapper } from '../SchedulerFix/Actions/SchedulerHeader/SchedulerHeader.styled';
import { SchedulerHead, SchedulerWrapper } from '../SchedulerFix/Scheduler.styled';
import { WithProjectProps, withProjectFilter } from '../Shared/ProjectFilter';
import {
  CellProps,
  ScheduleAvailabilityAction,
  ScheduleAvailabilityBody,
  ScheduleAvailabilityCell,
  ScheduleAvailabilityHeader,
  ScheduleAvailabilityLink,
  ScheduleAvailabilityRow,
  ScheduleAvailabilityStar,
  ScheduleAvailabilityTable,
  ScheduleAvailabilityTableWrapper,
  TableLabel
} from './ScheduleAvailability.styled';
import ScheduleAvailabilityActionButton from './ScheduleAvailabilityActionButton';
import ScheduleAvailabilityBreakdown from './ScheduleAvailabilityBreakdown';
import ScheduleAvailabilityNote from './ScheduleAvailabilityNote';
import ScheduleFixPreview from './ScheduleFixPreview';
import { useSearchParams } from 'react-router-dom';

interface Props extends WithProjectProps {
  children?: React.ReactNode;
}

interface ScheduleAvailabilityCellProps extends CellProps {
  shiftId: string;
  weekday: string;
  userId: string;
  rowDisabled: boolean;
}

const ScheduleAvailability = (props: Props): React.ReactElement<Props> => {
  const { projectId } = props;
  const weekdays = getWeekdays();
  const users: User[] = useAppSelector(selectUsers);
  const shifts: Shift[] = useAppSelector(selectShifts);
  const enableEditing = useAppSelector(selectEnableEditing);
  const availabilities = useAppSelector(selectAvailabilities);
  const createdAvailability = useAppSelector(selectCreatedAvailability);
  const deletedAvailability = useAppSelector(selectDeletedAvailability);
  const showAllNote = useAppSelector(selectShowAllNote);
  const currentUser = useAuth();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectId) {
      dispatch(scheduleAvailabilityActions.getListOfAvailability({ projectId }));
      dispatch(noteActions.getNotes({ projectId }));
      if (currentUser.role === UserRole.MANAGER) {
        dispatch(fixedScheduledPreviewActions.getFixedScheduledPreview({ projectId }));
        dispatch(scheduleAvailabilityActions.requestAvailabilityBreakdown({ projectId }));
      }
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId && createdAvailability) {
      dispatch(scheduleAvailabilityActions.getListOfAvailability({ projectId }));

      if (currentUser.role === UserRole.MANAGER) {
        dispatch(scheduleAvailabilityActions.requestAvailabilityBreakdown({ projectId }));
      }
    }
  }, [createdAvailability]);

  useEffect(() => {
    if (projectId && deletedAvailability) {
      dispatch(scheduleAvailabilityActions.getListOfAvailability({ projectId }));

      if (currentUser.role === UserRole.MANAGER) {
        dispatch(scheduleAvailabilityActions.requestAvailabilityBreakdown({ projectId }));
      }
    }
  }, [deletedAvailability]);

  const handleCreateAvailability = (e: React.MouseEvent, cell: ScheduleAvailabilityCellProps) => {
    e.preventDefault();
    const { shiftId, weekday, userId, rowDisabled } = cell;

    if (!enableEditing || rowDisabled) {
      return;
    }

    dispatch(
      scheduleAvailabilityActions.createAvailability({
        userId,
        shiftId,
        weekday: getWeekdayNumber(weekday)
      })
    );
  };

  const renderAvailabilityIcon = (
    userId: string,
    shiftId: string,
    weekday: string
  ): Nullable<string> => {
    const weekdayNumber = getWeekdayNumber(weekday);
    const userData = availabilities.find(
      (availability: Availability) => availability.userId === userId
    );

    if (!userData) {
      return null;
    }

    const userAvailability = userData.availabilities.find(
      (availability: ScheduleAvailabilityModel) =>
        availability.shift._id === shiftId && availability.weekday == weekdayNumber
    );

    if (!userAvailability) {
      return null;
    }

    return userAvailability._id;
  };

  if (!(users.length > 0) || !(shifts.length > 0)) {
    return <>No project selected</>;
  }

  const handleDeleteAvailability = (
    e: React.MouseEvent,
    availabilityId: string,
    rowDisabled: boolean
  ) => {
    e.preventDefault();

    if (!enableEditing || rowDisabled) {
      return;
    }

    dispatch(scheduleAvailabilityActions.deleteAvailability({ availabilityId }));
  };

  const handlePushToPreview = (
    e: React.MouseEvent<HTMLElement>,
    shift: Shift,
    weekday: string,
    user: User,
    rowDisabled: boolean
  ) => {
    e.preventDefault();

    if (!enableEditing || rowDisabled) {
      return;
    }

    dispatch(
      fixedScheduledPreviewActions.addNewUser({
        shift,
        weekday: getWeekdayNumber(weekday),
        user
      })
    );
  };

  const userComparation = (
    comparedValue: string
  ): ((firstUser: User, secondUser: User) => number) => {
    const compare = (firstUser: User, secondUser: User): number => {
      if (firstUser._id === comparedValue) {
        return -1;
      } else if (firstUser._id !== comparedValue && secondUser._id === comparedValue) {
        return 1;
      } else {
        return 0;
      }
    };

    return compare;
  };

  const userIdsParam = searchParams.get('userIdsParam');
  let sortedUsers = [];
  if (userIdsParam) {
    sortedUsers = [...users]
      .filter((user) => userIdsParam.includes(user._id))
      .sort(userComparation(currentUser._id));
  } else {
    // Current Agent should be on top
    sortedUsers = [...users].sort(userComparation(currentUser._id));
  }

  return (
    <SchedulerWrapper>
      <Row>
        <Col sm={currentUser.role === UserRole.MANAGER ? 6 : 12}>
          <TableLabel align="center">Availability</TableLabel>
          <ScheduleAvailabilityTableWrapper>
            <ScheduleAvailabilityTable
              bordered
              size="sm"
              responsive="sm"
              style={{ tableLayout: 'fixed' }}
            >
              <SchedulerHead>
                <SchedulerHeaderWrapper>
                  <ScheduleAvailabilityHeader key="agent-name-col" style={{ width: '60px' }}>
                    Agent
                  </ScheduleAvailabilityHeader>
                  <ScheduleAvailabilityHeader key="shift-col" style={{ width: '60px' }}>
                    Shift
                  </ScheduleAvailabilityHeader>
                  {weekdays.map((weekday: string, index: number) => {
                    return (
                      <ScheduleAvailabilityHeader key={index} style={{ width: '60px' }}>
                        {weekday}
                      </ScheduleAvailabilityHeader>
                    );
                  })}
                </SchedulerHeaderWrapper>
              </SchedulerHead>
              <ScheduleAvailabilityBody>
                {sortedUsers.sort(userComparation(currentUser._id)).map((user) => {
                  const rowDisabled =
                    currentUser.role === UserRole.AGENT && currentUser._id !== user._id;

                  const cellEditable = enableEditing && !rowDisabled;

                  return (
                    <Fragment key={`${user.username}-availability`}>
                      {shifts.map((shift, index) => {
                        return (
                          <ScheduleAvailabilityRow
                            key={`agent-${user.username}-shift-${shift.name}`}
                            disabled={rowDisabled}
                          >
                            {index === 0 && (
                              <ScheduleAvailabilityCell
                                key={`agent-${user.username}`}
                                rowSpan={
                                  !rowDisabled && showAllNote ? shifts.length + 1 : shifts.length
                                }
                                breakWord={true}
                              >
                                {user.displayName}
                              </ScheduleAvailabilityCell>
                            )}

                            <ScheduleAvailabilityCell
                              key={`shift-${shift.name}`}
                              title={`${parseShiftTime(shift.startTime)} - ${parseShiftTime(
                                shift.endTime
                              )}`}
                            >
                              {shift.name}
                            </ScheduleAvailabilityCell>
                            {getWeekdays().map((weekday: string) => {
                              const availabilityCreated = renderAvailabilityIcon(
                                user._id,
                                shift._id,
                                weekday
                              );

                              return (
                                <ScheduleAvailabilityCell
                                  enableEdit={cellEditable}
                                  key={`${user.username}-${shift.name}-${weekday}`}
                                >
                                  {availabilityCreated ? (
                                    <>
                                      <ScheduleAvailabilityStar>
                                        <FontAwesomeIcon icon={solid('star')} />
                                      </ScheduleAvailabilityStar>
                                      <ScheduleAvailabilityAction className="middle">
                                        <ScheduleAvailabilityLink
                                          href="#"
                                          onClick={(e) =>
                                            handleDeleteAvailability(
                                              e,
                                              availabilityCreated,
                                              rowDisabled
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon icon={solid('trash')} />
                                        </ScheduleAvailabilityLink>
                                        {currentUser.role === UserRole.MANAGER && (
                                          <ScheduleAvailabilityLink
                                            href="#!"
                                            onClick={(e) =>
                                              handlePushToPreview(
                                                e,
                                                shift,
                                                weekday,
                                                user,
                                                rowDisabled
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={solid('share')} />
                                          </ScheduleAvailabilityLink>
                                        )}
                                      </ScheduleAvailabilityAction>
                                    </>
                                  ) : (
                                    <ScheduleAvailabilityAction className="middle">
                                      <ScheduleAvailabilityLink
                                        href="#!"
                                        onClick={(e) =>
                                          handleCreateAvailability(e, {
                                            shiftId: shift._id,
                                            userId: user._id,
                                            weekday: weekday,
                                            rowDisabled
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon icon={solid('square-plus')} />
                                      </ScheduleAvailabilityLink>
                                    </ScheduleAvailabilityAction>
                                  )}
                                </ScheduleAvailabilityCell>
                              );
                            })}
                          </ScheduleAvailabilityRow>
                        );
                      })}

                      {/* notes */}
                      {!rowDisabled && showAllNote && (
                        <ScheduleAvailabilityRow disabled={rowDisabled}>
                          <ScheduleAvailabilityCell className="text-left">
                            Notes:
                          </ScheduleAvailabilityCell>
                          <ScheduleAvailabilityCell
                            colSpan={7}
                            isNoteInput={true}
                            className="text-left p-2"
                            breakWord={true}
                          >
                            <ScheduleAvailabilityNote projectId={projectId} user={user} />
                          </ScheduleAvailabilityCell>
                        </ScheduleAvailabilityRow>
                      )}
                      {/* notes */}
                    </Fragment>
                  );
                })}
              </ScheduleAvailabilityBody>
            </ScheduleAvailabilityTable>
          </ScheduleAvailabilityTableWrapper>
        </Col>
        {currentUser.role === UserRole.MANAGER && (
          <Col sm={6}>
            <ScheduleFixPreview />
            <ScheduleAvailabilityBreakdown />
          </Col>
        )}
      </Row>
    </SchedulerWrapper>
  );
};

export default withProjectFilter(ScheduleAvailability, ScheduleAvailabilityActionButton);
