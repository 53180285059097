import { User } from './user.model';

export enum NoteStatus {
  NONE = 'NONE',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED'
}

export type Note = {
  _id: string;
  content: string;
  status: NoteStatus;
  shift: string; //shiftId
  slot: string; // slotId
  project: string;
  userNote: User[];
  updatedAt: string;
};

export type CreateNotePayload = {
  projectId: string;
  content: string;
  status: NoteStatus;
  userTags: string[];
  userNote: string;
};

export type UpdateNotePayload = {
  noteId: string;
  status: string;
  content: string;
};
