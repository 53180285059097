import React, { useState } from 'react';
import '../../Scheduler.scss';
import { EventItem, EventName, EventHourCount } from './Event.styled';
import { FixedSlot, User, UserSlot } from 'models';
import { contextMenu, Item, Menu, Separator, useContextMenu } from 'react-contexify';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fixedSlotActions } from 'store/fixedSlots/fixedSlot.slice';
import { schedulerFixActions } from 'store/schedulerFixs/schedulerFix.slice';
import { format } from 'date-fns';
import { getContext } from 'redux-saga/effects';
import { useAuth } from 'hooks';
import { projectSelector } from 'store/projects/project.slice';

interface Props {
  data: UserSlot;
  slotData: FixedSlot;
  userData: User;
  slotId: string;
  shiftId: string;
  date: any;
  onSetDraggingItem: () => void;
  onSetDroppedItem: () => void;
  index: number;
  slotIndex: number;
  shiftDuration: number;
  showDetail: boolean;
}

// ============================ //

const Event = (props: any) => {
  const currentUser = useAuth();
  const RANDOM_TEXT = (Math.random() + 1).toString(36).substring(7);
  const dispatch = useAppDispatch();
  const { show } = useContextMenu();
  function displayMenu(e: any, id: string) {
    show({
      event: e,
      id: id
    });
  }
  const project = useAppSelector(projectSelector.selectSelectedProject);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const classes = isDraggingOver ? 'drag-entered' : '';

  const dragAndDropPayload = () => {
    return {
      type: 'event',
      userId: props.data.user._id,
      duration: props.data.duration,
      user: props.data.user,
      note: props.data.note,
      slotId: props.slotId,
      shiftId: props.shiftId,
      index: props.index,
      division: props.slotData?.division?._id || props.slotData?.division
    };
  };
  const onDrop = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(false);
    contextMenu.show({
      id: 'DRAG_AND_DROP',
      event: e
    });
    props.onSetDroppedItem(dragAndDropPayload());
  };

  const onDragStart = (e: any) => {
    e.stopPropagation();
    props.onSetDraggingItem(dragAndDropPayload());
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };
  const onDragEnter = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(true);
  };
  const onDragLeave = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const getColor = () => {
    return props?.userData?.color ? props.userData.color : 'transparent';
  };

  const getTextColor = () => {
    return project !== null &&
      project?.users?.find((u) => u._id === props.data.user._id) !== undefined
      ? '#32539c'
      : '#000';
  };

  const getContextMenuId = (): string => {
    return (
      RANDOM_TEXT +
      props.date +
      props.shiftId +
      props.index +
      props.data.user._id +
      props.data.duration
    );
  };

  const handleRemoveUserSlot = ({ event, props, triggerEvent, data }: any) => {
    dispatch(fixedSlotActions.deleteUserSlot(data));
  };

  const handleRemoveSlot = ({ event, props, triggerEvent, data }: any) => {
    dispatch(fixedSlotActions.deleteSlot(data));
  };

  const onAssign = () => {
    dispatch(schedulerFixActions.setIsModalCreateSlotVisible(true));
    dispatch(schedulerFixActions.setSelectedShiftId(props.shiftId));
    dispatch(schedulerFixActions.setSelectedDate(props.date));
  };

  const trackVisibility = (isVisible: boolean) => {
    setIsDraggingOver(false);
  };

  return currentUser?.role === 'MANAGER' ? (
    <EventItem
      draggable
      onDrop={onDrop}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      className={classes}
      onContextMenu={(e: any) => {
        e.stopPropagation();
        displayMenu(e, `${getContextMenuId()}`);
      }}
      style={{
        backgroundColor: getColor(),
        color: getTextColor()
      }}
    >
      <EventName style={{ pointerEvents: 'none' }}>
        {props.data.user.displayName}{' '}
        {props.data.duration !== props.shiftDuration && `- ${props.data.duration}`}
      </EventName>

      <div>
        <Menu id={getContextMenuId()} theme="light" onVisibilityChange={trackVisibility}>
          <Item
            onClick={handleRemoveUserSlot}
            data={{
              slotId: props.slotId,
              shiftId: props.shiftId,
              index: props.index
            }}
          >
            Remove {props.data.user.displayName}
          </Item>
          <Item
            onClick={handleRemoveSlot}
            data={{
              slotId: props.slotId,
              shiftId: props.shiftId,
              index: props.slotIndex
            }}
          >
            Remove this Agent Count
          </Item>
          <Separator />
          <Item onClick={onAssign}>Assign</Item>
        </Menu>
      </div>
    </EventItem>
  ) : (
    <EventItem
      className={classes}
      style={{
        backgroundColor: getColor(),
        whiteSpace: props.showDetail ? 'unset' : 'nowrap',
        overflow: props.showDetail ? 'unset' : 'hidden',
        textOverflow: props.showDetail ? 'unset' : 'ellipsis'
      }}
    >
      <EventName style={{ pointerEvents: 'none' }}>
        {props.data.user.displayName}{' '}
        {props.data.duration !== props.shiftDuration && `- ${props.data.duration}`}
      </EventName>
    </EventItem>
  );
};

export default React.memo(Event);
