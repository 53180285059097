import { TimePicker } from 'components/Shared';
import { AttendanceCoveringPayload, Project, User } from 'models';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import { attendanceActions, attendanceSelector } from 'store/attendances/attendance.slice';
import { fixedShiftActions } from 'store/fixedShifts/fixedShift.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { AttendanceCoveringWrapper } from './AttendanceCovering.styled';
import { toast } from 'react-toastify';
import useDidMountEffect from 'hooks/useDidMountEffect';

interface Props {
  setIsCovering: (status: boolean) => void;
  setIsCheckingIn: (status: boolean) => void;
}
const AttendanceCovering = (props: Props) => {
  const dispatch = useAppDispatch();
  const { setIsCovering, setIsCheckingIn } = { ...props };
  const [selectedProject, setSelectedProject] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isWorkingNextShift, setIsWorkingNextShift] = useState<boolean>(false);
  const projects = useAppSelector(projectSelector.selectProjects);
  const isLoadingProject: boolean = useAppSelector(projectSelector.selectIsFetching);
  const isCovering: boolean = useAppSelector(attendanceSelector.selectIsCovering);
  const error = useAppSelector(attendanceSelector.selectError);

  useDidMountEffect(() => {
    if (!isCovering && !error) {
      toast.success('Successfully Covering.');
      setIsCheckingIn(false);
      setIsCovering(false);
    }
  }, [isCovering]);

  useDidMountEffect(() => {
    if (!isCovering && error) {
      toast.error(error?.message || 'Something went wrong.');
    }
  }, [error]);

  useEffect(() => {
    if (!isLoadingProject) {
      if (projects.length > 0) {
        setSelectedProject(projects[0]._id);
        setUsers(projects[0].users);
        setSelectedUser(projects[0].users[0]?._id || '');
      }
    }
  }, [isLoadingProject]);

  const onChangeProject = (projectId: string) => {
    setSelectedProject(projectId);
    setUsers(projects.find((project) => project._id === projectId)?.users || []);
  };

  const onCovering = () => {
    const payload: AttendanceCoveringPayload = {
      project: selectedProject,
      coveredUser: selectedUser,
      coveringStartTime: startTime,
      coveringEndTime: endTime,
      continueWorking: isWorkingNextShift
    };
    dispatch(attendanceActions.covering(payload));
  };

  const onCancel = () => {
    setIsCovering(false);
  };
  return (
    <AttendanceCoveringWrapper className="w-100">
      <h3>You are covering for another. Please provide us with more information!</h3>
      <div className="w-100 p-5">
        {isLoadingProject && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="text-center" />
          </div>
        )}
        {!isLoadingProject && (
          <>
            <Form>
              <Form.Row className="justify-content-between">
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Project</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Project"
                    onChange={(e) => onChangeProject(e.target.value)}
                    name="projectId"
                    value={selectedProject}
                    custom
                  >
                    {projects.map((project: Project) => (
                      <option key={project._id} value={project._id}>
                        {project.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Covered's agent name</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Shift"
                    onChange={(e) => setSelectedUser(e.target.value)}
                    name="type"
                    value={selectedUser}
                    custom
                  >
                    {users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.username}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-between">
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>From</Form.Label>
                  <TimePicker fieldName={'from'} onChangeTime={setStartTime}>
                    <div
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <Form.Control
                        type="text"
                        onKeyDown={(e: any) => e.preventDefault()}
                        name="endTime"
                        defaultValue={startTime}
                        // isInvalid={formik.touched.endTime && formik.errors.endTime}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {formik.errors.endTime}
                      </Form.Control.Feedback> */}
                    </div>
                  </TimePicker>
                </Form.Group>
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>To</Form.Label>
                  <TimePicker fieldName={'to'} onChangeTime={setEndTime}>
                    <div
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <Form.Control
                        type="text"
                        onKeyDown={(e: any) => e.preventDefault()}
                        name="endTime"
                        defaultValue={endTime}
                        // isInvalid={formik.touched.endTime && formik.errors.endTime}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {formik.errors.endTime}
                      </Form.Control.Feedback> */}
                    </div>
                  </TimePicker>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    checked={isWorkingNextShift}
                    value="workingNextShift"
                    id="workingNextShift"
                    onChange={(e) => {
                      setIsWorkingNextShift(e.target.checked);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="workingNextShift">
                    I will work on the next shift too
                  </label>
                </div>
              </Form.Row>
            </Form>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onCovering}>
                Submit and Check-in
              </Button>
            </div>
          </>
        )}
      </div>
    </AttendanceCoveringWrapper>
  );
};

export default AttendanceCovering;
