import { AppState } from '../index.store';

export const selectIsFetching = (state: AppState) => state.offVacations.isFetching;

// export const selectIsFetchingOnHoldData = (state: AppState) =>
//   state.shiftChanges.isFetchingOnHoldData;

export const selectIsCreating = (state: AppState) => state.offVacations.isCreating;

export const selectIsUpdating = (state: AppState) => state.offVacations.isUpdating;

export const selectOffVacation = (state: AppState) => state.offVacations.offVacations;

export const selectPending = (state: AppState) => state.offVacations.pending;

export const selectPendingProjectCount = (state: AppState) =>
  state.offVacations.pendingProjectCount;

export const selectError = (state: AppState) => state.offVacations.error;

export const offVacationsPaginationInfo = (state: AppState) =>
  state.offVacations.offVacationsPaginationInfo;

export const selectApprovedRequestLog = (state: AppState) => state.offVacations.approvedRequestLog;

export const selectRejectedRequestLog = (state: AppState) => state.offVacations.rejectedRequestLog;

export const approvedShiftChangePaginationInfo = (state: AppState) =>
  state.offVacations.approvedShiftChangePaginationInfo;

export const rejectShiftChangePaginationInfo = (state: AppState) =>
  state.offVacations.rejectShiftChangePaginationInfo;
