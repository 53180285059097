import { format, isToday, isYesterday, parse } from 'date-fns';

export const formatTo_yyyyMMdd = (date: number | Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatTo_HHMM = (date: number | Date) => {
  return format(date, 'HH:mm');
};

export const formatTo_MMMd = (date: number | Date) => {
  console.log(date);
  return format(date, 'MMM d') || '';
};

export const formatTo_yyyyMMdd_HHMM = (date: number | Date) => {
  return format(date, 'yyyy-MM-dd HH:mm');
};

export const getReadableDurationString = (date: number | Date) => {
  if (isToday(date)) {
    return 'Today';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else {
    return format(date, 'MMM dd');
  }
};

export const parseShiftTime = (time: string) => {
  return format(parse(time.split(':', 2).join(':'), 'HH:mm', new Date()), 'h:mma');
};

export const toDateFromString = (dateString: string, format: string) => {
  return parse(dateString, format, new Date());
};
