import ApiService from '../utils/network';
import { API_URL } from './url';
import { Filter } from 'models';
export const filterApi = {
  getFilters(payload?: { kind: string } | undefined) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.FILTER}`, {
      params: {
        kind: payload?.kind
      }
    });
  },
  createFilter(payload: Filter) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.FILTER}`, payload);
  },
  deleteFilter(filterId: string) {
    return ApiService.delete(`${API_URL.BASE_URL}/${API_URL.FILTER}/${filterId}`);
  }
};
