import Attendance from 'components/Attendance/Attendance';
import { UserRole } from 'models';
import { useEffect } from 'react';
import { authActions, authSelector } from 'store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions } from 'store/users/user.slice';

const AttendancePage = () => {
  const isFetchingCurrentUser = useAppSelector(authSelector.selectAuthIsFetching);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(authActions.getCurrentUser({}));
  }, []);

  if (!isFetchingCurrentUser) {
    return <Attendance></Attendance>;
  } else {
    return <></>;
  }
};

export default AttendancePage;
