import { AppState } from '../index.store';

export const selectIsFetching = (state: AppState) => state.fixedShifts.isFetching;

export const selectIsUpdating = (state: AppState) => state.fixedShifts.isUpdating;

export const selectShifts = (state: AppState) => state.fixedShifts.shifts;

export const selectError = (state: AppState) => state.fixedShifts.error;

export const selectHighestIndexFixedSlot = (state: AppState) =>
  state.fixedShifts.highestIndexFixedSlot;
