import { Nullable } from '../../utils/common/types';

export type SlotInitialState = {
  error: Nullable<string>;
  isLoading: boolean;
  isUpdating: boolean;
};

export const initialState: SlotInitialState = {
  error: null,
  isLoading: false,
  isUpdating: false
};
