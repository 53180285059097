import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';
import { el } from 'date-fns/locale';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  formik: any;
  show: boolean;
  setShow: (show: boolean) => void;
}
const ModalConfirmEmptySlot = (props: Props) => {
  const { formik, show, setShow } = { ...props };

  const onCancel = () => {
    setShow(false);
  };

  const onProceed = () => {
    const newValue: any = [];
    formik.values.employees.forEach((employee: any) => {
      if (employee.length === 1 && employee[0].userId && employee[0].userId !== 'multiple') {
        newValue.push([employee[0]]);
      } else if (employee.length > 1) {
        const tempEmp: any = [];
        employee.forEach((emp: any) => {
          if (emp.userId) {
            console.log(emp);
            tempEmp.push(emp);
          }
        });
        if (tempEmp.length > 1) {
          newValue.push(tempEmp);
        }
      }
    });
    console.log(newValue);
    formik.setFieldValue('employees', newValue);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      className="handle"
      scrollable
      dialogAs={DraggableModalDialog}
      backdrop={false}
    >
      <Modal.Header className="pb-0" closeButton>
        <Modal.Title
          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
          }}
        >
          Warning
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Some assignments have yet to be assigned to a specific AGENT. Would you like to proceed?
        <br />
        If yes, the system will clear those assignments.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onProceed}>Proceed</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmEmptySlot;
