import { fork } from 'redux-saga/effects';
import authSaga from './auth/auth.saga';
import projectSaga from './projects/project.saga';
import shiftSaga from './shifts/shift.saga';
import fixedShiftSaga from './fixedShifts/fixedShift.saga';
import userSaga from './users/user.saga';
import slotSaga from './slots/slot.saga';
import fixedSlotSaga from './fixedSlots/fixedSlot.saga';
import schedulerSaga from './schedulers/scheduler.saga';
import schedulerFixSaga from './schedulerFixs/schedulerFix.saga';
import scheduleAvailabilitySaga from './scheduleAvailability/scheduleAvailability.saga';
import fixedScheduledPreviewSaga from './fixedSchedulePreview/fixedScheduledPreview.saga';
import divisionSaga from './divisions/division.saga';
import attendanceSaga from './attendances/attendance.saga';
import commentSaga from './comments/comment.saga';
import notificationSaga from './notifications/notification.saga';
import reportSaga from './reports/report.saga';
import salaryPeriodSaga from './salaryPeriods/salaryPeriod.saga';
import noteSaga from './notes/note.saga';
import filterSaga from './filters/filter.saga';
import scheduleGlance from './scheduleGlance/scheduleGlance.saga';
import shiftChanges from './shiftChanges/shiftChange.saga';
import offVacations from './offVacations/offVacation.saga';

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(projectSaga);
  yield fork(userSaga);
  yield fork(shiftSaga);
  yield fork(fixedShiftSaga);
  yield fork(slotSaga);
  yield fork(fixedSlotSaga);
  yield fork(schedulerSaga);
  yield fork(schedulerFixSaga);
  yield fork(scheduleAvailabilitySaga);
  yield fork(fixedScheduledPreviewSaga);
  yield fork(divisionSaga);
  yield fork(attendanceSaga);
  yield fork(commentSaga);
  yield fork(notificationSaga);
  yield fork(reportSaga);
  yield fork(salaryPeriodSaga);
  yield fork(noteSaga);
  yield fork(filterSaga);
  yield fork(scheduleGlance);
  yield fork(shiftChanges);
  yield fork(offVacations);
}
