import { Filter, Project, Shift, User } from 'models';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { schedulerSelector } from 'store/schedulers/scheduler.slice';
import SchedulerTable from './Actions/SchedulerTable/SchedulerTable';
import SchedulerFilter from './FilterActions/SchedulerFilter';
import { SchedulerWrapper } from './Scheduler.styled';
import { Card } from 'react-bootstrap';
import React from 'react';
import { shiftSelector } from 'store/shifts/shift.slice';
import SchedulerCompact from './MainActions/SchedulerCompact/SchedulerCompact';

interface Props {
  projects: Project[];
  shifts: Shift[];
  offVacationSlotShifts: Shift[];
  users: User[];
  isFetchingShifts: boolean;
  onChangeShowOffVacationSlots: (value: boolean) => void;
}

const Scheduler = (props: Props) => {
  const isApplyingFilter = useAppSelector(schedulerSelector.selectIsApplyingFilter);
  const isFetchingShifts = useAppSelector(shiftSelector.selectIsFetching);
  const showingCompactOverview = useAppSelector(schedulerSelector.selectShowingCompactOverview);
  const [dates, setDates] = useState([]);
  const [datesMonth, setDatesMonth] = useState([]);

  const onChangeDateRange = (value: any) => {
    setDates(value);
  };
  const onChangeDateRangeMonth = (value: any) => {
    setDatesMonth(value);
  };
  return (
    <SchedulerWrapper>
      <Card.Header style={{ marginBottom: 0 }}>
        <SchedulerFilter
          shifts={props.shifts}
          isFetchingShifts={props.isFetchingShifts}
          onChangeDateRange={onChangeDateRange}
          onChangeDateRangeMonth={onChangeDateRangeMonth}
          onChangeShowOffVacationSlots={props.onChangeShowOffVacationSlots}
        ></SchedulerFilter>
      </Card.Header>
      {showingCompactOverview && (
        <SchedulerCompact
          dates={dates}
          datesMonth={datesMonth}
          shifts={props.shifts}
          offVacationSlotShifts={props.offVacationSlotShifts}
        />
      )}
      {!showingCompactOverview &&
        props.projects.length > 0 &&
        props.shifts.length > 0 &&
        !isApplyingFilter &&
        !isFetchingShifts && (
          <SchedulerTable dates={dates} datesMonth={datesMonth} shifts={props.shifts} />
        )}
    </SchedulerWrapper>
  );
};

export default React.memo(Scheduler);
