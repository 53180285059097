import { Notification, PaginationInfo } from 'models';
import { Nullable } from '../../utils/common/types';
export type NotificationInitialState = {
  isFetchingSystemNoti: boolean;
  isFetchingTotalUnread: boolean;
  isFetchingUserNoti: boolean;
  systemNotifications: Notification[];
  userNotifications: Notification[];
  systemNotisPaginationInfo: PaginationInfo;
  userNotisPaginationInfo: PaginationInfo;
  error: any;
  isMarking: boolean;
  isMarkingAll: boolean;
  totalUnread: number;
};

export const initialState: NotificationInitialState = {
  isFetchingSystemNoti: false,
  isFetchingTotalUnread: false,
  isFetchingUserNoti: false,
  error: null,
  systemNotisPaginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  },
  userNotisPaginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  },
  systemNotifications: [],
  userNotifications: [],
  isMarking: false,
  isMarkingAll: false,
  totalUnread: 0
};
