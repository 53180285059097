import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { schedulerActions, schedulerSelector } from 'store/schedulers/scheduler.slice';

const VIEW_MODE = ['Week', 'Month'];
export const useViewFilter = () => {
  const dispatch = useAppDispatch();
  const selectedViewMode = useAppSelector(schedulerSelector.selectViewMode);

  const [label, setLabel] = useState('Week');

  const onSelect = (mode: string) => {
    // dispatch(schedulerActions.setIsApplyingFilter(true));
    dispatch(schedulerActions.setViewMode(mode));
    setLabel(mode);
  };
  return { VIEW_MODE, selectedViewMode, onSelect };
};
