import useDidMountEffect from 'hooks/useDidMountEffect';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';

export const useDeleteUser = () => {
  const dispatch = useAppDispatch();
  const isDeleting = useAppSelector(userSelector.selectIsDeleting);
  const user = useAppSelector(userSelector.selectSelectedUser);
  const error = useAppSelector(userSelector.selectError);
  const isVisible = useAppSelector(userSelector.selectIsModalDeleteVisible);

  const onDelete = () => {
    dispatch(userActions.deleteUser(user._id));
  };

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
    }
  }, [error]);

  useDidMountEffect(() => {
    if (error === null && !isDeleting) {
      console.log('run ne');
      toast.success('Successfully Deleted User.');
      console.log('after');
      onHide();
    }
  }, [isDeleting]);

  const onHide = () => {
    dispatch(userActions.setIsModalDeleteVisible(false));
  };
  return {
    isDeleting,
    isVisible,
    user,
    onHide,
    onDelete
  };
};
