import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { SlotHelper, findUserSlotsOf, getNewShifts } from 'helpers/slot.helper';
import cloneDeep from 'lodash.clonedeep';
import {
  CreateSlotPayload,
  Division,
  DragAndDropParams,
  GetShiftsPayload,
  Shift,
  UserSlot
} from 'models';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { shiftApi, slotApi } from 'services';
import { shiftActions, shiftSelector } from 'store/shifts/shift.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';
import { slotActions } from './slot.slice';
import { getDivisionId } from 'helpers/division.helper';

interface HandleUpdatePayload {
  droppedItem: DragAndDropParams;
  draggingItem: DragAndDropParams;
  droppedUserSlots: UserSlot[];
  draggingUserSlots: UserSlot[];
}

const updateDragAndDrop = (payload: HandleUpdatePayload) => {
  const { droppedItem, draggingItem, droppedUserSlots, draggingUserSlots } = { ...payload };
  const { slotId: dragSlotId, shiftId: dragShiftId } = { ...draggingItem };
  const { slotId: dropSlotId, shiftId: dropShiftId } = { ...droppedItem };
  return [
    call(slotApi.bulkUpdate, {
      slotIds: [dragSlotId, dropSlotId],
      shiftIds: [dragShiftId, dropShiftId],
      userSlots: [draggingUserSlots, droppedUserSlots],
      division: [getDivisionId(draggingItem.division), getDivisionId(droppedItem.division)]
    })
  ];
};

const updateSlotFunc = (
  shiftId: string,
  slotId: string,
  userSlots: UserSlot[],
  division: Division | string | undefined | Division[]
) => {
  const payload = {
    shiftId,
    slotId,
    userSlots,
    division: getDivisionId(division)
  };
  return call(slotApi.updateSlot, payload);
};

function* handleReplaceOrMerge(payload: HandleUpdatePayload) {
  const { droppedItem, draggingItem, droppedUserSlots, draggingUserSlots } = { ...payload };

  if (draggingUserSlots?.length === 0) {
    const { shiftId, slotId, division } = { ...droppedItem };
    yield all([updateSlotFunc(shiftId, slotId, droppedUserSlots, division)]);
    yield call(slotApi.deleteSlot, {
      shiftId: draggingItem.shiftId,
      slotId: draggingItem.slotId
    });
  } else {
    yield all(updateDragAndDrop(payload));
  }
}

function* handlePaste(payload: HandleUpdatePayload) {
  const { droppedItem, droppedUserSlots } = { ...payload };
  const { shiftId, slotId }: any = { ...droppedItem };
  const dropDivision = droppedItem?.division?._id || droppedItem?.division || undefined;
  yield all([updateSlotFunc(shiftId, slotId, droppedUserSlots, dropDivision)]);
}

function* handleCreate(payload: HandleUpdatePayload) {
  const { droppedItem, draggingItem, droppedUserSlots, draggingUserSlots } = { ...payload };
  const { shiftId: dragShiftId, slotId: dragSlotId }: DragAndDropParams = { ...draggingItem };
  const dragDivision = getDivisionId(draggingItem?.division);

  if (draggingUserSlots.length === 0) {
    const payload = { shiftId: dragShiftId, slotId: dragSlotId };
    yield call(slotApi.deleteSlot, payload);
  } else {
    yield all([updateSlotFunc(dragShiftId, dragSlotId, draggingUserSlots, dragDivision)]);
  }

  const { shiftId, date }: any = { ...droppedItem };
  const newPayload = {
    shiftId: shiftId,
    payload: {
      division: [dragDivision],
      date: formatTo_yyyyMMdd(date),
      employees: [droppedUserSlots]
    }
  };
  const response: { projectId: string; shiftId: string } = yield call(
    slotApi.createSlot,
    newPayload
  );
  return response;
}

function* handleDuplicate(params: HandleUpdatePayload) {
  const { droppedItem, draggingItem, droppedUserSlots } = { ...params };
  const payload: any = {
    shiftId: droppedItem.shiftId,
    payload: {
      date: format((droppedItem as any)?.date, 'yyyy-MM-dd'),
      division: [getDivisionId(draggingItem?.division)],
      employees: [droppedUserSlots]
    }
  };
  const response: unknown = yield call(slotApi.createSlot, payload);
  return response;
}

export function* updateSlotWorker({ payload }: PayloadAction<any>) {
  const { droppedItem, draggingItem, type } = payload;
  const shifts: Shift[] = yield select(shiftSelector.selectShifts);
  const helper = new SlotHelper(droppedItem, draggingItem, shifts);

  try {
    const { draggingUserSlots, droppedUserSlots }: any = helper.handleUpdateSlot(type);
    const handleUpdatePayload = { droppedItem, droppedUserSlots, draggingItem, draggingUserSlots };
    let newShifts = [];

    switch (type) {
      case 'replace':
      case 'merge':
        yield handleReplaceOrMerge(handleUpdatePayload);
        yield put(shiftActions.setShifts(helper.handleUpdateShifts() as Shift[]));
        break;
      case 'swap': {
        yield all(updateDragAndDrop(handleUpdatePayload));
        yield put(shiftActions.setShifts(helper.handleUpdateShifts() as Shift[]));
        break;
      }
      case 'paste': {
        yield handlePaste(handleUpdatePayload);
        yield put(shiftActions.setShifts(helper.handleUpdateShifts() as Shift[]));
        break;
      }
      case 'create': {
        const response: unknown = yield handleCreate(handleUpdatePayload);
        const payload: GetShiftsPayload = yield select(shiftSelector.selectGetShiftsPayload);
        const { projectId, dateStart, dateEnd } = { ...payload };
        newShifts = yield call(shiftApi.getShifts, projectId, dateStart, dateEnd);
        yield put(shiftActions.setShifts(sortShiftsByName(newShifts) as Shift[]));
        break;
      }
      case 'duplicate': {
        const response: unknown = yield handleDuplicate(handleUpdatePayload);
        const payload: GetShiftsPayload = yield select(shiftSelector.selectGetShiftsPayload);
        const { projectId, dateStart, dateEnd } = { ...payload };
        newShifts = yield call(shiftApi.getShifts, projectId, dateStart, dateEnd);
        yield put(shiftActions.setShifts(sortShiftsByName(newShifts) as Shift[]));
        break;
      }
      default: {
        break;
      }
    }
    toast.success('Successfully Updated');
  } catch (e) {
    console.log('updateSlotFailed error: ', e);
    yield put(slotActions.updateSlotFailed(e));
  }
}

export function* deleteSlotWorker({ payload }: PayloadAction<any>) {
  try {
    const response: Shift = yield call(slotApi.deleteSlot, payload);
    const shifts: Shift[] = yield select(shiftSelector.selectShifts);
    const _shifts = shifts.map((s) => {
      if (s._id === payload.shiftId) {
        const _slots = [...s.slots];
        _slots.splice(payload.index, 1);
        return { ...s, slots: _slots };
      }
      return s;
    });
    yield put(shiftActions.getShiftsSuccess(_shifts));
    yield put(slotActions.deleteSlotSuccess(response));
    toast.success('Successfully Deleted');
  } catch (e) {
    console.log('deleteSlotWorker Error: ', e);
    yield put(slotActions.deleteSlotFailed(e));
  }
}

export function* deleteUserSlotWorker({ payload }: PayloadAction<any>) {
  const shifts: Shift[] = yield select(shiftSelector.selectShifts);
  const userSlots = findUserSlotsOf(payload, shifts) || [];
  const { index, shiftId, slotId }: any = { ...payload };
  userSlots?.splice(index, 1);
  try {
    if (userSlots.length === 0) {
      yield call(slotApi.deleteSlot, {
        shiftId: shiftId,
        slotId: slotId
      });
    } else {
      yield call(slotApi.updateSlot, { ...payload, userSlots: userSlots });
    }
    const _shifts = getNewShifts(payload, cloneDeep(shifts), userSlots) as Shift[];
    yield put(shiftActions.setShifts(_shifts));
    toast.success('Successfully Deleted');
  } catch (e) {
    console.log('deleteSlotWorker Error: ', e);
    yield put(slotActions.deleteSlotFailed(e));
  }
}

export function* createSlotWorker({ payload }: PayloadAction<CreateSlotPayload>) {
  try {
    yield all(
      (payload.createdSlotIds as string[]).map((r, index) =>
        put(
          slotActions.deleteSlot({
            shiftId: payload.shiftId,
            slotId: r
          })
        )
      )
    );
    const response: { projectId: string; shiftId: string } = yield call(slotApi.createSlot, {
      ...payload
    });
    const shifts: Shift[] = yield call(shiftApi.getShifts, response.projectId);
    yield put(shiftActions.getShiftsSuccess(sortShiftsByName(shifts) as Shift[]));
    yield put(slotActions.createSlotSuccess(response));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(slotActions.createSlotFailed(e));
  }
}

export default function* shiftSaga() {
  yield takeLatest(slotActions.updateSlot, updateSlotWorker);
  yield takeLatest(slotActions.deleteSlot, deleteSlotWorker);
  yield takeLatest(slotActions.createSlot, createSlotWorker);
  yield takeLatest(slotActions.deleteUserSlot, deleteUserSlotWorker);
}
