import Avatar from 'react-avatar';

interface Props {
  name: string;
  size: string;
  textSizeRatio?: number;
  round?: boolean;
  color?: string;
}

export const FcAvatar = (props: Props) => {
  const { name, size, textSizeRatio, round, color } = { ...props };
  const COLORS = ['#5e72e4', '#2bffc6'];
  const random = (mn: number, mx: number) => {
    return Math.random() * (mx - mn) + mn;
  };

  return (
    <Avatar
      name={name}
      size={size}
      textSizeRatio={textSizeRatio || 1.5}
      round={round || true}
      color={color || COLORS[Math.floor(random(1, 5)) - 1]}
    />
  );
};
