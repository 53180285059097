import { AppState } from '../index.store';

export const selectIsFetching = (state: AppState) => state.users.isFetching;

export const selectIsFetched = (state: AppState) => state.users.isFetched;

export const selectUsers = (state: AppState) => state.users.users;

export const selectUsersModalAssign = (state: AppState) => state.users.usersModalAssign;

export const selectUsersPaginationInfo = (state: AppState) => state.users.paginationInfo;

export const selectManagersPaginationInfo = (state: AppState) => state.users.managersPaginationInfo;

export const selectIsModalCreateVisible = (state: AppState) => state.users.isModalCreateVisible;

export const selectIsModalUpdateVisible = (state: AppState) => state.users.isModalUpdateVisible;

export const selectIsModalDeleteVisible = (state: AppState) => state.users.isModalDeleteVisible;

export const selectIsModalResetPasswordVisible = (state: AppState) =>
  state.users.isModalResetPasswordVisible;

export const selectSelectedUser = (state: AppState) => state.users.selectedUser;

export const selectSelectedUsers = (state: AppState) => state.users.selectedUsers;

export const selectSelectedAll = (state: AppState) => state.users.selectedAll;

export const selectIsCreating = (state: AppState) => state.users.isCreating;

export const selectIsUpdating = (state: AppState) => state.users.isUpdating;

export const selectIsUpdatingPassword = (state: AppState) => state.users.isUpdatingPassword;

export const selectIsUpdatingStatus = (state: AppState) => state.users.isUpdatingStatus;

export const selectIsRemovingProject = (state: AppState) => state.users.isRemovingProject;

export const selectIsBulkUpdating = (state: AppState) => state.users.isBulkUpdating;

export const selectIsRestoring = (state: AppState) => state.users.isRestoring;

export const selectIsExporting = (state: AppState) => state.users.isExporting;

export const selectIsDeleting = (state: AppState) => state.users.isDeleting;

export const selectUsersSameProject = (state: AppState) => state.users.usersSameProject;

export const selectGetUsersModalAssignParams = (state: AppState) =>
  state.users.getUsersModalAssignParams;

export const selectError = (state: AppState) => state.users.error;
