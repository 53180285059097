import { Table } from 'react-bootstrap';
import RequestLogList from './ShiftChangeRequestLogList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getShiftChanges,
  shiftSelector,
  getRejectedRequestLog
} from 'store/shiftChanges/shiftChange.slice';
import { useEffect } from 'react';
import { GetShiftChangePayload } from 'models';

interface Props {
  selectedTab: string;
  projectId: string;
}

const ShiftChangeRejected = (props: Props) => {
  const { selectedTab, projectId } = { ...props };

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(shiftSelector.selectIsFetching);
  const data = useAppSelector(shiftSelector.selectRejectedRequestLog);
  const paginationInfo = useAppSelector(shiftSelector.rejectShiftChangePaginationInfo);

  useEffect(() => {
    dispatch(
      getRejectedRequestLog({
        page: 1,
        perPage: 10,
        type: props.selectedTab,
        projectId: [props.projectId],
        status: 'REJECTED',
        orderBy: 'createdAt',
        order: 'desc',
        displayProject: props.projectId
      } as GetShiftChangePayload)
    );
  }, [selectedTab, projectId]);

  return (
    <>
      <RequestLogList
        data={data}
        isLoading={isLoading}
        type={props.selectedTab}
        status="REJECTED"
        paginationInfo={paginationInfo}
        projectId={[props.projectId]}
        displayProject={projectId}
      />
    </>
  );
};

export default ShiftChangeRejected;
