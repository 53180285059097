import { useAuth } from 'hooks';
import { useEffect, useState } from 'react';
import { attendanceSelector } from 'store/attendances/attendance.slice';
import { authSelector } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/hooks';
import { projectActions } from 'store/projects/project.slice';
import { AttendanceWrapper } from './Attendance.styled';
import AttendanceCheckingOut from './MainActions/AttendanceCheckOut/AttendanceCheckOut';
import AttendanceChecking from './MainActions/AttendanceChecking/AttendanceChecking';
import AttendanceCheckingEarly from './MainActions/AttendanceCheckingEarly/AttendanceCheckingEarly';
import AttendanceCheckingLate from './MainActions/AttendanceCheckingLate/AttendanceCheckingLate';
import AttendanceCheckingOutLate from './MainActions/AttendanceCheckingOutLate/AttendanceCheckingOutLate';
import AttendanceCovering from './MainActions/AttendanceCovering/AttendanceCovering';
import AttendanceTypeSelect from './MainActions/AttendanceTypeSelect/AttendanceTypeSelect';
import { useAppDispatch } from 'store/hooks';
import AttendanceCheckingOutEarly from './MainActions/AttendanceCheckingOutEarly/AttendanceCheckingOutEarly';
import AttendanceManager from './MainActions/AttendanceManager/AttendanceManager';
const Attendance = () => {
  const dispatch = useAppDispatch();
  const [isCheckingIn, setIsCheckingIn] = useState<boolean>(false);
  const [isCovering, setIsCovering] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [selectedDivision, setSelectedDivision] = useState('');
  const [note, setNote] = useState('');

  const isCheckingInSuccess = useAppSelector(attendanceSelector.selectIsCheckingInSuccess);
  const isCheckingInLate = useAppSelector(attendanceSelector.selectIsCheckingInLate);
  const isCheckingInEarly = useAppSelector(attendanceSelector.selectIsCheckingInEarly);
  const isFetchingCurrentUser = useAppSelector(authSelector.selectAuthIsFetching);
  const isCheckingOutSuccess = useAppSelector(attendanceSelector.selectIsCheckingOutSuccess);
  const isCheckingOutLate = useAppSelector(attendanceSelector.selectIsCheckingOutLate);
  const isCheckingOutEarly = useAppSelector(attendanceSelector.selectIsCheckingOutEarly);
  const isCoveringSuccess = useAppSelector(attendanceSelector.selectIsCoveringSuccess);
  const currentUser = useAuth();

  useEffect(() => {
    return;
  }, [currentUser.checkedIn]);

  useEffect(() => {
    if (isCheckingOutLate) {
      dispatch(projectActions.getProjects());
    }
  }, [isCheckingOutLate]);

  const showCheckInScreen = () => {
    return (
      (currentUser.checkedIn !== undefined && currentUser.checkedIn === false) ||
      (currentUser.checkedIn && isCheckingOutSuccess)
    );
  };

  const showCheckOutScreen = () => {
    return (
      (currentUser.checkedIn !== undefined && currentUser.checkedIn === true) ||
      (currentUser.checkedIn && !isCheckingOutSuccess)
    );
  };

  return (
    <>
      {currentUser.role === 'MANAGER' ? (
        <AttendanceManager />
      ) : (
        !isFetchingCurrentUser && (
          <div className="shadow card">
            <div className="card-header border-0">
              <AttendanceWrapper>
                {showCheckInScreen() && (
                  <>
                    {!isCheckingIn && !isCoveringSuccess && !isCovering && (
                      <AttendanceTypeSelect
                        setIsCheckingIn={setIsCheckingIn}
                        setIsCovering={setIsCovering}
                      />
                    )}
                    {isCheckingIn && !isCoveringSuccess && !isCovering && (
                      <>
                        {!isCheckingInSuccess && !isCheckingInEarly && !isCheckingInLate && (
                          <AttendanceChecking
                            setIsCheckingIn={setIsCheckingIn}
                            selectedShift={selectedShift}
                            selectedProject={selectedProject}
                            setSelectedProject={setSelectedProject}
                            setSelectedShift={setSelectedShift}
                            selectedDivision={selectedDivision}
                            setSelectedDivision={setSelectedDivision}
                            note={note}
                            setNote={setNote}
                          />
                        )}
                        {!isCheckingInSuccess && !isCheckingInEarly && isCheckingInLate && (
                          <AttendanceCheckingLate
                            setIsCheckingIn={setIsCheckingIn}
                            selectedShift={selectedShift}
                            selectedProject={selectedProject}
                            selectedDivision={selectedDivision}
                            note={note}
                          />
                        )}
                        {!isCheckingInSuccess && isCheckingInEarly && !isCheckingInLate && (
                          <AttendanceCheckingEarly />
                        )}
                      </>
                    )}
                    {!isCheckingIn && isCovering && !isCoveringSuccess && (
                      <AttendanceCovering
                        setIsCovering={setIsCovering}
                        setIsCheckingIn={setIsCheckingIn}
                      />
                    )}
                  </>
                )}
                {showCheckOutScreen() && (
                  <>
                    {!isCheckingOutSuccess && !isCheckingOutEarly && !isCheckingOutLate && (
                      <AttendanceCheckingOut />
                    )}
                    {!isCheckingOutSuccess && !isCheckingOutEarly && isCheckingOutLate && (
                      <AttendanceCheckingOutLate />
                    )}
                    {!isCheckingOutSuccess && isCheckingOutEarly && !isCheckingOutLate && (
                      <AttendanceCheckingOutEarly />
                    )}
                  </>
                )}
                {(isCheckingInSuccess || isCoveringSuccess) && (
                  <>
                    <h3>You have been checked-in.</h3>
                    <h3>Have a nice shift!</h3>
                  </>
                )}
              </AttendanceWrapper>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Attendance;
