import { FcMultiselect } from 'components/Shared';
import { parse } from 'date-fns';
import { FormikProvider } from 'formik';
import { Project, User, WeekDay, Division } from 'models';
import { useEffect, useState } from 'react';
import { CustomCol, CustomRow, InputLabel } from './ProjectForm.styled';
import { Button, Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Calendar } from 'react-date-range';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userSelector } from 'store/users/user.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import UpdateShift from 'components/Project/MainActions/UpdateShift/UpdateShift';
import ModalAddUser from '../ModalAddUser/ModalAddUser';
import ModalReviewUser from '../ModalReviewUser/ModalReviewUser';
import { FcCreatableSelect } from 'components/Shared';
import { divisionActions, divisionSelector } from 'store/divisions/division.slice';
import { toast } from 'react-toastify';
import useDidMountEffect from 'hooks/useDidMountEffect';
import ModalAddManager from '../ModalAddManager/ModalAddManager';
import ModalReviewManager from '../ModalReviewManager/ModalReviewManager';
import ModalManageDivision from '../ModalManageDivision/ModalManageDivision';

interface Props {
  formik: any;
  project?: Project;
}

const ProjectForm = (props: Props) => {
  const { formik, project } = { ...props };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Form.Group as={Row} controlId="date">
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Name
            </InputLabel>
          </Col>
          <CustomCol sm="5">
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              isInvalid={formik.touched.name && !!formik.errors.name}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
            <Form.Control.Feedback
              type="invalid"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              {formik.errors.name}
            </Form.Control.Feedback>
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Number of Shifts
            </InputLabel>
          </Col>
          <CustomCol sm="5">
            <Form.Control
              type="number"
              placeholder="Enter Duration"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="numberOfShifts"
              value={formik.values.numberOfShifts}
              onKeyPress={(event: any) => {
                if (!/^[0-9]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              step="1"
              min="1"
              isInvalid={formik.touched.numOfShifts && !!formik.errors.numOfShifts}
              lang="en"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
            <Form.Control.Feedback
              type="invalid"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              {formik.errors.numOfShifts}
            </Form.Control.Feedback>
          </CustomCol>
          {props?.project && <UpdateShift project={props.project} />}
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Allotted Hours per Week
            </InputLabel>
          </Col>
          <CustomCol sm="5">
            <Form.Control
              type="number"
              placeholder="Enter Allotted Hours per Week"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.allottedHoursPerWeek}
              name="allottedHoursPerWeek"
              onKeyPress={(event: any) => {
                if (!/^[0-9]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              step="1"
              isInvalid={
                formik.touched.allottedHoursPerWeek && !!formik.errors.allottedHoursPerWeek
              }
              lang="en"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
            <Form.Control.Feedback
              type="invalid"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              {formik.errors.allottedHoursPerWeek}
            </Form.Control.Feedback>
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Week Definition
            </InputLabel>
          </Col>
          <CustomCol sm="5">
            <Form.Control
              as="select"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="weekDefinition"
              value={formik.values.weekDefinition}
              custom
              className="flex-1"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              {Object.keys(WeekDay)
                .filter((d) => isNaN(parseInt(d)))
                .map((day: any) => (
                  <option
                    key={WeekDay[day]}
                    value={WeekDay[day]}
                    onMouseDown={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    {day}
                  </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback
              type="invalid"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              {formik.errors.numOfShifts}
            </Form.Control.Feedback>
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Managers ({formik.values.managers.length})
            </InputLabel>
          </Col>
          <CustomCol sm="9">
            <ModalAddManager formik={formik} project={project} />
            <ModalReviewManager formik={formik} project={project} />
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Users ({formik.values.users.length})
            </InputLabel>
          </Col>
          <CustomCol sm="9">
            <ModalAddUser formik={formik} project={project} />
            <ModalReviewUser formik={formik} project={project} />
          </CustomCol>
        </Form.Group>
        {project && (
          <Form.Group as={Row}>
            <Col sm="3" className="w-100">
              <InputLabel
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
              >
                Divisions
              </InputLabel>
            </Col>

            <CustomCol sm="9">
              <ModalManageDivision project={project} />
            </CustomCol>
          </Form.Group>
        )}
      </Form>
    </FormikProvider>
  );
};

export default ProjectForm;
