import { BtnRemove } from './ButtonRemoveEmployee.styled';

interface Props {
  agentIndex: number;
  userIndex: number;
  arrayHelpers: any;
  agentCountHelpers: any;
}
const ButtonRemoveEmployee = (props: Props) => {
  const { agentIndex, userIndex, arrayHelpers, agentCountHelpers } = props;
  return (
    <div className={`form-group mb-3 ml-auto ${userIndex === 0 ? 'ml-auto' : 'ml-1'}`}>
      <div>
        {userIndex === 0 && (
          <BtnRemove
            className="fa-solid fa-circle-minus"
            onClick={() => {
              arrayHelpers.remove(agentIndex);
            }}
          />
        )}
        {userIndex !== 0 && (
          <BtnRemove
            className="fa-solid fa-circle-minus"
            onClick={() => {
              agentCountHelpers.remove(userIndex);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ButtonRemoveEmployee;
