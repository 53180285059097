import { TypeSelectWrapper, TypeSelectButton } from './AttendanceTypeSelect.styled';
import { useAppDispatch } from 'store/hooks';
import { projectActions } from 'store/projects/project.slice';
import { shiftActions } from 'store/shifts/shift.slice';

interface Props {
  setIsCheckingIn: (status: boolean) => void;
  setIsCovering: (status: boolean) => void;
}

const AttendanceTypeSelect = (props: Props) => {
  const dispatch = useAppDispatch();
  const { setIsCheckingIn, setIsCovering } = { ...props };

  const onCheckingIn = () => {
    setIsCheckingIn(true);
    setIsCovering(false);
    dispatch(projectActions.getProjects());
  };

  const onCovering = () => {
    setIsCheckingIn(false);
    setIsCovering(true);
    dispatch(projectActions.getProjects());
  };
  return (
    <TypeSelectWrapper className="w-100">
      <h1>Check-in Area</h1>
      <div className="d-flex justify-content-stretch w-75 p-5">
        <div className="w-50 d-inline-block">
          <TypeSelectButton onClick={onCheckingIn}>Check-in</TypeSelectButton>
        </div>
        <div className="w-50 d-inline-block">
          <TypeSelectButton className="float-right" onClick={onCovering}>
            Covering for someone else
          </TypeSelectButton>
        </div>
      </div>
    </TypeSelectWrapper>
  );
};

export default AttendanceTypeSelect;
