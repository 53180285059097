import { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const tabs = [
  {
    key: 'SHIFTCHANGE',
    value: 'ShiftChange'
  },
  {
    key: 'OFFVACATION',
    value: 'OffVacation'
  }
];

export const RequestLogTab = () => {
  const [selectedTab, setSelectedTab] = useState<string>('SHIFTCHANGE');

  const Tab = () => {
    return (
      <div className="d-flex justify-content-between">
        <ButtonGroup toggle className="mr-2">
          {tabs.map((tab) => (
            <ToggleButton
              key={tab.key}
              type="radio"
              variant="outline-primary"
              size="sm"
              name="radio"
              value={tab.key}
              checked={selectedTab === tab.key}
              onChange={(e) => setSelectedTab(tab.key)}
              style={{ lineHeight: 2 }}
              className="position-relative"
            >
              {tab.value}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    );
  };

  return { selectedTab, Tab };
};
