import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useReportSorter } from './useReportSorter';

const ReportSorter = () => {
  const { SORTER, sortString, setSortString } = useReportSorter();

  return (
    <DropdownButton
      title={`Sort by: ${sortString.toUpperCase()}`}
      id="dropdown"
      variant="outline-primary"
      size="sm"
      className="mr-2"
    >
      {SORTER.map((sort) => (
        <Dropdown.Item onClick={() => setSortString(sort)} key={sort}>
          <input
            type="checkbox"
            value={sort}
            checked={sortString === sort}
            onChange={() => setSortString(sort)}
            className="mr-2"
          />
          {sort.toUpperCase()}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default ReportSorter;
