import { Division } from './division.model';

export enum UserRole {
  AGENT = 'AGENT',
  MANAGER = 'MANAGER'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
  // BANNED = 'BANNED',
  // DELETED = 'DELETED'
}

export type UserProject = {
  project: { _id: string; name: string };
  divisions: [{ _id: string; name: string }];
};

type BaseUser = {
  employeeCode: number;
  email: string; //work email
  personalEmail?: string;
  username: string;
  fullName: string;
  role: UserRole;
  displayName: string;
  phone?: string;
  projects: UserProject[];
  status: string;
  deleted?: boolean;
  userType: string;
  checkedIn?: boolean;
};

export type User = BaseUser & {
  _id: string;
};

export type GetUsersPayload = {
  role?: UserRole;
  projectId?: string;
  divisionId?: string;
  page?: number;
  perPage?: number;
  search?: string;
  alphabets?: string;
  status?: string;
  deleted?: boolean;
  orderBy?: string;
  order?: string;
};

export type CreateUserPayload = Omit<BaseUser, 'status' | 'projects' | 'deleted'> & {
  password: string;
  passwordConfirmation: string;
  projects: [{ project: string; divisions: string[] }];
};

export type UpdateUserPayload = Pick<
  User,
  | '_id'
  | 'employeeCode'
  | 'username'
  | 'displayName'
  | 'phone'
  | 'email'
  | 'personalEmail'
  | 'fullName'
  | 'userType'
  | 'role'
> & {
  password: string;
  passwordConfirmation: string;
  projects: [{ project: string; divisions: string[] }];
};

export type ResetPasswordUserPayload = {
  id: string;
  password: string;
  passwordConfirmation: string;
};

export type UpdateStatusUserPayload = {
  id: string;
  status: string;
};

export type RemoveProjectFromUserPayload = {
  id: string;
  projectId: string;
};

export type BulkUpdateUsersPayload = {
  userIds: string[];
  status: string; //UserRole type
};

// Use in UI
export enum UserStatusVariant {
  'ACTIVE' = 'success',
  'INACTIVE' = 'warning',
  'DELETED' = 'danger'
}

export type UserSameProject = {
  _id: string; //projectId
  name: string; //projectName
  divisions: Division[];
  users: User[];
};
