import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { attendanceActions } from 'store/attendances/attendance.slice';
import { useAppDispatch } from 'store/hooks';

const actions = [
  { key: 'MANAGER_APPROVE', value: 'No. The management team approved this.' },
  { key: 'COVERED_BY_ANOTHER', value: 'Another agent is covering for me.' }
];
const AttendanceCheckingOutEarly = () => {
  const dispatch = useAppDispatch();
  const [selectedAction, setSelectedAction] = useState('');

  const onCancel = () => {
    dispatch(attendanceActions.resetToInit());
  };

  const onCheckout = () => {
    dispatch(
      attendanceActions.checkout({
        earlyCheckOutReason: selectedAction
      })
    );
  };
  return (
    <>
      <h3>You are checking out early. Has anyone covered for the rest of your shift?</h3>
      <div className="align-self-start pl-5 ml-5 mt-3">
        {actions.map((action) => (
          <div className="custom-control custom-checkbox mb-3" key={action.key}>
            <input
              className="custom-control-input"
              type="checkbox"
              value={action.key}
              id={action.key}
              checked={selectedAction === action.key}
              onChange={() => {
                setSelectedAction(action.key);
              }}
            />
            <label className="custom-control-label" htmlFor={action.key}>
              {action.value}
            </label>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-end">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onCheckout}>
          Check me out
        </Button>
      </div>
    </>
  );
};

export default AttendanceCheckingOutEarly;
