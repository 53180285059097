import { SpinnerButton, TimePicker } from 'components/Shared';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import { useAuth } from 'hooks';
import { useSpinner } from 'hooks/useSpinner';
import { ButtonTypes, OffVacationPayload, User } from 'models';
import { useEffect, useState } from 'react';
import { Form, Col, OverlayTrigger, Popover, Button, Badge, Nav } from 'react-bootstrap';
import { Calendar as DateRange } from 'react-date-range';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { userSelector } from 'store/users/user.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { DatesStatus, OffVacation, OffVacationRequestFormProps } from 'models/offVacation.model';
import {
  createOffVacation,
  offVacationSelector,
  getOffVacation,
  updateOffVacation
} from 'store/offVacations/offVacation.slice';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { toast } from 'react-toastify';
import {
  selectError,
  selectIsUpdating,
  selectPendingProjectCount
} from 'store/offVacations/offVacation.selector';
import { offVacationSchema } from 'utils/validators/offVacation.validator';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { eachDayOfInterval } from 'date-fns';
import ProjectFilter from 'hooks/scheduler/ProjectFilter';
import debounce from 'lodash.debounce';
import { OffRequestTypeTab } from '../OffRequestTypeTab/OffRequestTypeTab';
import ShiftChangePersonalFilter from 'components/ShiftChange/Actions/ShiftChangePersonalFilter/ShiftChangePersonalFilter';
import ShiftChangeProjectFilter from 'components/ShiftChange/Actions/ShiftChangeProjectFilter/ShiftChangeProjectFilter';
import ShiftChangeSaveFilter from 'components/ShiftChange/Actions/ShiftChangeSaveFilter/ShiftChangeSaveFilter';

const types = [
  { key: 'ONEDAY', value: 'One Day' },
  { key: 'SOMEDAY', value: 'Some Days' },
  { key: 'CONSECUTIVEDAY', value: 'Some Consecutive Days' },
  { key: 'SOMEHOURS', value: 'Some Hours' }
];

const OffVacationRequestManagerView = () => {
  const dispatch = useAppDispatch();
  const offVacations = useAppSelector(offVacationSelector.selectOffVacation);
  // const users = useAppSelector(userSelector.selectUsers);
  const isFetchingOffVacations = useAppSelector(offVacationSelector.selectIsFetching);
  // const selectedProject = useAppSelector(projectSelector.selectSelectedProject);
  // const [selectedProject, setSelectedProject] = useState('');

  const { selectedTab, OffVacataionTypeTab } = OffRequestTypeTab();
  const [page, setPage] = useState(1);
  const [perPageUs, setPerPageUs] = useState(50);
  const offVacationsPaginationInfo = useAppSelector(offVacationSelector.offVacationsPaginationInfo);
  const [typeStatus, setTypeStatus] = useState('');
  const [typeStatusArray, setTypeStatusArray] = useState<string[]>([]);
  const [typeStatusArrayTemp, setTypeStatusArrayTemp] = useState<DatesStatus[]>([]);

  const currentUser = useAuth();
  const projects = useAppSelector(projectSelector.selectProjects);
  const isFetchingProject = useAppSelector(projectSelector.selectIsFetching);
  const isUpdating = useAppSelector(selectIsUpdating);
  const error = useAppSelector(selectError);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [dates, setDates] = useState<any>([]);

  const [selectedProjectTab, setSelectedProjectTab] = useState('');
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [projectUsers, setProjectUsers] = useState<User[]>([]);
  const pendingProjectCount = useAppSelector(selectPendingProjectCount);

  useEffect(() => {
    if (selectedProjectTab.length === 0 && selectedProjects.length > 0) {
      setSelectedProjectTab(selectedProjects[0] || '');
    }
  }, [selectedProjects]);

  const getInitValues = (data: OffVacation) => {
    return {
      _id: '',
      type: data?.type || 'ONEDAY',
      requester: data?.requester || '',
      project: data?.project || '',
      date: data?.date || '',
      from: data?.from || '',
      to: data?.to || '',
      reason: data?.reason || ''
    };
  };
  const initialValues: OffVacation = getInitValues(offVacations?.[0]);

  useEffect(() => {
    if (!isFetchingOffVacations) {
      setFormValues(offVacations[0], formik, initialValues);
    }
  }, [isFetchingOffVacations]);

  const setFormValues = (
    data: OffVacation,
    _formik: FormikProps<OffVacation>,
    _initialValues: OffVacation
  ) => {
    const newData = Object.assign({}, _initialValues, data) as any;

    const { _id, type, requester, project, date, from, to, reason, dates }: any = {
      ...newData
    };

    _formik.setValues({
      _id: _id,
      type: type || 'ONEDAY',
      requester: requester?._id || requester[0]?._id || '',
      project: project?._id || project[0]?._id || '',
      date: getDate(date) || '',
      from: from || '',
      to: to || '',
      reason: reason || ''
    } as OffVacation);

    if (dates) {
      onLoadConsecutiveDay(dates);
    }
  };

  const onLoadConsecutiveDay = (item: string[]) => {
    setDates(item.map((date) => new Date(date)));

    setSelectedDates(item);
    formik.setFieldValue(
      'dates',
      item.map((date) => formatTo_yyyyMMdd(new Date(date)))
    );
  };

  const getDate = (value: string) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };

  const getData = () => {
    if (selectedTab && selectedProjectTab) {
      const getPayload = () => {
        return {
          type: selectedTab,
          // projectId: selectedProjectTab,
          projectId: selectedProjects,
          displayProject: selectedProjectTab.length ? selectedProjectTab : selectedProjects[0],
          status: 'PENDING',
          page: page,
          perPage: 1
        } as OffVacationPayload;
      };

      const payload = getPayload();
      dispatch(getOffVacation(payload));
    }
  };

  useEffect(() => {
    setTypeStatus('');
    setTypeStatusArray([]);

    setProjectUsers(projects.find((project) => project._id === selectedProjectTab)?.users || []);

    setPage(1);
    getData();
  }, [selectedProjectTab, selectedTab]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    dispatch(projectActions.getProjects());
  }, []);

  const onUpdateStatus = (status: string) => {
    setTimeout(() => {
      document.body.click();
    }, 5);
    setTypeStatus(status);
  };

  const onUpdateStatusArray = (status: string, index: number) => {
    setTimeout(() => {
      document.body.click();
    }, 5);

    const deleteIfExisted = typeStatusArrayTemp.filter((ds) => ds.index !== index);
    setTypeStatusArrayTemp(deleteIfExisted);

    const datesStatus = {
      index: index,
      status: status
    } as DatesStatus;
    setTypeStatusArrayTemp((typeStatusArrayTemp) => [...typeStatusArrayTemp, datesStatus]);
  };

  useEffect(() => {
    const sorted = typeStatusArrayTemp.sort((a, b) => {
      return a.index - b.index;
    });
    setTypeStatusArray(sorted.map((ds) => ds.status));
  }, [typeStatusArrayTemp]);

  useEffect(() => {
    if (typeStatusArray.length > 0) {
      const approvedExisted = typeStatusArray.filter((stt) => stt === 'APPROVED');
      if (approvedExisted.length > 0) {
        setTypeStatus('APPROVED');
      } else {
        setTypeStatus('REJECTED');
      }
    }
  }, [typeStatusArray]);

  useEffect(() => {
    console.log(typeStatus);
  }, [typeStatus]);

  const onSubmit = () => {
    const { _id, requester, project, type, reason } = { ...formik.values };
    const payload = {
      // ...formik.values,
      _id: _id,
      requester: requester,
      project: project,
      type: type,
      status: typeStatus,
      reason: reason,
      datesStatus: typeStatusArray
    };

    dispatch(updateOffVacation(payload));
  };

  useDidMountEffect(() => {
    if (!isUpdating && !error) {
      toast.success('Updated Successfully.');

      setTimeout(() => {
        setPage(1);
        getData();
      }, 500);
    }
  }, [isUpdating]);

  useDidMountEffect(() => {
    if (!isUpdating && error) {
      toast.error((error as any)?.message || 'Something went wrong.');
    }
  }, [error]);

  const formik: FormikProps<OffVacation> = useFormik<OffVacation>({
    initialValues: initialValues,
    validationSchema: offVacationSchema,
    onSubmit: (values: OffVacation) => {
      handleSubmit(values);
    }
  });
  const handleSubmit = (values: OffVacation) => {
    // if (values.type === 'SOMEDAY') {
    //   const range = eachDayOfInterval({
    //     start: values.dates ? new Date(values.dates?.[0]) || new Date() : new Date(),
    //     end: values.dates ? new Date(values?.dates?.[1]) || new Date() : new Date()
    //   });
    //   dispatch(
    //     createOffVacation({
    //       ...values,
    //       date: values.date || undefined,
    //       dates: range.map((r) => formatTo_yyyyMMdd(r))
    //     })
    //   );
    // } else {
    //   dispatch(createOffVacation({ ...values, date: values.date || undefined }));
    // }
  };

  const onSelectType = (e: any) => {
    // setSelectedType(e.target.value);
    formik.setFieldValue('type', e.target.value);
  };

  const onDateChange = (field: string, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
  };

  const onConsecutiveDayChange = (item: DateObject[]) => {
    setDates(item);
    const newSelectedDates = item.map((i: DateObject) => formatTo_yyyyMMdd(i.toDate()));
    setSelectedDates(newSelectedDates);
    formik.setFieldValue(
      'dates',
      newSelectedDates.map((date) => formatTo_yyyyMMdd(new Date(date)))
    );
  };

  const projectTabContent = (projectId: string) => {
    const pendingCount = pendingProjectCount?.find(
      (prj: any) => prj?._id?.projectId?.[0] === projectId
    );
    return (
      <Nav.Item className="position-relative" key={projectId}>
        <Nav.Link eventKey={projectId}>
          <Badge variant="danger" className="position-absolute" style={{ right: '10px' }}>
            {pendingCount?.count || 0}
          </Badge>
          {pendingCount?._id.project || projects.find((project) => project._id === projectId)?.name}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-start mb-4">
        <OffVacataionTypeTab />
        {/* <ProjectFilter /> */}
        <ShiftChangePersonalFilter setSelectedProjects={setSelectedProjects} />
        <ShiftChangeProjectFilter
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
        <ShiftChangeSaveFilter selectedProjects={selectedProjects} />{' '}
      </div>
      <Nav
        fill
        variant="tabs"
        defaultActiveKey={selectedProjectTab}
        className="mb-5 px-2 border-bottom"
        onSelect={(key: string | null) => setSelectedProjectTab(key !== null ? key : '')}
      >
        {selectedProjects.map(
          (prjId) => projects.some((project) => project._id === prjId) && projectTabContent(prjId)
        )}
      </Nav>
      {offVacations.length == 0 && <div>No Data</div>}
      {isFetchingProject
        ? useSpinner()
        : offVacations.length > 0 && (
            <FormikProvider value={formik}>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} xs={6} className="mb-3" controlId="type">
                    <Form.Label className="required">Request Type</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Disable"
                      onBlur={formik.handleBlur}
                      onChange={onSelectType}
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                      value={formik.values.type}
                      name="type"
                      custom
                    >
                      {types.map((type) => (
                        <option key={type.key} value={type.key}>
                          {type.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} xs={6} className="mb-3" controlId="requester">
                    <Form.Label className="required">Requester</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Disable"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                      value={formik.values.requester}
                      name="requester"
                      isInvalid={formik.touched.requester && !!formik.errors.requester}
                      custom
                    >
                      <option key="" value="">
                        Select Agent
                      </option>
                      {currentUser.role === 'AGENT' ? (
                        <option key={currentUser._id} value={currentUser._id}>
                          {currentUser?.displayName || currentUser?.username}
                        </option>
                      ) : (
                        projectUsers.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user?.displayName || user?.username}
                          </option>
                        ))
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.requester}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} className="mb-3" controlId="project">
                    <Form.Label className="required">Project</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Disable"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                      value={formik.values.project}
                      name="project"
                      isInvalid={formik.touched.project && !!formik.errors.project}
                      custom
                    >
                      <option key="" value="">
                        Select Project
                      </option>
                      {projects.map((project) => (
                        <option key={project._id} value={project._id}>
                          {project.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.project}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {(formik.values.type === 'ONEDAY' || formik.values.type === 'SOMEHOURS') && (
                  <Form.Row>
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      placement="top-end"
                      overlay={
                        <Popover
                          id="popover-basic"
                          style={{ maxWidth: '600px' }}
                          onMouseDown={(e: any) => {
                            e.stopPropagation();
                          }}
                        >
                          <Popover.Content style={{ padding: 0 }}>
                            <DateRange
                              onChange={(item) => onDateChange('date', item)}
                              // date={parse(date, 'yyyy-MM-dd', new Date())}
                            />
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Form.Group as={Col} className="mb-3" controlId="date" xs={6}>
                        <Form.Label className="text-start required">Date</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Date"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.date}
                          isInvalid={formik.touched.date && !!formik.errors.date}
                          onMouseDown={(e: any) => {
                            e.stopPropagation();
                          }}
                        />

                        <Form.Control.Feedback type="invalid">
                          {formik.errors.date}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </OverlayTrigger>

                    <Form.Group as={Col} xs={6} className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <br />
                      <Button
                        size="sm"
                        variant="success"
                        onClick={() => onUpdateStatus('APPROVED')}
                      >
                        Approve
                      </Button>
                      <Button size="sm" variant="danger" onClick={() => onUpdateStatus('REJECTED')}>
                        Reject
                      </Button>
                    </Form.Group>
                  </Form.Row>
                )}
                {formik.values.type === 'CONSECUTIVEDAY' && (
                  <>
                    <Form.Row>
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="top-end"
                        overlay={
                          <Popover
                            id="popover-basic"
                            style={{ maxWidth: '600px' }}
                            onMouseDown={(e: any) => {
                              e.stopPropagation();
                            }}
                          >
                            <Popover.Content style={{ padding: 0 }}>
                              <Calendar
                                range
                                rangeHover
                                value={dates}
                                onChange={onConsecutiveDayChange}
                                plugins={[<DatePanel />]}
                              />
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Form.Group as={Col} controlId="date" xs={6} className="mb-0">
                          <Form.Label className="text-start required mr-2">Date</Form.Label>
                          <i className="fas fa-calendar"></i>
                          <Form.Control
                            type="hidden"
                            placeholder="Date"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.date}
                            isInvalid={formik.touched.date && !!formik.errors.date}
                            onMouseDown={(e: any) => {
                              e.stopPropagation();
                            }}
                          />
                          <div className="mb-3">
                            {selectedDates.map((date, index) => (
                              <>
                                <Badge pill key={date} className="mr-1" variant="primary">
                                  {date}
                                </Badge>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                  key={index + 999}
                                  size="sm"
                                  variant="success"
                                  onClick={() => onUpdateStatusArray('APPROVED', index)}
                                >
                                  Approve
                                </Button>
                                <Button
                                  key={index - 999}
                                  size="sm"
                                  variant="danger"
                                  onClick={() => onUpdateStatusArray('REJECTED', index)}
                                >
                                  Reject
                                </Button>
                                <br />
                                <br />
                              </>
                            ))}
                          </div>
                        </Form.Group>
                      </OverlayTrigger>
                    </Form.Row>
                  </>
                )}
                {formik.values.type === 'SOMEDAY' && (
                  <>
                    <Form.Row>
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="top-end"
                        overlay={
                          <Popover
                            id="popover-basic"
                            style={{ maxWidth: '600px' }}
                            onMouseDown={(e: any) => {
                              e.stopPropagation();
                            }}
                          >
                            <Popover.Content style={{ padding: 0 }}>
                              <Calendar
                                multiple
                                value={dates}
                                onChange={onConsecutiveDayChange}
                                plugins={[<DatePanel />]}
                              />
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Form.Group as={Col} controlId="date" xs={6} className="mb-0">
                          <Form.Label className="text-start required mr-2">Date</Form.Label>
                          <i className="fas fa-calendar"></i>
                          <Form.Control
                            type="hidden"
                            placeholder="Date"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.date}
                            isInvalid={formik.touched.date && !!formik.errors.date}
                            onMouseDown={(e: any) => {
                              e.stopPropagation();
                            }}
                          />
                        </Form.Group>
                      </OverlayTrigger>
                    </Form.Row>
                    <div className="mb-3">
                      {selectedDates.map((date, index) => (
                        <>
                          <Badge pill key={date} className="mr-1" variant="primary">
                            {date}
                          </Badge>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Button
                            size="sm"
                            variant="success"
                            onClick={() => onUpdateStatusArray('APPROVED', index)}
                          >
                            Approve
                          </Button>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => onUpdateStatusArray('REJECTED', index)}
                          >
                            Reject
                          </Button>
                          <br />
                          <br />
                        </>
                      ))}
                    </div>
                  </>
                )}
                {formik.values.type === 'SOMEHOURS' && (
                  <Form.Row>
                    <Form.Group as={Col} className="mb-3" controlId="from">
                      <Form.Label>From</Form.Label>
                      <TimePicker formik={formik} fieldName={'from'}>
                        <div>
                          <Form.Control
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            onKeyDown={(e: any) => e.preventDefault()}
                            name="from"
                            value={formik.values.from}
                            isInvalid={formik.touched.from && !!formik.errors.from}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.from}
                          </Form.Control.Feedback>
                        </div>
                      </TimePicker>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" controlId="to">
                      <Form.Label>To</Form.Label>
                      <TimePicker formik={formik} fieldName={'to'}>
                        <div>
                          <Form.Control
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            onKeyDown={(e: any) => e.preventDefault()}
                            name="to"
                            value={formik.values.to}
                            isInvalid={formik.touched.to && !!formik.errors.to}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.to}
                          </Form.Control.Feedback>
                        </div>
                      </TimePicker>
                    </Form.Group>
                  </Form.Row>
                )}
                <Form.Row>
                  <Form.Group as={Col} className="mb-3" controlId="reason">
                    <Form.Label className="required">Reason</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Reason"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reason}
                      name="reason"
                      isInvalid={formik.touched.reason && !!formik.errors.reason}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.reason}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Form>
              {currentUser.role === 'AGENT' && (
                <div className="d-flex justify-content-between">
                  <Button variant="outline-secondary" onClick={() => formik.resetForm()}>
                    Cancel
                  </Button>
                  <SpinnerButton
                    btnType={ButtonTypes.Submit}
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      formik.submitForm();
                    }}
                  >
                    Submit
                  </SpinnerButton>
                </div>
              )}
              {currentUser.role === 'MANAGER' && (
                <div className="d-flex justify-content-between">
                  <Button
                    className="btn-icon-only text-light btn btn- btn-sm border-0 mr-0"
                    disabled={page === 1}
                    onClick={() => {
                      setPage(page - 1);
                    }}
                  >
                    <i className="fas fa-chevron-left" />
                  </Button>
                  <>
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => onSubmit()}
                      disabled={
                        ((formik.values.type === 'ONEDAY' || formik.values.type === 'SOMEHOURS') &&
                          typeStatus === '') ||
                        ((formik.values.type === 'SOMEDAY' ||
                          formik.values.type === 'CONSECUTIVEDAY') &&
                          typeStatusArray.length < selectedDates.length)
                      }
                    >
                      Submit
                    </Button>
                  </>
                  <Button
                    className="btn-icon-only text-light btn btn- btn-sm border-0 mr-0"
                    disabled={
                      offVacationsPaginationInfo.totalCount === 0 ||
                      offVacationsPaginationInfo.page === offVacationsPaginationInfo.totalCount
                    }
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    <i className="fas fa-chevron-right" />
                  </Button>
                </div>
              )}
            </FormikProvider>
          )}
    </>
  );
};
export default OffVacationRequestManagerView;
