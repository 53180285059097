import Draggable from 'react-draggable';
import React from 'react';
import { Modal } from 'react-bootstrap';

export default class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable onMouseDown={(e: any) => e.stopPropagation()}>
        <Modal.Dialog {...this.props} />
      </Draggable>
    );
  }
}
