import * as React from 'react';
import { useAuth, useToken } from 'hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  allowedRoles: string[];
  children: React.ReactNode;
}

export const PrivateRoute = ({ allowedRoles, ...children }: Props): JSX.Element => {
  const currentUser = useAuth();
  const token = useToken();
  const location = useLocation();
  if (!token) {
    toast.error('Unauthenticated. Please log in!');
    return <Navigate to="/login" replace />;
  } else {
    if (!currentUser) {
      return <Navigate to="/login" replace />;
    } else {
      if (allowedRoles.includes(currentUser.role)) {
        return <>{children.children}</>;
      } else {
        toast.error("You don't have permission to access this page.");
        return <Navigate to={currentUser.role.toLowerCase() + 's'} replace />;
      }
    }
  }
};
