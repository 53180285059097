import cloneDeep from 'lodash.clonedeep';
import { AnyObject } from 'yup/lib/types';

export const uniqueArray = <T>(array: Array<T>): Array<T> => {
  return array.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      array.findIndex((object) => {
        return JSON.stringify(object) === _value;
      })
    );
  });
};

export const sortArray = (array: AnyObject[], key: string): AnyObject[] => {
  return array.sort((first, second) => {
    if (first[key] > second[key]) {
      return 1;
    }

    if (first[key] < second[key]) {
      return -1;
    }

    return 0;
  });
};

// SimpleEl !== Object (string, number,...)
export const removeSimpleEl = <T>(array: Array<T>, value: T): Array<T> => {
  const index = array.indexOf(value);
  const res = [...array];
  res.splice(index, 1);
  return res;
};

// SimpleEl !== Object (string, number,...)
export const removeSimpleDuplicatedEl = <T>(array: Array<T>): Array<T> => {
  return array.filter((value, index, self) => self.indexOf(value) === index);
};

export function updateObjectInArray<T>(array: T[], newObj: T, key: keyof T): T[] {
  const newArray = cloneDeep(array).map((item) => {
    if (item[key] === newObj[key]) {
      return { ...item, ...newObj };
    }
    return item;
  });
  return newArray;
}
