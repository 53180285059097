import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Login, LoginResponse, TwoFactor, User } from 'models';
import { authApi } from 'services';
import { authActions } from './auth.slice';
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import { regSw } from 'helper';
import ApiService from 'utils/network';
import { API_URL } from 'services/url';

const reg = async () => {
  const serviceWorkerReg = await regSw();
  const subscription = await serviceWorkerReg.pushManager.getSubscription();
  if (subscription === null) {
    const subscription = await serviceWorkerReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        'BLNjnp463AGp9qdYYZd0PWv8oNMRox-gwdyp_MBHWGW8MWoe8XMmUa8FZlRtst0uBOJoO9Rtkut-YCke7afy8hI'
    });
  } else {
    serviceWorkerReg.update();
  }
  await ApiService.post(`${API_URL.BASE_URL}/subscriptions`, subscription);
};

const unreg = async (userId: string) => {
  await ApiService.delete(`${API_URL.BASE_URL}/subscriptions/remove-subscription`, {
    data: {
      userId: userId
    }
  });
};

export function* loginWorker({ payload }: PayloadAction<Login>) {
  try {
    const response: LoginResponse = yield call(authApi.login, payload);
    const cookies = new Cookies();
    cookies.set('accessToken', response?.accessToken, { path: '/' });
    const userInfo: User = yield call(authApi.getCurrentUser);
    cookies.set('currentUser', userInfo, { path: '/' });
    yield toast('Successfully Logged In', { type: 'success' });
    const redirectUrl = userInfo.role === 'MANAGER' ? '/managers' : '/agents';
    yield put(push(redirectUrl));
    try {
      yield reg();
    } catch (e) {
      console.log(e);
    }
    yield put(authActions.postLoginSuccess(response));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(authActions.postLoginFailed(e));
  }
}

export function* verifyTwoFactorWorker({ payload }: PayloadAction<TwoFactor>) {
  try {
    const response: { accessToken: string } = yield call(authApi.verifyTwoFactor, payload);
    localStorage.setItem('twoFactorAccessToken', response.accessToken);
    yield put(authActions.postVerifyTwoFactorSuccess(response.accessToken));
    // yield toast("Successfully Logged In", { type: "success" });
    // yield put(push("/managers"));
  } catch (e: any) {
    console.log('Fetching Data Error: ', e);
    // yield toast(e?.response?.data?.message, {type: "error"});
    yield put(authActions.postVerifyTwoFactorFailed(e));
  }
}

export function* logoutWorker() {
  const cookies = new Cookies();
  try {
    yield unreg(cookies.get('currentUser')._id);
  } catch (e) {
    console.log(e);
  }
  cookies.remove('currentUser', { path: '/' });
  cookies.remove('twoFactorAccessToken', { path: '/' });
  cookies.remove('accessToken', { path: '/' });
  yield toast.success('Successfully Logged Out', { type: 'success' });
  yield put(authActions.getCurrentUserSuccess(null));
  yield put(authActions.logoutSuccess());
  yield put(push('/login'));
}

export function* getCurrentUserWorker({ payload }: PayloadAction<any>) {
  try {
    const response: User = yield call(authApi.getCurrentUser);
    const cookies = new Cookies();
    cookies.set('currentUser', response, { path: '/' });
    yield put(authActions.getCurrentUserSuccess(response));
  } catch (e: any) {
    console.log('Fetching Datah Error: ', e);
    // yield toast(e?.response?.data?.message, {type: "error"});
    yield put(authActions.postVerifyTwoFactorFailed(e));
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.postLogin, loginWorker);
  yield takeLatest(authActions.postVerifyTwoFactor, verifyTwoFactorWorker);
  yield takeEvery(authActions.logout, logoutWorker);
  yield takeLatest(authActions.getCurrentUser, getCurrentUserWorker);
}
