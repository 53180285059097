import { Filter, SalaryPeriod } from 'models';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { salaryPeriodApi } from 'services/salaryPeriod.api';
import { filterActions, filterSelector } from './filter.slice';
import { filterApi } from 'services';
import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponse } from 'utils/network';

export function* getPersonalFiltersWorker({ payload }: any) {
  try {
    const response: Filter[] = yield call(filterApi.getFilters, payload);
    const filters = response.sort((a: Filter, b: Filter) => {
      return a.name > b.name ? 1 : -1;
    });
    yield put(filterActions.getPersonalFiltersSuccess(filters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    // yield put(projectActions.getProjectsFailed(e));
  }
}

export function* createPersonalFiltersWorker({ payload }: PayloadAction<any>) {
  try {
    const response: Filter = yield call(filterApi.createFilter, payload);
    const filters: Filter[] = yield select(filterSelector.selectPersonalFilters);
    const newFilters = [...filters];
    newFilters.push(response);
    yield put(filterActions.createPersonalFilterSuccess(newFilters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(filterActions.createPersonalFilterFailed(e));
  }
}

export function* deleteFilterWorker({ payload }: PayloadAction<{ filterId: string }>) {
  try {
    const { filterId } = payload;
    const response: Filter = yield call(filterApi.deleteFilter, filterId);
    yield put(filterActions.deleteFilterSuccess({ filterId }));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(
      filterActions.deleteFilterFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

export default function* filterSaga() {
  yield takeLatest(filterActions.getPersonalFilters, getPersonalFiltersWorker);
  yield takeLatest(filterActions.createPersonalFilter, createPersonalFiltersWorker);
  yield takeLatest(filterActions.deleteFilter, deleteFilterWorker);
}
