import { Nullable } from '../../utils/common/types';
import { Project } from 'models';
import ProjectFilter from 'components/Scheduler/FilterActions/ProjectFilter/ProjectFilter';

export type ProjectInitialState = {
  projects: Project[];
  project: Project;
  error: Nullable<any>;
  isFetching: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  isModalUpdateProjectVisible: boolean;
  isUpdatingProjectUsers: boolean;
  selectedProject: Project | null;
};

export const initialState: ProjectInitialState = {
  projects: [],
  project: Object.assign({}),
  error: null,
  isFetching: false,
  isCreating: false,
  isDeleting: false,
  isModalUpdateProjectVisible: false,
  isUpdatingProjectUsers: false,
  selectedProject: null
};
