import { Division } from './division.model';

export enum ButtonTypes {
  Button = 'button',
  Submit = 'submit',
  React = 'reset'
}

export interface UserSlotFormProps {
  userId: string;
  duration: string;
  divisionId: string;
  note: string;
}

export enum SchedulerType {
  ACTUAL = 'actual',
  FIX = 'fix',
  AVAILABLE = 'available'
}

export enum WeekDay {
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7
}

export enum WeekType {
  WEEKDAY = 'WEEKDAY',
  WEEKEND = 'WEEKEND',
  ALL = 'ALL WEEK'
}

export const getWeekdays = () => {
  return Object.keys(WeekDay).filter((d) => isNaN(parseInt(d)));
};

export const getWeekdayNumber = (weekday: string): number => {
  const weekdayNumber = Object.entries(WeekDay).find(([key]) => key === weekday)?.[1];

  if (!weekdayNumber) {
    return 1;
  }

  return parseInt(weekdayNumber as string);
};

export interface PaginationInfo {
  page: number;
  perPage: number;
  totalCount: number;
}

export interface DragAndDropParams {
  type: string;
  slotId: string;
  shiftId: string;
  division?: Division | undefined;
}
