import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { AppWrapper, MainContent } from './AppLayout.styled';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { ErrorBoundary } from 'react-error-boundary';
import { Page500 } from 'components/common';
const Layout = () => {
  return (
    <AppWrapper>
      <Sidebar />
      <MainContent className="main-content" id="panel">
        <ErrorBoundary FallbackComponent={Page500}>
          <Header />
          <Breadcrumb />
          <Container fluid className="mt--6">
            <Outlet></Outlet> {/* Child Components will render here */}
          </Container>
        </ErrorBoundary>
      </MainContent>
    </AppWrapper>
  );
};

export default Layout;
