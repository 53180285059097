import React, { useEffect, useState } from 'react';
import { parse, format } from 'date-fns';
import { OverlayTrigger, Popover, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './timePicker.scss';
import moment from 'moment';

interface Props {
  formik?: any;
  fieldName: string;
  children?: any;
  onChangeTime?: (value: string) => void;
  startTime?: any;
  endTime?: any;
}

export const TimePicker = (props: Props) => {
  const [startTime, setStartTime] = useState(new Date(0, 0, 0, 0));
  const [endTime, setEndTime] = useState(new Date(0, 0, 0, 23, 45));
  const onChange = (date: Date) => {
    const time = format(new Date(date), 'HH:mm') || '';
    if (props.formik) {
      props.formik.setFieldValue([props.fieldName], time);
    }
    if (props.onChangeTime) {
      props.onChangeTime(time);
    }
  };

  const getTime = () => {
    if (props.formik && props.formik.values[props.fieldName]) {
      const time = parse(props.formik.values[props.fieldName], 'HH:mm', new Date());
      if (!isNaN(time.getTime())) {
        return time;
      }
      return new Date();
    } else {
      return new Date();
    }
  };

  useEffect(() => {
    if (props.startTime) {
      const start = parse(props.startTime, 'HH:mm', new Date());
      setStartTime(new Date(0, 0, 0, start.getHours(), start.getMinutes()));
    }
    if (props.endTime) {
      if (props.endTime == '00:00') {
        setEndTime(moment().endOf('day').toDate());
      } else {
        const end = parse(props.endTime, 'HH:mm', new Date());
        setEndTime(new Date(0, 0, 0, end.getHours(), end.getMinutes()));
      }
    }
  }, [props.startTime, props.endTime]);

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="bottom-end"
      overlay={
        <Popover id="popover-basic" style={{ padding: '0' }}>
          <Popover.Content style={{ padding: 0 }}>
            <DatePicker
              selected={getTime()}
              onChange={(date: Date) => onChange(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              inline
              minTime={startTime}
              maxTime={endTime}
            />
          </Popover.Content>
        </Popover>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
};
