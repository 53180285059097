import { AppState } from '../index.store';

export const selectIsModalCreateSlotVisible = (state: AppState) =>
  state.schedulerFixs.isModalCreateSlotVisible;

export const selectSelectedShiftId = (state: AppState) => state.schedulerFixs.selectedShiftId;

export const selectSelectedDate = (state: AppState) => state.schedulerFixs.selectedDate;

export const selectIsApplyingFilter = (state: AppState) => state.schedulerFixs.isApplyingFilter;

export const selectPersonalFilters = (state: AppState) => state.schedulerFixs.personalFilters;

export const selectIsLoadingFilters = (state: AppState) => state.schedulerFixs.isLoadingFilters;

export const selectError = (state: AppState) => state.schedulerFixs.error;
