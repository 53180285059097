import { Badge } from 'react-bootstrap';
import { RemoveDivisionButton } from './ModalManageDivision.styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Division, Project } from 'models';
import { FcOverlayTriggerClick } from 'components/Shared/FcOverlayTriggerClick/FcOverlayTriggerClick';
import { SwatchesPicker, ColorResult } from 'react-color';
import { FcModalConfirm, SpinnerButton } from 'components/Shared';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
interface Props {
  division: Division;
  onRemove: (divisionId: string) => void;
  onSelectColor: (color: string) => void;
}
const DivisionItem = (props: Props) => {
  const { division, onRemove, onSelectColor } = { ...props };
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);

  const onChangeComplete = (color: ColorResult) => {
    onSelectColor(color.hex);
  };

  const onOk = () => {
    onRemove(division._id);
  };

  return (
    <>
      <FcOverlayTriggerClick
        overlay={<SwatchesPicker onChangeComplete={onChangeComplete} />}
        triggerComponent={
          <Badge
            pill
            className="mr-2 pr-2 text-white"
            style={{ background: division.color }}
            key={division._id}
          >
            {division.name}
            <RemoveDivisionButton
              icon={solid('xmark')}
              onClick={() => {
                setIsModalConfirmVisible(true);
                setTimeout(() => {
                  document.body.click();
                }, 5);
              }}
            />
          </Badge>
        }
      />

      <FcModalConfirm
        show={isModalConfirmVisible}
        onHide={() => setIsModalConfirmVisible(false)}
        title="Confirmation"
        body={
          <>
            <p>
              Are you sure you want to <strong>Delete</strong> division{' '}
              <strong>{division.name}</strong>?
            </p>
          </>
        }
        footer={
          <>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setIsModalConfirmVisible(false);
              }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <SpinnerButton
              btnType="submit"
              colorType="primary"
              onClick={onOk}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              OK
            </SpinnerButton>
          </>
        }
      />
    </>
  );
};

export default DivisionItem;
