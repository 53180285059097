import { AppState } from '../index.store';

export const selectError = (state: AppState) => state.reports.error;

export const selectIsFetching = (state: AppState) => state.reports.isFetching;

export const selectSelectedDateRange = (state: AppState) => state.reports.selectedDateRange;

export const selectReports = (state: AppState) => state.reports.reports;

export const selectDashboardData = (state: AppState) => state.reports.dashboardData;

export const selectSortString = (state: AppState) => state.reports.sortString;

export const selectIsExporting = (state: AppState) => state.reports.isExporting;

export const selectIsCreatingFilter = (state: AppState) => state.reports.isCreatingFilter;

export const selectFilters = (state: AppState) => state.reports.personalFilters;

export const selectUserType = (state: AppState) => state.reports.userType;
