import { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { ButtonTypes } from '../../../models/common.model';

interface Props {
  // btnType?: ButtonTypes | undefined; // success
  btnType?: 'button' | 'submit' | 'reset' | undefined; // error
  colorType?: string;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  size?: 'lg' | 'sm' | undefined;
  onClick?: () => void;
  onMouseDown?: (e: any) => void;
}

export const SpinnerButton = (props: Props) => {
  return (
    <Button
      variant={props.colorType}
      disabled={props.disabled}
      type={props.btnType}
      className={props.className}
      onClick={props.onClick}
      size={props.size}
    >
      {props.disabled ? (
        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      ) : (
        props.children
      )}
    </Button>
  );
};
