import { AppState } from '../index.store';

export const selectIsModalCreateSlotVisible = (state: AppState) =>
  state.schedulers.isModalCreateSlotVisible;

export const selectSelectedShiftId = (state: AppState) => state.schedulers.selectedShiftId;

export const selectShowingCompactOverview = (state: AppState) =>
  state.schedulers.showingCompactOverview;

export const selectSelectedDate = (state: AppState) => state.schedulers.selectedDate;

export const selectSelectedDateRange = (state: AppState) => state.schedulers.selectedDateRange;

export const selectIsApplyingFilter = (state: AppState) => state.schedulers.isApplyingFilter;

export const selectPersonalFilters = (state: AppState) => state.schedulers.personalFilters;

export const selectIsLoadingFilters = (state: AppState) => state.schedulers.isLoadingFilters;

export const selectViewMode = (state: AppState) => state.schedulers.viewMode;

export const selectError = (state: AppState) => state.schedulers.error;
