import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { schedulerActions, schedulerSelector } from 'store/schedulers/scheduler.slice';
import { useViewFilter } from './useViewFilter';

const ViewFilter = () => {
  const { VIEW_MODE, selectedViewMode, onSelect } = useViewFilter();

  return (
    <ToggleButtonGroup
      className="mr-2"
      type="radio"
      name="viewType"
      value={selectedViewMode}
      onChange={(val) => onSelect(val)}
      size="sm"
    >
      {VIEW_MODE.map((mode) => (
        <ToggleButton size="sm" key={mode} value={mode} variant="outline-primary">
          {mode}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ViewFilter;
