import styled from 'styled-components';

export const WarningTriangle = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  font-size: 0.6rem;
  border-radius: 50%;
  background: var(--yellow);
  color: #fff;
  top: 0.15rem;
  right: 0.15rem;
  position: absolute;
`;
