import * as Yup from 'yup';

export const shiftSchema = Yup.object().shape({
  startTime: Yup.string().required(),
  endTime: Yup.string()
    .required()
    .test('isEqualStartTime', 'End Time must not be Equal to Start Time', (value, schema) => {
      return value !== schema.parent.startTime;
    })
});

export const shiftChangeSchema = Yup.object().shape({
  employeeType: Yup.string().required('Required'),
  project: Yup.string().required('Required'),
  owner: Yup.string()
    .required('Required')
    .test(
      'Owner or Agent must be current logged-in user.',
      'Owner or Agent must be current logged-in user.',
      (value, schema) => {
        return (
          value === schema.parent.createdBy ||
          schema.parent.agent[0] === schema.parent.createdBy ||
          schema.parent.agent[1] === schema.parent.createdBy
        );
      }
    ),
  agent: Yup.array(),
  'agent[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agent[0] !== undefined && schema.parent.agent[0] !== '';
  }),
  'agent[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agent[1] !== undefined && schema.parent.agent[1] !== '';
    }
    return true;
  }),
  ownerShift: Yup.string().required('Required'),
  agentShift: Yup.array(),
  'agentShift[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agentShift[0] !== undefined && schema.parent.agentShift[0] !== '';
  }),
  'agentShift[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agentShift[1] !== undefined && schema.parent.agentShift[1] !== '';
    }
    return true;
  }),
  ownerNewShift: Yup.string().required('Required'),
  agentNewShift: Yup.array(),
  'agentNewShift[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agentNewShift[0] !== undefined && schema.parent.agentNewShift[0] !== '';
  }),
  'agentNewShift[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agentNewShift[1] !== undefined && schema.parent.agentNewShift[1] !== '';
    }
    return true;
  }),
  ownerDate: Yup.string().required('Required'),
  agentDate: Yup.array(),
  'agentDate[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agentDate[0] !== undefined && schema.parent.agentDate[0] !== '';
  }),
  'agentDate[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agentDate[1] !== undefined && schema.parent.agentDate[1] !== '';
    }
    return true;
  }),
  ownerNewDate: Yup.string().required('Required'),
  agentNewDate: Yup.array(),
  'agentNewDate[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agentNewDate[0] !== undefined && schema.parent.agentNewDate[0] !== '';
  }),
  'agentNewDate[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agentNewDate[1] !== undefined && schema.parent.agentNewDate[1] !== '';
    }
    return true;
  }),
  ownerTime: Yup.string().test('Required', 'Required', (value, schema) => {
    if (schema.parent.type === 'COVER') {
      return value !== undefined && value !== '';
    }
    return true;
  }),
  agentTime: Yup.string().test('Required', 'Required', (value, schema) => {
    if (schema.parent.type === 'COVER') {
      return value !== undefined && value !== '';
    }
    return true;
  }),
  reason: Yup.string().required('Required'),
  offVacation: Yup.string().required('Required')
});

export const shiftCoverSchema = Yup.object().shape({
  project: Yup.string().required('Required'),
  owner: Yup.string()
    .required('Required')
    .test(
      'Owner or Agent must be current logged-in user.',
      'Owner or Agent must be current logged-in user.',
      (value, schema) => {
        return (
          value === schema.parent.createdBy ||
          schema.parent.agent[0] === schema.parent.createdBy ||
          schema.parent.agent[1] === schema.parent.createdBy
        );
      }
    ),
  agent: Yup.array(),
  'agent[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agent[0] !== undefined && schema.parent.agent[0] !== '';
  }),
  'agent[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agent[1] !== undefined && schema.parent.agent[1] !== '';
    }
    return true;
  }),
  ownerShift: Yup.string().required('Required'),
  ownerDate: Yup.string().required('Required'),
  ownerTime: Yup.string().test('Required', 'Required', (value, schema) => {
    if (schema.parent.type === 'COVER') {
      return value !== undefined && value !== '';
    }
    return true;
  }),
  agentTime: Yup.string().test('Required', 'Required', (value, schema) => {
    if (schema.parent.type === 'COVER') {
      return value !== undefined && value !== '';
    }
    return true;
  }),
  reason: Yup.string().required('Required'),
  offVacation: Yup.string().required('Required')
});

export const shiftReplaceSchema = Yup.object().shape({
  project: Yup.string().required('Required'),
  owner: Yup.string()
    .required('Required')
    .test(
      'Owner or Agent must be current logged-in user.',
      'Owner or Agent must be current logged-in user.',
      (value, schema) => {
        return (
          value === schema.parent.createdBy ||
          schema.parent.agent[0] === schema.parent.createdBy ||
          schema.parent.agent[1] === schema.parent.createdBy
        );
      }
    ),
  agent: Yup.array(),
  'agent[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agent[0] !== undefined && schema.parent.agent[0] !== '';
  }),
  'agent[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agent[1] !== undefined && schema.parent.agent[1] !== '';
    }
    return true;
  }),
  ownerShift: Yup.string().required('Required'),
  ownerDate: Yup.string().required('Required'),
  reason: Yup.string().required('Required'),
  offVacation: Yup.string().required('Required')
});

export const shiftMoveSchema = Yup.object().shape({
  project: Yup.string().required('Required'),
  owner: Yup.string()
    .required('Required')
    .test(
      'Owner or Agent must be current logged-in user.',
      'Owner or Agent must be current logged-in user.',
      (value, schema) => {
        return (
          value === schema.parent.createdBy || schema.parent.agent[0] === schema.parent.createdBy
        );
      }
    ),
  ownerShift: Yup.string().required('Required'),
  agentShift: Yup.array(),
  'agentShift[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agentShift[0] !== undefined && schema.parent.agentShift[0] !== '';
  }),
  'agentShift[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agentShift[1] !== undefined && schema.parent.agentShift[1] !== '';
    }
    return true;
  }),
  ownerDate: Yup.string().required('Required'),
  agentDate: Yup.array(),
  'agentDate[0]': Yup.string().test('Required.', 'Required', (value, schema) => {
    return schema.parent.agentDate[0] !== undefined && schema.parent.agentDate[0] !== '';
  }),
  'agentDate[1]': Yup.string().test('Required.', 'Required', (value, schema) => {
    if (schema.parent.employeeType === 'FullTime <> PartTime') {
      return schema.parent.agentDate[1] !== undefined && schema.parent.agentDate[1] !== '';
    }
    return true;
  }),
  reason: Yup.string().required('Required'),
  offVacation: Yup.string().required('Required')
});
