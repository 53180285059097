import { format, isWeekend } from 'date-fns';
import { ShiftHelper } from 'helpers';
import { useAuth } from 'hooks/useAuth';
import { Shift, Slot } from 'models';
import React, { SetStateAction } from 'react';
import { Item, Menu, contextMenu, useContextMenu } from 'react-contexify';
import { useAppDispatch } from 'store/hooks';
import { schedulerActions } from 'store/schedulers/scheduler.slice';
import ShiftAgent from '../../MainActions/ShiftAgent/ShiftAgent';
import { useSchedulerRowHeader } from './../../../../hooks/scheduler/useSchedulerRowHeader';
interface Props {
  shift: Shift;
  dates: any;
  selectedDates: any;
  setDraggingItem: React.Dispatch<SetStateAction<object>>;
  setDroppedItem: React.Dispatch<SetStateAction<object>>;
}

const SchedulerRow = (props: Props) => {
  const currentUser = useAuth();
  const { shift, dates, setDraggingItem, setDroppedItem } = props;

  const { show } = useContextMenu();
  const displayMenu = (e: any, id: string) => {
    if (!currentUser || currentUser?.role !== 'MANAGER') {
      return;
    }
    show({
      event: e,
      id: id
    });
  };

  const dispatch = useAppDispatch();

  const isSlotSameDateColumn = (date: Date, slot: Slot) => {
    return format(date, 'yyyy-MM-dd') === format(new Date(slot.date), 'yyyy-MM-dd');
  };

  const onDrop = (e: any, date: Date) => {
    e.stopPropagation();
    contextMenu.show({
      id: 'CREATE',
      event: e
    });
    props.setDroppedItem({
      type: 'shift',
      date: date,
      shiftId: shift._id
    });
  };

  const onAssign = (date: Date) => {
    dispatch(schedulerActions.setIsModalCreateSlotVisible(true));
    dispatch(schedulerActions.setSelectedShiftId(shift._id));
    dispatch(schedulerActions.setSelectedDate(format(date, 'yyyy-MM-dd')));
  };

  const isSelectedDay = (date: Date) => {
    return (
      date >= props.selectedDates[0] && date <= props.selectedDates[props.selectedDates.length - 1]
    );
  };

  const getBackgroundColor = (date: Date) => {
    if (isSelectedDay(date)) {
      // return isWeekend(date) ? "#5e72e41a" : "none"
      return isWeekend(date) ? '#5e72e40d' : 'none';
    } else {
      return '#fafafa';
    }
  };

  return (
    <tr>
      {useSchedulerRowHeader(shift)}
      {dates.map((d: Date, index: number) => (
        <td
          key={index}
          onDrop={(e) => onDrop(e, d)}
          onDragStart={(e) => e.stopPropagation()}
          onDragEnter={(e) => e.stopPropagation()}
          onDragLeave={(e) => e.stopPropagation()}
          onDragOver={(e) => e.preventDefault()}
          onContextMenu={(e) => {
            e.stopPropagation();
            displayMenu(e, `${index + shift._id + d}`);
          }}
          style={{
            background: getBackgroundColor(d)
          }}
        >
          <div>
            <Menu id={index + shift._id + d} theme="light">
              <Item onClick={() => onAssign(d)}>Assign</Item>
            </Menu>
          </div>
          {isSelectedDay(d) &&
            (shift.slots as Slot[]).map(
              (slot: Slot, slotIndex: number) =>
                isSlotSameDateColumn(d, slot) && (
                  <ShiftAgent
                    key={slot._id + slotIndex}
                    index={index}
                    slotIndex={slotIndex}
                    data={slot}
                    shiftData={shift}
                    shiftDuration={ShiftHelper.getDurationOfShift(shift)}
                    onSetDraggingItem={setDraggingItem}
                    onSetDroppedItem={setDroppedItem}
                    date={d}
                  />
                )
            )}
        </td>
      ))}
    </tr>
  );
};

export default SchedulerRow;
