import { PayloadAction } from '@reduxjs/toolkit';
import { FixedShift, Project, WeekType } from 'models';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fixedShiftApi, projectApi } from 'services';
import { projectActions, projectSelector } from './project.slice';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';
import fixedShiftSlice, { fixedShiftActions } from 'store/fixedShifts/fixedShift.slice';
import { AxiosResponse } from 'axios';
import { userActions } from 'store/users/user.slice';

export function* getProjectsWorker() {
  try {
    const response: Project[] = yield call(projectApi.getProjects);
    const sortedProjects = response.sort((a: Project, b: Project) => {
      return a.name > b.name ? 1 : -1;
    });
    yield put(projectActions.getProjectsSuccess(sortedProjects));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(projectActions.getProjectsFailed(e));
  }
}

export function* getProjectsNoUpdateFetchingWorker() {
  try {
    const response: Project[] = yield call(projectApi.getProjects);
    const sortedProjects = response.sort((a: Project, b: Project) => {
      return a.name > b.name ? 1 : -1;
    });
    yield put(projectActions.getProjectsNoUpdateFetchingSuccess(sortedProjects));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(projectActions.getProjectsNoUpdateFetchingFailed(e));
  }
}

export function* getProjectWorker(payload: PayloadAction<string>) {
  try {
    const response: Project = yield call(projectApi.getProject, payload);
    yield put(projectActions.getProjectSuccess(response));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(projectActions.getProjectsFailed(e));
  }
}

export function* createProjectWorker(payload: PayloadAction<any>) {
  try {
    const projectResponse: Project = yield call(projectApi.createProject, {
      ...payload.payload,
      weekDefinition: parseInt(payload.payload.weekDefinition)
    });
    const addingItems: FixedShift[] = [];
    for (let i = 0; i < payload.payload.numberOfShifts; i++) {
      addingItems.push({
        startTime: '00:00',
        endTime: '06:00',
        name: `Shift ${i + 1}`,
        numOfAgents: 0,
        breakTime: 0,
        type: WeekType.WEEKDAY
      } as FixedShift);
    }
    const shiftResponse = (yield call(fixedShiftApi.createShifts, {
      projectId: projectResponse._id,
      payload: addingItems
    })) as FixedShift[];
    yield put(fixedShiftActions.setShifts(sortShiftsByName(shiftResponse) as FixedShift[]));
    yield put(projectActions.createProjectSuccess(projectResponse));
    yield put(projectActions.setSelectedProject(projectResponse));
    yield put(projectActions.getProjects());
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(projectActions.getProjectsFailed(e));
  }
}

export function* updateProjectWorker(payload: PayloadAction<any>) {
  try {
    const _payload = {
      ...payload.payload,
      weekDefinition: parseInt(payload.payload.weekDefinition)
    };
    const response: Project = yield call(projectApi.updateProject, {
      payload: _payload,
      projectId: payload.payload.id
    });
    const projects: Project[] = yield select(projectSelector.selectProjects);
    const project = projects.find((p) => p._id === payload.payload.id);
    if (
      project?.numberOfShifts !== undefined &&
      project?.numberOfShifts !== payload.payload.numberOfShifts
    ) {
      yield updateProjectShift(project, payload.payload.numberOfShifts);
    }
    yield put(projectActions.updateProjectSuccess(response));
    yield put(projectActions.setSelectedProject(response));
    yield put(projectActions.getProjectsNoUpdateFetching());
    yield put(userActions.setUsers(response.users));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(projectActions.updateProjectFailed(e));
  }
}

export function* updateProjectUsersWorker(payload: PayloadAction<any>) {
  try {
    const _payload = {
      ...payload.payload,
      weekDefinition: parseInt(payload.payload.weekDefinition)
    };
    const response: Project = yield call(projectApi.updateProject, {
      payload: _payload,
      projectId: payload.payload.id
    });
    yield put(projectActions.updateProjectUsersSuccess(response));
    yield put(projectActions.setSelectedProject(response));
    yield put(projectActions.getProjectsNoUpdateFetching());
    yield put(userActions.setUsers(response.users));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(projectActions.updateProjectUsersFailed(e));
  }
}

export function* setIsModalUpdateProjectVisibleWorker(payload: PayloadAction<boolean>) {
  yield put(projectActions.setIsModalUpdateProjectVisibleSuccess(payload.payload));
}

export function* setSelectedProjectWorker(payload: PayloadAction<Project | null>) {
  yield put(projectActions.setSelectedProjectSuccess(payload.payload));
}

function* updateProjectShift(project: Project, numberOfShifts: number) {
  const shifts: FixedShift[] = yield call(fixedShiftApi.getShifts, project._id);
  const sortedShifts = sortShiftsByName(shifts);
  if (project.numberOfShifts > numberOfShifts) {
    const number = project?.numberOfShifts - numberOfShifts;
    const removingShifts = [
      ...sortedShifts.slice(Math.max(sortedShifts.length - number), sortedShifts.length)
    ];

    yield all(
      removingShifts.map((s) =>
        call(fixedShiftApi.removeShift, {
          projectId: project._id,
          shiftId: s._id
        })
      )
    );
  } else {
    const number = numberOfShifts - project?.numberOfShifts;
    const addingItems: FixedShift[] = [];
    for (let i = 0; i < number; i++) {
      const shiftIndex = sortedShifts.length + i + 1;
      addingItems.push({
        startTime: '00:00',
        endTime: '06:00',
        name: `Shift ${shiftIndex}`,
        numOfAgents: 0,
        breakTime: 0,
        type: WeekType.WEEKDAY
      } as FixedShift);
    }
    yield call(fixedShiftApi.createShifts, {
      projectId: project._id,
      payload: addingItems
    });
  }
  yield put(fixedShiftActions.getShifts(project._id));
}

export function* deleteProjectWorker({ payload }: PayloadAction<string>) {
  try {
    const response: Project = yield call(projectApi.deleteProject, payload);
    yield put(projectActions.getProjects());
    yield put(projectActions.deleteProjectSuccess(response));
  } catch (e) {
    console.log('deleteProjectWorker err: ', e);
    yield put(projectActions.deleteProjectFailed(e));
  }
}

export default function* projectSaga() {
  yield takeLatest(projectActions.getProjects, getProjectsWorker);
  yield takeLatest(projectActions.getProject, getProjectWorker);
  yield takeLatest(projectActions.createProject, createProjectWorker);
  yield takeLatest(projectActions.updateProject, updateProjectWorker);
  yield takeLatest(projectActions.updateProjectUsers, updateProjectUsersWorker);
  yield takeLatest(
    projectActions.setIsModalUpdateProjectVisible,
    setIsModalUpdateProjectVisibleWorker
  );
  yield takeLatest(projectActions.setSelectedProject, setSelectedProjectWorker);
  yield takeLatest(projectActions.getProjectsNoUpdateFetching, getProjectsNoUpdateFetchingWorker);
  yield takeLatest(projectActions.deleteProject, deleteProjectWorker);
}
