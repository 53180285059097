import { Project, User } from 'models';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { userActions } from 'store/users/user.slice';
import { useUserFilter } from './useUserFilter';
import { schedulerFixActions } from 'store/schedulerFixs/schedulerFix.slice';
import { schedulerActions } from 'store/schedulers/scheduler.slice';

export const useProjectFilter = () => {
  const dispatch = useAppDispatch();
  const [selectedProject, setSelectedProject] = useState('');
  const [projectLabel, setProjectLabel] = useState('Project');
  const [searchParams, setSearchParams] = useSearchParams();
  const { setSelectedAll, setSelectedUsers } = useUserFilter();
  const projects = useAppSelector(projectSelector.selectProjects);
  const projectId = localStorage.getItem('projectId');
  const filterId = localStorage.getItem('filterId');
  const userIds = localStorage.getItem('userIds');

  useEffect(() => {
    if (projectId && !filterId) {
      const project = projects.find((prj: Project) => prj?._id === projectId);
      if (project) {
        searchParams.set('project', project.name);
        searchParams.set('projectId', project._id);
        setSearchParams(searchParams);
      }
    }
  }, []);

  useEffect(() => {
    const project = projects.find((prj) => prj?.name === searchParams.get('project'));
    if (project) {
      setProjectLabel(project.name);
      setSelectedProject(project._id);
      dispatch(projectActions.setSelectedProject(project));
      setUsersAfterChangingProject(project);
    }
  }, [searchParams]);

  const setUsersAfterChangingProject = (project: Project) => {
    const users: User[] = project.users || [];

    dispatch(userActions.setUsers(users));
    if (userIds) {
      setSelectedUsers(JSON.parse(userIds));
      if (JSON.parse(userIds).length === users.length) {
        setSelectedAll(true);
      }
    } else {
      const selectedUsers = users.map((u) => u._id);
      setSelectedUsers(selectedUsers);
      localStorage.setItem('userIds', JSON.stringify(selectedUsers));
      setSelectedAll(true);
    }
  };

  const onSelectProject = (e: any, projectId: string) => {
    dispatch(schedulerActions.setIsApplyingFilter(true));
    dispatch(schedulerFixActions.setIsApplyingFilter(true));
    if (e !== null) {
      e.stopPropagation();
      localStorage.removeItem('filterId');
    }
    // dispatch(schedulerFixActions.setIsApplyingFilter(true));
    const project = projects.find((prj: Project) => prj?._id === projectId);
    if (project) {
      searchParams.set('project', project.name);
      searchParams.set('projectId', project._id);
      setSearchParams(searchParams);
      localStorage.setItem('projectId', project._id);
      if (e !== null) {
        localStorage.setItem('userIds', JSON.stringify(project.users.map((u) => u._id) || []));
      }
    }
  };

  return { projects, selectedProject, projectLabel, onSelectProject };
};
