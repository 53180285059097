import { PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import {
  GetDivisionsPayload,
  Division,
  SetDivisionsModalAddUserPayload,
  CreateDivisionPayload,
  DeleteDivisionPayload,
  UpdateDivisionPayload
} from 'models';
import { AllEffect, CallEffect, all, call, put, select, takeLatest } from 'redux-saga/effects';
import { divisionApi } from 'services/division.api';
import { divisionActions, divisionSelector } from './division.slice';
import { AxiosResponse } from 'axios';
import { selectSelectedDivisions } from './division.selector';

export function* getDivisionsWorker(payload: PayloadAction<GetDivisionsPayload>) {
  try {
    const response: Division[] = yield call(divisionApi.getDivisions, payload.payload);
    yield put(
      divisionActions.setDivisionsModalAddUser({
        projectId: payload.payload.projectId,
        divisions: response
      })
    );
    yield put(divisionActions.getDivisionsSuccess(response));
  } catch (e) {
    console.log('getDivisionsWorker Error: ', e);
    yield put(divisionActions.getDivisionsFailed(e));
  }
}

export function* getMultiProjectDivisionsWorker(payload: PayloadAction<string[]>) {
  const projectIds = payload.payload;
  try {
    const res: AllEffect<CallEffect<AxiosResponse<any, any>>> = yield all(
      projectIds.map((projectId: string) =>
        call(divisionApi.getDivisions, { projectId: projectId })
      )
    );
    yield all(
      (res as any as []).map((r, index) =>
        put(
          divisionActions.setDivisionsModalAddUser({
            projectId: projectIds[index],
            divisions: r as Division[]
          } as SetDivisionsModalAddUserPayload)
        )
      )
    );
    yield put(divisionActions.getMultiProjectDivisionsSuccess());
  } catch (e) {
    console.log('getDivisionsWorker Error: ', e);
    yield put(divisionActions.getDivisionsFailed(e));
  }
}

export function* setDivisionsModalAddUserWorker(
  payload: PayloadAction<SetDivisionsModalAddUserPayload>
) {
  try {
    const divisionsModalAddUser: any[] = yield select(divisionSelector.selectDivisionsModalAddUser);
    const _divisionsModalAddUser = cloneDeep(
      divisionsModalAddUser.filter((item: any) => item.projectId !== payload.payload.projectId)
    );
    _divisionsModalAddUser.push(payload.payload);

    yield put(divisionActions.setDivisionsModalAddUserSuccess(_divisionsModalAddUser));
  } catch (e) {
    console.log('setDivisionsModalAddUserWorker Error: ', e);
    // yield put(divisionActions.setDivisionsModalAddUserSuccessFailed(e));
  }
}

export function* createDivisionWorker(payload: PayloadAction<CreateDivisionPayload>) {
  try {
    const response: Division = yield call(divisionApi.createDivision, payload.payload);

    const divisions: any[] = yield select(divisionSelector.selectDivisionsModalAddUser);
    const currentProjectDivisions = cloneDeep(
      divisions.find((division) => division.projectId === payload.payload.projectId)
    );
    let setDivisionsPayload: SetDivisionsModalAddUserPayload;
    if (currentProjectDivisions) {
      currentProjectDivisions.divisions.push(response);
      setDivisionsPayload = currentProjectDivisions;
    } else {
      setDivisionsPayload = {
        projectId: payload.payload.projectId,
        divisions: [response]
      };
    }
    yield put(divisionActions.setDivisionsModalAddUser(setDivisionsPayload));
    yield put(divisionActions.createDivisionSuccess(response));
  } catch (e) {
    console.log('createDivisionsWorker Error: ', e);
    yield put(divisionActions.createDivisionFailed(e));
  }
}

export function* updateDivisionWorker({ payload }: PayloadAction<UpdateDivisionPayload>) {
  try {
    const response: Division = yield call(divisionApi.updateDivision, payload);
    yield put(divisionActions.updateDivisionSuccess());
  } catch (e) {
    console.log('updateDivisionsWorker Error: ', e);
    yield put(divisionActions.createDivisionFailed(e));
  }
}

export function* deleteDivisionWorker(payload: PayloadAction<DeleteDivisionPayload>) {
  try {
    const response: Division = yield call(divisionApi.deleteDivision, payload.payload);
    console.log('run');
    const divisions: any[] = yield select(divisionSelector.selectDivisionsModalAddUser);
    const currentProjectDivisions = cloneDeep(
      divisions.find((division) => division.projectId === payload.payload.projectId)
    );
    const currentDivisions = currentProjectDivisions.divisions.filter(
      (division: Division) => division._id !== payload.payload.divisionId
    );
    yield put(
      divisionActions.setDivisionsModalAddUser({
        projectId: payload.payload.projectId,
        divisions: currentDivisions
      })
    );
    yield put(divisionActions.deleteDivisionSuccess());
  } catch (e) {
    console.log('createDivisionsWorker Error: ', e);
    yield put(divisionActions.createDivisionFailed(e));
  }
}

export function* setSelectedDivisionsWorker({ payload }: PayloadAction<string[]>) {
  yield put(divisionActions.setSelectedDivisionsSuccess(payload));
}

export function* setSelectedAllDivisionsWorker({ payload }: PayloadAction<boolean>) {
  yield put(divisionActions.setSelectedAllDivisionsSuccess(payload));
}

export default function* divisionSaga() {
  yield takeLatest(divisionActions.getDivisions, getDivisionsWorker);
  yield takeLatest(divisionActions.setDivisionsModalAddUser, setDivisionsModalAddUserWorker);
  yield takeLatest(divisionActions.setSelectedDivisions, setSelectedDivisionsWorker);
  yield takeLatest(divisionActions.setSelectedAllDivisions, setSelectedAllDivisionsWorker);
  yield takeLatest(divisionActions.getMultiProjectDivisions, getMultiProjectDivisionsWorker);
  yield takeLatest(divisionActions.createDivision, createDivisionWorker);
  yield takeLatest(divisionActions.updateDivision, updateDivisionWorker);
  yield takeLatest(divisionActions.deleteDivision, deleteDivisionWorker);
}
