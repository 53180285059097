import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';

interface Props {
  overlay: any;
  triggerComponent: any;
}

export const FcOverlayTriggerClick = (props: Props) => {
  const { overlay, triggerComponent } = { ...props };
  return (
    <OverlayTrigger
      rootClose={true}
      trigger="click"
      overlay={
        <Popover id="popover-basic">
          <Popover.Content className="p-0">{overlay}</Popover.Content>
        </Popover>
      }
    >
      {triggerComponent}
    </OverlayTrigger>
  );
};
