import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const RequestLogListItemWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #e9ecef;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    border-top: 1px solid #e9ecef;
  }

  &.highlighted {
    background: #eef1ff;
    border-color: #fff;
  }
`;
