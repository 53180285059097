import { FcMentionInput } from 'components/Shared';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export interface CellProps {
  enableEdit?: boolean;
  isNoteInput?: boolean;
  breakWord?: boolean;
}

export interface RowProps {
  disabled?: boolean;
}

export interface TableLabelProps {
  align?: string;
}

export const ScheduleAvailabilityTableWrapper = styled.div`
  width: 100%;
  margin: auto;
`;

export const ScheduleAvailabilityTable = styled(Table)`
  height: 1px;
`;

export const ScheduleAvailabilityBody = styled.tbody``;

export const ScheduleAvailabilityRow = styled.tr<RowProps>`
  opacity: ${(props) => (props.disabled ? '0.7' : '1.0')};
`;

export const ScheduleAvailabilityCell = styled.td<CellProps>`
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
  text-align: center;
  padding: ${(props) => (props.isNoteInput ? '0' : '0.5rem')} !important;
  position: relative;
  white-space: ${(props) => (props.breakWord ? 'break-spaces' : 'nowrap')} !important;
  ${(props) => props.enableEdit && '&:hover { span { opacity: 0; }; .middle { opacity: 1.0; } }'};
`;

export const ScheduleAvailabilityStar = styled.span`
  color: #5e72e4;
`;

export const ScheduleAvailabilityHeader = styled.th`
  text-align: center;
  padding: 0.5rem !important;
  max-width: 60px;
  white-space: break-spaces !important;
`;

export const TableLabel = styled.div<TableLabelProps>`
  margin-bottom: 0.25rem;
  margin-top: 0;
  text-align: ${(props) => props.align};
`;

export const ScheduleAvailabilityAction = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
`;

export const ScheduleAvailabilityLink = styled.a`
  cursor: pointer;
  padding: 0 10px;
`;

export const ScheduleAvailabilitySendNote = styled.i`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;
