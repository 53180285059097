import { useAuth, useProjectFilter } from 'hooks';
import HighlighterPicker from 'hooks/scheduler/HighlighterPicker';
import PersonalFilter from 'hooks/scheduler/PersonalFilter';
import ProjectFilter from 'hooks/scheduler/ProjectFilter';
import UserFilter from 'hooks/scheduler/UserFilter';
import { usePersonalFilter } from 'hooks/scheduler/usePersonalFilter';
import { useUserFilter } from 'hooks/scheduler/useUserFilter';
import { FixedShift } from 'models';
import { useAppDispatch } from 'store/hooks';
import ButtonApplyFilter from './ButtonApplyFilter/ButtonApplyFilter';
import ButtonSaveFilter from 'hooks/scheduler/ButtonSaveFilter';
import ExportSchedulerFix from './ExportSchedulerFix/ExportSchedulerFix';
import { FiltersWrapper } from './SchedulerFilter.styled';
import DivisionFilterV2 from 'hooks/scheduler/DivisionFilterV2';
import { useEffect, useState } from 'react';
interface Props {
  shifts: FixedShift[];
  isFetchingShifts: boolean;
  fixedSlotsIndex: number;
  setIsAddingMoreIndex: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  setIsVisibleDeleteConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

const SchedulerFilter = (props: Props) => {
  const currentUser = useAuth();

  const { projects, selectedProject, onSelectProject } = useProjectFilter();
  const { selectedUsers } = useUserFilter();
  const { filters } = usePersonalFilter();
  const [fixedSlotIndex, setFixedSlotIndex] = useState(props.fixedSlotsIndex);

  useEffect(() => {
    setFixedSlotIndex(props.fixedSlotsIndex);
  }, [props.fixedSlotsIndex]);

  useEffect(() => {
    setFixedSlotIndex(1);
  }, [selectedProject]);

  return (
    <div>
      <FiltersWrapper>
        <PersonalFilter />
        <ProjectFilter />
        <UserFilter />

        {selectedUsers && filters && (
          <>
            <ButtonApplyFilter
              selectedProject={selectedProject}
              fixedSlotsIndex={fixedSlotIndex}
              setIsAddingMoreIndex={props.setIsAddingMoreIndex}
              setSelectedTab={props.setSelectedTab}
              setIsVisibleDeleteConfirm={props.setIsVisibleDeleteConfirm}
            />
            <ButtonSaveFilter />
          </>
        )}
      </FiltersWrapper>
      {projects.length > 0 && props.shifts.length > 0 && (
        <div className="d-flex justify-content-between mb-1">
          <div>
            <DivisionFilterV2 />
            <HighlighterPicker />
            {currentUser?.role === 'MANAGER' && (
              <ExportSchedulerFix
                selectedProject={selectedProject}
                fixedSlotsIndex={fixedSlotIndex}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulerFilter;
