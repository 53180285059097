import { FixedScheduledPreviewSlot } from '../store/fixedSchedulePreview/fixedScheduledPreview.initialState';
import apiService from '../utils/network';

export const fixedScheduledPreviewApi = {
  getFixedScheduledPreview(projectId: string) {
    return apiService.get('/fixed-scheduled-preview', { params: { projectId } });
  },
  savePreviewData(projectId: string, slots: FixedScheduledPreviewSlot[]) {
    return apiService.post('/fixed-scheduled-preview', { projectId, slots });
  },
  getShuffledPreviewData(projectId: string) {
    return apiService.get(`/projects/${projectId}/shuffled-availabilities`);
  }
};
