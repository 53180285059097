import { SpinnerButton } from 'components/Shared';
import { FormikProps, FormikProvider } from 'formik';
import { useAuth } from 'hooks';
import {
  ButtonTypes,
  FixedShift,
  OffVacation,
  Project,
  ShiftChange,
  ShiftChangeFormProps,
  User
} from 'models';
import { ScheduleGlance } from 'models/scheduleGlance.model';
import { useEffect, useState } from 'react';
import { Badge, Button, Col, Form } from 'react-bootstrap';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';

interface Props {
  offVacations: OffVacation;
}

const types = [
  { key: 'ONEDAY', value: 'One Day' },
  { key: 'SOMEDAY', value: 'Some Days' },
  { key: 'CONSECUTIVEDAY', value: 'Some Consecutive Days' },
  { key: 'SOMEHOURS', value: 'Some Hours' }
];

const OffVacationRequestDetail = (props: Props) => {
  const { offVacations } = { ...props };
  const currentUser = useAuth();
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedDatesStatus, setSelectedDatesStatus] = useState<string[]>([]);
  const [data, setData] = useState<OffVacation>();

  const onLoadConsecutiveDay = (dates: string[], datesStatus: string[]) => {
    setSelectedDates(dates);
    setSelectedDatesStatus(datesStatus);
  };

  const getDate = (value: string | undefined) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };

  useEffect(() => {
    const getInitValues = (data: OffVacation) => {
      return {
        _id: '',
        type: data?.type || 'ONEDAY',
        requester: data?.requester || '',
        project: data?.project || '',
        date: data?.date || '',
        from: data?.from || '',
        to: data?.to || '',
        reason: data?.reason || '',
        dates: data?.dates || []
      };
    };
    const initialValues: OffVacation = getInitValues(offVacations);

    const newData = Object.assign({}, initialValues, offVacations) as any;

    const { _id, type, requester, project, date, from, to, reason, dates, datesStatus }: any = {
      ...newData
    };
    // debugger; // eslint-disable-line no-debugger

    const data = {
      _id: _id,
      type: type || 'ONEDAY',
      requester: requester?.displayName || requester[0]?.displayName || '',
      project: project?.name || project[0]?.name || '',
      date: getDate(date) || '',
      from: from || '',
      to: to || '',
      reason: reason || '',
      dates: dates || [],
      datesStatus: datesStatus || []
    };
    if (dates) {
      onLoadConsecutiveDay(dates, datesStatus);
    }
    setData(data);
  }, []);

  return (
    <Form>
      <Form.Row>
        <Form.Group as={Col} xs={6} className="mb-3" controlId="type">
          <Form.Label className="required">Request Type</Form.Label>
          <Form.Control type="text" value={data?.type} disabled />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6} className="mb-3" controlId="requester">
          <Form.Label className="required">Requester</Form.Label>
          <Form.Control type="text" value={data?.requester} disabled />
        </Form.Group>
        <Form.Group as={Col} xs={6} className="mb-3" controlId="project">
          <Form.Label className="required">Project</Form.Label>
          <Form.Control type="text" value={data?.project} disabled />
        </Form.Group>
      </Form.Row>
      {(data?.type === 'ONEDAY' || data?.type === 'SOMEHOURS') && (
        <Form.Row>
          <Form.Group as={Col} className="mb-3" controlId="date" xs={6}>
            <Form.Label className="text-start required">Date</Form.Label>
            <Form.Control type="text" value={data?.date} disabled />
          </Form.Group>
        </Form.Row>
      )}
      {data?.type === 'CONSECUTIVEDAY' && (
        <>
          <Form.Row>
            <Form.Group as={Col} controlId="date" xs={12} className="mb-0">
              <Form.Label className="text-start required mr-2">Date</Form.Label>
              {/* <Form.Control type="text" value={data?.date} disabled /> */}

              <div className="mb-3">
                {selectedDates.map((date, index) => (
                  <>
                    <Badge pill key={date} className="mr-1" variant="primary">
                      {date}
                    </Badge>
                  </>
                ))}
              </div>
              <div className="mb-3">
                {selectedDatesStatus.map((dateStatus, index) => (
                  <>
                    <Badge
                      pill
                      key={dateStatus}
                      className="mr-1"
                      variant={dateStatus === 'REJECTED' ? 'danger' : 'primary'}
                    >
                      {dateStatus}
                    </Badge>
                  </>
                ))}
              </div>
            </Form.Group>
          </Form.Row>
        </>
      )}
      {data?.type === 'SOMEDAY' && (
        <>
          <Form.Row>
            <Form.Group as={Col} controlId="date" xs={12} className="mb-0">
              <Form.Label className="text-start required mr-2">Date</Form.Label>
            </Form.Group>
          </Form.Row>
          <div className="mb-3">
            {selectedDates.map((date, index) => (
              <>
                <Badge pill key={date} className="mr-1" variant="primary">
                  {date}
                </Badge>
              </>
            ))}
          </div>
          <div className="mb-3">
            {selectedDatesStatus.map((dateStatus, index) => (
              <>
                <Badge
                  pill
                  key={dateStatus}
                  className="mr-1"
                  variant={dateStatus === 'REJECTED' ? 'danger' : 'primary'}
                >
                  {dateStatus}
                </Badge>
              </>
            ))}
          </div>
        </>
      )}
      {data?.type === 'SOMEHOURS' && (
        <Form.Row>
          <Form.Group as={Col} className="mb-3" controlId="from">
            <Form.Label>From</Form.Label>
            <Form.Control type="text" value={data?.from} disabled />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="to">
            <Form.Label>To</Form.Label>
            <Form.Control type="text" value={data?.to} disabled />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="reason">
          <Form.Label className="required">Reason</Form.Label>
          <Form.Control type="text" value={data?.reason} disabled />
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default OffVacationRequestDetail;
