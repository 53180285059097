import { Nullable } from '../../utils/common/types';
import { FixedShift, GetShiftsPayload } from 'models';

export type FixedShiftInitialState = {
  shifts: FixedShift[];
  error: Nullable<any>;
  isFetching: boolean;
  isUpdating: boolean;
  highestIndexFixedSlot: number[];
};

export const initialState: FixedShiftInitialState = {
  shifts: [],
  error: null,
  isFetching: false,
  isUpdating: false,
  highestIndexFixedSlot: []
};
