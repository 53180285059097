import { PayloadAction } from '@reduxjs/toolkit';
import { FixedShift } from 'models';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fixedShiftApi } from 'services';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';
import { fixedShiftActions, fixedShiftSelector } from './fixedShift.slice';
import { toast } from 'react-toastify';

export function* getShiftsWorker({ payload }: PayloadAction<string>) {
  try {
    const response: FixedShift[] = yield call(fixedShiftApi.getShifts, payload);
    yield put(fixedShiftActions.getShiftsSuccess(sortShiftsByName(response) as FixedShift[]));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(fixedShiftActions.getShiftsFailed(e));
  }
}

export function* getShiftsSlotIndexWorker({ payload }: PayloadAction<any>) {
  try {
    const response: FixedShift[] = yield call(fixedShiftApi.getShiftsSlotIndex, payload);
    yield put(
      fixedShiftActions.getShiftsSlotIndexSuccess(sortShiftsByName(response) as FixedShift[])
    );
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(fixedShiftActions.getShiftsSlotIndexFailed(e));
  }
}

export function* getHighestIndexFixedSlotWorker({ payload }: PayloadAction<any>) {
  try {
    const highestIndexFixedSlotResponse: FixedShift[] = yield call(
      fixedShiftApi.getHighestIndexFixedSlot,
      payload
    );

    yield put(fixedShiftActions.getHighestIndexFixedSlotSuccess(highestIndexFixedSlotResponse));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(fixedShiftActions.getHighestIndexFixedSlotFailed(e));
  }
}

export function* updateShiftWorker({ payload }: PayloadAction<any>) {
  try {
    yield call(fixedShiftApi.updateShift, {
      ...payload,
      note: payload.note.length > 0 ? payload.note : undefined
    });
    const shifts: FixedShift[] = yield select(fixedShiftSelector.selectShifts);
    let _shifts = [...shifts];
    _shifts = _shifts.map((s) => {
      return s._id === payload.shiftId ? { ...s, ...payload } : s;
    });
    yield put(fixedShiftActions.updateShiftSuccess(_shifts));
  } catch (e) {
    console.log('updateShiftWorker Error: ', e);
    yield put(fixedShiftActions.updateShiftFailed(e));
  }
}

export function* setShiftsWorker({ payload }: PayloadAction<FixedShift[]>) {
  try {
    yield put(fixedShiftActions.setShiftsSuccess(payload));
  } catch (e) {
    console.log('Setting Data Error: ', e);
  }
}

export function* deleteFixedSlotsByIndexWorker({ payload }: PayloadAction<any>) {
  try {
    const response: FixedShift = yield call(fixedShiftApi.deleteFixedSlotsByIndex, {
      projectId: payload.projectId,
      index: payload.index
    });

    toast.success('Successfully Deleted');
  } catch (e) {
    console.log('deleteSlotWorker Error: ', e);
    yield put(fixedShiftActions.deleteFixedSlotsByIndexFailed(e));
  }
}

export default function* shiftSaga() {
  yield takeLatest(fixedShiftActions.getShifts, getShiftsWorker);
  yield takeLatest(fixedShiftActions.setShifts, setShiftsWorker);
  yield takeLatest(fixedShiftActions.updateShift, updateShiftWorker);
  yield takeLatest(fixedShiftActions.getShiftsSlotIndex, getShiftsSlotIndexWorker);
  yield takeLatest(fixedShiftActions.getHighestIndexFixedSlot, getHighestIndexFixedSlotWorker);
  yield takeLatest(fixedShiftActions.deleteFixedSlotsByIndex, deleteFixedSlotsByIndexWorker);
}
