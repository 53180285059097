import { Modal } from 'react-bootstrap';

interface Props {
  title?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  show: boolean;
  onHide: () => void;
}

export const FcModalConfirm = (props: Props) => {
  const { title, body, footer, show, onHide } = { ...props };
  return (
    <>
      <Modal
        style={{
          zIndex: 100000
        }}
        show={show}
        onHide={onHide}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title !== undefined && title}</Modal.Title>
        </Modal.Header>

        {body !== undefined && <Modal.Body>{body}</Modal.Body>}

        {footer !== undefined && <Modal.Footer>{footer}</Modal.Footer>}
      </Modal>
    </>
  );
};
