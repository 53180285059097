import { AppState } from '../index.store';

export const selectError = (state: AppState) => state.notes.error;

export const selectIsCreating = (state: AppState) => state.notes.isCreating;

export const selectIsUpdating = (state: AppState) => state.notes.isUpdating;

export const selectIsFetching = (state: AppState) => state.notes.isFetching;

export const selectNotes = (state: AppState) => state.notes.notes;

export const selectUpdatingNoteId = (state: AppState) => state.notes.updatingNoteId;
