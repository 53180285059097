import { call, put, takeLatest } from 'redux-saga/effects';
import { projectApi } from '../../services';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  Availability,
  ScheduleAvailabilityBreakdownShift,
  ScheduleAvailabilityPreview
} from './scheduleAvailability.initialState';
import { scheduleAvailabilityActions } from './scheduleAvailability.slice';
import { ErrorResponse } from '../../utils/network';
import { ApplyChangesPayload, availabilityApi } from '../../services/availiability.api';
import { ScheduleAvailabilityModel } from '../../models/scheduleAvailability.model';

function* getListOfAvailabilitySaga({
  payload
}: PayloadAction<{
  projectId: string;
}>) {
  try {
    const { projectId } = payload;
    const response: Availability[] = yield call(projectApi.getListOfAvailability, projectId);
    yield put(scheduleAvailabilityActions.getListOfAvailabilitySuccess(response));
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.getListOfAvailabilityFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* createAvailabilitySaga({
  payload
}: PayloadAction<{
  userId: string;
  shiftId: string;
  weekday: number;
}>) {
  try {
    const { userId, shiftId, weekday } = payload;
    const response: ScheduleAvailabilityModel = yield call(
      availabilityApi.createAvailability,
      userId,
      shiftId,
      weekday
    );
    yield put(scheduleAvailabilityActions.createAvailabilitySuccess({ availability: response }));
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.createAvailabilityFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* deleteAvailabilitySaga({
  payload
}: PayloadAction<{
  availabilityId: string;
}>) {
  try {
    const { availabilityId } = payload;
    yield call(availabilityApi.deleteAvailability, availabilityId);
    yield put(scheduleAvailabilityActions.deleteAvailabilitySuccess({ availabilityId }));
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.deleteAvailabilityFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* applyChangesSaga({ payload }: PayloadAction<ScheduleAvailabilityPreview[]>) {
  try {
    const data: ApplyChangesPayload[] = payload.map((item) => {
      return {
        shiftId: item.shiftId,
        weekday: item.weekday,
        users: item.users.map((user) => user._id),
        index: item.index
      };
    });
    yield call(availabilityApi.applyChangesToFixedSchedule, data);
    yield put(
      scheduleAvailabilityActions.requestApplyChangesSuccess({
        message: 'Applied all changes to fixed schedule successfully'
      })
    );
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.requestApplyChangesFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* getAvailabilityBreakdownSaga({
  payload
}: PayloadAction<{
  projectId: string;
}>) {
  try {
    const { projectId } = payload;
    const result: ScheduleAvailabilityBreakdownShift[] = yield call(
      projectApi.getAvailabilityBreakdown,
      projectId
    );

    yield put(scheduleAvailabilityActions.requestAvailabilityBreakdownSuccess({ data: result }));
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.requestAvailabilityBreakdownFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* agentClearAvailability({
  payload
}: PayloadAction<{
  projectId: string;
}>) {
  try {
    const { projectId } = payload;
    yield call(projectApi.agentClearAvailability, projectId);
    yield put(scheduleAvailabilityActions.agentClearAvailabilitySuccess());
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.agentClearAvailabilityFailed({
        error: (e as ErrorResponse).message
      })
    );
  }
}

function* triggerNoti({ payload }: PayloadAction<string>) {
  try {
    yield call(availabilityApi.triggerNoti, payload);
    yield put(scheduleAvailabilityActions.triggerNotiSuccess());
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.triggerNotiFailed({ error: (e as ErrorResponse).message })
    );
  }
}

function* setShowAllNoteSaga({ payload }: PayloadAction<{ showAllNote: boolean }>) {
  try {
    const { showAllNote } = { ...payload };
    yield put(scheduleAvailabilityActions.setShowAllNoteSuccess({ showAllNote: showAllNote }));
  } catch (e) {
    yield put(
      scheduleAvailabilityActions.triggerNotiFailed({ error: (e as ErrorResponse).message })
    );
  }
}

export default function* scheduleAvailabilitySaga() {
  yield takeLatest(scheduleAvailabilityActions.getListOfAvailability, getListOfAvailabilitySaga);
  yield takeLatest(scheduleAvailabilityActions.createAvailability, createAvailabilitySaga);
  yield takeLatest(scheduleAvailabilityActions.deleteAvailability, deleteAvailabilitySaga);
  yield takeLatest(scheduleAvailabilityActions.requestApplyChanges, applyChangesSaga);
  yield takeLatest(
    scheduleAvailabilityActions.requestAvailabilityBreakdown,
    getAvailabilityBreakdownSaga
  );
  yield takeLatest(scheduleAvailabilityActions.agentClearAvaiability, agentClearAvailability);
  yield takeLatest(scheduleAvailabilityActions.triggerNoti, triggerNoti);
  yield takeLatest(scheduleAvailabilityActions.setShowAllNote, setShowAllNoteSaga);
}
