import { Project } from 'models';
import { Table, Button, Badge } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { ProjectName } from './ProjectList.styled';
import RemoveProject from '../RemoveProject/RemoveProject';
import { Link } from 'react-router-dom';

const linkItems = [
  {
    id: 1,
    url: '/managers/schedule_fix?project=',
    name: 'Schedule - Fix'
  },
  {
    id: 2,
    url: '/managers/schedule_actual?project=',
    name: 'Schedule - Actual'
  },
  {
    id: 3,
    url: '/managers/schedule_availability?project=',
    name: 'Schedule - Availability'
  },
  {
    id: 4,
    url: '#',
    name: 'Admin List'
  },
  {
    id: 5,
    url: '#',
    name: 'Tool'
  }
];

const ProjectList = () => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectSelector.selectProjects);

  const onEdit = (prj: Project) => {
    dispatch(projectActions.setIsModalUpdateProjectVisible(true));
    dispatch(projectActions.setSelectedProject(prj));
    return;
  };

  const onClick = () => {
    localStorage.removeItem('personalId');
    localStorage.removeItem('projectId');
    localStorage.removeItem('userIds');
  };

  return (
    <Table responsive className="mt-3">
      <thead className="thead-light">
        <tr>
          <th>Project</th>
          <th></th>
          <th>#</th>
        </tr>
        <tr></tr>
      </thead>
      <tbody>
        {projects.map((prj) => (
          <tr key={prj._id}>
            <td>
              <ProjectName variant="primary" pill onClick={() => onEdit(prj)}>
                {prj.name}
              </ProjectName>
            </td>
            <td>
              {linkItems.map((item) => (
                <Link
                  key={item.id}
                  to={`${item.url}${prj.name}&projectId=${prj._id}`}
                  className="mr-2 text-primary text-bold"
                  onClick={onClick}
                >
                  <Badge variant="success" pill>
                    {item.name}
                  </Badge>
                </Link>
              ))}
            </td>
            <td>
              <RemoveProject project={prj} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProjectList;
