import { useAuth } from 'hooks';
import UserByAgent from './UserByAgent';
import UserByManager from './UserByManager';
import { UserRole } from 'models';

const User = () => {
  const currentUser = useAuth();
  return <>{currentUser.role === UserRole.MANAGER ? <UserByManager /> : <UserByAgent />}</>;
};

export default User;
