import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './shift.initialState';
import { GetShiftsPayload, Shift, ShiftChange } from 'models';

const shiftSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    getShifts: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = true;
      state.error = null;
    },
    getShiftsSuccess: (state, { payload }: PayloadAction<Shift[]>) => {
      state.shifts = payload;
      state.isFetching = false;
      state.error = null;
    },
    getShiftsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    setGetShiftsPayload: (state, { payload }: PayloadAction<GetShiftsPayload>) => {
      state.getShiftsPayload = payload;
    },
    setShifts: (state, { payload }: PayloadAction<Shift[]>) => {
      // state.shifts = payload;
    },
    setShiftsSuccess: (state, { payload }: PayloadAction<Shift[]>) => {
      state.shifts = payload;
    },
    shiftChange: (state, { payload }: PayloadAction<ShiftChange>) => {
      state.isFetching = true;
    },
    shiftChangeSuccess: (state, { payload }: PayloadAction<ShiftChange>) => {
      state.isFetching = false;
      state.error = null;
    },
    shiftChangeFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    setSelectedShift: (state, { payload }: PayloadAction<Shift | null>) => undefined,
    setSelectedShiftSuccess: (state, { payload }: PayloadAction<Shift | null>) => {
      state.selectedShift = payload;
    },
    getOffVacationSlotShifts: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = true;
      state.error = null;
    },
    getOffVacationSlotShiftsSuccess: (state, { payload }: PayloadAction<Shift[]>) => {
      state.offVacationSlotShifts = payload;
      state.isFetching = false;
      state.error = null;
    },
    getOffVacationSlotShiftsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    }
  }
});

export const shiftActions = shiftSlice.actions;

export * as shiftSelector from './shift.selector';

export default shiftSlice.reducer;
