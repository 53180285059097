import { Spinner } from 'react-bootstrap';

interface Props {
  hidden: boolean;
}

const LoadingIcon = (props: Props) => {
  const { hidden } = props;

  if (hidden) {
    return <></>;
  }

  return (
    <div className="text-center">
      <Spinner animation="border" variant="primary" className="text-center" />
    </div>
  );
};

export default LoadingIcon;
