import styled from 'styled-components';

export const InlineEditContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const InlineEditOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 200%;
  z-index: 301;
  top: -50%;
  left: 0;
`;

export const EditViewContainer = styled.div`
  position: relative;
  z-index: 300;
  max-width: 120px;
`;

export const ReadViewContainer = styled.div`
  line-height: 1.5;
  margin-top: -5px;
  cursor: pointer;
`;

export const ReadViewItem = styled.div`
  font-size: 100%;
`;
