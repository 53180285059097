import { useAppSelector } from 'store/hooks';
import { BtnAdd } from './ButtonAddEmployee.styled';
import { projectSelector } from 'store/projects/project.slice';
interface Props {
  isAddingSubEmpl: boolean;
  arrayHelpers?: any;
  agentCountHelpers?: any;
  shiftDuration: number;
}

const ButtonAddEmployee = (props: Props) => {
  const { isAddingSubEmpl, arrayHelpers, agentCountHelpers, shiftDuration } = { ...props };

  const project = useAppSelector(projectSelector.selectSelectedProject);

  return (
    <>
      {isAddingSubEmpl === true ? (
        <div className="mb-3">
          <BtnAdd
            className="pl-4"
            onClick={() => {
              agentCountHelpers.push({
                userId: '',
                duration: `${shiftDuration}`,
                divisionId: '',
                note: ''
              });
            }}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            + Add Sub-Employee
          </BtnAdd>
        </div>
      ) : (
        <BtnAdd
          onClick={() => {
            arrayHelpers.push([
              {
                userId: '',
                duration: `${shiftDuration}`,
                divisionId: '',
                note: ''
              }
            ]);
          }}
          onMouseDown={(e: any) => {
            e.stopPropagation();
          }}
        >
          + Add Employee
        </BtnAdd>
      )}
    </>
  );
};

export default ButtonAddEmployee;
