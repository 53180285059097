import { Dropdown, Popover, Table } from 'react-bootstrap';
import styled from 'styled-components';

export const AlphabetSortWrapper = styled.div`
  margin: 0 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.35rem;
  padding: 0.5rem 1.25rem;
  background: #f6f9fc;
  border: 1px solid #e9ecef;
`;

export const FilterIcon = styled.i``;
export const FilterDropdown = styled(Dropdown)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
`;

export const FilterItemLabel = styled.span`
  margin-left: 0.5rem;
  text-transform: none;
  font-size: 0.875rem;
  white-space: nowrap;
`;

export const CustomControlLabel = styled.label`
  &:before {
    top: 0.6rem !important;
  }
`;

export const ActivateIcon = styled.i`
  color: var(--primary);
`;

export const CustomTable = styled(Table)`
  td {
    white-space: break-spaces;
  }
  td,
  th {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
`;

export const CustomPopover = styled(Popover)`
  // min-width: 200px;
  padding: 0.5rem;
`;
