import cloneDeep from 'lodash.clonedeep';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { scheduleAvailabilityActions } from 'store/scheduleAvailability/scheduleAvailability.slice';
import { selectShowAllNote } from 'store/scheduleAvailability/scheduledAvailability.selector';

const ScheduleAvailabilityToggleNote = () => {
  const dispatch = useAppDispatch();
  const showAllNote: boolean = useAppSelector(selectShowAllNote);

  const toggle = () => {
    dispatch(scheduleAvailabilityActions.setShowAllNote({ showAllNote: !showAllNote }));
  };

  return (
    <Button variant="primary" size="sm" onClick={toggle}>
      {showAllNote ? 'Hide Notes' : 'Show Notes'}
    </Button>
  );
};

export default ScheduleAvailabilityToggleNote;
