import { Nullable } from '../../utils/common/types';
import { User, PaginationInfo, GetUsersPayload, UserSameProject } from 'models';

export type UserInitialState = {
  users: User[];
  usersModalAssign: User[];
  getUsersModalAssignParams: GetUsersPayload;
  error: any;
  isFetching: boolean;
  isFetched: boolean;
  paginationInfo: PaginationInfo;
  managersPaginationInfo: PaginationInfo;
  isModalCreateVisible: boolean;
  isModalUpdateVisible: boolean;
  isModalResetPasswordVisible: boolean;
  isModalDeleteVisible: boolean;
  selectedUser: User;
  selectedUsers: string[];
  selectedAll: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isUpdatingPassword: boolean;
  isUpdatingStatus: boolean;
  isRemovingProject: boolean;
  isBulkUpdating: boolean;
  isDeleting: boolean;
  isRestoring: boolean;
  isExporting: boolean;
  isFetchingUsersSameProject: boolean;
  usersSameProject: UserSameProject[];
};

export const initialState: UserInitialState = {
  users: [],
  usersModalAssign: [],
  getUsersModalAssignParams: {},
  error: null,
  isFetching: false,
  isFetched: false,
  paginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  },
  managersPaginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  },
  isModalCreateVisible: false,
  isModalUpdateVisible: false,
  isModalResetPasswordVisible: false,
  isModalDeleteVisible: false,
  selectedUser: Object.assign({}),
  selectedUsers: [],
  selectedAll: false,
  isCreating: false,
  isUpdating: false,
  isUpdatingPassword: false,
  isUpdatingStatus: false,
  isRemovingProject: false,
  isBulkUpdating: false,
  isDeleting: false,
  isRestoring: false,
  isExporting: false,
  isFetchingUsersSameProject: false,
  usersSameProject: []
};
