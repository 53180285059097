import { GetReportsPayload } from 'models/report.model';
import apiService from '../utils/network';

export const reportApi = {
  getReports(payload: GetReportsPayload) {
    return apiService.get('/reports', { params: payload });
  },
  getDashboardData(payload: GetReportsPayload) {
    return apiService.get('/reports/dashboard', { params: payload });
  },
  exportTimesheet(payload: GetReportsPayload) {
    return apiService.get(`/reports/csv`, { params: payload });
  }
};
