import { faker } from '@faker-js/faker';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { compareDesc } from 'date-fns';
import { Shift } from 'models';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useAppSelector } from 'store/hooks';
import { reportSelector } from 'store/reports/report.slice';
import { ChartWrapper } from './ReportDashboardChart.styled';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  indexAxis: 'y' as const,
  scales: {
    x: {
      grid: {
        display: false
      }
    }
  },
  elements: {
    bar: {
      borderWidth: 4,
      borderColor: '#fff'
    }
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'Agent Working Hour',
      position: 'bottom' as const
    }
  }
};

export const ReportDashboardChart = () => {
  const data = useAppSelector(reportSelector.selectDashboardData);

  const randomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const generateColor = () => {
    const h = randomInt(90, 320);
    const s = randomInt(40, 70);
    const l = randomInt(60, 80);
    return `hsl(${h},${s}%,${l}%)`;
  };

  const getChartHeight = () => {
    const height = data !== undefined ? data.users.length * 130 : 200;
    return height + 'px';
  };

  const getData = () => {
    const datasets: any = [];
    if (data !== undefined) {
      data.shifts.forEach((s: Shift, index: number) => {
        datasets.push({
          label: s.name,
          data: data.durations[index],
          barThickness: 35,
          backgroundColor: generateColor()
        });
      });
      return { labels: data.users, datasets: datasets };
    } else {
      return { labels: [], datasets: [] };
    }
  };

  return (
    <ChartWrapper className="px-4 py-3" style={{ height: getChartHeight() }}>
      <Bar options={options} data={getData() as any} />
    </ChartWrapper>
  );
};

export default ReportDashboardChart;
