import { Filter } from 'models';
import { Nullable } from 'utils/common/types';
export type SchedulerInitialState = {
  isModalCreateSlotVisible: boolean;
  selectedShiftId: string;
  selectedDate: string;
  isApplyingFilter: boolean;
  personalFilters: Filter[];
  error: Nullable<any>;
  isLoadingFilters: boolean;
  viewMode: string;
  selectedDateRange: any;
  showingCompactOverview: boolean;
};

export const initialState: SchedulerInitialState = {
  isModalCreateSlotVisible: false,
  selectedShiftId: '',
  selectedDate: '',
  isApplyingFilter: false,
  personalFilters: [],
  error: null,
  isLoadingFilters: false,
  viewMode: 'Week',
  selectedDateRange: null,
  showingCompactOverview: false
};
