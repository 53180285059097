import { Card } from 'react-bootstrap';
import { Page500Wrapper } from './Page500.styled';

export const Page500 = () => {
  return (
    <Page500Wrapper>
      <div>
        <h1>Error 500!</h1>
        <h3>Oops! Something went wrong</h3>
      </div>
    </Page500Wrapper>
  );
};
