import { Popover } from 'react-bootstrap';
import styled from 'styled-components';

export const AgentCommentWrapper = styled.div`
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  font-size: 0.6rem;
  border-radius: 50%;
  background: var(--danger);
  color: #fff;
  bottom: 0.15rem;
  right: 0.15rem;
`;

export const AgentCommentPopover = styled(Popover)`
  padding: 0;
  border-radius: 0.375rem;
  width: 350px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
