import { FixedShift, Project } from 'models';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { schedulerFixActions, schedulerFixSelector } from 'store/schedulerFixs/schedulerFix.slice';
import SchedulerHeader from './Actions/SchedulerHeader/SchedulerHeader';
import SchedulerRow from './Actions/SchedulerRow/SchedulerRow';
import SchedulerRowEmpty from './Actions/SchedulerRow/SchedulerRowEmpty';
import SchedulerFilter from './FilterActions/SchedulerFilter';
import MenuCreateSlot from './MainActions/MenuCreateSlot/MenuCreateSlot';
import MenuUpdateSlot from './MainActions/MenuUpdateSlot/MenuUpdateSlot';
import ModalCreateSlot from './MainActions/ModalCreateSlot/ModalCreateSlot';
import {
  SchedulerBody,
  SchedulerHead,
  SchedulerTable,
  SchedulerTableWrapper,
  SchedulerWrapper
} from './Scheduler.styled';
import { fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { ScheduleFixTab } from './ScheduleFixTab/ScheduleFixTab';

interface Props {
  projects: Project[];
  shifts: FixedShift[];
  isFetchingShifts: boolean;
  indexArrayFixedSlot: number[];
}

const Scheduler = (props: Props) => {
  const dispatch = useAppDispatch();
  const isModalCreateSlotVisible = useAppSelector(
    schedulerFixSelector.selectIsModalCreateSlotVisible
  );
  const isApplyingFilter = useAppSelector(schedulerFixSelector.selectIsApplyingFilter);
  const isFetchingShifts = useAppSelector(fixedShiftSelector.selectIsFetching);
  const date = useAppSelector(schedulerFixSelector.selectSelectedDate);
  const [draggingItem, setDraggingItem] = useState({});
  const [droppedItem, setDroppedItem] = useState({});
  const shifts: FixedShift[] = [...props.shifts];
  const {
    selectedTab,
    setSelectedTab,
    Tab,
    isAddingMoreIndex,
    setIsAddingMoreIndex,
    setScheduleFixTabChange,
    setIsVisibleDeleteConfirm
  } = ScheduleFixTab(props.indexArrayFixedSlot);

  const ModalCreateOnhide = () => {
    dispatch(schedulerFixActions.setIsModalCreateSlotVisible(false));
    if (isAddingMoreIndex) {
      setScheduleFixTabChange(true);
    }
  };

  return (
    <SchedulerWrapper>
      <Card.Header style={{ marginBottom: 0 }}>
        <SchedulerFilter
          shifts={props.shifts}
          isFetchingShifts={props.isFetchingShifts}
          fixedSlotsIndex={selectedTab}
          setIsAddingMoreIndex={setIsAddingMoreIndex}
          setSelectedTab={setSelectedTab}
          setIsVisibleDeleteConfirm={setIsVisibleDeleteConfirm}
        />
        {props.projects.length > 0 &&
          props.shifts.length > 0 &&
          !isApplyingFilter &&
          !isFetchingShifts && <Tab />}
      </Card.Header>
      {!isAddingMoreIndex &&
        props.projects.length > 0 &&
        props.shifts.length > 0 &&
        !isApplyingFilter &&
        !isFetchingShifts && (
          <SchedulerTableWrapper>
            <SchedulerTable>
              <SchedulerHead>
                <SchedulerHeader shifts={shifts} />
              </SchedulerHead>
              <SchedulerBody>
                {shifts.map((s) => (
                  <SchedulerRow
                    key={s._id}
                    shift={s as FixedShift}
                    setDraggingItem={setDraggingItem}
                    setDroppedItem={setDroppedItem}
                  />
                ))}
              </SchedulerBody>
            </SchedulerTable>
          </SchedulerTableWrapper>
        )}
      {isAddingMoreIndex && (
        <SchedulerTableWrapper>
          <SchedulerTable>
            <SchedulerHead>
              <SchedulerHeader shifts={[]} />
            </SchedulerHead>
            <SchedulerBody>
              {shifts.map((s) => (
                <SchedulerRowEmpty
                  key={s._id}
                  shift={s as FixedShift}
                  setDraggingItem={setDraggingItem}
                  setDroppedItem={setDroppedItem}
                />
              ))}
            </SchedulerBody>
          </SchedulerTable>
        </SchedulerTableWrapper>
      )}
      {isModalCreateSlotVisible && (
        <ModalCreateSlot
          show={isModalCreateSlotVisible}
          shifts={props.shifts}
          date={date}
          onHide={ModalCreateOnhide}
          fixedSlotsIndex={selectedTab}
          isAddingMoreIndex={isAddingMoreIndex}
        />
      )}
      <MenuUpdateSlot
        draggingItem={draggingItem}
        droppedItem={droppedItem}
        shifts={props.shifts}
        fixedSlotsIndex={selectedTab}
        isAddingMoreIndex={isAddingMoreIndex}
      />
      <MenuCreateSlot
        draggingItem={draggingItem}
        droppedItem={droppedItem}
        fixedSlotsIndex={selectedTab}
        isAddingMoreIndex={isAddingMoreIndex}
      />
    </SchedulerWrapper>
  );
};

export default Scheduler;
