import { SpinnerButton } from 'components/Shared/';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { ButtonTypes } from 'models/common.model';
import { Login } from 'models/index';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { authActions, authSelector } from 'store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loginSchema } from 'utils/validators';
import {
  ForgotLink,
  LoginFormContainer,
  LoginFormHeader,
  LoginFormWrapper
} from './LoginForm.styled';

const LoginForm = () => {
  const error: any = useAppSelector(authSelector.selectError);
  // const isFetching: boolean = useAppSelector(authSelector.selectAuthIsFetching);

  useEffect(() => {
    if (error !== null) {
      toast.error(
        error?.message ||
          'Something went wrong. Please check your login info again or contact to MANAGER'
      );
      formik.setSubmitting(false);
    }
  }, [error]);

  const dispatch = useAppDispatch();
  const handleSubmit = (values: Login, actions: FormikHelpers<Login>) => {
    dispatch(authActions.postLogin(values));
    // login("admin");
  };

  const formik: FormikProps<Login> = useFormik<Login>({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: (values, actions) => {
      handleSubmit(values, actions);
    }
  });

  return (
    <LoginFormWrapper>
      <LoginFormContainer>
        <LoginFormHeader>Welcome Back!</LoginFormHeader>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label className="text-start">Username / Employee ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Username / Employee ID"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.username}
              isInvalid={formik.touched.username && !!formik.errors.username}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              isInvalid={formik.touched.password && !!formik.errors.password}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
          </Form.Group>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={formik.isSubmitting}
            className="w-100 d-inline"
          >
            Sign In
          </SpinnerButton>
          <ForgotLink className="link-primary" href="#">
            Forgot Password?
          </ForgotLink>
        </Form>
      </LoginFormContainer>
    </LoginFormWrapper>
  );
};

export default LoginForm;
