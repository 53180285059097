import { useDateRangeCalendar } from 'components/Report/hooks/useDateRangeCalendar';
import { Button } from 'react-bootstrap';
import { attendanceActions } from 'store/attendances/attendance.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { shiftSelector } from 'store/shifts/shift.slice';

const AttendanceButtonApply = (props: any) => {
  const dispatch = useAppDispatch();

  const { selectedDateRange } = useDateRangeCalendar();
  const selectedProject = useAppSelector(projectSelector.selectSelectedProject);
  const selectedShift = useAppSelector(shiftSelector.selectSelectedShift);

  const onApplyFilter = () => {
    props.resetResultList();
    dispatch(
      attendanceActions.getAttendances({
        projectId: selectedProject !== null ? selectedProject._id : '',
        dateStart: selectedDateRange[0],
        dateEnd: selectedDateRange[1],
        shiftId: selectedShift !== null ? selectedShift._id : ''
      })
    );
  };

  return (
    <Button size="sm" variant="primary" onClick={onApplyFilter}>
      Apply
    </Button>
  );
};

export default AttendanceButtonApply;
