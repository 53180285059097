import { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { TotalCount, TableCellNote } from './AttendanceManager.styled';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { attendanceActions, attendanceSelector } from 'store/attendances/attendance.slice';
import PersonalFilter from 'hooks/scheduler/PersonalFilter';
import ProjectFilter from 'hooks/scheduler/ProjectFilter';
import DateRangeCalendar from 'components/Report/hooks/DateRangeCalendar';
import ButtonApplyFilter from 'components/Scheduler/FilterActions/ButtonApplyFilter/ButtonApplyFilter';
import ButtonSaveFilter from 'hooks/scheduler/ButtonSaveFilter';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import AttendanceShiftFilter from 'components/Attendance/FilterActions/AttendanceShiftFilter';
import AttendanceProjectFilter from 'components/Attendance/FilterActions/AttendanceProjectFilter';
import AttendanceButtonApply from 'components/Attendance/FilterActions/AttendanceButtonApply';
import { Division, Project, UserSlot } from 'models';
import { formatTo_yyyyMMdd_HHMM } from 'utils/formatters/datetime.formatter';
import format from 'date-fns/format';
import moment from 'moment';
import { useDateRangeCalendar } from 'components/Report/hooks/useDateRangeCalendar';

const AttendanceManager = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(attendanceSelector.selectAttendances);
  const projects = useAppSelector(projectSelector.selectProjects);
  const { selectedDateRange, ranges } = useDateRangeCalendar();
  // const result = [];
  // const result: any[] = [];
  const [result, setResult] = useState([] as any);
  // dateStart: selectedDateRange[0],
  // dateEnd: selectedDateRange[1],
  const getAttendanceData = (attendanceData: any, dateString: any) => {
    // console.log(attendanceData);
    console.log(dateString);
    if (attendanceData.length === 0) return null;

    const filtersList = attendanceData.filter(
      (f: any) =>
        // f.name.toLowerCase().includes(itemFilterText.toLowerCase())
        formatDate(f.date, false) === dateString
    );

    // console.log(filtersList);
    // debugger; // eslint-disable-line no-debugger
    return filtersList;
  };

  useEffect(() => {
    // ranges[0].map((r: any) => {
    //   const a = getAttendanceData(data, r);
    //   debugger; // eslint-disable-line no-debugger
    // });

    const start = moment(selectedDateRange[0], 'YYYY-MM-DD');
    const end = moment(selectedDateRange[1], 'YYYY-MM-DD');

    const current = start.clone();

    while (current.isSameOrBefore(end)) {
      // result.push(current.format('YYYY-MM-DD'));
      // setResult(getAttendanceData(data, current.format('YYYY-MM-DD')));
      // console.log('result2');
      // console.log(current);
      // setResult([...result, getAttendanceData(data, current.format('YYYY-MM-DD'))]);

      const filtersList = data.filter(
        (f: any) =>
          // f.name.toLowerCase().includes(itemFilterText.toLowerCase())
          formatDate(f.date, false) === current.format('YYYY-MM-DD')
      );
      // console.log('1');
      // console.log(filtersList);
      setResult((result: any) => [...result, filtersList]);

      // console.log('2');
      // setResult((result: any) => [
      //   ...result,
      //   getAttendanceData(data, current.format('YYYY-MM-DD'))
      // ]);

      current.add(1, 'd');
      // console.log('3');
      // console.log(result);
      // console.log(current.format('YYYY-MM-DD'));
      // debugger; // eslint-disable-line no-debugger
    }

    // console.log('result');
    // console.log(result);
  }, [data]);

  useEffect(() => {
    dispatch(projectActions.getProjects());
  }, []);

  const getTotalExpectedAgent = () => {
    return data.reduce((a: any, b: any) => {
      return a + b.slotCount || 0;
    }, 0);
  };

  const getTotalCheckedInAgent = () => {
    const totalAgentCheckedIn = data.reduce((a: any, b: any) => {
      return a + b.checkedInCount || 0;
    }, 0);
    if (totalAgentCheckedIn === undefined) return 0;
    return totalAgentCheckedIn;
  };

  const getDate = (value: string) => {
    return value ? formatTo_yyyyMMdd_HHMM(new Date(value)) : '';
  };

  const getUserData = (userSlot: UserSlot, users: any) => {
    // Either use find or filter
    if (users != null) {
      const data = users.filter(
        (userAttendanceData: any) => userSlot.user._id === userAttendanceData.userId
      );
      if (data.length > 0) return data[0];
    }

    return null;
  };

  const getUserDataDivision = (userSlot: UserSlot, users: any) => {
    const data = getUserData(userSlot, users);
    let divisionName = '';

    if (data && data.divisionId) {
      projects.map((project: Project) => {
        return project.divisions.map((division: Division) => {
          if (division._id === data.divisionId) {
            divisionName = division.name;
          }
          return;
          // }
        });
      });
    }
    return divisionName;
  };

  const getNote = (userSlot: UserSlot, users: any) => {
    if (users != null) {
      return users.find(
        (userAttendanceData: any) => userSlot.user._id === userAttendanceData.userId
      )?.note;
    }
    return '';
  };

  const exportAttendance = () => {
    let csvData =
      ['Date', 'Name', 'Division', 'Checked-in at', 'Check-out at', 'Note'].join(',') + '\r\n';

    data.map((value: any) => {
      value.userSlots.length > 0 &&
        value.userSlots.map((userSlot: UserSlot) => {
          const rowData =
            [
              value.date,
              userSlot?.user?.displayName || userSlot?.user?.username,
              getUserDataDivision(userSlot, value.users),
              getDate(getUserData(userSlot, value.users)?.checkedInAt),
              getDate(getUserData(userSlot, value.users)?.checkedOutAt),
              getNote(userSlot, value.users)
            ].join(',') + '\r\n';
          csvData += rowData;
        });
    });

    const url = window.URL.createObjectURL(new Blob([csvData]));
    const link = document.createElement('a');
    link.href = url;
    if (data && data.length === 1) {
      link.setAttribute('download', `Attendance-${data[0].date}.csv`);
    } else if (data && data.length > 1) {
      link.setAttribute('download', `Attendance-${data[0].date}-${data[data.length - 1].date}.csv`);
    } else {
      link.setAttribute('download', `Attendance-${format(new Date(), 'yyyy-MM-dd')}.csv`);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const formatDate = (_date: any, withtime: boolean) => {
    // debugger; // eslint-disable-line no-debugger
    if (withtime) return moment(_date).format('DD MMM YYYY, hh:mm A');
    else return moment(_date).format('YYYY-MM-DD');

    // else return moment(_date).add(1, 'day').format('YYYY-MM-DD');
  };

  const resetResultList = () => {
    setResult([]);
  };

  return (
    <Card className="shadow">
      <Card.Body>
        <Row>
          <Col className="mb-4">
            <div className="px-4 py-3 d-flex justify-content-between">
              <div>
                <PersonalFilter />
                <AttendanceProjectFilter />
                <AttendanceShiftFilter />
                {/* <ShiftFilter /> */}
                <DateRangeCalendar />
                <AttendanceButtonApply resetResultList={resetResultList} />
                <ButtonSaveFilter />
              </div>
              <div>
                <Button size="sm" variant="success" onClick={() => exportAttendance()}>
                  Export
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="mb-4">
            <TotalCount className="expected-number">
              TOTAL EXPECTED AGENTS: {getTotalExpectedAgent()}
            </TotalCount>
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <TotalCount className="bg-danger checked-number">
              TOTAL CHECKED IN: {getTotalCheckedInAgent()}/{getTotalExpectedAgent()}
            </TotalCount>
          </Col>
        </Row>
        <Row>
          {result != null &&
            result.length > 0 &&
            result.map(
              (rvalue: any, rindex: any) => {
                // console.log(rvalue);
                // debugger; // eslint-disable-line no-debugger
                return (
                  // debugger; // eslint-disable-line no-debugger
                  // {
                  //   ('b');
                  // }
                  // console.log(value);
                  // {
                  //   ('a');
                  // }
                  // <p>{formatDate(value.date, false)}</p>;
                  <Col xs={12} md={6} style={{ display: rvalue[0] ? 'inline' : 'none' }}>
                    <Table bordered striped className="mb-3" key={rindex}>
                      <tbody key={rindex}>
                        <tr>
                          <td
                            colSpan={5}
                            className="bg-success text-white font-weight-bold"
                            // title={formatDate(rvalue[0].date, true)}
                          >
                            {rvalue[0] && formatDate(rvalue[0].date, false)}
                            {/* |
                            {rvalue[0] &&
                              `Checked in: ${
                                rvalue[0].checkedInCount !== undefined
                                  ? rvalue[0].checkedInCount
                                  : 0
                              }/${rvalue[0].slotCount}`} */}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Name</b>
                          </td>
                          <td>
                            <b>Division</b>
                          </td>
                          <td>
                            <b>Checked-in at</b>
                          </td>
                          <td>
                            <b>Checked-out at</b>
                          </td>
                          <td>
                            <b>Note</b>
                          </td>
                          {/* <td>
                      <b>Is checked-in</b>
                    </td> */}
                        </tr>

                        {rvalue != null &&
                          rvalue.length > 0 &&
                          rvalue.map((value: any, vrindex: any) => {
                            return (
                              value.userSlots.length > 0 &&
                              value.userSlots.map((userSlot: UserSlot) => (
                                <tr key={userSlot.userId}>
                                  <td>{userSlot?.user?.displayName || userSlot?.user?.username}</td>
                                  {/* CHECK IF userSlot's userId is in value.users, then get the division by userSlot.project.division */}
                                  <td>{getUserDataDivision(userSlot, value.users)}</td>
                                  <td>
                                    {getDate(getUserData(userSlot, value.users)?.checkedInAt)}
                                  </td>
                                  <td>
                                    {getDate(getUserData(userSlot, value.users)?.checkedOutAt)}
                                  </td>
                                  <td>
                                    <TableCellNote
                                      style={{ whiteSpace: 'initial' }}
                                      title={getNote(userSlot, value.users)}
                                    >
                                      {getNote(userSlot, value.users)}
                                    </TableCellNote>
                                  </td>
                                </tr>
                              ))
                            );
                          })}
                      </tbody>
                    </Table>
                  </Col>
                );
              }
              // <AttendanceManagerDataTable
              //   dataValue={rvalue}
              //   projects={projects}
              // ></AttendanceManagerDataTable>
              // formatDate(value.date, false)
            )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AttendanceManager;
