import { FcPagination } from 'components/Shared';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { ShiftChange, PaginationInfo, RequestLog } from 'models';
import { useEffect, useState } from 'react';
import { Table, Spinner, Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getApprovedRequestLog, getRejectedRequestLog } from 'store/shiftChanges/shiftChange.slice';
import ShiftChangeRequestLogListItem from '../../Actions/ShiftChangeRequestLogListItem/ShiftChangeRequestLogListItem';

interface Props {
  data: RequestLog[];
  isLoading: boolean;
  type: string;
  paginationInfo: PaginationInfo;
  status: string;
  projectId: string[];
  displayProject: string;
}

const ShiftChangeRequestLogList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { data, isLoading, type, status } = { ...props };
  const { page, perPage, totalCount } = { ...props.paginationInfo };
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getShiftChangesRequest();
  }, [currentPage]);

  const getShiftChangesRequest = () => {
    const payload = {
      page: currentPage,
      perPage: 10,
      type: type,
      orderBy: 'createdAt',
      order: 'desc',
      projectId: props.projectId,
      status: status,
      displayProject: props.displayProject
    };
    if (status === 'APPROVED') {
      dispatch(getApprovedRequestLog(payload));
    } else {
      dispatch(getRejectedRequestLog(payload));
    }
  };

  const Header = () => {
    return (
      <Card.Header className="border-0 p-0">
        <div className="d-flex p-3">
          <Col xs={8}>
            <h5 className="h4 mb-0">
              {props.status === 'APPROVED' ? 'Approved' : 'Rejected'} (
              {data.length > 0 ? totalCount : 0})
            </h5>
          </Col>
          <Col xs={2} className="text-right">
            Created Date
          </Col>
          <Col xs={2} className="text-right"></Col>
        </div>
      </Card.Header>
    );
  };

  return (
    <Card className="shadow py-2">
      {isLoading && (
        <div className="text-center p-5">
          <Spinner animation="border" variant="primary" className="text-center" />
        </div>
      )}
      {!isLoading && (
        <>
          {Header()}
          <Card.Body className="card-body p-0">
            <div>
              {(!data || data.length <= 0) && (
                <div className="text-center">
                  <span className="text-center">No data.</span>
                </div>
              )}
              {data &&
                data.length > 0 &&
                data.map((rl: RequestLog) => (
                  <ShiftChangeRequestLogListItem key={rl._id} data={rl} type={type} />
                ))}
            </div>
            <div className="float-right mt-3 pr-4">
              <FcPagination
                onChangePage={(page: number) => {
                  setCurrentPage(page);
                }}
                totalItems={totalCount}
                perPage={10}
                currentPage={currentPage}
              />
            </div>
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default ShiftChangeRequestLogList;
