import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './meta.initialState';
import { Project } from '../../models';

const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setSelectedProject: (
      state,
      {
        payload
      }: PayloadAction<{
        project: Project;
      }>
    ) => {
      state.selectedProject = payload.project;
    }
  }
});

export const { actions: metaActions, reducer: metaReducer } = metaSlice;
