import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './note.initialState';
import { CreateNotePayload, UpdateNotePayload, Note } from 'models';

const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    getNotes: (state, { payload }: PayloadAction<{ projectId: string }>) => {
      state.isFetching = true;
      state.error = null;
    },
    getNotesSuccess: (state, { payload }: PayloadAction<Note[]>) => {
      state.isFetching = false;
      state.notes = payload;
      state.error = null;
    },
    getNotesFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    createNote: (state, { payload }: PayloadAction<CreateNotePayload>) => {
      state.isCreating = true;
      state.error = null;
    },
    createNoteSuccess: (state, { payload }: PayloadAction<Comment>) => {
      state.isCreating = false;
      state.error = null;
    },
    createNoteFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = false;
      state.error = payload;
    },
    updateNote: (state, { payload }: PayloadAction<UpdateNotePayload>) => {
      state.isUpdating = true;
      state.updatingNoteId = payload.noteId;
      state.error = null;
    },
    updateNoteSuccess: (state, { payload }: PayloadAction<Note[]>) => {
      state.isUpdating = false;
      state.notes = payload;
      state.error = null;
    },
    updateNoteFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    deleteNote: (state, { payload }: PayloadAction<Comment>) => {
      state.isDeleting = true;
      state.error = null;
    },
    deleteNoteSuccess: (state, { payload }: PayloadAction<Comment>) => {
      state.isDeleting = false;
      state.error = null;
    },
    deleteNoteFailed: (state, { payload }: PayloadAction<any>) => {
      state.isDeleting = false;
      state.error = payload;
    }
  }
});

export const noteActions = noteSlice.actions;

export * as noteSelector from './note.selector';

export default noteSlice.reducer;
