import apiService from '../utils/network';

export interface ApplyChangesPayload {
  shiftId: string;
  weekday: number;
  users: string[];
  index: number;
}

export const availabilityApi = {
  createAvailability(userId: string, shiftId: string, weekday: number) {
    return apiService.post('/availabilities', { userId, shiftId, weekday });
  },
  deleteAvailability(availabilityId: string) {
    return apiService.delete(`/availabilities/${availabilityId}`);
  },
  applyChangesToFixedSchedule(payload: ApplyChangesPayload[]) {
    return apiService.post('/availabilities/apply-changes', payload);
  },
  triggerNoti(payload: string) {
    return apiService.post(`/availabilities/project/${payload}/notification`);
  }
};
