import { AssignSlotValidatorHelper, ShiftHelper } from 'helpers';
import { FixedShift, Project, User, UserSlot } from 'models';
import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ButtonRemoveEmployee from '../ButtonRemoveEmployee/ButtonRemoveEmployee';
import DuplicatedWarning from '../DuplicatedWarning/DuplicatedWarning';
import InputDuration from '../InputDuration/InputDuration';
import InputNote from '../InputNote/InputNote';
import InputUserId from '../InputUserId/InputUserId';
import { WarningIcon } from './InputEmployeeRow.styled';
import { useUserFilter } from 'hooks/scheduler/useUserFilter';
import InputDivision from '../InputDivision/InputDivision';

interface Props {
  agentCount: [UserSlot];
  agentIndex: number;
  userIndex: number;
  formik: any;
  shifts: FixedShift[];
  date: number;
  shiftId: string;
  arrayHelpers: any;
  agentCountHelpers: any;
  project: Project | null;
}
const InputEmployeeRow = (props: Props) => {
  const {
    agentCount,
    agentIndex,
    userIndex,
    formik,
    shifts,
    date,
    shiftId,
    arrayHelpers,
    agentCountHelpers,
    project
  } = {
    ...props
  };
  const { users } = useUserFilter();

  const getWarningText = (agentIndex: number) => {
    const hours = AssignSlotValidatorHelper.getTotalHours(agentIndex, formik.values);
    const duration = ShiftHelper.getShiftDuration(shifts, shiftId);
    if (hours > duration) {
      return 'Excess hours added';
    }
    if (hours < duration) {
      return 'Not enough hours added';
    }
    if (AssignSlotValidatorHelper.hasInvalidAgent(agentIndex, shifts, date, formik.values)) {
      return 'Agents with excess hours detected';
    }
  };

  return (
    <React.Fragment>
      <Form.Row>
        <React.Fragment>
          <InputUserId
            formik={formik}
            agentIndex={agentIndex}
            userIndex={userIndex}
            agentCount={agentCount}
            project={project}
          />
          {project && project.divisions.length > 0 && (
            <InputDivision
              formik={formik}
              agentIndex={agentIndex}
              userIndex={userIndex}
              agentCount={agentCount}
              project={project}
            />
          )}
          <InputDuration
            formik={formik}
            agentIndex={agentIndex}
            userIndex={userIndex}
            agentCount={agentCount}
            shiftDuration={ShiftHelper.getShiftDuration(shifts, shiftId)}
            project={project}
          />
          <InputNote formik={formik} agentIndex={agentIndex} userIndex={userIndex} />
          {userIndex === 0 &&
            (AssignSlotValidatorHelper.isTotalHoursInvalid(
              agentIndex,
              shifts,
              shiftId,
              formik.values
            ) ||
              AssignSlotValidatorHelper.hasInvalidAgent(
                agentIndex,
                shifts,
                date,
                formik.values
              )) && (
              <div className="form-group mb-3">
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip id={`tooltip-auto`} className="test">
                      {getWarningText(agentIndex)}
                    </Tooltip>
                  }
                >
                  <WarningIcon className="fa-solid fa-triangle-exclamation text-warning" />
                </OverlayTrigger>
              </div>
            )}
          <DuplicatedWarning
            formik={formik}
            shifts={shifts}
            agentIndex={agentIndex}
            userIndex={userIndex}
          />
          <ButtonRemoveEmployee
            agentIndex={agentIndex}
            userIndex={userIndex}
            arrayHelpers={arrayHelpers}
            agentCountHelpers={agentCountHelpers}
          />
        </React.Fragment>
      </Form.Row>
    </React.Fragment>
  );
};

export default InputEmployeeRow;
