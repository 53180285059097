import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './notification.initialState';
import {
  Notification,
  GetNotificationsPayload,
  PaginationInfo,
  MarkAsReadPayload,
  SetNotificationsPayload,
  MarkAsUnreadPayload
} from 'models';

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getSystemNotifications: (state, { payload }: PayloadAction<GetNotificationsPayload>) => {
      state.isFetchingSystemNoti = true;
      state.error = null;
    },
    getSystemNotificationsSuccess: (state, { payload }: PayloadAction<Notification[]>) => {
      state.isFetchingSystemNoti = false;
      state.systemNotifications = payload;
      state.error = null;
    },
    getSystemNotificationsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetchingSystemNoti = false;
      state.error = payload;
    },
    getUserNotifications: (state, { payload }: PayloadAction<GetNotificationsPayload>) => {
      state.isFetchingUserNoti = true;
      state.error = null;
    },
    getUserNotificationsSuccess: (state, { payload }: PayloadAction<Notification[]>) => {
      state.isFetchingUserNoti = false;
      state.userNotifications = payload;
      state.error = null;
    },
    getUserNotificationsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetchingUserNoti = false;
      state.error = payload;
    },
    setSystemNotisPaginationInfo: (state, { payload }: PayloadAction<PaginationInfo>) => undefined,
    setSystemNotisPaginationInfoSuccess: (state, { payload }: PayloadAction<PaginationInfo>) => {
      state.systemNotisPaginationInfo = payload;
    },
    setUserNotisPaginationInfo: (state, { payload }: PayloadAction<PaginationInfo>) => undefined,
    setUserNotisPaginationInfoSuccess: (state, { payload }: PayloadAction<PaginationInfo>) => {
      state.userNotisPaginationInfo = payload;
    },
    setNotifications: (state, { payload }: PayloadAction<SetNotificationsPayload>) => undefined,
    setNotificationsSuccess: (state, { payload }: PayloadAction<SetNotificationsPayload>) => {
      if (payload.type === 'MANUAL') {
        state.userNotifications = payload.notifications;
      } else {
        state.systemNotifications = payload.notifications;
      }
    },
    markAsRead: (state, { payload }: PayloadAction<MarkAsReadPayload>) => {
      state.isMarking = true;
      state.error = false;
    },
    markAsReadSuccess: (state) => {
      state.isMarking = false;
      state.error = false;
    },
    markAsReadFailed: (state, { payload }: PayloadAction<any>) => {
      state.isMarking = false;
      state.error = payload;
    },
    markAllAsRead: (state) => {
      state.isMarkingAll = true;
      state.error = false;
    },
    markAllAsReadSuccess: (state) => {
      state.isMarkingAll = false;
      state.error = false;
    },
    markAllAsReadFailed: (state, { payload }: PayloadAction<any>) => {
      state.isMarkingAll = false;
      state.error = payload;
    },
    getTotalUnreadNotis: (state) => {
      state.isFetchingTotalUnread = true;
      state.error = null;
    },
    getTotalUnreadNotisSuccess: (state, { payload }: PayloadAction<number>) => {
      state.isFetchingTotalUnread = false;
      state.totalUnread = payload;
      state.error = null;
    },
    getTotalUnreadNotisFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetchingTotalUnread = false;
      state.totalUnread = 0;
      state.error = payload;
    },
    markAsUnread: (state, { payload }: PayloadAction<MarkAsUnreadPayload>) => {
      state.isMarking = true;
      state.error = false;
    },
    markAsUnreadSuccess: (state) => {
      state.isMarking = false;
      state.error = false;
    },
    markAsUnreadFailed: (state, { payload }: PayloadAction<any>) => {
      state.isMarking = false;
      state.error = payload;
    }
  }
});

export const notificationActions = notificationSlice.actions;

export * as notificationSelector from './notification.selector';

export default notificationSlice.reducer;
