import { useSpinner } from 'hooks/useSpinner';
import { Project } from 'models';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import ReportTimesheetFilter from './MainActions/ReportTimesheetFilter';
import ReportTimesheetList from './MainActions/ReportTimesheetList';
import { reportActions } from 'store/reports/report.slice';

const ReportTimesheet = () => {
  const projects: Project[] = useAppSelector(projectSelector.selectProjects);
  const isFetchingProjects: boolean = useAppSelector(projectSelector.selectIsFetching);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(projectActions.getProjects());
    dispatch(reportActions.getPersonalFilters({ kind: 'REPORT' }));
  }, []);

  return !isFetchingProjects ? (
    <>
      <ReportTimesheetFilter />
      <ReportTimesheetList />
    </>
  ) : (
    useSpinner()
  );
};
export default ReportTimesheet;
