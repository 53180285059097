import styled from 'styled-components';

export const BtnAdd = styled.span`
  color: #5e72e4;
  margin-bottom: 0;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;
