import { FcPagination, SpinnerButton } from 'components/Shared';
import debounce from 'lodash.debounce';
import { ButtonTypes, Project, User, UserRole, UserStatus } from 'models';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { userActions, userSelector } from 'store/users/user.slice';
import { CustomRow } from './ModalAddManager.styled';
import UserBoard from './UserBoard/UserBoard';
import UserSortByAlphabet from './UserSortByAlphabet/UserSortByAlphabet';
import { toast } from 'react-toastify';
import useDidMountEffect from 'hooks/useDidMountEffect';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';

interface Props {
  formik: any;
  project: Project | undefined;
}
const ModalAddManager = (props: Props) => {
  const dispatch = useAppDispatch();
  const [selectedAlphabet, setSelectedAlphabet] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [selectedManagers, setSelectedManagers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const isFetching = useAppSelector(userSelector.selectIsFetching);
  const isFetched = useAppSelector(userSelector.selectIsFetched);
  const isUpdatingProjectManagers = useAppSelector(projectSelector.selectIsUpdatingProjectUsers);
  const updateProjectError = useAppSelector(projectSelector.selectError);
  const managers = useAppSelector(userSelector.selectUsersModalAssign);
  const { page, perPage, totalCount } = useAppSelector(userSelector.selectManagersPaginationInfo);
  const [isVisible, setIsVisible] = useState(false);

  const onSelectAlphabet = (value: string[]) => {
    setCurrentPage(1);
    setSelectedAlphabet(value);
    getUsers();
  };

  const toggleVisible = () => {
    setSelectedManagers([]);
    setIsVisible(!isVisible);
  };

  const onSave = () => {
    props.formik.setFieldValue('managers', selectedManagers);
    const payload = { ...props.project, managers: selectedManagers };
    if (props.project) {
      dispatch(
        projectActions.updateProjectUsers({
          ...payload,
          id: props.project._id
        })
      );
    } else {
      setIsVisible(false);
    }
  };

  const onSearch = debounce((e: any) => {
    setCurrentPage(1);
    setSearchString(e.target.value);
    getUsers();
  }, 200);

  useEffect(() => {
    if (isVisible) {
      setSearchString('');
      setSelectedAlphabet([]);
      setSelectedManagers(props.formik.values.managers);
      setCurrentPage(1);
      getUsers();
    }
  }, [isVisible]);

  useEffect(() => {
    onSelectAlphabet(selectedAlphabet);
  }, [selectedAlphabet]);

  useDidMountEffect(() => {
    if (props.project && !isUpdatingProjectManagers && !updateProjectError) {
      toast.success("Update Project's Managers Successfully");
      setIsVisible(false);
    }
  }, [isUpdatingProjectManagers]);

  useDidMountEffect(() => {
    if (!isUpdatingProjectManagers && updateProjectError) {
      toast.error(updateProjectError?.message || 'Something went wrong!');
    }
  }, [updateProjectError]);

  const getUsers = () => {
    dispatch(
      userActions.getUsersModalAssign({
        role: UserRole.MANAGER,
        page: currentPage,
        perPage: 10,
        search: searchString,
        alphabets: selectedAlphabet.join(','),
        status: 'ACTIVE' as UserStatus
        // deleted: false
      })
    );
  };
  return (
    <>
      <Button variant="warning" onClick={toggleVisible}>
        Add
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={toggleVisible}
        size="lg"
        centered
        scrollable={true}
        dialogAs={DraggableModalDialog}
      >
        <Modal.Body>
          <CustomRow>
            <Col xs="9">
              <Modal.Title>Add a New Manager</Modal.Title>
            </Col>
            <Col xs="3" className="text-right">
              <Form.Control type="text" onChange={onSearch} placeholder="Search by username" />
            </Col>
          </CustomRow>
          <UserSortByAlphabet
            selectedAlphabet={selectedAlphabet}
            onSelectAlphabet={onSelectAlphabet}
          />
          <UserBoard
            users={managers}
            isLoading={isFetching}
            selectedUsers={selectedManagers}
            setSelectedUsers={setSelectedManagers}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {isFetched && (
            <FcPagination
              onChangePage={(page: number) => {
                setCurrentPage(page);
                dispatch(
                  userActions.getUsersModalAssign({
                    role: UserRole.MANAGER,
                    page: page,
                    perPage: 10,
                    search: '',
                    alphabets: selectedAlphabet.join(','),
                    status: 'ACTIVE' as UserStatus
                    // deleted: false
                  })
                );
              }}
              totalItems={totalCount}
              perPage={10}
              currentPage={currentPage}
              // setCurrentPage={(page: number) => setCurrentPage(page)}
            />
          )}
          <div>
            <Button variant="outline-secondary" onClick={toggleVisible}>
              Cancel
            </Button>
            {!props.project && (
              <Button variant="primary" onClick={onSave}>
                Save
              </Button>
            )}
            {props.project && (
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                disabled={isUpdatingProjectManagers}
                onClick={onSave}
              >
                Save
              </SpinnerButton>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalAddManager;
