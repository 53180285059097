import { addDays, isEqual, isWithinInterval, startOfDay } from 'date-fns';
import { FixedShift, Shift, Slot, WeekDay, Project } from 'models';
import { formatTo_yyyyMMdd, toDateFromString } from 'utils/formatters/datetime.formatter';
export class ShiftHelper {
  public static findById(
    shifts: (Shift | FixedShift)[],
    shiftId: string
  ): Shift | FixedShift | undefined {
    return shifts.find((shift) => shift._id === shiftId);
  }

  public static getDurationOfShift(shift: Shift | FixedShift) {
    return ShiftHelper.calcDuration(shift);
  }

  public static getShiftDuration(shifts: (Shift | FixedShift)[], shiftId: string) {
    const shift: Shift | FixedShift | undefined = shifts.find((s) => s._id === shiftId);
    if (shift) {
      return ShiftHelper.calcDuration(shift);
    }
    return 0;
  }

  public static findSlotSameDate(shift: Shift | FixedShift, date: string | number) {
    if (typeof date !== 'number') {
      return (shift as Shift)?.slots.filter(
        (slot: Slot) => formatTo_yyyyMMdd(new Date(slot.date)) === date
      ); // get all slot of shift on selected day
    } else {
      return (shift as FixedShift)?.slots.filter((slot) => date === slot.weekday); // get all slot of shift on selected day
    }
  }

  public static calcDuration(shift: Shift | FixedShift) {
    if (!shift) {
      return 0;
    }

    const startTime = ShiftHelper.parseTime(shift.startTime);
    const endTime = ShiftHelper.parseTime(shift.endTime);

    const hoursInDay = 24;
    const durationToMidnight = hoursInDay - startTime;

    if (endTime > startTime) {
      return endTime - startTime;
    } else {
      return durationToMidnight + endTime;
    }
  }

  public static getEndTime(shift: Shift | FixedShift, currentDate: Date) {
    const endTime = ShiftHelper.getEndTimeString(shift);
    const formatString = 'yyyy-MM-dd HH:mm';

    let time = toDateFromString(`${formatTo_yyyyMMdd(currentDate)} ${endTime}`, formatString);
    if (ShiftHelper.isPostMidnight(shift)) {
      time = addDays(time, 1);
    }
    return time;
  }

  public static isPostMidnight(shift: Shift | FixedShift) {
    const { startTime } = { ...shift };

    const startTimeInt = ShiftHelper.getTimeInt(startTime);
    const endTimeInt = ShiftHelper.getEndTimeInt(shift);

    return endTimeInt === 24 || endTimeInt < startTimeInt;
  }

  public static parseTime(time: string) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours + minutes / 60;
  }

  public static getTimeInt(timeString: string) {
    return parseInt(timeString.split(':')[0]);
  }

  public static getEndTimeString(shift: Shift | FixedShift) {
    return shift.endTime === '00:00' ? '24:00' : shift.endTime;
  }

  public static getEndTimeInt(shift: Shift | FixedShift) {
    return shift.endTime === '00:00' ? 24 : ShiftHelper.parseTime(shift.endTime);
  }

  public static getNewEndTimeInt(shift: Shift | FixedShift) {
    return ShiftHelper.parseTime(shift.endTime);
  }

  public static getWeekTotalHours(shifts: Shift[], dates: Date[], slotMarkAs = 'NULL') {
    let totalHours = 0;
    shifts.forEach((shift) => {
      shift.slots.forEach((slot) => {
        if (slot.markAs === slotMarkAs) {
          if (
            isWithinInterval(startOfDay(new Date(slot.date)), {
              start: dates[0],
              end: dates[dates.length - 1]
            })
          ) {
            slot.userSlots.forEach(
              (userSlot) => (totalHours += userSlot.duration - shift.breakTime)
            );
          }
        }
      });
    });
    return totalHours;
  }

  public static getFixedWeekTotalHours(shifts: FixedShift[]) {
    let totalHours = 0;
    shifts.forEach((shift) => {
      shift.slots.forEach((slot) => {
        slot.userSlots.forEach((userSlot) => (totalHours += userSlot.duration));
      });
    });
    return totalHours;
  }

  public static getDateTotalHours(shifts: Shift[], date: Date, slotMarkAs = 'NULL') {
    let totalHours = 0;
    shifts.forEach((shift) => {
      shift.slots.forEach((slot) => {
        if (slot.markAs === slotMarkAs) {
          if (isEqual(startOfDay(new Date(slot.date)), date)) {
            slot.userSlots.forEach(
              (userSlot) => (totalHours += userSlot.duration - shift.breakTime)
            );
          }
        }
      });
    });

    return totalHours;
  }

  public static getFixedWeekdayTotalHours(shifts: FixedShift[], weekday: string) {
    let totalHours = 0;
    shifts.forEach((shift) => {
      shift.slots.forEach((slot) => {
        if (WeekDay[weekday as keyof typeof WeekDay] === slot.weekday) {
          slot.userSlots.forEach((userSlot) => (totalHours += userSlot.duration));
        }
      });
    });
    return totalHours;
  }

  public static getDateTotalHoursByDivision(
    shifts: Shift[],
    date: Date,
    divisionName: string,
    slotMarkAs = 'NULL'
  ) {
    let totalHours = 0;
    shifts.forEach((shift) => {
      shift.slots.forEach((slot) => {
        if (slot.division && slot.division[0]?.name == divisionName) {
          if (slot.markAs === slotMarkAs) {
            if (isEqual(startOfDay(new Date(slot.date)), date)) {
              slot.userSlots.forEach((userSlot) => {
                totalHours += userSlot.duration - shift.breakTime;
              });
            }
          }
        }
      });
    });
    return totalHours;
  }
}
