import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { reportActions, reportSelector } from './report.slice';
import { GetReportsPayload, Report, ReportDashboardData } from 'models/report.model';
import { reportApi } from 'services/report.api';
import { format } from 'date-fns';
import { Filter } from 'models';
import { filterApi } from 'services';
import { AxiosResponse } from 'axios';

export function* setSelectedDateRangeWorker(payload: PayloadAction<Date[]>) {
  try {
    const _payload = payload.payload;
    const dateRanges = [
      formatTo_yyyyMMdd(_payload[0]),
      formatTo_yyyyMMdd(_payload[_payload.length - 1])
    ];
    yield put(reportActions.setSelectedDateRangeSuccess(dateRanges));
  } catch (e) {
    console.log('setSelectedDateRangeWorker Error: ', e);
    yield put(reportActions.setSelectedDateRangeFailed(e));
  }
}

export function* getReportsWorker(payload: PayloadAction<GetReportsPayload>) {
  try {
    const _payload = payload.payload;
    const response: Report[] = yield call(reportApi.getReports, _payload);
    yield put(reportActions.getReportsSuccess(response));
  } catch (e) {
    console.log('getReportsWorker Error: ', e);
    yield put(reportActions.getReportsFailed(e));
  }
}

export function* getDashboardDataWorker({ payload }: PayloadAction<GetReportsPayload>) {
  try {
    const response: ReportDashboardData[] = yield call(reportApi.getDashboardData, payload);
    yield put(reportActions.getDashboardDataSuccess(response[0]));
  } catch (e) {
    console.log('getDashboardDataWorker Error: ', e);
    yield put(reportActions.getDashboardDataFailed(e));
  }
}

export function* exportTimesheetWorker({ payload }: PayloadAction<GetReportsPayload>) {
  try {
    const response: string = yield call(reportApi.exportTimesheet, payload);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Timesheet-${format(new Date(), 'yyyy-MM-dd')}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    yield put(reportActions.exportTimesheetSuccess(response));
  } catch (e) {
    console.log('exportTimesheetWorker Error: ', e);
    yield put(reportActions.exportTimesheetFailed(e));
  }
}

export function* setSortStringWorker({ payload }: PayloadAction<string>) {
  try {
    yield put(reportActions.setSortStringSuccess(payload));
  } catch (e) {
    console.log('setSortStringWorker Error: ', e);
  }
}

export function* setUserTypeWorker({ payload }: PayloadAction<string>) {
  try {
    yield put(reportActions.setUserTypeSuccess(payload));
  } catch (e) {
    console.log('setUserTypeWorker Error: ', e);
  }
}

export function* getPersonalFiltersWorker({ payload }: PayloadAction<{ kind: string }>) {
  try {
    const response: Filter[] = yield call(filterApi.getFilters, payload);
    const filters = response.sort((a: Filter, b: Filter) => {
      return a.name > b.name ? 1 : -1;
    });
    yield put(reportActions.getPersonalFiltersSuccess(filters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    // yield put(projectActions.getProjectsFailed(e));
  }
}
export function* createPersonalFiltersWorker({ payload }: PayloadAction<any>) {
  try {
    const response: Filter = yield call(filterApi.createFilter, payload);
    const filters: Filter[] = yield select(reportSelector.selectFilters);
    const newFilters = [...filters];
    newFilters.push(response);
    yield put(reportActions.createPersonalFilterSuccess(newFilters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(reportActions.createPersonalFilterFailed(e));
  }
}

export default function* reportSaga() {
  yield takeLatest(reportActions.setSelectedDateRange, setSelectedDateRangeWorker);
  yield takeLatest(reportActions.getReports, getReportsWorker);
  yield takeLatest(reportActions.getDashboardData, getDashboardDataWorker);
  yield takeLatest(reportActions.setSortString, setSortStringWorker);
  yield takeLatest(reportActions.exportTimesheet, exportTimesheetWorker);
  yield takeLatest(reportActions.getPersonalFilters, getPersonalFiltersWorker);
  yield takeLatest(reportActions.createPersonalFilter, createPersonalFiltersWorker);
  yield takeLatest(reportActions.setUserType, setUserTypeWorker);
}
