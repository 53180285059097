import { DropdownAutoCloseOutside } from 'components/Shared/index';
import { Project, User } from 'models';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { projectActions } from 'store/projects/project.slice';
import { schedulerActions } from 'store/schedulers/scheduler.slice';
import { shiftActions } from 'store/shifts/shift.slice';
import { userActions } from 'store/users/user.slice';
import { Nullable } from '../../../../utils/common/types';
import { FilterItem, FilterItemLabel } from './ProjectFilter.styled';
import { fixedShiftSelector, fixedShiftActions } from 'store/fixedShifts/fixedShift.slice';

interface Props {
  projects: Project[];
  selectedProject: Nullable<string>;
  setSelectedProject?: (value: string) => void;
  setSelectedUsers: (value: any) => void;
  setIsSelectedAllUsers: (value: boolean) => void;
}

const ProjectFilter = (props: Props) => {
  const { setSelectedProject } = props;
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [label, setLabel] = useState('Project');
  const [isNewlyRendered, setIsNewlyRendered] = useState(true);
  const [activeFilter, setActiveFilter] = useState(props.projects);

  useEffect(() => {
    const project = props.projects.find((prj) => prj?._id === props.selectedProject);
    if (project) {
      setLabel(project.name);
      searchParams.set('project', project.name);
      searchParams.set('projectId', project._id);
      setSearchParams(searchParams);
      if (isNewlyRendered) {
        dispatch(shiftActions.getShifts(project._id));
        setIsNewlyRendered(false);
      } else {
        dispatch(schedulerActions.setIsApplyingFilter(true));
      }
      setIsNewlyRendered(false);
    }
  }, [props.selectedProject]);

  useEffect(() => {
    const project = props.projects.find((prj) => prj?.name === searchParams.get('project'));
    if (project) {
      if (setSelectedProject) {
        setSelectedProject(project._id);
        dispatch(projectActions.setSelectedProject(project));
        setUsersAfterChangingProject(project);
      }
    }
  }, [searchParams]);

  const onSelectProject = (e: any, projectId: string) => {
    e.stopPropagation();
    const project = props.projects.find((prj) => prj?._id === projectId);
    if (project) {
      searchParams.set('project', project.name);
      searchParams.set('projectId', project._id);
      setSearchParams(searchParams);

      dispatch(
        fixedShiftActions.getHighestIndexFixedSlot({
          projectId: projectId
        })
      );
    }
  };

  const setUsersAfterChangingProject = (project: Project) => {
    const _users: User[] = project.users || [];
    dispatch(userActions.setUsers(_users));
    props.setSelectedUsers(_users.map((u) => u._id));
    props.setIsSelectedAllUsers(true);
  };

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(props.projects);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(props.projects);
    } else {
      const filtersList = props.projects.filter((f) =>
        f.name.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  return (
    <DropdownAutoCloseOutside className="mr-2">
      <Dropdown.Toggle size="sm" variant="outline-primary" onMouseEnter={onSetFilter}>
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        <Dropdown.Item as="li">
          <FilterItem>
            <input
              type="text"
              onChange={(e) => onChangeFilter(e.target.value)}
              placeholder="Search Item"
            />
          </FilterItem>
        </Dropdown.Item>
        {activeFilter.length > 0 &&
          activeFilter.map((prj) => (
            <Dropdown.Item key={prj._id} as="li" onClick={(e) => onSelectProject(e, prj._id)}>
              <FilterItem>
                <input
                  type="radio"
                  value={prj._id}
                  checked={props.selectedProject === prj._id}
                  onChange={(e) => onSelectProject(e, prj._id)}
                />
                <FilterItemLabel>{prj.name}</FilterItemLabel>
              </FilterItem>
            </Dropdown.Item>
          ))}
        {activeFilter.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};
export default ProjectFilter;
