import UpdateUser from './MainActions/UpdateUser/UpdateUser';
import UserList from './MainActions/UserList/UserList';
import UserTopActions from './MainActions/UserTopActions/UserTopActions';
import UserResetPassword from './MainActions/UserResetPassword/UserResetPassword';
import DeleteUser from './MainActions/DeleteUser/DeleteUser';

const UserByManager = () => {
  return (
    <div className="shadow card">
      <div className="card-header border-0">
        <UserTopActions />
      </div>
      <UserList />
      <UpdateUser />
      <UserResetPassword />
      <DeleteUser />
    </div>
  );
};

export default UserByManager;
