import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { reportActions } from 'store/reports/report.slice';
import { useDateRangeCalendar } from './useDateRangeCalendar';
import { useProjectFilter } from './useProjectFilter';
import { useReportSorter } from './useReportSorter';

const ButtonApplyDashboard = () => {
  const dispatch = useAppDispatch();
  const { selectedDateRange } = useDateRangeCalendar();
  const { selectedProject } = useProjectFilter();
  const { sortString } = useReportSorter();

  const onApplyFilter = () => {
    dispatch(
      reportActions.getDashboardData({
        projectId: selectedProject,
        dateStart: selectedDateRange[0],
        dateEnd: selectedDateRange[1],
        filter: sortString
      })
    );
  };

  return (
    <Button size="sm" variant="primary" onClick={onApplyFilter}>
      Apply
    </Button>
  );
};
export default ButtonApplyDashboard;
