import { Comment, Note } from 'models';
import { Nullable } from '../../utils/common/types';
export type NoteInitialState = {
  notes: Note[];
  isFetching: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  updatingNoteId: string;
  isDeleting: boolean;
  error: any;
};

export const initialState: NoteInitialState = {
  notes: [],
  isFetching: false,
  isCreating: false,
  isUpdating: false,
  updatingNoteId: '',
  isDeleting: false,
  error: null
};
