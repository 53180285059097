import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

import logo from 'assets/images/login.jpg';
import { authSelector } from 'store/auth/auth.slice';
import { useAppSelector } from 'store/hooks';
import { LoginBackground } from './Login.styled';
import LoginForm from './MainActions/LoginForm';
import { User } from 'models';
import { Cookies } from 'react-cookie';

const Login = () => {
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const token = cookies.get('accessToken');
  // const twoFactorAccessToken = localStorage.getItem("twoFactorAccessToken");
  const loginStep = useAppSelector(authSelector.selectStep);
  //
  if (token && currentUser) {
    const url = currentUser?.role === 'MANAGER' ? '/managers' : '/agents';
    return <Navigate to={url} replace />;
  } else {
    return (
      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <LoginForm />
            {/* {loginStep === LoginStep.LOGIN && <LoginForm />}
            {loginStep === LoginStep.VERIFY_CODE && (
              <TwoFactorLogin />
            )} */}
          </Col>
          <Col md={6} className="d-none d-md-block px-0">
            <LoginBackground src={logo}></LoginBackground>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default Login;
