import { CreateNotePayload, UpdateNotePayload, Note } from 'models';
import ApiService from '../utils/network';
import { API_URL } from './url';

export const noteApi = {
  getNotes(projectId: string) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.NOTE}/project/${projectId}`);
  },
  createNote(payload: CreateNotePayload) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.NOTE}/${payload.projectId}`, payload);
  },
  updateNote(payload: UpdateNotePayload) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.NOTE}/${payload.noteId}`, {
      status: payload.status,
      content: payload.content
    });
  },
  deleteNote(payload: Note) {
    return ApiService.delete(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shift}/${API_URL.SLOTS}/${payload.slot}/${API_URL.NOTE}/${payload._id}`
    );
  }
};
