import { FcModalConfirm } from 'components/Shared';
import { User, UserRole } from 'models';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';

interface Props {
  selectedUsers: User[];
  getUsers: () => void;
  resetPage: () => void;
  resetSelectedUsers: () => void;
  type?: string;
}

const UserBulkRestore = (props: Props) => {
  const { selectedUsers, getUsers, resetSelectedUsers, resetPage, type } = { ...props };
  const [isInited, setIsInited] = useState(false);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const dispatch = useAppDispatch();
  const isRestoring = useAppSelector(userSelector.selectIsRestoring);
  const error = useAppSelector(userSelector.selectError);

  useEffect(() => {
    if (isInited && !isRestoring && error === null) {
      toast.success('Successfully restored.');
      resetPage();
      getUsers();
      resetSelectedUsers();
      setIsModalConfirmVisible(false);
    }
  }, [isRestoring]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.message || 'Something went wrong');
    }
  }, [error]);

  const onRestore = () => {
    setIsInited(true);
    setIsModalConfirmVisible(true);
  };

  const onOk = () => {
    dispatch(userActions.restoreUsers(selectedUsers.map((u) => u._id)));
  };

  return (
    <div>
      {!type && <span className="mr-2">With selected</span>}
      <Button size="sm" variant="success" onClick={onRestore}>
        Restore
      </Button>
      <FcModalConfirm
        show={isModalConfirmVisible}
        onHide={() => setIsModalConfirmVisible(false)}
        title="Confirmation"
        body={
          <>
            <p>
              Are you sure you want to <strong>Restore</strong> these users:
            </p>
            <ul>
              {selectedUsers.map((user) => (
                <li key={user._id}>{user.username}</li>
              ))}
            </ul>
          </>
        }
        footer={
          <>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setIsModalConfirmVisible(false);
              }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onOk}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              OK
            </Button>
          </>
        }
      />
    </div>
  );
};

export default UserBulkRestore;
