import { SpinnerButton } from 'components/Shared';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { ButtonTypes } from 'models';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { filterActions, filterSelector } from 'store/filters/filter.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { filterSchema } from 'utils/validators';

interface FormProps {
  name: string;
}

interface Props {
  selectedProjects: string[];
}

const ShiftChangeSaveFilter = (props: Props) => {
  const { selectedProjects } = { ...props };
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(filterSelector.selectIsLoadingFilters);
  const error = useAppSelector(filterSelector.selectError);
  const [isVisible, setIsVisible] = useState(false);

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: {
      name: ''
    },
    validationSchema: filterSchema,
    onSubmit: (values, actions) => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (error === null && !isLoading && formik.isSubmitting) {
      toast.success('Successfully Created.');
      formik.setSubmitting(false);
      formik.resetForm();
      setIsVisible(false);
    }
  }, [isLoading]);

  const handleSubmit = (values: FormProps) => {
    dispatch(
      filterActions.createPersonalFilter({
        ...values,
        ...{
          project: '',
          employees: [],
          divisions: [],
          projects: selectedProjects,
          kind: 'SHIFTCHANGE'
        }
      })
    );
  };
  return (
    <>
      <Button size="sm" variant="primary" onClick={() => setIsVisible(true)}>
        Save
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
        }}
        size="sm"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="text-start">Filter Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Personal Filter 1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={formik.isSubmitting}
            colorType="primary"
            onClick={formik.submitForm}
          >
            Save
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShiftChangeSaveFilter;
