import InlineEdit from '@atlaskit/inline-edit';
import Select, { ValueType } from '@atlaskit/select';
import {
  EditViewContainer,
  InlineEditContainer,
  InlineEditOverlay,
  ReadViewContainer,
  ReadViewItem
} from './UserInlineEdit.styled';
import './UserInlineEdit.styles.scss';

export type UserInlineEditOptionType = {
  label: string;
  value: string;
  shiftId: string;
  weekday: number;
};

export interface UserInlineEditProps {
  defaultValue: UserInlineEditOptionType[];
  selectOptions: UserInlineEditOptionType[];
  onConfirm: (value: ValueType<UserInlineEditOptionType, true>) => void;
  isEditing?: boolean;
}

const UserInlineEdit = (props: UserInlineEditProps): React.ReactElement<UserInlineEditProps> => {
  const { defaultValue, selectOptions, onConfirm, isEditing } = props;

  return (
    <InlineEditContainer>
      <InlineEdit<ValueType<UserInlineEditOptionType, true>>
        defaultValue={defaultValue}
        editView={(fieldProps) => (
          <EditViewContainer>
            <Select
              {...fieldProps}
              options={selectOptions}
              isMulti
              autoFocus
              openMenuOnFocus
              isClearable={false}
              classNamePrefix="user-select"
              menuShouldScrollIntoView={true}
            />
          </EditViewContainer>
        )}
        readView={() =>
          defaultValue.length === 0 && isEditing ? (
            <ReadViewContainer>
              <i>Empty</i>
            </ReadViewContainer>
          ) : (
            <ReadViewContainer className="fixed-schedule-preview-cell">
              {defaultValue.map((option: UserInlineEditOptionType) => (
                <ReadViewItem key={option.label}>{option.label}</ReadViewItem>
              ))}
            </ReadViewContainer>
          )
        }
        onConfirm={onConfirm}
        hideActionButtons
      />
      {isEditing === false && <InlineEditOverlay />}
    </InlineEditContainer>
  );
};

export default UserInlineEdit;
