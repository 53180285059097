import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FixedShift } from 'models';
import { initialState } from './fixedShift.initialState';

const fixedShiftSlice = createSlice({
  name: 'fixedShifts',
  initialState,
  reducers: {
    getShifts: (state, { payload }: PayloadAction<string>) => {
      state.isFetching = true;
      state.error = null;
    },
    getShiftsSuccess: (state, { payload }: PayloadAction<FixedShift[]>) => {
      state.shifts = payload;
      state.isFetching = false;
      state.error = null;
    },
    getShiftsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getShiftsSlotIndex: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = true;
      state.error = null;
    },
    getShiftsSlotIndexSuccess: (state, { payload }: PayloadAction<FixedShift[]>) => {
      state.shifts = payload;
      state.isFetching = false;
      state.error = null;
    },
    getShiftsSlotIndexFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getHighestIndexFixedSlot: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = true;
      state.error = null;
    },
    getHighestIndexFixedSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.highestIndexFixedSlot = payload;
    },
    getHighestIndexFixedSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    updateShift: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = true;
      state.error = null;
    },
    updateShiftSuccess: (state, { payload }: PayloadAction<any>) => {
      state.shifts = payload;
      state.isUpdating = false;
      state.error = null;
    },
    updateShiftFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    setShifts: (state, { payload }: PayloadAction<FixedShift[]>) => {
      // state.shifts = payload;
    },
    setShiftsSuccess: (state, { payload }: PayloadAction<FixedShift[]>) => {
      state.shifts = payload;
    },
    deleteFixedSlotsByIndex: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = true;
      state.error = null;
    },
    deleteFixedSlotsByIndexSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = null;
    },
    deleteFixedSlotsByIndexFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    }
  }
});

export const fixedShiftActions = fixedShiftSlice.actions;

export * as fixedShiftSelector from './fixedShift.selector';

export default fixedShiftSlice.reducer;
