import {
  addDays,
  eachDayOfInterval,
  endOfISOWeek,
  format,
  isSameDay,
  startOfISOWeek,
  subDays,
  startOfMonth,
  endOfMonth
} from 'date-fns';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { reportActions, reportSelector } from 'store/reports/report.slice';
import { defineds, staticRanges } from './staticRanges';

export const useDateRangeCalendar = () => {
  const dispatch = useAppDispatch();
  const selectedDateRange = useAppSelector(reportSelector.selectSelectedDateRange);
  const [counter, setCounter] = useState(1);
  const [timeString, setTimeString] = useState<string>('This week');
  const [isVisible, setIsVisible] = useState(false);
  const [ranges, setRanges] = useState<any>([
    {
      startDate: startOfISOWeek(new Date()),
      endDate: endOfISOWeek(new Date()),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    const dates = eachDayOfInterval({
      start: ranges[0].startDate,
      end: ranges[0].endDate
    });
    const datesMonth = eachDayOfInterval({
      start: startOfISOWeek(ranges[0].startDate),
      end: endOfISOWeek(ranges[0].endDate)
    });
    dispatch(reportActions.setSelectedDateRange(dates));
    updateTimeString();
  }, [ranges]);

  const updateTimeString = () => {
    const startDate = ranges[0].startDate;
    const endDate = ranges[0].endDate;
    if (
      isSameDay(startDate, defineds.startOfLastWeek) &&
      isSameDay(endDate, defineds.endOfLastWeek)
    ) {
      setTimeString('Last week');
      return;
    }

    if (isSameDay(startDate, defineds.startOfWeek) && isSameDay(endDate, defineds.endOfWeek)) {
      setTimeString('This week');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfNextWeek) &&
      isSameDay(endDate, defineds.endOfNextWeek)
    ) {
      setTimeString('Next week');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfLastMonth) &&
      isSameDay(endDate, defineds.endOfLastMonth)
    ) {
      setTimeString('Last month');
      return;
    }

    if (isSameDay(startDate, defineds.startOfMonth) && isSameDay(endDate, defineds.endOfMonth)) {
      setTimeString('This month');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfNextMonth) &&
      isSameDay(endDate, defineds.endOfNextMonth)
    ) {
      setTimeString('Next month');
      return;
    }

    setTimeString(
      `${format(startDate, 'E, MMM dd, yyyy')} - ${format(endDate, 'E, MMM dd, yyyy')}`
    );
  };

  const onSelect = (item: any) => {
    setRanges([item.selection]);
    if (counter < 2) {
      if (item.selection.startDate.getTime() !== item.selection.endDate.getTime()) {
        setIsVisible(false);
        setCounter(1);
      } else {
        setCounter(counter + 1);
      }
    } else {
      setIsVisible(false);
      setCounter(1);
    }
  };

  const onToggleDateRangePicker = () => {
    if (!isVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
      setCounter(1);
    }
  };

  return {
    ranges,
    selectedDateRange,
    onToggleDateRangePicker,
    isVisible,
    onSelect,
    timeString
  };
};
