import styled from 'styled-components';

export const ShiftAgentContainer = styled.div`
  margin-bottom: 0.4rem;
  border-radius: 0.375rem;
  padding: 0.25rem;
  position: relative;
  background: #e8effd;
  height: 100%;
  &.drag-entered {
    & div {
      text-decoration: underline;
    }
  }
  &:hover {
    cursor: move;
  }
`;

export const ShiftAgentContent = styled.div`
  height: 100%;
  padding: 0.2rem;
  border-left: 0.2rem solid #32539c;
`;
export const ShiftAgentWrapper = styled.div`
  display: block;
`;

export const ContextMenu = styled.div``;
