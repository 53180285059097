import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useUserFilter } from './useUserFilter';
import { userActions } from 'store/users/user.slice';
import { User } from 'models';

const DEFAULT_COLOR = '#e8effd';
export const useHighlighter = () => {
  const [allColor, setAllColor] = useState(DEFAULT_COLOR);
  const [isSelectingAllUsers, setIsSelectingAllUsers] = useState(false);
  const [isSelectingSelectedUsers, setIsSelectingSelectedUsers] = useState(false);
  const [selectedUsersColor, setSelectedUsersColor] = useState(DEFAULT_COLOR);
  const [pickedUsers, setPickedUsers] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { users, selectedUsers } = useUserFilter();

  const onSelectColor = (e: any, user: any) => {
    const _users = users.map((u) => {
      return user._id === u._id ? { ...u, color: e.target.value } : u;
    });
    dispatch(userActions.setUsers(_users));
  };

  const onSelectColorAll = (e: any) => {
    const color = e.target.value;
    setAllColor(color);
    const _users = users.map((u) => {
      return { ...u, color: color };
    });
    dispatch(userActions.setUsers(users));
  };

  const onChangeSelectedUsersColor = (e: any) => {
    const color = e.target.value;
    setSelectedUsersColor(color);
    const _users = users.map((u) => {
      return pickedUsers.includes(u._id) ? { ...u, color: color } : u;
    });
    dispatch(userActions.setUsers(_users));
  };

  const onSelectUser = (e: any, user: User) => {
    const checked = e.target.checked;
    const _users = [...pickedUsers];
    if (checked) {
      _users.push(user._id);
    } else {
      const index = _users.findIndex((userId) => userId === user._id);
      _users.splice(index, 1);
    }
    setPickedUsers(_users);
  };
  const onReset = () => {
    const _users = users.map((u) => {
      return { ...u, color: DEFAULT_COLOR };
    });
    setAllColor(DEFAULT_COLOR);
    setSelectedUsersColor(DEFAULT_COLOR);
    setPickedUsers([]);
    dispatch(userActions.setUsers(_users));
  };

  const onClick = (e: any, user: User) => {
    const _users = [...pickedUsers];
    if (!_users.includes(user._id)) {
      _users.push(user._id);
    } else {
      const index = _users.findIndex((userId) => userId === user._id);
      _users.splice(index, 1);
    }
    setPickedUsers(_users);
  };

  return {
    allColor,
    selectedUsersColor,
    pickedUsers,
    DEFAULT_COLOR,
    onReset,
    onSelectColor,
    onSelectColorAll,
    onClick,
    onSelectUser,
    onChangeSelectedUsersColor
  };
};
