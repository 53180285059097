import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './scheduler.initialState';
import { Filter } from 'models';
const schedulerSlice = createSlice({
  name: 'schedulers',
  initialState,
  reducers: {
    setIsModalCreateSlotVisible: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsModalCreateSlotVisibleSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalCreateSlotVisible = payload;
    },
    setSelectedShiftId: (state, { payload }: PayloadAction<string>) => undefined,
    setSelectedShiftIdSuccess: (state, { payload }: PayloadAction<string>) => {
      state.selectedShiftId = payload;
    },
    setSelectedDate: (state, { payload }: PayloadAction<string>) => undefined,
    setSelectedDateSuccess: (state, { payload }: PayloadAction<string>) => {
      state.selectedDate = payload;
    },
    setSelectedDateRange: (state, { payload }: PayloadAction<any>) => undefined,
    setSelectedDateRangeSuccess: (state, { payload }: PayloadAction<any>) => {
      state.selectedDateRange = payload;
    },
    setIsApplyingFilter: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsApplyingFilterSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isApplyingFilter = payload;
    },
    setViewMode: (state, { payload }: PayloadAction<string>) => undefined,
    setViewModeSuccess: (state, { payload }: PayloadAction<string>) => {
      state.viewMode = payload;
    },
    getPersonalFilters: (state) => undefined,
    getPersonalFiltersSuccess: (state, { payload }: PayloadAction<any>) => {
      state.personalFilters = payload;
      state.error = null;
    },
    getPersonalFiltersFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    createPersonalFilter: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = true;
      state.error = null;
    },
    createPersonalFilterSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = false;
      state.personalFilters = payload;
      state.error = null;
    },
    createPersonalFilterFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = false;
      state.error = payload;
    },
    setShowingCompactOverview: (state, { payload }: PayloadAction<boolean>) => undefined,
    setShowingCompactOverviewSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.showingCompactOverview = payload;
    }
  }
});

export const schedulerActions = schedulerSlice.actions;

export * as schedulerSelector from './scheduler.selector';

export default schedulerSlice.reducer;
