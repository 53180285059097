import { ProjectTopActionsWrapper } from './ProjectTopActions.styled';
import AddProject from 'components/Project/MainActions/AddProject/AddProject';

const ProjectTopActions = () => {
  return (
    <ProjectTopActionsWrapper>
      <AddProject></AddProject>
    </ProjectTopActionsWrapper>
  );
};

export default ProjectTopActions;
