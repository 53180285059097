import ApiService from '../utils/network';
import { API_URL } from './url';

export const slotApi = {
  createSlot(payload: any) {
    return ApiService.post(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/slots`,
      payload.payload
    );
  },
  updateSlot(payload: any) {
    const { userSlots, division }: any = { ...payload };
    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/slots/${payload.slotId}`,
      { userSlots: userSlots, division: division }
    );
  },
  deleteSlot(payload: any) {
    return ApiService.delete(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/slots/${payload.slotId}`
    );
  },
  bulkUpdate(payload: any) {
    const { slotIds, shiftIds, userSlots, division }: any = { ...payload };

    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.SHIFT}/${shiftIds[0]}/slots/bulk_update`, {
      slotIds,
      shiftIds,
      userSlots,
      division
    });
  }
};
