import { FormikTouched, setNestedObjectValues } from 'formik';
import { Modal, Button } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { schedulerActions } from 'store/schedulers/scheduler.slice';
interface Props {
  show: boolean;
  formik: any;
  initialValues: any;
  changingValue: object;
  onHide: () => void;
  onProceed: () => void;
  onKeepGoing: () => void;
}

const ModalConfirmChange = (props: Props) => {
  const { show, formik, initialValues, changingValue, onHide } = { ...props };

  const dispatch = useAppDispatch();

  const onProceed = async () => {
    // props.onProceed();
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      props.onProceed();
    } else {
      formik.setTouched(setNestedObjectValues<FormikTouched<any>>(errors, true));
      onHide();
    }
  };

  const onKeepGoing = () => {
    props.onKeepGoing();
  };
  return (
    <Modal
      className="modal-confirm"
      style={{
        zIndex: 100000
      }}
      show={props.show}
      onHide={props.onHide}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-0">
        <p>There are changes to this page. Are you sure you want to proceed?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onHide} variant="secondary">
          No
        </Button>
        <Button onClick={onKeepGoing} variant="danger">
          Keep going
        </Button>
        <Button onClick={onProceed} variant="primary">
          Apply and Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmChange;
