import { PayloadAction } from '@reduxjs/toolkit';
import { Filter } from 'models';
// import { Shift, Slot } from "models";
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { filterApi } from 'services';
import { schedulerFixActions, schedulerFixSelector } from './schedulerFix.slice';

export function* setIsModalCreateSlotVisibleWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(schedulerFixActions.setIsModalCreateSlotVisibleSuccess(payload));
  } catch (e) {
    console.log('setIsModalCreateSlotVisibleWorker Error: ', e);
  }
}

export function* setSelectedShiftIdWorker({ payload }: PayloadAction<string>) {
  try {
    yield put(schedulerFixActions.setSelectedShiftIdSuccess(payload));
  } catch (e) {
    console.log('setSelectedShiftIdWorker Error: ', e);
  }
}

export function* setSelectedDateWorker({ payload }: PayloadAction<number>) {
  try {
    yield put(schedulerFixActions.setSelectedDateSuccess(payload));
  } catch (e) {
    console.log('setSelectedDateSuccess Error: ', e);
  }
}

export function* setIsApplyingFilterWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(schedulerFixActions.setIsApplyingFilterSuccess(payload));
  } catch (e) {
    console.log('setIsApplyingFilterSuccess Error: ', e);
  }
}

export function* getPersonalFiltersWorker() {
  try {
    const response: Filter[] = yield call(filterApi.getFilters);
    const filters = response.sort((a: Filter, b: Filter) => {
      return a.name > b.name ? 1 : -1;
    });
    yield put(schedulerFixActions.getPersonalFiltersSuccess(filters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    // yield put(projectActions.getProjectsFailed(e));
  }
}
export function* createPersonalFiltersWorker({ payload }: PayloadAction<Filter>) {
  try {
    const response: Filter = yield call(filterApi.createFilter, payload);
    const filters: Filter[] = yield select(schedulerFixSelector.selectPersonalFilters);
    const newFilters = [...filters];
    newFilters.push(response);
    yield put(schedulerFixActions.createPersonalFilterSuccess(newFilters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(schedulerFixActions.createPersonalFilterFailed(e));
  }
}

export default function* schedulerSaga() {
  yield takeLatest(
    schedulerFixActions.setIsModalCreateSlotVisible,
    setIsModalCreateSlotVisibleWorker
  );
  yield takeLatest(schedulerFixActions.setSelectedShiftId, setSelectedShiftIdWorker);
  yield takeLatest(schedulerFixActions.setSelectedDate, setSelectedDateWorker);
  yield takeLatest(schedulerFixActions.setIsApplyingFilter, setIsApplyingFilterWorker);
  yield takeLatest(schedulerFixActions.getPersonalFilters, getPersonalFiltersWorker);
  yield takeLatest(schedulerFixActions.createPersonalFilter, createPersonalFiltersWorker);
}
