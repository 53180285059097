import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { scheduleAvailabilityActions } from '../../store/scheduleAvailability/scheduleAvailability.slice';
import {
  selectApplyChangesMessage,
  selectEnableEditing,
  selectPreview,
  selectProjectId,
  selectRecentClear
} from '../../store/scheduleAvailability/scheduledAvailability.selector';
import { toast } from 'react-toastify';
import { fixedScheduledPreviewActions } from '../../store/fixedSchedulePreview/fixedScheduledPreview.slice';
import { ScheduleAvailabilityPreview } from '../../store/scheduleAvailability/scheduleAvailability.initialState';
import { useAuth } from '../../hooks';
import { UserRole } from '../../models';
import { fixedShiftSelector, fixedShiftActions } from 'store/fixedShifts/fixedShift.slice';
import { DropdownAutoCloseOutside } from 'components/Shared/index';
import { Dropdown } from 'react-bootstrap';
import {
  FilterItem,
  FilterItemLabel
} from 'components/Scheduler/FilterActions/SchedulerFilter.styled';
import ScheduleFixIndex from 'components/Shared/ScheduleFixIndex/ScheduleFixIndex';

interface Props {
  children?: React.ReactNode;
}

const ButtonWrapper = styled(Col)`
  text-align: right;
`;

const ScheduleAvailabilityActionButton = (): React.ReactElement<Props> => {
  const projectId = useAppSelector(selectProjectId);
  const enableEditing = useAppSelector(selectEnableEditing);
  const previewData = useAppSelector(selectPreview);
  const applyChangesMessage = useAppSelector(selectApplyChangesMessage);
  const currentUser = useAuth();
  const recentClear = useAppSelector(selectRecentClear);
  const [fixTab, setFixTab] = useState(1);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (applyChangesMessage && projectId) {
      toast.success(applyChangesMessage);
      dispatch(scheduleAvailabilityActions.requestAvailabilityBreakdown({ projectId }));
    }
  }, [applyChangesMessage]);

  useEffect(() => {
    if (recentClear && projectId) {
      dispatch(scheduleAvailabilityActions.getListOfAvailability({ projectId }));
    }
  }, [recentClear]);

  const handleEditClicked = () => {
    dispatch(scheduleAvailabilityActions.setEnableEditing({ enabled: true }));
  };

  const handleDoneClicked = () => {
    dispatch(scheduleAvailabilityActions.setEnableEditing({ enabled: false }));
    if (projectId) {
      dispatch(scheduleAvailabilityActions.triggerNoti(projectId));
    }

    if (currentUser.role === UserRole.MANAGER) {
      if (projectId) {
        dispatch(fixedScheduledPreviewActions.requestSavePreviewData({ projectId, previewData }));
      }
    }
  };

  const handleApplyChangesClicked = () => {
    const changingData: ScheduleAvailabilityPreview[] = previewData.map((previewItem) => {
      return {
        shiftId: previewItem.shift._id,
        weekday: previewItem.weekday,
        users: previewItem.users,
        index: fixTab
      };
    });

    dispatch(scheduleAvailabilityActions.requestApplyChanges(changingData));
  };

  const handleShuffleClicked = () => {
    if (projectId) {
      dispatch(fixedScheduledPreviewActions.requestShuffledPreviewData({ projectId }));
    }
  };

  const handleClearClicked = () => {
    if (currentUser.role !== UserRole.AGENT) {
      return;
    }

    if (projectId) {
      dispatch(scheduleAvailabilityActions.agentClearAvaiability({ projectId }));
    }
  };

  const onScheduleFixIndexChangeValueHandler = (e: any, index: number) => {
    // e.stopPropagation();
    setFixTab(e);
  };

  return (
    <ButtonWrapper>
      {!enableEditing ? (
        <>
          <ScheduleFixIndex fixTab={fixTab} onChangeValue={onScheduleFixIndexChangeValueHandler} />
          {currentUser.role === UserRole.MANAGER && (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={handleApplyChangesClicked}
              disabled={!projectId || !(previewData.length > 0)}
            >
              Save
            </Button>
          )}

          <Button
            variant="outline-primary"
            onClick={handleEditClicked}
            disabled={!projectId}
            size="sm"
          >
            Edit
          </Button>
        </>
      ) : (
        <>
          {currentUser.role === UserRole.MANAGER ? (
            <Button variant="primary" onClick={handleShuffleClicked} size="sm">
              Shuffle
            </Button>
          ) : (
            <Button variant="primary" onClick={handleClearClicked} size="sm">
              Clear
            </Button>
          )}

          <Button variant="primary" onClick={handleDoneClicked} size="sm">
            Done
          </Button>
          {/* TODO: Implement `undo` function */}
          {/* <Button variant="secondary">Undo</Button> */}
        </>
      )}
    </ButtonWrapper>
  );
};

export default ScheduleAvailabilityActionButton;
