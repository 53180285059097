import { AppState } from '../index.store';

export const selectIsFetching = (state: AppState) => state.shiftChanges.isFetching;

export const selectIsFetchingOnHoldData = (state: AppState) =>
  state.shiftChanges.isFetchingOnHoldData;

export const selectIsCreating = (state: AppState) => state.shiftChanges.isCreating;

export const selectIsUpdating = (state: AppState) => state.shiftChanges.isUpdating;

export const selectPendingProjectCount = (state: AppState) =>
  state.shiftChanges.pendingProjectCount;

export const selectOnholdProjectCount = (state: AppState) => state.shiftChanges.onholdProjectCount;

export const selectShiftChanges = (state: AppState) => state.shiftChanges.shiftChanges;

export const selectOnHoldData = (state: AppState) => state.shiftChanges.onHoldData;

export const selectPending = (state: AppState) => state.shiftChanges.pending;

export const selectError = (state: AppState) => state.shiftChanges.error;

export const selectApprovedRequestLog = (state: AppState) => state.shiftChanges.approvedRequestLog;

export const selectRejectedRequestLog = (state: AppState) => state.shiftChanges.rejectedRequestLog;

export const approvedShiftChangePaginationInfo = (state: AppState) =>
  state.shiftChanges.approvedShiftChangePaginationInfo;

export const rejectShiftChangePaginationInfo = (state: AppState) =>
  state.shiftChanges.rejectShiftChangePaginationInfo;
