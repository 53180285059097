import ShiftChange from 'components/ShiftChange/ShiftChange';
import { UserRole, UserStatus } from 'models';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { projectActions } from 'store/projects/project.slice';
import { userActions } from 'store/users/user.slice';

const ShiftChangePage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      userActions.getUsersModalAssign({
        page: 1,
        perPage: 10
      })
    );
    dispatch(projectActions.getProjects());
  }, []);
  return <ShiftChange></ShiftChange>;
};

export default ShiftChangePage;
