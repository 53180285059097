import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './shiftChange.initialState';
import {
  GetShiftChangePayload,
  GetShiftChangeResponse,
  Shift,
  ShiftChange,
  PaginationInfo,
  ShiftChangeFormProps,
  GetRequestLogResponse,
  GetShiftChangeIdPayload
} from 'models';

const shiftChangeSlice = createSlice({
  name: 'shiftChanges',
  initialState,
  reducers: {
    getShiftChanges: (state, { payload }: PayloadAction<GetShiftChangePayload>) => {
      state.isFetching = true;
    },
    getShiftChangesSuccess: (state, { payload }: PayloadAction<GetShiftChangeResponse>) => {
      state.isFetching = false;
      state.shiftChanges = payload.data;
      state.pending = payload.pending;
      state.pendingProjectCount = payload.group.pending;
      state.onholdProjectCount = payload.group.onhold;
      state.error = null;
    },
    getShiftChangesFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getOnHoldData: (state, { payload }: PayloadAction<GetShiftChangePayload>) => {
      state.isFetchingOnHoldData = true;
    },
    getOnHoldDataSuccess: (state, { payload }: PayloadAction<GetShiftChangeResponse>) => {
      state.isFetchingOnHoldData = false;
      state.onHoldData = payload.data;
      // state.pending = payload.pending;
      state.error = null;
    },
    getOnHoldDataFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetchingOnHoldData = false;
      state.error = payload;
    },
    createShiftChange: (state, { payload }: PayloadAction<ShiftChangeFormProps>) => {
      state.isCreating = true;
    },
    createShiftChangeSuccess: (state, { payload }: PayloadAction<ShiftChange>) => {
      state.isCreating = false;
      state.error = null;
    },
    createShiftChangeFailed: (state, { payload }: PayloadAction<any>) => {
      console.log(payload);
      state.isCreating = false;
      state.error = payload;
    },
    updateShiftChange: (state, { payload }: PayloadAction<ShiftChange>) => {
      state.isUpdating = true;
    },
    updateShiftChangeSuccess: (state, { payload }: PayloadAction<ShiftChange>) => {
      state.isUpdating = false;
      state.error = null;
    },
    updateShiftChangeFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    getApprovedRequestLog: (state, { payload }: PayloadAction<GetShiftChangePayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getRejectedRequestLog: (state, { payload }: PayloadAction<GetShiftChangePayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getApprovedRequestLogSuccess: (state, { payload }: PayloadAction<GetRequestLogResponse>) => {
      state.isFetching = false;
      state.approvedRequestLog = payload.data;
      state.pending = payload.pending;
      state.error = null;
    },
    getApprovedRequestLogFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getRejectedRequestLogSuccess: (state, { payload }: PayloadAction<GetRequestLogResponse>) => {
      state.isFetching = false;
      state.rejectedRequestLog = payload.data;
      state.pending = payload.pending;
      state.error = null;
    },
    getRejectedRequestLogFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    setApprovedRequestLogPaginationInfo: (state, { payload }: PayloadAction<PaginationInfo>) =>
      undefined,
    setApprovedRequestLogPaginationInfoSuccess: (
      state,
      { payload }: PayloadAction<PaginationInfo>
    ) => {
      state.approvedShiftChangePaginationInfo = payload;
    },
    setRejectedRequestLogPaginationInfo: (state, { payload }: PayloadAction<PaginationInfo>) =>
      undefined,
    setRejectedRequestLogPaginationInfoSuccess: (
      state,
      { payload }: PayloadAction<PaginationInfo>
    ) => {
      state.rejectShiftChangePaginationInfo = payload;
    },
    getShiftChangeById: (state, { payload }: PayloadAction<GetShiftChangeIdPayload>) => {
      state.isFetching = true;
    },
    getShiftChangeByIdSuccess: (state, { payload }: PayloadAction<GetShiftChangeResponse>) => {
      state.isFetching = false;
      state.shiftChanges = payload.data;
      state.error = null;
    },
    getShiftChangeByIdFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    }
  }
});

export const {
  getShiftChanges,
  getShiftChangesSuccess,
  getShiftChangesFailed,
  getOnHoldData,
  getOnHoldDataSuccess,
  getOnHoldDataFailed,
  createShiftChange,
  createShiftChangeSuccess,
  createShiftChangeFailed,
  updateShiftChange,
  updateShiftChangeSuccess,
  updateShiftChangeFailed,
  getApprovedRequestLog,
  getRejectedRequestLog,
  getApprovedRequestLogSuccess,
  getApprovedRequestLogFailed,
  getRejectedRequestLogSuccess,
  getRejectedRequestLogFailed,
  setApprovedRequestLogPaginationInfo,
  setApprovedRequestLogPaginationInfoSuccess,
  setRejectedRequestLogPaginationInfo,
  setRejectedRequestLogPaginationInfoSuccess,
  getShiftChangeById,
  getShiftChangeByIdSuccess,
  getShiftChangeByIdFailed
} = {
  ...shiftChangeSlice.actions
};

export * as shiftSelector from './shiftChange.selector';

export default shiftChangeSlice.reducer;
