import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './attendance.initialState';
import {
  Attendance,
  AttendanceCheckInPayload,
  AttendanceCheckoutPayload,
  AttendanceCoveringPayload,
  GetAttendancesPayload
} from 'models';

const attendanceSlice = createSlice({
  name: 'attendances',
  initialState,
  reducers: {
    getAttendances: (state, { payload }: PayloadAction<GetAttendancesPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getAttendancesSuccess: (state, { payload }: PayloadAction<Attendance[]>) => {
      state.isFetching = false;
      state.attendances = payload;
      state.error = null;
    },
    getAttendancesFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    checkin: (state, { payload }: PayloadAction<AttendanceCheckInPayload>) => {
      state.isCheckingIn = true;
      state.isCheckingInSuccess = false;
      state.isCheckingInLate = false;
      state.isCheckingInEarly = false;
      state.error = null;
    },
    checkinSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isCheckingIn = false;
      state.isCheckingInSuccess = true;
      state.isCheckingInLate = false;
      state.isCheckingInEarly = false;
      state.error = null;
    },
    checkinFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCheckingIn = false;
      state.isCheckingInSuccess = false;
      if (payload.error === 'LateCheckInException') {
        state.isCheckingInLate = true;
        state.isCheckingInEarly = false;
      } else if (payload.error === 'EarlyCheckInException') {
        state.isCheckingInLate = false;
        state.isCheckingInEarly = true;
      }
      state.error = payload;
    },
    covering: (state, { payload }: PayloadAction<AttendanceCoveringPayload>) => {
      state.isCovering = true;
      state.isCoveringSuccess = false;
      state.error = null;
    },
    coveringSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isCovering = false;
      state.isCoveringSuccess = true;
      state.error = null;
    },
    coveringFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCovering = false;
      state.isCoveringSuccess = false;
      state.error = payload;
    },
    checkout: (state, { payload }: PayloadAction<AttendanceCheckoutPayload>) => {
      state.isCheckingOut = true;
      state.isCheckingOutSuccess = false;
      state.error = null;
    },
    checkoutSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isCheckingOut = false;
      state.isCheckingOutSuccess = true;
      state.isCheckingOutLate = false;
      state.isCheckingOutEarly = false;
      state.error = null;
    },
    checkoutFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCheckingOut = false;
      state.error = payload;
      if (payload.error === 'LateCheckOutException') {
        state.isCheckingOutLate = true;
        state.isCheckingOutEarly = false;
      } else if (payload.error === 'EarlyCheckOutException') {
        state.isCheckingOutLate = false;
        state.isCheckingOutEarly = true;
      }
    },
    resetToInit: (state) => undefined,
    resetToInitSuccess: (state) => {
      state.isCheckingOut = false;
      state.isCheckingIn = false;
      state.isCheckingInSuccess = false;
      state.isCheckingInLate = false;
      state.isCheckingInEarly = false;
      state.isCheckingOutSuccess = false;
      state.isCheckingOutLate = false;
      state.isCheckingOutEarly = false;
      state.isCheckingOutLate = false;
      state.isCheckingOutEarly = false;
      state.isCoveringSuccess = false;
      state.isCovering = false;
      state.error = null;
    },
    resetToInitFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    }
  }
});

export const attendanceActions = attendanceSlice.actions;

export * as attendanceSelector from './attendance.selector';

export default attendanceSlice.reducer;
