import User from 'components/User/User';
import { useAuth } from 'hooks';
import { UserRole, UserStatus } from 'models';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { projectActions } from 'store/projects/project.slice';
import { userActions } from 'store/users/user.slice';

const UserPage = () => {
  const currentUser = useAuth();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (currentUser.role === UserRole.MANAGER) {
      dispatch(
        userActions.getUsersModalAssign({
          page: 1,
          perPage: 10
        })
      );
      dispatch(projectActions.getProjects());
    } else {
      dispatch(userActions.getUsersSameProject());
    }
  }, []);
  return <User></User>;
};

export default UserPage;
