import styled from 'styled-components';

export const BadgeCount = styled.div`
  border-radius: 50%;
  color: #fff;
  background: red;
  width: 14px;
  height: 14px;
  font-size: 0.5rem;
  position: absolute;
  top: 0;
  line-height: 14px;
  right: 0;
`;
