import { Button } from 'react-bootstrap';
import ProjectTopActions from './MainActions/ProjectTopActions/ProjectTopActions';
import ProjectList from './MainActions/ProjectList/ProjectList';
import UpdateProject from './MainActions/UpdateProject/UpdateProject';
const Project = () => {
  return (
    <div className="shadow card">
      <div className="card-header border-0">
        <ProjectTopActions />
      </div>
      <ProjectList />
      <UpdateProject></UpdateProject>
    </div>
  );
};

export default Project;
