import ShiftForm from 'components/Project/Actions/ShiftForm/ShiftForm';
import { SpinnerButton } from 'components/Shared';
import { FormikProps, useFormik } from 'formik';
import { ButtonTypes, FixedShift, Project, WeekType } from 'models';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { fixedShiftActions, fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { shiftSchema } from 'utils/validators';
import './UpdateShift.scss';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';

interface Props {
  project: Project;
}
interface FormProps {
  shiftId: string;
  startTime: string;
  endTime: string;
  note: string;
  breakTime: number;
  numOfAgents: number;
  type: string;
}

const UpdateProject = (props: Props) => {
  const { project } = { ...props };
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [submittingType, setSubmittingType] = useState('');
  const isUpdating = useAppSelector(fixedShiftSelector.selectIsUpdating);
  const error = useAppSelector(fixedShiftSelector.selectError);
  const shifts: FixedShift[] = useAppSelector(fixedShiftSelector.selectShifts);
  const isFetching = useAppSelector(fixedShiftSelector.selectIsFetching);

  useEffect(() => {
    if (isVisible) {
      dispatch(fixedShiftActions.getShifts(project?._id));
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !isFetching) {
      if (shifts[0]) {
        formik.setValues({
          shiftId: shifts[0]?._id || '',
          startTime: shifts[0]?.startTime || '24:00',
          endTime: shifts[0]?.endTime || '23:59',
          note: shifts[0]?.note || '',
          breakTime: shifts[0]?.breakTime || 0,
          numOfAgents: shifts[0]?.numOfAgents || 0,
          type: shifts[0]?.type || WeekType.WEEKDAY
        });
      }
    }
  }, [isFetching]);

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: {
      shiftId: shifts[0]?._id || '',
      startTime: shifts[0]?.startTime || '',
      endTime: shifts[0]?.endTime || '',
      note: shifts[0]?.note || '',
      breakTime: shifts[0]?.breakTime || 0,
      numOfAgents: shifts[0]?.numOfAgents || 0,
      type: shifts[0]?.type || WeekType.WEEKDAY
    },
    validationSchema: shiftSchema,
    onSubmit: (values, actions) => {
      handleSubmit(values, actions);
    }
  });

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.message || 'Something went wrong.');
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (error === null && !isUpdating && formik.isSubmitting) {
      toast.success('Successfully Updated.');
      formik.setSubmitting(false);
      if (submittingType === 'submit') {
        setIsVisible(false);
      }
    }
  }, [isUpdating]);

  const handleSubmit = (values: any, actions: any) => {
    dispatch(
      fixedShiftActions.updateShift({
        ...values,
        ...{
          projectId: project?._id || ''
        }
      })
    );
  };

  return (
    <>
      <Button
        variant="warning"
        onClick={() => {
          setIsVisible(true);
        }}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
      >
        <i className="fa-solid fa-gear"></i>
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
        }}
        size="lg"
        centered
        dialogAs={DraggableModalDialog}
        backdrop={'static'}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Edit Shift
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <ShiftForm project={project} shifts={shifts} formik={formik} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setIsVisible(false)}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Cancel
          </Button>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            colorType="success"
            disabled={formik.isSubmitting && submittingType === 'apply'}
            onClick={() => {
              setSubmittingType('apply');
              formik.submitForm();
            }}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Apply
          </SpinnerButton>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={formik.isSubmitting && submittingType === 'submit'}
            onClick={() => {
              setSubmittingType('submit');
              formik.submitForm();
            }}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Save
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateProject;
