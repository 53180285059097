import { SpinnerButton } from 'components/Shared';
import { FormikProps, FormikProvider } from 'formik';
import { useAuth } from 'hooks';
import { ButtonTypes, FixedShift, Project, ShiftChange, ShiftChangeFormProps, User } from 'models';
import { ScheduleGlance } from 'models/scheduleGlance.model';
import { Button, Col, Form } from 'react-bootstrap';
import ShiftChangeDetailSwap from './ShiftChangeDetailSwap';
import ShiftChangeDetailCover from './ShiftChangeDetailCover';
import ShiftChangeDetailMove from './ShiftChangeDetailMove';
import ShiftChangeDetailReplace from './ShiftChangeDetailReplace';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';

interface Props {
  shiftChange: ShiftChange;
}

const requestTypes = [
  { key: 'SWAP', value: 'Swap' },
  { key: 'COVER', value: 'Cover' },
  { key: 'REPLACE', value: 'Replace' },
  { key: 'MOVE', value: 'Move' }
];

const ShiftChangeDetail = (props: Props) => {
  const { shiftChange } = { ...props };
  const currentUser = useAuth();

  const getDate = (value: string | undefined) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };
  const getInitValues = (data: ShiftChange) => {
    return {
      type: data?.type,
      employeeType: data?.employeeType || '',
      project: data?.project || '',
      owner: data?.owner || '',
      agent: data?.agent || ['', ''],
      ownerShift: data?.ownerShift || '',
      agentShift: data?.agentShift || ['', ''],
      ownerNewShift: data?.ownerShift || '',
      agentNewShift: data?.agentShift || ['', ''],
      ownerDate: data?.ownerDate || '',
      agentDate: data?.agentDate || ['', ''],
      ownerNewDate: data?.ownerDate || '',
      agentNewDate: data?.agentDate || ['', ''],
      ownerTime: data?.ownerTime || '',
      agentTime: data?.agentTime || '',
      reason: data?.reason || '',
      offVacation: data?.offVacation || false,
      createdBy: data?.createdBy || '',
      createdAt: data?.createdAt || ''
    };
  };

  const initialValues: ShiftChangeFormProps = getInitValues(shiftChange);
  const newData = Object.assign({}, initialValues, shiftChange) as any;
  const {
    type,
    project,
    employeeType,
    reason,
    agent,
    agentShift,
    agentNewShift,
    agentDate,
    agentNewDate,
    agentTime,
    owner,
    ownerShift,
    ownerNewShift,
    ownerDate,
    ownerNewDate,
    ownerTime
  }: any = {
    ...newData
  };
  const data = {
    type: type,
    project: project?.[0]?.name || '',
    employeeType: employeeType || 'NULL',
    reason: reason || '',
    agent: [agent?.[0]?.displayName || '', agent?.[1]?.displayName || ''],
    agentTime: agentTime || '',
    agentShift: [agentShift?.[0]?.name || '', agentShift?.[1]?.name || ''],
    agentNewShift: [agentNewShift?.[0]?.name || '', agentNewShift?.[1]?.name || ''],
    agentDate: [getDate(agentDate?.[0]), getDate(agentDate?.[1])],
    agentNewDate: [getDate(agentNewDate?.[0]), getDate(agentNewDate?.[1])],
    owner: owner?.[0]?.displayName || '',
    ownerShift: ownerShift?.[0]?.name || '',
    ownerNewShift: ownerNewShift?.[0]?.name || '',
    ownerDate: getDate(ownerDate),
    ownerNewDate: getDate(ownerNewDate),
    ownerTime: ownerTime || ''
  };

  return (
    <Form>
      <Form.Row>
        <Form.Group
          as={Col}
          className={`${data.type === 'SWAP' ? '' : 'col-12 col-md-6'} mb-3`}
          controlId="type"
        >
          <Form.Label className="required">Request Type</Form.Label>
          <Form.Control type="text" value={data.type} disabled />
        </Form.Group>
        {(data.type === 'SWAP' || data.type === 'REPLACE') && (
          <Form.Group as={Col} className="mb-3" controlId="employeeType">
            <Form.Label className="required">User Type</Form.Label>
            <Form.Control type="text" value={data.employeeType} disabled />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3" controlId={`project`}>
          <Form.Label className="required">Project</Form.Label>
          <Form.Control type="text" value={data.project} disabled />
        </Form.Group>
      </Form.Row>
      {data.type === 'SWAP' && <ShiftChangeDetailSwap data={data} />}
      {data.type === 'COVER' && <ShiftChangeDetailCover data={data} />}
      {data.type === 'REPLACE' && <ShiftChangeDetailReplace data={data} />}
      {data.type === 'MOVE' && <ShiftChangeDetailMove data={data} />}
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="reason">
          <Form.Label className="required">Reason</Form.Label>
          <Form.Control
            type="text"
            placeholder="Reason"
            value={data.reason}
            name="reason"
            disabled
          />
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default ShiftChangeDetail;
