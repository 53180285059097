import { SpinnerButton } from 'components/Shared';
import { ButtonTypes } from 'models';
import { Button, Form, Modal } from 'react-bootstrap';
import { useButtonSaveFilter } from './useButtonSaveFilter';

const ButtonSaveFilter = () => {
  const { formik, isVisible, setIsVisible } = useButtonSaveFilter();
  return (
    <>
      <Button size="sm" variant="primary" onClick={() => setIsVisible(true)}>
        Save
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
        }}
        size="sm"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="text-start">Filter Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Personal Filter 1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={formik.isSubmitting}
            colorType="primary"
            onClick={formik.submitForm}
          >
            Save
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ButtonSaveFilter;
