import { DropdownAutoCloseOutside } from 'components/Shared';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from './FilterDropdown.styled';
import { useUserFilter } from './useUserFilter';
import { useEffect } from 'react';
import { useUserTypeFilter } from './useUserTypeFilter';

const UserTypeFilter = () => {
  const { userTypes, selectedUserType, onSelectUserType } = useUserTypeFilter();

  return (
    <DropdownAutoCloseOutside className="mr-2">
      <Dropdown.Toggle size="sm" variant="outline-primary">
        User Type
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        {userTypes.length > 0 &&
          userTypes.map((ut) => (
            <Dropdown.Item key={ut} as="li" onClick={(e) => onSelectUserType(e, ut)}>
              <FilterItem>
                <input
                  type="checkbox"
                  value={ut}
                  checked={selectedUserType.includes(ut)}
                  onChange={(e) => onSelectUserType(e, ut)}
                  onClick={(e) => {
                    console.log('XX event value', e);
                  }}
                />
                <FilterItemLabel>{ut}</FilterItemLabel>
              </FilterItem>
            </Dropdown.Item>
          ))}
        {userTypes.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};
export default UserTypeFilter;
