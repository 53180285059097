import styled from 'styled-components';

export const LoginFormWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginFormContainer = styled.div`
  width: 65%;
  max-width: 500px;
`;

export const LoginFormHeader = styled.h3`
  text-align: center;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const ForgotLink = styled.a`
  float: right;
  margin-top: 0.5rem;
`;
