import { FormikProps } from 'formik';
import { FixedShift, ShiftChangeFormProps, User } from 'models';
import { Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Calendar } from 'react-date-range';

interface Props {
  formik: FormikProps<ShiftChangeFormProps>;
  projectUsers: User[];
  shifts: FixedShift[];
  onDateChange: (field: string, item: any) => void;
  onSelectAgentDate: (field: string, index: number, item: any) => void;
  onSelectAgentShift: (index: number, e: any) => void;
}

const ShiftMoveBodyForm = (props: Props) => {
  const { formik, projectUsers, shifts, onSelectAgentDate, onSelectAgentShift, onDateChange } = {
    ...props
  };
  return (
    <>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`owner`}>
          <Form.Label className="required">Shift Owner</Form.Label>
          <Form.Control
            as="select"
            placeholder="Select Owner"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            name="owner"
            value={formik.values.owner}
            isInvalid={formik.touched.owner && !!formik.errors.owner}
          >
            <option value="">Select Owner</option>
            {projectUsers.map((user) => (
              <option key={user._id} value={user._id}>
                {user.username}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{formik.errors.owner}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`ownerShift`}>
          <Form.Label className="required">Current Shift</Form.Label>
          <Form.Control
            as="select"
            placeholder="Select Shift"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            name="ownerShift"
            value={formik.values.ownerShift}
            isInvalid={formik.touched.ownerShift && !!formik.errors.ownerShift}
          >
            <option value="">Select Shift</option>
            {shifts.map((shift) => (
              <option key={shift._id} value={shift._id}>
                {shift.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{formik.errors.ownerShift}</Form.Control.Feedback>
        </Form.Group>
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="top-end"
          overlay={
            <Popover
              id="popover-basic"
              style={{ maxWidth: '600px' }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Popover.Content style={{ padding: 0 }}>
                <Calendar
                  onChange={(item) => onDateChange('ownerDate', item)}
                  // date={parse(date, 'yyyy-MM-dd', new Date())}
                />
              </Popover.Content>
            </Popover>
          }
        >
          <Form.Group as={Col} className="mb-3" controlId="ownerDate">
            <Form.Label className="text-start required">Date effect</Form.Label>
            <Form.Control
              type="text"
              placeholder="Date"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.ownerDate}
              isInvalid={formik.touched.ownerDate && !!formik.errors.ownerDate}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.ownerDate}</Form.Control.Feedback>
          </Form.Group>
        </OverlayTrigger>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`agentShift[0]`}>
          <Form.Label className="required">New Shift</Form.Label>
          <Form.Control
            as="select"
            placeholder="Select Shift"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              onSelectAgentShift(0, e);
            }}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            name="agentShift[0]"
            value={formik.values.agentShift[0]}
            isInvalid={
              (formik.touched.agentShift as any)?.[0] &&
              (formik.errors.agentShift?.[0] ? formik.errors.agentShift[0] : undefined) !==
                undefined
            }
          >
            <option value="">Select Shift</option>
            {shifts.map((shift) => (
              <option key={shift._id} value={shift._id}>
                {shift.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {formik.errors.agentShift?.[0] ? formik.errors.agentShift[0] : undefined}
          </Form.Control.Feedback>
        </Form.Group>

        <OverlayTrigger
          rootClose
          trigger="click"
          placement="top-end"
          overlay={
            <Popover
              id="popover-basic"
              style={{ maxWidth: '600px' }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Popover.Content style={{ padding: 0 }}>
                <Calendar
                  onChange={(item) => onSelectAgentDate('agentDate[0]', 0, item)}
                  // date={parse(date, 'yyyy-MM-dd', new Date())}
                />
              </Popover.Content>
            </Popover>
          }
        >
          <Form.Group as={Col} className="mb-3" controlId="agentDate[0]">
            <Form.Label className="text-start required">Date effect</Form.Label>
            <Form.Control
              type="text"
              placeholder="Date"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.agentDate[0]}
              isInvalid={
                (formik.touched.agentDate as any)?.[0] &&
                (formik.errors.agentDate?.[0] ? formik.errors.agentDate[0] : undefined) !==
                  undefined
              }
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.agentDate?.[0] ? formik.errors.agentDate[0] : undefined}
            </Form.Control.Feedback>
          </Form.Group>
        </OverlayTrigger>
      </Form.Row>
    </>
  );
};

export default ShiftMoveBodyForm;
