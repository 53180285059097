import styled from 'styled-components';

export const AlphabetFilter = styled.div`
  padding: 0.25rem 0.25rem 0.25rem 0;
  // background: #fafafa;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const AlphabetFilterLabel = styled.span`
  margin-right: 0.25rem;
`;

export const Alphabet = styled.span`
  color: #5e72e4;
  padding-right: 0.5rem;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;

export const AlphabetWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
