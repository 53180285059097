import { UserSlot } from 'models';
import { useState } from 'react';
import { ButtonShowDetail } from './useShowDetail.styled';

export const useShowDetail = (userSlots: UserSlot[]) => {
  const [showDetail, setShowDetail] = useState(false);

  const ShowMoreButton = () => {
    const icon = showDetail ? 'fa-angles-up' : 'fa-angles-down';
    if (userSlots.length > 2) {
      return (
        <>
          <ButtonShowDetail onClick={() => setShowDetail(!showDetail)}>
            <i className={`fas ${icon} mr-1`} />
            {!showDetail ? `Show all (${userSlots.length})` : 'Show less'}
          </ButtonShowDetail>
        </>
      );
    }
    return <></>;
  };

  return { showDetail, setShowDetail, ShowMoreButton };
};
