import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Division,
  RemoveProjectFromUserPayload,
  User,
  UserProject,
  UserStatus,
  UserStatusVariant
} from 'models';
import React, { useEffect, useState } from 'react';
import { Badge, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';
import UserBulkRestore from '../UserBulkRestore/UserBulkRestore';
import {
  ButtonAction,
  ButtonRemove,
  ProjectName,
  UserCell,
  UserProjectDivisionTable,
  UserRow
} from './UserListItem.styled';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { useAuth } from 'hooks';

interface Props {
  user: User;
  selectedUsers: User[];
  setSelectedUsers: (users: User[]) => void;
  getUsers: () => void;
  resetPage: () => void;
}

const UserListItem = (props: Props) => {
  const { user, selectedUsers, setSelectedUsers, getUsers, resetPage } = { ...props };

  const dispatch = useAppDispatch();
  const currentUser = useAuth();
  const isUpdating = useAppSelector(userSelector.selectIsUpdatingStatus);
  const isRemoving = useAppSelector(userSelector.selectIsRemovingProject);
  const error = useAppSelector(userSelector.selectError);

  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const onRemove = (projectId: string) => {
    const payload: RemoveProjectFromUserPayload = {
      id: user._id,
      projectId: projectId
    };
    dispatch(userActions.removeProjectFromUser(payload));
    return;
  };

  const getStatusVariant = (u: User) => {
    return UserStatusVariant[u.status as keyof typeof UserStatusVariant];
  };

  const onChangeStatus = (status: string) => {
    if (status !== 'DELETED') {
      dispatch(userActions.updateStatusUser({ id: user._id, status: status }));
    } else {
      dispatch(userActions.deleteUsers([user._id]));
    }
  };

  const onChange = (e: any) => {
    const _users = [...selectedUsers];
    if (e.target.checked) {
      _users.push(user);
    } else {
      const index = _users.findIndex((u) => u._id === user._id);
      _users.splice(index, 1);
    }
    setSelectedUsers(_users);
  };

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
    }
  }, [error]);

  useDidMountEffect(() => {
    if (error === null && !isUpdating) {
      toast.success('Successfully Updated Status.');
    }
  }, [isUpdating]);

  useDidMountEffect(() => {
    if (error === null && !isRemoving) {
      toast.success('Successfully Removed Project From User.');
    }
  }, [isRemoving]);

  const inputSelectUser = () => {
    return (
      <div className="custom-control custom-checkbox mb-3">
        <input
          className="custom-control-input"
          type="checkbox"
          value={user._id}
          id={user._id}
          checked={selectedUsers.find((u) => user._id === u._id) !== undefined}
          onChange={onChange}
        />
        <label className="custom-control-label" htmlFor={user._id} />
      </div>
    );
  };

  const userProjectDivisions = () => {
    return (
      <UserProjectDivisionTable>
        <tbody>
          {user.projects.map((p: UserProject, index: number) => (
            <UserRow key={index}>
              <>
                <UserCell className="p-0 border-top-0 pr-4 pb-3" width={150}>
                  <div className="mb-3">
                    <ProjectName pill variant="primary">
                      {p.project.name}
                      <ButtonRemove icon={solid('xmark')} onClick={() => onRemove(p.project._id)} />
                    </ProjectName>
                  </div>
                </UserCell>
                <UserCell className="p-0 border-top-0 pb-3 pl-4" width={150}>
                  {p.divisions &&
                    p.divisions.map((division: Division) => (
                      <div className="mb-1" key={division._id}>
                        <Badge pill variant="primary" key={division._id}>
                          {division.name}
                        </Badge>
                      </div>
                    ))}
                </UserCell>
              </>
            </UserRow>
          ))}
        </tbody>
      </UserProjectDivisionTable>
    );
  };

  const actions = () => {
    return [
      {
        action: 'Edit',
        variant: 'primary',
        onClick: (e: any) => {
          dispatch(userActions.setSelectedUser(user));
          dispatch(userActions.setIsModalUpdateVisible(true));
          e.stopPropagation();
        }
      },
      {
        action: 'Reset Password',
        variant: 'primary',
        onClick: (e: any) => {
          dispatch(userActions.setSelectedUser(user));
          dispatch(userActions.setIsModalResetPasswordVisible(true));
          e.stopPropagation();
        }
      },
      {
        action: 'Delete',
        variant: 'danger',
        onClick: (e: any) => {
          dispatch(userActions.setSelectedUser(user));
          dispatch(userActions.setIsModalDeleteVisible(true));
          e.stopPropagation();
        }
      }
    ];
  };

  return (
    <UserRow key={user._id}>
      <UserCell width={20}>{inputSelectUser()}</UserCell>
      <UserCell width={100}>{user.employeeCode}</UserCell>
      <UserCell width={110}>{user.username}</UserCell>
      <UserCell width={100}>{user.displayName}</UserCell>
      <UserCell width={160}>
        <div>{user.fullName}</div>
        <div>
          <Badge className="mr-1" variant="warning">
            {user.role}
          </Badge>
          <Badge variant="warning">{user.userType}</Badge>
        </div>
      </UserCell>
      <UserCell width={120}>{user.phone}</UserCell>
      <UserCell width={180}>{user.email}</UserCell>
      <UserCell width={180}>{user.personalEmail}</UserCell>
      <UserCell width={300}>{userProjectDivisions()}</UserCell>
      <UserCell width={110}>
        {currentUser.role === 'MANAGER' ? (
          <>
            {user.deleted ? (
              <Badge variant="danger">DELETED</Badge>
            ) : (
              <OverlayTrigger
                rootClose={true}
                trigger="click"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Content className="p-0">
                      {Object.keys(UserStatus).map((status) => (
                        <Dropdown.Item
                          key={status}
                          onClick={() => {
                            onChangeStatus(status);
                          }}
                        >
                          <Badge
                            variant={UserStatusVariant[status as keyof typeof UserStatusVariant]}
                          >
                            {status}
                          </Badge>
                        </Dropdown.Item>
                      ))}
                    </Popover.Content>
                  </Popover>
                }
              >
                <Dropdown.Toggle as={Badge} variant={getStatusVariant(user)} id="dropdown-basic">
                  {user.status}
                </Dropdown.Toggle>
              </OverlayTrigger>
            )}
          </>
        ) : (
          <Badge variant={getStatusVariant(user)}>{user.status}</Badge>
        )}
      </UserCell>
      {currentUser.role === 'MANAGER' && (
        <UserCell width={90}>
          {!user.deleted && (
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Content className="p-0">
                    {actions().map((action) => (
                      <Dropdown.Item key={action.action} onClick={(e) => action.onClick(e)}>
                        <ButtonAction
                          className={`text-${action.variant}`}
                          onClick={(e) => action.onClick(e)}
                        >
                          {action.action}
                        </ButtonAction>
                      </Dropdown.Item>
                    ))}
                  </Popover.Content>
                </Popover>
              }
            >
              <Dropdown.Toggle
                className="btn-icon-only text-light btn btn- btn-sm bg-transparent border-0 mr-0"
                style={{ boxShadow: 'none' }}
                bsPrefix="test"
              >
                <i className="fas fa-ellipsis-v" />
              </Dropdown.Toggle>
            </OverlayTrigger>
          )}
          {user.deleted && (
            <UserBulkRestore
              selectedUsers={[user]}
              resetPage={resetPage}
              getUsers={getUsers}
              resetSelectedUsers={() => {
                return;
              }}
              type="user"
            />
          )}
        </UserCell>
      )}
    </UserRow>
  );
};

export default UserListItem;
