import {
  isSameDay,
  startOfISOWeek,
  endOfISOWeek,
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
  addMonths
} from 'date-fns';
import { createStaticRanges } from 'react-date-range';

export const defineds = {
  startOfWeek: startOfISOWeek(new Date()),
  endOfWeek: endOfISOWeek(new Date()),
  startOfLastWeek: startOfISOWeek(subDays(new Date(), 7)),
  endOfLastWeek: endOfISOWeek(subDays(new Date(), 7)),
  startOfNextWeek: startOfISOWeek(addDays(new Date(), 7)),
  endOfNextWeek: endOfISOWeek(addDays(new Date(), 7)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1))
};

export const staticRanges = createStaticRanges([
  {
    label: 'Last week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, defineds.startOfLastWeek) &&
          isSameDay(range.endDate, defineds.endOfLastWeek)
        );
      }
      return false;
    }
  },
  {
    label: 'This week',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range?.startDate, defineds.startOfWeek) &&
          isSameDay(range?.endDate, defineds.endOfWeek)
        );
      }
      return false;
    }
  },
  {
    label: 'Next week',
    range: () => ({
      startDate: defineds.startOfNextWeek,
      endDate: defineds.endOfNextWeek,
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range?.startDate, defineds.startOfNextWeek) &&
          isSameDay(range?.endDate, defineds.endOfNextWeek)
        );
      }
      return false;
    }
  },
  {
    label: 'Last month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range?.startDate, defineds.startOfLastMonth) &&
          isSameDay(range?.endDate, defineds.endOfLastMonth)
        );
      }
      return false;
    }
  },
  {
    label: 'This month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range?.startDate, defineds.startOfMonth) &&
          isSameDay(range?.endDate, defineds.endOfMonth)
        );
      }
      return false;
    }
  },
  {
    label: 'Next month',
    range: () => ({
      startDate: defineds.startOfNextMonth,
      endDate: defineds.endOfNextMonth,
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range?.startDate, defineds.startOfNextMonth) &&
          isSameDay(range?.endDate, defineds.endOfNextMonth)
        );
      }
      return false;
    }
  }
]);
