import Scheduler from 'components/SchedulerFix/Scheduler';
import { FixedShift, Project } from 'models';
import { useEffect } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { filterActions } from 'store/filters/filter.slice';
import { fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';

const ScheduleFixPage = () => {
  const projects: Project[] = useAppSelector(projectSelector.selectProjects);
  const isFetchingProjects: boolean = useAppSelector(projectSelector.selectIsFetching);
  const shifts: FixedShift[] = useAppSelector(fixedShiftSelector.selectShifts);
  const indexArrayFixedSlot: number[] = useAppSelector(
    fixedShiftSelector.selectHighestIndexFixedSlot
  );
  const isFetchingShifts: boolean = useAppSelector(fixedShiftSelector.selectIsFetching);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(filterActions.getPersonalFilters(undefined));
    dispatch(projectActions.getProjects());
  }, []);

  useEffect(() => {
    dispatch(projectActions.setIsModalUpdateProjectVisible(false));
  }, [isFetchingProjects]);
  return (
    <Card className="shadow">
      {isFetchingProjects ? (
        <div className="text-center p-5">
          <Spinner animation="border" variant="primary" className="text-center" />
        </div>
      ) : (
        <Scheduler
          projects={projects}
          shifts={shifts}
          isFetchingShifts={isFetchingShifts}
          indexArrayFixedSlot={indexArrayFixedSlot}
        />
      )}
    </Card>
  );
};

export default ScheduleFixPage;
