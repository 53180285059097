import { FixedShift } from 'models';
import { Item, Menu, Separator } from 'react-contexify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fixedSlotActions, fixedSlotSelector } from 'store/fixedSlots/fixedSlot.slice';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  draggingItem: any;
  droppedItem: any;
  shifts: FixedShift[];
  fixedSlotsIndex: number;
  isAddingMoreIndex: boolean;
}

const MenuUpdateSlot = (props: Props) => {
  const dispatch = useAppDispatch();
  const error: any = useAppSelector(fixedSlotSelector.selectError);
  const isLoading = useAppSelector(fixedSlotSelector.selectIsLoading);
  // const { show, shiftId, date, onHide } = props;
  const { draggingItem, droppedItem, shifts, fixedSlotsIndex, isAddingMoreIndex } = props;

  const handleDragAndDrop = (type: string) => {
    if (type === 'cancel') return;
    dispatch(
      fixedSlotActions.updateSlot({
        droppedItem: droppedItem,
        draggingItem: draggingItem,
        type: type,
        index: fixedSlotsIndex
      })
    );
  };

  return (
    <Menu id={'DRAG_AND_DROP'} theme="light">
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id={`tooltip-auto`} className="test">
            Dragging item will be 'created' in dropped item's position, and the dragging item will
            be kept in old position.
          </Tooltip>
        }
      >
        <Item onClick={() => handleDragAndDrop('paste')}>Paste</Item>
      </OverlayTrigger>
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id={`tooltip-auto`} className="test">
            Dropped item will be 'replaced' by dragging item, and the dragging item will be
            'removed' in old position
          </Tooltip>
        }
      >
        <Item onClick={() => handleDragAndDrop('replace')}>Replace</Item>
      </OverlayTrigger>
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id={`tooltip-auto`} className="test">
            Position of dragging and dropped item will be 'swapped'
          </Tooltip>
        }
      >
        <Item onClick={() => handleDragAndDrop('swap')}>Swap</Item>
      </OverlayTrigger>
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id={`tooltip-auto`} className="test">
            Dragging item will be 'created' in dropped item's position, and the dragging item will
            be 'removed' in old position
          </Tooltip>
        }
      >
        <Item onClick={() => handleDragAndDrop('merge')}>Merge</Item>
      </OverlayTrigger>
      <Item onClick={() => handleDragAndDrop('cancel')}>Cancel</Item>
    </Menu>
  );
};

export default MenuUpdateSlot;
