import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { addHours, addMinutes, startOfDay } from 'date-fns';
import {
  Attendance,
  AttendanceCheckInPayload,
  AttendanceCheckoutPayload,
  AttendanceCoveringPayload,
  FixedShift,
  GetAttendancesPayload,
  User
} from 'models';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { attendanceApi } from 'services/index';
import { authSelector } from 'store/auth/auth.slice';
import { fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { attendanceActions } from './attendance.slice';
import { toast } from 'react-toastify';

export function* checkinWorker(payload: PayloadAction<AttendanceCheckInPayload>) {
  try {
    const response: AxiosResponse<any> = yield call(attendanceApi.checkin, payload.payload);
    const currentUser: User = yield select(authSelector.selectCurrentUser);
    const role = currentUser.role === 'MANAGER' ? 'managers' : 'agents';
    const shifts: FixedShift[] = yield select(fixedShiftSelector.selectShifts);
    const today = startOfDay(new Date());
    const endTime = shifts.find((shift) => shift._id === payload.payload.shift)?.endTime.split(':');
    let endTimeHour = 0;
    let endTimeMinute = 0;
    if (endTime && endTime.length > 0) {
      endTimeHour = parseInt(endTime[0] === '00' ? '24' : endTime[0]) || 0;
      endTimeMinute = parseInt(endTime[1]) || 0;
    }
    let timeToDisplay: Date;
    timeToDisplay = addHours(today, endTimeHour);
    timeToDisplay = addMinutes(timeToDisplay, endTimeMinute);
    yield put(attendanceActions.checkinSuccess({}));
  } catch (e: any) {
    console.log('checkinWorker Error: ', e);
    toast.error('Check-in Error ' + e.error + ' - ' + e.message);

    yield put(attendanceActions.checkinFailed(e));
  }
}

export function* coveringWorker(payload: PayloadAction<AttendanceCoveringPayload>) {
  try {
    const response: AxiosResponse<any> = yield call(attendanceApi.covering, payload.payload);
    const currentUser: User = yield select(authSelector.selectCurrentUser);
    const role = currentUser.role === 'MANAGER' ? 'managers' : 'agents';
    yield put(attendanceActions.coveringSuccess({}));
  } catch (e) {
    console.log('coveringWorker Error: ', e);
    yield put(attendanceActions.coveringFailed(e));
  }
}

export function* getAttendancesWorker({ payload }: PayloadAction<GetAttendancesPayload>) {
  try {
    const response: Attendance[] = yield call(attendanceApi.getAttendances, payload);
    yield put(attendanceActions.getAttendancesSuccess(response));
  } catch (e) {
    console.log('getAttendancesWorker Error: ', e);
    yield put(attendanceActions.getAttendancesFailed(e));
  }
}

export function* checkoutWorker(payload: PayloadAction<AttendanceCheckoutPayload>) {
  try {
    const response: AxiosResponse<any> = yield call(attendanceApi.checkout, payload.payload);
    yield put(attendanceActions.checkoutSuccess({}));
  } catch (e) {
    console.log('coveringWorker Error: ', e);
    yield put(attendanceActions.checkoutFailed(e));
  }
}

export function* resetToInitWorker() {
  try {
    yield put(attendanceActions.resetToInitSuccess());
  } catch (e) {
    console.log('resetToInitWorker Error: ', e);
    yield put(attendanceActions.resetToInitFailed(e));
  }
}

export default function* divisionSaga() {
  yield takeLatest(attendanceActions.getAttendances, getAttendancesWorker);
  yield takeLatest(attendanceActions.checkin, checkinWorker);
  yield takeLatest(attendanceActions.covering, coveringWorker);
  yield takeLatest(attendanceActions.checkout, checkoutWorker);
  yield takeLatest(attendanceActions.resetToInit, resetToInitWorker);
}
