export type Login = {
  username: string;
  password: string;
};

export type LoginResponse = {
  accessToken: string;
  twoFAEnabled: boolean;
  dataURL: string;
  secret: string;
};

export enum LoginStep {
  LOGIN = 1,
  VERIFY_CODE = 2
}
