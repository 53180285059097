import { addDays, addHours, parse, startOfDay } from 'date-fns';
import { ShiftHelper, SlotValidatorHelper, UserHelper, UserSlotHelper } from 'helpers/index';
import cloneDeep from 'lodash.clonedeep';
import { FixedShift, FixedSlot, Shift, Slot, User, UserSlot } from 'models';
enum ErrorTypes {
  EXCESS_HOURS = 'excess_hours' as any,
  NOT_ENOUGH_HOURS = 'not_enough_hours' as any,
  INVALID_AGENT = 'invalid_agent' as any
}

const ErrorMessages = {
  [ErrorTypes.EXCESS_HOURS]: 'Excess hours added',
  [ErrorTypes.NOT_ENOUGH_HOURS]: 'Not enough hours added',
  [ErrorTypes.INVALID_AGENT]: 'Agent with excess hours detected'
};

interface Props {
  show: boolean;
  shifts: (Shift | FixedShift)[];
  users: User[];
  shiftId: string;

  date: string | number;
  values: any;
  onHide: () => void;
  onSubmit: () => void;
}

export class AssignSlotValidatorHelper {
  public static getErrors = (shifts: (Shift | FixedShift)[], props: Props) => {
    const errors = {
      [ErrorTypes.EXCESS_HOURS]: [] as any,
      [ErrorTypes.NOT_ENOUGH_HOURS]: [] as any,
      [ErrorTypes.INVALID_AGENT]: [] as any
    };
    const duration = ShiftHelper.getShiftDuration(props.shifts, props.shiftId);
    props.values.employees.map((agentCount: [UserSlot], index: number) => {
      // agentCount is slot (slot is parent of userslot);
      // get hours of userSlots in agentCount
      const userSlots = this.getUserSlots(index, props.values);
      const totalHours = UserSlotHelper.getTotalHours(userSlots);
      if (totalHours > duration) {
        const invalidUsers = userSlots.map((userSlot: UserSlot) => {
          return `${UserHelper.findUsername(userSlot.userId, props.users)} (${userSlot.duration})`;
        });
        errors[ErrorTypes.EXCESS_HOURS].push(invalidUsers);
      } else if (totalHours < duration) {
        const invalidUsers = userSlots.map((userSlot: UserSlot) => {
          return `${UserHelper.findUsername(userSlot.userId, props.users)} (${userSlot.duration})`;
        });
        errors[ErrorTypes.NOT_ENOUGH_HOURS].push(invalidUsers);
      }

      agentCount.map((userSlot: UserSlot) => {
        if (userSlot.userId === '' || userSlot.userId === 'multiple') {
          return;
        }
        const agentError = [];
        if (this.assignedMoreThan2Shift(userSlot.userId, props.shifts, props.date, props.values)) {
          agentError.push(`assigned more than 2 shifts on ${props.date}`);
        }
        if (this.assignedMoreThan12Hours(userSlot.userId, props.shifts, props.date, props.values)) {
          agentError.push(`assigned more than 12 hours on ${props.date}`);
        }
        const { hoursOfNext24Hrs, hoursOfPrev24Hrs } = {
          ...this.getHoursWithin24Hours(userSlot.userId, props.shifts, props.values)
        };
        if (hoursOfPrev24Hrs && hoursOfPrev24Hrs > 12) {
          agentError.push(`already assigned ${hoursOfPrev24Hrs} hours within last 24 hours`);
        }
        if (hoursOfNext24Hrs && hoursOfNext24Hrs > 12) {
          agentError.push(`already assigned ${hoursOfNext24Hrs} hours within next 24 hours`);
        }
        if (agentError.length) {
          errors[ErrorTypes.INVALID_AGENT].push({
            [`${UserHelper.findUsername(userSlot.userId, props.users)}`]: agentError
          });
        }
      });
    });
    return errors;
  };

  public static assignedMoreThan12Hours = (
    userId: string,
    shifts: (Shift | FixedShift)[],
    date: string | number,
    values: any
  ) => {
    let hours = 0;
    const notSelectedShifts = shifts.filter((shift) => shift._id !== values.shiftId);
    notSelectedShifts.forEach((shift) => {
      const slots = ShiftHelper.findSlotSameDate(shift, date);
      slots.forEach((slot) => {
        const userSlots = slot.userSlots.filter((userSlot) => userSlot.user._id === userId);
        hours += UserSlotHelper.getTotalHours(userSlots);
      });
    });
    const userSlotsInForm = cloneDeep(values.employees)
      .reduce((a: any, b: any) => {
        return a.concat(b);
      })
      .filter((userSlot: UserSlot) => userSlot.userId === userId);
    const hoursInForm = UserSlotHelper.getTotalHours(userSlotsInForm);
    return hours + hoursInForm > 12;
  };

  public static assignedMoreThan2Shift = (
    userId: string,
    shifts: (Shift | FixedShift)[],
    date: string | number,
    values: any
  ) => {
    const shiftIncludingUser: string[] = [];
    // current shift is having the user need to be validate
    // so remove current shift to impro perf
    const _shifts = [...shifts.filter((shift) => shift._id !== values.shiftId)];
    _shifts.forEach((shift) => {
      const slots = ShiftHelper.findSlotSameDate(shift, date);
      let userIncluded = false;
      slots.forEach((slot) => {
        if (slot.userSlots.find((userSlot) => userSlot.user._id === userId)) {
          userIncluded = true;
          return;
        }
      });
      if (userIncluded) {
        shiftIncludingUser.push(shift._id);
        return;
      }
    });
    return shiftIncludingUser.length >= 2;
  };

  public static getHoursWithin24Hours = (
    userId: string,
    shifts: (Shift | FixedShift)[],
    values: any
  ) => {
    const selectedShift = shifts.find((shift) => shift._id === values.shiftId);
    if (selectedShift) {
      const selectedDate = values.date; // date selected in form
      const endTime = selectedShift.endTime === '00:00' ? '24:00' : selectedShift.endTime;
      const selectedDateTime = parse(`${selectedDate} ${endTime}`, 'yyyy-MM-dd HH:mm', new Date());

      const next24HrsDateTime = addDays(selectedDateTime, 1);
      const prev24HrsDateTime = addDays(selectedDateTime, -1);

      const nextSlots: (Slot | FixedSlot)[] = [];
      const prevSlots: (Slot | FixedSlot)[] = [];

      if (values.date !== undefined) {
        (shifts as Shift[]).forEach((shift) => {
          const endTimeInt = shift.endTime === '00:00' ? 24 : parseInt(shift.endTime.split(':')[0]);
          shift.slots.forEach((slot) => {
            const dateSlot = startOfDay(new Date(slot.date));
            const datetime = addHours(dateSlot, endTimeInt);
            if (datetime > selectedDateTime && datetime <= next24HrsDateTime) {
              nextSlots.push(slot);
              return;
            }
            if (datetime < selectedDateTime && datetime >= prev24HrsDateTime) {
              prevSlots.push(slot);
            }
          });
        });
      } else {
        (shifts as FixedShift[]).forEach((shift) => {
          const currentEndTime = parseInt(endTime);
          const endTimeInt = shift.endTime === '00:00' ? 24 : parseInt(shift.endTime.split(':')[0]);
          shift.slots.forEach((slot) => {
            if (
              (slot.weekday == values.weekday && endTimeInt > currentEndTime) ||
              (slot.weekday == values.weekday + 1 && endTimeInt <= currentEndTime)
            ) {
              nextSlots.push(slot);
              return;
            }
            if (
              (slot.weekday == values.weekday && endTimeInt < currentEndTime) ||
              (slot.weekday == values.weekday - 1 && endTimeInt >= currentEndTime)
            ) {
              prevSlots.push(slot);
            }
          });
        });
      }
      // get hours next and prev day (excepted hours of agents assigned in current shift)
      let hoursOfNext24Hrs: number = this.getHoursAgentAssigned(nextSlots, userId);
      let hoursOfPrev24Hrs: number = this.getHoursAgentAssigned(prevSlots, userId);
      let userSlotsInForm: UserSlot[] = [];
      // get hours in form
      values.employees.map((slot: any) => slot.map((obj: any) => userSlotsInForm.push(obj)));
      userSlotsInForm = userSlotsInForm.filter((userSlot) => userSlot.userId === userId);
      const hoursInForm: number = userSlotsInForm.reduce((a: any, b: any) => {
        return (parseFloat(a.toString()) || 0) + (parseFloat(b?.duration?.toString()) || 0);
      }, 0);

      hoursOfNext24Hrs =
        parseFloat(hoursOfNext24Hrs.toString()) + parseFloat(hoursInForm.toString());
      hoursOfPrev24Hrs =
        parseFloat(hoursOfPrev24Hrs.toString()) + parseFloat(hoursInForm.toString());
      return { hoursOfNext24Hrs, hoursOfPrev24Hrs };
    }
  };

  public static getHoursAgentAssigned = (slots: (Slot | FixedSlot)[], userId: string): number => {
    let hours = 0;
    slots.map((slot: Slot | FixedSlot) => {
      const userSlots = slot.userSlots.filter((userSlot) => {
        return userSlot.user._id === userId;
      });
      hours += UserSlotHelper.getTotalHours(userSlots);
    });
    return hours;
  };

  public static getUserSlots(agentIndex: number, values: any) {
    return values.employees[agentIndex].filter((v: UserSlot) => v.userId !== 'multiple');
  }

  public static hasInvalidAgent = (
    agentIndex: number,
    shifts: (Shift | FixedShift)[],
    date: string | number,
    values: any
  ) => {
    const userSlots: UserSlot[] = this.getUserSlots(agentIndex, values);
    let res = false;
    userSlots.forEach((userSlot) => {
      res = this.isAgentInvalid(userSlot.userId, shifts, date, values);
    });
    return res;
  };

  public static isAgentInvalid = (
    userId: string,
    shifts: (Shift | FixedShift)[],
    date: string | number,
    values: any
  ) => {
    return (
      // isAssignedMoreThan2Shift(userId, shifts, date, values) ||
      // isAssignedMoreThan12Hours(userId, shifts, date, values) ||
      this.assignedMoreThan12Hours(userId, shifts, date, values)
    );
  };

  public static getTotalHours(agentIndex: number, values: any) {
    const userSlots = this.getUserSlots(agentIndex, values);
    return UserSlotHelper.getTotalHours(userSlots);
  }

  public static isTotalHoursInvalid = (
    agentIndex: number,
    shifts: (Shift | FixedShift)[],
    shiftId: string,
    values: any
  ) => {
    return this.getTotalHours(agentIndex, values) !== ShiftHelper.getShiftDuration(shifts, shiftId);
  };
}
