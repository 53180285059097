import { Filter } from 'models';
import { Nullable } from 'utils/common/types';

export type SchedulerFixInitialState = {
  isModalCreateSlotVisible: boolean;
  selectedShiftId: string;
  selectedDate: number;
  isApplyingFilter: boolean;
  type: string;
  personalFilters: Filter[];
  error: Nullable<any>;
  isLoadingFilters: boolean;
};

export const initialState: SchedulerFixInitialState = {
  isModalCreateSlotVisible: false,
  selectedShiftId: '',
  selectedDate: 1,
  isApplyingFilter: false,
  type: 'actual',
  personalFilters: [],
  error: null,
  isLoadingFilters: false
};
