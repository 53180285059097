import { FormikProps, useFormik } from 'formik';
import { ShiftHelper } from 'helpers';
import { FixedShift, FixedSlot, UserSlot } from 'models';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fixedSlotActions, fixedSlotSelector } from 'store/fixedSlots/fixedSlot.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { schedulerFixActions, schedulerFixSelector } from 'store/schedulerFixs/schedulerFix.slice';
import { fixedSlotSchema } from 'utils/validators';

interface Props {
  show: boolean;
  shifts: FixedShift[];
  date: number;
  onHide: () => void;
  fixedSlotsIndex: number;
}

type FormEmployeesProp = Array<
  Array<Omit<UserSlot, 'duration'> & { duration: string; divisionId?: string }>
>;

// type FormEmployee
interface FormProps {
  weekday: number;
  shiftId: string;
  employees: FormEmployeesProp;
}

export const useModalCreateSlot = (props: Props) => {
  const dispatch = useAppDispatch();
  const error: any = useAppSelector(fixedSlotSelector.selectError);
  const isLoading = useAppSelector(fixedSlotSelector.selectIsLoading);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [modalConfirmEmptyVisible, setModalConfirmEmptyVisible] = useState(false);
  const [isShowConfirmChangeModal, setIsShowConfirmChangeModal] = useState(false);
  const [submittingType, setSubmittingType] = useState('');
  const [isBeingProceed, setIsBeingProceed] = useState(false);
  const [changingValue, setChangingValue] = useState({});
  const { date } = props;
  const shiftId = useAppSelector(schedulerFixSelector.selectSelectedShiftId);
  const project = useAppSelector(projectSelector.selectSelectedProject);
  const [isAddingMoreScheduleFixedIndex, setIsAddingMoreScheduleFixedIndex] = useState(false);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (error === null && !isLoading && formik.isSubmitting) {
      toast.success('Successfully Created.');
      formik.setSubmitting(false);
      submittingType === 'submit' ? handleSubmitSuccess() : handleApplySuccess();
    }
  }, [isLoading]);

  const handleSubmitSuccess = () => {
    props.onHide();
  };

  const setScheduleFixedIndex = (value: boolean) => {
    setIsAddingMoreScheduleFixedIndex(value);
  };

  const handleApplySuccess = () => {
    setIsShowConfirmModal(false);
    if (!isBeingProceed) {
      formik.resetForm({ values: { ...formik.values } });
      return;
    }
    const _emplyoees = getInitialEmployees(date, formik.values.shiftId) as FormEmployeesProp;
    const _shiftId = ((isChangingShift() ? changingValueRes : shiftId) as string) || '';
    resetForm(date, _shiftId, _emplyoees);
    setIsBeingProceed(false);
  };

  const getShiftDuration = (_shiftId: string) => {
    return ShiftHelper.getShiftDuration(props.shifts, _shiftId);
  };

  const getInitialEmployees = (date: number, shiftId: string) => {
    const shift = ShiftHelper.findById(props.shifts, shiftId) as FixedShift | undefined;
    if (!shift) return;

    const existingSlots = mapExistingSlots(shift);
    const missingSlots = mapMissingSlots(shift, existingSlots.length);
    return [...existingSlots, ...missingSlots];
  };

  const mapMissingSlots = (shift: FixedShift, existingSlotsLength: number) => {
    const agentCount = shift?.numOfAgents || 0;
    const missingSlots: any[] = [];
    for (let i = 0; i < agentCount - existingSlotsLength; i++) {
      missingSlots.push([createEmptySlot(shift._id)]);
    }
    return missingSlots;
  };

  const mapExistingSlots = (shift: FixedShift) => {
    const existingSlots: FixedSlot[] = ShiftHelper.findSlotSameDate(shift, date) as FixedSlot[];

    return existingSlots.map((slot) => {
      const isSingleSlot = slot.userSlots.length === 1;
      return isSingleSlot ? [mapUserSlot(slot, slot.userSlots[0])] : handleMultiUserSlot(slot);
    });
  };

  const createEmptySlot = (shiftId: string) => {
    return {
      userId: '',
      duration: `${getShiftDuration(shiftId)}`,
      divisionId: '',
      note: '',
      index: 1
    };
  };

  const handleMultiUserSlot = (slot: FixedSlot) => {
    const res = [];
    res.push({
      userId: 'multiple',
      divisionId: slot?.division?.[0]?._id || (slot?.division as any)?._id,
      duration: `${getShiftDuration(shiftId)}`,
      note: '',
      index: props.fixedSlotsIndex
    });
    return [...res, ...slot.userSlots.map((userSlot) => mapUserSlot(slot, userSlot))];
  };

  const mapUserSlot = (slot: FixedSlot, userSlot: UserSlot) => {
    const { user, duration, note } = userSlot;
    const { division } = { ...slot };
    return {
      userId: user._id,
      duration: duration,
      note: note,
      divisionId: division?.[0]?._id || (division as any)?._id,
      user: user,
      index: props.fixedSlotsIndex
    };
  };

  const initialValues: FormProps = {
    weekday: date,
    shiftId: shiftId,
    employees: getInitialEmployees(date, shiftId) as FormEmployeesProp
  };

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: initialValues,
    validationSchema: fixedSlotSchema,
    onSubmit: (values: FormProps) => {
      handleSubmit(values);
    }
  });

  const onDateChange = (e: any) => {
    const weekday = parseInt(e.target.value);
    if (allowChangeWithoutConfirm()) {
      setSelectedDate(weekday);
      const employees = getInitialEmployees(weekday, formik.values.shiftId);
      resetForm(weekday, formik.values.shiftId, employees as FormEmployeesProp);
      return;
    }
    handleChangeWithConfirm({ date: weekday });
  };

  const onChangeShift = (e: any) => {
    if (allowChangeWithoutConfirm()) {
      setSelectedShiftId(e.target.value);
      resetForm(
        date,
        e.target.value,
        getInitialEmployees(formik.values.weekday, e.target.value) as FormEmployeesProp
      );
      return;
    }
    handleChangeWithConfirm({ shiftId: e.target.value });
  };

  const allowChangeWithoutConfirm = () => {
    const _employees = formik.values.employees;
    const _initialEmployees = formik.initialValues.employees;
    // return true if form values hasn't different with initialValues of just change duration of initialValues
    return _employees?.[0]?.[0].userId === '' || _initialEmployees === _employees;
  };

  const handleChangeWithConfirm = (value: object) => {
    setIsShowConfirmChangeModal(true);
    setChangingValue(value);
  };

  const handleSubmit = (values: FormProps) => {
    const shift = props.shifts.find((shift: FixedShift) => {
      return shift._id === values.shiftId;
    });

    if (!shift) return;

    dispatch(fixedSlotActions.createSlot(getCreateSlotPayload(values, shift)));
  };

  const getCreateSlotPayload = (values: FormProps, shift: FixedShift) => {
    const createdSlotIds = ShiftHelper.findSlotSameDate(shift, date).map((slot) => slot._id) || [];
    const { shiftId, employees } = { ...values };

    return {
      shiftId: shiftId,
      payload: {
        weekday: values.weekday,
        division: employees.map((e) => e[0].divisionId) || '',
        employees: employees.map((v) => {
          return v
            .filter((item) => item.userId !== 'multiple')
            .map((item) => ({
              userId: item.userId,
              duration: parseFloat(item.duration.toString()),
              note: item.note
            }));
        }),
        index: props.fixedSlotsIndex
      },
      createdSlotIds: createdSlotIds,
      isAddingMoreScheduleFixedIndex: isAddingMoreScheduleFixedIndex
    };
  };

  const onProceed = () => {
    setSubmittingType('apply');
    formik.submitForm().then(() => {
      const value = changingValueRes();
      if (changingValueKey()) {
        isChangingDate() ? setSelectedDate(value as unknown as number) : setSelectedShiftId(value);
      }
      formik.setFieldValue(changingValueKey(), value);
      setIsBeingProceed(true);
      setIsShowConfirmChangeModal(false);
    });
  };

  const onKeepGoing = () => {
    const _date = ((isChangingDate() ? changingValueRes() : date) as number) || 1;
    const _shiftId = (isChangingShift() ? changingValueRes() : shiftId) || '';
    const _employees = getInitialEmployees(_date, _shiftId);
    setSelectedDate(_date as number);
    setSelectedShiftId(_shiftId);
    resetForm(_date, _shiftId, _employees as FormEmployeesProp);
    setIsBeingProceed(false);
    setIsShowConfirmChangeModal(false);
  };

  const setSelectedDate = (value: number) => {
    dispatch(schedulerFixActions.setSelectedDate(value));
  };

  const setSelectedShiftId = (value: string) => {
    dispatch(schedulerFixActions.setSelectedShiftId(value));
  };

  const resetForm = (date: number, shiftId: string, employees: FormEmployeesProp): void => {
    formik.resetForm({
      values: {
        weekday: date,
        shiftId: shiftId,
        employees: employees
      }
    });
  };

  const changingValueKey = () => {
    return Object.keys(changingValue)[0];
  };

  const isChangingShift = () => {
    return changingValueKey() === 'shiftId';
  };

  const isChangingDate = () => {
    return changingValueKey() === 'date';
  };

  const changingValueRes = (): string => {
    return Object.values(changingValue)[0] as string;
  };

  return {
    isShowConfirmModal,
    setIsShowConfirmModal,
    isShowConfirmChangeModal,
    setIsShowConfirmChangeModal,
    formik,
    onDateChange,
    onChangeShift,
    getShiftDuration,
    shiftId,
    initialValues,
    getInitialEmployees,
    isChangingDate,
    isChangingShift,
    changingValue,
    onProceed,
    onKeepGoing,
    submittingType,
    setSubmittingType,
    project,
    modalConfirmEmptyVisible,
    setModalConfirmEmptyVisible,
    setScheduleFixedIndex
  };
};
