import styled from 'styled-components';

export const TotalCount = styled.div`
  padding: 1rem;
  color: #fff;
  font-weight: 600;

  &.expected-number {
    margin-right: -0.5rem;
    background: var(--success);
  }
  &.checked-number {
    margin-left: -0.5rem;
    background: var(--danger);
  }
`;
export const TableCellNote = styled.div`
  white-space: initial;
  display: block;
  overflow: hidden;
  max-height: 5.6em;
  padding-top: 0px !important;
  border: none !important;
`;
