import { object, string } from 'yup';
import * as Yup from 'yup';
export const offVacationSchema = object({
  type: string().required('Required'),
  requester: string().required('Required'),
  project: string().required('Required'),
  date: Yup.string().when('type', {
    is: (value: any) => value === 'ONEDAY' || value === 'SOMEHOURS',
    then: Yup.string().required('Required')
  }),
  reason: string().required('Required')
});
