import { FormikHelpers, FormikProps, useFormik } from 'formik';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { divisionActions, divisionSelector } from 'store/divisions/division.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface FormProps {
  name: string;
}

interface Props {
  projectId: string;
}

export const useModalManageDivision = (props: Props) => {
  const { projectId } = { ...props };

  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  const divisions = useAppSelector(divisionSelector.selectDivisions);
  const isCreatingDivision = useAppSelector(divisionSelector.selectIsCreating);
  const isDeletingDivision = useAppSelector(divisionSelector.selectIsDeleting);
  const isUpdatingDivision = useAppSelector(divisionSelector.selectIsUpdating);
  const divisionError = useAppSelector(divisionSelector.selectError);

  const initialValues: FormProps = {
    name: ''
  };

  useDidMountEffect(() => {
    if (!isCreatingDivision && !divisionError) {
      toast.success('Successfully Created Division');
      getDivisions();
    }
  }, [isCreatingDivision]);

  useDidMountEffect(() => {
    if (!isDeletingDivision && !divisionError) {
      toast.success('Successfully Deleted Division');
      getDivisions();
    }
  }, [isDeletingDivision]);

  useDidMountEffect(() => {
    if (!isUpdatingDivision && !divisionError) {
      getDivisions();
    }
  }, [isUpdatingDivision]);

  useDidMountEffect(() => {
    if (!divisionError) {
      toast.error(divisionError.message || 'Error.');
    }
  }, [divisionError]);

  useEffect(() => {
    if (isVisible) {
      getDivisions();
    }
  }, [isVisible]);

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: initialValues,
    // validationSchema: projectSchema,
    onSubmit: (values: FormProps) => {
      handleSubmit(values);
    }
  });

  const getDivisions = () => {
    dispatch(divisionActions.getDivisions({ projectId: projectId }));
  };

  const onRemoveDivision = (divisionId: string) => {
    dispatch(divisionActions.deleteDivision({ projectId: projectId, divisionId: divisionId }));
  };

  const onSelectDivisionColor = (divisionId: string, name: string, color: string) => {
    dispatch(
      divisionActions.updateDivision({
        projectId: projectId,
        divisionId: divisionId,
        name: name,
        color: color
      })
    );
  };

  const handleSubmit = (values: FormProps) => {
    dispatch(divisionActions.createDivision({ projectId: projectId, name: values.name }));
    formik.resetForm();
  };

  return {
    isVisible,
    setIsVisible,
    showPicker,
    setShowPicker,
    divisions,
    onRemoveDivision,
    onSelectDivisionColor,
    formik
  };
};
