import WarningTag from 'components/SchedulerFix/Actions/WarningTag/WarningTag';
import { ShiftHelper } from 'helpers';
import { useAuth } from 'hooks';
import { useShiftAgentContainer } from 'hooks/scheduler/useShiftAgentContainer';
import { useShowDetail } from 'hooks/scheduler/useShowDetail';
import { useUserFilter } from 'hooks/scheduler/useUserFilter';
import { DragAndDropParams, FixedShift, FixedSlot, User, UserSlot } from 'models';
import React, { ReactNode } from 'react';
import { Item, Menu, Separator, contextMenu, useContextMenu } from 'react-contexify';
import { fixedSlotActions } from 'store/fixedSlots/fixedSlot.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { schedulerFixActions } from 'store/schedulerFixs/schedulerFix.slice';
import '../../Scheduler.scss';
import Event from '../Event/Event';
import { ContextMenu, ShiftAgentWrapper } from './ShiftAgent.styled';
import { projectSelector } from 'store/projects/project.slice';
import { useDivisionFilterV2 } from 'hooks/scheduler/useDivisionFilterV2';

interface Props {
  data: FixedSlot;
  shiftData: FixedShift;
  shiftDuration: number;
  index: number;
  slotIndex: number;
  onSetDraggingItem: (object: DragAndDropParams) => void;
  onSetDroppedItem: (object: DragAndDropParams) => void;
  children?: ReactNode;
  date: number;
}

// components
const ShiftAgent = (props: Props) => {
  const { users, selectedUsers } = useUserFilter();
  const { showDetail, ShowMoreButton } = useShowDetail(props.data.userSlots);
  const selectedProject = useAppSelector(projectSelector.selectSelectedProject);
  const { selectedDivisions, selectedAllDivisions } = useDivisionFilterV2();
  const dispatch = useAppDispatch();

  const handleRemoveSlot = ({ event, props, triggerEvent, data }: any) => {
    dispatch(fixedSlotActions.deleteSlot(data));
  };

  const onAssign = () => {
    dispatch(schedulerFixActions.setIsModalCreateSlotVisible(true));
    dispatch(schedulerFixActions.setSelectedShiftId(props.shiftData._id));
    dispatch(schedulerFixActions.setSelectedDate(props.data.weekday));
  };

  const isShowAgent = (userSlot: UserSlot) => {
    const user = users.find((u: User) => u._id === userSlot.user._id);
    const isSelected = selectedUsers.includes(userSlot.user._id);

    if (!user || (isSelected && user && (selectedAllDivisions || validUserDivision(user)))) {
      return true;
    }

    if (selectedProject?.divisions && selectedProject.divisions.length) {
      return false;
    }

    // Handle the case when divisions are not present or an empty array
    return !user || (isSelected && user);
  };

  const validUserDivision = (user: User) => {
    return user.projects.some((project) => {
      if (!project.divisions || !project.divisions.length) {
        return false;
      }
      return (
        project.divisions.some((divisions) => selectedDivisions.includes(divisions._id)) &&
        selectedDivisions.includes(props.data.division[0]._id)
      );
    });
  };

  const trackVisibility = (isVisible: boolean) => {
    setIsDraggingOver(false);
  };

  const getData = () => {
    return showDetail ? props.data.userSlots : props.data.userSlots.slice(0, 2);
  };

  const { setIsDraggingOver, Container } = useShiftAgentContainer(
    props.index,
    props.data,
    props.shiftData,
    props.onSetDraggingItem,
    props.onSetDroppedItem,
    <>
      <WarningTag users={users} shiftId={props.shiftData._id} date={props.date} slot={props.data} />
      <ContextMenu>
        <Menu id={props.index + props.data._id} theme="light" onVisibilityChange={trackVisibility}>
          <Item
            onClick={handleRemoveSlot}
            data={{
              slotId: props.data._id,
              shiftId: props.shiftData._id,
              index: props.slotIndex
            }}
          >
            Remove this Agent Count
          </Item>
          <Separator />
          <Item onClick={onAssign}>Assign</Item>
        </Menu>
      </ContextMenu>
      <ShiftAgentWrapper>
        {getData().map(
          (userSlot: UserSlot, userSlotIndex: number) =>
            isShowAgent(userSlot) && (
              <Event
                data={userSlot}
                slotData={props.data}
                slotId={props.data._id}
                shiftId={props.shiftData._id}
                userData={users.find((u) => u._id === userSlot.user._id)}
                date={props.date}
                key={`${props.data._id}${userSlotIndex}`}
                onSetDraggingItem={props.onSetDraggingItem}
                onSetDroppedItem={props.onSetDroppedItem}
                index={userSlotIndex}
                slotIndex={props.slotIndex}
                shiftDuration={ShiftHelper.getDurationOfShift(props.shiftData)}
                showDetail={showDetail}
              />
            )
        )}

        <div className="clearfix"></div>
      </ShiftAgentWrapper>
      <ShowMoreButton />
    </>
  );

  return <Container></Container>;
};

export default React.memo(ShiftAgent);
