import { SpinnerButton, TimePicker } from 'components/Shared';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import { useAuth } from 'hooks';
import { useSpinner } from 'hooks/useSpinner';
import { ButtonTypes } from 'models';
import { useEffect, useState } from 'react';
import { Form, Col, OverlayTrigger, Popover, Button, Badge } from 'react-bootstrap';
import { Calendar as DateRange } from 'react-date-range';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { userSelector } from 'store/users/user.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { OffVacationRequestFormProps } from 'models/offVacation.model';
import { createOffVacation } from 'store/offVacations/offVacation.slice';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { toast } from 'react-toastify';
import { selectError, selectIsCreating } from 'store/offVacations/offVacation.selector';
import { offVacationSchema } from 'utils/validators/offVacation.validator';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { eachDayOfInterval } from 'date-fns';

const types = [
  { key: 'ONEDAY', value: 'One Day' },
  { key: 'SOMEDAY', value: 'Some Days' },
  { key: 'CONSECUTIVEDAY', value: 'Some Consecutive Days' },
  { key: 'SOMEHOURS', value: 'Some Hours' }
];

const OffVacationRequest = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAuth();
  const projects = useAppSelector(projectSelector.selectProjects);
  const users = useAppSelector(userSelector.selectUsers);
  const isFetchingProject = useAppSelector(projectSelector.selectIsFetching);
  const isCreating = useAppSelector(selectIsCreating);
  const error = useAppSelector(selectError);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [dates, setDates] = useState<any>([]);

  useEffect(() => {
    dispatch(projectActions.getProjects());
  }, []);

  useDidMountEffect(() => {
    if (!isCreating && !error) {
      toast.success('Requested Off Successfully.');
      formik.resetForm();
      setDates([]);
      setSelectedDates([]);
    }
  }, [isCreating]);

  useDidMountEffect(() => {
    if (!isCreating && error) {
      toast.error((error as any)?.message || 'Something went wrong.');
    }
  }, [error]);

  const initialValues: OffVacationRequestFormProps = {
    type: 'ONEDAY',
    requester: '',
    project: '',
    date: '',
    from: '',
    to: '',
    reason: ''
  };

  const formik: FormikProps<OffVacationRequestFormProps> = useFormik<OffVacationRequestFormProps>({
    initialValues: initialValues,
    validationSchema: offVacationSchema,
    onSubmit: (values: OffVacationRequestFormProps) => {
      handleSubmit(values);
    }
  });
  const handleSubmit = (values: OffVacationRequestFormProps) => {
    if (values.type === 'CONSECUTIVEDAY') {
      const range = eachDayOfInterval({
        start: values.dates ? new Date(values.dates?.[0]) || new Date() : new Date(),
        end: values.dates ? new Date(values?.dates?.[1]) || new Date() : new Date()
      });
      dispatch(
        createOffVacation({
          ...values,
          date: values.date || undefined,
          dates: range.map((r) => formatTo_yyyyMMdd(r))
        })
      );
    } else {
      dispatch(createOffVacation({ ...values, date: values.date || undefined }));
    }
  };

  const onSelectType = (e: any) => {
    // setSelectedType(e.target.value);
    formik.setFieldValue('type', e.target.value);
  };

  const onDateChange = (field: string, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
  };

  const onConsecutiveDayChange = (item: DateObject[]) => {
    setDates(item);
    const newSelectedDates = item.map((i: DateObject) => formatTo_yyyyMMdd(i.toDate()));
    setSelectedDates(newSelectedDates);
    formik.setFieldValue(
      'dates',
      newSelectedDates.map((date) => formatTo_yyyyMMdd(new Date(date)))
    );
  };

  return (
    <>
      {isFetchingProject ? (
        useSpinner()
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <Form.Row>
              <Form.Group as={Col} xs={6} className="mb-3" controlId="type">
                <Form.Label className="required">Request Type</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Disable"
                  onBlur={formik.handleBlur}
                  onChange={onSelectType}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  value={formik.values.type}
                  name="type"
                  custom
                >
                  {types.map((type) => (
                    <option key={type.key} value={type.key}>
                      {type.value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={6} className="mb-3" controlId="requester">
                <Form.Label className="required">Requester</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Disable"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  value={formik.values.requester}
                  name="requester"
                  isInvalid={formik.touched.requester && !!formik.errors.requester}
                  custom
                >
                  <option key="" value="">
                    Select Agent
                  </option>
                  {currentUser.role === 'AGENT' ? (
                    <option key={currentUser._id} value={currentUser._id}>
                      {currentUser?.displayName || currentUser?.username}
                    </option>
                  ) : (
                    users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user?.displayName || user?.username}
                      </option>
                    ))
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.requester}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={6} className="mb-3" controlId="project">
                <Form.Label className="required">Project</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Disable"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  value={formik.values.project}
                  name="project"
                  isInvalid={formik.touched.project && !!formik.errors.project}
                  custom
                >
                  <option key="" value="">
                    Select Project
                  </option>
                  {projects.map((project) => (
                    <option key={project._id} value={project._id}>
                      {project.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.project}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            {(formik.values.type === 'ONEDAY' || formik.values.type === 'SOMEHOURS') && (
              <Form.Row>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="top-end"
                  overlay={
                    <Popover
                      id="popover-basic"
                      style={{ maxWidth: '600px' }}
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <Popover.Content style={{ padding: 0 }}>
                        <DateRange
                          onChange={(item) => onDateChange('date', item)}
                          // date={parse(date, 'yyyy-MM-dd', new Date())}
                        />
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Form.Group as={Col} className="mb-3" controlId="date" xs={6}>
                    <Form.Label className="text-start required">Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Date"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.date}
                      isInvalid={formik.touched.date && !!formik.errors.date}
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </OverlayTrigger>
              </Form.Row>
            )}
            {formik.values.type === 'CONSECUTIVEDAY' && (
              <>
                <Form.Row>
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="top-end"
                    overlay={
                      <Popover
                        id="popover-basic"
                        style={{ maxWidth: '600px' }}
                        onMouseDown={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <Popover.Content style={{ padding: 0 }}>
                          <Calendar
                            range
                            rangeHover
                            value={dates}
                            onChange={onConsecutiveDayChange}
                            plugins={[<DatePanel />]}
                          />
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Form.Group as={Col} controlId="date" xs={6} className="mb-0">
                      <Form.Label className="text-start required mr-2">Date</Form.Label>
                      <i className="fas fa-calendar"></i>
                      <Form.Control
                        type="hidden"
                        placeholder="Date"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.date}
                        isInvalid={formik.touched.date && !!formik.errors.date}
                        onMouseDown={(e: any) => {
                          e.stopPropagation();
                        }}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {formik.errors.date}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </OverlayTrigger>
                </Form.Row>
                {selectedDates.length > 0 && (
                  <div className="mb-3">
                    <Badge pill className="mr-1" variant="primary">
                      {selectedDates[0]}
                    </Badge>
                    <span>- </span>
                    <Badge pill className="mr-1" variant="primary">
                      {selectedDates[1]}
                    </Badge>
                  </div>
                )}
              </>
            )}
            {formik.values.type === 'SOMEDAY' && (
              <>
                <Form.Row>
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="top-end"
                    overlay={
                      <Popover
                        id="popover-basic"
                        style={{ maxWidth: '600px' }}
                        onMouseDown={(e: any) => {
                          e.stopPropagation();
                        }}
                      >
                        <Popover.Content style={{ padding: 0 }}>
                          <Calendar
                            multiple
                            value={dates}
                            onChange={onConsecutiveDayChange}
                            plugins={[<DatePanel />]}
                          />
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Form.Group as={Col} controlId="date" xs={6} className="mb-0">
                      <Form.Label className="text-start required mr-2">Date</Form.Label>
                      <i className="fas fa-calendar"></i>
                      <Form.Control
                        type="hidden"
                        placeholder="Date"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.date}
                        isInvalid={formik.touched.date && !!formik.errors.date}
                        onMouseDown={(e: any) => {
                          e.stopPropagation();
                        }}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {formik.errors.date}
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </OverlayTrigger>
                </Form.Row>
                <div className="mb-3">
                  {selectedDates.map((date) => (
                    <Badge pill key={date} className="mr-1" variant="primary">
                      {date}
                    </Badge>
                  ))}
                </div>
              </>
            )}
            {formik.values.type === 'SOMEHOURS' && (
              <Form.Row>
                <Form.Group as={Col} className="mb-3" controlId="from">
                  <Form.Label>From</Form.Label>
                  <TimePicker formik={formik} fieldName={'from'}>
                    <div>
                      <Form.Control
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={(e: any) => e.preventDefault()}
                        name="from"
                        value={formik.values.from}
                        isInvalid={formik.touched.from && !!formik.errors.from}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.from}
                      </Form.Control.Feedback>
                    </div>
                  </TimePicker>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="to">
                  <Form.Label>To</Form.Label>
                  <TimePicker formik={formik} fieldName={'to'}>
                    <div>
                      <Form.Control
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onKeyDown={(e: any) => e.preventDefault()}
                        name="to"
                        value={formik.values.to}
                        isInvalid={formik.touched.to && !!formik.errors.to}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.to}
                      </Form.Control.Feedback>
                    </div>
                  </TimePicker>
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} className="mb-3" controlId="reason">
                <Form.Label className="required">Reason</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Reason"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                  name="reason"
                  isInvalid={formik.touched.reason && !!formik.errors.reason}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.reason}</Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Form>
          {currentUser.role === 'AGENT' && (
            <div className="d-flex justify-content-between">
              <Button variant="outline-secondary" onClick={() => formik.resetForm()}>
                Cancel
              </Button>
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                disabled={formik.isSubmitting}
                onClick={() => {
                  formik.submitForm();
                }}
              >
                Submit
              </SpinnerButton>
            </div>
          )}
        </FormikProvider>
      )}
    </>
  );
};
export default OffVacationRequest;
