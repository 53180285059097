import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';
import { useEffect, useState } from 'react';
import { User } from 'models';
import { useSearchParams } from 'react-router-dom';
import { reportActions, reportSelector } from 'store/reports/report.slice';

export const useUserTypeFilter = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedUserType, setSelectedUserType] = useState<string[]>(['FULLTIME', 'PARTIME']);
  const userTypes: string[] = ['FULLTIME', 'PARTIME'];

  const selectedUserTypes = useAppSelector(reportSelector.selectUserType);
  const setSelectedUserTypes = (val: string[]) => {
    dispatch(reportActions.setUserType(val.join(',')));
  };

  const onSelectUserType = (e: any, userType: string) => {
    e.stopPropagation();
    if (e._reactName === 'onChange') {
      const index = selectedUserType?.indexOf(userType);
      index > -1 ? selectedUserType.splice(index, 1) : selectedUserType.push(userType);

      searchParams.set('userType', selectedUserType.join(','));
      setSearchParams(searchParams);
      setSelectedUserTypes(selectedUserType);
    }
  };

  return {
    userTypes,
    selectedUserType,
    onSelectUserType,
    selectedUserTypes
  };
};
