import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectShifts } from '../../../store/shifts/shift.selector';
import {
  ScheduleAvailabilityBody,
  ScheduleAvailabilityCell,
  ScheduleAvailabilityRow
} from '../ScheduleAvailability.styled';
import { parseShiftTime } from '../../../utils/formatters/datetime.formatter';
import { User, getWeekdayNumber, getWeekdays } from '../../../models';
import {
  selectEnableEditing,
  selectPreview
} from '../../../store/scheduleAvailability/scheduledAvailability.selector';
import { fixedScheduledPreviewActions } from '../../../store/fixedSchedulePreview/fixedScheduledPreview.slice';
import UserInlineEdit, { UserInlineEditOptionType } from '../UserInlineEdit';
import { selectUsers } from '../../../store/users/user.selector';
import { ValueType } from '@atlaskit/select';
import { useSearchParams } from 'react-router-dom';

const useFixedSchedulePreview = (): (() => React.ReactElement) => {
  const renderPreview = () => {
    const shifts = useAppSelector(selectShifts);
    const previewData = useAppSelector(selectPreview);
    const enableEditing = useAppSelector(selectEnableEditing);
    const allUsers: User[] = useAppSelector(selectUsers);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const userIdsParam = searchParams.get('userIdsParam');

    const onConfirm = (
      options: ValueType<UserInlineEditOptionType, true>,
      shiftId: string,
      weekday: number
    ) => {
      const selectedUserIds = options.length === 0 ? [] : options.map((option) => option.value);
      const selectedUsers = allUsers.filter((user) => selectedUserIds.indexOf(user._id) !== -1);

      const shift = shifts.find((shift) => shift._id === shiftId);
      if (shift) {
        dispatch(
          fixedScheduledPreviewActions.updateUsers({
            shift,
            weekday,
            users: selectedUsers
          })
        );
      }
    };

    const renderAgentName = (shiftId: string, weekday: string): React.ReactElement => {
      const weekdayNumber = getWeekdayNumber(weekday);
      const existingPreview = previewData.find(
        (item) => item.shift._id === shiftId && item.weekday === weekdayNumber
      );

      let defaultUsers: User[] = [];

      if (existingPreview) {
        if (userIdsParam) {
          defaultUsers = existingPreview.users.filter((user) => userIdsParam.includes(user._id));
        } else {
          defaultUsers = existingPreview.users;
        }
      }

      return (
        <ScheduleAvailabilityCell key={`${shiftId}-${weekdayNumber}`}>
          <UserInlineEdit
            defaultValue={defaultUsers.map((user) => {
              return { label: user.displayName, value: user._id, shiftId, weekday: weekdayNumber };
            })}
            selectOptions={allUsers.map((user) => {
              return { label: user.displayName, value: user._id, shiftId, weekday: weekdayNumber };
            })}
            onConfirm={(options) => {
              onConfirm(options, shiftId, weekdayNumber);
            }}
            isEditing={enableEditing}
          />
        </ScheduleAvailabilityCell>
      );
    };

    return (
      <>
        <ScheduleAvailabilityBody>
          {shifts.map((shift) => (
            <ScheduleAvailabilityRow key={`shift-${shift._id}`}>
              <ScheduleAvailabilityCell
                key="shift-column"
                title={`${parseShiftTime(shift.startTime)} - ${parseShiftTime(shift.endTime)}`}
              >
                {shift.name}
              </ScheduleAvailabilityCell>
              {getWeekdays().map((weekday) => renderAgentName(shift._id, weekday))}
            </ScheduleAvailabilityRow>
          ))}
        </ScheduleAvailabilityBody>
      </>
    );
  };

  return renderPreview;
};

export default useFixedSchedulePreview;
