import {
  BulkUpdateUsersPayload,
  CreateUserPayload,
  GetUsersPayload,
  RemoveProjectFromUserPayload,
  ResetPasswordUserPayload,
  UpdateStatusUserPayload,
  UpdateUserPayload
} from 'models';
import ApiService from '../utils/network';
import { API_URL } from './url';

export const userApi = {
  getUsers(payload: GetUsersPayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.USER}`, {
      params: payload
    });
  },
  createUser(payload: CreateUserPayload) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.USER}`, payload);
  },
  resetPasswordUser(payload: ResetPasswordUserPayload) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.USER}/${payload.id}/password`, payload);
  },
  updateStatusUser(payload: UpdateStatusUserPayload) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.USER}/${payload.id}/status`, payload);
  },
  removeProject(payload: RemoveProjectFromUserPayload) {
    return ApiService.delete(`${API_URL.BASE_URL}/${API_URL.USER}/${payload.id}/remove-project`, {
      data: {
        projectId: payload.projectId
      }
    });
  },
  bulkUpdateUsers(payload: BulkUpdateUsersPayload) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.USER}/bulk-update`, payload);
  },
  deleteUsers(payload: string[]) {
    return ApiService.patch(
      `${API_URL.BASE_URL}/${API_URL.USER}/delete-request?ids=${payload.join(',')}`
    );
  },
  updateUser(payload: UpdateUserPayload) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.USER}/${payload._id}`, payload);
  },
  deleteUser(payload: string) {
    return ApiService.delete(`${API_URL.BASE_URL}/${API_URL.USER}/`, {
      data: {
        userIds: [payload]
      }
    });
  },
  restoreUsers(payload: string[]) {
    return ApiService.patch(
      `${API_URL.BASE_URL}/${API_URL.USER}/restore-request?ids=${payload.join(',')}`
    );
  },
  exportUsers(payload: GetUsersPayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.USER}/csv`, { params: payload });
  },
  getUsersSameProject() {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.USER}/users_same_project`);
  }
};
