import styled from 'styled-components';
export const ShiftInformation = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
`;

export const ShiftInformationItem = styled.div`
  padding: 0.5rem;
  border: 1px solid #dee2e6;
  font-size: 0.8rem;
  font-weight: 600;
  &:nth-child(2) {
    border-top: 0;
    border-bottom: 0;
  }
`;
