import logo from 'assets/images/logo.png';
import SalaryPeriod from 'components/SalaryPeriod/SalaryPeriod';
import { useAuth } from 'hooks';
import { UserRole } from 'models';
import { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notificationActions, notificationSelector } from 'store/notifications/notification.slice';
import { SidebarIcon, SidebarLogo, UnreadNoti } from './Sidebar.styled';
import { agentSidebarItems } from './agentSidebarItems';
import { managerSidebarItems } from './managerSidebarItems';
import { getIcon } from 'react-toastify/dist/components';
const Sidebar = () => {
  const dispatch = useAppDispatch();

  const currentUser = useAuth();
  const { pathname } = useLocation();
  const sidebarItems =
    currentUser && currentUser?.role === UserRole.MANAGER ? managerSidebarItems : agentSidebarItems;
  const totalUnread = useAppSelector(notificationSelector.selectTotalUnread);
  const [collapseOpen, setCollapseOpen] = useState<boolean>();
  const [showSalaryPeriod, setShowSalaryPeriod] = useState<boolean>();

  const toggleCollapse = (): void => {
    setCollapseOpen((data: any) => !data);
  };

  useEffect(() => {
    dispatch(notificationActions.getTotalUnreadNotis());
  }, []);

  const getActiveClass = (href: string) => {
    return pathname === href ? ' active' : '';
  };

  const getIcon = () => {
    return `fas fa-chevron-${showSalaryPeriod ? 'down' : 'right'}`;
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
      style={{ padding: '1rem 1.5rem' }}
    >
      <Container fluid className="position-relative">
        <Navbar.Brand className="pt-0 text-center" href="/">
          <SidebarLogo className="navbar-brand-img" src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler" />
        <Navbar.Collapse id="basic-navbar-nav" className="mt-3">
          <Nav>
            {sidebarItems.map((item, index) => (
              <Nav.Link
                href={item.href}
                key={index}
                className={`${pathname === item.href ? 'active' : ''}`}
              >
                <SidebarIcon
                  className={`${item.icon} ${item.colorClass ? item.colorClass : 'text-primary'}`}
                />
                {item.name}
                {item.name === 'Notifications/Inbox' ? (
                  <UnreadNoti>{totalUnread}</UnreadNoti>
                ) : null}
              </Nav.Link>
            ))}
          </Nav>
          <hr className="my-3" />
          <h6
            className="navbar-heading text-muted clickable"
            onClick={() => {
              setShowSalaryPeriod(!showSalaryPeriod);
            }}
          >
            Salary Period <i className={getIcon()}></i>
          </h6>
          {showSalaryPeriod && (
            <Nav>
              <SalaryPeriod />
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
