import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FixedSchedulePreviewItem, initialState } from './fixedScheduledPreview.initialState';
import { Shift, User } from '../../models';

const fixedScheduledPreviewSlice = createSlice({
  name: 'fixedScheduledPreview',
  initialState,
  reducers: {
    getFixedScheduledPreview: (
      state,
      {
        payload
      }: PayloadAction<{
        projectId: string;
      }>
    ) => {
      const { projectId } = payload;
      state.error = null;
      state.isFetching = true;
      state.projectId = projectId;
    },
    getFixedScheduledPreviewSuccess: (
      state,
      { payload }: PayloadAction<{ previewData: FixedSchedulePreviewItem[] }>
    ) => {
      const { previewData } = payload;
      state.error = null;
      state.previewData = previewData;
      state.isFetching = false;
    },
    getFixedScheduledPreviewFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      const { error } = payload;
      state.error = error;
      state.isFetching = false;
    },
    addNewUser: (
      state,
      {
        payload
      }: PayloadAction<{
        shift: Shift;
        weekday: number;
        user: User;
      }>
    ) => {
      const { shift, weekday, user } = payload;
      const previewItem = state.previewData.find(
        (item) => item.shift._id === shift._id && item.weekday === weekday
      );

      if (!previewItem) {
        state.previewData = [
          ...state.previewData,
          {
            shift,
            weekday,
            users: [user]
          }
        ];
      } else {
        const currentUsers = previewItem.users;
        const userExisted = currentUsers.find((currentUser) => currentUser._id === user._id);

        if (userExisted) {
          return;
        }

        currentUsers.push(user);
      }
    },
    updateUsers: (
      state,
      {
        payload
      }: PayloadAction<{
        shift: Shift;
        weekday: number;
        users: User[];
      }>
    ) => {
      const { shift, weekday, users } = payload;
      const previewItem = state.previewData.find(
        (item) => item.shift._id === shift._id && item.weekday === weekday
      );

      if (previewItem) {
        previewItem.users = [...[], ...users];
      } else {
        state.previewData = [
          ...state.previewData,
          {
            shift,
            weekday,
            users
          }
        ];
      }
    },
    requestSavePreviewData: (
      state,
      {
        payload
      }: PayloadAction<{
        projectId: string;
        previewData: FixedSchedulePreviewItem[];
      }>
    ) => {
      const { previewData, projectId } = payload;
      state.error = null;
      state.isFetching = true;
      state.previewData = previewData;
      state.projectId = projectId;
    },
    requestSavePreviewDataSuccess: (state) => {
      state.error = null;
      state.isFetching = false;
    },
    requestSavePreviewDataFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      const { error } = payload;
      state.error = error;
      state.isFetching = false;
    },
    requestShuffledPreviewData: (state, { payload }: PayloadAction<{ projectId: string }>) => {
      const { projectId } = payload;
      state.isFetching = true;
      state.error = null;
      state.projectId = projectId;
    },
    requestShuffledPreviewDataSuccess: (
      state,
      { payload }: PayloadAction<{ previewData: FixedSchedulePreviewItem[] }>
    ) => {
      const { previewData } = payload;
      state.error = null;
      state.isFetching = false;
      state.previewData = previewData;
    },
    requestShuffledPreviewDataFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
      const { error } = payload;
      state.error = error;
      state.isFetching = false;
    }
  }
});

export const { actions: fixedScheduledPreviewActions, reducer: fixedScheduledPreviewReducer } =
  fixedScheduledPreviewSlice;
