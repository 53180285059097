import { DropdownAutoCloseOutside } from 'components/Shared';
import { Division } from 'models';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from './FilterDropdown.styled';
import { useDivisionFilterV2 } from './useDivisionFilterV2';

const DivisionFilter = () => {
  const {
    divisions,
    selectedDivisions,
    divisionLabel,
    onSelectDivision,
    selectedAllDivisions,
    setSelectedAllDivisions
  } = useDivisionFilterV2();
  return (
    <>
      <DropdownAutoCloseOutside className="mr-2">
        <Dropdown.Toggle size="sm" variant="outline-primary">
          {divisionLabel}
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul">
          {divisions.length > 0 && (
            <Dropdown.Item as="li" onClick={(e) => onSelectDivision(e, 'all')}>
              <FilterItem>
                <input
                  type="checkbox"
                  value={'all'}
                  checked={selectedAllDivisions}
                  onChange={(e) => onSelectDivision(e, 'all')}
                />
                <FilterItemLabel>All Divisions</FilterItemLabel>
              </FilterItem>
            </Dropdown.Item>
          )}
          {divisions.map((division: Division) => (
            <Dropdown.Item
              key={division._id}
              as="li"
              onClick={(e) => onSelectDivision(e, division._id)}
            >
              <FilterItem>
                <input
                  type="checkbox"
                  value={division._id}
                  checked={selectedDivisions.includes(division._id)}
                  onChange={(e) => onSelectDivision(e, division._id)}
                />
                <FilterItemLabel>{division.name}</FilterItemLabel>
              </FilterItem>
            </Dropdown.Item>
          ))}
          {divisions.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
        </Dropdown.Menu>
      </DropdownAutoCloseOutside>
    </>
  );
};

export default DivisionFilter;
