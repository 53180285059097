import { Col, Form } from 'react-bootstrap';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';

interface Props {
  data: any;
}

const ShiftChangeDetailCover = (props: Props) => {
  const { data } = { ...props };
  const getDate = (value: string | undefined) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };
  const getShift = (value: string | undefined) => {
    return value;
  };

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`owner`}>
          <Form.Label className="required">Shift Owner</Form.Label>
          <Form.Control type="text" value={data.owner} disabled />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId={`agent[0]`}>
          <Form.Label className="required">Corresponding Agent</Form.Label>
          <Form.Control type="text" value={data.agent[0]} disabled />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`ownerShift`}>
          <Form.Label className="required">Shift</Form.Label>
          <Form.Control type="text" value={getShift(data.ownerShift)} disabled />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="ownerDate">
          <Form.Label className="text-start required">Date</Form.Label>
          <Form.Control type="text" value={getDate(data.ownerDate)} disabled />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="ownerTime">
          <Form.Label className="required">From Time</Form.Label>
          <Form.Control type="text" value={data.ownerTime} disabled />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="agentTime">
          <Form.Label className="required">To Time</Form.Label>
          <Form.Control type="text" value={data.agentTime} disabled />
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default ShiftChangeDetailCover;
