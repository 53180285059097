import {
  addDays,
  eachDayOfInterval,
  endOfISOWeek,
  format,
  isSameDay,
  startOfISOWeek,
  subDays,
  startOfMonth,
  endOfMonth
} from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { schedulerSelector, schedulerActions } from 'store/schedulers/scheduler.slice';
import { defineds, staticRanges } from './staticRanges';
import { useProjectFilter } from 'hooks';
import { shiftActions } from 'store/shifts/shift.slice';

interface Props {
  isScheduler?: boolean;
}

const SchedulerDateRangePicker = (props: any) => {
  const dispatch = useAppDispatch();
  const selectedViewMode = useAppSelector(schedulerSelector.selectViewMode);
  const [counter, setCounter] = useState(1);
  const [timeString, setTimeString] = useState<string>('This week');
  const [isVisible, setIsVisible] = useState(false);
  const [ranges, setRanges] = useState<any>([
    {
      startDate:
        selectedViewMode === 'Week' ? startOfISOWeek(new Date()) : startOfMonth(new Date()),
      endDate: selectedViewMode === 'Week' ? endOfISOWeek(new Date()) : endOfMonth(new Date()),
      key: 'selection'
    }
  ]);
  const { selectedProject } = useProjectFilter();
  useEffect(() => {
    const dates = eachDayOfInterval({
      start: ranges[0].startDate,
      end: ranges[0].endDate
    });
    const datesMonth = eachDayOfInterval({
      start: startOfISOWeek(ranges[0].startDate),
      end: endOfISOWeek(ranges[0].endDate)
    });
    dispatch(schedulerActions.setSelectedDateRange(dates));
    dispatch(shiftActions.getShifts(selectedProject));
    // dispatch(schedulerActions.setIsApplyingFilter(true));
    props.onChangeDateRange(dates);
    props.onChangeDateRangeMonth(datesMonth);
    updateTimeString();
  }, [ranges]);

  useEffect(() => {
    setRanges([
      {
        startDate:
          selectedViewMode === 'Week' ? startOfISOWeek(new Date()) : startOfMonth(new Date()),
        endDate: selectedViewMode === 'Week' ? endOfISOWeek(new Date()) : endOfMonth(new Date()),
        key: 'selection'
      }
    ]);
  }, [selectedViewMode]);

  const updateTimeString = () => {
    const startDate = ranges[0].startDate;
    const endDate = ranges[0].endDate;
    if (
      isSameDay(startDate, defineds.startOfLastWeek) &&
      isSameDay(endDate, defineds.endOfLastWeek)
    ) {
      setTimeString('Last week');
      return;
    }

    if (isSameDay(startDate, defineds.startOfWeek) && isSameDay(endDate, defineds.endOfWeek)) {
      setTimeString('This week');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfNextWeek) &&
      isSameDay(endDate, defineds.endOfNextWeek)
    ) {
      setTimeString('Next week');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfLastMonth) &&
      isSameDay(endDate, defineds.endOfLastMonth)
    ) {
      setTimeString('Last month');
      return;
    }

    if (isSameDay(startDate, defineds.startOfMonth) && isSameDay(endDate, defineds.endOfMonth)) {
      setTimeString('This month');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfNextMonth) &&
      isSameDay(endDate, defineds.endOfNextMonth)
    ) {
      setTimeString('Next month');
      return;
    }

    setTimeString(
      `${format(startDate, 'E, MMM dd, yyyy')} - ${format(endDate, 'E, MMM dd, yyyy')}`
    );
  };

  const onNext = () => {
    setRanges([
      {
        startDate: addDays(ranges[0].endDate, 1),
        endDate: addDays(ranges[0].endDate, 7),
        key: 'selection'
      }
    ]);
  };

  const onPrevious = () => {
    setRanges([
      {
        startDate: subDays(ranges[0].startDate, 7),
        endDate: subDays(ranges[0].startDate, 1),
        key: 'selection'
      }
    ]);
  };

  const onSelect = (item: any) => {
    setRanges([item.selection]);
    if (counter < 2) {
      if (item.selection.startDate.getTime() !== item.selection.endDate.getTime()) {
        setIsVisible(false);
        setCounter(1);
      } else {
        setCounter(counter + 1);
      }
    } else {
      setIsVisible(false);
      setCounter(1);
    }
  };

  const onToggleDateRangePicker = () => {
    if (!isVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
      setCounter(1);
    }
  };

  return (
    <>
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="bottom-end"
        onToggle={onToggleDateRangePicker}
        show={isVisible}
        overlay={
          <Popover id="popover-basic" style={{ maxWidth: '600px' }}>
            <Popover.Content style={{ padding: 0 }}>
              <DateRangePicker
                editableDateInputs={true}
                onChange={(item) => onSelect(item)}
                ranges={ranges}
                months={1}
                weekStartsOn={1}
                rangeColors={['#5e72e4']}
                color="#5e72e4"
                direction="horizontal"
                staticRanges={staticRanges}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <Button size="sm" variant="outline-primary">
          <i className="fas fa-calendar mr-2" />
          {timeString}
        </Button>
      </OverlayTrigger>
      <ButtonGroup size="sm">
        <Button size="sm" variant="outline-primary" className="btn-previous" onClick={onPrevious}>
          <i className="fa-solid fa-chevron-left"></i>
        </Button>
        <Button className="btn-next" variant="outline-primary" onClick={onNext}>
          <i className="fa-solid fa-chevron-right"></i>
        </Button>
      </ButtonGroup>
    </>
  );
};

export default SchedulerDateRangePicker;
