import useDidMountEffect from 'hooks/useDidMountEffect';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import { User, UserRole, UserStatus } from 'models';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { userActions, userSelector } from 'store/users/user.slice';
import { removeSimpleEl } from 'utils/formatters/array';

export const useUserList = () => {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [changingPage, setChangingPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedAlphabet, setSelectedAlphabet] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);
  const [filteringItem, setFilteringItem] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');
  const [filteringOrder, setFilteringOrder] = useState<string>('asc');
  const [selectedRole, setSelectedRole] = useState<string[]>(['AGENT', 'MANAGER']);
  const [selectedStatus, setSelectedStatus] = useState<string>('ALL');
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [selectedUserType, setSelectedUserType] = useState<string[]>(['FULLTIME', 'PARTIME']);

  const users = useAppSelector(userSelector.selectUsersModalAssign);
  const projects = useAppSelector(projectSelector.selectProjects);
  const isFetchingUser = useAppSelector(userSelector.selectIsFetching);
  const isFetchedUser = useAppSelector(userSelector.selectIsFetched);
  const { totalCount } = useAppSelector(userSelector.selectUsersPaginationInfo);
  const divisions = projects
    .map((project) => project.divisions)
    .reduce((acc, current) => acc.concat(current), []);

  useDidMountEffect(() => {
    getUsers();
  }, [selectedRole]);

  useDidMountEffect(() => {
    getUsers();
  }, [selectedUserType]);

  useDidMountEffect(() => {
    getUsers();
  }, [filteringItem]);

  useDidMountEffect(() => {
    getUsers();
  }, [filteringOrder]);

  useDidMountEffect(() => {
    getUsers();
  }, [searchString]);

  useDidMountEffect(() => {
    getUsers();
  }, [selectedStatus]);

  useDidMountEffect(() => {
    getUsers();
  }, [selectedProjects]);

  useDidMountEffect(() => {
    getUsers();
  }, [selectedDivisions]);

  useDidMountEffect(() => {
    getUsers();
  }, [selectedAlphabet]);

  useDidMountEffect(() => {
    getUsers();
  }, [changingPage]);

  useEffect(() => {
    let _isSelectedAll = true;
    users.forEach((user) => {
      const isNotExisting = !selectedUsers.find((u) => u._id === user._id);
      if (isNotExisting) {
        _isSelectedAll = false;
      }
    });
    setIsSelectedAll(_isSelectedAll);
  }, [users]);

  useEffect(() => {
    let _isSelectedAll = true;
    users.forEach((user) => {
      const isNotExisting = !selectedUsers.find((u) => u._id === user._id);
      if (isNotExisting) {
        _isSelectedAll = false;
      }
    });
    setIsSelectedAll(_isSelectedAll);
  }, [selectedUsers]);

  const onSelectAlphabet = (value: string[]) => {
    resetPagination();
    setSelectedAlphabet(value);
  };

  const onSelectRole = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    resetPagination();
    if (e.target.checked && (value === 'AGENT' || value === 'MANAGER')) {
      const roles = [...selectedRole];
      roles.push(value);
      setSelectedRole(roles);
    } else if (!e.target.checked) {
      setSelectedRole(removeSimpleEl(selectedRole, value));
    }
  };

  const onSelectStatus = (value: string) => {
    resetPagination();
    setSelectedStatus(value);
  };

  const onSelectUserType = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    resetPagination();
    if (e.target.checked && (value === 'FULLTIME' || value === 'PARTIME')) {
      const userTypes = [...selectedUserType];
      userTypes.push(value);
      setSelectedUserType(userTypes);
    } else if (!e.target.checked) {
      setSelectedUserType(removeSimpleEl(selectedUserType, value));
    }
  };

  const onSelectProject = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    resetPagination();
    const _selectedProjects = cloneDeep(selectedProjects);
    if (e.target.checked) {
      _selectedProjects.push(value);
    } else {
      const index = selectedProjects.findIndex((projectId) => projectId === value);
      _selectedProjects.splice(index, 1);
    }
    setSelectedProjects(_selectedProjects);
  };

  const onSelectDivision = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    resetPagination();
    const _selectedDivisions = cloneDeep(selectedDivisions);
    if (e.target.checked) {
      _selectedDivisions.push(value);
    } else {
      const index = selectedDivisions.findIndex((projectId) => projectId === value);
      _selectedDivisions.splice(index, 1);
    }
    setSelectedDivisions(_selectedDivisions);
  };

  const onFilter = (fieldName: string, order: string) => {
    setFilteringItem(fieldName);
    setFilteringOrder(order);
  };

  const onSearch = debounce((e: any) => {
    resetPagination();
    setSearchString(e.target.value);
  }, 350);

  const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsSelectedAll(isChecked);
    const _selectedUsers = cloneDeep(selectedUsers);
    const _users = cloneDeep(users);
    if (isChecked) {
      _users.forEach((user) => {
        const isNotExisting = !_selectedUsers.find((u) => u._id === user._id);
        if (isNotExisting) {
          _selectedUsers.push(user);
        }
      });
    } else {
      _users.forEach((user) => {
        const index = _selectedUsers.findIndex((u) => u._id === user._id);
        _selectedUsers.splice(index, 1);
      });
    }
    setSelectedUsers(_selectedUsers);
  };

  const getStatusParams = () => {
    if (selectedStatus === 'ALL' || selectedStatus === 'DELETED') {
      return undefined;
    } else {
      return selectedStatus as UserStatus;
    }
  };

  const getUsers = () => {
    setIsSelectedAll(false);
    const payload = {
      role: selectedRole.length === 1 ? (selectedRole[0] as UserRole) : undefined,
      page: currentPage,
      perPage: 10,
      search: searchString,
      alphabets: selectedAlphabet.join(','),
      status: getStatusParams(),
      projectId: selectedProjects.join(','),
      divisionId: selectedDivisions.join(','),
      orderBy: filteringItem,
      order: filteringOrder,
      deleted: selectedStatus === 'DELETED' ? true : undefined,
      userType: selectedUserType.length === 1 ? selectedUserType[0] : undefined
    };
    dispatch(userActions.setGetUsersModalAssignParams(payload));
    dispatch(userActions.getUsersModalAssign(payload));
  };

  const resetPagination = () => {
    setCurrentPage(1);
    setChangingPage(1);
  };

  return {
    filteringItem,
    filteringOrder,
    onFilter,
    selectedAlphabet,
    onSelectAlphabet,
    selectedStatus,
    onSelectStatus,
    selectedRole,
    onSelectRole,
    isSelectedAll,
    onSelectAll,
    projects,
    divisions,
    setSelectedDivisions,
    selectedDivisions,
    onSelectDivision,
    selectedProjects,
    onSearch,
    setCurrentPage,
    setChangingPage,
    users,
    isFetchingUser,
    selectedUsers,
    setSelectedUsers,
    getUsers,
    isFetchedUser,
    onSelectProject,
    currentPage,
    totalCount,
    selectedUserType,
    onSelectUserType
  };
};
