import { Table } from 'react-bootstrap';
import NotificationList from './NotificationList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notificationActions, notificationSelector } from 'store/notifications/notification.slice';
import { useEffect } from 'react';
import { GetNotificationsPayload } from 'models';

const UserNotification = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(notificationSelector.selectIsFetchingUserNoti);
  const data = useAppSelector(notificationSelector.selectUserNotifications);
  const paginationInfo = useAppSelector(notificationSelector.selectUserNotisPaginationInfo);

  useEffect(() => {
    dispatch(
      notificationActions.getUserNotifications({
        page: 1,
        perPage: 10,
        type: 'MANUAL',
        orderBy: 'createdAt',
        order: 'desc'
      } as GetNotificationsPayload)
    );
  }, []);

  return (
    <>
      <NotificationList
        data={data}
        isLoading={isLoading}
        type="MANUAL"
        paginationInfo={paginationInfo}
      />
    </>
  );
};

export default UserNotification;
