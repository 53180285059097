import ApiService from '../utils/network';
import { API_URL } from './url';
import {
  OffVacationPayload,
  OffVacationRequestFormProps,
  OffVacation,
  OffVacationIdPayload
} from 'models';
export const offVacationApi = {
  getOffVacation(payload: OffVacationPayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.OFF_VACATION}`, {
      params: payload
    });
  },
  createOffVacation(payload: OffVacationRequestFormProps) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.OFF_VACATION}`, payload);
  },
  updateOffVacation(payload: OffVacation) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.OFF_VACATION}/${payload._id}`, payload);
  },
  getOffVacationById(payload: OffVacationIdPayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.OFF_VACATION}/getById`, {
      params: payload
    });
  }
};
