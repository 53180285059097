import { FixedShift, FixedSlot, User, UserSlot } from 'models';
import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { WarningTriangle } from './WarningTag.styled';
import { SlotValidatorHelper } from 'helpers';
enum ErrorTypes {
  EXCESS_HOURS = 'excess_hours' as any,
  NOT_ENOUGH_HOURS = 'not_enough_hours' as any,
  INVALID_AGENT = 'invalid_agent' as any
}

interface Props {
  users: User[];
  shiftId: string;
  date: number;
  slot: FixedSlot;
}

const ErrorMessages = {
  [ErrorTypes.EXCESS_HOURS]: 'Excess hours added',
  [ErrorTypes.NOT_ENOUGH_HOURS]: 'Not enough hours added',
  [ErrorTypes.INVALID_AGENT]: 'Agent with excess hours detected'
};
const WarningTag = (props: Props) => {
  const shifts: FixedShift[] = useAppSelector(fixedShiftSelector.selectShifts);

  const slotValidatorHelper = new SlotValidatorHelper({ ...props, shifts: shifts });
  const errors = slotValidatorHelper.getErrors();

  const hasErrors = () => {
    return (
      errors[ErrorTypes.EXCESS_HOURS].length > 0 ||
      errors[ErrorTypes.INVALID_AGENT].length > 0 ||
      errors[ErrorTypes.NOT_ENOUGH_HOURS].length > 0
    );
  };
  return (
    <>
      {hasErrors() && (
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip id={`tooltip-auto`} className="warning-tag" style={{ textAlign: 'left' }}>
              <>
                {Object.keys(errors).map((errorType: any, index: number) => (
                  <React.Fragment key={index}>
                    {Object.values(errors[errorType]).length > 0 && (
                      <div>
                        <span>
                          <strong>{`- ${ErrorMessages[errorType]}`}:</strong>
                        </span>
                        <ul>
                          {Object.values(errors[errorType]).map((error: any, errIndex) => (
                            <li key={errIndex}>{error.join(', ')}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            </Tooltip>
          }
        >
          <WarningTriangle title="warningLabel" className="clickable">
            <i className="fa-solid fa-exclamation"></i>
          </WarningTriangle>
        </OverlayTrigger>
      )}
    </>
  );
};

// export default React.memo(WarningTag);
export default WarningTag;
