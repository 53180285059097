import { Badge, Nav } from 'react-bootstrap';
import RequestLogList from './ShiftChangeRequestLogList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getShiftChanges, shiftSelector } from 'store/shiftChanges/shiftChange.slice';
import { useEffect, useState } from 'react';
import { GetShiftChangePayload } from 'models';
import ShiftChangeRejected from './ShiftChangeRejected';
import ShiftChangeApproved from './ShiftChangeApproved';
import { RequestTypeTab } from '../RequestTypeTab/RequestTypeTab';
import PersonalFilter from 'hooks/scheduler/PersonalFilter';
import ProjectFilter from 'hooks/scheduler/ProjectFilter';
import { filterActions } from 'store/filters/filter.slice';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import ShiftChangeProjectFilter from 'components/ShiftChange/Actions/ShiftChangeProjectFilter/ShiftChangeProjectFilter';
import ShiftChangeSaveFilter from 'components/ShiftChange/Actions/ShiftChangeSaveFilter/ShiftChangeSaveFilter';
import ShiftChangePersonalFilter from 'components/ShiftChange/Actions/ShiftChangePersonalFilter/ShiftChangePersonalFilter';

const RequestLogShiftChangeSummary = () => {
  const dispatch = useAppDispatch();
  const { selectedTab, Tab } = RequestTypeTab();
  // const project = useAppSelector(projectSelector.selectSelectedProject);
  const [selectedProjectTab, setSelectedProjectTab] = useState('');
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const projects = useAppSelector(projectSelector.selectProjects);

  useEffect(() => {
    dispatch(filterActions.getPersonalFilters({ kind: 'SHIFTCHANGE' }));
    dispatch(projectActions.getProjects());
  }, []);

  useEffect(() => {
    if (selectedProjectTab.length === 0 && selectedProjects.length > 0) {
      setSelectedProjectTab(selectedProjects[0] || '');
    }
  }, [selectedProjects]);

  // useEffect(() => {
  // }, [selectedProjectTab]);

  const projectTabContent = (projectId: string) => {
    // const pendingCount = pendingProjectCount?.find(
    //   (prj: any) => prj?._id?.projectId?.[0] === projectId
    // );
    // const onholdCount = onholdProjectCount?.find(
    //   (prj: any) => prj?._id?.projectId?.[0] === projectId
    // );
    return (
      <Nav.Item className="position-relative" key={projectId}>
        <Nav.Link eventKey={projectId}>
          {/* <Badge variant="danger" className="position-absolute" style={{ right: '30px' }}>
            {pendingCount?.count || 0}
          </Badge>
          <Badge variant="info" className="position-absolute" style={{ right: '10px' }}>
            {onholdCount?.count || 0}
          </Badge>
          {pendingCount?._id.project || projects.find((project) => project._id === projectId)?.name} */}
          {projects.find((project) => project._id === projectId)?.name}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-start mb-4">
        <Tab />
        <ShiftChangePersonalFilter setSelectedProjects={setSelectedProjects} />

        <ShiftChangeProjectFilter
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
        <ShiftChangeSaveFilter selectedProjects={selectedProjects} />
      </div>
      <Nav
        fill
        variant="tabs"
        defaultActiveKey={selectedProjectTab}
        className="mb-5 px-2 border-bottom"
        onSelect={(key: string | null) => setSelectedProjectTab(key !== null ? key : '')}
      >
        {selectedProjects.map(
          (prjId) => projects.some((project) => project._id === prjId) && projectTabContent(prjId)
        )}
      </Nav>
      <div className="border-bottom my-3"></div>
      {selectedProjects && selectedProjectTab && selectedTab && (
        <ShiftChangeApproved selectedTab={selectedTab} projectId={selectedProjectTab} />
      )}
      {selectedProjects && selectedProjectTab && selectedTab && (
        <ShiftChangeRejected selectedTab={selectedTab} projectId={selectedProjectTab} />
      )}
    </>
  );
};

export default RequestLogShiftChangeSummary;
