import NewShiftChangeForm from 'components/ShiftChange/Actions/NewShiftChangeForm/NewShiftChangeForm';
import ShiftChangeProjectFilter from 'components/ShiftChange/Actions/ShiftChangeProjectFilter/ShiftChangeProjectFilter';
import { FormikProps, useFormik } from 'formik';
import PersonalFilter from 'hooks/scheduler/PersonalFilter';
import useDidMountEffect from 'hooks/useDidMountEffect';
import debounce from 'lodash.debounce';
import { FixedShift, Project, ShiftChange, ShiftChangeFormProps, User } from 'models';
import { useEffect, useState } from 'react';
import { Badge, Button, Col, Nav, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { filterActions } from 'store/filters/filter.slice';
import { fixedShiftActions, fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import {
  selectError,
  selectIsFetching,
  selectIsFetchingOnHoldData,
  selectIsUpdating,
  selectOnHoldData,
  selectOnholdProjectCount,
  selectPendingProjectCount,
  selectShiftChanges
} from 'store/shiftChanges/shiftChange.selector';
import {
  getOnHoldData,
  getShiftChanges,
  updateShiftChange
} from 'store/shiftChanges/shiftChange.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { shiftChangeSchema } from 'utils/validators';
import { RequestTypeTab } from '../RequestTypeTab/RequestTypeTab';
import ShiftChangeSaveFilter from 'components/ShiftChange/Actions/ShiftChangeSaveFilter/ShiftChangeSaveFilter';
import ShiftChangePersonalFilter from 'components/ShiftChange/Actions/ShiftChangePersonalFilter/ShiftChangePersonalFilter';

const ShiftChangeManagerView = () => {
  const dispatch = useAppDispatch();
  const { selectedTab, Tab } = RequestTypeTab();
  const projects = useAppSelector(projectSelector.selectProjects);
  const shifts: FixedShift[] = useAppSelector(fixedShiftSelector.selectShifts);
  const isFetchingShiftChanges = useAppSelector(selectIsFetching);
  const isUpdating = useAppSelector(selectIsUpdating);
  const error = useAppSelector(selectError);
  const shiftChanges = useAppSelector(selectShiftChanges);
  const pendingProjectCount = useAppSelector(selectPendingProjectCount);
  const onholdProjectCount = useAppSelector(selectOnholdProjectCount);
  const isFetchingProjects = useAppSelector(projectSelector.selectIsFetching);
  const onHoldData = useAppSelector(selectOnHoldData);
  const isFetchingOnHoldData = useAppSelector(selectIsFetchingOnHoldData);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectTab, setSelectedProjectTab] = useState('');
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [projectUsers, setProjectUsers] = useState<User[]>([]);
  const [page, setPage] = useState(1);
  const [onHoldPage, setOnHoldPage] = useState(1);

  const getInitValues = (data: ShiftChange) => {
    return {
      type: data?.type || selectedTab,
      employeeType: data?.employeeType || '',
      project: data?.project || '',
      owner: data?.owner || '',
      agent: data?.agent || ['', ''],
      ownerShift: data?.ownerShift || '',
      agentShift: data?.agentShift || ['', ''],
      ownerNewShift: data?.ownerShift || '',
      agentNewShift: data?.agentShift || ['', ''],
      ownerDate: data?.ownerDate || '',
      agentDate: data?.agentDate || ['', ''],
      ownerNewDate: data?.ownerDate || '',
      agentNewDate: data?.agentDate || ['', ''],
      ownerTime: data?.ownerTime || '',
      agentTime: data?.agentTime || '',
      reason: data?.reason || '',
      offVacation: data?.offVacation || false,
      createdBy: data?.createdBy || '',
      createdAt: data?.createdAt || ''
    };
  };

  const initialValues: ShiftChangeFormProps = getInitValues(shiftChanges?.[0]);
  const onHoldInitialValues: ShiftChangeFormProps = getInitValues(onHoldData?.[0]);

  useEffect(() => {
    dispatch(filterActions.getPersonalFilters({ kind: 'SHIFTCHANGE' }));
    dispatch(projectActions.getProjects());
  }, []);

  useDidMountEffect(() => {
    if (!isUpdating && !error) {
      toast.success('Approved Successfully.');
      getData();
    }
  }, [isUpdating]);

  useDidMountEffect(() => {
    if (!isUpdating && error) {
      toast.error((error as any)?.message || 'Something went wrong.');
    }
  }, [error]);

  useEffect(() => {
    getData();
    getOnHold();
  }, [selectedTab]);

  useEffect(() => {
    if (selectedProjectTab.length === 0 && selectedProjects.length > 0) {
      setSelectedProjectTab(selectedProjects[0] || '');
    } else {
      getData();
      getOnHold();
    }
  }, [selectedProjects]);

  useEffect(() => {
    dispatch(fixedShiftActions.getShifts(selectedProjectTab));
    getData();
    getOnHold();
  }, [selectedProjectTab]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    getOnHold();
  }, [onHoldPage]);

  useEffect(() => {
    if (!isFetchingShiftChanges) {
      setFormValues(shiftChanges[0], formik, initialValues);
    }
  }, [isFetchingShiftChanges]);

  useEffect(() => {
    if (!isFetchingOnHoldData) {
      setFormValues(onHoldData[0], onHoldFormik, onHoldInitialValues);
    }
  }, [isFetchingOnHoldData]);

  const formik: FormikProps<ShiftChangeFormProps> = useFormik<ShiftChangeFormProps>({
    initialValues: initialValues,
    validationSchema: shiftChangeSchema,
    onSubmit: () => {
      return;
    }
  });

  const onHoldFormik: FormikProps<ShiftChangeFormProps> = useFormik<ShiftChangeFormProps>({
    initialValues: onHoldInitialValues,
    validationSchema: shiftChangeSchema,
    onSubmit: () => {
      return;
    }
  });

  const setFormValues = (
    data: ShiftChange,
    _formik: FormikProps<ShiftChangeFormProps>,
    _initialValues: ShiftChangeFormProps
  ) => {
    const newData = Object.assign({}, _initialValues, data) as any;

    const {
      type,
      project,
      employeeType,
      reason,
      agent,
      agentShift,
      agentNewShift,
      agentDate,
      agentNewDate,
      agentTime,
      owner,
      ownerShift,
      ownerNewShift,
      ownerDate,
      ownerNewDate,
      ownerTime
    }: any = {
      ...newData
    };
    _formik.setValues({
      type: type || selectedTab,
      project: project[0]?._id || project._id || '',
      employeeType: employeeType || 'NULL',
      reason: reason || '',
      agent: [agent?.[0]?._id || '', agent?.[1]?._id || ''],
      agentTime: agentTime || '',
      agentShift: [agentShift?.[0]?._id || '', agentShift?.[1]?._id || ''],
      agentNewShift: [agentNewShift?.[0]?._id || '', agentNewShift?.[1]?._id || ''],
      agentDate: [getDate(agentDate?.[0]), getDate(agentDate?.[1])],
      agentNewDate: [getDate(agentNewDate?.[0]), getDate(agentNewDate?.[1])],
      owner: owner?.[0]?._id || '',
      ownerShift: ownerShift?.[0]?._id || '',
      ownerNewShift: ownerNewShift?.[0]?._id || '',
      ownerDate: getDate(ownerDate),
      ownerNewDate: getDate(ownerNewDate),
      ownerTime: ownerTime || ''
    } as ShiftChangeFormProps);
    // debugger; // eslint-disable-line no-debugger
  };

  const getDate = (value: string) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };

  const getData = debounce(() => {
    if (selectedProjects.length === 0) return;
    const payload = getPayload('PENDING');
    dispatch(getShiftChanges(payload));
  }, 200);

  const getOnHold = debounce(() => {
    if (selectedProjects.length === 0) return;
    const payload = getPayload('ONHOLD');
    dispatch(getOnHoldData(payload));
  }, 200);

  const getPayload = (status: string) => {
    return {
      type: selectedTab,
      projectId: selectedProjects,
      displayProject: selectedProjectTab.length ? selectedProjectTab : selectedProjects[0],
      status: status,
      page: status === 'PENDING' ? page : onHoldPage,
      perPage: 1
    };
  };

  const getDefaultUsers = () => {
    const res =
      projects.find((project) => project._id === (shiftChanges?.[0] as any)?.project?._id)?.users ||
      projects.find((project) => project._id === (shiftChanges?.[0] as any)?.project[0]?._id)
        ?.users ||
      [];

    return res;
  };

  const getDefaultOnHoldUsers = () => {
    const res =
      projects.find((project) => project._id === (onHoldData?.[0] as any)?.project?._id)?.users ||
      projects.find((project) => project._id === (onHoldData?.[0] as any)?.project[0]?._id)
        ?.users ||
      [];
    return res;
  };

  const onDateChange = (field: string, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
  };

  const onSelectAgent = (index: number, e: any) => {
    formik.setFieldValue(`agent[${index}]`, e.target.value);
  };

  const onSelectAgentShift = (index: number, e: any) => {
    formik.setFieldValue(`agentShift[${index}]`, e.target.value);
  };

  const onSelectAgentNewShift = (index: number, e: any) => {
    formik.setFieldValue(`agentNewShift[${index}]`, e.target.value);
  };

  const onSelectAgentDate = (field: string, index: number, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
    formik.setFieldValue(`agentDate[${index}]`, formattedDate);
  };

  const onSelectAgentNewDate = (field: string, index: number, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
    formik.setFieldValue(`agentNewDate[${index}]`, formattedDate);
  };

  const onDateChangeOnHold = (field: string, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
  };

  const onSelectAgentOnHold = (index: number, e: any) => {
    formik.setFieldValue(`agent[${index}]`, e.target.value);
  };

  const onSelectAgentShiftOnHold = (index: number, e: any) => {
    onHoldFormik.setFieldValue(`agentShift[${index}]`, e.target.value);
  };

  const onSelectAgentNewShiftOnHold = (index: number, e: any) => {
    onHoldFormik.setFieldValue(`agentNewShift[${index}]`, e.target.value);
  };

  const onSelectAgentDateOnHold = (field: string, index: number, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    onHoldFormik.setFieldValue(field, formattedDate);
    onHoldFormik.setFieldValue(`agentDate[${index}]`, formattedDate);
  };

  const onSelectAgentNewDateOnHold = (field: string, index: number, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    onHoldFormik.setFieldValue(field, formattedDate);
    onHoldFormik.setFieldValue(`agentNewDate[${index}]`, formattedDate);
  };

  const onUpdateStatus = (status: string) => {
    const { agent, agentDate, agentNewDate, agentNewShift, agentShift } = { ...formik.values };
    const payload = {
      ...formik.values,
      _id: shiftChanges[0]._id,
      agent: agent.filter((a) => a.length) || [],
      agentDate: agentDate.filter((a) => a.length),
      agentNewDate: agentNewDate.filter((a) => a.length),
      agentShift: agentShift.filter((a) => a.length),
      agentNewShift: agentNewShift.filter((a) => a.length),
      status: status
    };
    dispatch(updateShiftChange(payload));
    getData();

    setTimeout(() => {
      getOnHold();
    }, 500);
  };

  const onUpdateStatusOnHold = (status: string) => {
    const { agent, agentDate, agentNewDate, agentNewShift, agentShift } = {
      ...onHoldFormik.values
    };
    dispatch(
      updateShiftChange({
        ...onHoldFormik.values,
        _id: onHoldData[0]._id,
        agent: agent.filter((a) => a.length) || [],
        agentDate: agentDate.filter((a) => a.length),
        agentNewDate: agentNewDate.filter((a) => a.length),
        agentShift: agentShift.filter((a) => a.length),
        agentNewShift: agentNewShift.filter((a) => a.length),
        status: status
      })
    );
    getOnHold();
  };

  const onChangeProject = (projectId: string) => {
    setSelectedProject(projectId);
    setProjectUsers(projects.find((project) => project._id === projectId)?.users || []);
    formik.setFieldValue('project', projectId);
  };

  const onChangeProjectOnHold = (projectId: string) => {
    setSelectedProject(projectId);
    setProjectUsers(projects.find((project) => project._id === projectId)?.users || []);
    formik.setFieldValue('project', projectId);
  };

  const projectTabContent = (projectId: string) => {
    const pendingCount = pendingProjectCount?.find(
      (prj: any) => prj?._id?.projectId?.[0] === projectId
    );
    const onholdCount = onholdProjectCount?.find(
      (prj: any) => prj?._id?.projectId?.[0] === projectId
    );
    return (
      <Nav.Item className="position-relative" key={projectId}>
        <Nav.Link eventKey={projectId}>
          <Badge variant="danger" className="position-absolute" style={{ right: '30px' }}>
            {pendingCount?.count || 0}
          </Badge>
          <Badge variant="info" className="position-absolute" style={{ right: '10px' }}>
            {onholdCount?.count || 0}
          </Badge>
          {pendingCount?._id.project || projects.find((project) => project._id === projectId)?.name}
        </Nav.Link>
      </Nav.Item>
    );
  };
  return (
    <>
      <div className="d-flex justify-content-start mb-4">
        <Tab />
        <ShiftChangePersonalFilter setSelectedProjects={setSelectedProjects} />
        <ShiftChangeProjectFilter
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
        <ShiftChangeSaveFilter selectedProjects={selectedProjects} />
      </div>

      {selectedProjects.length > 0 && !isFetchingProjects && !isFetchingShiftChanges && (
        <>
          <Nav
            fill
            variant="tabs"
            defaultActiveKey={selectedProjectTab}
            className="mb-5 px-2 border-bottom"
            onSelect={(key: string | null) => setSelectedProjectTab(key !== null ? key : '')}
          >
            {selectedProjects.map(
              (prjId) =>
                projects.some((project) => project._id === prjId) && projectTabContent(prjId)
            )}
          </Nav>
          <Row>
            <Col xs={12} lg={8} xl={6} className="pr-3 pr-lg-5">
              <NewShiftChangeForm
                formik={formik}
                projects={projects}
                projectUsers={getDefaultUsers()}
                shifts={shifts}
                onSelectAgent={onSelectAgent}
                onSelectAgentDate={onSelectAgentDate}
                onSelectAgentNewDate={onSelectAgentNewDate}
                onSelectAgentShift={onSelectAgentShift}
                onSelectAgentNewShift={onSelectAgentNewShift}
                onDateChange={onDateChange}
                onChangeProject={onChangeProject}
                selectedType={selectedTab}
                setSelectedType={() => {
                  return;
                }}
              />
              <div className="d-flex justify-content-between">
                <Button
                  className="btn-icon-only text-light btn btn- btn-sm bg-transparent border-0 mr-0"
                  disabled={page === 0}
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  <i className="fas fa-chevron-left" />
                </Button>
                <>
                  <Button size="sm" variant="success" onClick={() => onUpdateStatus('APPROVED')}>
                    Approve
                  </Button>
                  <Button size="sm" variant="danger" onClick={() => onUpdateStatus('REJECTED')}>
                    Reject
                  </Button>
                  <Button size="sm" variant="info" onClick={() => onUpdateStatus('ONHOLD')}>
                    On-hold
                  </Button>
                </>
                <Button
                  className="btn-icon-only text-light btn btn- btn-sm bg-transparent border-0 mr-0"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  <i className="fas fa-chevron-right" />
                </Button>
              </div>
            </Col>
            <Col xs={12} lg={8} xl={6} className="pl-3 pl-lg-5 mt-xs-5 border-left">
              <NewShiftChangeForm
                formik={onHoldFormik}
                projects={projects}
                projectUsers={getDefaultOnHoldUsers()}
                shifts={shifts}
                onSelectAgent={onSelectAgentOnHold}
                onSelectAgentDate={onSelectAgentDateOnHold}
                onSelectAgentNewDate={onSelectAgentNewDateOnHold}
                onSelectAgentShift={onSelectAgentShiftOnHold}
                onSelectAgentNewShift={onSelectAgentNewShiftOnHold}
                onDateChange={onDateChangeOnHold}
                onChangeProject={onChangeProjectOnHold}
                selectedType={selectedTab}
                setSelectedType={() => {
                  return;
                }}
              />
              <div className="d-flex justify-content-between">
                <Button
                  className="btn-icon-only text-light btn btn- btn-sm bg-transparent border-0 mr-0"
                  disabled={onHoldPage === 1}
                  onClick={() => {
                    setOnHoldPage(onHoldPage - 1);
                  }}
                >
                  <i className="fas fa-chevron-left" />
                </Button>
                <>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => onUpdateStatusOnHold('APPROVED')}
                  >
                    Approve
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => onUpdateStatusOnHold('REJECTED')}
                  >
                    Reject
                  </Button>
                </>
                <Button
                  className="btn-icon-only text-light btn btn- btn-sm bg-transparent border-0 mr-0"
                  onClick={() => {
                    setOnHoldPage(onHoldPage + 1);
                  }}
                >
                  <i className="fas fa-chevron-right" />
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ShiftChangeManagerView;
