import { SpinnerButton } from 'components/Shared';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';
const UserExportCsv = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(userSelector.selectError);
  const isExporting = useAppSelector(userSelector.selectIsExporting);

  return (
    <>
      <SpinnerButton
        btnType="button"
        colorType="success"
        size="sm"
        disabled={isExporting}
        onClick={() => {
          dispatch(userActions.exportUsers());
        }}
      >
        Export CSV
      </SpinnerButton>
    </>
  );
};

export default UserExportCsv;
