import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './offVacation.initialState';
import {
  OffVacationRequestFormProps,
  OffVacationPayload,
  // Shift,
  OffVacation,
  OffVacationResponse,
  OffVacationIdPayload,
  PaginationInfo
} from 'models';
import { GetOffVacationRequestLogResponse } from 'models/offVacation.model';

const offVacationSlice = createSlice({
  name: 'offVacationSlices',
  initialState,
  reducers: {
    getOffVacation: (state, { payload }: PayloadAction<OffVacationPayload>) => {
      state.isFetching = true;
    },
    getOffVacationSuccess: (state, { payload }: PayloadAction<OffVacationResponse>) => {
      state.isFetching = false;
      state.offVacations = payload.data;
      state.offVacationsPaginationInfo = payload.pagination;
      state.error = null;
      state.pending = payload.pending;
      state.pendingProjectCount = payload.group.pending;
    },
    getOffVacationFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    createOffVacation: (state, { payload }: PayloadAction<OffVacationRequestFormProps>) => {
      state.isCreating = true;
    },
    createOffVacationSuccess: (state, { payload }: PayloadAction<OffVacation>) => {
      state.isCreating = false;
      state.error = null;
    },
    createOffVacationFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = false;
      state.error = payload;
    },
    updateOffVacation: (state, { payload }: PayloadAction<OffVacation>) => {
      state.isUpdating = true;
    },
    updateOffVacationSuccess: (state, { payload }: PayloadAction<OffVacation>) => {
      state.isUpdating = false;
      state.error = null;
    },
    updateOffVacationFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    getOffVacationById: (state, { payload }: PayloadAction<OffVacationIdPayload>) => {
      state.isFetching = true;
    },
    getOffVacationByIdSuccess: (state, { payload }: PayloadAction<OffVacationResponse>) => {
      state.isFetching = false;
      state.offVacations = payload.data;
      state.error = null;
    },
    getOffVacationByIdFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getOffVacationApprovedRequestLog: (state, { payload }: PayloadAction<OffVacationPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getOffVacationRejectedRequestLog: (state, { payload }: PayloadAction<OffVacationPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getOffVacationApprovedRequestLogSuccess: (
      state,
      { payload }: PayloadAction<GetOffVacationRequestLogResponse>
    ) => {
      state.isFetching = false;
      state.approvedRequestLog = payload.data;
      state.pending = payload.pending;
      state.error = null;
    },
    getOffVacationApprovedRequestLogFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getOffVacationRejectedRequestLogSuccess: (
      state,
      { payload }: PayloadAction<GetOffVacationRequestLogResponse>
    ) => {
      state.isFetching = false;
      state.rejectedRequestLog = payload.data;
      state.pending = payload.pending;
      state.error = null;
    },
    getOffVacationRejectedRequestLogFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    setOffVacationApprovedRequestLogPaginationInfo: (
      state,
      { payload }: PayloadAction<PaginationInfo>
    ) => undefined,
    setOffVacationApprovedRequestLogPaginationInfoSuccess: (
      state,
      { payload }: PayloadAction<PaginationInfo>
    ) => {
      state.approvedShiftChangePaginationInfo = payload;
    },
    setOffVacationRejectedRequestLogPaginationInfo: (
      state,
      { payload }: PayloadAction<PaginationInfo>
    ) => undefined,
    setOffVacationRejectedRequestLogPaginationInfoSuccess: (
      state,
      { payload }: PayloadAction<PaginationInfo>
    ) => {
      state.rejectShiftChangePaginationInfo = payload;
    }
  }
});

export const {
  getOffVacation,
  getOffVacationSuccess,
  getOffVacationFailed,
  createOffVacation,
  createOffVacationSuccess,
  createOffVacationFailed,
  updateOffVacation,
  updateOffVacationSuccess,
  updateOffVacationFailed,
  getOffVacationById,
  getOffVacationByIdSuccess,
  getOffVacationByIdFailed,
  getOffVacationApprovedRequestLog,
  getOffVacationRejectedRequestLog,
  getOffVacationApprovedRequestLogSuccess,
  getOffVacationApprovedRequestLogFailed,
  getOffVacationRejectedRequestLogSuccess,
  getOffVacationRejectedRequestLogFailed,
  setOffVacationApprovedRequestLogPaginationInfo,
  setOffVacationApprovedRequestLogPaginationInfoSuccess,
  setOffVacationRejectedRequestLogPaginationInfo,
  setOffVacationRejectedRequestLogPaginationInfoSuccess
} = {
  ...offVacationSlice.actions
};

export * as offVacationSelector from './offVacation.selector';

export default offVacationSlice.reducer;
