import { GetNotificationsPayload } from 'models';
import ApiService from '../utils/network';
import { API_URL } from './url';

export const notificationApi = {
  getNotifications(payload: GetNotificationsPayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.NOTIFICATION}`, { params: payload });
  },
  markAsRead(payload: string) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.NOTIFICATION}/${payload}/mark-as-read`);
  },
  markAllAsRead() {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.NOTIFICATION}/mark-all-as-read`);
  },
  getTotalUnreadNotis() {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.NOTIFICATION}/unRead`);
  },
  markAsUnread(payload: string) {
    return ApiService.put(`${API_URL.BASE_URL}/${API_URL.NOTIFICATION}/${payload}/mark-as-unread`);
  }
};
