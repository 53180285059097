import Project from 'components/Project/Project';
import { UserRole } from 'models';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { projectActions } from 'store/projects/project.slice';
import { userActions } from 'store/users/user.slice';

const ProjectPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(projectActions.getProjects());
  }, []);
  return <Project></Project>;
};

export default ProjectPage;
