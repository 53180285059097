import { format, isWeekend } from 'date-fns';
import { ShiftHelper } from 'helpers';
import { Division, Shift } from 'models';
import React from 'react';
import {
  DivisionContent,
  RowHeader,
  ScheduleHeaderText,
  SchedulerTableEl,
  SchedulerTableWrapper
} from './SchedulerCompact.styled';
import { useProjectFilter } from 'hooks/scheduler/useProjectFilter';

interface Props {
  dates: Date[];
  datesMonth: Date[];
  shifts: Shift[];
  offVacationSlotShifts: Shift[];
}

const SchedulerCompact = (props: Props) => {
  const { dates, datesMonth, shifts } = { ...props };
  const { selectedProject, projects } = useProjectFilter();
  const project = projects.find((p) => p._id === selectedProject);

  const getDates = () => {
    const res = [];
    const _dates = [...datesMonth];
    while (_dates.length > 0) {
      res.push(_dates.splice(0, 7));
    }
    return res;
  };

  const isSelectedDay = (date: Date) => {
    return date >= dates[0] && date <= dates[dates.length - 1];
  };
  const getBackgroundColor = (date: Date) => {
    if (isSelectedDay(date)) {
      return isWeekend(date) ? '#5e72e40d' : 'none';
    } else {
      return '#fafafa';
    }
  };

  const totalHrs = ShiftHelper.getWeekTotalHours(shifts, dates);

  return (
    <>
      <SchedulerTableWrapper>
        <SchedulerTableEl>
          <tbody>
            {getDates().map((week: any, weekIndex: number) => (
              <React.Fragment key={weekIndex}>
                <tr>
                  {weekIndex === 0 && (
                    <RowHeader>
                      <ScheduleHeaderText>
                        Alloted Hours: {project?.allottedHoursPerWeek} hrs
                      </ScheduleHeaderText>
                      <ScheduleHeaderText>Actual Hours: {totalHrs} hrs</ScheduleHeaderText>
                    </RowHeader>
                  )}
                  {weekIndex > 0 && (
                    <RowHeader style={{ width: '250px', verticalAlign: 'middle' }} rowSpan={2}>
                      <ScheduleHeaderText>
                        Alloted Hours: {project?.allottedHoursPerWeek} hrs
                      </ScheduleHeaderText>
                      <ScheduleHeaderText>
                        Actual Hours: {ShiftHelper.getWeekTotalHours(shifts, week)} hrs
                      </ScheduleHeaderText>
                      <ScheduleHeaderText>
                        Off vacay with cover:{' '}
                        {ShiftHelper.getWeekTotalHours(
                          props.offVacationSlotShifts,
                          week,
                          'COVER_OFF_VACATION'
                        )}{' '}
                        hrs
                      </ScheduleHeaderText>
                      <ScheduleHeaderText>
                        Off vacay without cover:{' '}
                        {ShiftHelper.getWeekTotalHours(
                          props.offVacationSlotShifts,
                          week,
                          'OFFVACATION'
                        )}{' '}
                        hrs
                      </ScheduleHeaderText>
                    </RowHeader>
                  )}
                  {week.map((d: Date, dateIndex: number) => (
                    <td key={dateIndex}>
                      <>
                        {weekIndex === 0 && (
                          <ScheduleHeaderText className="day-name">
                            {format(d, 'E')}
                          </ScheduleHeaderText>
                        )}
                        <ScheduleHeaderText className="pt-1">{format(d, 'd/M')}</ScheduleHeaderText>
                        <ScheduleHeaderText>
                          Total: {ShiftHelper.getDateTotalHours(shifts, d)} hrs
                        </ScheduleHeaderText>
                      </>
                    </td>
                  ))}
                </tr>
                <tr>
                  {weekIndex === 0 && (
                    <RowHeader style={{ width: '250px', verticalAlign: 'middle' }} rowSpan={1}>
                      <ScheduleHeaderText>
                        Alloted Hours: {project?.allottedHoursPerWeek} hrs
                      </ScheduleHeaderText>
                      <ScheduleHeaderText>
                        Actual Hours: {ShiftHelper.getWeekTotalHours(shifts, week)} hrs
                      </ScheduleHeaderText>
                      <ScheduleHeaderText>
                        Off vacay with cover:{' '}
                        {ShiftHelper.getWeekTotalHours(
                          props.offVacationSlotShifts,
                          week,
                          'COVER_OFF_VACATION'
                        )}{' '}
                        hrs
                      </ScheduleHeaderText>
                      <ScheduleHeaderText>
                        Off vacay without cover:{' '}
                        {ShiftHelper.getWeekTotalHours(
                          props.offVacationSlotShifts,
                          week,
                          'OFFVACATION'
                        )}{' '}
                        hrs
                      </ScheduleHeaderText>
                    </RowHeader>
                  )}

                  {week.map((d: Date, index: number) => (
                    <td
                      key={index}
                      style={{
                        background: getBackgroundColor(d),
                        height: '200px'
                      }}
                    >
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        {project &&
                          project.divisions.map((division: Division, dateIndex: number) => (
                            <li key={dateIndex}>
                              <DivisionContent style={{ borderColor: division.color || '#32539c' }}>
                                {ShiftHelper.getDateTotalHoursByDivision(shifts, d, division.name)}{' '}
                                hrs
                              </DivisionContent>
                            </li>
                          ))}
                      </ul>
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </SchedulerTableEl>
      </SchedulerTableWrapper>
    </>
  );
};

export default SchedulerCompact;
