import { Col, Form } from 'react-bootstrap';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';

interface Props {
  data: any;
}

const ShiftChangeDetailSwap = (props: Props) => {
  const { data } = { ...props };
  const getDate = (value: string | undefined) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };
  const getShift = (value: string | undefined) => {
    return value;
  };

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`owner`}>
          <Form.Label className="required">Shift Owner</Form.Label>
          <Form.Control type="text" value={data.owner} disabled />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId={`agent[0]`}>
          <Form.Label className="required">Corresponding Agent</Form.Label>
          <Form.Control type="text" value={data.agent[0]} disabled />
        </Form.Group>
        {data.employeeType === 'FullTime <> PartTime' && (
          <Form.Group as={Col} className="mb-3" controlId={`agent1`}>
            <Form.Label className="required">Corresponding Agent</Form.Label>
            <Form.Control type="text" value={data.agent[1]} disabled />
            {/* <Form.Control
              as="select"
              placeholder="Select Agent"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
              name="agent1"
              value={data.agent[1]}
            >
              <option value="">Select Agent</option>
              {projectUsers.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.username}
                </option>
              ))}
            </Form.Control> */}
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`ownerShift`}>
          <Form.Label className="required">Current Shift</Form.Label>
          <Form.Control type="text" value={data.ownerShift} disabled />
          {/* <Form.Control
            as="select"
            placeholder="Select Shift"
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
            name="ownerShift"
            value={data.ownerShift}
          >
            <option value="">Select Shift</option>
            {shifts.map((shift) => (
              <option key={shift._id} value={shift._id}>
                {shift.name}
              </option>
            ))}
          </Form.Control> */}
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId={`agentShift[0]`}>
          <Form.Label className="required">Current Shift</Form.Label>
          <Form.Control type="text" value={data.agentShift[0]} disabled />
        </Form.Group>
        {data.employeeType === 'FullTime <> PartTime' && (
          <Form.Group as={Col} className="mb-3" controlId={`agentShift[1]`}>
            <Form.Label className="required">Current Shift</Form.Label>
            <Form.Control type="text" value={data.agentShift[1]} disabled />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="ownerDate">
          <Form.Label className="text-start required">Date</Form.Label>
          <Form.Control type="text" value={getDate(data.ownerDate)} disabled />
        </Form.Group>

        <Form.Group as={Col} className="mb-3" controlId="agentDate[0]">
          <Form.Label className="text-start required">Date</Form.Label>
          <Form.Control type="text" value={getDate(data.agentDate[0])} disabled />
        </Form.Group>

        {data.employeeType === 'FullTime <> PartTime' && (
          <Form.Group as={Col} className="mb-3" controlId="agentDate[1]">
            <Form.Label className="text-start required">Date</Form.Label>
            <Form.Control type="text" value={getDate(data.agentDate[1])} disabled />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId={`ownerNewShift`}>
          <Form.Label className="required">New Shift</Form.Label>
          <Form.Control type="text" value={getShift(data.ownerNewShift)} disabled />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId={`agentNewShift[0]`}>
          <Form.Label className="required">New Shift</Form.Label>
          <Form.Control type="text" value={getShift(data.agentNewShift[0])} disabled />
        </Form.Group>
        {data.employeeType === 'FullTime <> PartTime' && (
          <Form.Group as={Col} className="mb-3" controlId={`agentNewShift[1]`}>
            <Form.Label className="required">New Shift</Form.Label>
            <Form.Control type="text" value={getShift(data.agentNewShift[1])} disabled />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="ownerNewDate">
          <Form.Label className="text-start required">New Date</Form.Label>
          <Form.Control type="text" value={getDate(data.ownerNewDate)} disabled />
        </Form.Group>
        <Form.Group as={Col} className="mb-3" controlId="agentNewDate[0]">
          <Form.Label className="text-start required">New Date</Form.Label>
          <Form.Control type="text" value={getDate(data.agentNewDate[0])} disabled />
        </Form.Group>

        {data.employeeType === 'FullTime <> PartTime' && (
          <Form.Group as={Col} className="mb-3" controlId="agentNewDate[1]">
            <Form.Label className="text-start required">New Date</Form.Label>
            <Form.Control type="text" value={getDate(data.agentNewDate[1])} disabled />
          </Form.Group>
        )}
      </Form.Row>
    </>
  );
};

export default ShiftChangeDetailSwap;
