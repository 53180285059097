import { ShiftHelper } from 'helpers';
import { useProjectFilter } from 'hooks/scheduler/useProjectFilter';
import { FixedShift, WeekDay } from 'models';
import { useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { SchedulerHeaderItem, SchedulerHeaderWrapper } from './SchedulerHeader.styled';

interface Props {
  shifts: FixedShift[];
}

const SchedulerHeader = (props: Props) => {
  const { shifts } = { ...props };
  const { selectedProject, projects } = useProjectFilter();
  const getWeekDay = () => {
    const weekDef = useAppSelector(projectSelector.selectSelectedProject)?.weekDefinition || 0;
    if (weekDef !== 0) {
      const weekdays = Object.values(WeekDay).filter((d) => typeof d === 'number');
      const firstArr = weekdays.slice(weekDef - 1);
      const secondArr = weekdays.slice(0, weekDef - 1);
      const res = [...firstArr, ...secondArr];
      return res.map((weekday) => WeekDay[weekday as any]);
    }
    return Object.keys(WeekDay).filter((d) => isNaN(parseInt(d)));
  };

  const projectWorkingHrsInfo = () => {
    const project = projects.find((p) => p._id === selectedProject);
    const totalHrs = ShiftHelper.getFixedWeekTotalHours(shifts);
    return (
      <>
        Alloted Hrs: {project?.allottedHoursPerWeek}
        <br />
        Total Hrs: {totalHrs}
      </>
    );
  };

  return (
    <SchedulerHeaderWrapper>
      <SchedulerHeaderItem>{projectWorkingHrsInfo()}</SchedulerHeaderItem>
      {getWeekDay().map((d: any, index: number) => (
        <SchedulerHeaderItem key={index}>
          <>
            {d}
            <br />
            {ShiftHelper.getFixedWeekdayTotalHours(shifts, d)}
          </>
        </SchedulerHeaderItem>
      ))}
    </SchedulerHeaderWrapper>
  );
};

export default SchedulerHeader;
