import cloneDeep from 'lodash.clonedeep';
import { SpinnerButton } from 'components/Shared';
import { Project, User, ButtonTypes } from 'models';
import { useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userSelector } from 'store/users/user.slice';
import { CustomRow } from '../ModalAddUser/ModalAddUser.styled';
import UserBoard from '../ModalAddUser/UserBoard/UserBoard';
import UserSortByAlphabet from '../ModalAddUser/UserSortByAlphabet/UserSortByAlphabet';
import { projectSelector, projectActions } from 'store/projects/project.slice';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { toast } from 'react-toastify';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';

interface Props {
  formik: any;
  project: Project | undefined;
}
const ModalReviewUser = (props: Props) => {
  const dispatch = useAppDispatch();
  const [selectedAlphabet, setSelectedAlphabet] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [search, setSearch] = useState<string>('');
  const isFetchingUser = useAppSelector(userSelector.selectIsFetching);
  const isUpdatingProjectUsers = useAppSelector(projectSelector.selectIsUpdatingProjectUsers);
  const updateProjectUsersError = useAppSelector(projectSelector.selectError);
  const users = [...props.formik.values.users];

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = () => {
    setSelectedUsers([]);
    setIsVisible(!isVisible);
  };

  const onRemove = () => {
    const _users = users.filter(
      (u: User) => selectedUsers.find((usr) => usr._id === u._id) === undefined
    );
    props.formik.setFieldValue('users', _users);
    const payload = { ...props.project, users: _users };
    if (props.project) {
      dispatch(
        projectActions.updateProjectUsers({
          ...payload,
          id: props.project._id
        })
      );
    } else {
      setIsVisible(false);
    }
  };

  useDidMountEffect(() => {
    if (props.project && !isUpdatingProjectUsers && !updateProjectUsersError) {
      toast.success("Update Project's Users Successfully");
      setIsVisible(false);
    }
  }, [isUpdatingProjectUsers]);

  useDidMountEffect(() => {
    if (!isUpdatingProjectUsers && updateProjectUsersError) {
      toast.error(updateProjectUsersError?.message || 'Something went wrong!');
    }
  }, [updateProjectUsersError]);

  const onSelectAlphabet = (value: string[]) => {
    setSelectedAlphabet(value);
  };

  const getDisplayedUser = () => {
    if (search.length === 0 && selectedAlphabet.length === 0) {
      return users;
    }

    return cloneDeep(
      users.filter((u) => {
        const username = u.username.toLowerCase();

        if (search.length > 0 && selectedAlphabet.length === 0) {
          return username.includes(search.toLowerCase());
        } else if (search.length === 0 && selectedAlphabet.length > 0) {
          return selectedAlphabet.find((char) => char.toLowerCase() === username[0]);
        } else {
          return (
            selectedAlphabet.find((char) => char.toLowerCase === username[0]) &&
            username.includes(search.toLowerCase())
          );
        }
      })
    );
  };

  return (
    <>
      <Button variant="warning" onClick={toggleVisible}>
        Review All Agents
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={toggleVisible}
        size="lg"
        centered
        scrollable={true}
        dialogAs={DraggableModalDialog}
      >
        {!isFetchingUser && (
          <Modal.Body>
            <CustomRow>
              <Col xs="9">
                <Modal.Title>Review All Agents</Modal.Title>
              </Col>
              <Col xs="3" className="text-right">
                <Form.Control
                  type="text"
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                  }}
                />
              </Col>
            </CustomRow>
            <UserSortByAlphabet
              selectedAlphabet={selectedAlphabet}
              onSelectAlphabet={setSelectedAlphabet}
            />
            <UserBoard
              users={getDisplayedUser()}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              type="review"
            />
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={toggleVisible}>
            Cancel
          </Button>
          {!props.project && (
            <Button variant="primary" onClick={onRemove}>
              Remove
            </Button>
          )}
          {props.project && (
            <SpinnerButton
              btnType={ButtonTypes.Submit}
              disabled={isUpdatingProjectUsers}
              onClick={onRemove}
            >
              Remove
            </SpinnerButton>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalReviewUser;
