import { PayloadAction } from '@reduxjs/toolkit';
import { ScheduleGlance } from 'models/scheduleGlance.model';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { scheduleGlanceApi } from 'services/scheduleGlance.api';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';
import { scheduleGlanceActions, scheduleGlanceSelector } from './scheduleGlance.slice';

export function* getScheduleGlance({ payload }: PayloadAction<any>) {
  try {
    const response: ScheduleGlance[] = yield call(scheduleGlanceApi.getScheduleGlance, payload);
    yield put(scheduleGlanceActions.getScheduleGlanceSuccess(response as ScheduleGlance[]));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(scheduleGlanceActions.getScheduleGlanceFailed(e));
  }
}

export default function* scheduleGlanceSaga() {
  yield takeLatest(scheduleGlanceActions.getScheduleGlance, getScheduleGlance);
}
