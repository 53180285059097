import { Nullable } from '../../utils/common/types';
import { PendingCount, ShiftChange, PaginationInfo, RequestLog } from 'models';

export type ShiftChangeInitialState = {
  shiftChanges: ShiftChange[];
  onHoldData: ShiftChange[];
  pending: PendingCount | null;
  pendingProjectCount: any;
  onholdProjectCount: any;
  error: Nullable<string>;
  isFetching: boolean;
  isFetchingOnHoldData: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  approvedShiftChange: ShiftChange[];
  rejectShiftChange: ShiftChange[];
  approvedShiftChangePaginationInfo: PaginationInfo;
  rejectShiftChangePaginationInfo: PaginationInfo;
  approvedRequestLog: RequestLog[];
  rejectedRequestLog: RequestLog[];
};

export const initialState: ShiftChangeInitialState = {
  shiftChanges: [],
  onHoldData: [],
  pending: null,
  pendingProjectCount: [],
  onholdProjectCount: [],
  error: null,
  isFetching: false,
  isFetchingOnHoldData: false,
  isCreating: false,
  isUpdating: false,
  approvedShiftChange: [],
  rejectShiftChange: [],
  approvedShiftChangePaginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  },
  rejectShiftChangePaginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  },
  approvedRequestLog: [],
  rejectedRequestLog: []
};
