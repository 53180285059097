// interface Props {}

import { FormikProps, useFormik } from 'formik';
import { useAuth } from 'hooks';
import { FixedShift, ShiftChange, ShiftChangeFormProps, User } from 'models';
import { ScheduleGlance } from 'models/scheduleGlance.model';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fixedShiftActions, fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import {
  scheduleGlanceActions,
  scheduleGlanceSelector
} from 'store/scheduleGlance/scheduleGlance.slice';
import { selectError, selectIsCreating } from 'store/shiftChanges/shiftChange.selector';
import { createShiftChange } from 'store/shiftChanges/shiftChange.slice';
import { formatTo_yyyyMMdd, getReadableDurationString } from 'utils/formatters/datetime.formatter';
import {
  shiftChangeSchema,
  shiftCoverSchema,
  shiftMoveSchema,
  shiftReplaceSchema
} from 'utils/validators';

const initialValues: ShiftChangeFormProps = {
  type: 'SWAP',
  employeeType: 'NULL',
  project: '',
  owner: '',
  agent: ['', ''],
  ownerShift: '',
  agentShift: ['', ''],
  ownerNewShift: '',
  agentNewShift: ['', ''],
  ownerDate: '',
  agentDate: ['', ''],
  ownerNewDate: '',
  agentNewDate: ['', ''],
  ownerTime: '',
  agentTime: '',
  reason: '',
  offVacation: false,
  createdBy: '',
  createdAt: ''
};

export const useShiftChangeAgentView = () => {
  const dispatch = useAppDispatch();
  const currentUser: User = useAuth();
  const projects = useAppSelector(projectSelector.selectProjects);
  const shifts: FixedShift[] = useAppSelector(fixedShiftSelector.selectShifts);
  const isCreating = useAppSelector(selectIsCreating);
  const error = useAppSelector(selectError);

  const [selectedProject, setSelectedProject] = useState('');
  const [projectUsers, setProjectUsers] = useState<User[]>([]);
  const [ownerOrAgentChange, setOwnerOrAgentChange] = useState('');
  const [glanceDateArr, setGlanceDateArr] = useState<string[]>([]);
  const [shiftStartTime, setShiftStartTime] = useState('');
  const [shiftEndTime, setShiftEndTime] = useState('');
  const [selectedType, setSelectedType] = useState('SWAP');
  const scheduleGlances: ScheduleGlance[] = useAppSelector(
    scheduleGlanceSelector.selectScheduleGlance
  );
  const [scheduleGlancesOwner, setScheduleGlancesOwner] = useState<ScheduleGlance[]>([]);
  const [scheduleGlancesAgent, setScheduleGlancesAgent] = useState<ScheduleGlance[]>([]);

  const getValidationSchema = () => {
    switch (selectedType) {
      case 'SWAP':
        return shiftChangeSchema;
      case 'COVER':
        return shiftCoverSchema;
      case 'REPLACE':
        return shiftReplaceSchema;
      case 'MOVE':
        return shiftMoveSchema;
      default:
        return shiftChangeSchema;
    }
  };

  const formik: FormikProps<ShiftChangeFormProps> = useFormik<ShiftChangeFormProps>({
    initialValues: initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: (values: ShiftChangeFormProps) => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    dispatch(projectActions.getProjects());
  }, []);

  useEffect(() => {
    if (formik.isSubmitting && !isCreating && error === null) {
      formik.setSubmitting(false);
      toast.success('Created Successfully.');
      formik.resetForm();
    }
  }, [isCreating]);

  useEffect(() => {
    if (error !== null) {
      toast.error((error as any)?.message || 'Something went wrong.');
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    dispatch(fixedShiftActions.getShifts(selectedProject));
    formik.setFieldValue('createdBy', currentUser._id);
  }, [selectedProject]);

  const dispatchScheduleGlance = (dateStr: string, selectedProject: string, agent: string) => {
    const day = new Date(dateStr);
    const yesterday = new Date(day);
    yesterday.setDate(day.getDate() - 1);
    const tomorrow = new Date(day);
    tomorrow.setDate(day.getDate() + 1);
    const arr = [formatTo_yyyyMMdd(yesterday), formatTo_yyyyMMdd(tomorrow)];
    dispatch(
      scheduleGlanceActions.getScheduleGlance({
        project: selectedProject,
        agent: agent,
        dates: arr
      })
    );
    const glanceDateArr = [
      getReadableDurationString(yesterday),
      getReadableDurationString(day),
      getReadableDurationString(tomorrow)
    ];
    setGlanceDateArr(glanceDateArr);
  };

  useEffect(() => {
    // let yesterday = new Date(new Date(formik.values.ownerDate) - 864e5);
    if (formik.values.ownerDate && formik.values.ownerDate !== '') {
      dispatchScheduleGlance(formik.values.ownerDate, selectedProject, formik.values.owner);
      setOwnerOrAgentChange('ownerDate');
    }
  }, [formik.values.ownerDate]);

  useEffect(() => {
    if (formik.values.agentDate[0] && formik.values.agentDate[0] !== '') {
      dispatchScheduleGlance(formik.values.agentDate[0], selectedProject, formik.values.agent[0]);
      setOwnerOrAgentChange('agentDate0');
    }
  }, [formik.values.agentDate[0]]);

  useEffect(() => {
    if (ownerOrAgentChange === 'ownerDate') {
      setScheduleGlancesOwner(scheduleGlances);
    } else {
      setScheduleGlancesAgent(scheduleGlances);
    }
  }, [scheduleGlances]);

  const onDateChange = (field: string, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
  };

  const onSelectAgent = (index: number, e: any) => {
    formik.setFieldValue(`agent[${index}]`, e.target.value);
  };

  const onSelectAgentShift = (index: number, e: any) => {
    formik.setFieldValue(`agentShift[${index}]`, e.target.value);
  };

  const onSelectAgentNewShift = (index: number, e: any) => {
    formik.setFieldValue(`agentNewShift[${index}]`, e.target.value);
  };

  const onSelectAgentDate = (field: string, index: number, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
    formik.setFieldValue(`agentDate[${index}]`, formattedDate);
  };

  const onSelectAgentNewDate = (field: string, index: number, item: any) => {
    const formattedDate = formatTo_yyyyMMdd(item);
    formik.setFieldValue(field, formattedDate);
    formik.setFieldValue(`agentNewDate[${index}]`, formattedDate);
  };

  const handleSubmit = (values: ShiftChangeFormProps) => {
    const {
      agent,
      agentDate,
      agentNewDate,
      agentShift,
      agentNewShift,
      ownerNewDate,
      ownerNewShift
    } = {
      ...values
    };
    const agentPayload = agent.filter((a) => a.length);
    const payload: ShiftChangeFormProps = {
      ...values,
      agent: agent.filter((a) => a.length),
      agentDate: agentDate.filter((a) => a.length),
      agentNewDate: agentNewDate.filter((a) => a.length),
      agentShift: agentShift.filter((a) => a.length),
      agentNewShift: agentNewShift.filter((a) => a.length),
      ownerNewDate: ownerNewDate ? ownerNewDate : formatTo_yyyyMMdd(new Date())
      // ownerNewShift: ownerNewShift ? ownerNewShift : shifts[0]._id
    };
    dispatch(createShiftChange(payload));
    // formik.resetForm();
  };

  const onChangeProject = (projectId: string) => {
    setSelectedProject(projectId);
    setProjectUsers(projects.find((project) => project._id === projectId)?.users || []);
    formik.setFieldValue('project', projectId);
  };

  return {
    formik,
    handleSubmit,
    onChangeProject,
    projects,
    projectUsers,
    onSelectAgent,
    onSelectAgentShift,
    onSelectAgentNewShift,
    onSelectAgentDate,
    onSelectAgentNewDate,
    onDateChange,
    shifts,
    scheduleGlancesOwner,
    scheduleGlancesAgent,
    glanceDateArr,
    shiftStartTime,
    shiftEndTime,
    selectedType,
    setSelectedType
  };
};
