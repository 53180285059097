import ModalConfirm from 'components/SchedulerFix/Actions/ModalConfirm/ModalConfirm';
import ModalConfirmChange from 'components/SchedulerFix/Actions/ModalConfirmChange/ModalConfirmChange';
import ShiftInformation from 'components/SchedulerFix/FormActions/ShiftInformation/ShiftInformation';
import ButtonAddEmployee from 'hooks/scheduler/FormActions/ButtonAddEmployee';
import InputEmployeeRow from '../../FormActions/InputEmployeeRow/InputEmployeeRow';

import { FieldArray, FormikProvider } from 'formik';
import { FixedShift, WeekDay } from 'models';
import React, { useEffect } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import FormButtons from 'components/SchedulerFix/FormActions/FormButtons/FormButtons';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';
import { ShiftHelper } from 'helpers';
import { useModalCreateSlot } from './useModalCreateSlot';
import ModalConfirmEmptySlot from 'hooks/scheduler/ModalConfirmEmptySlot';

interface Props {
  show: boolean;
  shifts: FixedShift[];
  date: number;
  onHide: () => void;
  fixedSlotsIndex: number;
  isAddingMoreIndex: boolean;
}

interface FormProps {
  weekday: number;
  shiftId: string;
  employees: [
    [
      {
        userId: string;
        duration: string;
        note: string;
      }
    ]
  ];
}

const ModalCreateSlot = (props: Props) => {
  const { show, shifts, date, onHide, fixedSlotsIndex, isAddingMoreIndex } = { ...props };
  const {
    isShowConfirmModal,
    setIsShowConfirmModal,
    isShowConfirmChangeModal,
    setIsShowConfirmChangeModal,
    modalConfirmEmptyVisible,
    setModalConfirmEmptyVisible,
    formik,
    onDateChange,
    onChangeShift,
    getShiftDuration,
    shiftId,
    initialValues,
    changingValue,
    onProceed,
    onKeepGoing,
    submittingType,
    setSubmittingType,
    project,
    setScheduleFixedIndex
  } = useModalCreateSlot(props);

  useEffect(() => {
    setScheduleFixedIndex(isAddingMoreIndex);
  }, []);

  const hasDivision = () => {
    return project && project.divisions.length > 0;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        centered
        className="handle"
        scrollable
        dialogAs={DraggableModalDialog}
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Assign Employee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <FormikProvider value={formik}>
            <Form>
              <Form.Row>
                <Form.Group as={Col} xs={6} lg={3} controlId="date">
                  <Form.Label className="text-start">Date</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Date"
                    onBlur={formik.handleBlur}
                    onChange={(e) => onDateChange(e)}
                    onMouseDown={(e: any) => {
                      e.stopPropagation();
                    }}
                    name="date"
                    value={date}
                    isInvalid={formik.touched.weekday && !!formik.errors.weekday}
                    custom
                  >
                    {Object.keys(WeekDay)
                      .filter((d) => isNaN(parseInt(d)))
                      .map((day: any) => (
                        <option key={WeekDay[day]} value={WeekDay[day]}>
                          {day}
                        </option>
                      ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.weekday}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={6} lg={{ span: 2, offset: 1 }} controlId="shift">
                  <Form.Label className="text-start">Shift</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Employeee"
                    onBlur={formik.handleBlur}
                    onChange={(e) => onChangeShift(e)}
                    name={`shiftId`}
                    value={formik.values.shiftId}
                    custom
                    onMouseDown={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    {props.shifts.map((shift) => (
                      <option key={shift._id} value={shift._id}>
                        {shift.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <ShiftInformation
                  shifts={shifts}
                  shiftDuration={ShiftHelper.getShiftDuration(shifts, shiftId)}
                  formik={formik}
                />
              </Form.Row>
              <Row>
                <Col
                  xs={hasDivision() ? 3 : 4}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  Employee Name
                </Col>
                {project && project.divisions.length > 0 && (
                  <Col
                    xs={2}
                    style={{ paddingLeft: '8px' }}
                    onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                    }}
                  >
                    Division
                  </Col>
                )}
                <Col
                  xs={hasDivision() ? 2 : 3}
                  style={{ paddingLeft: '8px' }}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  Duration
                </Col>
              </Row>
              <FieldArray
                name="employees"
                render={(arrayHelpers) => (
                  <>
                    {formik.values.employees.map((agentCount: any, agentIndex: number) => (
                      <React.Fragment key={agentIndex}>
                        <FieldArray
                          name={`employees[${agentIndex}]`}
                          render={(agentCountHelpers) => (
                            <>
                              {agentCount.map((user: any, userIndex: number) => (
                                <InputEmployeeRow
                                  key={`${agentIndex + userIndex}`}
                                  agentCount={agentCount}
                                  agentIndex={agentIndex}
                                  userIndex={userIndex}
                                  formik={formik}
                                  shifts={shifts}
                                  date={date}
                                  shiftId={shiftId}
                                  arrayHelpers={arrayHelpers}
                                  agentCountHelpers={agentCountHelpers}
                                  project={project}
                                />
                              ))}
                              {agentCount[0].userId === 'multiple' && (
                                <ButtonAddEmployee
                                  isAddingSubEmpl={true}
                                  agentCountHelpers={agentCountHelpers}
                                  shiftDuration={ShiftHelper.getShiftDuration(shifts, shiftId)}
                                />
                              )}
                            </>
                          )}
                        />
                      </React.Fragment>
                    ))}
                    <ButtonAddEmployee
                      isAddingSubEmpl={false}
                      arrayHelpers={arrayHelpers}
                      shiftDuration={ShiftHelper.getShiftDuration(shifts, shiftId)}
                    />
                  </>
                )}
              />
            </Form>
          </FormikProvider>
          <ModalConfirm
            show={isShowConfirmModal}
            shifts={shifts}
            shiftId={shiftId}
            date={date}
            values={formik.values}
            onHide={() => setIsShowConfirmModal(false)}
            onSubmit={() => formik.submitForm()}
          />
          <ModalConfirmChange
            show={isShowConfirmChangeModal}
            formik={formik}
            initialValues={initialValues}
            changingValue={changingValue}
            onProceed={onProceed}
            onKeepGoing={onKeepGoing}
            onHide={() => setIsShowConfirmChangeModal(false)}
          />
          <ModalConfirmEmptySlot
            formik={formik}
            show={modalConfirmEmptyVisible}
            setShow={(show) => setModalConfirmEmptyVisible(show)}
          />
        </Modal.Body>
        <Modal.Footer>
          <FormButtons
            formik={formik}
            shifts={shifts}
            shiftId={shiftId}
            date={date}
            submittingType={submittingType}
            setSubmittingType={(value: string) => setSubmittingType(value)}
            setIsShowConfirmModal={(value: boolean) => setIsShowConfirmModal(value)}
            setModalConfirmEmptyVisible={(value: boolean) => setModalConfirmEmptyVisible(value)}
            onHide={onHide}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCreateSlot;
