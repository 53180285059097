import { Form, Col } from 'react-bootstrap';
import { Project, UserSlotFormProps } from 'models';

interface Props {
  formik: any;
  agentIndex: number;
  userIndex: number;
  agentCount: any;
  shiftDuration: number;
  project: Project | null;
}

const InputDuration = (props: Props) => {
  const { formik, agentIndex, userIndex, agentCount, shiftDuration, project } = { ...props };

  const getUserSlot = () => {
    return formik.values.employees[agentIndex][userIndex];
  };

  const getDuration = () => {
    if (getUserSlot().userId === 'multiple') {
      const userSlots = formik.values.employees[agentIndex].filter(
        (userSlot: any) => userSlot.userId !== 'multiple'
      );
      const res =
        userSlots.reduce((a: any, b: any) => {
          return parseFloat(a.toString()) + parseFloat(b.duration.toString() || 0);
        }, 0) || 0;
      return res === 0 ? shiftDuration : res;
    }
    return getUserSlot().duration;
  };

  const hasDivision = () => {
    return project && project.divisions.length > 0;
  };

  return (
    <Form.Group
      as={Col}
      xs={hasDivision() ? 2 : 3}
      className="mb-3"
      controlId={`user-[${userIndex}].duration`}
    >
      <Form.Control
        type="number"
        placeholder="Enter Duration"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        name={`employees[${agentIndex}][${userIndex}].duration`}
        value={getDuration()}
        onMouseDown={(e: any) => {
          e.stopPropagation();
        }}
        step="0.5"
        isInvalid={
          formik.touched.employees?.[agentIndex]?.[userIndex]?.duration &&
          (formik.errors.employees?.[agentIndex]?.[userIndex]
            ? (formik.errors.employees[agentIndex][userIndex] as UserSlotFormProps)?.duration
            : undefined) !== undefined
        }
        lang="en"
      />
      <Form.Control.Feedback type="invalid">
        {formik.errors.employees?.[agentIndex]?.[userIndex]
          ? (formik.errors.employees[agentIndex][userIndex] as UserSlotFormProps)?.duration
          : undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
export default InputDuration;
