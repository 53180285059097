import { AppState } from '../index.store';

export const selectIsFetching = (state: AppState) => state.shifts.isFetching;

export const selectShifts = (state: AppState) => state.shifts.shifts;

export const selectGetShiftsPayload = (state: AppState) => state.shifts.getShiftsPayload;

export const selectSelectedShift = (state: AppState) => state.shifts.selectedShift;

export const selectError = (state: AppState) => state.shifts.error;

export const selectOffVacationSlotShifts = (state: AppState) => state.shifts.offVacationSlotShifts;
