import { useAuth } from 'hooks';
import { OffVacationRequestLog } from 'models';
import { Badge, Button, Col, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';

interface Props {
  data: OffVacationRequestLog;
  selectedType: string;
}

const OffVacationRequestLogDetail = (props: Props) => {
  const { data, selectedType } = { ...props };
  const currentUser = useAuth();
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedDatesStatus, setSelectedDatesStatus] = useState<string[]>([]);
  // const [data, setData] = useState<OffVacation>();

  const onLoadConsecutiveDay = (dates?: string[], datesStatus?: string[]) => {
    if (dates) setSelectedDates(dates);
    if (datesStatus) setSelectedDatesStatus(datesStatus);
  };

  const getDate = (value: string | undefined) => {
    return value ? formatTo_yyyyMMdd(new Date(value)) : '';
  };

  useEffect(() => {
    onLoadConsecutiveDay(data.dates, data.datesStatus);
  }, []);

  return (
    <Form>
      <Form.Row>
        <Form.Group as={Col} xs={6} className="mb-3" controlId="type">
          <Form.Label className="required">Request Type</Form.Label>
          <Form.Control type="text" value={data?.type} disabled />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6} className="mb-3" controlId="requester">
          <Form.Label className="required">Requester</Form.Label>
          <Form.Control type="text" value={data?.requester?.displayName} disabled />
        </Form.Group>
        <Form.Group as={Col} xs={6} className="mb-3" controlId="project">
          <Form.Label className="required">Project</Form.Label>
          <Form.Control type="text" value={data?.project?.name} disabled />
        </Form.Group>
      </Form.Row>
      {(data?.type === 'ONEDAY' || data?.type === 'SOMEHOURS') && (
        <Form.Row>
          <Form.Group as={Col} className="mb-3" controlId="date" xs={6}>
            <Form.Label className="text-start required">Date</Form.Label>
            <Form.Control type="text" value={getDate(data?.date)} disabled />
          </Form.Group>
        </Form.Row>
      )}
      {data?.type === 'CONSECUTIVEDAY' && (
        <>
          <Form.Row>
            <Form.Group as={Col} controlId="date" xs={12} className="mb-0">
              <Form.Label className="text-start required mr-2">Date</Form.Label>
              <div className="mb-3">
                {selectedDates.map((date, index) => (
                  <>
                    <Badge pill key={date} className="mr-1" variant="primary">
                      {getDate(date)}
                    </Badge>
                  </>
                ))}
              </div>
              <div className="mb-3">
                {selectedDatesStatus.map((dateStatus, index) => (
                  <>
                    <Badge
                      pill
                      key={dateStatus}
                      className="mr-1"
                      variant={dateStatus === 'REJECTED' ? 'danger' : 'primary'}
                    >
                      {dateStatus}
                    </Badge>
                  </>
                ))}
              </div>
            </Form.Group>
          </Form.Row>
        </>
      )}
      {data?.type === 'SOMEDAY' && (
        <>
          <Form.Row>
            <Form.Group as={Col} controlId="date" xs={12} className="mb-0">
              <Form.Label className="text-start required mr-2">Date</Form.Label>
            </Form.Group>
          </Form.Row>
          <div className="mb-3">
            {selectedDates.map((date, index) => (
              <>
                <Badge pill key={date} className="mr-1" variant="primary">
                  {getDate(date)}
                </Badge>
              </>
            ))}
          </div>
          <div className="mb-3">
            {selectedDatesStatus.map((dateStatus, index) => (
              <>
                <Badge
                  pill
                  key={dateStatus}
                  className="mr-1"
                  variant={dateStatus === 'REJECTED' ? 'danger' : 'primary'}
                >
                  {dateStatus}
                </Badge>
              </>
            ))}
          </div>
        </>
      )}
      {data?.type === 'SOMEHOURS' && (
        <Form.Row>
          <Form.Group as={Col} className="mb-3" controlId="from">
            <Form.Label>From</Form.Label>
            <Form.Control type="text" value={data?.from} disabled />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="to">
            <Form.Label>To</Form.Label>
            <Form.Control type="text" value={data?.to} disabled />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="reason">
          <Form.Label className="required">Reason</Form.Label>
          <Form.Control type="text" value={data?.reason} disabled />
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default OffVacationRequestLogDetail;
