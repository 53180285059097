import ApiService from '../utils/network';
import {
  AttendanceCheckInPayload,
  AttendanceCheckoutPayload,
  AttendanceCoveringPayload,
  GetAttendancesPayload
} from '../models';
import { API_URL } from './url';

export const attendanceApi = {
  getAttendances(payload: GetAttendancesPayload) {
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.ATTENDANCE}`, { params: payload });
  },
  checkin(payload: AttendanceCheckInPayload) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.ATTENDANCE}/check-in`, payload);
  },
  covering(payload: AttendanceCoveringPayload) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.ATTENDANCE}/check-in/covering`, payload);
  },
  checkout(payload: AttendanceCheckoutPayload) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.ATTENDANCE}/check-out`, payload);
  }
};
