import { PayloadAction } from '@reduxjs/toolkit';
import format from 'date-fns/format';
import {
  BulkUpdateUsersPayload,
  CreateUserPayload,
  GetUsersPayload,
  PaginationInfo,
  RemoveProjectFromUserPayload,
  ResetPasswordUserPayload,
  UpdateStatusUserPayload,
  UpdateUserPayload,
  User,
  UserSameProject
} from 'models';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { userApi } from 'services';
import { userActions, userSelector } from './user.slice';

export function* setUsersWorker({ payload }: PayloadAction<User[]>) {
  try {
    yield put(userActions.setUsersSuccess(payload));
  } catch (e) {
    console.log(e);
  }
}

export function* getUsersWorker({ payload }: PayloadAction<GetUsersPayload>) {
  try {
    const response: UserResponse = yield call(userApi.getUsers, payload);
    yield put(userActions.getUsersSuccess(response.data));
  } catch (e) {
    console.log('getUsersWorker err: ', e);
    yield put(userActions.getUsersFailed(e));
  }
}

interface UserResponse {
  data: User[];
  pagination: any;
}

export function* getUsersModalAssignWorker({ payload }: PayloadAction<GetUsersPayload>) {
  try {
    const response: UserResponse = yield call(userApi.getUsers, payload);
    yield put(userActions.getUsersModalAssignSuccess(response.data));
    yield put(
      userActions.setPaginationInfoSuccess({
        info: response.pagination as PaginationInfo,
        role: payload.role as string
      })
    );
  } catch (e) {
    console.log('getUsersWorker err: ', e);
    yield put(userActions.getUsersModalAssignFailed(e));
  }
}

export function* setIsModalCreateVisibleWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(userActions.setIsModalCreateVisibleSuccess(payload));
  } catch (e) {
    console.log('setIsModalUpdateVisibleWorker err: ', e);
    yield put(userActions.getUsersModalAssignFailed(e));
  }
}

export function* setIsModalUpdateVisibleWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(userActions.setIsModalUpdateVisibleSuccess(payload));
    console.log('saga');
  } catch (e) {
    console.log('setIsModalUpdateVisibleWorker err: ', e);
    // yield put(userActions.getUsersModalAssignFailed(e));
  }
}

export function* setIsModalResetPasswordVisibleWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(userActions.setIsModalResetPasswordVisibleSuccess(payload));
  } catch (e) {
    console.log('setIsModalResetPasswordVisibleWorker err: ', e);
    // yield put(userActions.getUsersModalAssignFailed(e));
  }
}

export function* setIsModalDeleteVisibleWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(userActions.setIsModalDeleteVisibleSuccess(payload));
  } catch (e) {
    console.log('setIsModalDeleteVisibleWorker err: ', e);
    // yield put(userActions.getUsersModalAssignFailed(e));
  }
}

export function* setSelectedUserWorker({ payload }: PayloadAction<User>) {
  try {
    yield put(userActions.setSelectedUserSuccess(payload));
  } catch (e) {
    console.log('setSelectedUserWorker err: ', e);
    yield put(userActions.setSelectedUserFailed(e));
  }
}

export function* setSelectedUsersWorker({ payload }: PayloadAction<string[]>) {
  try {
    yield put(userActions.setSelectedUsersSuccess(payload));
  } catch (e) {
    console.log('setSelectedUsersWorker err: ', e);
    yield put(userActions.setSelectedUsersFailed(e));
  }
}
export function* setSelectedAllWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(userActions.setSelectedAllSuccess(payload));
  } catch (e) {
    console.log('setSelectedAllWorker err: ', e);
    yield put(userActions.setSelectedAllFailed(e));
  }
}

export function* createUserWorker({ payload }: PayloadAction<CreateUserPayload>) {
  try {
    const response: User = yield call(userApi.createUser, payload);
    yield put(userActions.createUserSuccess(response));
    const getUsersPayload: GetUsersPayload = yield select(
      userSelector.selectGetUsersModalAssignParams
    );
    yield put(userActions.getUsersModalAssign(getUsersPayload));
  } catch (e) {
    console.log('createUserWorker err: ', e);
    yield put(userActions.createUserFailed(e));
  }
}

export function* resetPasswordUserWorker({ payload }: PayloadAction<ResetPasswordUserPayload>) {
  try {
    const response: User = yield call(userApi.resetPasswordUser, payload);
    yield put(userActions.resetPasswordUserSuccess(response));
  } catch (e) {
    console.log('resetPasswordUsersWorker err: ', e);
    yield put(userActions.resetPasswordUserFailed(e));
  }
}

export function* updateStatusUserWorker({ payload }: PayloadAction<UpdateStatusUserPayload>) {
  try {
    const response: User = yield call(userApi.updateStatusUser, payload);
    const pageInfo: PaginationInfo = yield select(userSelector.selectUsersPaginationInfo);
    yield put(userActions.getUsersModalAssign(pageInfo));
    yield put(userActions.updateStatusUserSuccess(response));
  } catch (e) {
    console.log('updateStatusUserWorker err: ', e);
    yield put(userActions.updateStatusUserFailed(e));
  }
}

export function* removeProjectFromUserWorker({
  payload
}: PayloadAction<RemoveProjectFromUserPayload>) {
  try {
    const response: User = yield call(userApi.removeProject, payload);
    const users: User[] = yield select(userSelector.selectUsersModalAssign);
    const newUsers = users.map((user) => {
      if (user._id === payload.id) {
        return {
          ...user,
          projects: user.projects.filter((p) => p.project._id !== payload.projectId) || []
        };
      } else {
        return user;
      }
    });
    yield put(userActions.setUsersModalAssignSuccess(newUsers as User[]));
    yield put(userActions.removeProjectFromUserSuccess(response));
  } catch (e) {
    console.log('removeProjectFromUserWorker err: ', e);
    yield put(userActions.updateStatusUserFailed(e));
  }
}

export function* bulkUpdateUsersWorker({ payload }: PayloadAction<BulkUpdateUsersPayload>) {
  try {
    const response: User = yield call(userApi.bulkUpdateUsers, payload);
    const pageInfo: PaginationInfo = yield select(userSelector.selectUsersPaginationInfo);
    yield put(userActions.getUsersModalAssign(pageInfo));
    yield put(userActions.bulkUpdateUsersSuccess(response));
  } catch (e) {
    console.log('bulkUpdateUsersWorker err: ', e);
    yield put(userActions.bulkUpdateUsersFailed(e));
  }
}

export function* deleteUsersWorker({ payload }: PayloadAction<string[]>) {
  try {
    const response: User = yield call(userApi.deleteUsers, payload);
    const pageInfo: PaginationInfo = yield select(userSelector.selectUsersPaginationInfo);
    yield put(userActions.getUsersModalAssign(pageInfo));
    yield put(userActions.deleteUsersSuccess(response));
  } catch (e) {
    console.log('deleteUserseUsersWorker err: ', e);
    yield put(userActions.deleteUsersFailed(e));
  }
}

export function* updateUserWorker({ payload }: PayloadAction<UpdateUserPayload>) {
  try {
    console.log('run');
    const response: User = yield call(userApi.updateUser, payload);
    yield put(userActions.updateUserSuccess(response));
    toast.success('User Updated Successfully.');
    const getUsersPayload: GetUsersPayload = yield select(
      userSelector.selectGetUsersModalAssignParams
    );
    yield put(userActions.getUsersModalAssign(getUsersPayload));
  } catch (e: any) {
    console.log('updateUserWorker err: ', e);
    toast.error(e?.message || 'Something went wrong.');
    yield put(userActions.createUserFailed(e));
  }
}

export function* deleteUserWorker({ payload }: PayloadAction<string>) {
  try {
    const response: User = yield call(userApi.deleteUser, payload);
    const pageInfo: PaginationInfo = yield select(userSelector.selectUsersPaginationInfo);
    yield put(userActions.getUsersModalAssign(pageInfo));
    yield put(userActions.deleteUserSuccess(response));
  } catch (e) {
    console.log('deleteUserseUsersWorker err: ', e);
    yield put(userActions.deleteUsersFailed(e));
  }
}

export function* restoreUsersWorker({ payload }: PayloadAction<string[]>) {
  try {
    const response: User = yield call(userApi.restoreUsers, payload);
    yield put(userActions.restoreUsersSuccess(response));
  } catch (e) {
    console.log('bulkUpdateUsersWorker err: ', e);
    yield put(userActions.bulkUpdateUsersFailed(e));
  }
}

export function* getUsersSameProjectWorker() {
  try {
    const response: UserSameProject[] = yield call(userApi.getUsersSameProject);
    yield put(userActions.getUsersSameProjectSuccess(response));
  } catch (e) {
    console.log('getUsersSameProjectWorker err: ', e);
    yield put(userActions.getUsersSameProjectFailed(e));
  }
}

export function* exportUsersWorker() {
  try {
    const exportUsersPayload: GetUsersPayload = yield select(
      userSelector.selectGetUsersModalAssignParams
    );
    const response: string = yield call(userApi.exportUsers, exportUsersPayload);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `UserList-${format(new Date(), 'yyyy-MM-dd')}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    yield put(userActions.exportUsersSuccess());
  } catch (e) {
    console.log('exportUsersWorker err: ', e);
    yield put(userActions.exportUsersFailed(e));
  }
}

export function* setGetUsersModalAssignParamsWorker({ payload }: PayloadAction<GetUsersPayload>) {
  try {
    yield put(userActions.setGetUsersModalAssignParamsSuccess(payload));
  } catch (e) {
    console.log('setGetUsersModalAssignParamsWorker err: ', e);
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.setUsers, setUsersWorker);
  yield takeLatest(userActions.getUsers, getUsersWorker);
  yield takeLatest(userActions.getUsersModalAssign, getUsersModalAssignWorker);
  yield takeLatest(userActions.setGetUsersModalAssignParams, setGetUsersModalAssignParamsWorker);
  yield takeLatest(userActions.setIsModalCreateVisible, setIsModalCreateVisibleWorker);
  yield takeLatest(userActions.setIsModalUpdateVisible, setIsModalUpdateVisibleWorker);
  yield takeLatest(userActions.setIsModalDeleteVisible, setIsModalDeleteVisibleWorker);
  yield takeLatest(
    userActions.setIsModalResetPasswordVisible,
    setIsModalResetPasswordVisibleWorker
  );
  yield takeLatest(userActions.setSelectedUser, setSelectedUserWorker);
  yield takeLatest(userActions.setSelectedUsers, setSelectedUsersWorker);
  yield takeLatest(userActions.setSelectedAll, setSelectedAllWorker);
  yield takeLatest(userActions.createUser, createUserWorker);
  yield takeLatest(userActions.resetPasswordUser, resetPasswordUserWorker);
  yield takeLatest(userActions.updateStatusUser, updateStatusUserWorker);
  yield takeLatest(userActions.removeProjectFromUser, removeProjectFromUserWorker);
  yield takeLatest(userActions.bulkUpdateUsers, bulkUpdateUsersWorker);
  yield takeLatest(userActions.deleteUsers, deleteUsersWorker);
  yield takeLatest(userActions.updateUser, updateUserWorker);
  yield takeLatest(userActions.deleteUser, deleteUserWorker);
  yield takeLatest(userActions.restoreUsers, restoreUsersWorker);
  yield takeLatest(userActions.exportUsers, exportUsersWorker);
  yield takeLatest(userActions.getUsersSameProject, getUsersSameProjectWorker);
}
