import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './schedulerFix.initialState';

const schedulerFixSlice = createSlice({
  name: 'schedulerFixs',
  initialState,
  reducers: {
    setIsModalCreateSlotVisible: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsModalCreateSlotVisibleSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalCreateSlotVisible = payload;
    },
    setSelectedShiftId: (state, { payload }: PayloadAction<string>) => undefined,
    setSelectedShiftIdSuccess: (state, { payload }: PayloadAction<string>) => {
      state.selectedShiftId = payload;
    },
    setSelectedDate: (state, { payload }: PayloadAction<number>) => undefined,
    setSelectedDateSuccess: (state, { payload }: PayloadAction<number>) => {
      state.selectedDate = payload;
    },
    setIsApplyingFilter: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsApplyingFilterSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isApplyingFilter = payload;
    },
    getPersonalFilters: (state) => undefined,
    getPersonalFiltersSuccess: (state, { payload }: PayloadAction<any>) => {
      state.personalFilters = payload;
      state.error = null;
    },
    getPersonalFiltersFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    createPersonalFilter: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = true;
      state.error = null;
    },
    createPersonalFilterSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = false;
      state.personalFilters = payload;
      state.error = null;
    },
    createPersonalFilterFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoadingFilters = false;
      state.error = payload;
    }
  }
});

export const schedulerFixActions = schedulerFixSlice.actions;

export * as schedulerFixSelector from './schedulerFix.selector';

export default schedulerFixSlice.reducer;
