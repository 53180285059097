import Multiselect from 'multiselect-react-dropdown';
import { forwardRef, LegacyRef, MutableRefObject, useRef } from 'react';
import './multiselect.scss';
interface Props {
  data: any;
  displayValue: string;
  selectedValues?: any;
  onSelect: (val: any) => void;
  onRemove: (val: any) => void;
  ref: any;
}

export const FcMultiselect = forwardRef((props: Props, ref) => {
  // const { data, displayValue } = { ...props };
  const multiselectRef = useRef<React.LegacyRef<Multiselect>>();

  const onSelect = (val: any) => {
    props.onSelect(val);
  };

  const onRemove = (val: any) => {
    props.onRemove(val);
  };

  const onReset = () => {
    if (
      multiselectRef !== undefined &&
      multiselectRef.current !== undefined &&
      multiselectRef !== null &&
      multiselectRef.current !== null
    ) {
      (multiselectRef as any).current.resetSelectedValues();
    }
  };

  return (
    <Multiselect
      options={props.data} // Options to display in the dropdown
      selectedValues={props?.selectedValues}
      onSelect={(val: any) => onSelect(val)} // Function will trigger on select event
      onRemove={(val: any) => onRemove(val)} // Function will trigger on remove event
      displayValue={props.displayValue} // Property name to display in the dropdown options
      ref={(multiselectRef as LegacyRef<Multiselect>) || undefined}
    />
  );
});
