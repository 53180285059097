import Scheduler from 'components/Scheduler/Scheduler';
import { Project, Shift, User } from 'models';
import { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { filterActions } from 'store/filters/filter.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { shiftSelector, shiftActions } from 'store/shifts/shift.slice';
import { userSelector } from 'store/users/user.slice';

const ScheduleActualPage = () => {
  const projects: Project[] = useAppSelector(projectSelector.selectProjects);
  const isFetchingProjects: boolean = useAppSelector(projectSelector.selectIsFetching);
  const shifts: Shift[] = useAppSelector(shiftSelector.selectShifts);
  const offVacationSlotShift: Shift[] = useAppSelector(shiftSelector.selectOffVacationSlotShifts);
  const users: User[] = useAppSelector(userSelector.selectUsers);
  const isFetchingShifts: boolean = useAppSelector(shiftSelector.selectIsFetching);
  const dispatch = useAppDispatch();
  const [showOriginalOrOffVacation, setShowOriginalOrOffVacation] = useState(true);
  const selectedProject = useAppSelector(projectSelector.selectSelectedProject);

  useEffect(() => {
    dispatch(filterActions.getPersonalFilters(undefined));
    dispatch(projectActions.getProjects());
  }, []);

  const onChangeShowOffVacationSlots = (value: boolean) => {
    if (selectedProject) {
      dispatch(shiftActions.getOffVacationSlotShifts(selectedProject?._id));
    }
    setShowOriginalOrOffVacation(value);
  };
  useEffect(() => {
    console.log(offVacationSlotShift);
  }, [showOriginalOrOffVacation]);

  return (
    <Card className="shadow">
      {isFetchingProjects ? (
        <div className="text-center p-5">
          <Spinner animation="border" variant="primary" className="text-center" />
        </div>
      ) : (
        <Scheduler
          projects={projects}
          shifts={showOriginalOrOffVacation ? shifts : offVacationSlotShift}
          offVacationSlotShifts={offVacationSlotShift}
          users={users}
          isFetchingShifts={isFetchingShifts}
          onChangeShowOffVacationSlots={onChangeShowOffVacationSlots}
        />
      )}
    </Card>
  );
};

export default ScheduleActualPage;
