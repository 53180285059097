import React, { Fragment } from 'react';
import { SchedulerHead } from '../SchedulerFix/Scheduler.styled';
import {
  ScheduleAvailabilityHeader,
  ScheduleAvailabilityTable,
  ScheduleAvailabilityTableWrapper,
  TableLabel
} from './ScheduleAvailability.styled';
import { SchedulerHeaderWrapper } from '../Scheduler/Actions/SchedulerHeader/SchedulerHeader.styled';
import { getWeekdays } from '../../models';
import { useAppSelector } from '../../store/hooks';
import { selectSelectedProject } from '../../store/meta/meta.selector';
import useFixedSchedulePreview from './hooks/useFixedSchedulePreview';

const ScheduleFixPreview = (): React.ReactElement => {
  const project = useAppSelector(selectSelectedProject);
  const renderFixedSchedule = useFixedSchedulePreview();

  return (
    <Fragment>
      <TableLabel align="center">Fixed Schedule Preview</TableLabel>
      <ScheduleAvailabilityTableWrapper>
        <ScheduleAvailabilityTable bordered size="sm" responsive="sm">
          <SchedulerHead>
            <SchedulerHeaderWrapper>
              <ScheduleAvailabilityHeader style={{ width: '10%' }}>
                {project?.name}
              </ScheduleAvailabilityHeader>
              {getWeekdays().map((weekday, index) => {
                return (
                  <ScheduleAvailabilityHeader key={index}>{weekday}</ScheduleAvailabilityHeader>
                );
              })}
            </SchedulerHeaderWrapper>
          </SchedulerHead>
          {renderFixedSchedule()}
        </ScheduleAvailabilityTable>
      </ScheduleAvailabilityTableWrapper>
    </Fragment>
  );
};

export default ScheduleFixPreview;
