import { Division } from 'models';
import { Nullable } from '../../utils/common/types';
export type AttendanceInitialState = {
  isCheckingIn: boolean;
  isCheckingInEarly: boolean;
  isCheckingInLate: boolean;
  isCheckingInSuccess: boolean;
  isCovering: boolean;
  isCoveringSuccess: boolean;
  isCheckingOut: boolean;
  isCheckingOutSuccess: boolean;
  isCheckingOutEarly: boolean;
  isCheckingOutLate: boolean;
  isFetching: boolean;
  attendances: any;
  error: any;
};

export const initialState: AttendanceInitialState = {
  isCheckingIn: false,
  isCheckingInSuccess: false,
  isCheckingInEarly: false,
  isCheckingInLate: false,
  isCovering: false,
  isCoveringSuccess: false,
  isCheckingOut: false,
  isCheckingOutSuccess: false,
  isCheckingOutEarly: false,
  isCheckingOutLate: false,
  isFetching: false,
  attendances: [],
  error: null
};
