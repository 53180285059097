import { FcModalConfirm } from 'components/Shared';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { BulkUpdateUsersPayload, User, UserRole } from 'models';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';

const actions = [
  {
    key: 'ACTIVE',
    value: 'Activate',
    btnColor: 'secondary'
  },
  {
    key: 'INACTIVE',
    value: 'Deactivate',
    btnColor: 'secondary'
  },
  // {
  //   key: 'BANNED',
  //   value: 'Ban',
  //   btnColor: 'secondary'
  // },
  {
    key: 'DELETED',
    value: 'Delete',
    btnColor: 'danger'
  }
];

interface Props {
  selectedUsers: User[];
  getUsers: () => void;
  resetPage: () => void;
  resetSelectedUsers: () => void;
  isVisible: boolean;
}

const UserBulkUpdate = (props: Props) => {
  const { selectedUsers, getUsers, resetSelectedUsers, resetPage, isVisible } = { ...props };
  const [isInited, setIsInited] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const dispatch = useAppDispatch();
  const isBulkUpdating = useAppSelector(userSelector.selectIsBulkUpdating);
  const error = useAppSelector(userSelector.selectError);

  // useDidMountEffect(() => {
  //   if (!isBulkUpdating && error === null) {
  //     toast.success('Successfully updated.');
  //     resetPage();
  //     getUsers();
  //     resetSelectedUsers();
  //   }
  // }, [isBulkUpdating]);

  useEffect(() => {
    if (isInited && !isBulkUpdating && error === null) {
      toast.success('Successfully updated.');
      resetPage();
      getUsers();
      setIsModalConfirmVisible(false);
      resetSelectedUsers();
    }
  }, [isBulkUpdating]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.message || 'Something went wrong');
    }
  }, [error]);

  const onClick = (actionType: string) => {
    setIsInited(true);
    setIsModalConfirmVisible(true);
    setSelectedAction(actionType);
  };

  const onOk = () => {
    if (selectedAction !== 'DELETED') {
      const payload: BulkUpdateUsersPayload = {
        userIds: selectedUsers.map((u) => u._id),
        status: selectedAction
      };
      dispatch(userActions.bulkUpdateUsers(payload));
    } else {
      dispatch(userActions.deleteUsers(selectedUsers.map((u) => u._id)));
    }
  };

  return (
    <div>
      <span className="mr-2">With selected</span>
      {actions.map((action) => (
        <Button
          size="sm"
          variant={action.btnColor}
          onClick={() => onClick(action.key)}
          key={action.key}
        >
          {action.value}
        </Button>
      ))}
      <FcModalConfirm
        show={isModalConfirmVisible}
        onHide={() => setIsModalConfirmVisible(false)}
        title="Confirmation"
        body={
          <>
            <p>
              Are you sure you want to{' '}
              <strong>
                {actions.find((action) => action.key === selectedAction)?.value || 'perform'}
              </strong>{' '}
              these users:
            </p>
            <ul>
              {selectedUsers.map((user) => (
                <li key={user._id}>{user.username}</li>
              ))}
            </ul>
          </>
        }
        footer={
          <>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setIsModalConfirmVisible(false);
              }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onOk}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              OK
            </Button>
          </>
        }
      />
    </div>
  );
};

export default UserBulkUpdate;
