import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  margin-bottom: 1.25rem;
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterItemLabel = styled.span`
  margin-left: 0.5rem;
`;
