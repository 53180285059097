import { DropdownAutoCloseOutside } from 'components/Shared';
import { Dropdown } from 'react-bootstrap';
import { UserSortByRoles } from './UserByRoles.styled';
interface Props {
  selectedRole: string[];
  onSelectRole: (e: any, value: string) => void;
}

const UserByRoles = (props: Props) => {
  const { selectedRole, onSelectRole } = { ...props };

  return (
    <DropdownAutoCloseOutside className="mr-2">
      <UserSortByRoles variant="outline-primary" size="sm" id="dropdown-basic">
        By Role: {selectedRole.join(', ')}
      </UserSortByRoles>
      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => e.stopPropagation()}>
          <input
            type="checkbox"
            value="MANAGER"
            checked={selectedRole.includes('MANAGER')}
            onChange={(e) => onSelectRole(e, 'MANAGER')}
            className="mr-2"
          />
          Manager
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => e.stopPropagation()}>
          <input
            type="checkbox"
            value="AGENT"
            checked={selectedRole.includes('AGENT')}
            onChange={(e) => onSelectRole(e, 'AGENT')}
            className="mr-2"
          />
          Agent
        </Dropdown.Item>
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};

export default UserByRoles;
