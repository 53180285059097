import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { appRoutes } from 'routes/app.route';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import type { BreadcrumbMatch } from 'use-react-router-breadcrumbs';
import { BreadcrumbBody, BreadcrumbHeader } from './Breadcrumb.styled';

const Breadcrumb = () => {
  const { pathname } = useLocation();
  const crumbs = pathname.slice(1).split('/');
  const breadcrumbs = useBreadcrumbs(appRoutes);

  const BreadcrumbItem = (match: BreadcrumbMatch, breadcrumb: React.ReactNode, index: number) => {
    const isRootPath = (path: string) => {
      return path === '/';
    };

    if (isRootPath(match.pathname)) {
      return (
        <li className="breadcrumb-item" key="default">
          <Link to="/">
            <i className="fas fa-home"></i>
          </Link>
        </li>
      );
    }

    if (index !== breadcrumbs.length - 1) {
      return (
        <li className="breadcrumb-item" key={match.pathname}>
          <Link to={match.pathname}>{breadcrumb}</Link>
        </li>
      );
    }

    return (
      <li className="breadcrumb-item active" key={match.pathname} aria-current="page">
        {breadcrumb}
      </li>
    );
  };

  return (
    <BreadcrumbHeader className="header bg-primary pb-6">
      <Container fluid>
        <BreadcrumbBody className="header-body">
          <Row className="align-items-center py-4">
            <Col xs={7} lg={6}>
              <h6 className="h2 text-white d-inline-block mb-0">
                {breadcrumbs[breadcrumbs.length - 1].breadcrumb}
              </h6>
              <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                  {breadcrumbs.map(({ match, breadcrumb }, index) =>
                    BreadcrumbItem(match, breadcrumb, index)
                  )}
                </ol>
              </nav>
            </Col>
          </Row>
        </BreadcrumbBody>
      </Container>
    </BreadcrumbHeader>
  );
};
export default Breadcrumb;
