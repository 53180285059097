import { AppState } from '../index.store';

export const selectError = (state: AppState) => state.notifications.error;

export const selectIsFetchingSystemNoti = (state: AppState) =>
  state.notifications.isFetchingSystemNoti;

export const selectIsFetchingUserNoti = (state: AppState) => state.notifications.isFetchingUserNoti;

export const selectIsMarkingAll = (state: AppState) => state.notifications.isMarkingAll;

export const selectSystemNotifications = (state: AppState) =>
  state.notifications.systemNotifications;

export const selectUserNotifications = (state: AppState) => state.notifications.userNotifications;

export const selectTotalUnread = (state: AppState) => state.notifications.totalUnread;

export const selectSystemNotisPaginationInfo = (state: AppState) =>
  state.notifications.systemNotisPaginationInfo;

export const selectUserNotisPaginationInfo = (state: AppState) =>
  state.notifications.userNotisPaginationInfo;
