import { useProjectFilter } from 'hooks';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useReportSorter } from './useReportSorter';
import { useDateRangeCalendar } from './useDateRangeCalendar';
import { reportActions, reportSelector } from 'store/reports/report.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { toast } from 'react-toastify';
const ReportTimesheetExport = () => {
  const dispatch = useAppDispatch();
  const { selectedDateRange } = useDateRangeCalendar();
  const { selectedProject } = useProjectFilter();
  const { sortString } = useReportSorter();
  const isExporting = useAppSelector(reportSelector.selectIsExporting);
  const error = useAppSelector(reportSelector.selectError);

  useDidMountEffect(() => {
    if (!isExporting && !error) {
      toast.success('Successfully Exported Timesheet.');
    }
  }, [isExporting]);

  useDidMountEffect(() => {
    if (isExporting && !error) {
      toast.error(error?.message || 'Something went wrong.');
    }
  }, [error]);

  const onExport = () => {
    dispatch(
      reportActions.exportTimesheet({
        projectId: selectedProject,
        dateStart: formatTo_yyyyMMdd(new Date(selectedDateRange[0])),
        dateEnd: formatTo_yyyyMMdd(new Date(selectedDateRange[1])),
        filter: sortString
      })
    );
  };
  return (
    <Button size="sm" variant="success" onClick={onExport}>
      Export
    </Button>
  );
};

export default ReportTimesheetExport;
