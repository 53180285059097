import { ShiftChange } from 'models';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import ApiService from '../utils/network';
import { API_URL } from './url';
export const shiftApi = {
  getShifts(projectId: string, dateStart?: string | null, dateEnd?: string | null) {
    let params = {};

    if (dateStart && dateStart !== null && dateEnd && dateEnd !== null) {
      params = {
        dateStart: formatTo_yyyyMMdd(new Date(dateStart)),
        dateEnd: formatTo_yyyyMMdd(new Date(dateEnd)),
        getFixedSlot: false
      };
    }
    return ApiService.get(`${API_URL.BASE_URL}/${API_URL.PROJECT}/${projectId}/${API_URL.SHIFT}`, {
      params: params
    });
  },
  getOffVacationSlotShifts(projectId: string, dateStart?: string | null, dateEnd?: string | null) {
    let params = {};

    if (dateStart && dateStart !== null && dateEnd && dateEnd !== null) {
      params = {
        dateStart: formatTo_yyyyMMdd(new Date(dateStart)),
        dateEnd: formatTo_yyyyMMdd(new Date(dateEnd)),
        getFixedSlot: false
      };
    }
    return ApiService.get(
      `${API_URL.BASE_URL}/${API_URL.PROJECT}/${projectId}/${API_URL.SHIFT}/${API_URL.SLOTS}/${API_URL.OFF_VACATION2}`,
      {
        params: params
      }
    );
  }
};
