import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './scheduleGlance.initialState';
import { ScheduleGlance } from 'models/scheduleGlance.model';

const scheduleGlanceSlice = createSlice({
  name: 'scheduleGlance',
  initialState,
  reducers: {
    getScheduleGlance: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = true;
      state.error = null;
    },
    getScheduleGlanceSuccess: (state, { payload }: PayloadAction<ScheduleGlance[]>) => {
      state.scheduleGlance = payload;
      state.isFetching = false;
      state.error = null;
    },
    getScheduleGlanceFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    }
  }
});

export const scheduleGlanceActions = scheduleGlanceSlice.actions;

export * as scheduleGlanceSelector from './scheduleGlance.selector';

export default scheduleGlanceSlice.reducer;
