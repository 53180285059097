import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { attendanceActions, attendanceSelector } from 'store/attendances/attendance.slice';

const AttendanceCheckOut = () => {
  const dispatch = useAppDispatch();

  const onCheckOut = () => {
    dispatch(attendanceActions.checkout(null as any));
  };

  return (
    <>
      <div className="w-100 p-5">
        <div className="text-center">
          <h1>Check-out Area</h1>
          <Button onClick={onCheckOut}>Check Out</Button>
          {/* <Spinner animation="border" variant="primary" className="text-center" /> */}
        </div>
      </div>
    </>
  );
};

export default AttendanceCheckOut;
