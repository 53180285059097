import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BulkUpdateUsersPayload,
  CreateUserPayload,
  GetUsersPayload,
  PaginationInfo,
  RemoveProjectFromUserPayload,
  ResetPasswordUserPayload,
  UpdateStatusUserPayload,
  UpdateUserPayload,
  User,
  UserSameProject
} from 'models';
import { initialState } from './user.initialState';

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: (state, { payload }: PayloadAction<GetUsersPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getUsersSuccess: (state, { payload }: PayloadAction<User[]>) => {
      state.users = payload;
      state.isFetching = false;
      state.error = null;
    },
    getUsersFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.error = payload;
    },
    getUsersModalAssign: (state, { payload }: PayloadAction<GetUsersPayload>) => {
      state.isFetching = true;
      state.error = null;
    },
    getUsersModalAssignSuccess: (state, { payload }: PayloadAction<User[]>) => {
      state.usersModalAssign = payload;
      state.isFetching = false;
      state.isFetched = true;
      state.error = null;
    },
    getUsersModalAssignFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.isFetched = true;
      state.error = payload;
    },
    setUsers: (state, { payload }: PayloadAction<User[]>) => undefined,
    setUsersSuccess: (state, { payload }: PayloadAction<User[]>) => {
      state.users = payload;
    },
    setUsersModalAssign: (state, { payload }: PayloadAction<User[]>) => undefined,
    setUsersModalAssignSuccess: (state, { payload }: PayloadAction<User[]>) => {
      state.usersModalAssign = payload;
    },
    setPaginationInfo: (
      state,
      { payload }: PayloadAction<{ info: PaginationInfo; role: string }>
    ) => undefined,
    setPaginationInfoSuccess: (
      state,
      { payload }: PayloadAction<{ info: PaginationInfo; role: string }>
    ) => {
      if (payload.role === 'MANAGER') {
        state.managersPaginationInfo = payload.info;
      } else {
        state.paginationInfo = payload.info;
      }
    },
    setIsModalCreateVisible: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsModalCreateVisibleSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalCreateVisible = payload;
    },
    setIsModalUpdateVisible: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsModalUpdateVisibleSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalUpdateVisible = payload;
    },
    setIsModalResetPasswordVisible: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsModalResetPasswordVisibleSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalResetPasswordVisible = payload;
    },
    setIsModalDeleteVisible: (state, { payload }: PayloadAction<boolean>) => undefined,
    setIsModalDeleteVisibleSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalDeleteVisible = payload;
    },
    setSelectedUser: (state, { payload }: PayloadAction<User>) => undefined,
    setSelectedUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.selectedUser = payload;
    },
    setSelectedUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = `Error: ${payload}`;
    },
    setSelectedUsers: (state, { payload }: PayloadAction<string[]>) => undefined,
    setSelectedUsersSuccess: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedUsers = payload;
    },
    setSelectedUsersFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = `Error: ${payload}`;
    },
    setSelectedAll: (state, { payload }: PayloadAction<boolean>) => undefined,
    setSelectedAllSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.selectedAll = payload;
    },
    setSelectedAllFailed: (state, { payload }: PayloadAction<any>) => {
      state.error = `Error: ${payload}`;
    },
    createUser: (state, { payload }: PayloadAction<CreateUserPayload>) => {
      state.isCreating = true;
      state.error = null;
    },
    createUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isCreating = false;
      state.error = null;
    },
    createUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.isCreating = false;
      state.error = payload;
    },
    resetPasswordUser: (state, { payload }: PayloadAction<ResetPasswordUserPayload>) => {
      state.isUpdatingPassword = true;
      state.error = null;
    },
    resetPasswordUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isUpdatingPassword = false;
      state.error = null;
    },
    resetPasswordUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdatingPassword = false;
      state.error = payload;
    },
    updateStatusUser: (state, { payload }: PayloadAction<UpdateStatusUserPayload>) => {
      state.isUpdatingStatus = true;
      state.error = null;
    },
    updateStatusUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isUpdatingStatus = false;
      state.error = null;
    },
    updateStatusUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdatingStatus = false;
      state.error = payload;
    },
    removeProjectFromUser: (state, { payload }: PayloadAction<RemoveProjectFromUserPayload>) => {
      state.isRemovingProject = true;
      state.error = null;
    },
    removeProjectFromUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isRemovingProject = false;
      state.error = null;
    },
    removeProjectFromUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.isRemovingProject = false;
      state.error = payload;
    },
    bulkUpdateUsers: (state, { payload }: PayloadAction<BulkUpdateUsersPayload>) => {
      state.isBulkUpdating = true;
      state.error = null;
    },
    bulkUpdateUsersSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isBulkUpdating = false;
      state.error = null;
    },
    bulkUpdateUsersFailed: (state, { payload }: PayloadAction<any>) => {
      state.isBulkUpdating = false;
      state.error = payload;
    },
    deleteUsers: (state, { payload }: PayloadAction<string[]>) => {
      state.isBulkUpdating = true;
      state.error = null;
    },
    deleteUsersSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isBulkUpdating = false;
      state.error = null;
    },
    deleteUsersFailed: (state, { payload }: PayloadAction<any>) => {
      state.isBulkUpdating = false;
      state.error = payload;
    },
    updateUser: (state, { payload }: PayloadAction<UpdateUserPayload>) => {
      state.isUpdating = true;
      state.error = null;
    },
    updateUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isUpdating = false;
      state.error = null;
    },
    updateUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.isUpdating = false;
      state.error = payload;
    },
    deleteUser: (state, { payload }: PayloadAction<string>) => {
      state.isDeleting = true;
      state.error = null;
    },
    deleteUserSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isDeleting = false;
      state.error = null;
    },
    deleteUserFailed: (state, { payload }: PayloadAction<any>) => {
      state.isDeleting = false;
      state.error = payload;
    },
    restoreUsers: (state, { payload }: PayloadAction<string[]>) => {
      state.isRestoring = true;
      state.error = null;
    },
    restoreUsersSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isRestoring = false;
      state.error = null;
    },
    restoreUsersFailed: (state, { payload }: PayloadAction<any>) => {
      state.isRestoring = false;
      state.error = payload;
    },
    exportUsers: (state) => {
      state.isExporting = true;
    },
    exportUsersSuccess: (state) => {
      state.isExporting = false;
    },
    exportUsersFailed: (state, { payload }: PayloadAction<any>) => {
      state.isExporting = false;
      state.error = payload;
    },
    setGetUsersModalAssignParams: (state, { payload }: PayloadAction<GetUsersPayload>) => undefined,
    setGetUsersModalAssignParamsSuccess: (state, { payload }: PayloadAction<GetUsersPayload>) => {
      state.getUsersModalAssignParams = payload;
    },
    getUsersSameProject: (state) => {
      state.isFetchingUsersSameProject = true;
      state.error = null;
    },
    getUsersSameProjectSuccess: (state, { payload }: PayloadAction<UserSameProject[]>) => {
      state.isFetchingUsersSameProject = false;
      state.usersSameProject = payload;
      state.error = null;
    },
    getUsersSameProjectFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetchingUsersSameProject = false;
      state.error = payload;
    }
  }
});

export const userActions = userSlice.actions;

export * as userSelector from './user.selector';

export default userSlice.reducer;
