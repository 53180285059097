import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { UserBoardWrapper, ButtonRemove, LoadingIcon } from './UserBoard.styled';
import { User } from 'models';
import { Badge, Form } from 'react-bootstrap';
import React, { SetStateAction } from 'react';
import cloneDeep from 'lodash.clonedeep';

interface Props {
  users: User[];
  selectedUsers: User[];
  isLoading?: boolean;
  setSelectedUsers: (users: User[]) => void;
  type?: string;
}
const UserBoard = (props: Props) => {
  const { users, selectedUsers, isLoading, setSelectedUsers, type } = { ...props };

  const onSelect = (e: any, user: User) => {
    const _users = [...selectedUsers];
    if (e.target.checked) {
      _users.push(user);
    } else {
      const index = _users.findIndex((u) => u._id === user._id);
      _users.splice(index, 1);
    }
    setSelectedUsers(_users);
  };

  const onRemove = (user: User) => {
    const index = selectedUsers.findIndex((u) => u._id === user._id);
    const _selectedUsers = [...selectedUsers];
    _selectedUsers.splice(index, 1);
    setSelectedUsers(_selectedUsers);
  };

  const getAvailableUsers = () => {
    if (props.type === 'review') {
      return users;
    } else {
      const _users = cloneDeep(users);
      return _users.filter((user) => selectedUsers.find((u) => u._id === user._id) === undefined);
    }
  };

  return (
    <>
      <UserBoardWrapper>
        {isLoading ? (
          <LoadingIcon animation="border" variant="secondary" />
        ) : (
          getAvailableUsers().map((user: User) => (
            <div className="mb-1" key={user._id}>
              <Form.Check
                type="checkbox"
                value={user._id}
                inline
                className="mr-0"
                checked={selectedUsers.find((u) => u._id === user._id) !== undefined}
                onChange={(e: any) => {
                  onSelect(e, user);
                }}
              />
              <span>
                {user.username} - {user.employeeCode}
              </span>
            </div>
          ))
        )}
      </UserBoardWrapper>
      <div className="mt-3">
        {props.selectedUsers.length > 0 && (
          <>
            <span className="mr-2">Selected Agent:</span>
            {props.selectedUsers.map((user) => (
              <Badge pill variant="primary" className="mr-2 pr-2" key={user._id}>
                {user.username}
                <ButtonRemove icon={solid('xmark')} onClick={() => onRemove(user)} />
              </Badge>
            ))}
          </>
        )}
      </div>
    </>
  );
};
export default UserBoard;
