import { FcPagination } from 'components/Shared';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { Notification, PaginationInfo } from 'models';
import { useEffect, useState } from 'react';
import { Table, Spinner, Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notificationActions, notificationSelector } from 'store/notifications/notification.slice';
import NotificationListItem from '../Actions/NotificationListItem/NotificationListItem';

interface Props {
  data: Notification[];
  isLoading: boolean;
  type: string;
  paginationInfo: PaginationInfo;
}

const NotificationList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { data, isLoading, type } = { ...props };
  const { page, perPage, totalCount } = { ...props.paginationInfo };
  const [currentPage, setCurrentPage] = useState(1);

  const error = useAppSelector(notificationSelector.selectError);
  const isMarkingAll = useAppSelector(notificationSelector.selectIsMarkingAll);

  useDidMountEffect(() => {
    if (!isMarkingAll && !error) {
      toast.success('Successfully Marked All Notifications As Read.');
    }
  }, [isMarkingAll]);

  useDidMountEffect(() => {
    if (!isMarkingAll && error) {
      toast.error('Mark All Notifications As Read Failed.');
    }
  }, [error]);

  useEffect(() => {
    getNotifications();
  }, [currentPage]);

  const getNotifications = () => {
    const payload = {
      page: currentPage,
      perPage: 10,
      type: type,
      orderBy: 'createdAt',
      order: 'desc'
    };
    if (type !== 'MANUAL') {
      dispatch(notificationActions.getSystemNotifications(payload));
    } else {
      dispatch(notificationActions.getUserNotifications(payload));
    }
  };

  const onMarkAll = () => {
    dispatch(notificationActions.markAllAsRead());
  };

  const Header = () => {
    return (
      <Card.Header className="border-0 p-0">
        <div className="d-flex p-3">
          <Col xs={10}>
            <h5 className="h4 mb-0">From {type === 'MANUAL' ? 'Agents' : 'System'}</h5>
          </Col>
          <Col xs={2} className="text-right">
            <strong
              className="text-primary clickable"
              onClick={() => {
                onMarkAll();
              }}
            >
              Mark all as read
            </strong>
          </Col>
        </div>
      </Card.Header>
    );
  };

  return (
    <Card className="shadow py-2">
      {isLoading && (
        <div className="text-center p-5">
          <Spinner animation="border" variant="primary" className="text-center" />
        </div>
      )}
      {!isLoading && (
        <>
          {Header()}
          <Card.Body className="card-body p-0">
            <div>
              {(!data || data.length <= 0) && (
                <div className="text-center">
                  <span className="text-center">No data.</span>
                </div>
              )}
              {data &&
                data.length > 0 &&
                data.map((noti: Notification) => (
                  <NotificationListItem key={noti._id} data={noti} type={type} />
                ))}
            </div>
            <div className="float-right mt-3 pr-4">
              <FcPagination
                onChangePage={(page: number) => {
                  setCurrentPage(page);
                }}
                totalItems={totalCount}
                perPage={10}
                currentPage={currentPage}
              />
            </div>
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default NotificationList;
