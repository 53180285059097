import { DropdownAutoCloseOutside } from 'components/Shared';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from './FilterDropdown.styled';
import { usePersonalFilter } from './usePersonalFilter';
import { useState } from 'react';
const PersonalFilter = () => {
  const { filters, label, selectedFilter, onSelectFilter } = usePersonalFilter();
  const [activeFilter, setActiveFilter] = useState(filters);

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(filters);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(filters);
    } else {
      const filtersList = filters.filter((f) =>
        f.name.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  return (
    <DropdownAutoCloseOutside className="mr-2">
      <Dropdown.Toggle size="sm" variant="outline-primary" onMouseEnter={onSetFilter}>
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        <Dropdown.Item as="li">
          <FilterItem>
            <input
              type="text"
              onChange={(e) => onChangeFilter(e.target.value)}
              placeholder="Search Item"
            />
          </FilterItem>
        </Dropdown.Item>
        {activeFilter.length > 0 &&
          activeFilter.map((filter) => (
            <Dropdown.Item key={filter._id} as="li" onClick={(e) => onSelectFilter(filter._id)}>
              <FilterItem>
                <input
                  type="radio"
                  value={filter._id}
                  checked={selectedFilter === filter._id}
                  onChange={(e) => onSelectFilter(filter._id)}
                />
                <FilterItemLabel>{filter.name}</FilterItemLabel>
              </FilterItem>
            </Dropdown.Item>
          ))}
        {activeFilter.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};
export default PersonalFilter;
