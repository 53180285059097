import { User } from '../../models';
import { ScheduleAvailabilityModel } from '../../models/scheduleAvailability.model';
import { Nullable } from '../../utils/common/types';

export type ScheduleAvailabilityInitialState = {
  availabilityList: Availability[];
  error: Nullable<string>;
  isFetching: boolean;
  projectId: Nullable<string>;
  enableEditing: boolean;
  creatingAvailability: Nullable<{
    userId: string;
    weekday: number;
    shiftId: string;
  }>;
  createdAvailability: Nullable<ScheduleAvailabilityModel>;
  deletingAvailability: Nullable<string>;
  deletedAvailability: Nullable<string>;
  preview: ScheduleAvailabilityPreview[];
  applyChanges: {
    message: Nullable<string>;
    error: Nullable<string>;
    payload: ScheduleAvailabilityPreview[];
    sending: boolean;
  };
  shuffle: {
    message: Nullable<string>;
    error: Nullable<string>;
    data: ScheduleAvailabilityPreview[];
    fetching: boolean;
    projectId: Nullable<string>;
  };
  breakdown: {
    error: Nullable<string>;
    data: ScheduleAvailabilityBreakdownShift[];
    fetching: boolean;
    projectId: Nullable<string>;
  };
  recentClear: boolean;
  showAllNote: boolean;
};

export type Availability = {
  userId: string;
  count: number;
  user: User;
  availabilities: ScheduleAvailabilityModel[];
};

export const initialState: ScheduleAvailabilityInitialState = {
  availabilityList: [],
  error: null,
  isFetching: false,
  projectId: null,
  enableEditing: false,
  creatingAvailability: null,
  createdAvailability: null,
  deletingAvailability: null,
  deletedAvailability: null,
  preview: [],
  applyChanges: {
    message: null,
    error: null,
    payload: [],
    sending: false
  },
  shuffle: {
    message: null,
    error: null,
    data: [],
    fetching: false,
    projectId: null
  },
  breakdown: {
    error: null,
    data: [],
    fetching: false,
    projectId: null
  },
  recentClear: false,
  showAllNote: false
};

export type ScheduleAvailabilityPreview = {
  shiftId: string;
  weekday: number;
  users: User[];
  index: number;
};

export type ScheduleAvailabilityBreakdownShift = {
  shiftId: string;
  name: string;
  startTime: string;
  endTime: string;
  breakdown: ScheduleAvailabilityBreakdownItem[];
};

export type ScheduleAvailabilityBreakdownItem = {
  weekday: number;
  noOfAgents: number;
  noOfHours: number;
};
