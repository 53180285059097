import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const PickerPopover = styled.div`
  position: 'absolute';
  z-index: '2';
`;

export const PickerCover = styled.div`
  position: 'fixed';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const RemoveDivisionButton = styled(FontAwesomeIcon)`
  margin-left: 0.25rem;
  &:hover {
    cursor: pointer;
  }
`;
