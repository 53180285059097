import styled from 'styled-components';

export const ButtonShowDetail = styled.span`
  font-size: 0.775rem;
  margin-bottom: 0;
  color: #32539c;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #32539c;
  }
`;
