import { FormikProps, useFormik } from 'formik';
import { UpdateUserPayload, UserProject } from 'models';
import { useEffect, useState } from 'react';
import { divisionActions, divisionSelector } from 'store/divisions/division.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { userActions, userSelector } from 'store/users/user.slice';
import { updateUserSchema } from 'utils/validators/user.validator';

interface FormProps {
  fullName: string;
  employeeCode: number | undefined;
  username: string;
  email: string;
  displayName: string;
  password: string;
  passwordConfirmation: string;
  // twoFA: string;
  role: string;
  userType: string;
  phone: string;
  projects: UserProject[];
  personalEmail: string;
}

export const useUpdateUser = () => {
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const isFetchingProjects = useAppSelector(projectSelector.selectIsFetching);
  const projectError = useAppSelector(projectSelector.selectError);
  const isUpdating = useAppSelector(userSelector.selectIsUpdating);
  const error = useAppSelector(userSelector.selectError);
  const user = useAppSelector(userSelector.selectSelectedUser);
  const projects = useAppSelector(projectSelector.selectProjects);
  const isFetchingMultiProjectDivisions = useAppSelector(
    divisionSelector.selectIsFetchingMultiProjectDivisions
  );
  const isVisible = useAppSelector(userSelector.selectIsModalUpdateVisible);

  const getInitialValues = () => {
    return {
      username: user?.username || '',
      employeeCode: user?.employeeCode || undefined,
      displayName: user?.displayName || '',
      email: user?.email || '',
      personalEmail: user?.personalEmail || '',
      phone: user?.phone || '',
      fullName: user?.fullName || '',
      password: '',
      passwordConfirmation: '',
      role: user?.role || 'AGENT',
      userType: user?.userType || '',
      projects: (user?.projects || []) as UserProject[]
    };
  };

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: getInitialValues(),
    validationSchema: updateUserSchema,
    onSubmit: (values: FormProps, actions) => {
      handleSubmit(values, actions);
    }
  });

  useEffect(() => {
    if (formik.isSubmitting && !isUpdating && error === null) {
      formik.setSubmitting(false);
      onHide();
    }
  }, [isUpdating]);

  useEffect(() => {
    if (error !== null) {
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    formik.resetForm({ values: getInitialValues() });
    if (isVisible) {
      dispatch(projectActions.getProjects());
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isFetchingProjects && user && Object.keys(user).length) {
      dispatch(
        divisionActions.getMultiProjectDivisions(
          user.projects.map((project) => project.project._id)
        )
      );
    }
  }, [isFetchingProjects]);

  const handleSubmit = (values: FormProps, actions: any) => {
    const payload = {
      ...values,
      _id: user._id,
      projects: getUserProjects(values.projects)
    };
    dispatch(userActions.updateUser(payload as any as UpdateUserPayload));
  };

  const getUserProjects = (userProjects: UserProject[]) => {
    console.log(userProjects);
    return userProjects
      .filter((item) => item.project._id !== '')
      .map((project) => {
        return {
          project: project.project._id,
          divisions:
            project?.divisions
              ?.map((division) => division._id)
              ?.filter((division) => division.length > 0) || []
        };
      });
  };

  const onHide = () => {
    dispatch(userActions.setIsModalUpdateVisible(false));
  };

  return {
    isVisible,
    isFetchingProjects,
    isFetchingMultiProjectDivisions,
    formik,
    projects,
    submitted,
    onHide
  };
};
