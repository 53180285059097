import { SpinnerButton } from 'components/Shared';
import { FixedShift } from 'models';
import { useEffect, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { ButtonTypes } from 'models';
import { bool } from 'yup';
import { fixedShiftActions } from 'store/fixedShifts/fixedShift.slice';
import { schedulerFixActions } from 'store/schedulerFixs/schedulerFix.slice';
import { useAppDispatch } from 'store/hooks';
import { useProjectFilter } from 'hooks';
import { Button, Modal } from 'react-bootstrap';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';

export const ScheduleFixTab = (indexArrayFixedSlot: number[]) => {
  const dispatch = useAppDispatch();
  const { projects, selectedProject, onSelectProject } = useProjectFilter();

  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [isAddingMoreIndex, setIsAddingMoreIndex] = useState(false);
  const biggestTabIndex = indexArrayFixedSlot[indexArrayFixedSlot.length - 1] || 1;
  const [originalSelectedTab, setOriginalSelectedTab] = useState<number>(1);
  const [isVisibleDeleteConfirm, setIsVisibleDeleteConfirm] = useState(false);
  const [isDeletingConfirm, setIsDeletingConfirm] = useState(false);

  // debugger; // eslint-disable-line no-debugger
  // const sortShiftsByIndex = (shift: FixedShift) => {
  //   const fixedSLots = [...shift.slots]; // create a new array & not mutate state
  //   return fixedSLots.sort((a, b) => {
  //     return b.index - a.index;
  //   });
  // };

  // useEffect(() => {
  // shifts.map((shift) => {
  //   const sortedFixSlot = sortShiftsByIndex(shift);
  //   if (sortedFixSlot.length > 0) {
  //     if (sortedFixSlot[0].index > biggestTabIndex) {
  //       biggestTabIndex = sortedFixSlot[0].index;
  //     }
  //   }
  // });
  // }, []);

  const AddScheduleFix = (biggestIndex: number) => {
    setOriginalSelectedTab(selectedTab);
    setSelectedTab(biggestIndex + 1);
    setIsAddingMoreIndex(true);
  };
  const CancelScheduleFix = () => {
    setSelectedTab(originalSelectedTab);
    setIsAddingMoreIndex(false);
  };

  const onTabChange = (index: number) => {
    setSelectedTab(index);

    dispatch(
      fixedShiftActions.getHighestIndexFixedSlot({
        projectId: selectedProject
      })
    );
    dispatch(
      fixedShiftActions.getShiftsSlotIndex({
        projectId: selectedProject,
        fixedSlotsIndex: index
      })
    );
    dispatch(schedulerFixActions.setIsApplyingFilter(false));
    setIsAddingMoreIndex(false);
  };

  const setScheduleFixTabChange = (value: boolean) => {
    if (value) onTabChange(biggestTabIndex);
  };

  // TO DO: FIX ISSUE WHEN DELETE SCHEDULE FIX TAB
  const DeleteScheduleFix = () => {
    setIsDeletingConfirm(true);
    dispatch(
      fixedShiftActions.deleteFixedSlotsByIndex({
        projectId: selectedProject,
        index: selectedTab
      })
    );
    setSelectedTab(originalSelectedTab);
    setIsAddingMoreIndex(false);
    setIsDeletingConfirm(false);
    setIsVisibleDeleteConfirm(false);
  };

  const onHide = () => {
    setIsVisibleDeleteConfirm(false);
    setIsDeletingConfirm(false);
  };

  const Tab = () => {
    return (
      <div className="d-flex ">
        {biggestTabIndex > 1 && (
          <ButtonGroup toggle className="mr-2">
            {indexArrayFixedSlot.map((e, index) => (
              <ToggleButton
                key={e}
                type="radio"
                variant="outline-primary"
                size="sm"
                name="radio"
                value={e}
                checked={selectedTab === e}
                onChange={(event) => onTabChange(e)}
                style={{ lineHeight: 2 }}
              >
                Schedule Fix {e}
              </ToggleButton>
            ))}
          </ButtonGroup>
        )}

        <SpinnerButton
          size="sm"
          btnType={ButtonTypes.Submit}
          disabled={false}
          colorType="primary"
          onClick={() => {
            AddScheduleFix(biggestTabIndex);
          }}
        >
          Add Schedule Fix
        </SpinnerButton>
        {isAddingMoreIndex && (
          <SpinnerButton
            size="sm"
            btnType={ButtonTypes.Button}
            disabled={false}
            className="btn btn-info"
            onClick={() => {
              CancelScheduleFix();
            }}
          >
            Cancel
          </SpinnerButton>
        )}

        {biggestTabIndex > 1 && (
          <>
            <SpinnerButton
              size="sm"
              btnType={ButtonTypes.Button}
              disabled={false}
              className="btn btn-danger"
              onClick={() => {
                setIsVisibleDeleteConfirm(true);
              }}
            >
              Delete
            </SpinnerButton>
            <Modal
              className="modal-confirm handle"
              show={isVisibleDeleteConfirm}
              onHide={onHide}
              size="lg"
              centered
              scrollable={true}
              dialogAs={DraggableModalDialog}
              backdrop={'static'}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  Delete User
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Are you sure you want to delete schedule fix{' '}
                  <span>
                    <strong>{selectedTab}</strong>
                  </span>
                  ?
                </p>
                <span></span>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                  Cancel
                </Button>
                <SpinnerButton
                  btnType={ButtonTypes.Submit}
                  disabled={isDeletingConfirm}
                  onClick={DeleteScheduleFix}
                >
                  Yes
                </SpinnerButton>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    );
  };

  return {
    selectedTab,
    setSelectedTab,
    Tab,
    isAddingMoreIndex,
    setIsAddingMoreIndex,
    setScheduleFixTabChange,
    setIsVisibleDeleteConfirm
  };
};
