import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { reportActions, reportSelector } from 'store/reports/report.slice';

const SORTER = ['date', 'name'];
export const useReportSorter = () => {
  const dispatch = useAppDispatch();
  const sortString = useAppSelector(reportSelector.selectSortString);

  const setSortString = (val: string) => {
    dispatch(reportActions.setSortString(val));
  };

  return { sortString, setSortString, SORTER };
};
