import { getWeek } from 'date-fns';
import { FixedShift, Project, WeekDay, WeekType } from 'models';
import { ShiftInformation, ShiftInformationItem } from './ShiftFormInformation.styled';

interface Props {
  project: Project;
  formik: any;
  shifts: FixedShift[];
  shift: FixedShift;
}

const ShiftFormInformation = (props: Props) => {
  const { project, formik, shifts, shift } = { ...props };

  const getShiftDuration = () => {
    const selectingShiftId = formik.values.shiftId;
    const hours = shifts
      .filter((s) => s._id !== selectingShiftId)
      .reduce((a, b) => {
        return a + getIndividualShiftDuration(b.startTime, b.endTime, b.breakTime);
      }, 0);
    const formValues = formik.values;
    return (
      hours +
      getIndividualShiftDuration(formValues.startTime, formValues.endTime, formValues.breakTime)
    );
  };

  const getIndividualShiftDuration = (startTime: string, endTime: string, breakTime: number) => {
    const _endTime = endTime.split(':');
    const endTimeHour = parseInt(_endTime[0] === '00' ? '24' : _endTime[0]);
    const endTimeMinute = parseInt(_endTime[1]) / 60;
    const _startTime = startTime.split(':');
    const startTimeHour = parseInt(_startTime[0]);
    const startTimeMinute = parseInt(_startTime[1]) / 60;
    return endTimeHour + endTimeMinute - (startTimeHour + startTimeMinute) - breakTime || 0;
  };

  const getAgentCount = () => {
    const selectingShiftId = formik.values.shiftId;
    const agentCount = shifts
      .filter((s) => s._id !== selectingShiftId)
      .reduce((a, b) => {
        return a + b.numOfAgents;
      }, 0);
    return agentCount + formik.values.numOfAgents;
  };

  const getAllotedHours = (shift: FixedShift) => {
    const allotedHours = getIndividualShiftDuration(
      shift.startTime,
      shift.endTime,
      shift.breakTime
    );
    return allotedHours * shift.numOfAgents * getWeekTypeNumber(shift?.type || 1);
  };

  const getRemainingHours = () => {
    const _shifts = shifts.filter((s) => s._id !== formik.values.shiftId);
    const hours = _shifts.reduce((a, b) => {
      return a + getAllotedHours(b);
    }, 0);
    const formValues = formik.values;
    const currentShiftAllotedHours =
      getIndividualShiftDuration(formValues.startTime, formValues.endTime, formValues.breakTime) *
      formik.values.numOfAgents *
      getWeekTypeNumber(shift?.type || 1);
    return (project?.allottedHoursPerWeek || 0) - hours - currentShiftAllotedHours;
  };

  const getWeekTypeNumber = (type: WeekType) => {
    if (type === WeekType.WEEKDAY) {
      return 5;
    } else if (type === WeekType.WEEKEND) {
      return 2;
    } else {
      return 7;
    }
  };

  return (
    <ShiftInformation
      onMouseDown={(e: any) => {
        e.stopPropagation();
      }}
    >
      <ShiftInformationItem>
        Current Shift Duration: {getShiftDuration().toFixed(2)} hours
      </ShiftInformationItem>
      <ShiftInformationItem>Current Agent count: {getAgentCount()}</ShiftInformationItem>
      <ShiftInformationItem>
        Alloted Hours Remaining: {getRemainingHours().toFixed(2)} hours
      </ShiftInformationItem>
    </ShiftInformation>
  );
};

export default ShiftFormInformation;
