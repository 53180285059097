import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';
import { FieldArray } from 'formik';
import React from 'react';
import { Badge, Button, Col, Form, Modal } from 'react-bootstrap';
import { useModalManageDivision } from './useModalManageDivision';
import { SwatchesPicker } from 'react-color';
import { PickerPopover, PickerCover, RemoveDivisionButton } from './ModalManageDivision.styled';
import { ButtonTypes, Project } from 'models';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SpinnerButton } from 'components/Shared';
import DivisionItem from './DivisionItem';

interface Props {
  project: Project;
}
const ModalManageDivision = (props: Props) => {
  const { project } = { ...props };
  const { isVisible, setIsVisible, divisions, onRemoveDivision, onSelectDivisionColor, formik } =
    useModalManageDivision({
      projectId: project._id
    });

  return (
    <>
      <Button
        variant="warning"
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        Manange
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
        }}
        size="lg"
        centered
        scrollable={true}
        dialogAs={DraggableModalDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>{project.name}'s Division</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Col>
              <Form.Group
                // className={`mb-3 ${
                //   agentCount.length > 1 && userIndex > 0 ? 'pl-4' : ''
                // }`}
                controlId={`name`}
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Division Name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  name={`name`}
                  value={formik.values.name}
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                disabled={formik.isSubmitting}
                onClick={() => {
                  formik.submitForm();
                }}
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
              >
                Add
              </SpinnerButton>
            </Col>
          </Form.Row>

          {divisions.map((d) => (
            <DivisionItem
              key={d._id}
              division={d}
              onRemove={() => onRemoveDivision(d._id)}
              onSelectColor={(color: string) => {
                onSelectDivisionColor(d._id, d.name, color);
              }}
            />
          ))}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between"></Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalManageDivision;
