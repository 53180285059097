import { AppState } from '../index.store';

export const selectIsFetching = (state: AppState) => state.divisions.isFetching;

export const selectDivisions = (state: AppState) => state.divisions.divisions;

export const selectError = (state: AppState) => state.divisions.error;

export const selectDivisionsModalAddUser = (state: AppState) =>
  state.divisions.divisionsModalAddUser;

export const selectIsFetchingMultiProjectDivisions = (state: AppState) =>
  state.divisions.isFetchingMultiProjectDivisions;

export const selectIsCreating = (state: AppState) => state.divisions.isCreating;

export const selectIsDeleting = (state: AppState) => state.divisions.isDeleting;

export const selectIsUpdating = (state: AppState) => state.divisions.isUpdating;

export const selectSelectedDivisions = (state: AppState) => state.divisions.selectedDivisions;

export const selectSelectedAllDivisions = (state: AppState) => state.divisions.selectedAllDivisions;
