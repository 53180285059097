const AttendanceCheckingEarly = () => {
  return (
    <>
      <h3>
        You are checking in early. Sorry, you can only check in 35 minutes prior to the regular
        shift start.
      </h3>
      <div className="mt-3">
        <h2>Remaining Time:</h2>
      </div>
    </>
  );
};

export default AttendanceCheckingEarly;
