import { FcAvatar, FcMentionInput } from 'components/Shared';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { Comment, UpdateCommentPayload, User } from 'models';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { commentActions, commentSelector } from 'store/comments/comment.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatTo_HHMM, getReadableDurationString } from 'utils/formatters/datetime.formatter';
import {
  AgentCommentContent,
  AgentCommentItemWrapper,
  AgentCommentName,
  CommentAction,
  ToggleCustom
} from './AgentCommentItem.styled';
import { userApi } from 'services';
import { SuggestionDataItem } from 'react-mentions';
import { useAuth } from 'hooks';

interface Props {
  data: Comment;
}

const CustomToggle: React.FC<{ children: React.ReactNode; onClick: (e: any) => void }> =
  React.forwardRef(({ children, onClick }, ref: any) => (
    <a
      href="!#"
      ref={ref as any}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

const getContent = (content: string) => {
  const stringArr = content.split(' ');
  return (
    <span key={content.slice(0, 32)}>
      {stringArr.map((string) => {
        if (/^@(\[\w+\])(\(\w+\))/g.test(string)) {
          const reg = /\[(.*)\]/;
          const taggedUser = content.match(reg)![1];
          return (
            <span key={content.slice(0, 32)} className="text-primary">{`@${taggedUser} `}</span>
          );
        } else {
          return `${string} `;
        }
      })}
    </span>
  );
};

const AgentCommentItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const { data } = { ...props };
  const [isEditing, setIsEditing] = useState(false);
  const [commentString, setCommentString] = useState('');
  const [userTags, setUserTags] = useState<string[]>([]);
  const isUpdating = useAppSelector(commentSelector.selectIsUpdating);
  const error = useAppSelector(commentSelector.selectError);
  const currentUser = useAuth();

  useDidMountEffect(() => {
    if (!isUpdating && !error) {
      toast.success('Successfully Updated Comment.');
      setIsEditing(false);
    }
  }, [isUpdating]);

  const [isShow, setIsShow] = useState(false);
  useDidMountEffect(() => {
    if (!isUpdating && error) {
      toast.error('Update Comment Failed.');
    }
  }, [error]);

  useEffect(() => {
    setCommentString(data.content);
  }, []);

  const getUsers = async (query: string, callback: any) => {
    await userApi
      .getUsers({
        page: 1,
        perPage: 10,
        search: query
      })
      .then((res: any) => {
        return res.data
          .map((user: User) => user)
          .filter((user: User) => !userTags.includes(user._id));
      })
      // Transform the users to what react-mentions expects
      .then((res: User[]) => {
        return res.map(
          (user: User) =>
            ({ display: user?.displayName || user?.username, id: user._id } as SuggestionDataItem)
        );
      })
      .then(callback);
  };

  const updateStatus = (status: string, comment: Comment) => {
    dispatch(
      commentActions.updateComment({
        shiftId: comment.shift,
        slotId: comment.slot,
        commentId: comment._id,
        status: status,
        content: comment.content
      })
    );
  };

  const onDelete = () => {
    dispatch(commentActions.deleteComment(data));
  };

  const onClear = () => {
    setCommentString('');
    setUserTags([]);
  };

  const onSendComment = () => {
    const payload: UpdateCommentPayload = {
      shiftId: data.shift,
      slotId: data.slot,
      commentId: data._id,
      content: commentString,
      status: data.status
    };
    dispatch(commentActions.updateComment(payload));
  };

  const showingDelete = () => {
    return (
      currentUser.role === 'MANAGER' ||
      (currentUser.role === 'AGENT' &&
        data.userComment[0]._id === currentUser._id &&
        data.status === 'NONE')
    );
  };

  const CommentStatus = () => {
    if (data.status === 'APPROVED') {
      return <i className="far fa-circle-check text-success mr-2 pt-1" />;
    }
    if (data.status === 'DENIED') {
      return <i className="far fa-circle-xmark text-danger mr-2 pt-1" />;
    }
    return <></>;
  };

  const UserCommentInfo = () => {
    return (
      <div className="d-flex justify-items-center">
        <div className="pt-1">
          <FcAvatar
            size="28"
            textSizeRatio={2}
            name={data.userComment[0]?.displayName || data.userComment[0]?.username}
          />
        </div>
        <div className="pl-2">
          <span>{data.userComment[0]?.displayName || data.userComment[0]?.username}</span>
          <br />
          <span className="font-weight-normal">
            {formatTo_HHMM(new Date(data.updatedAt))}{' '}
            {getReadableDurationString(new Date(data.updatedAt))}
          </span>
        </div>
      </div>
    );
  };

  const UpdateCommentStatus = () => {
    return currentUser.role === 'MANAGER' && data.status === 'NONE' ? (
      <>
        <ToggleCustom
          onClick={() => {
            updateStatus('APPROVED', data);
          }}
        >
          <i className="fas fa-check text-success" />
        </ToggleCustom>
        <ToggleCustom
          onClick={() => {
            updateStatus('DENIED', data);
          }}
        >
          <i className="fas fa-xmark text-danger" />
        </ToggleCustom>
        <Dropdown.Divider />
      </>
    ) : null;
  };

  return (
    <AgentCommentItemWrapper className="w-100">
      <AgentCommentName className="d-flex justify-content-between aligns-content-start">
        {UserCommentInfo()}
        <div>
          <div className="d-flex flex-row justify-items-end aligns-content-center">
            {CommentStatus()}
            {currentUser.role === 'MANAGER' && data.status === 'NONE' && (
              <>
                <ToggleCustom
                  onClick={() => {
                    updateStatus('APPROVED', data);
                  }}
                >
                  <i className="fas fa-check text-success" />
                </ToggleCustom>
                <ToggleCustom
                  onClick={() => {
                    updateStatus('DENIED', data);
                  }}
                >
                  <i className="fas fa-xmark text-danger" />
                </ToggleCustom>
                <Dropdown.Divider />
              </>
            )}
            {currentUser.role === 'MANAGER' && data.status === 'NONE' && (
              <OverlayTrigger
                rootClose={true}
                placement="bottom"
                show={isShow}
                onToggle={() => {
                  setIsShow(!isShow);
                }}
                trigger="click"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Content className="p-0">
                      {currentUser._id === data.userComment[0]._id && (
                        <Dropdown.Item
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setIsEditing(true);
                            setIsShow(false);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                      )}
                      {showingDelete() && <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>}
                    </Popover.Content>
                  </Popover>
                }
              >
                <ToggleCustom>
                  <i className="fas fa-ellipsis-v clickable" />
                </ToggleCustom>
              </OverlayTrigger>
            )}
            {currentUser.role === 'AGENT' &&
              currentUser._id === data.userComment[0]._id &&
              data.status === 'NONE' && (
                <OverlayTrigger
                  rootClose={true}
                  placement="bottom"
                  show={isShow}
                  onToggle={() => {
                    setIsShow(!isShow);
                  }}
                  trigger="click"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Content className="p-0">
                        <Dropdown.Item
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setIsEditing(true);
                            setIsShow(false);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <ToggleCustom>
                    <i className="fas fa-ellipsis-v clickable" />
                  </ToggleCustom>
                </OverlayTrigger>
              )}
          </div>
        </div>
      </AgentCommentName>
      <AgentCommentContent>
        {!isEditing && <span>{getContent(data.content)}</span>}
        {isEditing && (
          <div>
            <FcMentionInput
              value={commentString}
              onChange={(newValue: string) => {
                setCommentString(newValue);
              }}
              selectedValue={userTags}
              onMention={(ids: string[]) => {
                setUserTags(ids);
              }}
              data={getUsers}
            />
            <div className="text-right">
              <CommentAction className="mr-2" onClick={onClear}>
                <i className="fas fa-eraser" />
              </CommentAction>
              <CommentAction className="text-primary" onClick={onSendComment}>
                <i className="fas fa-paper-plane" />
              </CommentAction>
            </div>
          </div>
        )}
      </AgentCommentContent>
    </AgentCommentItemWrapper>
  );
};

export default React.memo(AgentCommentItem);
