import { format, getWeek, parse, getMinutes } from 'date-fns';
import { FixedShift, FixedSlot, User, UserSlot, WeekDay } from 'models';
import React, { SetStateAction, useEffect } from 'react';
import { contextMenu, Item, Menu, useContextMenu } from 'react-contexify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { schedulerFixActions } from 'store/schedulerFixs/schedulerFix.slice';
import Event from '../../MainActions/Event/Event';
import ShiftAgent from '../../MainActions/ShiftAgent/ShiftAgent';
import { ShiftHeader } from './SchedulerRow.styled';
import { useAuth } from 'hooks';
import { projectSelector } from 'store/projects/project.slice';
import { ShiftHelper } from 'helpers';
import { useSchedulerRowHeader } from './../../../../hooks/scheduler/useSchedulerRowHeader';
interface Props {
  shift: FixedShift;
  setDraggingItem: React.Dispatch<SetStateAction<object>>;
  setDroppedItem: React.Dispatch<SetStateAction<object>>;
}

const SchedulerRow = (props: Props) => {
  const currentUser = useAuth();
  const { shift, setDraggingItem, setDroppedItem } = props;
  const { show } = useContextMenu();
  const displayMenu = (e: any, id: string) => {
    if (currentUser?.role !== 'MANAGER') {
      return;
    }
    show({
      event: e,
      id: id
    });
  };
  const parseShiftTime = (time: string) => {
    const parsedTime = parse(time, 'HH:mm', new Date());
    const formatString = getMinutes(parsedTime) === 0 ? 'ha' : 'h:mma';
    return format(parsedTime, formatString);
  };

  const getWeekDay = () => {
    const weekDef = useAppSelector(projectSelector.selectSelectedProject)?.weekDefinition || 0;
    if (weekDef !== 0) {
      const weekdays = Object.values(WeekDay).filter((d) => typeof d === 'number');
      const firstArr = weekdays.slice(weekDef - 1) as any;
      const secondArr = weekdays.slice(0, weekDef - 1) as any;
      const res = [...firstArr, ...secondArr];
      return res;
    }
    return Object.keys(WeekDay).filter((d) => isNaN(parseInt(d)));
  };

  const dispatch = useAppDispatch();

  const getShiftDuration = (shift: FixedShift) => {
    const _endTime = shift.endTime.split(':');
    const endTimeHour = parseInt(_endTime[0] === '00' ? '24' : _endTime[0]);
    const endTimeMinute = parseInt(_endTime[1]) / 60;
    const _startTime = shift.startTime.split(':');
    const startTimeHour = parseInt(_startTime[0]);
    const startTimeMinute = parseInt(_startTime[1]) / 60;
    return endTimeHour + endTimeMinute - (startTimeHour + startTimeMinute) || 0;
  };

  const getShiftInfo = (shift: FixedShift) => {
    return (
      <div>
        <p>{shift.name}</p>
        <p>
          {parseShiftTime(shift.startTime)} - {parseShiftTime(shift.endTime)}
        </p>
        <p className="font-italic">
          <span className="mr-1">Shift duration:</span>
          {ShiftHelper.getDurationOfShift(shift).toFixed(2)}
        </p>
        <p className="font-italic">
          <span className="mr-1">Agent Count:</span>
          {shift.numOfAgents}
        </p>
      </div>
    );
  };

  const isSlotSameDateColumn = (date: number, slot: FixedSlot) => {
    return date === slot.weekday;
  };

  const onDrop = (e: any, weekday: number) => {
    e.stopPropagation();
    contextMenu.show({
      id: 'CREATE',
      event: e
    });
    props.setDroppedItem({
      type: 'shift',
      weekday: weekday,
      shiftId: shift._id
    });
  };

  const onAssign = (weekday: number) => {
    dispatch(schedulerFixActions.setIsModalCreateSlotVisible(true));
    dispatch(schedulerFixActions.setSelectedShiftId(shift._id));
    dispatch(schedulerFixActions.setSelectedDate(weekday));
  };

  const getBackgroundColor = (weekday: number) => {
    return isWeekend(weekday) ? '#5e72e40d' : 'none';
  };

  const isWeekend = (weekday: number) => {
    return weekday === 6 || weekday === 7;
  };
  return (
    <tr>
      {useSchedulerRowHeader(shift)}
      {getWeekDay().map((d: number, index: number) => (
        <td
          key={index}
          onDrop={(e) => onDrop(e, d)}
          onDragStart={(e) => e.stopPropagation()}
          onDragEnter={(e) => e.stopPropagation()}
          onDragLeave={(e) => e.stopPropagation()}
          onDragOver={(e) => e.preventDefault()}
          onContextMenu={(e) => {
            e.stopPropagation();
            displayMenu(e, `${index + shift._id}`);
          }}
          style={{
            background: getBackgroundColor(d)
          }}
        >
          <div>
            <Menu id={index + shift._id} theme="light">
              <Item onClick={() => onAssign(d)}>Assign</Item>
            </Menu>
          </div>
          {shift.slots.map(
            (slot: FixedSlot, slotIndex: number) =>
              isSlotSameDateColumn(d, slot) && (
                <ShiftAgent
                  key={slot._id + slotIndex}
                  index={index}
                  slotIndex={slotIndex}
                  data={slot}
                  shiftData={shift}
                  shiftDuration={getShiftDuration(shift)}
                  onSetDraggingItem={setDraggingItem}
                  onSetDroppedItem={setDroppedItem}
                  date={d}
                />
              )
          )}
        </td>
      ))}
    </tr>
  );
};

export default SchedulerRow;
