import { useEffect, useState } from 'react';
import { AttendanceCheckingWrapper } from './AttendanceChecking.styled';
import { Form, Col, Spinner, Button } from 'react-bootstrap';
import { fixedShiftActions, fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { Project } from 'models';
import { attendanceActions } from 'store/attendances/attendance.slice';
import { divisionActions, divisionSelector } from 'store/divisions/division.slice';

interface Props {
  setIsCheckingIn: (status: boolean) => void;
  setSelectedProject: (value: string) => void;
  setSelectedShift: (value: string) => void;
  setSelectedDivision: (value: string) => void;
  selectedProject: string;
  selectedShift: string;
  selectedDivision: string;
  note: string;
  setNote: (value: string) => void;
}
const AttendanceChecking = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    setIsCheckingIn,
    selectedProject,
    selectedShift,
    setSelectedProject,
    setSelectedShift,
    selectedDivision,
    setSelectedDivision,
    note,
    setNote
  } = { ...props };

  const projects = useAppSelector(projectSelector.selectProjects);
  const isLoadingProject: boolean = useAppSelector(projectSelector.selectIsFetching);
  const shifts = useAppSelector(fixedShiftSelector.selectShifts);
  const isLoadingShift: boolean = useAppSelector(fixedShiftSelector.selectIsFetching);
  const divisions = useAppSelector(divisionSelector.selectDivisions);
  const isLoadingDivision: boolean = useAppSelector(divisionSelector.selectIsFetching);

  const onChangeProject = (projectId: string) => {
    setSelectedProject(projectId);
    dispatch(fixedShiftActions.getShifts(projectId));
    dispatch(divisionActions.getDivisions({ projectId: projectId }));
    setSelectedDivision('');
  };

  const onCancel = () => {
    setIsCheckingIn(false);
  };

  const onCheckin = () => {
    dispatch(
      attendanceActions.checkin({
        project: selectedProject,
        shift: selectedShift,
        division: selectedDivision,
        note: note
      })
    );
  };
  useEffect(() => {
    if (!isLoadingProject) {
      if (projects.length > 0) {
        setSelectedProject(projects[0]._id);
      }
      dispatch(fixedShiftActions.getShifts(projects[0]._id));
      dispatch(divisionActions.getDivisions({ projectId: projects[0]._id }));
    }
  }, [isLoadingProject]);

  useEffect(() => {
    if (!isLoadingShift && shifts.length > 0) {
      setSelectedShift(shifts[0]._id);
    }
  }, [isLoadingShift]);

  useEffect(() => {
    if (!isLoadingDivision && divisions.length > 0) {
      setSelectedDivision(divisions[0]._id);
    }
  }, [isLoadingDivision]);

  return (
    <>
      <h3>You are checking in. Please provide us with more information!</h3>
      <div className="w-100 p-5">
        {isLoadingProject && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="text-center" />
          </div>
        )}
        {!isLoadingProject && (
          <>
            <Form>
              <Form.Row className="justify-content-between">
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Project</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Project"
                    onChange={(e) => onChangeProject(e.target.value)}
                    name="projectId"
                    value={selectedProject}
                    custom
                  >
                    {projects.map((project: Project) => (
                      <option key={project._id} value={project._id}>
                        {project.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Shift</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Shift"
                    onChange={(e) => setSelectedShift(e.target.value)}
                    name="type"
                    value={selectedShift}
                    custom
                  >
                    {shifts.map((shift) => (
                      <option key={shift._id} value={shift._id}>
                        {shift.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-between">
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Note</Form.Label>
                  <Form.Control type="text" onChange={(e) => setNote(e.target.value)} name="note" />
                </Form.Group>
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Division</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Division"
                    onChange={(e) => setSelectedDivision(e.target.value)}
                    name="division"
                    value={selectedDivision}
                    custom
                  >
                    {divisions.map((division) => (
                      <option key={division._id} value={division._id}>
                        {division.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onCheckin}>
                Check-in
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AttendanceChecking;
