import { useAuth } from 'hooks';
import { Comment } from 'models';
import React, { useState } from 'react';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { AgentCommentPopover, AgentCommentWrapper } from './AgentComment.styled';
import AgentCommentItem from '../AgentCommentItem/AgentCommentItem';
interface Props {
  data: Comment[];
}

const AgentComment = (props: Props) => {
  const dispatch = useAppDispatch();
  const { data } = { ...props };
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const currentUser = useAuth();

  const mouseOverhandler = () => {
    if (!isClicked) {
      setIsShow(true);
    }
  };

  const mouseOuthandler = () => {
    if (!isClicked) {
      setIsShow(false);
    }
  };

  return data && data.length > 0 ? (
    <div onMouseOver={mouseOverhandler} onMouseOut={mouseOuthandler} className="clickable">
      <OverlayTrigger
        rootClose={true}
        placement="auto"
        show={isShow}
        onToggle={() => {
          setIsClicked(false);
          setIsShow(false);
        }}
        trigger={['hover', 'focus', 'click']}
        overlay={
          <AgentCommentPopover
            id="popover-basic"
            onMouseOver={mouseOverhandler}
            onMouseOut={mouseOuthandler}
            onClick={() => setIsClicked(true)}
          >
            <Popover.Content>
              <div>
                {data.map((c: Comment) => (
                  <AgentCommentItem data={c} key={c._id} />
                ))}
              </div>
            </Popover.Content>
          </AgentCommentPopover>
        }
      >
        <AgentCommentWrapper>{data.length}</AgentCommentWrapper>
      </OverlayTrigger>
    </div>
  ) : (
    <></>
  );
};
export default React.memo(AgentComment);
