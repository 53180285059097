import { SpinnerButton, FcModalConfirm } from 'components/Shared';
import { ButtonTypes } from 'models';
import { useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import {
  addDays,
  eachDayOfInterval,
  endOfISOWeek,
  format,
  isSameDay,
  startOfISOWeek,
  subDays,
  startOfMonth,
  endOfMonth,
  addMonths
} from 'date-fns';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { staticRanges, defineds } from './staticRanges';
import { fixedSlotActions, fixedSlotSelector } from 'store/fixedSlots/fixedSlot.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface Props {
  selectedProject: string;
  fixedSlotsIndex: number;
}

const ExportSchedulerFix = (props: Props) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(fixedSlotSelector.selectIsLoading);
  const [isModalExportVisible, setIsModalExportVisible] = useState(false);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [timeString, setTimeString] = useState<string>('This week');
  const [ranges, setRanges] = useState<any>([
    {
      startDate: startOfISOWeek(new Date()),
      endDate: endOfISOWeek(new Date()),
      key: 'selection'
    }
  ]);
  useEffect(() => {
    const dates = eachDayOfInterval({
      start: ranges[0].startDate,
      end: ranges[0].endDate
    });
    updateTimeString();
  }, [ranges]);

  useEffect(() => {
    setRanges([
      {
        startDate: startOfISOWeek(new Date()),
        endDate: endOfISOWeek(new Date()),
        key: 'selection'
      }
    ]);
    setIsModalExportVisible(false);
  }, [isLoading]);

  const updateTimeString = () => {
    const startDate = ranges[0].startDate;
    const endDate = ranges[0].endDate;
    if (
      isSameDay(startDate, defineds.startOfLastWeek) &&
      isSameDay(endDate, defineds.endOfLastWeek)
    ) {
      setTimeString('Last week');
      return;
    }

    if (isSameDay(startDate, defineds.startOfWeek) && isSameDay(endDate, defineds.endOfWeek)) {
      setTimeString('This week');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfNextWeek) &&
      isSameDay(endDate, defineds.endOfNextWeek)
    ) {
      setTimeString('Next week');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfLastMonth) &&
      isSameDay(endDate, defineds.endOfLastMonth)
    ) {
      setTimeString('Last month');
      return;
    }

    if (isSameDay(startDate, defineds.startOfMonth) && isSameDay(endDate, defineds.endOfMonth)) {
      setTimeString('This month');
      return;
    }

    if (
      isSameDay(startDate, defineds.startOfNextMonth) &&
      isSameDay(endDate, defineds.endOfNextMonth)
    ) {
      setTimeString('Next month');
      return;
    }

    setTimeString(
      `${format(startDate, 'E, MMM dd, yyyy')} - ${format(endDate, 'E, MMM dd, yyyy')}`
    );
  };

  const onExport = () => {
    setIsModalConfirmVisible(false);
    dispatch(
      fixedSlotActions.exportFixedSlots({
        dateStart: formatTo_yyyyMMdd(ranges[0].startDate),
        dateEnd: formatTo_yyyyMMdd(ranges[0].endDate),
        projectId: props.selectedProject,
        fixedSlotsIndex: props.fixedSlotsIndex
      })
    );
  };
  return (
    <>
      <SpinnerButton
        size="sm"
        btnType={ButtonTypes.Submit}
        disabled={false}
        colorType="primary"
        onClick={() => {
          setIsModalExportVisible(true);
        }}
      >
        Export
      </SpinnerButton>
      <Modal
        className="modal-confirm"
        show={isModalExportVisible}
        onHide={() => {
          setIsModalExportVisible(false);
        }}
        size="sm"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <span className="mb-1">Date Range to be exported</span>
          <br />
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom-end"
            overlay={
              <Popover id="popover-basic" style={{ maxWidth: '600px' }}>
                <Popover.Content style={{ padding: 0 }}>
                  <DateRangePicker
                    editableDateInputs={true}
                    onChange={(item) => setRanges([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={ranges}
                    months={1}
                    weekStartsOn={1}
                    rangeColors={['#5e72e4']}
                    color="#5e72e4"
                    direction="horizontal"
                    staticRanges={staticRanges}
                  />
                </Popover.Content>
              </Popover>
            }
          >
            <Button variant="outline-primary">
              <i className="fas fa-calendar mr-2" />
              {timeString}
            </Button>
          </OverlayTrigger>
        </Modal.Body>
        <Modal.Footer>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={isLoading}
            colorType="primary"
            onClick={() => {
              setIsModalConfirmVisible(true);
            }}
          >
            Export
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
      <FcModalConfirm
        show={isModalConfirmVisible}
        onHide={() => setIsModalConfirmVisible(false)}
        title="Confirmation"
        body={
          <>
            <p>
              By exporting, Actual Schedule will be overwritten on selected time. Are you sure you
              want to perform this action?
            </p>
          </>
        }
        footer={
          <>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setIsModalConfirmVisible(false);
              }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <SpinnerButton
              btnType="submit"
              colorType="primary"
              onClick={onExport}
              disabled={isLoading}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              OK
            </SpinnerButton>
          </>
        }
      />
    </>
  );
};

export default ExportSchedulerFix;
