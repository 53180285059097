import { SlotValidatorHelper, ErrorTypes, ErrorMessages } from 'helpers';
import { Shift, Slot, User } from 'models';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { shiftSelector } from 'store/shifts/shift.slice';
import { WarningTriangle } from './WarningTag.styled';

interface Props {
  users: User[];
  shiftId: string;
  date: Date;
  slot: Slot;
}

const WarningTag = (props: Props) => {
  const shifts: Shift[] = useAppSelector(shiftSelector.selectShifts);
  const slotValidatorHelper = new SlotValidatorHelper({ ...props, shifts: shifts });
  const errors = slotValidatorHelper.getErrors();

  const hasErrors = () => {
    return (
      errors[ErrorTypes.EXCESS_HOURS].length > 0 ||
      errors[ErrorTypes.INVALID_AGENT].length > 0 ||
      errors[ErrorTypes.NOT_ENOUGH_HOURS].length > 0
    );
  };

  return (
    <>
      {hasErrors() && (
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip
              id={`tooltip-auto`}
              className="warning-tag"
              title={`${props.shiftId.slice(-3)}-${props.slot._id.slice(-3)}`}
              style={{ textAlign: 'left' }}
            >
              <>
                {Object.keys(errors).map((errorType: any, index: number) => (
                  <React.Fragment key={index}>
                    {Object.values(errors[errorType]).length > 0 && (
                      <div>
                        <span>
                          <strong title={errorType}>{`- ${ErrorMessages[errorType]}`}:</strong>
                        </span>
                        <ul>
                          {Object.values(errors[errorType]).map((error: any, errIndex) => (
                            <li key={errIndex}>{error.join(', ')}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            </Tooltip>
          }
        >
          <WarningTriangle title="warningLabel" className="clickable">
            <i className="fa-solid fa-exclamation"></i>
          </WarningTriangle>
        </OverlayTrigger>
      )}
    </>
  );
};

export default React.memo(WarningTag);
// export default WarningTag;
