import { User } from 'models';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions } from 'store/users/user.slice';
import { useProjectFilter } from './useProjectFilter';
import { useUserFilter } from './useUserFilter';
import { reportSelector } from 'store/reports/report.slice';

export const usePersonalFilter = () => {
  const dispatch = useAppDispatch();
  const [label, setLabel] = useState('Personal Filter');
  const [selectedFilter, setSelectedFilter] = useState('');
  const filters = useAppSelector(reportSelector.selectFilters);
  const { projects, setSelectedProject } = useProjectFilter();
  const { setSelectedUsers, setSelectedAll } = useUserFilter();

  const onSelectFilter = (filterId: string) => {
    const filter = filters.find((f) => f._id === filterId);
    if (filter) {
      const project = projects.find((p) => p._id === filter.project._id);
      setSelectedFilter(filterId);
      setLabel(filter.name);
      if (project) {
        setSelectedProject(project._id);
        const users: User[] = project.users || [];
        dispatch(userActions.setUsers(users));
        setSelectedUsers(filter.employees.map((user) => user._id));
        if (project.users.length === filter.employees.length) {
          setSelectedAll(true);
        } else {
          setSelectedAll(false);
        }
      }
    }
  };

  return { filters, label, selectedFilter, onSelectFilter };
};
