import { Division } from 'models';
import { Nullable } from '../../utils/common/types';

export type DivisionInitialState = {
  isFetching: boolean;
  isFetchingMultiProjectDivisions: boolean;
  divisions: Division[];
  divisionsModalAddUser: Array<any>;
  selectedDivisions: string[];
  selectedAllDivisions: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  string: boolean;
  error: any;
};

export const initialState: DivisionInitialState = {
  isFetching: false,
  isFetchingMultiProjectDivisions: false,
  divisions: [],
  divisionsModalAddUser: [],
  selectedDivisions: [],
  selectedAllDivisions: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  string: false,
  error: null
};
