import { DropdownAutoCloseOutside } from 'components/Shared';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from './FilterDropdown.styled';
import { useUserFilter } from './useUserFilter';
import { useEffect, useState } from 'react';

const UserFilter = () => {
  const { users, userLabel, selectedAll, selectedUsers, onSelectUser } = useUserFilter();
  const [activeFilter, setActiveFilter] = useState(users);

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(users);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(users);
    } else {
      const filtersList = users.filter((f) =>
        f.username.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  return (
    <DropdownAutoCloseOutside className="mr-2">
      <Dropdown.Toggle size="sm" variant="outline-primary" onMouseEnter={onSetFilter}>
        {userLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        <Dropdown.Item as="li">
          <FilterItem>
            <input
              type="text"
              onChange={(e) => onChangeFilter(e.target.value)}
              placeholder="Search Item"
            />
          </FilterItem>
        </Dropdown.Item>
        {users.length > 0 && (
          <Dropdown.Item as="li" onClick={(e) => onSelectUser(e, 'all')}>
            <FilterItem>
              <input
                type="checkbox"
                value={'all'}
                checked={selectedAll}
                onChange={(e) => onSelectUser(e, 'all')}
              />
              <FilterItemLabel>All Employees</FilterItemLabel>
            </FilterItem>
          </Dropdown.Item>
        )}
        {activeFilter.length > 0 &&
          activeFilter.map((u) => (
            <Dropdown.Item key={u._id} as="li" onClick={(e) => onSelectUser(e, u._id)}>
              <FilterItem>
                <input
                  type="checkbox"
                  value={u._id}
                  checked={selectedUsers.includes(u._id)}
                  onChange={(e) => onSelectUser(e, u._id)}
                />
                <FilterItemLabel>{u.username}</FilterItemLabel>
              </FilterItem>
            </Dropdown.Item>
          ))}
        {activeFilter.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};
export default UserFilter;
