import { Card } from 'react-bootstrap';
import {
  SalaryPeriodWrapper,
  SalaryPeriodHeader,
  SalaryPeriodContent
} from './SalaryPeriod.styled';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { salaryPeriodActions, salaryPeriodSelector } from 'store/salaryPeriods/salaryPeriod.slice';
import { formatTo_MMMd } from 'utils/formatters/datetime.formatter';

const SalaryPeriod = () => {
  const dispatch = useAppDispatch();
  const currentPeriod = useAppSelector(salaryPeriodSelector.selectCurrentPeriod);

  useEffect(() => {
    dispatch(salaryPeriodActions.getCurrentPeriod());
  }, []);

  return (
    <SalaryPeriodWrapper>
      <Card bg="primary" className="text-white mb-2">
        <Card.Body className="p-2">
          <SalaryPeriodHeader>Current Period</SalaryPeriodHeader>
          <br />

          <SalaryPeriodContent>
            {currentPeriod !== undefined ? (
              <>
                {formatTo_MMMd(new Date(currentPeriod.startDate))} -{' '}
                {formatTo_MMMd(new Date(currentPeriod.endDate))}: 15 Hours
              </>
            ) : (
              <>NaN - NaN: 15 Hours</>
            )}
          </SalaryPeriodContent>
        </Card.Body>
      </Card>

      <Card bg="primary" className="text-white mb-2">
        <Card.Body className="p-2">
          <SalaryPeriodContent>Nov 21: 5 Hours</SalaryPeriodContent>
        </Card.Body>
      </Card>
      <Card bg="primary" className="text-white mb-2">
        <Card.Body className="p-2">
          <SalaryPeriodContent>This week: 11 Hours</SalaryPeriodContent>
        </Card.Body>
      </Card>
      <Card bg="primary" className="text-white mb-0">
        <Card.Body className="p-2">
          <SalaryPeriodContent>This month: 33 Hours</SalaryPeriodContent>
        </Card.Body>
      </Card>
    </SalaryPeriodWrapper>
  );
};

export default SalaryPeriod;
