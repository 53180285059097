import styled from 'styled-components';

export const EventItem = styled.div`
  color: #32539c;
  display: inline-block;
  opacity: 1;
  clear: left;
  float: left;
  line-height: 1.5rem;

  & p {
    line-height: 1.25rem;
  }

  &:hover {
    cursor: grab;
    text-decoration: underline;
  }
  &.drag-entered {
    & p {
      text-decoration: underline !important;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const EventName = styled.p`
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1rem;
  border-radius: 0.25rem;
  padding: 0 0.3rem 0 0.2rem;
`;

export const EventHourCount = styled.p`
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1rem;
`;
