import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './App.scss';
import 'assets/stylesheets/argon.min.css';
import 'assets/stylesheets/colors.scss';
import { CookiesProvider } from 'react-cookie';

import AppRouter from './routes/app.route';
function App() {
  return (
    <div>
      <CookiesProvider>
        <AppRouter />
      </CookiesProvider>
      <ToastContainer
        position="top-right"
        autoClose={1200}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
