import { WarningIcon } from './DuplicatedWarning.styled';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Shift, Slot } from 'models';
import { format } from 'date-fns';

interface Props {
  formik: any;
  shifts: Shift[];
  agentIndex: number;
  userIndex: number;
}

const FieldWarning = (props: Props) => {
  const { formik, shifts, agentIndex, userIndex } = props;

  const isAgentDuplicated = () => {
    const shift = shifts.find((shift) => shift._id === formik.values.shiftId);

    const userId = formik.values.employees[agentIndex][userIndex].userId;
    if (userId !== 'multiple' && userId !== '') {
      const userSlotsInForm: any = [];
      formik.values.employees.map((slot: any) => slot.map((obj: any) => userSlotsInForm.push(obj)));
      const sameUserSlotsInForm = userSlotsInForm.filter(
        (userSlot: any) => userSlot.userId === userId
      );
      return sameUserSlotsInForm.length > 1;
    }
  };

  return (
    <>
      {isAgentDuplicated() && (
        <div className="form-group mb-3">
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id={`tooltip-auto`} className="duplicate-warning-tooltip">
                Duplicated
              </Tooltip>
            }
          >
            <WarningIcon
              className="fa-solid fa-triangle-exclamation text-yellow"
              title={`duplicateWarning`}
            />
          </OverlayTrigger>
        </div>
      )}
    </>
  );
};

export default FieldWarning;
