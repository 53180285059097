import { DropdownAutoCloseOutside } from 'components/Shared';
import { Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect, useState } from 'react';
import { filterSelector } from 'store/filters/filter.slice';
import { FilterItem, FilterItemLabel } from 'hooks/scheduler/FilterDropdown.styled';
import { projectSelector } from 'store/projects/project.slice';
import { shiftActions, shiftSelector } from 'store/shifts/shift.slice';

interface Props {
  [key: string]: unknown;
  // setSelectedProjects: (projectIds: string[]) => void;
}

const AttendanceShiftFilter = (props: Props) => {
  // const { setSelectedProjects } = { ...props };
  const dispatch = useAppDispatch();
  const [label, setLabel] = useState('Shift');
  const shifts = useAppSelector(shiftSelector.selectShifts);
  const selectedShift = useAppSelector(shiftSelector.selectSelectedShift);

  useEffect(() => {
    const filterId = localStorage.getItem('filterId');
    if (filterId) {
      onSelectShift(filterId);
    }
  }, []);

  const onSelectShift = (shiftId: string) => {
    const shift = shifts.find((s) => s._id === shiftId);
    if (shift) {
      setLabel(shift.name);
      dispatch(shiftActions.setSelectedShift(shift));
      // const project = projects.find((p) => p._id === filter.project._id);
      // setSelectedFilter(filterId);
      // localStorage.setItem('filterId', filterId);
      // setLabel(filter.name);
      // if (project) {
      //   onSelectProject(null, project._id);
      //   const users: User[] = project.users || [];
      //   dispatch(userActions.setUsers(users));
      //   setSelectedUsers(filter.employees.map((user) => user._id));
      //   if (project.users.length === filter.employees.length) {
      //     setSelectedAll(true);
      //   } else {
      //     setSelectedAll(false);
      //   }
      // }
    }
  };

  return (
    <DropdownAutoCloseOutside className="mr-2" {...props}>
      <Dropdown.Toggle size="sm" variant="outline-primary h-100">
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        {shifts.length > 0 &&
          shifts.map((shift) => (
            <Dropdown.Item key={shift._id} as="li" onClick={(e) => onSelectShift(shift._id)}>
              <FilterItem>
                <input
                  type="radio"
                  value={shift._id}
                  checked={selectedShift !== null && selectedShift._id === shift._id}
                  onChange={(e) => onSelectShift(shift._id)}
                />
                <FilterItemLabel>{shift.name}</FilterItemLabel>
              </FilterItem>
            </Dropdown.Item>
          ))}
        {shifts.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};
export default AttendanceShiftFilter;
