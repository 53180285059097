import { Button } from 'react-bootstrap';
import { fixedShiftActions } from 'store/fixedShifts/fixedShift.slice';
import { schedulerFixActions } from 'store/schedulerFixs/schedulerFix.slice';
import { useAppDispatch } from 'store/hooks';

interface Props {
  selectedProject: string;
  fixedSlotsIndex: number;
  setIsAddingMoreIndex: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  setIsVisibleDeleteConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

const ButtonApplyFilter = (props: Props) => {
  const dispatch = useAppDispatch();

  const onApplyFilter = () => {
    // dispatch(fixedShiftActions.getShifts(props.selectedProject));
    dispatch(
      fixedShiftActions.getHighestIndexFixedSlot({
        projectId: props.selectedProject
      })
    );
    dispatch(
      fixedShiftActions.getShiftsSlotIndex({
        projectId: props.selectedProject,
        fixedSlotsIndex: props.fixedSlotsIndex
      })
    );
    dispatch(schedulerFixActions.setIsApplyingFilter(false));
    props.setIsAddingMoreIndex(false);
    props.setSelectedTab(1);
    props.setIsVisibleDeleteConfirm(false);
  };

  return (
    <Button
      size="sm"
      variant="primary"
      onClick={onApplyFilter}
      // disabled={props.isFetchingShifts}
    >
      Apply
    </Button>
  );
};

export default ButtonApplyFilter;
