import { FormikProps, useFormik } from 'formik';
import { ShiftHelper } from 'helpers';
import { Shift, Slot, UserSlot } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { schedulerActions, schedulerSelector } from 'store/schedulers/scheduler.slice';
import { slotActions, slotSelector } from 'store/slots/slot.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { slotSchema } from 'utils/validators';

interface Props {
  show: boolean;
  shifts: Shift[];
  date: string;
  onHide: () => void;
}

type FormEmployeesProp = Array<
  Array<Omit<UserSlot, 'duration'> & { duration: string; divisionId?: string }>
>;

// type FormEmployee
interface FormProps {
  date: string;
  shiftId: string;
  employees: FormEmployeesProp;
}

export const useModalCreateSlot = (props: Props) => {
  const dispatch = useAppDispatch();
  const error: any = useAppSelector(slotSelector.selectError);
  const isLoading = useAppSelector(slotSelector.selectIsLoading);
  const [modalWarningVisible, setModalWarningVisible] = useState(false);
  const [modalConfirmEmptyVisible, setModalConfirmEmptyVisible] = useState(false);
  const [isShowConfirmChangeModal, setIsShowConfirmChangeModal] = useState(false);
  const [submittingType, setSubmittingType] = useState('');
  const [isBeingProceed, setIsBeingProceed] = useState(false);
  const [changingValue, setChangingValue] = useState({});
  const { date } = props;
  const shiftId = useAppSelector(schedulerSelector.selectSelectedShiftId);
  const project = useAppSelector(projectSelector.selectSelectedProject);

  const shiftDuration = useMemo(() => {
    return ShiftHelper.getShiftDuration(props.shifts, shiftId);
  }, [shiftId]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
      formik.setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (error === null && !isLoading && formik.isSubmitting) {
      toast.success('Successfully Created.');
      formik.setSubmitting(false);
      submittingType === 'submit' ? handleSubmitSuccess() : handleApplySuccess();
    }
  }, [isLoading]);

  const handleSubmitSuccess = () => {
    props.onHide();
  };

  const handleApplySuccess = () => {
    setModalWarningVisible(false);
    if (!isBeingProceed) {
      formik.resetForm({ values: { ...formik.values } });
      return;
    }
    const _emplyoees = getInitialEmployees(date, formik.values.shiftId) as FormEmployeesProp;
    const _shiftId = ((isChangingShift() ? changingValueRes : shiftId) as string) || '';
    resetForm(date, _shiftId, _emplyoees);
    setIsBeingProceed(false);
  };

  const getInitialEmployees = (date: string, shiftId: string): FormEmployeesProp => {
    const shift = ShiftHelper.findById(props.shifts, shiftId) as Shift | undefined;

    if (!shift) return [];

    const existingSlots = mapExistingSlots(shift);
    const missingSlots = mapMissingSlots(shift, existingSlots.length);
    return [...existingSlots, ...missingSlots];
  };

  const mapMissingSlots = (shift: Shift, existingSlotsLength: number) => {
    const agentCount = shift?.numOfAgents || 0;
    const missingSlots: any[] = [];
    for (let i = 0; i < agentCount - existingSlotsLength; i++) {
      missingSlots.push([createEmptySlot()]);
    }
    return missingSlots;
  };

  const mapExistingSlots = (shift: Shift) => {
    const existingSlots: Slot[] = ShiftHelper.findSlotSameDate(shift, date) as Slot[];

    return existingSlots.map((slot) => {
      const isSingleSlot = slot.userSlots.length === 1;
      return isSingleSlot ? [mapUserSlot(slot, slot.userSlots[0])] : handleMultiUserSlot(slot);
    });
  };

  const createEmptySlot = () => ({
    userId: '',
    duration: shiftDuration.toString(),
    divisionId: '',
    note: ''
  });

  const mapUserSlot = (slot: Slot, userSlot: UserSlot) => ({
    userId: userSlot.user._id,
    duration: userSlot.duration,
    note: userSlot.note,
    divisionId: slot.division?.[0]?._id || (slot.division as any)?._id,
    user: userSlot.user
  });

  const handleMultiUserSlot = (slot: Slot) => {
    const res = [
      {
        userId: 'multiple',
        divisionId: slot.division?.[0]?._id || (slot.division as any)?._id,
        duration: shiftDuration.toString(),
        note: ''
      }
    ];
    return [...res, ...slot.userSlots.map((userSlot) => mapUserSlot(slot, userSlot))];
  };

  const initialValues: FormProps = {
    date: date,
    shiftId: shiftId,
    employees: getInitialEmployees(date, shiftId)
  };

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: initialValues,
    validationSchema: slotSchema,
    onSubmit: (values: FormProps) => {
      handleSubmit(values);
    }
  });

  const onDateChange = (item: any) => {
    const newDate = formatTo_yyyyMMdd(item);
    if (changeWithoutConfirm()) {
      setSelectedDate(newDate);
      const employees = getInitialEmployees(newDate, formik.values.shiftId);
      resetForm(newDate, formik.values.shiftId, employees);
      return;
    }
    handleChangeWithConfirm({ date: newDate });
  };

  const onChangeShift = (e: any) => {
    const shiftId = e.target.value;
    if (changeWithoutConfirm()) {
      const newEmployees = getInitialEmployees(formik.values.date, shiftId);
      setSelectedShiftId(shiftId);
      resetForm(date, shiftId, newEmployees);
      return;
    }
    handleChangeWithConfirm({ shiftId: shiftId });
  };

  const changeWithoutConfirm = () => {
    const _employees = formik.values.employees;
    const _initialEmployees = formik.initialValues.employees;
    // return true if form values hasn't different with initialValues of just change duration of initialValues
    return _employees?.[0]?.[0].userId === '' || _initialEmployees === _employees;
  };

  const handleChangeWithConfirm = (value: object) => {
    setIsShowConfirmChangeModal(true);
    setChangingValue(value);
  };

  const handleSubmit = (values: FormProps) => {
    const shift = props.shifts.find((shift: Shift) => {
      return shift._id === values.shiftId;
    });

    if (!shift) return;

    dispatch(slotActions.createSlot(getPayload(values, shift)));
  };

  const getPayload = (values: FormProps, shift: Shift) => {
    const createdSlotIds = ShiftHelper.findSlotSameDate(shift, date).map((slot) => slot._id) || [];
    const { shiftId, employees } = { ...values };

    return {
      shiftId: shiftId,
      payload: {
        date: values.date,
        division: employees.map((e) => e[0].divisionId) || '',
        employees: employees.map((v) => {
          return v
            .filter((item) => item.userId !== 'multiple')
            .map((item) => ({
              userId: item.userId,
              duration: parseFloat(item.duration.toString()),
              note: item.note
            }));
        })
      },
      createdSlotIds: createdSlotIds
    };
  };

  const onProceed = () => {
    setSubmittingType('apply');
    formik.submitForm().then(() => {
      const value = changingValueRes();
      if (changingValueKey()) {
        isChangingDate() ? setSelectedDate(value) : setSelectedShiftId(value);
      }
      formik.setFieldValue(changingValueKey(), value);
      setIsBeingProceed(true);
      setIsShowConfirmChangeModal(false);
    });
  };

  const onKeepGoing = () => {
    const _date = (isChangingDate() ? changingValueRes() : date) || '';
    const _shiftId = (isChangingShift() ? changingValueRes() : shiftId) || '';
    const _employees = getInitialEmployees(_date, _shiftId);
    setSelectedDate(_date);
    setSelectedShiftId(_shiftId);
    resetForm(_date, _shiftId, _employees);
    setIsBeingProceed(false);
    setIsShowConfirmChangeModal(false);
  };

  const setSelectedDate = (value: string) => {
    dispatch(schedulerActions.setSelectedDate(value));
  };

  const setSelectedShiftId = (value: string) => {
    dispatch(schedulerActions.setSelectedShiftId(value));
  };

  const resetForm = (date: string, shiftId: string, employees: FormEmployeesProp): void => {
    const values = { date, shiftId, employees };
    formik.resetForm({ values });
  };

  const changingValueKey = () => {
    return Object.keys(changingValue)[0];
  };

  const isChangingShift = () => {
    return changingValueKey() === 'shiftId';
  };

  const isChangingDate = () => {
    return changingValueKey() === 'date';
  };

  const changingValueRes = (): string => {
    return Object.values(changingValue)[0] as string;
  };
  return {
    modalWarningVisible,
    setModalWarningVisible,
    isShowConfirmChangeModal,
    setIsShowConfirmChangeModal,
    formik,
    onDateChange,
    onChangeShift,
    shiftDuration,
    shiftId,
    initialValues,
    getInitialEmployees,
    isChangingDate,
    isChangingShift,
    changingValue,
    onProceed,
    onKeepGoing,
    submittingType,
    setSubmittingType,
    project,
    modalConfirmEmptyVisible,
    setModalConfirmEmptyVisible
  };
};
