import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { Project, User } from 'models';
import { useSearchParams } from 'react-router-dom';
import { userActions } from 'store/users/user.slice';
import { useUserFilter } from './useUserFilter';

export const useProjectFilter = () => {
  const dispatch = useAppDispatch();
  const [selectedProject, setSelectedProject] = useState('');
  const [projectLabel, setProjectLabel] = useState('Project');
  const [isNewlyRendered, setIsNewlyRendered] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setSelectedAll, setSelectedUsers } = useUserFilter();
  const projects = useAppSelector(projectSelector.selectProjects);

  useEffect(() => {
    const project = projects.find((prj) => prj?.name === searchParams.get('project'));
    if (project) {
      setSelectedProject(project._id);
      dispatch(projectActions.setSelectedProject(project));
      setUsersAfterChangingProject(project);
    }
  }, [searchParams]);

  useEffect(() => {
    const project = projects.find((prj) => prj?._id === selectedProject);
    if (project) {
      setProjectLabel(project.name);
      searchParams.set('project', project.name);
      searchParams.set('projectId', project._id);
      setSearchParams(searchParams);
      if (isNewlyRendered) {
        // dispatch(shiftActions.getShifts(project._id));
        setIsNewlyRendered(false);
      } else {
        // dispatch(schedulerActions.setIsApplyingFilter(true));
      }
      setIsNewlyRendered(false);
    }
  }, [selectedProject]);

  const setUsersAfterChangingProject = (project: Project) => {
    const _users: User[] = project.users || [];
    dispatch(userActions.setUsers(_users));
    setSelectedUsers(_users.map((u) => u._id));
    setSelectedAll(true);
  };

  const onSelectProject = (e: any, projectId: string) => {
    e.stopPropagation();
    const project = projects.find((prj: Project) => prj?._id === projectId);
    if (project) {
      searchParams.set('project', project.name);
      searchParams.set('projectId', project._id);
      setSearchParams(searchParams);
    }
  };

  return { projects, selectedProject, setSelectedProject, projectLabel, onSelectProject };
};
