import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const CustomRow = styled(Row)`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #cecece;
  justify-content: between;
  align-items: center;
  margin-bottom: 0.5rem;
`;
