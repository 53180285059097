import { DropdownAutoCloseOutside } from 'components/Shared';
import { Dropdown } from 'react-bootstrap';
import { FilterItem, FilterItemLabel } from './FilterDropdown.styled';
import { usePersonalFilter } from './usePersonalFilter';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useState, useEffect } from 'react';
import { BtnRemovePersonalFilter } from 'components/Shared/ButtonRemove/BtnRemovePersonalFilter.styled';
import { toast } from 'react-toastify';
import { filterSelector, filterActions } from 'store/filters/filter.slice';

interface Props {
  [key: string]: unknown;
}

const PersonalFilter = (props: Props) => {
  const { filters, label, selectedFilter, onSelectFilter } = usePersonalFilter();
  const [activeFilter, setActiveFilter] = useState(filters);

  const onSetFilter = () => {
    if (activeFilter.length === 0) {
      setActiveFilter(filters);
    }
  };

  const onChangeFilter = (itemFilterText: string) => {
    if (itemFilterText === '') {
      setActiveFilter(filters);
    } else {
      const filtersList = filters.filter((f) =>
        f.name.toLowerCase().includes(itemFilterText.toLowerCase())
      );
      setActiveFilter(filtersList);
    }
  };

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(filterSelector.selectIsLoadingFilters);
  const error = useAppSelector(filterSelector.selectError);
  const [deletedFilter, setDeletedFilter] = useState<string[]>([]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.response?.data?.message);
    }
  }, [error]);

  useEffect(() => {
    if (error === null && !isLoading && deletedFilter.length > 0) {
      toast.success('Successfully Deleted.');

      // Reset filter list from BE
      dispatch(filterActions.getPersonalFilters({ kind: 'SHIFTCHANGE' }));

      // Remove the deleted filter in FE for temporarily display
      const filtersList = filters.filter(function (filter, index) {
        return filter._id != deletedFilter[index];
      });
      setActiveFilter(filtersList);
    }
  }, [isLoading]);

  const onDeleteFilter = (event: any, filterId: string) => {
    dispatch(filterActions.deleteFilter({ filterId: filterId }));
    deletedFilter.push(filterId);
    setDeletedFilter(deletedFilter);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <DropdownAutoCloseOutside className="mr-2" {...props}>
      <Dropdown.Toggle size="sm" variant="outline-primary h-100" onMouseEnter={onSetFilter}>
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        <Dropdown.Item as="li">
          <FilterItem>
            <input
              type="text"
              onChange={(e) => onChangeFilter(e.target.value)}
              placeholder="Search Item"
            />
          </FilterItem>
        </Dropdown.Item>

        {activeFilter.length > 0 &&
          activeFilter.map((filter) => (
            <Dropdown.Item key={filter._id} as="li" onClick={(e) => onSelectFilter(filter._id)}>
              <FilterItem>
                <input
                  type="radio"
                  value={filter._id}
                  checked={selectedFilter === filter._id}
                  onChange={(e) => onSelectFilter(filter._id)}
                />
                <FilterItemLabel>{filter.name}</FilterItemLabel>
                <BtnRemovePersonalFilter
                  className="fa fa-trash"
                  onClick={(e) => onDeleteFilter(e, filter._id)}
                />
              </FilterItem>
            </Dropdown.Item>
          ))}
        {activeFilter.length == 0 && <Dropdown.Item as="li">No Data</Dropdown.Item>}
      </Dropdown.Menu>
    </DropdownAutoCloseOutside>
  );
};
export default PersonalFilter;
