import * as Yup from 'yup';

export const userSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  employeeCode: Yup.number().required('Required'),
  username: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  personalEmail: Yup.string().email().required('Required'),
  displayName: Yup.string().required('Required'),
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      'Password must have at least 6 character and contain each of the following: uppercase letters, lowercase letters, special characters and numbers'
    ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/, 'Invalid Phone Number')
});

export const updateUserSchema = Yup.object().shape({
  employeeCode: Yup.number().required('Required'),
  username: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  personalEmail: Yup.string().email().required('Required'),
  displayName: Yup.string().required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/, 'Invalid Phone Number')
});
