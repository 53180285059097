import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';

export interface ErrorResponse {
  statusCode: number;
  message: string;
  error: string;
}

export const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 40000
};

const apiService: AxiosInstance = axios.create(axiosConfig);
const cookies = new Cookies();

apiService.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    if (config.url !== `${process.env.REACT_APP_API_URL}/auth/login`) {
      // const accessToken = localStorage.getItem("twoFactorAccessToken");
      // config.headers = {
      //   Authorization: accessToken ? `Bearer ${accessToken}` : "",
      // };

      const accessToken: string = cookies.get('accessToken');
      config.headers = {
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
      };
    }

    // if (config.url === `${process.env.REACT_APP_API_URL}/auth/2fa/verify`) {
    //   const accessToken = localStorage.getItem("accessToken");
    //   config.headers = {
    //     Authorization: accessToken ? `Bearer ${accessToken}` : "",
    //   };
    // }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiService.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data;
  },
  function (error) {
    if (401 === error.response.status) {
      cookies.remove('accessToken', { path: '/' });
      cookies.remove('twoFactorAccessToken', { path: '/' });
      cookies.remove('currentUser', { path: '/' });
      if (!error.config.url.includes('/auth/login')) {
        window.location.href = '/login';
      }
      // handle error: inform user, go to login, etc
    }

    const axiosErr = error as AxiosError;
    const errResponse = axiosErr.response?.data as ErrorResponse;

    if (!errResponse.message) {
      errResponse.message = 'An error has occurred. Please try again later.';
    }

    return Promise.reject(errResponse);
  }
);

export default apiService;
