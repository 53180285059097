import { User } from './user.model';

export enum CommentStatus {
  NONE = 'NONE',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED'
}

export type Comment = {
  _id: string;
  content: string;
  status: CommentStatus;
  shift: string; //shiftId
  slot: string; // slotId
  project: string;
  userComment: User[];
  updatedAt: string;
};

export type CreateCommentPayload = {
  shiftId: string;
  slotId: string;
  content: string;
  status: CommentStatus;
  userTags: string[];
  userComment: string;
  userSlot: string;
};

export type UpdateCommentPayload = {
  shiftId: string;
  slotId: string;
  commentId: string;
  status: string;
  content: string;
};
