import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetReportsPayload } from 'models/report.model';
import { initialState } from './salaryPeriod.initialState';
import { SalaryPeriod } from 'models';

const salaryPeriodSlice = createSlice({
  name: 'salaryPeriods',
  initialState,
  reducers: {
    getCurrentPeriod: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    getCurrentPeriodSuccess: (state, { payload }: PayloadAction<SalaryPeriod>) => {
      state.isFetching = false;
      state.currentPeriod = payload;
      state.error = null;
    },
    getCurrentPeriodFailed: (state, { payload }: PayloadAction<any>) => {
      state.isFetching = false;
      state.currentPeriod = undefined;
      state.error = payload;
    }
  }
});

export const salaryPeriodActions = salaryPeriodSlice.actions;

export * as salaryPeriodSelector from './salaryPeriod.selector';

export default salaryPeriodSlice.reducer;
