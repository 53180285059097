import ButtonApplyDashboard from 'components/Report/hooks/ButtonApplyDashboard';
import DateRangeCalendar from 'components/Report/hooks/DateRangeCalendar';
import PersonalFilter from 'components/Report/hooks/PersonalFilter';
import ProjectFilter from 'components/Report/hooks/ProjectFitler';
import ReportTimesheetSaveFilter from 'components/Report/hooks/ReportTimesheetSaveFilter';
import UserFilter from 'components/Report/hooks/UserFilter';

const ReportDashboardFilter = () => {
  return (
    <div className="px-4 py-3 d-flex justify-content-between">
      <div>
        <PersonalFilter />
        <ProjectFilter />
        <UserFilter />
        <DateRangeCalendar />
        <ButtonApplyDashboard />
        <ReportTimesheetSaveFilter />
      </div>
    </div>
  );
};

export default ReportDashboardFilter;
