import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { attendanceActions } from 'store/attendances/attendance.slice';

interface Props {
  setIsCheckingIn: (status: boolean) => void;
  selectedShift: string;
  selectedProject: string;
  selectedDivision: string;
  note: string;
}

const actions = [
  { key: 'ARRIVED_LATE', value: 'I arrived late.' },
  { key: 'FORGOT', value: 'I forgot to check in.' },
  { key: 'COVERED_BY_ANOTHER', value: 'Another agent is covering for me.' }
];
const AttendanceCheckingLate = (props: Props) => {
  const { setIsCheckingIn, selectedShift, selectedProject, selectedDivision, note } = { ...props };
  const dispatch = useAppDispatch();
  const [selectedAction, setSelectedAction] = useState('');

  const onCancel = () => {
    setIsCheckingIn(false);
    dispatch(attendanceActions.resetToInit());
  };

  const onCheckin = () => {
    dispatch(
      attendanceActions.checkin({
        project: selectedProject,
        shift: selectedShift,
        reason: selectedAction,
        division: selectedDivision,
        note: note
      })
    );
  };

  return (
    <>
      <h3>You are checking in late. Has anyone covered your shift?</h3>
      <div className="align-self-start pl-5 ml-5 mt-3">
        {actions.map((action) => (
          <div className="custom-control custom-checkbox mb-3" key={action.key}>
            <input
              className="custom-control-input"
              type="checkbox"
              value={action.key}
              id={action.key}
              checked={selectedAction === action.key}
              onChange={() => {
                setSelectedAction(action.key);
              }}
            />
            <label className="custom-control-label" htmlFor={action.key}>
              {action.value}
            </label>
          </div>
        ))}
        <div className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onCheckin}>
            Check-in
          </Button>
        </div>
      </div>
    </>
  );
};

export default AttendanceCheckingLate;
