import { Button } from 'react-bootstrap';
import { shiftActions } from 'store/shifts/shift.slice';
import { schedulerActions } from 'store/schedulers/scheduler.slice';
import { useAppDispatch } from 'store/hooks';

interface Props {
  selectedProject: string;
}

const ButtonApplyFilter = (props: Props) => {
  const dispatch = useAppDispatch();

  const onApplyFilter = () => {
    dispatch(shiftActions.getShifts(props.selectedProject));
    dispatch(schedulerActions.setIsApplyingFilter(false));
    dispatch(shiftActions.getOffVacationSlotShifts(props.selectedProject));
  };

  return (
    <Button
      size="sm"
      variant="primary"
      onClick={onApplyFilter}
      // disabled={props.isFetchingShifts}
    >
      Apply
    </Button>
  );
};

export default ButtonApplyFilter;
