import ApiService from '../utils/network';
import { API_URL } from './url';

export const fixedSlotApi = {
  createSlot(payload: any) {
    return ApiService.post(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/${API_URL.FIXED_SLOTS}`,
      payload.payload
    );
  },
  updateSlot(payload: any) {
    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/${API_URL.FIXED_SLOTS}/${payload.slotId}`,
      { userSlots: payload.userSlots }
    );
  },
  deleteSlot(payload: any) {
    return ApiService.delete(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${payload.shiftId}/${API_URL.FIXED_SLOTS}/${payload.slotId}`
    );
  },
  exportSlots(payload: {
    dateStart: string;
    dateEnd: string;
    projectId: string;
    fixedSlotsIndex: number;
  }) {
    return ApiService.post(`${API_URL.BASE_URL}/${API_URL.FIXED_SLOTS}/export`, payload);
  },
  bulkUpdate(payload: any) {
    const { slotIds, shiftIds, userSlots, division }: any = { ...payload };

    return ApiService.put(
      `${API_URL.BASE_URL}/${API_URL.SHIFT}/${shiftIds[0]}/${API_URL.FIXED_SLOTS}/bulk_update`,
      {
        slotIds,
        shiftIds,
        userSlots,
        division
      }
    );
  }
};
