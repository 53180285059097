import { Popover } from 'react-bootstrap';
import styled from 'styled-components';

export const AgentCommentItemWrapper = styled.div`
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
`;

export const AgentCommentContent = styled.div`
  padding: 0;
`;

export const AgentCommentName = styled.h5`
  margin-bottom: 0.25rem;
`;

export const ToggleCustom = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
  border-radius: 50%;
  text-align: center;
  &:hover {
    background: var(--primary);
    cursor: pointer;
    i {
      color: #fff !important;
    }
  }
`;

export const CommentAction = styled.span`
  font-weight: 600;
  font-size: 0.725rem;
  &:hover {
    cursor: pointer;
  }
`;
