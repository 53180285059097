import { Comment, Filter, SalaryPeriod } from 'models';
import { Nullable } from '../../utils/common/types';
import { Report, ReportDashboardData } from 'models/report.model';
export type SalaryPeriodInitialState = {
  isFetching: boolean;
  currentPeriod: SalaryPeriod | undefined;
  error: any;
};

export const initialState: SalaryPeriodInitialState = {
  isFetching: false,
  currentPeriod: undefined,
  error: null
};
