import ProjectForm from 'components/Project/Actions/ProjectForm/ProjectForm';
import { SpinnerButton } from 'components/Shared';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';
import { FormikProps, useFormik } from 'formik';
import { ButtonTypes, User } from 'models';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { divisionActions } from 'store/divisions/division.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { projectSchema } from 'utils/validators';

interface FormProps {
  name: string;
  users: User[];
  managers: User[];
  allottedHoursPerWeek: number;
  numberOfShifts: number;
  weekDefinition: number;
}

const UpdateProject = () => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submittingType, setSubmittingType] = useState('');
  const _isVisible = useAppSelector(projectSelector.selectIsModalUpdateProjectVisible);
  const isCreating = useAppSelector(projectSelector.selectIsCreating);
  const project = useAppSelector(projectSelector.selectSelectedProject);
  const error = useAppSelector(projectSelector.selectError);

  useEffect(() => {
    setIsModalVisible(_isVisible);
    if (project) {
      formik.setFieldValue('name', project.name);
      formik.setFieldValue('users', project.users);
      formik.setFieldValue('managers', project.managers);
      formik.setFieldValue('allottedHoursPerWeek', project.allottedHoursPerWeek);
      formik.setFieldValue('numberOfShifts', project.numberOfShifts);
      formik.setFieldValue('weekDefinition', project.weekDefinition);
      dispatch(divisionActions.getDivisions({ projectId: project._id }));
    }
  }, [_isVisible]);

  useEffect(() => {
    if (formik.isSubmitting && !isCreating) {
      formik.setSubmitting(false);
      toast.success('Updated Successfully.');
      if (submittingType === 'submit') {
        dispatch(projectActions.setIsModalUpdateProjectVisible(false));
      }
    }
  }, [isCreating]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error?.message || 'Something went wrong.');
      formik.setSubmitting(false);
    }
  }, [error]);

  const initialValues: FormProps = {
    name: project?.name || '',
    users: project?.users || [],
    managers: project?.managers || [],
    allottedHoursPerWeek: project?.allottedHoursPerWeek || 0,
    numberOfShifts: project?.numberOfShifts || 1,
    weekDefinition: project?.weekDefinition || 1
  };

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: initialValues,
    validationSchema: projectSchema,
    onSubmit: (values: FormProps, actions) => {
      handleSubmit(values, actions);
    }
  });

  const handleSubmit = (values: FormProps, actions: any) => {
    // if (project) {
    dispatch(projectActions.updateProject({ ...values, id: project!._id }));
    // }update
  };

  return (
    <Modal
      className="modal-confirm handle"
      show={isModalVisible}
      onHide={() => {
        dispatch(projectActions.setIsModalUpdateProjectVisible(false));
      }}
      size="lg"
      centered
      dialogAs={DraggableModalDialog}
      backdrop={'static'}
    >
      <Modal.Header closeButton>
        <Modal.Title
          onMouseDown={(e: any) => {
            e.stopPropagation();
          }}
        >
          Project Setting
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <ProjectForm formik={formik} project={project || undefined}></ProjectForm>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => dispatch(projectActions.setIsModalUpdateProjectVisible(false))}
          onMouseDown={(e: any) => {
            e.stopPropagation();
          }}
        >
          Cancel
        </Button>
        <SpinnerButton
          btnType={ButtonTypes.Submit}
          colorType="success"
          disabled={formik.isSubmitting && submittingType === 'apply'}
          onClick={() => {
            setSubmittingType('apply');
            formik.submitForm();
          }}
          onMouseDown={(e: any) => {
            e.stopPropagation();
          }}
        >
          Apply
        </SpinnerButton>
        <SpinnerButton
          btnType={ButtonTypes.Submit}
          disabled={formik.isSubmitting && submittingType === 'submit'}
          onClick={() => {
            setSubmittingType('submit');
            formik.submitForm();
          }}
          onMouseDown={(e: any) => {
            e.stopPropagation();
          }}
        >
          Save
        </SpinnerButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProject;
