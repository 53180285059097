import { Nullable } from '../../utils/common/types';
import { OffVacation, PaginationInfo, Count, OffVacationRequestLog } from 'models';

export type OffVacationInitialState = {
  offVacations: OffVacation[];
  error: Nullable<string>;
  isFetching: boolean;
  isFetchingOnHoldData: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  offVacationsPaginationInfo: PaginationInfo;
  pending: Count | null;
  pendingProjectCount: any;
  approvedRequestLog: OffVacationRequestLog[];
  rejectedRequestLog: OffVacationRequestLog[];
  approvedShiftChangePaginationInfo: PaginationInfo;
  rejectShiftChangePaginationInfo: PaginationInfo;
};

export const initialState: OffVacationInitialState = {
  offVacations: [],
  error: null,
  isFetching: false,
  isFetchingOnHoldData: false,
  isCreating: false,
  isUpdating: false,
  offVacationsPaginationInfo: {
    page: 1,
    perPage: 1,
    totalCount: 50
  },
  pending: null,
  pendingProjectCount: [],
  approvedRequestLog: [],
  rejectedRequestLog: [],
  approvedShiftChangePaginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  },
  rejectShiftChangePaginationInfo: {
    page: 1,
    perPage: 10,
    totalCount: 10
  }
};
