import styled from 'styled-components';

export const ShiftInformationWrapper = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.5rem;
`;

export const ShiftInformationDetail = styled.p`
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 600;
`;
