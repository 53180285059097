import { User, Project } from 'models';
import { useEffect, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import { projectSelector } from 'store/projects/project.slice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { TimePicker } from 'components/Shared';
import { attendanceActions } from 'store/attendances/attendance.slice';
const actions = [
  { key: 'FORGOT', value: 'I forgot to check out.' },
  { key: 'NEXT_AGENT_LATE', value: 'Next shift"s agent was late' }
];

const AttendanceCheckingOutLate = () => {
  const dispatch = useAppDispatch();
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const projects = useAppSelector(projectSelector.selectProjects);
  const isLoadingProject: boolean = useAppSelector(projectSelector.selectIsFetching);

  useEffect(() => {
    if (!isLoadingProject) {
      if (projects.length > 0) {
        setSelectedProject(projects[0]._id);
        setUsers(projects[0].users);
        setSelectedUser(projects[0].users[0]?._id || '');
      }
    }
  }, [isLoadingProject]);

  const onChangeProject = (projectId: string) => {
    setSelectedProject(projectId);
    setUsers(projects.find((project) => project._id === projectId)?.users || []);
  };

  const onCancel = () => {
    dispatch(attendanceActions.resetToInit());
  };

  const onCheckout = () => {
    dispatch(
      attendanceActions.checkout({
        lateCheckOutReason: selectedAction,
        project: selectedProject,
        agent: selectedUser,
        coveringStartTime: startTime,
        coveringEndTime: endTime
      })
    );
  };

  return (
    <>
      <h3>You are checking out late. Are you covering for anyone?</h3>
      <div className="align-self-start pl-5 ml-5 mt-3">
        {actions.map((action) => (
          <div className="custom-control custom-checkbox mb-3" key={action.key}>
            <input
              className="custom-control-input"
              type="checkbox"
              value={action.key}
              id={action.key}
              checked={selectedAction === action.key}
              onChange={() => {
                setSelectedAction(action.key);
              }}
            />
            <label className="custom-control-label" htmlFor={action.key}>
              {action.value}
            </label>
          </div>
        ))}
        {selectedAction === 'NEXT_AGENT_LATE' && (
          <div>
            <Form>
              <Form.Row className="justify-content-between">
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Project</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Project"
                    onChange={(e) => onChangeProject(e.target.value)}
                    name="projectId"
                    value={selectedProject}
                    custom
                  >
                    {projects.map((project: Project) => (
                      <option key={project._id} value={project._id}>
                        {project.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>Late agent's name</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Select Shift"
                    onChange={(e) => setSelectedUser(e.target.value)}
                    name="type"
                    value={selectedUser}
                    custom
                  >
                    {users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.username}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-between">
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>From</Form.Label>
                  <TimePicker fieldName={'from'} onChangeTime={setStartTime}>
                    <div
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <Form.Control
                        type="text"
                        onKeyDown={(e: any) => e.preventDefault()}
                        name="endTime"
                        defaultValue={startTime}
                        // isInvalid={formik.touched.endTime && formik.errors.endTime}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {formik.errors.endTime}
                      </Form.Control.Feedback> */}
                    </div>
                  </TimePicker>
                </Form.Group>
                <Form.Group as={Col} xs={12} md="5">
                  <Form.Label>To</Form.Label>
                  <TimePicker fieldName={'to'} onChangeTime={setEndTime}>
                    <div
                      onMouseDown={(e: any) => {
                        e.stopPropagation();
                      }}
                    >
                      <Form.Control
                        type="text"
                        onKeyDown={(e: any) => e.preventDefault()}
                        name="endTime"
                        defaultValue={endTime}
                        // isInvalid={formik.touched.endTime && formik.errors.endTime}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {formik.errors.endTime}
                      </Form.Control.Feedback> */}
                    </div>
                  </TimePicker>
                </Form.Group>
              </Form.Row>
            </Form>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onCheckout}>
            Check me out
          </Button>
        </div>
      </div>
    </>
  );
};

export default AttendanceCheckingOutLate;
