import { PayloadAction } from '@reduxjs/toolkit';
import { Filter } from 'models';
// import { Shift, Slot } from "models";
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { filterApi } from 'services';
import { schedulerActions, schedulerSelector } from './scheduler.slice';
export function* setIsModalCreateSlotVisibleWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(schedulerActions.setIsModalCreateSlotVisibleSuccess(payload));
  } catch (e) {
    console.log('setIsModalCreateSlotVisibleWorker Error: ', e);
  }
}

export function* setSelectedShiftIdWorker({ payload }: PayloadAction<string>) {
  try {
    yield put(schedulerActions.setSelectedShiftIdSuccess(payload));
  } catch (e) {
    console.log('setSelectedShiftIdWorker Error: ', e);
  }
}

export function* setSelectedDateWorker({ payload }: PayloadAction<string>) {
  try {
    yield put(schedulerActions.setSelectedDateSuccess(payload));
  } catch (e) {
    console.log('setSelectedDate Error: ', e);
  }
}

export function* setSelectedDateRangeWorker({ payload }: PayloadAction<any>) {
  try {
    yield put(schedulerActions.setSelectedDateRangeSuccess(payload));
  } catch (e) {
    console.log('setSelectedDate Error: ', e);
  }
}

export function* setViewModeWorker({ payload }: PayloadAction<string>) {
  try {
    yield put(schedulerActions.setViewModeSuccess(payload));
  } catch (e) {
    console.log('setViewMode Error: ', e);
  }
}

export function* setShowingCompactOverviewWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(schedulerActions.setShowingCompactOverviewSuccess(payload));
  } catch (e) {
    console.log('setShowingCompactOverviewWorker Error: ', e);
  }
}

export function* setIsApplyingFilterWorker({ payload }: PayloadAction<boolean>) {
  try {
    yield put(schedulerActions.setIsApplyingFilterSuccess(payload));
  } catch (e) {
    console.log('setIsApplyingFilterSuccess Error: ', e);
  }
}

export function* getPersonalFiltersWorker() {
  try {
    const response: Filter[] = yield call(filterApi.getFilters);
    const filters = response.sort((a: Filter, b: Filter) => {
      return a.name > b.name ? 1 : -1;
    });
    yield put(schedulerActions.getPersonalFiltersSuccess(filters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    // yield put(projectActions.getProjectsFailed(e));
  }
}
export function* createPersonalFiltersWorker({ payload }: PayloadAction<any>) {
  try {
    const response: Filter = yield call(filterApi.createFilter, payload);
    const filters: Filter[] = yield select(schedulerSelector.selectPersonalFilters);
    const newFilters = [...filters];
    newFilters.push(response);
    yield put(schedulerActions.createPersonalFilterSuccess(newFilters));
  } catch (e) {
    console.log('Fetching Data Error: ', e);
    yield put(schedulerActions.createPersonalFilterFailed(e));
  }
}

export default function* schedulerSaga() {
  yield takeLatest(schedulerActions.setIsModalCreateSlotVisible, setIsModalCreateSlotVisibleWorker);
  yield takeLatest(schedulerActions.setSelectedShiftId, setSelectedShiftIdWorker);
  yield takeLatest(schedulerActions.setSelectedDate, setSelectedDateWorker);
  yield takeLatest(schedulerActions.setSelectedDateRange, setSelectedDateRangeWorker);
  yield takeLatest(schedulerActions.setIsApplyingFilter, setIsApplyingFilterWorker);
  yield takeLatest(schedulerActions.setShowingCompactOverview, setShowingCompactOverviewWorker);
  yield takeLatest(schedulerActions.getPersonalFilters, getPersonalFiltersWorker);
  yield takeLatest(schedulerActions.createPersonalFilter, createPersonalFiltersWorker);
  yield takeLatest(schedulerActions.setViewMode, setViewModeWorker);
}
