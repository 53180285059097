import { PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import {
  Comment,
  CreateNotePayload,
  Note,
  Project,
  Shift,
  UpdateCommentPayload,
  UpdateNotePayload
} from 'models';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { shiftApi } from 'services';
import { commentApi } from 'services/comment.api';
import { noteApi } from 'services/note.api';
import { projectSelector } from 'store/projects/project.slice';
import { schedulerSelector } from 'store/schedulers/scheduler.slice';
import { shiftActions, shiftSelector } from 'store/shifts/shift.slice';
import { formatTo_yyyyMMdd } from 'utils/formatters/datetime.formatter';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';
import { noteActions } from './note.slice';
import { selectNotes } from './note.selector';
import { updateObjectInArray } from 'utils/formatters/array';

export function* getNotesWorker({ payload }: PayloadAction<{ projectId: string }>) {
  try {
    const response: Note[] = yield call(noteApi.getNotes, payload.projectId);
    yield put(noteActions.getNotesSuccess(response));
  } catch (e) {
    console.log('getNotesWorker Error: ', e);
    yield put(noteActions.getNotesFailed(e));
  }
}

export function* createNoteWorker(payload: PayloadAction<CreateNotePayload>) {
  try {
    const response: { projectId: string; shiftId: string } = yield call(
      noteApi.createNote,
      payload.payload
    );
    const dateRange: Date[] = yield select(schedulerSelector.selectSelectedDateRange);
    const shifts: Shift[] = yield call(
      shiftApi.getShifts,
      response.projectId,
      formatTo_yyyyMMdd(dateRange[0]),
      formatTo_yyyyMMdd(dateRange[dateRange.length - 1])
    );
    yield put(shiftActions.setShifts(sortShiftsByName(shifts) as Shift[]));
    yield put(noteActions.createNoteSuccess({} as any));
  } catch (e) {
    console.log('createDivisionsWorker Error: ', e);
    yield put(noteActions.createNoteFailed(e));
  }
}

export function* updateNoteWorker({ payload }: PayloadAction<UpdateNotePayload>) {
  try {
    const response: Note = yield call(noteApi.updateNote, payload);
    const notes: Note[] = yield select(selectNotes);
    const newNotes = updateObjectInArray(notes, response, '_id');
    yield put(noteActions.updateNoteSuccess(newNotes));
  } catch (e) {
    console.log('updateNoteWorker Error: ', e);
    yield put(noteActions.updateNoteFailed(e));
  }
}

export function* deleteNoteWorker(payload: PayloadAction<Comment>) {
  try {
    const comment = payload.payload;
    const response: Comment = yield call(commentApi.deleteComment, comment);
    const shifts: Shift[] = yield select(shiftSelector.selectShifts);
    const _shifts = cloneDeep(shifts);
    const shift = _shifts.find((s) => s._id === comment.shift);
    let slot;
    let comments;
    if (shift) {
      slot = shift.slots.find((sl) => sl._id === comment.slot);
      if (slot) {
        comments = cloneDeep(slot).comments.filter((c) => c._id !== comment._id);
        slot.comments = comments;
      }
    }
    yield put(shiftActions.setShiftsSuccess(_shifts));
    yield put(noteActions.deleteNoteSuccess({} as any));
    // yield put(divisionActions.createDivisionSuccess(response));
  } catch (e) {
    console.log('deleteDivisionsWorker Error: ', e);
    yield put(noteActions.createNoteFailed(e));
  }
}

export default function* noteSaga() {
  yield takeLatest(noteActions.getNotes, getNotesWorker);
  yield takeLatest(noteActions.createNote, createNoteWorker);
  yield takeLatest(noteActions.updateNote, updateNoteWorker);
  // yield takeLatest(noteActions.deleteNote, deleteNoteWorker);
}
