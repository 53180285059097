import styled from 'styled-components';
import { Badge, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UserCellProps {
  width: number;
}

export const ProjectName = styled(Badge)`
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonRemove = styled(FontAwesomeIcon)`
  margin-left: 0.35rem;
  background: var(--primary);
  color: white;
  width: 5px;
  height: 5px;
  padding: 2px;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
`;

export const StatusBadge = styled(Dropdown.Toggle)`
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonAction = styled.span`
  font-weight: 600;
  font-size: 0.775rem;
  &:hover {
    cursor: pointer;
  }
`;

export const UserRow = styled.tr``;

export const UserCell = styled.td<UserCellProps>`
  width: ${(props) => props.width};
`;

export const UserProjectDivisionTable = styled.table``;
