import { Slot, User, UserSlot } from 'models';
import React, { useRef, useState } from 'react';
import { Item, Menu, Separator, contextMenu, useContextMenu } from 'react-contexify';
import '../../Scheduler.scss';
import { EventItem, EventName } from './Event.styled';

import Comment from 'components/Scheduler/Actions/Comment/Comment';
import { format } from 'date-fns';
import { useAuth } from 'hooks';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectSelector } from 'store/projects/project.slice';
import { schedulerActions } from 'store/schedulers/scheduler.slice';
import { slotActions } from 'store/slots/slot.slice';

interface Props {
  data: UserSlot;
  slotData: Slot;
  userData: User;
  slotId: string;
  shiftId: string;
  date: any;
  onSetDraggingItem: () => void;
  onSetDroppedItem: () => void;
  index: number;
  slotIndex: number;
  shiftDuration: number;
  showDetail: boolean;
}

// ============================ //

const Event = (props: any) => {
  const currentUser = useAuth();
  const RANDOM_TEXT = (Math.random() + 1).toString(36).substring(7);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useAppDispatch();
  const { show } = useContextMenu();
  function displayMenu(e: any, id: string) {
    show({
      event: e,
      id: id
    });
  }
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const classes = isDraggingOver ? 'drag-entered' : '';
  const project = useAppSelector(projectSelector.selectSelectedProject);
  const dragAndDropPayload = () => {
    const { data, slotData, slotId, shiftId, index }: any = { ...props };
    return {
      type: 'event',
      userId: data.user._id,
      user: data.user,
      duration: data.duration,
      slotId: slotId,
      shiftId: shiftId,
      index: index,
      division: slotData?.division?._id || slotData?.division
    };
  };
  const onDrop = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(false);
    contextMenu.show({
      id: 'DRAG_AND_DROP',
      event: e
    });
    props.onSetDroppedItem(dragAndDropPayload);
  };

  const onDragStart = (e: any) => {
    e.stopPropagation();
    props.onSetDraggingItem(dragAndDropPayload);
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };
  const onDragEnter = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(true);
  };
  const onDragLeave = (e: any) => {
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const getColor = () => {
    return props?.userData?.color ? props.userData.color : 'transparent';
  };

  const getTextDecoration = () => {
    return project !== null &&
      project?.users?.find((u) => u._id === props.data.user._id) !== undefined
      ? 'unset'
      : 'line-through';
  };

  const getTextColor = () => {
    return project !== null &&
      project?.users?.find((u) => u._id === props.data.user._id) !== undefined
      ? '#32539c'
      : '#000';
  };

  const getContextMenuId = (): string => {
    return (
      RANDOM_TEXT +
      props.date +
      props.shiftId +
      props.index +
      props.data.userId +
      props.data.duration
    );
  };

  const handleRemoveUserSlot = ({ event, props, triggerEvent, data }: any) => {
    dispatch(slotActions.deleteUserSlot(data));
  };

  const handleRemoveSlot = ({ event, props, triggerEvent, data }: any) => {
    dispatch(slotActions.deleteSlot(data));
  };

  const onAssign = () => {
    dispatch(schedulerActions.setIsModalCreateSlotVisible(true));
    dispatch(schedulerActions.setSelectedShiftId(props.shiftId));
    dispatch(schedulerActions.setSelectedDate(format(new Date(props.date), 'yyyy-MM-dd')));
  };

  const trackVisibility = (isVisible: boolean) => {
    setIsDraggingOver(false);
  };

  const onComment = () => {
    setIsShow(true);
  };

  return currentUser?.role === 'MANAGER' ? (
    <OverlayTrigger
      rootClose={true}
      placement="auto"
      show={isShow}
      onToggle={() => {
        setIsShow(!isShow);
      }}
      trigger="click"
      overlay={
        <Popover id="popover-basic" onClick={(e) => e.stopPropagation()}>
          <Popover.Content>
            <Comment
              userId={props.data.user._id}
              slotId={props.slotId}
              shiftId={props.shiftId}
              setVisible={(value: boolean) => {
                setIsShow(false);
              }}
            ></Comment>
          </Popover.Content>
        </Popover>
      }
    >
      <EventItem
        draggable
        onDrop={onDrop}
        onDragStart={onDragStart}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        className={classes}
        onContextMenu={(e: any) => {
          e.stopPropagation();
          displayMenu(e, `${getContextMenuId()}`);
        }}
        style={{
          backgroundColor: getColor(),
          color: getTextColor()
        }}
      >
        <>
          <EventName
            style={{
              pointerEvents: 'none',
              textDecoration: getTextDecoration(),
              textDecorationThickness: '2px'
            }}
          >
            <span>
              {props.data.user.displayName}
              {props.data.duration !== props.shiftDuration && ` - ${props.data.duration}`}
            </span>
          </EventName>

          {props.data.note && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id={`tooltip-auto`}>{props.data?.note}</Tooltip>}
            >
              <i className="fa-solid fa-note-sticky ml-1 text-yellow"></i>
            </OverlayTrigger>
          )}
        </>

        <div>
          <Menu id={getContextMenuId()} theme="light" onVisibilityChange={trackVisibility}>
            <Item
              onClick={handleRemoveUserSlot}
              data={{
                slotId: props.slotId,
                shiftId: props.shiftId,
                index: props.index
              }}
            >
              Remove {props.data.user.displayName}
            </Item>
            <Item
              onClick={handleRemoveSlot}
              data={{
                slotId: props.slotId,
                shiftId: props.shiftId,
                index: props.slotIndex
              }}
            >
              Remove this Agent Count
            </Item>
            <Separator />
            <Item onClick={onAssign}>Assign</Item>
            <Separator />
            <Item onClick={onComment}>Comment</Item>
          </Menu>
        </div>
      </EventItem>
    </OverlayTrigger>
  ) : (
    <OverlayTrigger
      rootClose={true}
      placement="auto"
      show={isShow}
      onToggle={() => {
        setIsShow(!isShow);
      }}
      trigger="click"
      overlay={
        <Popover id="popover-basic" onClick={(e) => e.stopPropagation()}>
          <Popover.Content>
            <Comment
              userId={props.data.user._id}
              slotId={props.slotId}
              shiftId={props.shiftId}
              setVisible={(value: boolean) => {
                setIsShow(false);
              }}
            ></Comment>
          </Popover.Content>
        </Popover>
      }
    >
      <EventItem
        className={classes}
        style={{
          backgroundColor: getColor()
        }}
        onContextMenu={(e: any) => {
          e.stopPropagation();
          displayMenu(e, `${getContextMenuId()}`);
        }}
      >
        <EventName
          style={{
            pointerEvents: 'none',
            color: getTextColor(),
            textDecorationThickness: '2px',
            textDecoration: getTextDecoration()
          }}
        >
          <span>
            {props.data.user.displayName}{' '}
            {props.data.duration !== props.shiftDuration && `- ${props.data.duration}`}
          </span>
        </EventName>
        <div>
          <Menu id={getContextMenuId()} theme="light" onVisibilityChange={trackVisibility}>
            <Item onClick={onComment}>Comment</Item>
          </Menu>
        </div>
      </EventItem>
    </OverlayTrigger>
  );
};

export default React.memo(Event);
