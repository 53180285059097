import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BtnRemove } from './RemoveProject.styled';
import { FcModalConfirm, SpinnerButton } from 'components/Shared';
import { useState } from 'react';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { Button } from 'react-bootstrap';
import { Project } from 'models';
import { toast } from 'react-toastify';
import useDidMountEffect from 'hooks/useDidMountEffect';

interface Props {
  project: Project;
}

const RemoveProject = (props: Props) => {
  const { project } = { ...props };
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const dispatch = useAppDispatch();

  const isDeleting = useAppSelector(projectSelector.selectIsDeleting);
  const selectedProject = useAppSelector(projectSelector.selectSelectedProject);
  const error = useAppSelector(projectSelector.selectError);

  useDidMountEffect(() => {
    if (!isDeleting && !error && project._id === selectedProject?._id) {
      toast.success('Successfully Deleting.');
      setIsModalConfirmVisible(false);
    }
  }, [isDeleting]);

  useDidMountEffect(() => {
    if (!isDeleting && error && project._id === selectedProject?._id) {
      toast.error(error?.message || 'Something went wrong.');
    }
  }, [error]);

  const onOk = () => {
    dispatch(projectActions.setSelectedProject(project));
    dispatch(projectActions.deleteProject(project._id));
  };

  return (
    <>
      <BtnRemove className="fa fa-trash" onClick={() => setIsModalConfirmVisible(true)} />
      <FcModalConfirm
        show={isModalConfirmVisible}
        onHide={() => setIsModalConfirmVisible(false)}
        title="Confirmation"
        body={
          <>
            <p>
              Are you sure you want to <strong>Delete</strong> project{' '}
              <strong>{project.name}</strong>?
            </p>
          </>
        }
        footer={
          <>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setIsModalConfirmVisible(false);
              }}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <SpinnerButton
              btnType="submit"
              colorType="primary"
              onClick={onOk}
              disabled={isDeleting}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              OK
            </SpinnerButton>
          </>
        }
      />
    </>
  );
};
export default RemoveProject;
