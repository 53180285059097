import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useUserFilter } from './useUserFilter';
import { useProjectFilter } from './useProjectFilter';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { toast } from 'react-toastify';
import { reportActions, reportSelector } from 'store/reports/report.slice';
import { Button, Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { filterSchema } from 'utils/validators';
import { SpinnerButton } from 'components/Shared';
import { ButtonTypes } from 'models';

interface FormProps {
  name: string;
}

const ReportTimesheetSaveFilter = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useProjectFilter();
  const { selectedUsers } = useUserFilter();
  const [isVisible, setIsVisible] = useState(false);

  const isCreatingFilter = useAppSelector(reportSelector.selectIsCreatingFilter);
  const error = useAppSelector(reportSelector.selectError);

  useDidMountEffect(() => {
    if (!isCreatingFilter && !error) {
      toast.success('Successfully Saved Filter.');
      formik.setSubmitting(false);
      formik.resetForm();
      setIsVisible(false);
    }
  }, [isCreatingFilter]);

  useDidMountEffect(() => {
    if (isCreatingFilter && !error) {
      toast.error(error?.message || 'Something went wrong.');
    }
  }, [error]);

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: {
      name: ''
    },
    validationSchema: filterSchema,
    onSubmit: (values, actions) => {
      handleSubmit(values, actions);
    }
  });

  const handleSubmit = (values: FormProps, actions: FormikHelpers<FormProps>) => {
    dispatch(
      reportActions.createPersonalFilter({
        ...values,
        ...{
          project: selectedProject,
          employees: selectedUsers,
          kind: 'REPORT'
        }
      })
    );
  };

  return (
    <>
      <Button size="sm" onClick={() => setIsVisible(true)}>
        Save
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
        }}
        size="sm"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className="text-start">Filter Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Personal Filter 1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={formik.isSubmitting}
            colorType="primary"
            onClick={formik.submitForm}
          >
            Save
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ReportTimesheetSaveFilter;
