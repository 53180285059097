import { ShiftInformationWrapper, ShiftInformationDetail } from './ShiftInformation.styled';
import { Col } from 'react-bootstrap';
import { Shift } from 'models';
import { format } from 'date-fns';

interface Props {
  shifts: Shift[];
  formik: any;
  shiftDuration: number;
}

const ShiftInformation = (props: Props) => {
  const { shifts, formik, shiftDuration } = props;
  const shiftId = formik.values.shiftId;
  const date = formik.values.date;
  const shift = shifts.find((shift) => shift._id === shiftId);
  const slots = shift?.slots.filter((slot) => format(new Date(slot.date), 'yyyy-MM-dd') === date);

  const getTotalAgent = () => {
    return `Total agents: ${slots?.length || 0}`;
  };

  const getTotalHours = () => {
    return `Total hours: ${(slots?.length || 0) * shiftDuration}`;
  };

  return (
    <>
      <Col className="align-self-end mb-4" lg={{ span: 2, offset: 1 }} xs={6}>
        <ShiftInformationWrapper>
          <ShiftInformationDetail>{getTotalAgent()}</ShiftInformationDetail>
          <ShiftInformationDetail>{getTotalHours()}</ShiftInformationDetail>
        </ShiftInformationWrapper>
      </Col>
      <Col className="align-self-end mb-4" lg={3} xs={6}>
        <ShiftInformationWrapper>
          <ShiftInformationDetail>Total alloted: {shift?.numOfAgents}</ShiftInformationDetail>
          <ShiftInformationDetail>
            Total alloted hours: {(shift?.numOfAgents || 0) * shiftDuration}
          </ShiftInformationDetail>
        </ShiftInformationWrapper>
      </Col>
    </>
  );
};

export default ShiftInformation;
