import { Button, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { staticRanges } from './staticRanges';
import { useDateRangeCalendar } from './useDateRangeCalendar';

const DateRangeCalendar = () => {
  const { onToggleDateRangePicker, ranges, selectedDateRange, isVisible, onSelect, timeString } =
    useDateRangeCalendar();

  return (
    <>
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="bottom-end"
        onToggle={onToggleDateRangePicker}
        show={isVisible}
        overlay={
          <Popover id="popover-basic" style={{ maxWidth: '600px' }}>
            <Popover.Content style={{ padding: 0 }}>
              <DateRangePicker
                editableDateInputs={true}
                onChange={(item) => onSelect(item)}
                ranges={ranges}
                months={1}
                weekStartsOn={1}
                rangeColors={['#5e72e4']}
                color="#5e72e4"
                direction="horizontal"
                staticRanges={staticRanges}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <Button size="sm" variant="outline-primary">
          <i className="fas fa-calendar mr-2" />
          {timeString}
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default DateRangeCalendar;
