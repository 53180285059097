import styled from 'styled-components';

export const SalaryPeriodWrapper = styled.div`
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
  padding: 0.1rem;
  font-size: 0.75rem;
  padding: 0.25rem 1.5rem;
`;

export const SalaryPeriodHeader = styled.span`
  font-weight: 600;
`;
export const SalaryPeriodContent = styled.span``;
