import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { projectActions, projectSelector } from '../../../store/projects/project.slice';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import { Project } from '../../../models';
import { shiftActions } from '../../../store/shifts/shift.slice';
import { FilterWrapper } from '../Filter/Filter.styled';
import ProjectFilter from '../../Scheduler/FilterActions/ProjectFilter/ProjectFilter';
import { metaActions } from '../../../store/meta/meta.slice';
import { useSearchParams } from 'react-router-dom';
import { userActions } from '../../../store/users/user.slice';
import ScheduleAvailabilityToggleNote from '../../ScheduleAvailability/ScheduleAvailabilityToggleNote';
import UserFilter from 'hooks/scheduler/UserFilter';

export interface WithProjectProps {
  projectId?: string;
}

export function withProjectFilter<T extends WithProjectProps = WithProjectProps>(
  WrappedComponent: React.ComponentType<T>,
  ActionComponent?: React.ComponentType
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithProjectFilter = (props: Omit<T, keyof WithProjectProps>) => {
    const projects: Project[] = useAppSelector(projectSelector.selectProjects);
    const isFetchingProjects: boolean = useAppSelector(projectSelector.selectIsFetching);
    const [, setSelectedUsers] = useState<string[]>([]);
    const [, setSelectedAllusers] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(projectActions.getProjects());
    }, []);

    useEffect(() => {
      if (projectId && projects.length > 0) {
        handleProjectChanged(projectId);
      }
    }, [projectId, projects]);

    const handleSelectUsers = (selectedUsers: string[]) => {
      setSelectedUsers(selectedUsers);
    };

    const handleProjectChanged = (projectId: string) => {
      dispatch(shiftActions.getShifts(projectId));

      const selectedProject = projects.find((project) => project._id === projectId);

      if (selectedProject) {
        dispatch(metaActions.setSelectedProject({ project: selectedProject }));
        dispatch(userActions.setUsers(selectedProject.users));
      }
    };

    return (
      <Card>
        <Card.Header>
          <Row>
            <FilterWrapper>
              <ProjectFilter
                projects={projects}
                selectedProject={projectId}
                setSelectedUsers={handleSelectUsers}
                setIsSelectedAllUsers={setSelectedAllusers}
              />
              <UserFilter />
              <ScheduleAvailabilityToggleNote />
            </FilterWrapper>
            {ActionComponent && <ActionComponent />}
          </Row>
        </Card.Header>
        <Card.Body>
          <LoadingIcon hidden={!isFetchingProjects} />

          <WrappedComponent {...(props as T)} projectId={projectId} />
        </Card.Body>
      </Card>
    );
  };

  ComponentWithProjectFilter.displayName = `withProjectFilter(${displayName})`;

  return ComponentWithProjectFilter;
}
