import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const CustomRow = styled(Row)`
  border-bottom: 1px solid #cecece;
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
`;

export const CustomCol = styled(Col)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const ProjectName = styled.div`
  background: #2643e9;
  opacity: 0.8;
  padding: 0.5rem 0.25rem;
  border-radius: 0.325rem;
  color: #fff;
  font-weight: 600;
  display: flex;
  height: calc(1.5em + 1.25rem + 5px);
  justify-content: center;
  align-items: center;
`;

export const InputLabel = styled.div`
  background: #e9c426;
  opacity: 0.8;
  padding: 0.25rem 0.15rem;
  border-radius: 0.325rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
  height: calc(1.5em + 1.25rem + 5px);
  flex: 5;
  margin-right: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
