import { Nullable } from '../../utils/common/types';
import { ScheduleGlance } from 'models/scheduleGlance.model';

export type ScheduleGlanceInitialState = {
  scheduleGlance: ScheduleGlance[];
  error: Nullable<any>;
  isFetching: boolean;
  isUpdating: boolean;
};

export const initialState: ScheduleGlanceInitialState = {
  scheduleGlance: [],
  error: null,
  isFetching: false,
  isUpdating: false
};
