import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './fixedSlot.initialState';
import { CreateFixedSlotPayload, FixedSlot } from 'models';

const fixedSlotSlice = createSlice({
  name: 'fixedSlots',
  initialState,
  reducers: {
    createSlot: (state, { payload }: PayloadAction<CreateFixedSlotPayload>) => {
      state.isLoading = true;
      state.error = null;
    },
    createSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
    },
    createSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    },
    updateSlot: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = null;
    },
    updateSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
    },
    updateSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    },
    deleteSlot: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    },
    deleteUserSlot: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteUserSlotSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteUserSlotFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    },
    exportFixedSlots: (
      state,
      {
        payload
      }: PayloadAction<{
        dateStart: string;
        dateEnd: string;
        projectId: string;
        fixedSlotsIndex: number;
      }>
    ) => {
      state.isLoading = true;
      state.error = null;
    },
    exportFixedSlotsSuccess: (
      state,
      {
        payload
      }: PayloadAction<{
        dateStart: string;
        dateEnd: string;
        projectId: string;
      }>
    ) => {
      state.isLoading = false;
      state.error = null;
    },
    exportFixedSlotsFailed: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export const fixedSlotActions = fixedSlotSlice.actions;

export * as fixedSlotSelector from './fixedSlot.selector';

export default fixedSlotSlice.reducer;
