import { PayloadAction } from '@reduxjs/toolkit';
import {
  Notification,
  GetNotificationsPayload,
  GetNotificationsResponse,
  MarkAsReadPayload,
  MarkAsUnreadPayload
} from 'models';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notificationApi } from 'services/notification.api';
import notificationSlice, { notificationActions, notificationSelector } from './notification.slice';

export function* getSystemNotificationsWorker(payload: PayloadAction<GetNotificationsPayload>) {
  try {
    const response: GetNotificationsResponse = yield call(
      notificationApi.getNotifications,
      payload.payload
    );
    yield put(notificationActions.getSystemNotificationsSuccess(response.data));
    yield put(notificationActions.setSystemNotisPaginationInfoSuccess(response.pagination));
  } catch (e) {
    console.log('createDivisionsWorker Error: ', e);
    yield put(notificationActions.getSystemNotificationsFailed(e));
  }
}

export function* getUserNotificationsWorker(payload: PayloadAction<GetNotificationsPayload>) {
  try {
    const response: GetNotificationsResponse = yield call(
      notificationApi.getNotifications,
      payload.payload
    );
    yield put(notificationActions.getUserNotificationsSuccess(response.data));
    yield put(notificationActions.setUserNotisPaginationInfoSuccess(response.pagination));
  } catch (e) {
    console.log('getUserNotificationsWorker Error: ', e);
    yield put(notificationActions.getUserNotificationsFailed(e));
  }
}

export function* markAsReadWorker(payload: PayloadAction<MarkAsReadPayload>) {
  try {
    const _payload = payload.payload;
    const response: Notification = yield call(notificationApi.markAsRead, _payload.id);
    const notifications: Notification[] =
      _payload.type === 'MANUAL'
        ? yield select(notificationSelector.selectUserNotifications)
        : yield select(notificationSelector.selectSystemNotifications);
    const newNotis = notifications.map((noti) => {
      if (noti._id === _payload.id) {
        return {
          ...noti,
          isRead: true
        };
      } else {
        return noti;
      }
    });
    yield put(
      notificationActions.setNotificationsSuccess({
        notifications: newNotis,
        type: _payload.type
      })
    );
    yield put(notificationActions.markAsReadSuccess());
  } catch (e) {
    console.log('markAsReadWorker Error: ', e);
    yield put(notificationActions.markAsReadFailed(e));
  }
}

export function* markAsUnreadWorker(payload: PayloadAction<MarkAsUnreadPayload>) {
  try {
    const _payload = payload.payload;
    const response: Notification = yield call(notificationApi.markAsUnread, _payload.id);
    const notifications: Notification[] =
      _payload.type === 'MANUAL'
        ? yield select(notificationSelector.selectUserNotifications)
        : yield select(notificationSelector.selectSystemNotifications);
    const newNotis = notifications.map((noti) => {
      if (noti._id === _payload.id) {
        return {
          ...noti,
          isRead: false
        };
      } else {
        return noti;
      }
    });
    yield put(
      notificationActions.setNotificationsSuccess({
        notifications: newNotis,
        type: _payload.type
      })
    );
    yield put(notificationActions.markAsUnreadSuccess());
  } catch (e) {
    console.log('markAsUnreadWorker Error: ', e);
    yield put(notificationActions.markAsUnreadFailed(e));
  }
}

export function* markAllAsReadWorker() {
  try {
    const response: Notification = yield call(notificationApi.markAllAsRead);
    const userNotifications: Notification[] = yield select(
      notificationSelector.selectUserNotifications
    );
    const systemNotifications: Notification[] = yield select(
      notificationSelector.selectSystemNotifications
    );
    const newUserNotis = userNotifications.map((noti) => {
      return { ...noti, isRead: true };
    });
    const newSystemNotis = systemNotifications.map((noti) => {
      return { ...noti, isRead: true };
    });
    yield put(
      notificationActions.setNotificationsSuccess({
        notifications: newUserNotis,
        type: 'MANUAL'
      })
    );
    yield put(
      notificationActions.setNotificationsSuccess({
        notifications: newSystemNotis,
        type: 'SYSTEM'
      })
    );
    yield put(notificationActions.markAllAsReadSuccess());
  } catch (e) {
    console.log('markAllAsReadWorker Error: ', e);
    yield put(notificationActions.markAllAsReadFailed(e));
  }
}

export function* getTotalUnreadNotisWorker() {
  try {
    const response: { count: number } = yield call(notificationApi.getTotalUnreadNotis);
    yield put(notificationActions.getTotalUnreadNotisSuccess(response.count));
  } catch (e) {
    console.log('getTotalUnreadNotisWorker Error: ', e);
    yield put(notificationActions.getTotalUnreadNotisFailed(e));
  }
}

export default function* notificationSaga() {
  yield takeLatest(notificationActions.getSystemNotifications, getSystemNotificationsWorker);
  yield takeLatest(notificationActions.getUserNotifications, getUserNotificationsWorker);
  yield takeLatest(notificationActions.markAsRead, markAsReadWorker);
  yield takeLatest(notificationActions.markAllAsRead, markAllAsReadWorker);
  yield takeLatest(notificationActions.getTotalUnreadNotis, getTotalUnreadNotisWorker);
  yield takeLatest(notificationActions.markAsUnread, markAsUnreadWorker);
}
