import { AppState } from '../index.store';

export const selectAuthIsLoggedIn = (state: AppState) => state.auths.isLoggedIn;

export const selectAuthIsFetching = (state: AppState) => state.auths.isFetching;

export const selectIsLoggingOut = (state: AppState) => state.auths.isLoggingOut;

export const selectStep = (state: AppState) => state.auths.step;

export const selectTwoFactorCode = (state: AppState) => state.auths.twoFactorCode;

export const selectTwoFactorSecretKey = (state: AppState) => state.auths.twoFactorSecretKey;

export const selectTwoFactorEnabled = (state: AppState) => state.auths.twoFactorEnabled;

export const selectCurrentUser = (state: AppState) => state.auths.currentUser;

export const selectError = (state: AppState) => state.auths.error;
