import { SpinnerButton } from 'components/Shared';
import DraggableModalDialog from 'components/Shared/DraggableModalWrapper';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import { ButtonTypes, User } from 'models';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';
import { resetPasswordSchema } from 'utils/validators';
import { useUserResetPassword } from './useUserResetPassword';

const UserResetPassword = () => {
  const {
    formik,
    isShowPassword,
    isShowPasswordConfirm,
    user,
    isVisible,
    setIsShowPassword,
    setIsShowPasswordConfirm,
    ButtonShowPassword,
    ButtonShowPasswordConfirm,
    onHide
  } = useUserResetPassword();

  return (
    <>
      <Modal
        className="modal-confirm handle"
        show={isVisible}
        onHide={onHide}
        size="sm"
        centered
        scrollable={true}
        dialogAs={DraggableModalDialog}
        backdrop={'static'}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Reset Password for {user.username}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form>
              <InputGroup className="mb-3">
                <Form.Control
                  type={isShowPassword ? 'text' : 'password'}
                  placeholder="Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                  autoComplete="nope"
                  isInvalid={formik.touched.password && !!formik.errors.password}
                />
                <InputGroup.Append className="clickable">
                  <InputGroup.Text>{ButtonShowPassword()}</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  type={isShowPasswordConfirm ? 'text' : 'password'}
                  placeholder="Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  name="passwordConfirmation"
                  autoComplete="nope"
                  isInvalid={
                    formik.touched.passwordConfirmation && !!formik.errors.passwordConfirmation
                  }
                />
                <InputGroup.Append className="clickable">
                  <InputGroup.Text>{ButtonShowPasswordConfirm()}</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.passwordConfirmation}
                </Form.Control.Feedback>
              </InputGroup>
            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onHide}>
            Cancel
          </Button>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Save
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserResetPassword;
