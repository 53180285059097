import { SpinnerButton } from 'components/Shared';
import { FormikProps, FormikProvider } from 'formik';
import { useAuth } from 'hooks';
import { ButtonTypes, FixedShift, Project, ShiftChangeFormProps, User } from 'models';
import { ScheduleGlance } from 'models/scheduleGlance.model';
import { Button, Col, Form } from 'react-bootstrap';
import ScheduleGlanceList from '../ScheduleGlanceList/ScheduleGlanceList';
import ShiftCoverBodyForm from './ShiftCoverBodyForm';
import ShiftSwapBodyForm from './ShiftSwapBodyForm';
import ShiftReplaceBodyForm from './ShiftReplaceBodyForm';
import ShiftMoveBodyForm from './ShiftMoveBodyForm';

interface Props {
  formik: FormikProps<ShiftChangeFormProps>;
  projects: Project[];
  projectUsers: User[];
  shifts: FixedShift[];
  scheduleGlancesOwner?: ScheduleGlance[] | undefined;
  scheduleGlancesAgent?: ScheduleGlance[] | undefined;
  glanceDateArr?: string[] | undefined;
  onSelectAgent: (index: number, e: any) => void;
  onSelectAgentDate: (field: string, index: number, item: any) => void;
  onSelectAgentNewDate: (field: string, index: number, item: any) => void;
  onSelectAgentShift: (index: number, e: any) => void;
  onSelectAgentNewShift: (index: number, e: any) => void;
  onDateChange: (field: string, item: any) => void;
  onChangeProject: (projectId: string) => void;
  shiftStartTime?: string;
  shiftEndTime?: string;
  selectedType: string;
  setSelectedType: (type: string) => void;
}

const requestTypes = [
  { key: 'SWAP', value: 'Swap' },
  { key: 'COVER', value: 'Cover' },
  { key: 'REPLACE', value: 'Replace' },
  { key: 'MOVE', value: 'Move' }
];

const NewShiftChangeForm = (props: Props) => {
  const {
    formik,
    projects,
    projectUsers,
    shifts,
    scheduleGlancesOwner,
    scheduleGlancesAgent,
    glanceDateArr,
    onSelectAgent,
    onSelectAgentDate,
    onSelectAgentNewDate,
    onSelectAgentShift,
    onSelectAgentNewShift,
    onDateChange,
    onChangeProject,
    shiftStartTime,
    shiftEndTime,
    selectedType,
    setSelectedType
  } = { ...props };
  const currentUser = useAuth();

  const onSelectType = (e: any) => {
    formik.resetForm();

    setSelectedType(e.target.value);
    formik.setFieldValue('type', e.target.value);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Form.Row>
          <Form.Group
            as={Col}
            className={`${selectedType === 'SWAP' ? '' : 'col-12 col-md-6'} mb-3`}
            controlId="type"
          >
            <Form.Label className="required">Request Type</Form.Label>
            <Form.Control
              as="select"
              placeholder="Disable"
              onBlur={formik.handleBlur}
              onChange={onSelectType}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
              value={formik.values.type}
              name="type"
              custom
            >
              {requestTypes.map((type) => (
                <option key={type.key} value={type.key}>
                  {type.value}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {(formik.values.type === 'SWAP' || formik.values.type === 'REPLACE') && (
            <Form.Group as={Col} className="mb-3" controlId="employeeType">
              <Form.Label className="required">User Type</Form.Label>
              <Form.Control
                as="select"
                placeholder="Enable"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
                value={formik.values.employeeType}
                name="employeeType"
                custom
                isInvalid={formik.touched.employeeType && !!formik.errors.employeeType}
              >
                <option value="">Select</option>
                <option value="FullTime <> FullTime">FT - FT</option>
                <option value="PartTime <> PartTime">PT - PT</option>
                <option value="FullTime <> PartTime">FT - PT</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.employeeType}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className="col-12 col-md-6 mb-3" controlId={`project`}>
            <Form.Label className="required">Project</Form.Label>
            <Form.Control
              as="select"
              placeholder="Select Project"
              onBlur={formik.handleBlur}
              // onChange={formik.handleChange}
              onChange={(e) => onChangeProject(e.target.value)}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
              name="project"
              value={formik.values.project}
              isInvalid={formik.touched.project && !!formik.errors.project}
            >
              <option value="">Select Project</option>
              {projects.map((project: Project) => (
                <option key={project._id} value={project._id}>
                  {project.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{formik.errors.project}</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        {formik.values.type === 'SWAP' && (
          <ShiftSwapBodyForm
            formik={formik}
            projectUsers={projectUsers}
            shifts={shifts}
            onSelectAgent={onSelectAgent}
            onSelectAgentDate={onSelectAgentDate}
            onSelectAgentNewDate={onSelectAgentNewDate}
            onSelectAgentShift={onSelectAgentShift}
            onSelectAgentNewShift={onSelectAgentNewShift}
            onDateChange={onDateChange}
          />
        )}
        {formik.values.type === 'COVER' && (
          <ShiftCoverBodyForm
            formik={formik}
            projectUsers={projectUsers}
            shifts={shifts}
            shiftStartTime={shiftStartTime}
            shiftEndTime={shiftEndTime}
            onSelectAgent={onSelectAgent}
            onDateChange={onDateChange}
          />
        )}
        {formik.values.type === 'REPLACE' && (
          <ShiftReplaceBodyForm
            formik={formik}
            projectUsers={projectUsers}
            shifts={shifts}
            onSelectAgent={onSelectAgent}
            onSelectAgentDate={onSelectAgentDate}
            onSelectAgentNewDate={onSelectAgentNewDate}
            onSelectAgentShift={onSelectAgentShift}
            onSelectAgentNewShift={onSelectAgentNewShift}
            onDateChange={onDateChange}
          />
        )}
        {formik.values.type === 'MOVE' && (
          <ShiftMoveBodyForm
            formik={formik}
            projectUsers={projectUsers}
            shifts={shifts}
            onSelectAgentShift={onSelectAgentShift}
            onSelectAgentDate={onSelectAgentDate}
            onDateChange={onDateChange}
          />
        )}
        <Form.Row>
          <Form.Group as={Col} className="mb-3" controlId="reason">
            <Form.Label className="required">Reason</Form.Label>
            <Form.Control
              type="text"
              placeholder="Reason"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reason}
              name="reason"
              isInvalid={formik.touched.reason && !!formik.errors.reason}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.reason}</Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        {currentUser.role === 'AGENT' && scheduleGlancesOwner && scheduleGlancesAgent && (
          <ScheduleGlanceList
            key="swap"
            scheduleGlancesOwner={scheduleGlancesOwner}
            scheduleGlancesAgent={scheduleGlancesAgent}
            glanceDateArr={glanceDateArr || []}
            showHeader={true}
          />
        )}
      </Form>
      {currentUser.role === 'AGENT' && (
        <div className="d-flex justify-content-between">
          <Button variant="outline-secondary" onClick={() => formik.resetForm()}>
            Cancel
          </Button>
          <SpinnerButton
            btnType={ButtonTypes.Submit}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Submit
          </SpinnerButton>
        </div>
      )}
    </FormikProvider>
  );
};

export default NewShiftChangeForm;
