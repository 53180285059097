import { PayloadAction } from '@reduxjs/toolkit';
import {
  GetShiftChangePayload,
  GetShiftChangeResponse,
  ShiftChange,
  ShiftChangeFormProps,
  GetRequestLogResponse,
  GetShiftChangeIdPayload
} from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { shiftChangeApi } from 'services';
import {
  getShiftChanges,
  getShiftChangesSuccess,
  getShiftChangesFailed,
  getOnHoldData,
  getOnHoldDataSuccess,
  getOnHoldDataFailed,
  createShiftChangeSuccess,
  createShiftChangeFailed,
  createShiftChange,
  updateShiftChange,
  updateShiftChangeSuccess,
  updateShiftChangeFailed,
  getApprovedRequestLog,
  getRejectedRequestLog,
  getApprovedRequestLogSuccess,
  getApprovedRequestLogFailed,
  getRejectedRequestLogSuccess,
  getRejectedRequestLogFailed,
  setApprovedRequestLogPaginationInfoSuccess,
  setRejectedRequestLogPaginationInfoSuccess,
  getShiftChangeById,
  getShiftChangeByIdSuccess,
  getShiftChangeByIdFailed
} from './shiftChange.slice';

export function* getShiftChangesWorker({ payload }: PayloadAction<GetShiftChangePayload>) {
  try {
    const response: GetShiftChangeResponse = yield call(shiftChangeApi.getShiftChanges, payload);
    yield put(getShiftChangesSuccess(response));
  } catch (e) {
    console.log('ShiftChangeFailed err: ', e);
    yield put(getShiftChangesFailed(e));
  }
}
export function* getOnHoldDataWorker({ payload }: PayloadAction<GetShiftChangePayload>) {
  try {
    const response: GetShiftChangeResponse = yield call(shiftChangeApi.getShiftChanges, payload);
    yield put(getOnHoldDataSuccess(response));
  } catch (e) {
    console.log('ShiftChangeFailed err: ', e);
    yield put(getOnHoldDataFailed(e));
  }
}

export function* createShiftChangeWorker({ payload }: PayloadAction<ShiftChangeFormProps>) {
  try {
    const response: ShiftChange = yield call(shiftChangeApi.createShiftChange, payload);
    yield put(createShiftChangeSuccess(response));
  } catch (e) {
    console.log('ShiftChangeFailed err: ', e);
    yield put(createShiftChangeFailed(e));
  }
}

export function* updateShiftChangeWorker({ payload }: PayloadAction<ShiftChange>) {
  try {
    const response: ShiftChange = yield call(shiftChangeApi.updateShiftChange, payload);
    yield put(updateShiftChangeSuccess(response));
  } catch (e) {
    console.log('ShiftChangeFailed err: ', e);
    yield put(updateShiftChangeFailed(e));
  }
}

export function* getApprovedRequestLogWorker({ payload }: PayloadAction<GetShiftChangePayload>) {
  try {
    const response: GetRequestLogResponse = yield call(shiftChangeApi.getShiftChanges, payload);
    yield put(getApprovedRequestLogSuccess(response));
    yield put(setApprovedRequestLogPaginationInfoSuccess(response.pagination));
  } catch (e) {
    console.log('ApprovedRequestLogFailed err: ', e);
    yield put(getApprovedRequestLogFailed(e));
  }
}

export function* getRejectedRequestLogWorker({ payload }: PayloadAction<GetShiftChangePayload>) {
  try {
    const response: GetRequestLogResponse = yield call(shiftChangeApi.getShiftChanges, payload);
    yield put(getRejectedRequestLogSuccess(response));
    yield put(setRejectedRequestLogPaginationInfoSuccess(response.pagination));
  } catch (e) {
    console.log('RejectedRequestLogFailed err: ', e);
    yield put(getRejectedRequestLogFailed(e));
  }
}

export function* getShiftChangeByIdWorker({ payload }: PayloadAction<GetShiftChangeIdPayload>) {
  try {
    const response: GetShiftChangeResponse = yield call(shiftChangeApi.getShiftChangeById, payload);
    yield put(getShiftChangeByIdSuccess(response));
  } catch (e) {
    console.log('ShiftChangeFailed err: ', e);
    yield put(getShiftChangeByIdFailed(e));
  }
}

export default function* shiftSaga() {
  yield takeLatest(getShiftChanges, getShiftChangesWorker);
  yield takeLatest(getOnHoldData, getOnHoldDataWorker);
  yield takeLatest(getShiftChanges, getShiftChangesWorker);
  yield takeLatest(createShiftChange, createShiftChangeWorker);
  yield takeLatest(updateShiftChange, updateShiftChangeWorker);
  yield takeLatest(getApprovedRequestLog, getApprovedRequestLogWorker);
  yield takeLatest(getRejectedRequestLog, getRejectedRequestLogWorker);
  yield takeLatest(getShiftChangeById, getShiftChangeByIdWorker);
}
