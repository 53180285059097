import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userActions, userSelector } from 'store/users/user.slice';
import { useEffect, useState } from 'react';
import { User } from 'models';
import { useSearchParams } from 'react-router-dom';

export const useUserFilter = () => {
  const dispatch = useAppDispatch();
  const [userLabel, setUserLabel] = useState('Employee');
  const users: User[] = useAppSelector(userSelector.selectUsers);
  const selectedUsers: string[] = useAppSelector(userSelector.selectSelectedUsers);
  const selectedAll: boolean = useAppSelector(userSelector.selectSelectedAll);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (selectedAll) {
      setUserLabel('All Employees');
    } else {
      if (selectedUsers.length === 0) {
        setUserLabel('Employee');
      } else {
        setUserLabel(`${selectedUsers.length} Employees`);
      }
    }
  }, [selectedUsers]);

  const setSelectedUsers = (userIds: string[]) => {
    dispatch(userActions.setSelectedUsers(userIds));
  };

  const setSelectedAll = (value: boolean) => {
    dispatch(userActions.setSelectedAll(value));
  };

  const onSelectUser = (e: any, userId: string) => {
    e.stopPropagation();
    const checked = e.target.checked;
    let _selectedUsers: string[] = [];
    if (userId === 'all') {
      const _isSelectedAll = !selectedAll;
      setSelectedAll(_isSelectedAll);
      _selectedUsers = _isSelectedAll === true ? [...users.map((user) => user._id)] : [];
    } else {
      const index = selectedUsers?.indexOf(userId);
      _selectedUsers = [...(selectedUsers || [])];
      index > -1 ? _selectedUsers.splice(index, 1) : _selectedUsers.push(userId);
      if (_selectedUsers.length === users.length) {
        setSelectedAll(true);
      } else {
        setSelectedAll(false);
      }
    }
    localStorage.setItem('userIds', JSON.stringify(_selectedUsers));
    setSelectedUsers(_selectedUsers);

    searchParams.set('userIdsParam', _selectedUsers.join(','));
    setSearchParams(searchParams);
  };

  return {
    users,
    userLabel,
    selectedAll,
    setSelectedAll,
    selectedUsers,
    setSelectedUsers,
    onSelectUser
  };
};
