import { Button, Table } from 'react-bootstrap';
import SystemNotification from './MainActions/SystemNotification';
import UserNotification from './MainActions/UserNotification';
const Notification = () => {
  return (
    <>
      <SystemNotification />
      <UserNotification />
    </>
  );
};

export default Notification;
