import { useAppSelector } from 'store/hooks';
import { userSelector } from 'store/users/user.slice';
import UserListByAgent from './MainActions/UserListByAgent/UserListByAgent';
import UserResetPassword from './MainActions/UserResetPassword/UserResetPassword';

const UserByAgent = () => {
  const data = useAppSelector(userSelector.selectUsersSameProject);

  return (
    <>
      {data.map((project) => (
        <UserListByAgent data={project} key={project._id} />
      ))}
      <UserResetPassword />
    </>
  );
};

export default UserByAgent;
