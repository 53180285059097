import { SpinnerButton } from 'components/Shared';
import { FormikProps, FormikProvider } from 'formik';
import { useAuth } from 'hooks';
import { ButtonTypes, FixedShift, Project, RequestLog, ShiftChangeFormProps, User } from 'models';
import { ScheduleGlance } from 'models/scheduleGlance.model';
import { Button, Col, Form } from 'react-bootstrap';
import ScheduleGlanceList from '../ScheduleGlanceList/ScheduleGlanceList';
import ShiftChangeRequestLogCoverDetail from './ShiftChangeRequestLogCoverDetail';
import ShiftChangeRequestLogMoveDetail from './ShiftChangeRequestLogMoveDetail';
import ShiftChangeRequestLogReplaceDetail from './ShiftChangeRequestLogReplaceDetail';
import ShiftChangeRequestLogSwapDetail from './ShiftChangeRequestLogSwapDetail';

interface Props {
  data: RequestLog;
  selectedType: string;
}

const ShiftChangeRequestLogDetail = (props: Props) => {
  const { data, selectedType } = { ...props };
  const currentUser = useAuth();

  return (
    <Form>
      <Form.Row>
        <Form.Group
          as={Col}
          className={`${selectedType === 'SWAP' ? '' : 'col-12 col-md-6'} mb-3`}
          controlId="type"
        >
          <Form.Label className="required">Request Type</Form.Label>
          <Form.Control type="text" value={data.type} disabled />
        </Form.Group>
        {(data.type === 'SWAP' || data.type === 'REPLACE') && (
          <Form.Group as={Col} className="mb-3" controlId="employeeType">
            <Form.Label className="required">User Type</Form.Label>
            <Form.Control type="text" value={data.employeeType} disabled />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3" controlId={`project`}>
          <Form.Label className="required">Project</Form.Label>
          <Form.Control type="text" value={data.project[0]?.name || ''} disabled />
        </Form.Group>
      </Form.Row>
      {selectedType === 'SWAP' && <ShiftChangeRequestLogSwapDetail data={data} />}
      {selectedType === 'COVER' && <ShiftChangeRequestLogCoverDetail data={data} />}
      {selectedType === 'REPLACE' && <ShiftChangeRequestLogReplaceDetail data={data} />}
      {selectedType === 'MOVE' && <ShiftChangeRequestLogMoveDetail data={data} />}
      <Form.Row>
        <Form.Group as={Col} className="mb-3" controlId="reason">
          <Form.Label className="required">Reason</Form.Label>
          <Form.Control type="text" placeholder="Reason" value={data.reason} disabled />
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default ShiftChangeRequestLogDetail;
