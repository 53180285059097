import { useProjectFilter } from 'hooks';
import ButtonSaveFilter from 'hooks/scheduler/ButtonSaveFilter';
import DivisionFilterV2 from 'hooks/scheduler/DivisionFilterV2';
import HighlighterPicker from 'hooks/scheduler/HighlighterPicker';
import PersonalFilter from 'hooks/scheduler/PersonalFilter';
import ProjectFilter from 'hooks/scheduler/ProjectFilter';
import UserFilter from 'hooks/scheduler/UserFilter';
import ViewFilter from 'hooks/scheduler/ViewFilter';
import { usePersonalFilter } from 'hooks/scheduler/usePersonalFilter';
import { useUserFilter } from 'hooks/scheduler/useUserFilter';
import { Shift } from 'models';
import SchedulerDateRangePicker from '../Actions/SchedulerDateRangePicker/SchedulerDateRangePicker';
import ButtonApplyFilter from './ButtonApplyFilter/ButtonApplyFilter';
import { FiltersWrapper } from './SchedulerFilter.styled';
import CompactOverview from 'hooks/scheduler/CompactOverview';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
interface Props {
  shifts?: Shift[];
  isFetchingShifts?: boolean;
  onChangeDateRange?: (value: any) => void;
  onChangeDateRangeMonth?: (value: any) => void;
  onChangeShowOffVacationSlots: (value: boolean) => void;
}

const SchedulerFilter = (props: Props) => {
  const { shifts } = props;

  const { projects, selectedProject } = useProjectFilter();
  const { users, selectedUsers } = useUserFilter();
  const { filters } = usePersonalFilter();
  const [showOffVacationSlot, setShowOffVacationSlot] = useState(false);

  const ShowOffVacation = () => {
    if (showOffVacationSlot === false) {
      props.onChangeShowOffVacationSlots(false);
      setShowOffVacationSlot(true);
    } else {
      props.onChangeShowOffVacationSlots(true);
      setShowOffVacationSlot(false);
    }
  };

  return (
    <div>
      <FiltersWrapper>
        <PersonalFilter />
        <ProjectFilter />
        <UserFilter />
        {selectedUsers && filters && (
          <>
            <ButtonApplyFilter selectedProject={selectedProject} />
            <ButtonSaveFilter />
          </>
        )}
        <Button size="sm" variant="primary" onClick={ShowOffVacation}>
          {showOffVacationSlot ? 'Hide Off-Vacation' : 'Show Off-Vacation'}
        </Button>
      </FiltersWrapper>

      {projects && projects.length > 0 && shifts && shifts.length > 0 && users && selectedUsers && (
        <div className="d-flex justify-content-between">
          <div>
            <ViewFilter />
            <CompactOverview />
          </div>
          <div>
            <DivisionFilterV2 />
            <HighlighterPicker />
            <SchedulerDateRangePicker
              onChangeDateRange={props.onChangeDateRange}
              onChangeDateRangeMonth={props.onChangeDateRangeMonth}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulerFilter;
