import UserSortByAlphabet from 'components/Project/Actions/ModalAddUser/UserSortByAlphabet/UserSortByAlphabet';
import { FcPagination } from 'components/Shared';
import { FcOverlayTriggerClick } from 'components/Shared/FcOverlayTriggerClick/FcOverlayTriggerClick';
import UserBulkRestore from 'components/User/Actions/UserBulkRestore/UserBulkRestore';
import UserBulkUpdate from 'components/User/Actions/UserBulkUpdate/UserBulkUpdate';
import UserByRoles from 'components/User/Actions/UserByRoles/UserByRoles';
import UserByStatus from 'components/User/Actions/UserByStatus/UserByStatus';
import UserListItem from 'components/User/Actions/UserListItem/UserListItem';
import { useAuth } from 'hooks';
import { User } from 'models';
import React from 'react';
import { Form, Popover } from 'react-bootstrap';
import {
  ActivateIcon,
  AlphabetSortWrapper,
  CustomControlLabel,
  CustomPopover,
  CustomTable,
  FilterDropdown,
  FilterIcon,
  FilterItem,
  FilterItemLabel
} from './UserList.styled';
import { useUserList } from './useUserList';

const UserList = () => {
  const {
    filteringItem,
    filteringOrder,
    onFilter,
    selectedAlphabet,
    onSelectAlphabet,
    selectedStatus,
    onSelectStatus,
    selectedRole,
    onSelectRole,
    isSelectedAll,
    onSelectAll,
    projects,
    divisions,
    selectedDivisions,
    onSelectDivision,
    selectedProjects,
    onSearch,
    setCurrentPage,
    setChangingPage,
    users,
    isFetchingUser,
    selectedUsers,
    setSelectedUsers,
    getUsers,
    isFetchedUser,
    onSelectProject,
    currentPage,
    totalCount,
    selectedUserType,
    onSelectUserType
  } = useUserList();
  const currentUser = useAuth();

  const getFilterIcon = (fieldName: string) => {
    if (filteringItem === fieldName) {
      if (filteringOrder === 'asc') {
        return (
          <ActivateIcon
            className="fas fa-sort-up ml-1 clickable"
            onClick={() => {
              onFilter(fieldName, 'desc');
            }}
          />
        );
      } else {
        return (
          <ActivateIcon
            className="fas fa-sort-down ml-1 clickable"
            onClick={() => {
              onFilter(fieldName, 'asc');
            }}
          />
        );
      }
    } else {
      return (
        <i
          className="fas fa-sort ml-1 clickable"
          onClick={() => {
            onFilter(fieldName, 'asc');
          }}
        ></i>
      );
    }
  };

  const CustomToggle: React.FC<{ children: React.ReactNode; onClick: (e: any) => void }> =
    React.forwardRef(({ children, onClick }, ref: any) => (
      <a
        href="!#"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    ));

  return (
    <>
      <AlphabetSortWrapper>
        <UserSortByAlphabet
          selectedAlphabet={selectedAlphabet}
          onSelectAlphabet={onSelectAlphabet}
        />
      </AlphabetSortWrapper>
      <UserByStatus
        selectedStatus={selectedStatus}
        onSelectStatus={onSelectStatus}
        children1={<UserByRoles selectedRole={selectedRole} onSelectRole={onSelectRole} />}
        children2={
          <Form inline className="ml-auto">
            <Form.Control
              type="text"
              size="sm"
              onChange={onSearch}
              placeholder="Search by username"
              className="h-100"
            />
          </Form>
        }
      />
      <>
        <CustomTable responsive className="mt-3" style={{ tableLayout: 'fixed' }}>
          <thead className="thead-light">
            <tr>
              <th style={{ width: '20px' }}>
                <div className="custom-control custom-checkbox">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    value="all"
                    id="all"
                    checked={isSelectedAll}
                    onChange={onSelectAll}
                  />
                  <CustomControlLabel className="custom-control-label" htmlFor="all" />
                </div>
              </th>
              <th style={{ width: '100px' }}>E.Code {getFilterIcon('employeeCode')}</th>
              <th style={{ width: '110px' }}>
                Username
                {getFilterIcon('username')}
              </th>
              <th style={{ width: '100px' }}>D.Name {getFilterIcon('displayName')}</th>
              <th style={{ width: '160px' }}>
                <div className="position-relative">
                  Full Name {getFilterIcon('fullName')}
                  <FilterDropdown>
                    <FcOverlayTriggerClick
                      overlay={
                        <CustomPopover id="popover-basic">
                          <Popover.Content className="p-0">
                            <FilterItem>
                              <input
                                type="checkbox"
                                value="FULLTIME"
                                checked={selectedUserType.includes('FULLTIME')}
                                onChange={(e) => onSelectUserType(e, 'FULLTIME')}
                              />
                              <FilterItemLabel>Full Time</FilterItemLabel>
                            </FilterItem>
                            <FilterItem>
                              <input
                                type="checkbox"
                                value="PARTIME"
                                checked={selectedUserType.includes('PARTIME')}
                                onChange={(e) => onSelectUserType(e, 'PARTIME')}
                              />
                              <FilterItemLabel>Part Time</FilterItemLabel>
                            </FilterItem>
                          </Popover.Content>
                        </CustomPopover>
                      }
                      triggerComponent={<FilterIcon className="fas fa-filter clickable" />}
                    />
                  </FilterDropdown>
                </div>
              </th>
              <th style={{ width: '120px' }}>Phone {getFilterIcon('phone')}</th>
              <th style={{ width: '180px' }}>Work Email {getFilterIcon('email')}</th>
              <th style={{ width: '180px' }}>Personal Email {getFilterIcon('personalEmail')}</th>
              <th style={{ width: '300px' }}>
                <table>
                  <thead>
                    <tr>
                      <th
                        className="p-0 pr-4 border-0 position-relative"
                        style={{ width: '150px' }}
                      >
                        Project
                        <FilterDropdown>
                          <FcOverlayTriggerClick
                            overlay={
                              <CustomPopover id="popover-basic">
                                <Popover.Content className="p-0">
                                  {projects.map((project) => (
                                    <FilterItem key={project._id}>
                                      <input
                                        type="checkbox"
                                        value={project._id}
                                        checked={selectedProjects.includes(project._id)}
                                        onChange={(e) => onSelectProject(e, project._id)}
                                      />
                                      <FilterItemLabel>{project.name}</FilterItemLabel>
                                    </FilterItem>
                                  ))}
                                </Popover.Content>
                              </CustomPopover>
                            }
                            triggerComponent={<FilterIcon className="fas fa-filter clickable" />}
                          />
                        </FilterDropdown>
                      </th>
                      <th
                        className="p-0 pl-4 border-0 position-relative"
                        style={{ width: '150px' }}
                      >
                        Division
                        <FilterDropdown>
                          <FcOverlayTriggerClick
                            overlay={
                              <CustomPopover id="popover-basic">
                                <Popover.Content className="p-0">
                                  {divisions.map((division) => (
                                    <FilterItem key={division._id}>
                                      <input
                                        type="checkbox"
                                        value={division._id}
                                        checked={selectedDivisions.includes(division._id)}
                                        onChange={(e) => onSelectDivision(e, division._id)}
                                      />
                                      <FilterItemLabel>{division.name}</FilterItemLabel>
                                    </FilterItem>
                                  ))}
                                </Popover.Content>
                              </CustomPopover>
                            }
                            triggerComponent={<FilterIcon className="fas fa-filter clickable" />}
                          />
                        </FilterDropdown>
                      </th>
                    </tr>
                  </thead>
                </table>
              </th>
              <th style={{ width: '80px' }}>Status</th>
              {currentUser.role === 'MANAGER' && <th style={{ width: '110px' }}>Action</th>}
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            {!isFetchingUser &&
              users.map((u: User) => (
                <UserListItem
                  user={u}
                  key={u._id}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={(users: User[]) => setSelectedUsers(users)}
                  resetPage={() => {
                    setCurrentPage(1);
                    setChangingPage(1);
                  }}
                  getUsers={getUsers}
                />
              ))}
          </tbody>
        </CustomTable>
        <div className="p-3 d-flex align-items-start justify-content-between">
          {selectedStatus !== 'DELETED' && (
            <UserBulkUpdate
              selectedUsers={selectedUsers}
              resetPage={() => {
                setCurrentPage(1);
                setChangingPage(1);
              }}
              getUsers={getUsers}
              isVisible={isFetchedUser}
              resetSelectedUsers={() => setSelectedUsers([])}
            />
          )}

          {selectedStatus === 'DELETED' && (
            <UserBulkRestore
              selectedUsers={selectedUsers}
              resetPage={() => {
                setCurrentPage(1);
                setChangingPage(1);
              }}
              getUsers={getUsers}
              resetSelectedUsers={() => setSelectedUsers([])}
            />
          )}

          <FcPagination
            onChangePage={(page: number) => {
              setCurrentPage(page);
              setChangingPage(page);
            }}
            totalItems={totalCount}
            perPage={10}
            currentPage={currentPage}
            // setCurrentPage={(page: number) => setCurrentPage(page)}
          />
        </div>
      </>
    </>
  );
};

export default UserList;
