import { Table } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
import { reportSelector } from 'store/reports/report.slice';
import { getDate, getDay, getMonth, getYear } from 'date-fns';
const ReportTimesheetList = () => {
  const reports = useAppSelector(reportSelector.selectReports);
  return (
    <>
      <Table responsive>
        <thead className="thead-light">
          <tr>
            <th>Timestamp</th>
            <th>Admin</th>
            <th>Shift</th>
            <th>Hour</th>
            <th>Month</th>
            <th>Date</th>
            <th>Year</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((r, index) => (
            <tr key={index}>
              <td></td>
              <td>{r.user?.displayName || r.user?.username}</td>
              <td>{r.shift.name.split('Shift ')[1]}</td>
              <td>{r.duration}</td>
              <td>{getMonth(new Date(r.date)) + 1}</td>
              <td>{getDate(new Date(r.date))}</td>
              <td>{getYear(new Date(r.date))}</td>
              <td>{r.markAs && r.markAs !== 'NULL' && r.markAs}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ReportTimesheetList;
