import { SpinnerButton } from 'components/Shared';
import { FormikProps, useFormik } from 'formik';
import { ButtonTypes, User } from 'models';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { projectActions, projectSelector } from 'store/projects/project.slice';
import { projectSchema } from 'utils/validators';
import ProjectForm from '../../Actions/ProjectForm/ProjectForm';

interface FormProps {
  name: string;
  users: User[];
  managers: User[];
  allottedHoursPerWeek: number;
  numberOfShifts: number;
  weekDefinition: number;
}

const initialValues: FormProps = {
  name: '',
  users: [],
  managers: [],
  allottedHoursPerWeek: 0,
  numberOfShifts: 1,
  weekDefinition: 1
};
const AddProject = () => {
  const dispatch = useAppDispatch();
  const [submittingType, setSubmittingType] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const isCreating = useAppSelector(projectSelector.selectIsCreating);
  const [isVisible, setIsVisible] = useState(false);
  const project = useAppSelector(projectSelector.selectSelectedProject);

  const formik: FormikProps<FormProps> = useFormik<FormProps>({
    initialValues: initialValues,
    validationSchema: projectSchema,
    onSubmit: (values: FormProps, actions) => {
      handleSubmit(values, actions);
    }
  });

  useEffect(() => {
    if (formik.isSubmitting && !isCreating) {
      formik.setSubmitting(false);
      toast.success('Project Created Successfully.');
      if (submittingType === 'submit') {
        setIsVisible(false);
        dispatch(projectActions.setSelectedProject(null));
      }
    }
  }, [isCreating]);

  useEffect(() => {
    if (!isVisible) {
      formik.resetForm();
      setSubmitted(false);
    }
  }, [isVisible]);

  const handleSubmit = (values: FormProps, actions: any) => {
    dispatch(
      projectActions.createProject({ ...values, managers: values.managers.map((m) => m._id) })
    );
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setIsVisible(true);
          dispatch(projectActions.setSelectedProject(null));
        }}
      >
        Add Project
      </Button>
      <Modal
        className="modal-confirm"
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
          dispatch(projectActions.setSelectedProject(null));
        }}
        size="lg"
        centered
        // scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <ProjectForm
            formik={formik}
            project={project === null ? undefined : project}
          ></ProjectForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setIsVisible(false)}>
            Cancel
          </Button>
          {!submitted && (
            <>
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                colorType="success"
                disabled={formik.isSubmitting && submittingType === 'apply'}
                onClick={() => {
                  setSubmittingType('apply');
                  setSubmitted(true);
                  formik.submitForm();
                }}
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
              >
                Apply
              </SpinnerButton>
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                disabled={formik.isSubmitting || submitted}
                onClick={() => {
                  setSubmittingType('submit');
                  setSubmitted(true);
                  formik.submitForm();
                }}
              >
                Save
              </SpinnerButton>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProject;
