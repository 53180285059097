import { TimePicker } from 'components/Shared';
import { ButtonTypes, FixedShift, WeekDay, WeekType, Project } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { fixedShiftActions, fixedShiftSelector } from 'store/fixedShifts/fixedShift.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './ShiftForm.scss';
import { CustomCol, CustomRow, InputLabel, ProjectName } from './ShiftForm.styled';
import ProjectForm from 'components/Project/Actions/ProjectForm/ProjectForm';
import ShiftFormInformation from './ShiftFormInformation/ShiftFormInformation';
import { sortShiftsByName } from 'utils/sorters/shift.sorter';

interface Props {
  project: Project;
  formik: any;
  shifts: FixedShift[];
}
const ShiftForm = (props: Props) => {
  const { project, formik, shifts } = { ...props };
  const [isTypeChanged, setIsTypeChanged] = useState(false);

  let shift: FixedShift | undefined = shifts[0];
  const onChangeShift = (shiftId: string) => {
    shift = shifts.find((s) => s._id === shiftId);
    formik.setValues({
      shiftId: shift?._id || '',
      startTime: shift?.startTime || '24:00',
      endTime: shift?.endTime || '23:59',
      note: shift?.note || '',
      breakTime: shift?.breakTime || 0,
      numOfAgents: shift?.numOfAgents || 0,
      type: (isTypeChanged ? formik.values.type : shift?.type) || WeekType.WEEKDAY
    });
  };

  const onChangeType = (type: string) => {
    formik.setValues({
      shiftId: formik.values.shiftId || '',
      startTime: '',
      endTime: '',
      note: '',
      breakTime: 0,
      numOfAgents: 0,
      type: type
    });
    setIsTypeChanged(true);
  };

  return (
    <>
      <CustomRow>
        <Col md={3}>
          <ProjectName
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            {project?.name}
          </ProjectName>
        </Col>
        <Col md={4} className="d-flex padding-x-xs">
          <InputLabel
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Weekly Alloted Hour
          </InputLabel>
          <Form.Control
            type="text"
            className="flex-1"
            defaultValue={project?.allottedHoursPerWeek}
            disabled
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          />
        </Col>
        <CustomCol
          md={2}
          className="d-flex"
          style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem' }}
        >
          <InputLabel
            style={{ flex: 2 }}
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            No. of Shift
          </InputLabel>
          <Form.Control
            type="text"
            defaultValue={project?.numberOfShifts}
            className="flex-1"
            disabled
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          />
        </CustomCol>
        <CustomCol md={3} className="d-flex">
          <InputLabel
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          >
            Week Definition
          </InputLabel>
          <Form.Control
            type="text"
            defaultValue={WeekDay[project.weekDefinition]}
            disabled
            className="flex-1"
            onMouseDown={(e: any) => {
              e.stopPropagation();
            }}
          ></Form.Control>
          {/* <Form.Control type="text" defaultValue={project?.weekDefinition}  className="flex-1"/> */}
        </CustomCol>
      </CustomRow>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Type
            </InputLabel>
          </Col>
          <CustomCol sm="4">
            <Form.Control
              as="select"
              placeholder="Select Employeee"
              onBlur={formik.handleBlur}
              onChange={(e) => onChangeType(e.target.value)}
              name="type"
              value={formik.values.type}
              custom
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              {(Object.keys(WeekType) as Array<keyof typeof WeekType>).map((type) => (
                <option
                  key={type}
                  value={type}
                  onMouseDown={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  {WeekType[type].charAt(0) + WeekType[type].slice(1).toLowerCase()}
                </option>
              ))}
            </Form.Control>
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Shift
            </InputLabel>
          </Col>
          <CustomCol sm="4">
            <Form.Control
              as="select"
              onChange={(e) => {
                onChangeShift(e.target.value);
              }}
              value={formik.values.shiftId}
              custom
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              {shifts.map((shift) => (
                <option key={shift._id} value={shift._id}>
                  {shift.name}
                </option>
              ))}
            </Form.Control>
          </CustomCol>
          <CustomCol sm="4">
            <Form.Control
              type="text"
              placeholder="Enter note"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="note"
              value={formik.values.note}
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Start Time
            </InputLabel>
          </Col>
          <CustomCol sm="4">
            <TimePicker formik={formik} fieldName={'startTime'}>
              <div
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <Form.Control
                  type="text"
                  onBlur={props.formik.handleBlur}
                  onChange={props.formik.handleChange}
                  onKeyDown={(e: any) => e.preventDefault()}
                  name="startTime"
                  value={props.formik.values.startTime}
                  isInvalid={formik.touched.startTime && formik.errors.startTime}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.startTime}
                </Form.Control.Feedback>
              </div>
            </TimePicker>
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              End Time
            </InputLabel>
          </Col>
          <CustomCol sm="4">
            <TimePicker formik={formik} fieldName={'endTime'}>
              <div
                onMouseDown={(e: any) => {
                  e.stopPropagation();
                }}
              >
                <Form.Control
                  type="text"
                  onBlur={props.formik.handleBlur}
                  onChange={props.formik.handleChange}
                  onKeyDown={(e: any) => e.preventDefault()}
                  name="endTime"
                  value={props.formik.values.endTime}
                  isInvalid={formik.touched.endTime && formik.errors.endTime}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.endTime}
                </Form.Control.Feedback>
              </div>
            </TimePicker>
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Break Time
            </InputLabel>
          </Col>
          <CustomCol sm="4">
            <Form.Control
              type="number"
              placeholder="Enter Break Time"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="breakTime"
              value={formik.values.breakTime}
              onKeyPress={(event: any) => {
                if (!/^[0-9]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              step="0.5"
              lang="en"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
          </CustomCol>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlaintextEmail">
          <Col sm="3" className="w-100">
            <InputLabel
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            >
              Agent Count
            </InputLabel>
          </Col>
          <CustomCol sm="4">
            <Form.Control
              type="number"
              placeholder="Enter Number of Agent"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="numOfAgents"
              value={formik.values.numOfAgents}
              min="1"
              step="1"
              onMouseDown={(e: any) => {
                e.stopPropagation();
              }}
            />
          </CustomCol>
        </Form.Group>
      </Form>
      <ShiftFormInformation project={project} formik={formik} shifts={shifts} shift={shift} />
    </>
  );
};

export default ShiftForm;
