import Login from 'components/Login/Login';
import { NotFound, PrivateRoute } from 'components/common';
import AppLayout from 'layout/AppLayout/AppLayout';
import AttendancePage from 'pages/Attendance';
import HomePage from 'pages/HomePage';
import Project from 'pages/Project';
import ReportPage from 'pages/ReportPage';
import ScheduleActualPage from 'pages/ScheduleActualPage';
import ScheduleAvailabilityPage from 'pages/ScheduleAvailabilityPage';
import ScheduleFixPage from 'pages/ScheduleFixPage';
import ShiftChangePage from 'pages/ShiftChangePage';
import User from 'pages/UserPage';
import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
// import { useCustomRoutes } from 'utils/routes/routesByRole';

type ExtendedRouteObject = RouteObject & {
  breadcrumb?: any;
  children?: ExtendedRouteObject[];
};

export const roles = [
  { name: 'MANAGER', path: '/managers' },
  { name: 'AGENT', path: '/agents' }
];

export const routes = [
  {
    path: '/notifications',
    breadcrumd: <span>Notification</span>,
    component: <HomePage></HomePage>,
    allowedRoles: ['MANAGER', 'AGENT'],
    icon: 'fa-regular fa-envelope',
    colorClass: 'text-primary'
  },
  {
    path: '/projects',
    breadcrumd: <span>Project</span>,
    component: <Project></Project>,
    allowedRoles: ['MANAGER'],
    icon: 'fa-solid fa-gears',
    colorClass: 'text-blue'
  },
  {
    path: '/users',
    breadcrumd: <span>User</span>,
    component: <User></User>,
    allowedRoles: ['MANAGER', 'AGENT'],
    icon: 'fa-solid fa-users',
    colorClass: 'text-orange'
  },
  {
    path: '/schedule_actual',
    breadcrumd: <span>Schedule Actual</span>,
    component: <ScheduleActualPage></ScheduleActualPage>,
    allowedRoles: ['MANAGER', 'AGENT'],
    icon: 'fa-regular fa-calendar-days',
    colorClass: 'text-info'
  },
  {
    path: '/schedule_fix',
    breadcrumd: <span>Schedule Fix</span>,
    component: <ScheduleFixPage></ScheduleFixPage>,
    allowedRoles: ['MANAGER', 'AGENT'],
    icon: 'fa-regular fa-calendar-plus',
    colorClass: 'text-red'
  },
  {
    path: '/schedule_availability',
    breadcrumd: <span>Schedule Availability</span>,
    component: <ScheduleAvailabilityPage />,
    allowedRoles: ['MANAGER', 'AGENT'],
    icon: 'fa-regular fa-calendar-check',
    colorClass: 'text-yellow'
  },
  {
    path: '/attendance',
    breadcrumd: <span>Attendance</span>,
    component: <AttendancePage />,
    allowedRoles: ['MANAGER', 'AGENT'],
    icon: 'fa-regular fa-circle-check',
    colorClass: 'text-success'
  },
  {
    path: '/report',
    breadcrumd: <span>Report</span>,
    component: <ReportPage />,
    allowedRoles: ['MANAGER'],
    icon: 'fa-solid fa-chart-pie',
    colorClass: 'text-info'
  },
  {
    path: '/shift_change',
    breadcrumd: <span>Shift Change Request</span>,
    component: <ShiftChangePage />,
    allowedRoles: ['MANAGER', 'AGENT'],
    icon: 'fa-regular fa-calendar-check',
    colorClass: 'text-yellow'
  }
];

export const routesByRole: ExtendedRouteObject[] = roles.map((role) => {
  return {
    element: <AppLayout />,
    path: role.path,
    children: [
      ...[
        {
          path: role.path,
          element: (
            <PrivateRoute allowedRoles={role.name === 'MANAGER' ? ['MANAGER'] : ['AGENT']}>
              <HomePage />
            </PrivateRoute>
          ),
          breadcrumb: () => <span>{role.name === 'MANAGER' ? 'Managers' : 'Agents'}</span>
        }
      ],
      ...routes.map((route: any) => {
        return {
          path: role.path + route.path,
          element: <PrivateRoute allowedRoles={route.allowedRoles}>{route.component}</PrivateRoute>,
          breadcrumd: () => <span>{route.path.split('/')[1]}</span>
        };
      })
    ]
  } as ExtendedRouteObject;
});

export const appRoutes: ExtendedRouteObject[] = [
  ...[
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    {
      path: '*',
      element: <NotFound></NotFound>
    }
  ],
  ...routesByRole
];

const AppRouter = () => {
  return useRoutes(appRoutes);
};

export default AppRouter;
