import * as React from 'react';
import { NotFoundBackground } from './NotFound.styled';
import NotFoundImg from 'assets/images/404.jpg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { User } from 'models';
import { Cookies } from 'react-cookie';

export const NotFound = () => {
  const cookies = new Cookies();
  const currentUser: User = cookies.get('currentUser');

  return (
    <div style={{ background: 'white' }}>
      <Link
        to={currentUser?.role === 'MANAGER' ? '/managers' : '/agents'}
        style={{ color: '#fff' }}
      >
        <Button variant="primary" style={{ position: 'absolute', top: '10vh', left: '50%' }}>
          Back
        </Button>
      </Link>
      <NotFoundBackground src={NotFoundImg}></NotFoundBackground>
    </div>
  );
};
