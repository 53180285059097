import * as Yup from 'yup';

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      'Password must have at least 6 character and contain at least one of the following: uppercase letters, lowercase letters, special characters and numbers'
    ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required')
});
