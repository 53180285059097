import createSagaMiddleware from '@redux-saga/core';
import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import attendanceReducer from './attendances/attendance.slice';
import authReducer from './auth/auth.slice';
import divisionReducer from './divisions/division.slice';
import fixedShiftReducer from './fixedShifts/fixedShift.slice';
import fixedSlotReducer from './fixedSlots/fixedSlot.slice';
import rootSaga from './index.saga';
import projectReducer from './projects/project.slice';
import schedulerFixReducer from './schedulerFixs/schedulerFix.slice';
import schedulerReducer from './schedulers/scheduler.slice';
import shiftReducer from './shifts/shift.slice';
import slotReducer from './slots/slot.slice';
import userReducer from './users/user.slice';
import commentReducer from './comments/comment.slice';
import notificationReducer from './notifications/notification.slice';
import reportReducer from './reports/report.slice';
import salaryPeriodReducer from './salaryPeriods/salaryPeriod.slice';
import noteReducer from './notes/note.slice';
import filterReducer from './filters/filter.slice';
import scheduleGlanceReducer from './scheduleGlance/scheduleGlance.slice';
import shiftChangeReducer from './shiftChanges/shiftChange.slice';
import offVacationReducer from './offVacations/offVacation.slice';

import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { fixedScheduledPreviewReducer } from './fixedSchedulePreview/fixedScheduledPreview.slice';
import { metaReducer } from './meta/meta.slice';
import { scheduleAvailabilityReducer } from './scheduleAvailability/scheduleAvailability.slice';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  router: routerReducer,
  auths: authReducer,
  projects: projectReducer,
  users: userReducer,
  shifts: shiftReducer,
  fixedShifts: fixedShiftReducer,
  slots: slotReducer,
  fixedSlots: fixedSlotReducer,
  schedulers: schedulerReducer,
  schedulerFixs: schedulerFixReducer,
  scheduleAvailability: scheduleAvailabilityReducer,
  meta: metaReducer,
  fixedScheduledPreview: fixedScheduledPreviewReducer,
  divisions: divisionReducer,
  attendances: attendanceReducer,
  comments: commentReducer,
  notifications: notificationReducer,
  reports: reportReducer,
  salaryPeriods: salaryPeriodReducer,
  notes: noteReducer,
  filters: filterReducer,
  scheduleGlance: scheduleGlanceReducer,
  shiftChanges: shiftChangeReducer,
  offVacations: offVacationReducer
});

export const makeStore = (preloadedState?: PreloadedState<AppState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState: preloadedState,
    middleware: [sagaMiddleware, routerMiddleware]
  });
};

const store = makeStore();

export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);

export type AppStore = ReturnType<typeof makeStore>;

export type AppState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export default store;
