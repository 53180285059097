import styled from 'styled-components';

export const ShiftHeader = styled.th``;

export const ShiftInfoIcon = styled.i`
  min-width: 1.125rem;
  margin-right: 0.5rem;
  // float: left;
`;

export const ButtonExpand = styled.i`
  transform: translatex(-0.5rem);
  &:hover {
    cursor: pointer;
    color: var(--primary);
  }
`;

export const ButtonCollapse = styled(ButtonExpand)``;
