import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectShowingCompactOverview } from 'store/schedulers/scheduler.selector';
import { schedulerActions } from 'store/schedulers/scheduler.slice';
const CompactOverview = () => {
  const dispatch = useAppDispatch();
  const compactOverview = useAppSelector(selectShowingCompactOverview);

  return (
    <Button
      variant="outline-primary"
      size="sm"
      onClick={() => dispatch(schedulerActions.setShowingCompactOverview(!compactOverview))}
    >
      Compact Overview:{' '}
      {compactOverview ? (
        <span className="text-success">ON</span>
      ) : (
        <span className="text-danger">OFF</span>
      )}
    </Button>
  );
};

export default CompactOverview;
