import { Filter } from 'models';
import { Report, ReportDashboardData } from 'models/report.model';
export type ReportInitialState = {
  isFetching: boolean;
  reports: Report[];
  dashboardData: ReportDashboardData | undefined;
  selectedDateRange: string[];
  sortString: string;
  isExporting: boolean;
  personalFilters: Filter[];
  isCreatingFilter: boolean;
  error: any;
  userType: string;
};

export const initialState: ReportInitialState = {
  isFetching: false,
  reports: [],
  dashboardData: undefined,
  selectedDateRange: [],
  sortString: 'date',
  isExporting: false,
  isCreatingFilter: false,
  personalFilters: [],
  error: null,
  userType: ''
};
