import styled from 'styled-components';

export const SchedulerTableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 1rem;
`;
export const SchedulerTableEl = styled.table`
  width: 100%;
  min-width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  color: #717783;
  text-align: center;

  & td {
    padding: 4px 4px 8px 4px;
    border-left: 0.5px solid #ecf1f9;
    border-right: 0.5px solid #ecf1f9;
    border-top: 1px solid #ecf1f9;
    border-bottom: 1px solid #ecf1f9;
    background: #fff;
    vertical-align: top;
    width: 200px;
    &:last-child {
      border-right: none;
    }
  }
`;

export const ScheduleHeaderText = styled.p`
  font-size: 0.85rem;
  color: #1b409d;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.375rem;

  &.day-name {
    padding-bottom: 0.25rem;
    margin-left: -8px !important;
    margin-right: -8px;
    border-bottom: 2px solid #ecf1f9;
  }
`;

export const RowHeader = styled.td`
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  font-size: 0.85rem;
  color: #1b409d;
  font-weight: 600;
  width: 250px !important;
  vertical-align: middle !important;
  background: #ecf1f9;
`;

export const DivisionContent = styled.div`
  height: 100%;
  padding: 0.2rem 0.5rem;
  border-left: 0.3rem solid #32539c;
  text-align: left;
`;
