import { ShiftHelper } from 'helpers';
import { FixedShift, Shift } from 'models';
import { useState } from 'react';
import { parseShiftTime } from 'utils/formatters/datetime.formatter';
import {
  ButtonCollapse,
  ButtonExpand,
  ShiftHeader,
  ShiftInfoIcon
} from './useSchedulerRowHeader.styled';

export const useSchedulerRowHeader = (shift: Shift | FixedShift) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);
  return (
    <ShiftHeader style={{ height: showDetail ? '120px' : '80px' }}>
      <p>
        <ShiftInfoIcon className="far fa-calendar text-primary" />
        {shift.name}
      </p>
      <p>
        <ShiftInfoIcon className="fa-solid fa-person-walking-dashed-line-arrow-right text-success" />
        {parseShiftTime(shift.startTime)}
      </p>
      <p>
        <ShiftInfoIcon
          className="fa-solid fa-person-walking-dashed-line-arrow-right text-danger"
          style={{
            transform: 'rotateY(180deg)'
          }}
        />
        {parseShiftTime(shift.endTime)}
      </p>
      {showDetail ? (
        <>
          <p>
            <ShiftInfoIcon className="fas fa-clock-rotate-left text-pink" />
            {ShiftHelper.getDurationOfShift(shift).toFixed(2)}
          </p>
          <p>
            <ShiftInfoIcon className="far fa-user text-info" />
            {shift.numOfAgents}
          </p>
          <ButtonCollapse className="fa-solid fa-chevron-up" onClick={() => setShowDetail(false)} />
        </>
      ) : (
        <ButtonExpand className="fa-solid fa-ellipsis" onClick={() => setShowDetail(true)} />
      )}
    </ShiftHeader>
  );
};
