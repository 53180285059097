import { Nullable } from '../../utils/common/types';

export type FixedSlotInitialState = {
  error: Nullable<string>;
  isLoading: boolean;
};

export const initialState: FixedSlotInitialState = {
  error: null,
  isLoading: false
};
