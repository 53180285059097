import { useProjectFilter } from 'hooks/scheduler/useProjectFilter';
import { SchedulerHeaderWrapper, SchedulerHeaderItem } from './SchedulerHeader.styled';
import { format } from 'date-fns';
import { Shift } from 'models';
import { ShiftHelper } from 'helpers';

interface Props {
  dates: Date[];
  shifts: Shift[];
}

const SchedulerHeader = (props: Props) => {
  const { dates, shifts } = { ...props };
  const { selectedProject, projects } = useProjectFilter();

  const projectWorkingHrsInfo = () => {
    const project = projects.find((p) => p._id === selectedProject);
    const totalHrs = ShiftHelper.getWeekTotalHours(shifts, dates);
    return (
      <>
        Alloted Hrs: {project?.allottedHoursPerWeek}
        <br />
        Total Hrs: {totalHrs}
      </>
    );
  };

  return (
    <SchedulerHeaderWrapper>
      <SchedulerHeaderItem>{projectWorkingHrsInfo()}</SchedulerHeaderItem>
      {props.dates.map((d: Date, index: number) => (
        <SchedulerHeaderItem key={index}>
          <>
            {format(d, 'E, MMM dd')} <br />
            {ShiftHelper.getDateTotalHours(shifts, d)}
          </>
        </SchedulerHeaderItem>
      ))}
    </SchedulerHeaderWrapper>
  );
};

export default SchedulerHeader;
