import { AppState } from '../index.store';

export const selectIsFetching = (state: AppState) => state.projects.isFetching;

export const selectIsCreating = (state: AppState) => state.projects.isCreating;

export const selectIsDeleting = (state: AppState) => state.projects.isDeleting;

export const selectProjects = (state: AppState) => state.projects.projects;

export const selectIsUpdatingProjectUsers = (state: AppState) =>
  state.projects.isUpdatingProjectUsers;

export const selectProject = (state: AppState) => state.projects.project;

export const selectError = (state: AppState) => state.projects.error;

export const selectIsModalUpdateProjectVisible = (state: AppState) =>
  state.projects.isModalUpdateProjectVisible;

export const selectSelectedProject = (state: AppState) => state.projects.selectedProject;
