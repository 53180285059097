import styled from 'styled-components';

export const SidebarLogo = styled.img`
  max-height: 3.5rem !important;
`;

export const SidebarIcon = styled.i``;

export const UnreadNoti = styled.div`
  background: var(--danger);
  border-radius: 0.375rem;
  margin-left: 0.5rem;
  padding: 0 0.25rem;
  font-size: 0.725rem;
  color: #fff;
}
`;
