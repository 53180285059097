import { SpinnerButton } from 'components/Shared';
import UserForm from 'components/User/Actions/UserForm/UserForm';
import { ButtonTypes } from 'models';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { userActions } from 'store/users/user.slice';
import { useUpdateUser } from './useUpdateUser';

const UpdateUser = () => {
  const {
    isVisible,
    isFetchingProjects,
    isFetchingMultiProjectDivisions,
    formik,
    projects,
    submitted,
    onHide
  } = useUpdateUser();

  return (
    <>
      <Modal
        className="modal-confirm modal-edit-user"
        show={isVisible}
        onHide={onHide}
        size="lg"
        centered
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        // scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {isFetchingProjects && isFetchingMultiProjectDivisions && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {!isFetchingProjects && !isFetchingMultiProjectDivisions && (
            <UserForm formik={formik} projects={projects} isUpdating={true}></UserForm>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onHide}>
            Cancel
          </Button>
          {!submitted && (
            <>
              <SpinnerButton
                btnType={ButtonTypes.Submit}
                disabled={formik.isSubmitting || submitted}
                onClick={() => {
                  // setSubmittingType('submit');
                  // setSubmitted(true);
                  formik.submitForm();
                }}
              >
                Save
              </SpinnerButton>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateUser;
