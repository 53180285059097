import { ButtonGroup, FormControl, Nav, ToggleButton } from 'react-bootstrap';

interface Props {
  selectedStatus: string;
  onSelectStatus: (value: string) => void;
  children1: React.ReactNode;
  children2: React.ReactNode;
}

const filterStatuses = [
  {
    key: 'ALL',
    value: 'All'
  },
  {
    key: 'ACTIVE',
    value: 'Active'
  },
  {
    key: 'INACTIVE',
    value: 'Inactive'
  },
  // {
  //   key: 'BANNED',
  //   value: 'Banned'
  // },
  {
    key: 'DELETED',
    value: 'Deleted'
  }
];

const UserByStatus = (props: Props) => {
  const { selectedStatus, onSelectStatus, children1, children2 } = { ...props };
  return (
    <>
      <div className="d-flex justify-content-between px-4 pt-2">
        <ButtonGroup toggle className="mr-2">
          {filterStatuses.map((s) => (
            <ToggleButton
              key={s.key}
              type="radio"
              variant="outline-primary"
              size="sm"
              name="radio"
              value={s.key}
              checked={selectedStatus === s.key}
              onChange={(e) => onSelectStatus(s.key)}
              style={{ lineHeight: 2 }}
            >
              {s.value}
            </ToggleButton>
          ))}
          {children1 !== undefined && children1 !== null && children1}
        </ButtonGroup>
        {children2}
      </div>
    </>
  );
};
export default UserByStatus;
