import styled from 'styled-components';

export const SchedulerHeaderWrapper = styled.tr``;

export const SchedulerHeaderItem = styled.th`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-width: 180px;
  &:first-child {
    z-index: 5;
    width: 150px;
  }
  &:last-child {
    border-right: none;
  }
`;
